@keyframes moveInLeft {
  0%{
    opacity: 0;
    transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
}
  80% {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
}
  100% {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
}
}

@keyframes moveInRight {
  0%{
    opacity: 0;
    transform: translateX(10rem);
    -webkit-transform: translateX(10rem);
    -moz-transform: translateX(10rem);
    -ms-transform: translateX(10rem);
    -o-transform: translateX(10rem);
}
  80% {
    transform: translateX(-1rem);
    -webkit-transform: translateX(-1rem);
    -moz-transform: translateX(-1rem);
    -ms-transform: translateX(-1rem);
    -o-transform: translateX(-1rem);
}
  100% {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
}
}

@keyframes moveInBottom {
  0%{
    opacity: 0;
    transform: translateY(3rem);
    -webkit-transform: translateY(3rem);
    -moz-transform: translateY(3rem);
    -ms-transform: translateY(3rem);
    -o-transform: translateY(3rem);
}

  100% {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
}
}
