//his primary colors:
$color-Greenprimary:#55c57a;
$color-Greenprimary-light:#7ed56f;
$color-Greenprimary-dark:#28b485;
$color-green-yellow:#a5b6f3;
$color-green-yellow:#839cf6;

$color-secondary-light:#ffb900;
$color-secondary-dark:#ff7730;
$color-red:#ff3030;

$color-tertiary-light:#085eaf;
$color-tertiary-dark:#5643fa;

//Colors:
$color-primary:#55c57a;
$color-primary-light-1:#0d71f380;
$color-primary-light-01:#470faef8;
$color-primary-light:#0e55b3;
$color-primary-light-3:#74aaf1fd;
$color-primary-light-2:#04399c;
$color-primary-dark:#28b48591;

$color-primary-1: #55c57a;
$color-primary-light-1: #7ed56f;
$color-primary-dark-1: #28b485;

$color-grey-light-0:#f7f7f7;
$color-grey-light-1:#f7f7f7c9;
$color-grey-light-2:#eeeeee;
// $color-grey-light-2:#fffefe;
$color-grey-light-4:#eceff1;
$color-grey-light-3:rgba(150, 143, 143, 1);
$color-grey-light-3:rgb(243, 149, 149);


$color-grey-dark:#777;
$color-grey-dark-1: #525050;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-navy-dark: #063970;


$color-white:#fff;
$color-black:#000;
$color-black-000:#4d4b4b;
$color-black-1:#141414;
$color-black-2:rgba(53, 42, 42, 0.87);

//Font size:
$default-font-size:1.6rem;

//Grid:
$grid-width:114rem;
$gutter-vertical:5rem;
$gutter-vertical-0:2rem;
$gutter-vertical-1:8rem;

$gutter-horizontal:6rem;
$gutter-horizontal-1:5rem;
$gutter-horizontal-0:3rem;
$gutter-horizontal-2:2rem;
$gutter-horizontal-4:1.5rem;
$gutter-horizontal-3:.5rem;
$gutter-horizontal-01:.1rem;
$gutter-horizontal-5:1rem;
$gutter-horizontal-6:11rem;





/* Alert */
.success {
  color:#20a020;
}
.danger{
  color:#a02020;
}
.alert-danger{
  height: fit-content;
  color:#2040a0 !important;
  background-color: rgba(#f2d7d1 , .5)!important;
  padding: 2rem;
  // margin-bottom: 13rem !important;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.alert{
  padding: 2rem;
  border:0.1rem solid transparent;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.alert-info{
  color:#2020a0;
  background-color: #e0e0ff;
}

.alert-success {
   color:#20a020;
  background-color: #eeffe0;
}

.alert-review {
    height: fit-content;
  color:#2040a0 !important;
  background-color: rgba(#f2d7d1 , .2)!important;
   padding: 2rem;
}

.alert-review-update{
  width: 85% !important;
    background-color: rgba($color-secondary-dark, .07);
    color:$color-primary-light;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    // text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 5rem 1rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    margin: 2rem auto;
    box-shadow: 0 1rem 1rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, .1) solid;

    @include respond(phone){
      font-size: 3rem;
      width: 95% !important;
    }
}
