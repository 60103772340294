.composition {
  position: relative;
// border: .1rem red solid;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2rem auto !important;

  @include respond(phone){
    height: 0;
  }

  &__single-pict {
    width: 95%;
    height:80%;
    margin: 3rem auto;


    &:hover {
      outline: .5rem solid rgba($color-white, .5);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.3rem);
      box-shadow: 0 1rem 1rem rgba($color-black, .3);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.3rem);
      -moz-transform:scale(1.1) translateY(-.3rem);
      -ms-transform:scale(1.1) translateY(-.3rem);
      -o-transform:scale(1.1) translateY(-.3rem);
}
  }

  &__podcast {
    width: 100%;
    height:100%;
    // margin-right: 15rem;
    // margin: 3rem auto;
    // padding: -5rem;
    // margin-bottom: -1rem;


    &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.3) translateY(-.3rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.3) translateY(-.3rem);
      -moz-transform:scale(1.3) translateY(-.3rem);
      -ms-transform:scale(1.3) translateY(-.3rem);
      -o-transform:scale(1.3) translateY(-.3rem);
}
  }

  &__photo {
    width: 30rem;
    // min-width: 30rem;
    // max-width: 30rem;
    // height: fit-content;

    min-height: 30rem;
    max-height: 30rem;
    margin: 2rem auto;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
    // border-radius: 1px;
    position: absolute;
    transition:all .2s;
    @include respond(tab-port){
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      transition:all .2s;
      // border: .1rem red solid;
    }
    @include respond(phone){
      width: 0 !important;
      height: 0 !important;
    }

    &--p1 {
      left:3rem;
      top:4rem;
       @include respond(tab-port){
        left:1%;
        top:10%;
        // z-index: 1;
       }
    }

    &--p2 {
      right:6rem;
      top:-1rem;
      z-index: 2;
       @include respond(tab-port){
        left:33%;
        top:-1%;
        // z-index: 1;
       }
    }

    &--p3 {
      left:15rem;
      top:8rem;
       @include respond(tab-port){
        left:60%;
        top:20%;
        // z-index: 1;
       }
    }

    &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.5) translateY(-.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.5) translateY(-.5rem);
      -moz-transform:scale(1.5) translateY(-.5rem);
      -ms-transform:scale(1.5) translateY(-.5rem);
      -o-transform:scale(1.5) translateY(-.5rem);
}

  }

  &:hover &__photo:not(:hover){
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
}
}



.composition-green-and-pink-pict-egg {
  position: relative;
// border: .1rem red solid;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2rem auto !important;
  // background-color: rgba($color-Greenprimary-dark, .08);
  //

  &__single-pict {
    width: 90%;
    height:98%;
    object-fit: fill;
    margin: 3rem auto;
    padding: 3rem;
    border-radius: 7%;
    // border: .35rem rgba($color-white, .4) solid;
    -webkit-border-radius: 7%;
    -moz-border-radius: 7%;
    -ms-border-radius: 7%;
    -o-border-radius: 7%;
    &:hover {
      // outline: .7rem solid rgba($color-white, .3);
      // outline-offset: 2rem;
      //border-radius: 0;
      // border: 1rem rgba(white, .5) solid;
      transform:scale(1.1) translateY(-.3rem);
      box-shadow: 0 .1rem .1rem rgba($color-black, .2);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.3rem);
      -moz-transform:scale(1.1) translateY(-.3rem);
      -ms-transform:scale(1.1) translateY(-.3rem);
      -o-transform:scale(1.1) translateY(-.3rem);
}
  }

}

.composition-create-form-3 {
  position: relative;
// border: .1rem red solid;
  height: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2rem auto ;

  &__single-pict {
    width: 70%;
    height:80%;
    margin: 3rem auto 3rem auto;
    border-radius: 5%;
    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;
    &:hover{
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.3) translateY(-.3rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.3) translateY(-.3rem);
      -moz-transform:scale(1.3) translateY(-.3rem);
      -ms-transform:scale(1.3) translateY(-.3rem);
      -o-transform:scale(1.3) translateY(-.3rem);
}
  }

  &__podcast {
    width: 100%;
    height:100%;
    // margin-right: 15rem;
    // margin: 3rem auto;
    // padding: -5rem;
    // margin-bottom: -1rem;


    &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.3) translateY(-.3rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.3) translateY(-.3rem);
      -moz-transform:scale(1.3) translateY(-.3rem);
      -ms-transform:scale(1.3) translateY(-.3rem);
      -o-transform:scale(1.3) translateY(-.3rem);
}
  }

  &__photo {
    width: 30rem;
    // min-width: 30rem;
    // max-width: 30rem;
    // height: fit-content;

    min-height: 30rem;
    max-height: 30rem;
    margin: 2rem auto;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
    // border-radius: 1px;
    position: absolute;
    transition:all .2s;

    &--p1 {
      left:3rem;
      top:4rem;
    }

    &--p2 {
      right:6rem;
      top:-1rem;
      z-index: 1;
    }

    &--p3 {
      left:15rem;
      top:8rem;
    }

    &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.5) translateY(-.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.5) translateY(-.5rem);
      -moz-transform:scale(1.5) translateY(-.5rem);
      -ms-transform:scale(1.5) translateY(-.5rem);
      -o-transform:scale(1.5) translateY(-.5rem);
}

  }

  &:hover &__photo:not(:hover){
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
}
}

.composition-seller-home-form-5 {
  // position: relative;
// border: .1rem red solid;
object-fit: cover;
  height: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 0 !important;

  &__single-pict {
    width: 100%;
    height:100%;
    margin-top: -3rem;
    // margin: -2rem auto;


    &:hover {
      outline: .3rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.3rem);
      box-shadow: 0 .3rem .3rem rgba($color-black, .3);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.3rem);
      -moz-transform:scale(1.1) translateY(-.3rem);
      -ms-transform:scale(1.1) translateY(-.3rem);
      -o-transform:scale(1.1) translateY(-.3rem);
}
  }

  &__podcast {
    width: 100%;
    height:100%;
    // margin-right: 15rem;
    // margin: 3rem auto;
    // padding: -5rem;
    // margin-bottom: -1rem;


    &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.3) translateY(-.3rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.3) translateY(-.3rem);
      -moz-transform:scale(1.3) translateY(-.3rem);
      -ms-transform:scale(1.3) translateY(-.3rem);
      -o-transform:scale(1.3) translateY(-.3rem);
}
  }

  &__photo {
    width: 30rem;
    // min-width: 30rem;
    // max-width: 30rem;
    // height: fit-content;

    min-height: 30rem;
    max-height: 35rem;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
    // border-radius: 1px;
    position: absolute;
    transition:all .2s;

    &--p1 {
      left:3rem;
      top:4rem;
    }

    &--p2 {
      right:8rem;
      top:-1rem;
      z-index: 1;
    }

    &--p3 {
      left:15rem;
      top:8rem;
    }

    &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.5) translateY(-.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.5) translateY(-.5rem);
      -moz-transform:scale(1.5) translateY(-.5rem);
      -ms-transform:scale(1.5) translateY(-.5rem);
      -o-transform:scale(1.5) translateY(-.5rem);
}

  }

  &:hover &__photo:not(:hover){
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
}
}


.composition-cover-background {
  width: 100vw;
  // position: relative;
border: .1rem red solid;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem auto !important;

  &__background-pict {
    width: 100%;
    height:80%;
    margin: 0 auto;
  }

    // &:hover {
    //   outline: .7rem solid rgba($color-white, .3);
    //   outline-offset: 2rem;
    //   //border-radius: 0;
    //   transform:scale(1.3) translateY(-.3rem);
    //   box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
    //   z-index: 100;
    //   -webkit-transform:scale(1.3) translateY(-.3rem);
    //   -moz-transform:scale(1.3) translateY(-.3rem);
    //   -ms-transform:scale(1.3) translateY(-.3rem);
    //   -o-transform:scale(1.3) translateY(-.3rem);
    // }
  }

//   &__photo {
//     width: 30rem;
//     // min-width: 30rem;
//     // max-width: 30rem;
//     // height: fit-content;

//     min-height: 30rem;
//     max-height: 35rem;
//     box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
//     // border-radius: 1px;
//     position: absolute;
//     transition:all .2s;

//     &--p1 {
//       left:3rem;
//       top:4rem;
//     }

//     &--p2 {
//       right:8rem;
//       top:-1rem;
//       z-index: 1;
//     }

//     &--p3 {
//       left:15rem;
//       top:8rem;
//     }

//     &:hover {
//       outline: .7rem solid rgba($color-white, .3);
//       outline-offset: 2rem;
//       //border-radius: 0;
//       transform:scale(1.5) translateY(-.5rem);
//       box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
//       z-index: 100;
//       -webkit-transform:scale(1.5) translateY(-.5rem);
//       -moz-transform:scale(1.5) translateY(-.5rem);
//       -ms-transform:scale(1.5) translateY(-.5rem);
//       -o-transform:scale(1.5) translateY(-.5rem);
//     }



//   &:hover &__photo:not(:hover){
//     transform: scale(.9);
//     -webkit-transform: scale(.9);
//     -moz-transform: scale(.9);
//     -ms-transform: scale(.9);
//     -o-transform: scale(.9);
//   }
// }


