.background-box-header{
   background-color: rgba($color-white, .75);
  box-shadow: 2rem 2rem 1rem rgba($color-black, .3);
}
 .background-box-header--inner{
    // border: 3rem red solid;
    box-shadow: 2rem 2rem 1rem rgba($color-black, .3);
    color: rgba($color-primary-light, 1) !important;
    background-color: rgba($color-black, .55) !important;
  }


.background-header-skeleton{
  background-color: rgba($color-white, .40);
  width: 90% !important;
  margin: 10rem !important;
  padding: 5rem !important;
}

.background-header-categories{
  background-color: rgba($color-white, .01);
   color: rgba($color-white, 1) !important;
   box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  text-align: center;
  letter-spacing: .2rem;
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  //width: 50rem !important;
  //height: 50rem !important;
  //margin: 5rem auto !important;
  padding: 35% .5rem 30% .5rem !important;

  // padding: 5rem auto 5rem auto !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.background-box-main-home-categories-section{
  box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
  color: rgba($color-primary-light, 1) !important;
  font-weight: 700 !important;

  padding: .3rem ;
  background-color: rgba($color-tertiary-dark, .01) !important;
}

.background-box-main-home-main-carousel{
  width: 100%;
   background-color: rgba($color-white, 1);
  box-shadow: 1rem 1rem 1rem rgba($color-black, .01);
}

.background-box-main-home-main-carousel-special-shape{
  //  background-color: rgba($color-white, .1);
  // box-shadow: 1rem 1rem 1rem rgba($color-black, 1);
  // border: .5rem red solid;
  width: 95% !important;
  height: 100% !important;
   clip-path: polygon(0 20%, 85% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -5rem;

  z-index: 1;
    // background-color: rgba($color-tertiary-dark, .91);
    background-image: linear-gradient(to left, rgba(34, 44, 157, 0.4),
     rgba(34, 44, 157, 0.4));
  position: absolute;
  top: 0;
  // margin-top: 5rem;
  @include respond(big-desktop){
      width: 100% !important;
  height: 80vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -10rem;
    //  border: .5rem red solid;
  }
  @include respond(big-desktop){
      width: 100% !important;
  height: 70vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -5rem;
    //  border: .5rem red solid;
  }
  @include respond(tab-land){
      width: 100% !important;
  height: 70vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -3rem;
    //  border: .5rem red solid;
  }
  @include respond(tab-port){
      width: 100% !important;
  height: 55vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -5rem;
    //  border: .5rem red solid;
  }
   @include respond(phone){
      width: 100% !important;
  height: 28vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -4% !important;
  // padding-top: 5rem !important;
    //  border: .5rem blue solid;
  }
   @include respond(phone-500){
      width: 100% !important;
  height: 24.5vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -4% !important;
  // padding-top: 5rem !important;
    //  border: .5rem red solid;
  }
  @include respond(small-phone){
      width: 100% !important;
  height: 22.5vh  !important;
   clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
  // padding: 5rem !important;
  margin-top: -5% !important;
  // padding-top: 5rem !important;
    //  border: .5rem red solid;
  }

}

.background-skelton-main-loading{
  background-color: rgba($color-white, .40);
  width: 90% !important;
  margin: 10rem !important;
  padding: 5rem !important;
}



   .background-category-subs-category-page {
    width: 80%;
    margin:  8rem auto;
    padding: 5rem ;
    // background-image:
    //   linear-gradient( to right bottom,
    //   rgba($color-white, .3) ,  rgba($color-white, .4) );
    background-color: rgba($color-black, .028);
    }

.background-customer-pict-upload{
  // background-color: rgba(248, 246, 244,.12);
  background-color: rgba($color-black, .03);
  // box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
  width: 45%;
  // height: 75% !important;
    margin: 2rem auto 1rem 25%!important;
  padding: 37ren auto 55rem auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
  justify-items: center;

  &__upload{
    width: 50%;
    margin: 0 auto;
    background-color: rgba(248, 246, 244,.3);
  }
}


.background-box-shadow{
  box-shadow: 2rem 2rem 1rem rgba($color-black, .3);
  color: rgba($color-primary-light, 1) !important;
  background-color: rgba($color-white, .55) !important;
}

.background-box-shadow2{
   background-color: rgba($color-white, .75);
  box-shadow: 2rem 2rem 1rem rgba($color-black, .3);
}


.background-white{
  background-color: rgba($color-white, .35);
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 10rem;
  padding-top: 4rem !important;
  padding-bottom: .5rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.background-white-single-product-related-products{
  background-color: rgba($color-white, .03);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: -1rem;
  padding-top: 3rem !important;
  padding-bottom: -4rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.background-white-categories-list{
  background-color: rgba($color-white, .25);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding-top: .1rem !important;
  padding-bottom: .1rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 7%;
  -webkit-border-radius: 7%;
  -moz-border-radius: 7%;
  -ms-border-radius: 7%;
  -o-border-radius: 7%;
}


.background-white-single-product-display {
  // background-color: rgba($color-white, .7);
  background-color: rgba($color-white, .4);
  width: 97%;
  margin: 3rem auto !important;
  // margin-left: 1rem;
  // margin-right: 1rem;
  // margin-bottom: 5rem;
  // margin-top: 5rem;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  padding-left: .1rem !important;
  padding-right: .1rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.background-whiteish{
  background-color: rgba($color-white, .40);
}

.background-whiteish-shop{
  background-color: rgba($color-white, .40);
  width: 90% !important;
  margin: 10rem !important;
  padding: 5rem !important;
}



.background-whiteish-2{
  background-color: rgba($color-tertiary-dark, .05);
  width: 95% !important;
  margin: 5rem auto;
  padding: 3rem ;
  border: .1rem rgba($color-black, .08) solid;

  // padding: 15rem 5rem 5rem -5rem !important;

}

.background-blueish{
  background-color: rgba($color-white, .15) !important;
  // border: rgba($color-white, 1) .2rem solid;
}

.background-whiteish-single-product{
  // margin:1rem;
  padding:5rem .1rem;
  // background-color: rgba($color-white, .15);
}

.color-background{
  // width: 100rem;
  background-color: rgba($color-black, .005);
  border: .1rem solid rgba($color-black, .07);
  margin: 2rem auto !important;
  &:not(:last-child){
    margin-right: 3rem;
  }
  // margin-top: 2rem;
  // margin-bottom: 2rem ;
  padding: 3rem;
  @include respond(phone){
    height: 55vh;
  }
}

.product-items-flex{
  width: 90%;
  display: flex;
  justify-content: space-around !important;
  justify-items: center;
  background-color: rgba($color-white, .9);
  border: .1rem solid rgba($color-black, .1);
  box-shadow: .2rem .2rem .1rem rgba($color-black, .1);
  color: rgba($color-primary-light, 1) !important;
  font-size: 1.4rem !important;
  // &:not(:last-child){
  //   margin-right: 3rem;
  // }
   margin: -2rem auto 0 auto;
  padding: 1rem 5rem 1rem 0 !important;

  @include respond(phone){
    width: 100%;
    display: flex;
    justify-content: space-around !important;
    justify-items: center;
    font-size: 4rem !important;
     padding: 4rem 15rem 4rem 0 !important;
    // border: .2rem red solid;
  }
  &__add-to-cart{
     display: flex;
    // border: .2rem red solid;
  // width: fit-content !important;
   margin: -1rem 1rem !important;
   color: rgba($color-primary-light, 1) !important;
  //  font-size: 1rem !important;
  display: flex;
  justify-content: space-around !important;
  justify-items: center;
  background-color: rgba($color-white, .7);
  border: .1rem solid rgba($color-black, .07);
  padding: .5rem 1rem .5rem 1rem !important;
  @include respond(phone){
  padding: 3rem 1rem 3rem 1rem !important;
  margin-top: -2rem !important;

  }
  }

  &__to-see-price{
    text-align: center;
    text-transform: none;
    font-size: 1.6rem;
    @include respond(phone){
      font-size: 4rem;

    }
  }
}

.font-add-to-wish{
   font-size: 1.4rem !important;
   font-weight: 400;
  text-transform: none !important;
  color: rgba($color-primary-light, 1) !important;
  // justify-content: space-around;
  // border: .2rem red solid;
  @include respond(phone){
  width: 33% !important;
    font-size: 4rem !important;
  }
}

.background-single-product-description{
  width: 100% !important;
  height: fit-content !important;
  background-color: rgba($color-white, .4) !important;
  border: .1rem solid rgba($color-black, .15);
  margin: 2rem 0 !important;
  // &:not(:last-child){
  //   margin-right: 3rem;
  // }
  // margin-top: 2rem;
  // margin-bottom: 2rem ;
  padding: 3rem;

}

.background-single-product-review{
  width: 100% !important;
  height: fit-content !important;
  background-color: rgba($color-white, .3) !important;
  border: .1rem solid rgba($color-black, .2);
  margin: 2rem 5rem !important;
  padding: 0;

  @include respond(phone){
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0;
  }
}

.background-single-product-description--outside{
   width: 95% !important;
    background-color: rgba($color-tertiary-dark, .02);
    margin: auto;
    margin-bottom: 10rem;
    padding: 2rem !important;
    // font-size: 15rem !important;

}

// .background-single-product-reviews{
//   width: 90% !important;
//   height: fit-content !important;
//   box-shadow: 0 .5rem .5rem rgba($color-white, .1);
//   border: .1rem solid rgba($color-black, .3);
//   margin: 2rem auto 10rem auto !important;
//   padding: 2rem 5rem;
// }

.ant-tabs-nav-wrap{
  width: 100% !important;
  height: fit-content !important;
  padding: 1.2rem 3rem;
  // color: rgba(red, 1) !important;
  background-color: rgba($color-black, .015);
  // border-bottom: rgba($color-black, .04) .2rem solid;

}

 .ant-tabs, .ant-tabs-tab{
  padding: 5rem auto !important;
  color: rgba($color-primary-light, 1) !important;
  letter-spacing: .1rem;
  font-size: 1.3rem;
  // background-color: rgba($color-white, .5);
  border: .2rem rgba($color-black, .04) solid;
}

 .ant-tabs{
  padding: 5rem auto !important;
  color: rgba($color-primary-light, 1) !important;
  letter-spacing: .1rem;
  font-size: 1.3rem;
  // background-color: rgba($color-white, .5);
  border: .2rem rgba($color-black, .04) solid;
}

.ant-tabs-tab{
  padding: 1.5rem 3rem !important;
  margin-right: -2rem !important;
  color: rgba($color-primary-light, 1) !important;
  letter-spacing: .1rem;
  font-size: 1.5rem;
  background-color: rgba($color-white, .4) !important;
  border: .2rem rgba($color-black, .4) solid;
}



 .ant-tabs-tab-active{
  width: fit-content;
  // padding-left: 5rem;

  // padding-left: 3rem !important;
  // padding-right: 3rem !important;
  // box-shadow: none !important;
  // border: white solid .1rem !important;
  outline: none !important;
  // font-size: 2.2rem !important;
  // color: rgba($color-secondary-dark, 1) !important;
  background-color: rgba($color-white, .8) !important;
  font-weight: 600;
  // border-bottom: rgba($color-white, .5) .7rem solid !important;
    z-index: 10000;
}

// .ant-tabs, .ant-tabs-tab, .ant-tabs-ink-bar {
//   color: red;
// }

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:rgba($color-primary-light, 1);
  // background-color: rgba($color-white, .2);
  border-bottom: whit solid 1rem;
  // margin-bottom: -1rem;
  // outline-width: 2rem;
}

.ant-modal-body{
  padding-top: 3rem  !important;
}
.ant-tabs-nav-list{
  font-size: 1rem;
}

.background-seller-public-products-new-and-best{
  width: 95%;
  height: fit-content;
  margin: 5rem auto;
  padding: 10rem auto !important;
  background-color: rgba($color-tertiary-dark, .04);
  // border: .1rem red solid;
}
