.horizontalSlider {
  position: relative;
  overflow: hidden;

  [dir='rtl'] & {
    direction: ltr;
    transform: scaleX(-1);
  }

  &Tray {
    overflow: hidden;
    width: 100%;
  }
}

.verticalSlider {
  position: relative;
  overflow: hidden;

  &Tray {
    overflow: hidden;
  }
}

.verticalTray {
  float: left;
}

.verticalSlideTrayWrap {
  overflow: hidden;
}

.sliderTray {
  display: block;
  list-style: none;
  padding: 0 ;
  margin: 0;
}

.sliderAnimation {
  transition: transform 500ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
  will-change: transform;
}

.masterSpinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4;
}

.slider img {
  object-fit: fill !important;
  width: fit-content;
  height:72rem !important;
  // padding-bottom: -55rem;
  // max-height:65rem;
  border-radius: .5rem;
  overflow: hidden;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
  //  box-shadow: 0 5rem 2rem rgba($color-black, .3);
  //  border: 1rem yellow solid;
}

.thumbnails-wrapper {
  padding: 0rem;
  margin: 5rem 0 3rem 0;
  box-shadow: 2rem 3rem 2rem rgba($color-black, .1);
  // border: .3rem rgba($color-grey-light-1, .7) solid;
}

.thumbnails-wrapper--single-product {
  height: 100% !important;
  width: 100% !important;
  min-height: 75rem !important;
  max-height: 75rem !important;
  // padding: 2rem 3rem 0 3rem;
  padding: 0 1rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  // margin: 1rem auto ;
  margin-top: -3rem !important;
  overflow: hidden;
  background-color: rgba($color-secondary-dark, .1);
  box-shadow: .3rem .3rem .3rem rgba($color-black, .2);
  border: .2rem white solid;
  // .thumb{
  //   height: 7rem;
  //   object-fit: contain !important;
  // }
}

.thumbnails-wrapper--single-product-carousel {
  position: relative;

    max-width: 90rem !important;
    // display: flex;
    // flex-direction: row !important;
    // justify-content: flex-start;
    // justify-items: center;
    // flex-wrap: wrap;
    //min-height: 65rem !important;
    // height: fit-content !important;
    max-height: 70rem !important;
    // margin-bottom: 2rem !important;
    //padding: 2rem 3rem 0 3rem;
    // padding:.5rem auto!important;
    //padding-top: 1rem !important;
    //padding-bottom: -3rem !important;
    //margin: 1rem auto ;
    margin: -5rem 0 13rem -12rem !important;
    // overflow: hidden;
    //background-color: rgba($color-secondary-dark, .1);
    // box-shadow: .3rem .3rem .3rem rgba($color-black, .2);
    // border: .2rem red solid;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    .slide{
    position: absolute;
  //  padding: -5rem 0 13rem -12rem !important;
    width: 100% !important;
    // max-width: 85rem !important;
    // height: 100% !important;
    object-fit: fill !important;

    // padding: 2rem 0 !important;
    // max-height: 70rem !important;
    height: 100% !important;
    // padding-top: 1rem !important;
    //width: 100% !important;;
    //min-height: 55rem !important;
    //max-height: 75rem !important;
    // border: .2rem red solid;
    // border-radius: 15% !important;
    // -webkit-border-radius: 15% !important;
    // -moz-border-radius: 15% !important;
    // -ms-border-radius: 15% !important;
    // -o-border-radius: 15% !important;
}
  .thumb{
    min-height: 5.5rem;
    // max-height: 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    justify-items: center !important;
    // max-height: 5rem !important;
    object-fit: fill !important;
    margin-top: .2rem;
    //padding-top: -1rem;
    // border: none!important;
    border-radius:5% !important;
    //z-index: 10000 !important;
    //-webkit-border-radius:85% !important;
    //-moz-border-radius:85% !important;
    //-ms-border-radius:85% !important;
    //-o-border-radius:85% !important;
    // -webkit-border-radius:5% !important;
    // -moz-border-radius:5% !important;
    // -ms-border-radius:5% !important;
    // -o-border-radius:5% !important;
}
}

.ul {
  background-color: red;
}
