

/* export const Box = styled.div` */
.box{
  background-size: cover;
  // background-position: bottom;
  margin-top: -4rem;
  // margin-bottom: -5rem !important;
  // position: relative !important;

  padding: 8rem 6rem;
  background-color: #131A22;
  position: relative;
  // bottom: -1%;
  width: 100%;
// border: .3rem yellow solid;
}


/* @media (max-width: 1000px) {
	padding: 70px 30px;
} */


// .container{
//   width: 100vw;
//   height: 30vh;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	max-width: 1000px;
// 	margin: 0 auto;
//   position: relative;


// }
  // bottom: 30vh;
	/* background: red; */


.column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill,
              minmax(185px, 1fr));
  grid-gap: 20px;

  @media row  {
    max-width: 1000px;
    grid-template-columns: repeat(auto-fill,
              minmax(200px, 1fr));
  }
}

.footLink {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
}
  .footLink:hover {
  color: rgba($color-tertiary-light,1);
    transition: 200ms ease-in;
}


.footer-header{
   display: flex;
   justify-content: center;
   margin-bottom: 5rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  background-clip:text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;
  text-align: center;


}

.footer-copyRight{
  width: 100%;
  border-top: .1rem rgba($color-grey-light-1, 1) solid;
  text-align: center;
  margin-top: 5rem;
  padding-top: 5rem;
  color: rgba($color-grey-light-1,.8);
}
