  .loading-main-home-carousel-categories-products-display-title {
    width: 70rem;
    color: $color-tertiary-light;
    //font-weight: 700;
    background-color: rgba($color-white, .5);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 2rem ;
    margin: 2rem auto;
    // border-bottom: .1rem rgba($color-tertiary-dark, .5) solid;
    border-radius: .5rem;
    text-transform: capitalize;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    // animation: moveInRight 1s ;
    // -webkit-animation: moveInRight 1s ;
  }
