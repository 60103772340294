
// .admin-dashboard-order-info {

//   background-color:black ;


//    &__heading-secondary {
//     display: inline-block;
//     font-size: 3.6rem;
//     text-transform: uppercase;
//     font-weight: 500;
//     background-clip:text;
//     -webkit-background-clip: text;
//     // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
//     color: $color-primary-light-1;
//     // letter-spacing: .2rem;
//     transition: all .2s;

//     &:hover {
//       transform: skewY(2deg) skewX(15deg) scale(1.1);
//       text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
//     }
//   }

// }

.admin-dashboard-order-info {
  &:link,
  &:visited {
    display: inline-block;
    text-transform: uppercase;
    // text-decoration: none;
    font-size: 1.6rem;

    padding: 1rem 2rem;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    -webkit-transition: all .2s;
    // box-shadow: 0 1rem 2rem rgba($color-black,.2);

  }
}
  // &:hover {
    // transform: translateY(-.3rem);
    // -webkit-transform: translateY(-.3rem);

    // box-shadow: 0 1rem 2rem rgba($color-black,1);

    //  &::after {
    //   transform: scaleX(1.4) scaleY(1.6);
    //   -webkit-transform: scaleX(1.4) scaleY(1.6);
    //   opacity: 0;

    // }
  // }

//   &:active,
//   &:focus {
//     //outline: none;
//     transform: translateY(-1rem);
//     -webkit-transform: translateY(-1rem);
//     box-shadow: 0 .5rem 1rem rgba($color-black, .2);
//     -moz-transform: translateY(-1rem);
//     -ms-transform: translateY(-1rem);
//     -o-transform: translateY(-1rem);
// }







