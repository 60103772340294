/*
0 - 600px: Phone
600px - 900px: Tablet portrait
900px - 1200px: Tablet landscape
[1200px - 1800px] is where our normal styles apply
1800px + : Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px


*/
/* Alert */
.success {
  color: #20a020;
}

.danger {
  color: #a02020;
}

.alert-danger {
  height: fit-content;
  color: #2040a0 !important;
  background-color: rgba(242, 215, 209, 0.5) !important;
  padding: 2rem;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.alert {
  padding: 2rem;
  border: 0.1rem solid transparent;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}

.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}

.alert-review {
  height: fit-content;
  color: #2040a0 !important;
  background-color: rgba(242, 215, 209, 0.2) !important;
  padding: 2rem;
}

.alert-review-update {
  width: 85% !important;
  background-color: rgba(255, 119, 48, 0.07);
  color: #0e55b3;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5rem;
  padding: 5rem 1rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  margin: 2rem auto;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
}
@media (max-width: 37.5em) {
  .alert-review-update {
    font-size: 3rem;
    width: 95% !important;
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
    -webkit-transform: translateX(10rem);
    -moz-transform: translateX(10rem);
    -ms-transform: translateX(10rem);
    -o-transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
    -webkit-transform: translateX(-1rem);
    -moz-transform: translateX(-1rem);
    -ms-transform: translateX(-1rem);
    -o-transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
    -webkit-transform: translateY(3rem);
    -moz-transform: translateY(3rem);
    -ms-transform: translateY(3rem);
    -o-transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
  }
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media (max-width: 112.5em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 30%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 25%;
  }
}
@media (max-width: 23.4375em) {
  html {
    font-size: 20%;
  }
}
@media (min-width: 112.51em) {
  html {
    font-size: 75%;
  }
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /* font-size: 1.6rem; */
  line-height: 1.7;
  color: #777;
  box-sizing: border-box;
}
@media (max-width: 56.25em) {
  body {
    padding: 0;
  }
}

.new-heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin-bottom: -2rem;
  margin-top: -2rem;
}
.new-heading-primary--header-domain-logged-out {
  display: block;
  font-size: 8rem;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.new-heading-primary--header-slogen-logged-out {
  display: flex;
  margin-top: -1rem;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  font-weight: bolder;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.new-heading-primary--main-logo-domain-logged-in {
  display: block;
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0.5rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.new-heading-primary--sub-logo-slogen-logged-in {
  display: block;
  justify-content: center !important;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.35rem;
  margin-bottom: 2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.new-heading-primary--home-categories-title {
  color: #fff;
  background-color: rgba(6, 57, 112, 0.3) !important;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 1.5rem 1rem;
  border-bottom: 0.1rem rgba(255, 255, 255, 0.9) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s;
  -webkit-animation: moveInRight 1s;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin-bottom: -2rem;
  margin-top: -2rem;
}
.heading-primary--no-product-found {
  width: fit-content;
  padding: 3rem 15rem;
  margin: 1rem auto;
  font-size: 2.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  color: white;
  background-color: rgba(247, 247, 247, 0.02);
}
.heading-primary--main {
  display: block;
  font-size: 6rem;
  font-weight: 500;
  letter-spacing: 2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
@media (max-width: 37.5em) {
  .heading-primary--main {
    letter-spacing: 1rem;
    font-size: 5rem;
  }
}
.heading-primary--header-loggedout {
  display: block;
  font-size: 6rem;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /*animation-delay: 3s;
  */ /*animation-iteration-count: 3;
*/
}
.heading-primary__loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 10rem;
  padding: 25rem;
  font-size: 4rem;
  font-weight: 500;
  color: white;
  letter-spacing: 2rem;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--canceled {
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  justify-items: center !important;
  color: #085eaf !important;
  background-color: rgba(247, 247, 247, 0.25);
  font-size: 2rem;
  font-weight: 500;
  padding: 15rem auto !important;
  margin-bottom: 25rem;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--create-product {
  display: flex;
  font-size: 3rem;
  font-weight: 500;
  margin: auto;
  padding: 2rem 5rem;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  justify-content: center;
  justify-items: center;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--pressRelease {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: justify;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-logo-title {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0.5rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-seller-name-description {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: start;
  text-transform: capitalize;
  text-decoration: none;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-seller-name-description:first-child {
  margin-top: 5rem;
}
@media (max-width: 37.5em) {
  .heading-primary--main-seller-name-description {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.heading-primary--seller-create-product {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  color: #0e55b3;
  background-color: rgba(255, 119, 48, 0.13) !important;
  text-align: start;
  text-transform: capitalize;
  text-decoration: none;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--seller-create-product:first-child {
  margin-top: 5rem;
}
.heading-primary--main-seller-profile-name-description {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #0e55b3;
  background-color: rgba(86, 67, 250, 0.03);
  text-align: start;
  text-transform: capitalize;
  text-decoration: none;
  padding: 1rem;
  margin: 0.2rem;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-seller-box {
  width: 100%;
  height: 43% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.7);
  margin-left: -1rem;
  padding: 1rem 0.1rem 1.5rem 0.1rem !important;
  font-size: 1.4rem;
  font-weight: 400 !important;
  letter-spacing: 0.2rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--main-2 {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 1rem;
  padding: 2rem 2rem 2rem 3rem;
  margin-left: -2rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--discount-product {
  width: 80%;
  display: flex;
  justify-content: center !important;
  margin: 2rem auto;
  color: #0e55b3;
  font-size: 1.4rem;
  font-weight: 500;
}
.heading-primary--discount-product__bold {
  color: red;
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.heading-primary--discount-product__background {
  background-color: rgba(255, 119, 48, 0.03);
  padding: 1rem;
}
.heading-primary--user-column {
  display: block;
  color: #085eaf;
  background-color: rgba(86, 67, 250, 0.1);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  padding: 1rem 1rem 1rem 2rem;
  margin-right: 8rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--seller-column {
  display: flex;
  color: #085eaf;
  background-color: rgba(86, 67, 250, 0.02);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.5rem !important;
  font-weight: 700;
  letter-spacing: 0.3rem;
  padding: 1rem 1.5rem;
  margin-right: 1.7rem;
  margin-bottom: 0.7rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--seller-column {
    display: flex;
    margin-bottom: 0 !important;
    padding-bottom: 0.2rem;
    margin-right: 0.5rem;
  }
}
.heading-primary--seller-column__row {
  margin-left: 0.5rem;
  font-size: 1.7rem !important;
}
@media (max-width: 37.5em) {
  .heading-primary--seller-column__row {
    font-size: 3rem !important;
    margin-bottom: 0.3em;
    margin-top: -1rem;
    padding-top: -0.5rem !important;
  }
}
.heading-primary--seller-home-header {
  display: flex;
  color: #085eaf;
  background-color: rgba(86, 67, 250, 0.07);
  border: 0.1rem solid rgba(86, 67, 250, 0.3);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  padding: 0.1rem 2rem 0.1rem 1rem !important;
  margin-top: 0.2rem !important;
  margin-bottom: 0.2rem !important;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--seller-home-header__row {
  margin-left: 0.8rem !important;
}
.heading-primary--seller-home-header__margin-right {
  margin-right: 0.8rem !important;
}
.heading-primary--admin-column {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 1rem;
  padding: 2rem 2rem 2rem 3rem;
  margin-left: -2rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--seller-public-coupon {
  display: block;
  color: #04399c;
  background-color: rgba(255, 255, 255, 0.55);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  padding: 1rem 2rem 2rem 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-checkout-summary {
  display: block;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.5rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  padding: 2rem 2rem 2rem 3rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-checkout-summary {
    font-size: 2.5rem;
  }
}
.heading-primary--main-2-checkout-summary__empty-cart {
  margin: 3rem auto auto auto;
}
.heading-primary--main-2-product-card-sub-category {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom: 0.15rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin-top: 1.3rem;
  padding: 1rem 0.1rem 1rem 2rem;
  margin-left: -1rem;
  box-shadow: 1rem 0.5rem 1rem rgba(0, 0, 0, 0.4);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-product-card-sub-category {
    font-size: 2.5rem;
  }
}
.heading-primary--main-2-product-card-coupon {
  width: fit-content !important;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(255, 119, 48, 0.3) !important;
  border-right: 0.1rem solid rgba(247, 247, 247, 0.7882352941);
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem !important;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin: 20.5rem auto 2rem auto;
  padding: 1rem 0.1rem 1rem 1rem;
  margin-left: 1rem;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-product-card-coupon {
    font-size: 3.5rem !important;
    margin: 50.5rem auto 2rem 5rem !important;
  }
}
.heading-primary--main-2-product-card-coupon-home-page {
  width: fit-content !important;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(255, 119, 48, 0.3) !important;
  border-right: 0.1rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem !important;
  font-weight: 700;
  letter-spacing: 0.3rem;
  margin: 11rem auto 2rem auto;
  padding: 1rem 0.1rem 1rem 1rem;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-product-card-coupon-home-page {
    font-size: 3rem !important;
    font-weight: 700;
    margin: 19.5rem auto 2rem 13rem !important;
    border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  }
}
.heading-primary--main-2-product-card-brand {
  width: fit-content !important;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.15) !important;
  font-size: 1.7rem !important;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin: 9rem auto 2rem auto;
  padding: 0.3rem 0.2rem 0.3rem 0 !important;
  margin-left: -1rem;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-product-card-brand {
    font-size: 3rem !important;
  }
}
.heading-primary--main-2-product-card-coupon-back-side {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(255, 119, 48, 0.3) !important;
  border-bottom: 0.1rem solid rgba(247, 247, 247, 0.7882352941);
  border-right: 0.1rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.7rem !important;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin: 11rem auto 2rem 60% !important;
  padding: 0.8rem 1rem 0.8rem -2rem;
  box-shadow: 1rem 0.5rem 1rem rgba(255, 255, 255, 0.5);
  z-index: 100;
}
@media (max-width: 112.5em) {
  .heading-primary--main-2-product-card-coupon-back-side {
    margin-left: 40%;
  }
}
@media (max-width: 75em) {
  .heading-primary--main-2-product-card-coupon-back-side {
    margin-left: 40%;
  }
}
@media (max-width: 56.25em) {
  .heading-primary--main-2-product-card-coupon-back-side {
    margin-left: 60%;
  }
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-product-card-coupon-back-side {
    margin-left: 60%;
    margin-top: 14rem !important;
    font-size: 4rem !important;
  }
}
.heading-primary--main-2-product-card-coupon-home-page-back-side {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(255, 119, 48, 0.3) !important;
  border-bottom: 0.1rem solid rgba(247, 247, 247, 0.7882352941);
  border-right: 0.1rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.7rem !important;
  font-weight: 600;
  letter-spacing: 0.3rem;
  margin: 4rem auto 2rem 60% !important;
  padding: 0.8rem 1rem 0.8rem -2rem;
  box-shadow: 1rem 0.5rem 1rem rgba(255, 255, 255, 0.5);
  z-index: 100;
}
@media (max-width: 112.5em) {
  .heading-primary--main-2-product-card-coupon-home-page-back-side {
    margin-left: 40%;
  }
}
@media (max-width: 75em) {
  .heading-primary--main-2-product-card-coupon-home-page-back-side {
    margin-left: 40%;
  }
}
@media (max-width: 56.25em) {
  .heading-primary--main-2-product-card-coupon-home-page-back-side {
    margin-left: 60%;
  }
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-product-card-coupon-home-page-back-side {
    margin-left: 60%;
    margin-top: 5rem !important;
    font-size: 3rem !important;
  }
}
.heading-primary--main-2-product-card-home-category-3-products-small {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 0.15rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-top: -1rem;
  padding: 2rem 2rem 1rem 3rem;
  box-shadow: 1rem 0.5rem 1rem rgba(0, 0, 0, 0.4);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--main-2-video-card-title {
  position: absolute;
  top: 10%;
  left: 5%;
  display: block;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  margin-top: -2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--main-2-video-card-title__empty-cart {
  margin: 3rem auto auto auto;
}
.heading-primary--main-2-video-filter {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  text-align: center;
  margin-top: -2rem;
  padding: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--main-2-successful-payment-order-details {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.3);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  padding: 2rem 2rem 2rem 3rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--main-2-successful-payment-order-details__empty-cart {
  margin: 3rem auto auto auto;
}
.heading-primary--main-2-profile-welcome {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  padding: 2rem 1rem 2rem 1rem;
  margin-left: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary__view-seller-menu {
  width: 0;
  height: 0;
  opacity: 0;
}
@media (max-width: 37.5em) {
  .heading-primary__view-seller-menu {
    width: fit-content;
    height: fit-content;
    opacity: 1;
    display: flex;
    flex-direction: column;
    color: #0e55b3;
    font-size: 3rem;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
    margin: 5rem !important;
    padding: 3rem;
  }
  .heading-primary__view-seller-menu:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
  }
}
.heading-primary--main-2-create-product {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 0.5rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 1rem;
  padding: 2rem 2rem 2rem 3rem;
  margin-left: -2rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.1);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-admin-all-products-title {
  width: 75%;
  display: block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  text-align: center;
  padding: 3rem 0rem;
  margin: 5rem auto 10rem auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.12);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-admin-nav {
  width: 99%;
  height: fit-content;
  display: block;
  justify-content: space-between;
  background-color: white;
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  text-align: start;
  text-decoration: none;
  padding: 10rem 0 10rem 1rem;
  margin: 5rem auto 1rem 0.5rem;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-admin-nav {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.heading-primary--main-2-admin-nav__phone {
  width: 0;
  height: 0;
  opacity: 0;
  position: relative;
  top: -30rem;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-admin-nav__phone {
    width: 99%;
    height: fit-content;
    opacity: 1;
    justify-content: space-around;
    background-color: white;
    border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.3rem;
    text-align: start;
    text-decoration: none;
    padding: 5rem 0 5rem 1rem;
    margin: 10rem auto 1rem 0.5rem;
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }
}
.heading-primary--main-2-admin-nav__phone-seller {
  width: 0;
  height: 0;
  opacity: 0;
  position: relative;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-admin-nav__phone-seller {
    width: 65%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    opacity: 1;
    justify-content: space-around;
    background-color: white;
    border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
    letter-spacing: 0.3rem;
    text-align: start;
    text-decoration: none;
    padding: 5rem 0 0.5rem 1rem;
    margin: 0.5rem auto 0.1rem 0.5rem;
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }
}
.heading-primary--main-2-admin-nav-wishlist {
  width: 99%;
  height: fit-content;
  display: block;
  justify-content: space-between;
  background-color: white;
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  text-align: start;
  text-decoration: none;
  padding: 10rem 0 10rem 1rem;
  margin: 5rem auto 1rem 0.5rem;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-admin-nav-wishlist {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.heading-primary--main-2-admin-nav-wishlist__phone {
  width: 0;
  height: 0;
  opacity: 0;
  position: relative;
  top: -30rem;
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-admin-nav-wishlist__phone {
    width: 99%;
    height: fit-content;
    display: flex;
    opacity: 1;
    background-color: white;
    border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.3rem;
    text-align: start;
    text-decoration: none;
    padding: 5rem 0 5rem 1rem;
    margin: 15rem auto 1rem 0.5rem;
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }
}
.heading-primary--main-single-product-nav {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  justify-items: center;
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  letter-spacing: 0.3rem;
  text-align: start;
  text-decoration: none;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-single-product-nav:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.02) translateY(-0.1rem);
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.02) translateY(-0.1rem);
  -moz-transform: scale(1.02) translateY(-0.1rem);
  -ms-transform: scale(1.02) translateY(-0.1rem);
  -o-transform: scale(1.02) translateY(-0.1rem);
}
.heading-primary--main-seller-public-profile-column-nav {
  width: fit-content;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  text-align: start;
  text-decoration: none;
  padding: 3rem 0 3rem 1rem;
  margin: -7rem -1rem 2rem 0 !important;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-seller-public-profile-column-nav:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.02) translateY(-0.1rem);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.02) translateY(-0.1rem);
  -moz-transform: scale(1.02) translateY(-0.1rem);
  -ms-transform: scale(1.02) translateY(-0.1rem);
  -o-transform: scale(1.02) translateY(-0.1rem);
}
.heading-primary--main-seller-single-product-column-nav {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  text-align: start;
  text-decoration: none;
  padding: 8rem 0 3rem 1rem;
  margin: 5rem 1rem 5rem 0 !important;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
@media (max-width: 37.5em) {
  .heading-primary--main-seller-single-product-column-nav {
    width: 100vw;
    display: flex;
    flex-direction: row !important;
    margin-top: 3rem !important;
    margin-left: 10rem;
    margin-right: 10rem;
    padding-top: 5rem;
  }
}
.heading-primary--main-seller-single-product-column-nav:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.02) translateY(-0.1rem);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.02) translateY(-0.1rem);
  -moz-transform: scale(1.02) translateY(-0.1rem);
  -ms-transform: scale(1.02) translateY(-0.1rem);
  -o-transform: scale(1.02) translateY(-0.1rem);
}
.heading-primary--main-2-cart-summary {
  width: 80%;
  height: fit-content;
  display: block;
  justify-content: space-between;
  border-bottom: 0.2rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  text-align: start;
  text-decoration: none;
  padding: 0.5rem 3rem 0.5rem 3rem;
  margin: 5rem auto 1rem auto;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
@media (max-width: 37.5em) {
  .heading-primary--main-2-cart-summary {
    width: 100%;
  }
}
.heading-primary--main-2-all-product {
  width: 100%;
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  font-size: 2rem;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-podcast-video {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.3rem;
  padding: 2rem 2rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--seller-home-podcast-video {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.3rem;
  padding: 0 auto;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-sub-category {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.3rem;
  padding: 2rem auto;
  margin-bottom: 3rem;
  margin-right: 0rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-all-product-title {
  display: block;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.05rem solid rgba(247, 247, 247, 0.7882352941);
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  padding: 2rem 0;
  margin: 5rem 12rem 10rem 8rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-main-shop-page-title {
  width: fit-content;
  display: block;
  height: fit-content;
  color: #fff;
  border-bottom: 1rem solid rgba(247, 247, 247, 0.5);
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  text-align: center;
  padding: 0.5rem 5rem;
  margin: 1rem auto;
  box-shadow: 0 0.3rem 0.3rem rgba(255, 255, 255, 0.1);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
.heading-primary--main-2-main-shop-page-title_description {
  width: 75%;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  font-size: 2rem;
  letter-spacing: 0.2rem;
  text-align: center;
  padding: 1rem 0rem;
  margin: -1rem auto 1rem auto;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--welcome-user-home-page {
  width: fit-content;
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: fit-content;
  color: #fff;
  font-size: 6rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  text-align: center;
  padding: 3rem 10rem;
  margin: 12rem auto;
  box-shadow: 0 0.3rem 0.3rem rgba(255, 255, 255, 0.07);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s; */
  /* animation-iteration-count: 3; */
}
@media (max-width: 37.5em) {
  .heading-primary--welcome-user-home-page {
    font-size: 7rem;
    margin-top: 20rem;
  }
}
.heading-primary--main-2-seller-pict-seller-video-page {
  width: 60% !important;
  height: 40% !important;
  display: block;
  border-radius: 50% !important;
  padding: 0;
  margin: 1rem auto 1rem auto;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.heading-primary--sub {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.7rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub:hover {
  transform: translateY(-0.5px);
  -webkit-transform: translateY(-0.5px);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-0.5px);
  -ms-transform: translateY(-0.5px);
  -o-transform: translateY(-0.5px);
}
@media (max-width: 37.5em) {
  .heading-primary--sub {
    letter-spacing: 0.5rem;
  }
}
.heading-primary--sub-filters-shop {
  display: block;
  width: fit-content;
  padding: 1rem 2rem;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0.7rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-filters-shop:hover {
  transform: translateY(-0.5px);
  -webkit-transform: translateY(-0.5px);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-0.5px);
  -ms-transform: translateY(-0.5px);
  -o-transform: translateY(-0.5px);
}
@media (max-width: 37.5em) {
  .heading-primary--sub-filters-shop {
    letter-spacing: 0.5rem;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
    font-size: 3.5rem;
  }
}
.heading-primary--sub-filters-videos {
  display: block;
  width: fit-content;
  padding: 1.5rem 3rem;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.7rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.heading-primary--sub-filters-videos:hover {
  transform: translateY(-0.5px);
  -webkit-transform: translateY(-0.5px);
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-0.5px);
  -ms-transform: translateY(-0.5px);
  -o-transform: translateY(-0.5px);
}
@media (max-width: 37.5em) {
  .heading-primary--sub-filters-videos {
    letter-spacing: 0.5rem;
    padding: 1.5rem 5rem;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
    font-size: 3.5rem;
  }
}
.heading-primary--sub-order-titles {
  width: fit-content;
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  padding-right: 2rem;
  padding-left: 3rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  background-color: rgba(255, 255, 255, 0.17);
  box-shadow: 0.01rem 0.4rem 0.1rem rgba(255, 255, 255, 0.3);
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-order-titles {
    font-size: 3rem;
  }
}
.heading-primary--sub-order-summary {
  width: 100%;
  display: flex;
  font-size: 1.7rem;
  font-weight: 700 !important;
  text-align: center;
  justify-content: center;
  justify-items: center;
  margin: 2rem auto;
  padding: 2rem 1rem;
  color: #0e55b3;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0.1rem 0.4rem 0.1rem white;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-order-summary {
    font-size: 3rem;
  }
}
.heading-primary--sub-order-contact-message-button-background {
  width: 100%;
  display: flex;
  font-size: 1.7rem;
  font-weight: 700 !important;
  text-align: center;
  justify-content: center;
  justify-items: center;
  margin: 2rem 0 0 0 !important;
  padding: 0.2rem 0.1rem;
  background-color: rgba(86, 67, 250, 0.09);
  border: rgba(0, 0, 0, 0.1) solid 0.2rem;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-order-contact-message-button-background {
    font-size: 3rem;
    margin-bottom: 10rem !important;
  }
}
.heading-primary--sub-order-shipping-type {
  width: fit-content;
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  margin: -1rem 5rem 2rem 0;
  padding-right: 2rem;
  padding-left: 3rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.2);
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-order-shipping-type {
    font-size: 2.5rem;
  }
}
.heading-primary--download-pdf {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 1rem auto 2rem auto !important;
  padding: 2rem auto !important;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-logo-title {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-got-coupon {
  display: block;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 2.2rem;
  font-weight: 700 !important;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-got-coupon {
    font-size: 3.3rem;
  }
}
.heading-primary--sub-got-coupon__note {
  font-size: 1.5rem;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-got-coupon__note {
    font-size: 3rem;
  }
}
.heading-primary--sub-successful-payment {
  width: fit-content;
  display: block;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.06);
  margin-top: 0;
  margin-bottom: -2rem;
  padding: 0rem;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.8rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-successful-payment__margin-bottom {
  margin-bottom: 15rem;
}
.heading-primary--sub-customer-pictures-our-community {
  width: fit-content;
  display: block;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 5rem;
  margin-bottom: 0.1rem !important;
  padding: 1.3rem 7rem 1.3rem 3rem !important;
  border-bottom: 0.5rem rgba(14, 85, 179, 0.5) solid;
  font-size: 2.5rem;
  font-weight: 700 !important;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-customer-pictures-our-community__margin-bottom {
  margin-bottom: 15rem;
}
.heading-primary--sub-1 {
  display: block;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-1-checkout-payment {
  display: block;
  margin-top: 5rem;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  text-align: center;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-1-checkout-payment__success {
  color: #777777;
}
.heading-primary--sub-1-checkout-payment__no-coupon {
  color: rgb(221, 29, 29);
}
.heading-primary--sub-1-checkout-payment-saved {
  display: block;
  margin-top: 0.5rem;
  padding: 0;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  text-align: center;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-1-checkout-payment-saved__color {
  color: #28b485;
}
.heading-primary--sub-2 {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2 {
    font-size: 3rem;
  }
}
.heading-primary--sub-2:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.heading-primary--setup-stripe-payout-acount {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--setup-stripe-payout-acount {
    font-size: 3rem;
  }
}
.heading-primary--setup-stripe-payout-acount:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  text-decoration: none !important;
}
.heading-primary--domain {
  height: fit-content;
  color: #0e55b3;
  background-color: white !important;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  padding: 7rem 3rem;
  margin: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  justify-items: center !important;
  border: none;
  border-radius: 0.5rem;
  text-transform: none;
  text-align: center !important;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--domain {
    font-size: 3rem;
    padding: 2rem auto;
  }
}
.heading-primary--sub-2-dashboard-admin {
  width: 70%;
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  display: block;
  padding: 2rem 7rem 2rem 7rem;
  margin: 0 auto 2rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-dashboard-admin-payment-info {
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  display: block;
  padding: 2rem 7rem 0.5rem 7rem;
  margin: 2rem auto 2rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-customer-history-payment-info {
  width: 95%;
  color: #0e55b3;
  background-color: white;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  display: block;
  padding: 2rem 7rem 0.5rem 7rem;
  margin: 2rem auto 2rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-customer-history-payment-info {
    font-size: 2.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.heading-primary--sub-2-product-update {
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.26);
  box-shadow: 0 0.5rem 0.5rem rgba(255, 255, 255, 0.01);
  display: block;
  padding: 2rem 2rem 2rem 2rem;
  margin: 5rem auto 1rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-product-update {
    font-size: 3rem;
  }
}
.heading-primary--sub-2-product-update__err {
  color: rgba(255, 0, 0, 0.5);
}
.heading-primary--sub-2-product-customer-review {
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0.5rem 0.5rem rgba(255, 255, 255, 0.01);
  display: block;
  padding: 2rem 2rem 2rem 2rem;
  margin: 5rem auto 1rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-product-customer-review {
    font-size: 3rem;
    padding: 3rem 5rem !important;
  }
}
.heading-primary--sub-2-product-customer-review__err {
  color: rgba(255, 0, 0, 0.5);
}
.heading-primary--sub-2-customer-pictures {
  width: fit-content;
  color: white;
  background-color: rgba(86, 67, 250, 0.3);
  box-shadow: 0 0.5rem 0.5rem rgba(255, 255, 255, 0.01);
  display: flex;
  padding: 2rem 4rem !important;
  margin: 5rem auto -1rem auto;
  border: none;
  border-radius: 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700 !important;
  letter-spacing: 0.2rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  letter-spacing: 0.1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-customer-pictures {
    font-size: 3rem;
  }
}
.heading-primary--sub-2-shop-card-title {
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0.5rem 0.5rem rgba(255, 255, 255, 0.01);
  display: block;
  width: 100%;
  padding: 3rem 0.5rem 0.5rem 1rem;
  margin: -3rem 0 3rem 2.5rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: start;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.heading-primary--sub-2-user-single-product {
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0.2rem 0.3rem 0.2rem rgba(0, 0, 0, 0.1);
  display: block;
  padding: 2rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-product-list-items {
  height: fit-content;
  display: block;
  padding: 0.5rem 0.9rem 0.5rem 0.5rem;
  margin: 0;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-successful-payment {
  width: 55%;
  color: rgba(8, 94, 175, 0.8);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 0.5rem rgba(255, 255, 255, 0.01);
  display: block;
  padding: 3rem 0;
  margin: 5rem auto 10rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-checkout-steps {
  width: 85%;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 7rem auto 1rem auto !important;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-checkout-steps {
    width: 90%;
    margin-top: 10rem !important;
    padding: 0 2rem;
  }
}
.heading-primary--sub-2-create-product {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-create-product:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.heading-primary--sub-2-create-category {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: white;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-create-category:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.heading-primary--sub-2-user-order-history {
  width: 70%;
  color: rgba(14, 85, 179, 0.8);
  background-color: rgba(86, 67, 250, 0.04);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 2rem 2rem;
  margin: 5rem auto !important;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-user-order-history {
    font-size: 2.5rem;
  }
}
.heading-primary--seller-dashboard-experience-education-title {
  width: 70%;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 119, 48, 0.06);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  margin: 10rem auto 1rem auto !important;
  padding: 2rem 2rem;
  border: none;
  border-radius: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--profile-exp-edu-title {
  width: 70%;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(86, 67, 250, 0.06);
  box-shadow: 0 0.5rem 0.5rem rgba(86, 67, 250, 0.15);
  display: block;
  padding: 2rem 2rem;
  margin: 5rem auto 3rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-seller-profile-dashbaord-profile-title {
  width: 60%;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 2rem 2rem;
  margin: 8rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-seller-profile-page-title {
  width: 60%;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1.5rem 2rem rgba(86, 67, 250, 0.06);
  display: block;
  padding: 1rem;
  margin: 2rem auto 1rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-seller-profile-dashbaord-logo {
  width: 100%;
  height: fit-content !important;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
  flex-direction: column;
  padding: 1rem auto 0 auto;
  border: none;
  border-radius: 1%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-2-seller-profile-dashbaord-logo {
    font-size: 2.2rem;
  }
}
.heading-primary--sub-2-user-order-details {
  width: 55%;
  color: #fff;
  background-color: rgba(86, 67, 250, 0.2);
  display: block;
  padding: 4rem 8rem;
  margin: 2rem auto 10rem auto;
  border-bottom: 0.2rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.7rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-2-user-wish-title {
  width: 60%;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  padding: 5rem 2rem;
  margin: 5rem auto -5rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3 {
  padding: 2rem 2rem 2rem 3rem;
  margin-top: -3rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-3 {
    width: 100%;
    margin: 1rem auto !important;
    padding: 1rem auto !important;
  }
}
.heading-primary--admin-user-list {
  padding: 0 0 20rem 0;
  margin-top: 3rem;
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--seller-create-home-form {
  width: 85vw;
  padding: 2rem 2rem 2rem 3rem;
  margin: 3rem auto;
  background-color: rgba(0, 0, 0, 0.01);
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-seller-dashboard {
  padding: 2rem 2rem 2rem 3rem;
  margin-top: 13rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-admin-dashboard {
  padding: 2rem 2rem 2rem 3rem;
  margin-top: 18rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-admin-order-list {
  padding: 2rem 2rem 2rem 2rem;
  margin-top: 5rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary__profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.heading-primary__profile--info {
  color: black;
  font-weight: 500;
}
.heading-primary__profile--description {
  width: 100% !important;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  justify-content: flex-start;
}
.heading-primary__profile--description-info {
  width: 100% !important;
  display: flex;
  margin-right: 2rem;
  justify-content: flex-end !important;
  justify-items: flex-end;
  padding-bottom: 1rem;
}
.heading-primary--sub-3-order-details-info {
  width: fit-content;
  margin: 4rem auto;
  padding: 2rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-profile {
  padding: 2rem 2rem 2rem 3rem;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-3-profile {
    font-size: 3rem;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}
.heading-primary--sub-3-edit-profile {
  padding-left: 8rem;
  padding-top: 4rem;
  padding-bottom: 5rem;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 2rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--sub-3-edit-profile {
    font-size: 2.7rem;
  }
}
.heading-primary--sub-3-checkout-shipping-address {
  padding: 16rem 2rem 2rem 3rem;
  margin-top: 3rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-order-list-for-seller {
  width: 80vw;
  padding: 2rem 2rem 2rem 3rem;
  margin-top: -3rem;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-create-product {
  margin: 6rem auto;
  padding: 2rem 2rem 2rem 3rem;
  margin-top: 3rem;
  box-shadow: 0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-create-coupon {
  width: 85%;
  margin: 6rem auto;
  padding: 5rem 2rem 5rem 3rem;
  margin-top: 3rem;
  color: #04399c;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.2rem 0.5rem 1.5rem rgba(0, 0, 0, 0.05);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-3-create-category {
  width: 65%;
  margin: 6rem auto;
  padding: 2rem 2rem 2rem 6rem;
  margin-top: 3rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-4 {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 2rem 5rem;
  margin: 0 auto;
  border: none;
  border-radius: 8rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-4:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.heading-primary--sub-4:active, .heading-primary--sub-4:focus {
  outline: none;
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}
.heading-primary--sub-5 {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1.5rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-5:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 37.5em) {
  .heading-primary--sub-5 {
    font-size: 3rem;
  }
}
.heading-primary--sub-save-general-cart {
  color: #74aaf1;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 7rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-save-general-cart:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.heading-primary--sub-save-general-cart-login {
  width: 45%;
  color: #74aaf1;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  display: block;
  padding: 1rem 3rem;
  margin: 0 auto;
  border: none;
  border-radius: 7rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-save-general-cart-login:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.heading-primary--sub-6 {
  width: 50%;
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: block;
  padding: 2rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--jumbotron-type-script {
  width: 60%;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 1rem 0.5rem 2rem rgba(0, 0, 0, 0.1);
  display: block;
  padding: 1rem;
  margin: 1rem auto 2rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2.3rem;
  font-weight: 700;
  font-weight: bolder !important;
  letter-spacing: 0.7rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--jumbotron-type-script {
    font-size: 3rem !important;
    font-weight: bolder;
  }
}
.heading-primary--new-arrivals-title {
  width: fit-content;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.2rem 0 rgba(86, 67, 250, 0.1);
  display: flex;
  justify-content: center;
  padding: 2rem 10rem;
  margin: 5rem auto 3rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--new-arrivals-title {
    font-size: 2.7rem;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.heading-primary--seller-coupon {
  width: fit-content;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.2rem solid rgba(86, 67, 250, 0.5);
  display: flex;
  justify-content: center;
  padding: 2rem 10rem;
  margin: 10rem auto 1rem auto !important;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--seller-coupon {
    font-size: 3rem;
  }
}
.heading-primary--seller-dashboard-company-name {
  width: 75%;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  padding: 2rem;
  margin: 2rem auto 5rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--seller-dashboard-company-name {
    font-size: 3rem;
  }
}
.heading-primary--category-products-3-and-2-title {
  width: 100%;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin: 1rem auto 1rem 0;
  border-bottom: 0.2rem rgba(8, 94, 175, 0.2) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 37.5em) {
  .heading-primary--category-products-3-and-2-title {
    font-size: 2.7rem !important;
  }
}
.heading-primary--home-1-best-sellers-title {
  width: 60%;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.07);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 1.5rem 1.3rem;
  margin: 4rem auto 0 auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--home-1-best-sellers-title-2 {
  width: 55%;
  color: rgba(0, 0, 0, 0.7);
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 3rem auto 1.3rem auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  font-weight: bolder !important;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 56.25em) {
  .heading-primary--home-1-best-sellers-title-2 {
    font-size: 3rem;
    font-weight: bolder;
  }
}
@media (max-width: 37.5em) {
  .heading-primary--home-1-best-sellers-title-2 {
    width: 75%;
    font-size: 3rem;
    font-weight: bolder;
  }
}
@media (max-width: 23.4375em) {
  .heading-primary--home-1-best-sellers-title-2 {
    width: 75%;
    font-size: 3rem;
    font-weight: bolder;
  }
}
.heading-primary--home-1-best-sellers-title-3 {
  width: 65%;
  color: rgba(34, 44, 157, 0.6);
  background-color: rgba(34, 44, 157, 0.0513);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1) !important;
  display: flex;
  justify-content: center;
  padding: 2rem 1.3rem;
  margin: 1rem auto 1rem auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bolder !important;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 56.25em) {
  .heading-primary--home-1-best-sellers-title-3 {
    font-size: 3rem;
    font-weight: bolder;
  }
}
@media (max-width: 37.5em) {
  .heading-primary--home-1-best-sellers-title-3 {
    width: 75%;
    font-size: 3.5rem;
    font-weight: bolder;
  }
}
@media (max-width: 23.4375em) {
  .heading-primary--home-1-best-sellers-title-3 {
    width: 75%;
    font-size: 3.5rem;
    font-weight: bolder;
  }
}
.heading-primary--seller-profile-categories-and-sub-title {
  width: 100%;
  color: #fff;
  background-color: rgba(86, 67, 250, 0.3);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: flex;
  justify-content: center;
  padding: 2rem;
  margin: 3rem auto 1rem auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--seller-home-new-arrivals-title {
  width: 65%;
  color: #085eaf;
  background-color: rgba(8, 94, 175, 0.03);
  box-shadow: 0.1rem 0.5rem 0.2rem rgba(86, 67, 250, 0.1);
  display: flex;
  justify-content: center;
  padding: 2rem;
  margin: 1rem auto 3rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--seller-home-best-seller-title {
  width: 65%;
  color: #fff;
  box-shadow: 0.1rem 0.5rem 0.1rem rgba(8, 94, 175, 0.5);
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 2rem auto -1rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--seller-home-best-seller-podcasts-title {
  width: 65%;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 2rem auto 5rem auto;
  border: none;
  border-bottom: rgba(255, 255, 255, 0.5) solid 0.2rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary----home-podcasts-title {
  width: 65%;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 2rem auto 5rem auto;
  border: none;
  border-bottom: rgba(255, 255, 255, 0.3) solid 0.2rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
@media (max-width: 56.25em) {
  .heading-primary----home-podcasts-title {
    font-size: 3rem;
  }
}
.heading-primary--home-podcasts-title {
  width: 100%;
  color: #fff;
  box-shadow: 0.1rem 0.5rem 0.1rem rgba(8, 94, 175, 0.9);
  display: flex;
  padding: 2rem 1rem;
  margin: 2rem 2rem 5rem -2rem;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--seller-home-other-sellers-podcasts-title {
  width: 65%;
  color: #fff;
  box-shadow: 0.01rem 0.5rem 0.1rem rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 2rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--public-press-release-page {
  width: 80% !important;
  color: #fff;
  background-color: rgba(86, 67, 250, 0.3);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  margin: 8rem auto 5rem auto !important;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem !important;
  font-weight: 600;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--public-press-release-page__posts {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  padding: 2rem 2rem 3rem 2rem;
  color: #085eaf;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.5);
}
.heading-primary--profile-public-press-release {
  width: 65%;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  padding: 1.7rem;
  margin: 8rem auto 5rem auto;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--seller-home-categories-title-loading {
  width: 70rem;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 2rem;
  margin: 2rem auto;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
}
.heading-primary--seller-all-producats-page-categories-list {
  width: 100%;
  color: #085eaf;
  background-color: rgba(238, 238, 238, 0.5);
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(86, 67, 250, 0.1);
  display: flex;
  justify-items: center;
  padding: 2rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
}
.heading-primary--seller-home-categories-title {
  color: #fff;
  background-color: rgba(86, 67, 250, 0.5) !important;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 2rem;
  border-bottom: 0.1rem rgba(255, 255, 255, 0.9) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s;
  -webkit-animation: moveInRight 1s;
}
.heading-primary--seller-home-categories-title {
  color: #fff;
  background-color: rgba(86, 67, 250, 0.5) !important;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 2rem;
  border-bottom: 0.1rem rgba(255, 255, 255, 0.9) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s;
  -webkit-animation: moveInRight 1s;
}
.heading-primary--home-categories-title {
  color: #fff;
  background-color: rgba(23, 37, 192, 0.34) !important;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: block;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  border-bottom: 0.1rem rgba(255, 255, 255, 0.9) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.9rem;
  font-weight: bolder;
  text-align: center !important;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s;
  -webkit-animation: moveInRight 1s;
}
@media (max-width: 56.25em) {
  .heading-primary--home-categories-title {
    text-align: left;
    font-size: 2.5rem !important;
    padding: 3rem auto !important;
  }
}
@media (max-width: 37.5em) {
  .heading-primary--home-categories-title {
    text-align: center !important;
    font-size: 2.3rem !important;
    font-weight: bold;
    padding: 1.5rem 0.1rem !important;
  }
}
@media (max-width: 23.4375em) {
  .heading-primary--home-categories-title {
    text-align: center !important;
    font-size: 2.2rem !important;
    font-weight: bold;
    padding: 1.5rem 0.1rem !important;
  }
}
.heading-primary--home-categories-title-phone {
  color: #fff;
  background-color: rgba(23, 37, 192, 0.34) !important;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  border-bottom: 0.1rem rgba(255, 255, 255, 0.9) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.9rem;
  font-weight: bolder;
  text-align: center !important;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s;
  -webkit-animation: moveInRight 1s;
}
@media (max-width: 56.25em) {
  .heading-primary--home-categories-title-phone {
    text-align: left;
    font-size: 2.5rem !important;
    padding: 3rem auto !important;
  }
}
@media (max-width: 37.5em) {
  .heading-primary--home-categories-title-phone {
    text-align: center !important;
    font-size: 2.3rem !important;
    font-weight: 700 !important;
    padding: 0.5rem 1rem 1.5rem 1rem !important;
    margin: 1rem auto 1rem auto !important;
  }
}
.heading-primary--seller-all-products-page-categories-title {
  color: #fff;
  background-color: rgba(40, 180, 133, 0.1);
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 3rem;
  border-bottom: 0.1rem rgba(255, 255, 255, 0.9) solid;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s;
  -webkit-animation: moveInRight 1s;
}
.heading-primary--seller-home-sub-category-products-title {
  width: 75%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  box-shadow: 1rem 0.5rem 2rem rgba(0, 0, 0, 0.3);
  display: block;
  padding: 0.5rem 0;
  margin: 2rem auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 21s 1s;
  -webkit-animation: moveInRight 21s 1s;
}
.heading-primary--home-category-products-title {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  display: block;
  padding: 0.5rem 0;
  margin: 2rem auto 3rem auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  animation: moveInRight 21s 1s;
  -webkit-animation: moveInRight 21s 1s;
}
.heading-primary--comments-title {
  width: 100%;
  color: white;
  text-align: center !important;
  font-weight: 700;
  background-color: rgba(255, 119, 48, 0.15);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: flex;
  padding: 1rem;
  margin: 2rem 1rem 1rem -1rem;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--posts-title {
  width: 80%;
  color: #ff7730;
  text-align: center;
  background-color: rgba(86, 67, 250, 0.07);
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  margin: 5rem auto 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 700 !important;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--coupon-title {
  width: 100%;
  color: #fff;
  background-color: rgba(86, 67, 250, 0.2);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: block;
  padding: 1.5rem 2rem;
  margin: 7rem auto 1rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--social-menu {
  width: 60%;
  color: #fff;
  background-color: rgba(8, 94, 175, 0.2);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.3);
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1.5rem 2rem;
  margin: 4rem auto 1rem auto;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--social-menu-press-release-page {
  width: 45%;
  color: #fff;
  background-color: rgba(86, 67, 250, 0.2);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1.5rem 2rem;
  margin: 3rem auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-profile-form {
  color: rgba(116, 170, 241, 0.9921568627);
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 1rem 7rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary--sub-profile-form:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.heading-secondary {
  display: inline-block;
  font-size: 3rem;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, #085eaf, #085eaf);
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
@media (max-width: 56.25em) {
  .heading-secondary {
    font-size: 3rem;
  }
}
@media (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}
.heading-secondary__sub {
  margin-left: 5rem;
}

.heading-secondary-seller-form1-title {
  width: 75%;
  margin: 3rem auto 3rem auto !important;
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.03);
  color: rgba(8, 94, 175, 0.8);
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
.heading-secondary-seller-form1-title__sub {
  margin-left: 5rem;
}

.heading-secondary-seller-form1-title-2nd-design-with-clip-path {
  width: 75%;
  margin: 2rem auto 3rem auto;
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.03);
  color: rgba(8, 94, 175, 0.8);
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
.heading-secondary-seller-form1-title-2nd-design-with-clip-path__sub {
  margin-left: 5rem;
}

.heading-secondary-seller-form2-title {
  width: 75%;
  margin: 1rem auto 2rem auto;
  font-size: 1.7rem;
  text-transform: capitalize;
  margin-top: 1rem;
  padding: 2rem 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0.1rem 13rem rgba(0, 0, 0, 0.1);
  color: rgba(8, 94, 175, 0.8);
  border-bottom: rgba(255, 255, 255, 0.4) 0.2rem solid;
  letter-spacing: 0.2rem;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.heading-seller-home-tri-picts-orange-title {
  width: 75%;
  margin: 1rem auto 2rem auto;
  font-size: 1.4rem;
  text-transform: capitalize;
  margin-top: 1rem;
  padding: 2rem 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0.1rem 13rem rgba(0, 0, 0, 0.1);
  color: rgba(8, 94, 175, 0.8);
  border-bottom: rgba(255, 255, 255, 0.4) 0.2rem solid;
  letter-spacing: 0.2rem;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.heading-secondary-seller-form-title-width-100 {
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5rem 0.5rem 0.8rem rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
@media (max-width: 37.5em) {
  .heading-secondary-seller-form-title-width-100 {
    font-size: 2rem;
  }
}

.heading-secondary-seller-form-6-col-1-of-2-title-width-100 {
  font-size: 1.3rem;
  text-transform: capitalize;
  margin: 5rem 0 4rem 0;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(40, 180, 133, 0.5);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.2);
  color: white;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}

.heading-secondary-seller-form-6-col-1-of-2-blue-background-title-width-100 {
  font-size: 1.3rem;
  text-transform: capitalize;
  margin: 5rem 0 4rem 0;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(8, 94, 175, 0.5);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.2);
  color: white;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}

.heading-secondary-seller-create-home-pink-green-col-1-of-2-title {
  font-size: 1.4rem;
  text-transform: capitalize;
  margin: 4rem 0 3rem 0;
  padding: 2rem 1rem;
  font-weight: 600;
  background-color: rgba(48, 130, 124, 0.3);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.2);
  color: white;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}

.heading-secondary-seller-form-podcasts-title-width-100 {
  width: 75%;
  margin: 1rem auto;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(8, 94, 175, 0.1);
  color: #085eaf;
  border-bottom: rgba(0, 0, 0, 0.2) 0.2rem solid;
  letter-spacing: 0.2rem;
}
.heading-secondary-seller-form-podcasts-title-width-100__sub {
  margin-left: 5rem;
}

.edit-remove-container {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgba(255, 119, 48, 0.05);
  border: rgba(255, 255, 255, 0.2) 0.25rem solid;
  box-shadow: 0.2rem 0.2rem 0.3rem rgba(255, 255, 255, 0.2);
  margin: 5rem auto;
}

.heading-secondary-seller-form-podcasts-title-width-100-new-design {
  width: 75%;
  margin: 1rem auto;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.5);
  color: #085eaf;
  border-bottom: rgba(8, 94, 175, 0.6) 0.4rem solid;
  letter-spacing: 0.2rem;
}
.heading-secondary-seller-form-podcasts-title-width-100-new-design__sub {
  margin-left: 5rem;
}

.heading-secondary-seller-form-podcasts-title-width-100-black-design {
  width: 75%;
  margin: 1rem auto;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.04);
  color: white;
  border-bottom: rgba(8, 94, 175, 0.9) 0.4rem solid;
  letter-spacing: 0.2rem;
}
.heading-secondary-seller-form-podcasts-title-width-100-black-design__sub {
  margin-left: 5rem;
}

.heading-secondary-seller-form-podcasts-title-width-100-black-design-2 {
  width: 75%;
  margin: 1rem auto 3rem auto;
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.7);
  border-bottom: rgba(6, 57, 112, 0.5) 0.4rem solid;
  letter-spacing: 0.2rem;
}
.heading-secondary-seller-form-podcasts-title-width-100-black-design-2__sub {
  margin-left: 5rem;
}

.heading-tertiary {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: -3rem;
}
.heading-tertiary__our-mission {
  font-size: 1.9rem;
  font-weight: bolder !important;
  margin-bottom: 0.5rem;
  letter-spacing: 0.1rem;
  color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 37.5em) {
  .heading-tertiary__our-mission {
    font-size: 3.8rem;
    margin-bottom: 0.5rem;
    padding-left: 2rem;
  }
}

.heading-order-summary-products {
  display: inline-block;
  color: #085eaf;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: -3rem;
}
@media (max-width: 37.5em) {
  .heading-order-summary-products {
    font-size: 2.5rem;
    font-weight: 700;
    color: #085eaf;
  }
}

.heading-subtitle--tri-pict-orange {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: -3rem;
}

.heading-seller-home-public-paragraph-title {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: -1.5rem;
}

.heading-seller-home-public-form-6-paragraph-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-top: 3rem;
}
@media (max-width: 37.5em) {
  .heading-seller-home-public-form-6-paragraph-title {
    font-size: 2rem;
  }
}

.heading-seller-home-create-pink-green-form-paragraph-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.heading-tertiary--seller-create-form-4-title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.paragraph {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
}

.paragraph-our-mission {
  font-size: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 37.5em) {
  .paragraph-our-mission {
    font-size: 3.5rem;
    padding: 1rem 2rem;
  }
}

.paragraph-seller-home-form-7-big-picture {
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  text-align: justify;
}
@media (max-width: 37.5em) {
  .paragraph-seller-home-form-7-big-picture {
    font-size: 2rem;
  }
}

.paragraph-seller-home-public {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: 0.5rem 3rem;
}

.paragraph-seller-home-public {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: 0.5rem 3rem;
}

.paragraph-seller-home-public-form-6 {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: 0 3rem;
}

.paragraph-seller-create-pink-green-square {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: 0 3rem;
  margin-top: -1rem;
}

.paragraph-podcast-home-form {
  height: 100%;
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  margin: auto 1rem;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.paragraph--seller-create-form-4-paragraph {
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
}

.heading-primary-upload-file {
  width: 65%;
  border-radius: 1%;
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-primary-upload-file__sub {
  margin: 2rem 5rem;
  padding: 2rem;
  border-radius: 0.3rem;
  border: none;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary-upload-file__sub:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  border: none;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}
.heading-primary-upload-file__sub:active, .heading-primary-upload-file__sub:focus {
  outline: none;
  border: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.heading-primary-create-product {
  width: 65%;
  border-radius: 1%;
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-primary-create-product__sub {
  margin: 2rem auto 1rem auto;
  padding: 2rem;
  border-radius: 0.3rem;
  border: none;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary-create-product__sub:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  border: none;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}
.heading-primary-create-product__sub:active, .heading-primary-create-product__sub:focus {
  outline: none;
  border: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.heading-primary-create-videos {
  width: 65%;
  border-radius: 1%;
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-primary-create-videos__sub {
  margin: -5rem auto 11rem auto;
  padding: 2rem;
  border-radius: 0.3rem;
  border: none;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary-create-videos__sub:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  border: none;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}
.heading-primary-create-videos__sub:active, .heading-primary-create-videos__sub:focus {
  outline: none;
  border: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.heading-primary-create-podcast {
  width: 65%;
  border-radius: 1%;
  margin: 0 5rem 2rem 8rem !important;
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-primary-create-podcast__sub {
  margin: 1rem auto 11rem auto;
  padding: 2rem;
  border-radius: 0.3rem;
  border: none;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary-create-podcast__sub:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  border: none;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}
.heading-primary-create-podcast__sub:active, .heading-primary-create-podcast__sub:focus {
  outline: none;
  border: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.podcast-form {
  width: 100%;
  margin: 8rem auto 5rem auto;
  padding: 2rem 0 !important;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  box-shadow: 0 0.5rem 1rem rgba(86, 67, 250, 0.1);
}

.podcast-player {
  width: 30rem !important;
  margin: 8rem auto 0 auto;
  padding: -1rem 0.2rem 1rem 1.5rem !important;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
}

.heading-primary-create-category {
  width: 65%;
  border-radius: 1%;
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-primary-create-category__sub {
  width: 40%;
  margin: 0 auto 2rem auto;
  padding: 1rem;
  background-color: rgba(86, 67, 250, 0.3);
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  border: none;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-primary-create-category__sub:hover {
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  border: none;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}
.heading-primary-create-category__sub:active, .heading-primary-create-category__sub:focus {
  outline: none;
  border: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.heading-primary-admin-nav {
  width: 85%;
  border-radius: 1%;
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-primary-admin-nav__sub {
  margin: 2rem 2rem 2rem -1rem;
  padding: 4rem 2rem 10rem 2rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.3rem;
  border: none;
  display: block;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}

.heading-sub-category--title {
  width: 75%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(86, 67, 250, 0.2);
  margin: 3rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-sub-category--title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-sub-category--title [class^=col-] {
  float: left;
}
.heading-sub-category--title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-create-video--title {
  width: 75%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(86, 67, 250, 0.3);
  margin: 10rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-create-video--title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-create-video--title [class^=col-] {
  float: left;
}
.heading-create-video--title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-create-product--title {
  width: 75%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(86, 67, 250, 0.3);
  margin: 10rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-create-product--title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-create-product--title [class^=col-] {
  float: left;
}
.heading-create-product--title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-existing-coupons-title {
  width: 75%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 2rem auto 3rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-existing-coupons-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-existing-coupons-title [class^=col-] {
  float: left;
}
.heading-existing-coupons-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-page-title {
  width: 45%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2.5rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 10rem auto 0 auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.05);
}
.heading-page-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-page-title [class^=col-] {
  float: left;
}
.heading-page-title--background {
  background-color: white;
}
.heading-page-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-profile-company-name {
  width: 70%;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  justify-items: center;
  color: white;
  letter-spacing: 0.2rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 8rem auto 5rem auto;
  border-bottom: 0.1rem #085eaf solid;
  font-size: 2rem;
  font-weight: 500;
  background-color: rgba(86, 67, 250, 0.3);
  margin: 10rem auto;
  margin-bottom: 2rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.heading-seller-profile-company-name::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-profile-company-name [class^=col-] {
  float: left;
}

.heading-seller-profile-press-release-title {
  width: 80%;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2.4rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 4rem auto;
  margin-right: 2rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-seller-profile-press-release-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-profile-press-release-title [class^=col-] {
  float: left;
}

.heading-order-list-for-seller-total-orders-title {
  width: 70%;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2.4rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 4rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-order-list-for-seller-total-orders-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-order-list-for-seller-total-orders-title [class^=col-] {
  float: left;
}

.heading-seller-profile-dashboard-info {
  width: 100%;
  border-radius: 1%;
  display: block;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 2rem auto 2rem 5rem;
  padding: 3rem;
  box-shadow: 0 0.3rem 0.3rem rgba(112, 90, 206, 0.2);
}
.heading-seller-profile-dashboard-info__content {
  background-color: rgba(0, 0, 0, 0.02);
}
@media (max-width: 37.5em) {
  .heading-seller-profile-dashboard-info {
    font-size: 3rem;
  }
}

.heading-seller-profile-seller-info {
  width: 100%;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
  display: block;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2rem auto 2rem 5rem;
  padding: 5rem 2rem 5rem 2rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-profile-seller-info__content {
  background-color: rgba(86, 67, 250, 0.04);
  border-radius: 3%;
  margin: 1rem;
  padding: 2rem;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  -ms-border-radius: 3%;
  -o-border-radius: 3%;
}
.heading-seller-profile-seller-info::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-profile-seller-info [class^=col-] {
  float: left;
}

.heading-seller-profile-mession {
  width: 100%;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
  display: block;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 4rem auto;
  padding: 3rem 2rem 3rem 2rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-profile-mession__content {
  background-color: rgba(86, 67, 250, 0.04);
  border-radius: 3%;
  margin: 1rem;
  padding: 2rem;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  -ms-border-radius: 3%;
  -o-border-radius: 3%;
}
.heading-seller-profile-mession::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-profile-mession [class^=col-] {
  float: left;
}

.heading-seller-profile-dashboard-press-release {
  width: 80%;
  border-radius: 1%;
  display: block;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2rem auto;
  padding: 3rem 5rem;
  box-shadow: 0 0.5rem 1rem rgba(112, 90, 206, 0.2);
}
.heading-seller-profile-dashboard-press-release__content {
  background-color: rgba(86, 67, 250, 0.03);
  border: rgba(0, 0, 0, 0.03) 0.2rem solid;
  color: #0e55b3;
  margin: 2rem auto 2rem auto;
}
.heading-seller-profile-dashboard-press-release::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-profile-dashboard-press-release [class^=col-] {
  float: left;
}

.heading-seller-videos-page-title {
  width: 100%;
  border-radius: 1%;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2.3rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 1rem 0 1rem 0 !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-seller-videos-page-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-videos-page-title [class^=col-] {
  float: left;
}
.heading-seller-videos-page-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-header-section-business-title {
  width: 100%;
  border-radius: 1%;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2.5rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 1rem auto 3rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-seller-header-section-business-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-header-section-business-title [class^=col-] {
  float: left;
}
.heading-seller-header-section-business-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-header-profile-not-edited-title {
  width: 65%;
  border-radius: 1%;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 3.5rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 1rem auto 3rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
}
.heading-seller-header-profile-not-edited-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-header-profile-not-edited-title [class^=col-] {
  float: left;
}
.heading-seller-header-profile-not-edited-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-header-profile-not-edited-message {
  width: 65%;
  border-radius: 1%;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2.5rem;
  font-weight: 400;
  background-color: rgba(255, 119, 48, 0.05);
  margin: 0 auto 3rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.heading-seller-header-profile-not-edited-message::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-header-profile-not-edited-message [class^=col-] {
  float: left;
}
.heading-seller-header-profile-not-edited-message--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-single-video-related-videos-title {
  width: 95%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2.5rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 2rem auto 5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.heading-seller-profile-company-site {
  width: 95%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.8rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.03);
  margin: 2rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
@media (max-width: 37.5em) {
  .heading-seller-profile-company-site {
    font-size: 3rem;
  }
}

.heading-seller-profile-picture {
  width: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  color: black;
  font-size: 5rem;
  font-weight: 700;
  background-color: rgba(119, 119, 119, 0.1);
  margin: 2rem 0;
  padding-left: auto;
  padding-right: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.heading-seller-press-release-picture-public {
  width: 100%;
  border-radius: 2%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  color: black;
  font-size: 5rem;
  font-weight: 700;
  background-color: rgba(119, 119, 119, 0.05);
  margin: 2rem 0;
  padding-left: auto;
  padding-right: auto;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.heading-seller-press-release-posts {
  width: 95%;
  border-radius: 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.6rem;
  font-weight: 500;
  padding-left: 0rem;
  padding-right: 0rem;
}

.heading-dashboard-analysis {
  width: 95%;
  border-radius: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #0e55b3;
  font-size: 2.5rem;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.heading-single-video-sub-column-title {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(86, 67, 250, 0.3);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  border-bottom: 0.5rem rgba(255, 255, 255, 0.8) solid;
  margin: 0rem auto 1.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-single-video-sub-column-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-single-video-sub-column-title [class^=col-] {
  float: left;
}
.heading-single-video-sub-column-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-profile-welcome {
  width: 85%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(86, 67, 250, 0.3);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  border-bottom: 0.5rem rgba(255, 255, 255, 0.8) solid;
  margin: 0 auto 1.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 37.5em) {
  .heading-seller-profile-welcome {
    font-size: 4rem;
  }
}
.heading-seller-profile-welcome::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-profile-welcome [class^=col-] {
  float: left;
}
.heading-seller-profile-welcome--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-public-all-podcasts-podcast-info-questions-columns {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  border-bottom: 0.5rem rgba(86, 67, 250, 0.3) solid;
  margin: 0 auto 0.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.heading-seller-public-all-podcasts-podcast-info-questions-columns::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-public-all-podcasts-podcast-info-questions-columns [class^=col-] {
  float: left;
}
.heading-seller-public-all-podcasts-podcast-info-questions-columns--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-public-single-podcast-and-video-info-and-questions-columns {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  border-bottom: 0.5rem rgba(86, 67, 250, 0.3) solid;
  margin: 0 auto 0.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.heading-seller-public-single-podcast-and-video-info-and-questions-columns::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-public-single-podcast-and-video-info-and-questions-columns [class^=col-] {
  float: left;
}
.heading-seller-public-single-podcast-and-video-info-and-questions-columns--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-public-all-products-videos-product-info-questions-columns {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  border-bottom: 0.5rem rgba(86, 67, 250, 0.3) solid;
  margin: 0 auto 0.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.heading-seller-public-all-products-videos-product-info-questions-columns::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-public-all-products-videos-product-info-questions-columns [class^=col-] {
  float: left;
}
.heading-seller-public-all-products-videos-product-info-questions-columns--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-single-product-productInfo-column {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: rgba(255, 119, 48, 0.03);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin: 5rem auto 1.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (max-width: 37.5em) {
  .heading-seller-single-product-productInfo-column {
    width: 85%;
    font-size: 4rem;
  }
}
.heading-seller-single-product-productInfo-column--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-public-video-product-info-columns {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.3);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  border-bottom: 0.5rem rgba(86, 67, 250, 0.3) solid;
  margin: 0rem auto 1.5rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.heading-search-title {
  width: 75%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(8, 94, 175, 0.05);
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 10rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
}
.heading-search-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-search-title [class^=col-] {
  float: left;
}
.heading-search-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-profile-welcome-title {
  width: 85%;
  border-radius: 1%;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 600;
  background-color: white;
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 0 auto 2rem auto !important;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
}
@media (max-width: 37.5em) {
  .heading-profile-welcome-title {
    font-size: 3rem;
  }
}
.heading-profile-welcome-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-profile-welcome-title [class^=col-] {
  float: left;
}
.heading-profile-welcome-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-profile-update-title {
  width: 85%;
  border-radius: 1%;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 600;
  background-color: white;
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 2rem auto 2rem auto !important;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
}
@media (max-width: 37.5em) {
  .heading-profile-update-title {
    font-size: 3rem;
  }
}
.heading-profile-update-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-profile-update-title [class^=col-] {
  float: left;
}
.heading-profile-update-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-update-ship-address-title {
  width: 85%;
  border-radius: 1%;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2.5rem;
  font-weight: 600;
  background-color: white;
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 2rem auto 2rem auto !important;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
}
.heading-update-ship-address-title::after {
  content: "";
  display: table;
  clear: both;
}
.heading-update-ship-address-title [class^=col-] {
  float: left;
}
.heading-update-ship-address-title--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-profile-update-shipping-address {
  width: 55%;
  border-radius: 1%;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.9rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 7rem auto 7rem auto !important;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 37.5em) {
  .heading-profile-update-shipping-address {
    width: 65%;
    font-size: 3rem;
  }
}
.heading-profile-update-shipping-address::after {
  content: "";
  display: table;
  clear: both;
}
.heading-profile-update-shipping-address [class^=col-] {
  float: left;
}
.heading-profile-update-shipping-address:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.heading-profile-update-shipping-address:active, .heading-profile-update-shipping-address:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.heading-cart-items {
  width: 40%;
  border-radius: 1%;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2.2rem;
  font-weight: 600;
  background-color: rgba(48, 79, 254, 0.035);
  margin: 2rem auto -5rem auto !important;
  padding: 2.3rem 3rem;
  box-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .heading-cart-items {
    font-size: 2.6rem;
  }
}
.heading-cart-items::after {
  content: "";
  display: table;
  clear: both;
}
.heading-cart-items [class^=col-] {
  float: left;
}
.heading-cart-items--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-view-shipping-cost-options {
  width: fit-content;
  border-radius: 1%;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  color: #04399c;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  background-color: rgba(255, 119, 48, 0.03);
  border: none;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  margin: 2rem auto 6rem auto !important;
  padding: 2rem 3rem;
  cursor: pointer;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.03);
}
.heading-view-shipping-cost-options::after {
  content: "";
  display: table;
  clear: both;
}
.heading-view-shipping-cost-options [class^=col-] {
  float: left;
}
.heading-view-shipping-cost-options:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.heading-view-shipping-cost-options:active, .heading-view-shipping-cost-options:focus {
  outline: none;
  transform: translateY(-1px);
  background-color: rgba(86, 67, 250, 0.02);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
}
@media (max-width: 37.5em) {
  .heading-view-shipping-cost-options {
    font-size: 2.5rem;
  }
}

.heading-cart-summary {
  width: 85%;
  height: fit-content;
  border-radius: 1%;
  display: row;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(48, 79, 254, 0.05);
  border-bottom: 0.1rem rgba(14, 85, 179, 0.1) solid;
  margin: 5rem auto 0 auto;
  padding: 2rem 3rem;
  text-align: center;
  letter-spacing: 0.2rem;
}
@media (max-width: 37.5em) {
  .heading-cart-summary {
    font-size: 3.5rem;
  }
}
.heading-cart-summary::after {
  content: "";
  display: table;
  clear: both;
}
.heading-cart-summary [class^=col-] {
  float: left;
}
.heading-cart-summary--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-cart-summary-seller {
  width: 100%;
  height: fit-content;
  border-radius: 1%;
  display: row;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: rgba(255, 0, 0, 0.015);
  margin: 5rem auto 1rem auto !important;
  padding: 3rem;
  text-align: center;
}
@media (max-width: 37.5em) {
  .heading-cart-summary-seller {
    font-size: 3rem;
  }
}
.heading-cart-summary-seller::after {
  content: "";
  display: table;
  clear: both;
}
.heading-cart-summary-seller [class^=col-] {
  float: left;
}
.heading-cart-summary-seller--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-checkout-summary {
  width: 100%;
  height: fit-content;
  border-radius: 1%;
  display: row;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(8, 94, 175, 0.05);
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 5rem auto 5rem auto;
  padding: 3rem;
  text-align: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
}
.heading-checkout-summary::after {
  content: "";
  display: table;
  clear: both;
}
.heading-checkout-summary [class^=col-] {
  float: left;
}

.heading-cart-details {
  width: 100%;
  height: fit-content;
  border-radius: 1%;
  display: row;
  justify-content: center;
  justify-items: center;
  color: black;
  background-color: white;
  font-size: 2rem;
  font-weight: 500;
  margin: 1rem auto 1rem auto;
  padding: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-cart-details::after {
  content: "";
  display: table;
  clear: both;
}
.heading-cart-details [class^=col-] {
  float: left;
}
.heading-cart-details--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-orders-dashboard-details {
  width: 100%;
  height: fit-content;
  border-radius: 1%;
  display: row;
  justify-content: center;
  justify-items: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.6rem;
  font-weight: 500;
  border-bottom: 0.1rem rgba(14, 85, 179, 0.5) solid;
  margin: 1rem auto 8rem auto;
  padding: 3rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.heading-orders-dashboard-details::after {
  content: "";
  display: table;
  clear: both;
}
.heading-orders-dashboard-details [class^=col-] {
  float: left;
}
.heading-orders-dashboard-details--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-existing-videos {
  width: fit-content;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(255, 119, 48, 0.04);
  margin: 3rem auto 10rem auto;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
@media (max-width: 37.5em) {
  .heading-existing-videos {
    font-size: 3rem;
  }
}
.heading-existing-videos::after {
  content: "";
  display: table;
  clear: both;
}
.heading-existing-videos [class^=col-] {
  float: left;
}
.heading-existing-videos--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-create-home-forms-header {
  width: fit-content;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 3rem auto;
  padding: 2rem 10rem;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 37.5em) {
  .heading-create-home-forms-header {
    font-size: 2.7rem;
    padding: 2rem 2rem;
    margin: 1rem 5rem;
    justify-content: start;
  }
}
.heading-create-home-forms-header--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-edit-profile-button {
  width: 45%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  color: #085eaf;
  font-size: 2rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 5rem auto 0 auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
.heading-edit-profile-button::after {
  content: "";
  display: table;
  clear: both;
}
.heading-edit-profile-button [class^=col-] {
  float: left;
}

.heading-sub-category--input {
  width: 75%;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 2rem;
  background-color: #5643fa;
  margin: 3rem auto 2rem auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.heading-sub-category--input::after {
  content: "";
  display: table;
  clear: both;
}
.heading-sub-category--input [class^=col-] {
  float: left;
}
.heading-sub-category--input--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.heading-seller-total-orders {
  width: 45%;
  border-radius: 1%;
  margin: 2rem auto 5rem auto;
  padding-left: 4rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(255, 255, 255, 0.08);
}
.heading-seller-total-orders:not(:last-child) {
  margin-bottom: 5rem;
}
.heading-seller-total-orders::after {
  content: "";
  display: table;
  clear: both;
}
.heading-seller-total-orders [class^=col-] {
  float: left;
}
.heading-seller-total-orders__info {
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.checkout-steps > div {
  width: 100%;
  margin-top: 10rem;
  margin-right: 0.3rem;
  margin-bottom: 7rem;
  border-top: 0.5rem rgba(51, 51, 51, 0.1) solid;
  color: rgba(119, 119, 119, 0.5);
  flex: 1;
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: end;
}

.checkout-steps > div.active {
  border-top: 0.5rem rgba(112, 90, 206, 0.6) solid;
  color: rgb(68, 36, 211);
  font-size: 1.7rem;
  font-weight: 600;
}
@media (max-width: 37.5em) {
  .checkout-steps > div.active {
    font-size: 2rem;
  }
}

.color1 {
  color: rgb(252, 186, 3);
  background-color: rgb(238, 211, 135);
}

.color2 {
  color: #20a020;
  background-color: #eeffe0;
  /* this for alert green font and lighter green background */
}

ul {
  list-style: none;
  width: 100%;
}

.summary > li {
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  margin: 2rem;
  flex: 1 1 2rem;
  text-decoration: none;
  -moz-list-bullet: none;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.summary-title {
  font-size: 2rem;
  padding: 1rem;
}

.summary-body {
  font-size: 4rem;
  padding: 1rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.4);
}

.summary-title.color1 {
  background-color: #f0e0e0;
}

.summary-title.color2 {
  background-color: #e0f0e0;
}

.summary-title.color3 {
  background-color: #e0e0f0;
}

.heading-dashboard {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.heading-dashboard__background {
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 20rem;
}
@media (max-width: 37.5em) {
  .heading-dashboard__background {
    margin-top: 5rem;
  }
}

.heading-seller-page {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.heading-seller-page__background {
  background-color: rgba(255, 255, 255, 0.15);
}

.heading-seller-profile-view-edu-exp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.heading-seller-profile-view-edu-exp__background {
  background-color: white;
}

.heading-seller-dashboard {
  height: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 5rem !important;
  margin: auto;
}
.heading-seller-dashboard__background {
  background-color: rgba(255, 255, 255, 0.15);
  margin-top: 20rem;
}

.heading-seller-view-profile-experience-education {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 12rem auto;
  padding: 5rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-view-profile-experience-education__background {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2rem;
}
.heading-seller-view-profile-experience-education__select-form {
  margin-bottom: 1rem;
}
.heading-seller-view-profile-experience-education__experience-education-width {
  width: 75%;
  margin: 2rem auto;
}

.heading-seller-single-product-video {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1.5rem auto 10rem auto;
  padding: 1.5rem 0;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-single-product-video__background {
  background-color: rgba(255, 255, 255, 0.5);
  border-top: 0.1rem solid rgba(86, 67, 250, 0.1);
}

.heading-seller-create-home-form-nav {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
}
.heading-seller-create-home-form-nav__background {
  width: 90%;
  height: 0;
  font-size: 2rem !important;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.01);
  margin: 0.5rem auto 12rem auto !important;
}
@media (max-width: 37.5em) {
  .heading-seller-create-home-form-nav__background {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.heading-seller-create-home-form-nav__background-phone {
  width: 0;
  height: 0;
  opacity: 0;
}
@media (max-width: 37.5em) {
  .heading-seller-create-home-form-nav__background-phone {
    width: 90%;
    opacity: 1;
    height: fit-content !important;
    margin: -5rem auto 10rem auto !important;
  }
}

.heading-update-shipping-address-background-in-cart {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2rem auto 0 auto;
  padding: 2rem 5rem !important;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.015);
  border: 0.1rem solid rgba(0, 0, 0, 0.05);
}
@media (max-width: 37.5em) {
  .heading-update-shipping-address-background-in-cart {
    width: fit-content;
  }
}

.heading-seller-view-shipping-regions-first {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1rem auto -1rem auto;
  padding: 5rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-view-shipping-regions-first__background {
  background-color: rgba(86, 67, 250, 0.1);
  padding: 2rem;
}
.heading-seller-view-shipping-regions-first__select-form {
  margin-bottom: 1rem;
}
.heading-seller-view-shipping-regions-first__experience-education-width {
  width: 75%;
  margin: 2rem auto;
}

.heading-seller-view-shipping-regions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1rem auto;
  padding: 5rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-view-shipping-regions__background {
  background-color: rgba(86, 67, 250, 0.1);
  padding: 2rem;
}
.heading-seller-view-shipping-regions__select-form {
  margin-bottom: 1rem;
}
.heading-seller-view-shipping-regions__experience-education-width {
  width: 75%;
  margin: 2rem auto;
}

.heading-seller-dashboard-profile-experience-education {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2rem auto 8rem auto !important;
  padding: 5rem;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
}
@media (max-width: 37.5em) {
  .heading-seller-dashboard-profile-experience-education {
    width: 100% !important;
    margin: 0 0 0 1rem !important;
  }
}
.heading-seller-dashboard-profile-experience-education__background {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 2rem;
}
@media (max-width: 37.5em) {
  .heading-seller-dashboard-profile-experience-education__background {
    padding: 0;
  }
}
.heading-seller-dashboard-profile-experience-education__select-form {
  margin-bottom: 1rem;
}
.heading-seller-dashboard-profile-experience-education__experience-education-width {
  width: 75%;
  margin: 2rem auto;
}

.heading-seller-profile-experience-education {
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5rem auto;
  padding: 5rem 10rem;
}
.heading-seller-profile-experience-education__background {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2rem;
}
.heading-seller-profile-experience-education__select-form {
  margin-bottom: 1rem;
}
.heading-seller-profile-experience-education__experience-education-width {
  width: 75%;
  margin: 2rem auto;
  padding: 5rem;
}

.heading-seller-experience-education {
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2rem 5rem 2rem 0 !important;
  padding: 5rem 10rem;
  margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-experience-education__background {
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 4rem !important;
  padding: 2rem 10rem;
}
.heading-seller-experience-education__select-form {
  margin-bottom: 1rem;
}
.heading-seller-experience-education__experience-education-width {
  width: 75%;
  margin: 2rem auto;
  padding: 5rem;
}

.heading-seller-profile--name-picture {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5rem -2rem 5rem -2rem;
  padding: 5rem;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.03);
}
.heading-seller-profile--name-picture__background {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2rem 3rem;
}
.heading-seller-profile--name-picture__select-form {
  margin-bottom: 1rem;
}
.heading-seller-profile--name-picture__experience-education-width {
  width: 75%;
  margin: 2rem auto;
}

.heading-primary-seller-video-side-description {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin: auto;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.heading-posts {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  justify-items: center;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.2);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-size: 1.5rem;
  margin-right: -1rem;
  padding: 2rem 1rem;
}
.heading-posts--post-postedBy {
  width: 40%;
  font-size: 1rem;
  color: #085eaf;
  text-align: start;
  background-color: rgba(247, 247, 247, 0);
  box-shadow: 1rem 0.5rem 2rem rgba(8, 94, 175, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.2rem 0.2rem;
  border: none;
  border-radius: 0.5rem;
  text-transform: initial;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}
.heading-posts__postedBy {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  background-color: rgba(86, 67, 250, 0.1);
  font-size: 1rem;
  margin-left: -0.2rem;
  margin-right: 0.2rem;
  padding-top: 1rem;
  padding-bottom: 0.1rem;
  padding-right: 2rem;
}
.heading-posts__commentedBy {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-items: center;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 1rem 0.5rem 2rem rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 0.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}
.heading-posts__commentedBy--date {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: #085eaf;
}
.heading-posts__comments {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  color: black;
  background-color: rgba(86, 67, 250, 0.1);
  font-size: 1rem;
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  padding-top: 2rem;
  padding-bottom: 0.1rem;
  padding-right: 1rem;
}
.heading-posts__comments__height-scroll {
  height: 35vh;
  overflow: scroll;
  margin-top: 2rem;
}
.heading-posts__comments::after {
  content: "";
  display: table;
  clear: both;
}
.heading-posts__comments [class^=col-] {
  float: left;
}
.heading-posts__comments [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.heading-posts__comments .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.heading-posts__comments .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2rem);
}
.heading-posts__comments .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.heading-posts__comments .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.heading-posts__text-likes {
  width: 100%;
  height: fit-content;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  background-color: rgba(8, 94, 175, 0.05);
  box-shadow: 1rem 0.5rem 2rem rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  margin-left: -0.5rem;
  margin-right: -1rem;
  padding: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
}
.heading-posts__post-text {
  width: 100%;
  height: fit-content;
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: start;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1rem 0.5rem 2rem rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-size: 1.6rem;
  margin-right: -1rem;
  padding: 2rem;
}
.heading-posts__contact-message {
  width: 100%;
  height: fit-content;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  text-align: start;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1rem 0.5rem 2rem rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-size: 1.6rem;
  margin-right: -1rem;
  padding: 2rem;
}
.heading-posts__comment-text {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: start;
  white-space: pre-wrap;
  text-transform: none;
  justify-items: center;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1rem 0.5rem 2rem rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-size: 1.5rem;
  margin: 0 auto;
  padding: 2rem;
}
.heading-posts__comment-text__height-scroll {
  height: 15vh;
  padding-right: 5rem;
  overflow: scroll;
  border-color: #5643fa;
}

.messageBox {
  width: 70%;
  display: flex;
  color: white;
  background-color: rgba(86, 67, 250, 0.8);
  margin: auto;
  padding: 1rem;
  font-size: 1.7rem;
}

.podcast {
  color: #5643fa;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.heading-categories-subs-column {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (max-width: 37.5em) {
  .heading-categories-subs-column {
    font-size: 3rem !important;
    font-weight: bold;
    padding-right: 3rem;
  }
}

.heading-seller-home-categories-subs-column {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.45rem;
  font-weight: 400;
}

.heading-categories-subs-show-categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-right: 2rem !important;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bolder !important;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 37.5em) {
  .heading-categories-subs-show-categories {
    font-size: 3rem !important;
    color: #0e55b3;
  }
}

.heading-seller-home-categories-subs-show-categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
}

.heading-categories-subs-display {
  width: 100%;
  margin: 2rem 0.5rem;
  text-align: center;
  display: flex;
  color: #085eaf;
  font-size: 1.5rem;
  font-weight: 500;
  z-index: 10000;
  cursor: pointer;
}
.heading-categories-subs-display__hide-products {
  width: 15rem !important;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 3rem 3rem !important;
  font-size: 1.7rem;
  font-weight: 600;
}
.heading-categories-subs-display__show-subs {
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  text-align: center;
  cursor: pointer;
  z-index: 20000;
  background-color: white;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
}
.heading-categories-subs-display__show-subs:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}

.order-product-detail {
  display: flex;
  flex-direction: column;
}
.order-product-detail__name {
  display: flex;
  flex-direction: row;
}
.order-product-detail__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.order-product-detail__row--bold {
  font-weight: 600;
  padding-right: 5rem;
}

.order-summary-seller-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-summary-background-grey {
  width: 95%;
  margin: 3rem auto 3rem auto;
  padding: 5rem 2rem 5rem 2rem;
  font-size: 1.5rem;
  color: #085eaf;
  background-color: rgba(86, 67, 250, 0.01);
  border: 0.05rem rgba(0, 0, 0, 0.1) solid;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 37.5em) {
  .order-summary-background-grey {
    font-size: 2.5rem;
  }
}

.contact-seller-admin-customer {
  width: 90%;
  font-size: 1.7rem;
  margin: 3rem auto 3rem auto;
  padding: 2rem;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .contact-seller-admin-customer {
    font-size: 3rem;
  }
}
.contact-seller-admin-customer:hover {
  transform: scale(1) translateY(-0.4rem);
  background-color: rgba(255, 119, 48, 0.15);
  -webkit-transform: scale(1) translateY(-0.4rem);
  -moz-transform: scale(1) translateY(-0.4rem);
  -ms-transform: scale(1) translateY(-0.4rem);
  -o-transform: scale(1) translateY(-0.4rem);
}
.contact-seller-admin-customer:active, .contact-seller-admin-customer:focus {
  outline: none;
  transform: translateY(-2px);
  background-color: rgba(255, 119, 48, 0.2);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.product-description-text {
  width: fit-content;
  height: fit-content;
  text-align: start;
  font-size: 1.2rem;
  color: #085eaf;
  background-color: rgba(0, 0, 0, 0.03);
  margin: 1.5rem 1rem auto -1rem !important;
  padding: 2rem !important;
}

.heading {
  font-size: 24px;
  color: #085eaf;
  margin-bottom: 40px;
  font-weight: bold;
}

.heading-mession {
  font-size: 2rem;
  color: #0e55b3;
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.heading--create-account-title {
  font-size: 24px;
  color: #085eaf;
  font-weight: 500;
  text-align: center;
}

.drawer-title {
  font-size: 1.4rem;
  color: #085eaf;
  font-weight: 500;
  text-align: center;
}

.title {
  font-size: 1.4rem;
  color: #085eaf;
  font-weight: 500;
  text-align: center;
}

.domain-note {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(8, 94, 175, 0.7);
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  border-radius: 2px;
  background-color: rgba(255, 119, 48, 0.03);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: flex;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .domain-note {
    font-size: 3rem;
  }
}
.domain-note--main {
  background-color: rgba(255, 0, 0, 0.02);
  color: rgba(14, 85, 179, 0.7);
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  margin: 2rem 0 0 0 !important;
  padding: 1rem 2rem;
  text-transform: none;
}
.domain-note__button {
  border: 0.1rem white solid;
}

.additional-sizes-and-quantities-section {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 2rem auto;
}
.additional-sizes-and-quantities-section__background {
  background-color: rgba(255, 255, 255, 0.04);
  padding: 1rem;
  padding-bottom: 1rem !important;
}

.option-5 {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 2rem auto;
}
.option-5__background {
  background-color: rgba(86, 67, 250, 0.04);
  padding: 1rem;
  padding-bottom: 1rem !important;
  margin-top: -3rem;
}

.sizes-and-quantities-options-A-and-B {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  padding: 2rem !important;
}
.sizes-and-quantities-options-A-and-B__background {
  width: 50%;
  background-color: rgba(255, 119, 48, 0.04);
  padding: 1rem;
  padding-bottom: 1rem !important;
}

.rgba-optional {
  font-size: 1rem;
  letter-spacing: 0.2rem;
  margin-left: 0.5rem;
}

.shipping-section {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}
.shipping-section__background {
  background-color: rgba(86, 67, 250, 0.03);
  padding: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem !important;
}
.shipping-section__free {
  display: flex;
  flex-direction: row !important;
}
.shipping-section__standard-plus-weight {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  justify-items: center !important;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.shipping-section__standard-plus-weight-inputs {
  width: 25%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  justify-items: center !important;
  padding: 1rem;
  margin: 0.5rem 2rem 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.option-section {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
  margin-top: -1rem;
}
.option-section__background {
  background-color: rgba(86, 67, 250, 0.03);
  padding: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem !important;
}
.option-section__free {
  display: flex;
  flex-direction: row !important;
}
.option-section__standard-plus-weight {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  justify-items: center !important;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.option-section__standard-plus-weight-inputs {
  width: 25%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  justify-items: center !important;
  padding: 1rem;
  margin: 0.5rem 2rem 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.color-background-subs-seller-products {
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.02);
  border: 0.1rem solid rgba(0, 0, 0, 0.07);
  margin: -3rem auto 10rem auto !important;
  padding: 1.5rem;
}
.color-background-subs-seller-products:not(:last-child) {
  margin-right: 3rem;
}

.podcast-description-color-background {
  background-color: rgba(0, 0, 0, 0.02);
  border: 0.1rem solid rgba(0, 0, 0, 0.07);
  margin: 15rem auto 2rem 6rem !important;
}

.podcast-description-color-background-1-of-4 {
  background-color: rgba(0, 0, 0, 0.02);
  border: 0.1rem solid rgba(0, 0, 0, 0.07);
  margin: 2rem auto 2rem auto !important;
}

.color-background-seller-products-categories-list {
  background-color: rgba(0, 0, 0, 0.02);
  border: 0.1rem solid rgba(0, 0, 0, 0.07);
  margin: 0 auto 0 auto;
  padding: 3rem;
}
.color-background-seller-products-categories-list:not(:last-child) {
  margin-right: 3rem;
}

.option4-A-color-background {
  background-color: rgba(40, 180, 133, 0.015);
  border: 0.2rem solid rgba(255, 255, 255, 0.8);
  margin: 2rem auto;
  padding: 3rem;
}

.colors-section {
  width: 100%;
  display: flex;
  padding: 1.5rem 3rem 1.5rem 2rem !important;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.colors-section__col-6 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  justify-items: center !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  padding: 0.5rem 2rem !important;
  background-color: rgba(255, 255, 255, 0.35);
}
.colors-section__col-upload-color-pictures {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  justify-items: center !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  padding: 2rem !important;
  background-color: rgba(255, 119, 48, 0.03);
}
.colors-section__col-12 {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  justify-items: center !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  padding: 1.5rem auto !important;
  background-color: rgba(255, 255, 255, 0.25);
}

.product-colors-options {
  width: fit-content !important;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  justify-items: center !important;
  padding: 2rem 0.5rem !important;
  margin-left: -7rem !important;
  margin-top: 8rem !important;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.15);
  height: 45vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (max-width: 37.5em) {
  .product-colors-options {
    margin-top: 10rem !important;
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    height: 25vh;
    justify-items: center !important;
  }
}
.product-colors-options ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  scrollbar-width: none;
}
.product-colors-options__background {
  width: fit-content !important;
  height: fit-content;
  display: flex;
  flex-direction: column !important;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem 0.7rem !important;
  margin: 0.5rem 1rem !important;
  border: 0.1rem white solid;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}
@media (max-width: 37.5em) {
  .product-colors-options__background {
    height: fit-content !important;
    margin: 1rem !important;
  }
}

.upload-colors-pictures-background-section {
  width: 100%;
  height: 7rem;
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  justify-items: center !important;
  margin: 2rem auto 2rem auto;
  padding: 3rem 3rem 1.5rem 2rem !important;
  color: rgba(4, 57, 156, 0.6);
  border-radius: 2rem;
  letter-spacing: 0.2rem;
  border: 0.1rem white solid;
  background-color: rgba(86, 67, 250, 0.06);
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  z-index: 10000;
}

.option4-all-sizes-with-discount {
  width: 75%;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 1rem auto;
  padding: 1.5rem 3rem 1.5rem 2rem !important;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.size-qty-section {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  justify-items: center !important;
  padding: 2rem 3rem 2rem 3rem;
  background-color: rgba(40, 180, 133, 0.01);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}
.size-qty-section__col-6 {
  flex-direction: column !important;
  justify-content: center !important;
  justify-items: center !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem auto !important;
  background-color: rgba(255, 255, 255, 0.25);
}
.size-qty-section__col-12 {
  display: flex;
  flex-direction: row !important;
  justify-items: center !important;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem 4rem 1rem 4rem !important;
  background-color: rgba(255, 255, 255, 0.5);
}

.nocolor-nosize-quantity-only-section {
  width: 50%;
  height: 55%;
  display: flex !important;
  justify-content: center;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  margin: 3rem auto;
  padding: 5rem auto !important;
}

.sizes-qtys-section {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  justify-items: center !important;
  background-color: rgba(40, 180, 133, 0.01);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}
.sizes-qtys-section__col-6 {
  flex-direction: column !important;
  justify-content: center !important;
  justify-items: center !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem auto !important;
  background-color: rgba(255, 255, 255, 0.25);
}
.sizes-qtys-section__col-12 {
  width: 100% !important;
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  justify-items: center !important;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  margin: 1rem auto;
  padding: 0 5rem !important;
  background-color: rgba(255, 255, 255, 0.25);
}

.sale {
  background-color: #ff7730;
  size: 100rem !important;
}

.product-discount {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  margin: 0.2rem 0;
  padding: 1.5rem !important;
  padding-left: 0.1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(255, 0, 0, 0.7);
  background-color: rgba(255, 255, 255, 0.8);
}
.product-discount:first-child {
  margin-top: 3rem !important;
}
@media (max-width: 37.5em) {
  .product-discount {
    font-size: 2.5rem;
  }
}

.create-coupon-message {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 0.7rem;
  border-radius: 2px;
  background-color: rgba(255, 0, 0, 0.05);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}

.create-coupon-discount {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  font-size: 1.4rem;
  font-family: inherit;
  color: rgba(0, 0, 0, 0.5);
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.01);
  border: none !important;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
}

.display-coupon-message {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 0.7rem;
  border-radius: 2px;
  background-color: rgba(255, 0, 0, 0.05);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}

.cloud-failed {
  width: 100%;
  height: fit-content;
  display: block;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  margin: 1rem auto !important;
  padding: 5rem 1rem;
  font-size: 15rem;
  color: rgba(255, 0, 0, 0.8) !important;
  background-color: rgba(255, 119, 48, 0.03);
}

.order-details {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  padding: 2rem;
  background-color: rgba(86, 67, 250, 0.03);
  border: rgba(0, 0, 0, 0.05) solid 0.02rem;
}
@media (max-width: 37.5em) {
  .order-details {
    font-size: 2.5rem;
  }
}
.order-details__qty-price {
  display: flex !important;
  flex-direction: row !important;
  margin-top: 0.5rem;
  margin-bottom: -1rem;
}
.order-details__payment-info {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
  margin-bottom: -2rem;
  margin-top: 0.5rem;
}
.order-details__payment-info--status-method {
  width: 25%;
}
.order-details__order-date {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  padding: 1rem;
}
.order-details__status {
  color: white;
  font-size: 2rem;
  background-color: rgba(255, 119, 48, 0.3);
  padding: 2rem 5rem;
}

.status-color-green {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(40, 180, 133, 0.3);
  border: 0.1rem white solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem;
}

.status-color-not-processed {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(255, 119, 48, 0.65);
  border: 0.1rem white solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem;
}

.status-color-processing {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(255, 119, 48, 0.25);
  border: 0.1rem white solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem;
}

.status-color-shipped {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(40, 180, 133, 0.2);
  border: 0.1rem white solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem;
}

.status-color-delivered {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(40, 180, 133, 0.5);
  border: 0.1rem white solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem;
}

.status-color-cancelled {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(119, 119, 119, 0.5);
  border: 0.1rem white solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.12);
  padding: 1rem 3rem;
}

.sample {
  width: 5rem !important;
  height: 15rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  margin: 40rem auto;
  margin-top: 40rem;
  font-size: 3rem;
  padding: 8rem 55rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
  -webkit-border-radius: 5rem;
}
.sample__space {
  width: 20rem !important;
  margin: 1rem 0 2rem -7rem;
  padding: 1rem auto !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  justify-items: center;
}

.sample-1 {
  width: 15rem !important;
  height: 7rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  margin: 4rem auto;
  margin-top: 7rem;
  font-size: 3rem;
  padding: 3rem 5rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
  -webkit-border-radius: 5rem;
}
.sample-1__space {
  width: 20rem !important;
  margin: 1rem 0 2rem -7rem;
  padding: 1rem auto !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  justify-items: center;
}

.spin {
  width: 6rem;
  height: 6rem;
  display: block;
  justify-content: center;
  justify-items: center;
  margin: 4rem auto 1rem -5rem !important;
  text-align: center;
  text-size-adjust: 25rem;
  background-color: rgba(255, 185, 0, 0.3);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.spin-reset-password {
  width: 100%;
  height: 6rem;
  display: block;
  justify-content: center;
  justify-items: center;
  margin: 4rem auto !important;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #0e55b3;
  font-size: 3rem;
}

.seller-order-details-page {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 500;
}
@media (max-width: 37.5em) {
  .seller-order-details-page {
    font-size: 2.4rem;
  }
}
.seller-order-details-page__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}
.seller-order-details-page__row--name-margin-right {
  margin-right: 4.2rem;
}
.seller-order-details-page__row--address-margin-right {
  margin-right: 3rem !important;
}
.seller-order-details-page__row--address-margin-right-city {
  margin-right: 6rem;
}
.seller-order-details-page__row--address-margin-right-state {
  margin-right: 2rem;
}
.seller-order-details-page__row--address-margin-right-zipcode {
  margin-right: 2rem;
}
.seller-order-details-page__country {
  display: flex;
  flex-direction: row;
  margin-top: -1.5rem;
}
.seller-order-details-page__country--country-margin-right {
  margin-right: 3rem;
}

.option-2-sizes-quantities-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.create-product-colors-options-second-note {
  width: 95%;
  font-size: 1.4rem;
  font-weight: 500;
  color: #063970;
  background-color: white;
  box-shadow: 0 0.15rem 0.15rem rgba(86, 67, 250, 0.3);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 2rem auto 0 auto !important;
  padding: 5rem;
  letter-spacing: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  transition: all 0.3s;
}
.create-product-colors-options-second-note__text {
  color: rgba(8, 94, 175, 0.8);
}

.create-product-colors-options-first-note {
  width: fit-content;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.15rem 0.15rem rgba(255, 255, 255, 0.9);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 2rem auto !important;
  padding: 1.5rem 5rem;
  letter-spacing: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  transition: all 0.3s;
}
.create-product-colors-options-first-note--main-note {
  text-transform: capitalize;
}

.create-product-colors-options-third-body-note {
  width: 95%;
  font-size: 1.4rem;
  font-weight: 500;
  border: 0.1rem rgba(255, 255, 255, 0.5) solid;
  margin: 0.1rem auto 2rem auto !important;
  padding: 2.5rem 5rem 2rem 5rem;
  letter-spacing: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  transition: all 0.3s;
}
.create-product-colors-options-third-body-note--main-note {
  text-transform: capitalize;
}

.dark-background {
  background-color: rgba(119, 119, 119, 0.035);
  text-align: center;
  margin: 2rem auto !important;
}

.single-product-main-pict-dark-background {
  height: 100% !important;
  width: 100% !important;
  min-height: 45rem;
  max-height: 45rem;
  object-fit: cover;
  background-color: rgba(119, 119, 119, 0.05);
  text-align: center;
}

.single-product-main-pict-in-modal {
  object-fit: fill !important;
  background-color: rgba(119, 119, 119, 0.05);
  text-align: center;
  margin-bottom: -1rem !important;
  padding-left: -4rem !important;
}
.single-product-main-pict-in-modal__height-scroll {
  width: 50rem;
  height: 80rem;
  overflow: scroll;
  margin: 2rem;
}

.light-background {
  background-color: rgba(255, 119, 48, 0.04);
  text-align: center;
  margin: 2rem auto !important;
}

.single-product-small-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 1%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: -4rem;
}

.subs-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  width: fit-content;
  border-radius: 1%;
  height: 100%;
  margin: 0.5rem 1rem 0.1rem 0;
  padding: 1.3rem;
  font-size: 1.5rem;
  font-weight: 400;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.07);
}
@media (max-width: 37.5em) {
  .subs-search {
    font-size: 3rem;
    font-weight: 400;
  }
}

.subs-seller-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  width: fit-content;
  border-radius: 5%;
  height: 100%;
  margin: 0.5rem 1rem 0.1rem 0.5rem;
  padding: 1.3rem;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.17);
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
}

.enlarge-modal-background {
  display: flex;
  justify-content: center !important;
  background-color: rgba(255, 255, 255, 0.4);
  text-align: center !important;
  margin: -7rem 8rem 7rem auto !important;
  color: rgba(66, 66, 66, 0.7);
  font-size: 1.5rem;
}
@media (max-width: 37.5em) {
  .enlarge-modal-background {
    font-size: 3rem;
    font-weight: 700;
    color: #ff7730;
  }
}
.enlarge-modal-background__model {
  width: 55% !important;
}
@media (max-width: 37.5em) {
  .enlarge-modal-background__model {
    width: 100% !important;
    height: 100% !important;
    margin: 20rem auto !important;
  }
}

.next-button {
  background-color: rgba(6, 57, 112, 0.4);
  color: white;
  border: none;
  text-align: center;
  letter-spacing: 0.2rem;
  font-weight: 500;
  margin: 1rem auto !important;
  margin-right: 2rem !important;
  margin-bottom: 3rem !important;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
}

.size {
  font-size: 2rem;
  text-align: center;
  color: rgba(255, 119, 48, 0.15);
}

.height {
  height: 85vh !important;
}

.flexColumn {
  display: flex !important;
  background-color: rgba(0, 0, 0, 0.05);
  flex-direction: column !important;
  height: 70vh !important;
  padding-bottom: 5rem;
  overflow: scroll;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.d-flexRow {
  display: flex !important;
  flex-direction: row !important;
  width: 100vw !important;
}

.categories-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
}

.single-product-flexColumn {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  justify-items: center;
  object-fit: scale-down !important;
  width: 100%;
  height: 100%;
  max-height: 50rem;
  min-height: 35rem !important;
  object-fit: fill;
  background-color: rgba(255, 255, 255, 0.35);
}
.single-product-flexColumn__height-scroll {
  height: 40vh;
  overflow-y: scroll;
  background: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  object-fit: fill !important;
  width: 100% !important;
  height: 80%;
}
.single-product-flexColumn__height-scroll ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none !important;
  scrollbar-width: none;
}

.view-order-messages {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: space-around;
  border: 0.1rem white solid;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
}

.center {
  margin: 1rem auto 1rem 13rem !important;
}

.product-video-title {
  color: rgba(255, 255, 255, 0.7);
  text-shadow: #e0e0f0;
  margin-top: 0.5rem;
  display: block;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  animation: moveInRight 1s ease-out;
  -webkit-animation: moveInRight 1s ease-out;
}

.business-description {
  margin-top: -3rem !important;
}

.font {
  font-size: 1.7rem !important;
  font-weight: 600;
  text-transform: none !important;
  letter-spacing: 0.1rem;
  color: rgba(14, 85, 179, 0.8) !important;
}
.font__center {
  display: block;
  text-align: center;
}
@media (max-width: 37.5em) {
  .font {
    font-size: 3rem !important;
  }
}

.zoom-review {
  font-size: 1.7rem !important;
  font-weight: 600;
  text-transform: none !important;
  letter-spacing: 0.1rem;
  color: rgba(14, 85, 179, 0.8) !important;
}
@media (max-width: 37.5em) {
  .zoom-review {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.vid-upload-error {
  background-color: rgba(255, 119, 48, 0.5) !important;
  font-size: 1.7rem !important;
  text-align: center !important;
}

.u-center-text {
  text-align: center;
}

.u-margin-top-Xsmall {
  margin-top: 2rem;
}

.u-margin-top-small {
  margin-top: 4rem;
}

.u-margin-top-big {
  margin-top: 8rem;
}

.u-margin-top-huge {
  margin-top: 10rem;
}

.u-margin-top-xsmall {
  margin-top: -2rem;
}

.u-margin-top-xmedium {
  margin-top: -6rem;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-bottom-Xsmall {
  margin-bottom: -2rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
}

.u-margin-bottom-big {
  margin-bottom: 8rem;
}

.u-margin-bottom-huge {
  margin-bottom: 15rem;
}

.u-margin-left-small {
  margin-left: 1rem;
}

.u-margin-left-medium {
  margin-left: 3rem;
}

.u-margin-left-big {
  margin-left: 6rem;
}

.u-margin-right-small {
  margin-right: 1rem;
}

.admin-dashboard-order-info:link, .admin-dashboard-order-info:visited {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  -webkit-transition: all 0.2s;
}

.login-btn:link, .login-btn:visited {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  -webkit-transition: all 0.2s;
}
.login-btn:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  color: #085eaf !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.login-btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.login-btn:active, .login-btn:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  color: white !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
}
.login-btn--white {
  color: white !important;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.login-btn--white::after {
  background-color: rgba(255, 255, 255, 0.3);
}
.login-btn--white {
  color: white !important;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.login-btn--white::after {
  background-color: rgba(255, 255, 255, 0.3);
}
.login-btn::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
}
.login-btn-animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn:link, .btn:visited {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  -webkit-transition: all 0.2s;
}
@media (max-width: 37.5em) {
  .btn:link, .btn:visited {
    font-size: 2.5rem;
    text-transform: capitalize;
  }
}
.btn:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.btn:active, .btn:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(8, 94, 175, 0.2) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.btn--white {
  background-color: #fff;
  color: #777;
}
@media (max-width: 37.5em) {
  .btn--white {
    font-size: 3rem !important;
    width: fit-content;
    padding: 2rem 5rem !important;
  }
}
.btn--white::after {
  background-color: #fff;
}
.btn--white:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn--customer-review {
  width: 25%;
  height: fit-content;
  margin: 0 auto !important;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
  background-color: rgba(86, 67, 250, 0.25);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
  color: #fff !important;
  border: rgba(255, 255, 255, 0.5) 0.1rem solid !important;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
@media (max-width: 37.5em) {
  .btn--customer-review {
    width: 35vw;
    font-size: 4rem;
  }
}
.btn--customer-review:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #0e55b3 !important;
}
.btn--white-category-sub-product-seller-name {
  width: fit-content;
  margin: 0.2rem auto !important;
  padding: 0.4rem 1.3rem;
  background-color: rgba(255, 255, 255, 0.7);
  color: #0e55b3;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.btn--white-category-sub-product-seller-name::after {
  background-color: #fff;
}
.btn--white-category-sub-product-seller-name:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn--carousel {
  background-color: #55c57a;
  color: #777;
  width: fit-content;
}
.btn--carousel::after {
  background-color: #fff;
}
.btn--carousel:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.btn--green {
  background-color: rgba(85, 197, 122, 0.9);
  color: #fff;
}
.btn--green::after {
  background-color: rgba(85, 197, 122, 0.9);
}
.btn--green:hover {
  color: #fff;
  background-color: rgba(85, 197, 122, 0.7);
}
.btn::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
}
.btn-animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-checkout {
  width: fit-content;
  display: flex;
  background-color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: -5rem;
  padding: 2rem !important;
  border-radius: 5%;
  transition: all 0.2s;
  position: relative;
  -webkit-transition: all 0.2s;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}
.btn-checkout:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn-header:link, .btn-header:visited {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  margin-top: -22rem;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  -webkit-transition: all 0.2s;
}
.btn-header:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
}
.btn-header:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.btn-header:active, .btn-header:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.btn-header--white {
  background-color: #fff;
  color: #777;
}
.btn-header--white::after {
  background-color: #fff;
}
.btn-header--white:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn-header--white-comment-like {
  background-color: #fff;
  color: #777;
}
.btn-header--white-comment-like::after {
  background-color: #fff;
}
.btn-header--white-comment-like:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn-header--carousel {
  background-color: #55c57a;
  color: #777;
  width: fit-content;
}
.btn-header--carousel::after {
  background-color: #fff;
}
.btn-header--carousel:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.btn-header--green {
  background-color: rgba(85, 197, 122, 0.9);
  color: #fff;
}
.btn-header--green::after {
  background-color: rgba(85, 197, 122, 0.9);
}
.btn-header--green:hover {
  color: #fff;
  background-color: rgba(85, 197, 122, 0.7);
}
.btn-header::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
}
.btn-header-animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  font-weight: 600;
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -webkit-border-radius: 0.5rem;
}
.btn-text:hover {
  background-color: #55c57a;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
}
.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

.btn-press-release:link, .btn-press-release:visited {
  font-size: 1.5rem !important;
  font-weight: 600;
  margin-left: 2rem;
  color: #085eaf;
  display: block;
  text-decoration: none;
  border-bottom: 3px solid #55c57a;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -webkit-border-radius: 0.5rem;
}
.btn-press-release:hover {
  background-color: rgba(86, 67, 250, 0.1);
  color: #085eaf;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}
.btn-press-release:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.button-card:link, .button-card:visited {
  display: block;
  font-size: 1.6rem;
  padding: 3rem 4rem;
  margin-left: 3rem;
  margin-right: 3rem;
  text-decoration: none;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.button-card:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.button-card:active, .button-card:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.button-card--white {
  background-color: #fff;
  color: #777;
}
.button-card--white::after {
  background-color: #fff;
}
.button-card--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-create-product:link, .btn-create-product:visited {
  display: block;
  font-size: 1.6rem;
  padding: 3rem 0;
  text-decoration: none;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.btn-create-product:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn-create-product:active, .btn-create-product:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.btn-create-product--white {
  background-color: #fff;
  color: #777;
}
.btn-create-product--white::after {
  background-color: #fff;
}
.btn-create-product--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-create-coupon:link, .btn-create-coupon:visited {
  display: block;
  font-size: 1.6rem;
  padding: 0;
  text-decoration: none;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.btn-create-coupon:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn-create-coupon:active, .btn-create-coupon:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.btn-create-coupon--tertiary {
  background-color: rgba(86, 67, 250, 0.3);
  color: #fff;
  font-weight: 700;
}
.btn-create-coupon--tertiary::after {
  background-color: #fff;
}
.btn-create-coupon--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-customer-picture-submit {
  width: 18%;
  height: 4rem !important;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  background-color: rgba(255, 255, 255, 0.4);
  color: #0e55b3;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 3.5rem;
  padding-top: 1rem !important;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.btn-customer-picture-submit:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  background-color: rgba(255, 255, 255, 0.6);
  color: #0e55b3;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}
.btn-customer-picture-submit:active, .btn-customer-picture-submit:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.btn-customer-picture-submit--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.button-proceed {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.3rem 0.3rem;
}

.btn--register {
  width: 70%;
  font-size: 1.8rem !important;
  font-weight: 500;
  background-color: rgba(50, 94, 168, 0.5) !important;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7) !important;
  margin: 1rem auto -5rem auto !important;
  padding: 0.5rem;
  border: 0.01rem rgba(0, 0, 255, 0.5) solid;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--register {
    font-size: 4rem !important;
  }
}
.btn--register:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(50, 94, 168, 0.7);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--register:active, .btn--register:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--register-complete {
  width: 60%;
  height: fit-content;
  font-size: 1.8rem !important;
  font-weight: 500;
  background-color: rgba(50, 94, 168, 0.5) !important;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7) !important;
  margin: 1rem auto 0 auto !important;
  border: 0.01rem rgba(0, 0, 255, 0.5) solid;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--register-complete:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(50, 94, 168, 0.7);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--register-complete:active, .btn--register-complete:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--login {
  width: 63% !important;
  height: fit-content;
  display: block;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(50, 94, 168, 0.5) !important;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
  margin: 3rem auto 0 auto !important;
  padding: 0.7rem !important;
  border: 0.01rem rgba(0, 0, 255, 0.1) solid;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--login {
    height: fit-content;
    font-size: 3rem;
    margin-bottom: 5rem;
    padding: 2rem auto;
  }
}
.btn--login:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(9, 95, 245, 0.4) !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--login:active, .btn--login:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--forgot-password {
  width: 63% !important;
  height: fit-content;
  display: block;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(50, 94, 168, 0.5) !important;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
  margin: 3rem auto 0 auto !important;
  padding: 0.7rem !important;
  border: 0.01rem rgba(0, 0, 255, 0.1) solid;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--forgot-password {
    height: fit-content;
    font-size: 4rem;
    margin-bottom: 5rem;
    padding: 2rem auto;
    border: none;
  }
}
.btn--forgot-password:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(9, 95, 245, 0.4) !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--forgot-password:active, .btn--forgot-password:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--login-google-forgot-password {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7) !important;
  margin: -3rem auto 0 auto !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--login-google-forgot-password {
    height: fit-content;
    font-size: 3rem;
    padding: 0;
    margin-bottom: 3rem;
  }
}
.btn--login-google-forgot-password:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--login-google-forgot-password:active, .btn--login-google-forgot-password:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--login-google {
  width: 60% !important;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(50, 94, 168, 0.5) !important;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
  margin: 0 auto 0 auto !important;
  padding: 0.5rem;
  border: 0.01rem rgba(0, 0, 255, 0.5) solid;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--login-google {
    font-size: 3rem;
    padding: 0 !important;
    height: 5rem;
    margin-top: 1.5rem !important;
    margin-bottom: 3rem !important;
  }
}
.btn--login-google:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(9, 95, 245, 0.5) !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--login-google:active, .btn--login-google:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgb(60, 112, 202);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: white;
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue:active, .btn--blue:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-contact-seller-customer-admin {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgb(60, 112, 202);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: white;
  margin: 3rem 27.5% !important;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--blue-contact-seller-customer-admin {
    font-size: 3rem;
  }
}
.btn--blue-contact-seller-customer-admin:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-contact-seller-customer-admin:active, .btn--blue-contact-seller-customer-admin:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--card-seller-name {
  width: 100% !important;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(60, 112, 202, 0.5);
  color: white;
  margin-top: 1rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--card-seller-name:hover {
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}
.btn--card-seller-name:active, .btn--card-seller-name:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--card-related-seller-name {
  width: 75% !important;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center !important;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  margin-top: 1rem;
  border: none;
}
.btn--card-related-seller-name:hover {
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}
.btn--card-related-seller-name:active, .btn--card-related-seller-name:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-checkout {
  width: 20vw;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: rgba(60, 112, 202, 0.5);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: white;
  margin-top: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--blue-checkout {
    width: 30vw;
    font-size: 3rem !important;
    margin: 1rem 35vw !important;
  }
}
.btn--blue-checkout:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  color: white;
  background-color: rgba(60, 112, 202, 0.6) !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-checkout:active, .btn--blue-checkout:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-order-download {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(60, 112, 202, 0.8);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  color: white;
  margin-top: -2rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue-order-download:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(60, 112, 202);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-order-download:active, .btn--blue-order-download:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-proceed {
  width: 65%;
  font-size: 1.7rem;
  font-weight: 500;
  background-color: rgb(60, 112, 202);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: white;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--blue-proceed {
    font-size: 2.5rem;
  }
}
.btn--blue-proceed:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-proceed:active, .btn--blue-proceed:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--shipping-to-proceed {
  width: 85%;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  text-transform: capitalize;
  background-color: rgba(255, 119, 48, 0.07);
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  color: #0e55b3;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--shipping-to-proceed {
    font-size: 3rem;
  }
}
.btn--shipping-to-proceed:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(255, 119, 48, 0.09);
  box-shadow: 0.7rem 0.7rem 0.7rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--shipping-to-proceed:active, .btn--shipping-to-proceed:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--shipping-option {
  width: 15%;
  height: 100% !important;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  background-color: rgba(60, 112, 202, 0.5);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  color: white;
  margin: auto 0 1rem auto !important;
  padding: 2rem auto !important;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--shipping-option {
    width: 20%;
    font-size: 2.5rem;
  }
}
.btn--shipping-option:hover {
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  background-color: rgba(50, 94, 168, 0.6);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
}
.btn--shipping-option:hover:active, .btn--shipping-option:hover:focus {
  outline: none;
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
}

.btn--blue-disabled {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: rgba(119, 119, 119, 0.5);
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue-disabled:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-disabled:active, .btn--blue-disabled:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--edit {
  width: 20vw;
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  font-weight: 500;
  background-color: rgba(60, 112, 202, 0.5);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
  margin: 3rem auto;
  padding: 1.5rem 3rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .btn--edit {
    font-size: 3rem;
  }
}
.btn--edit:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--edit:active, .btn--edit:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-view-profile-experience-edu {
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(86, 67, 250, 0.3);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.05);
  color: white;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue-view-profile-experience-edu:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-view-profile-experience-edu:active, .btn--blue-view-profile-experience-edu:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--update-shipping-address {
  width: fit-content;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.1);
  color: #0e55b3;
  margin-top: 1rem;
  padding: 1.5rem 3rem !important;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--update-shipping-address__view-shipping-cost {
  padding: 5rem 7rem;
  background-color: rgba(255, 119, 48, 0.045);
}
.btn--update-shipping-address:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--update-shipping-address:active, .btn--update-shipping-address:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-profile-categories-sub {
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(86, 67, 250, 0.7);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.05);
  color: white !important;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue-profile-categories-sub:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--blue-profile-categories-sub:active, .btn--blue-profile-categories-sub:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--view-comments {
  width: 55%;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  background-color: rgba(60, 112, 202, 0.5);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15);
  color: white;
  margin-top: 1rem;
  padding: 0.5rem 4rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--view-comments:hover {
  color: white;
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(97, 144, 227, 0.5);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--view-comments:active, .btn--view-comments:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--view-write-questions-on-video {
  width: 90%;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.2rem 0.2rem rgba(8, 94, 175, 0.4);
  color: rgba(8, 94, 175, 0.7);
  margin: 5rem 3rem -1rem 3rem !important;
  padding: 2rem 4rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--view-write-questions-on-video:hover {
  color: white;
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: rgba(8, 94, 175, 0.7);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--view-write-questions-on-video:active, .btn--view-write-questions-on-video:focus {
  outline: none;
  transform: translateY(-2px);
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: #085eaf !important;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--seller-customer-admin-contact {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.2rem 0.2rem rgba(8, 94, 175, 0.4);
  color: rgba(8, 94, 175, 0.7);
  margin: 1rem 0.5rem;
  margin-left: -0.1rem;
  padding: 2rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--seller-customer-admin-contact:hover {
  color: white;
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: rgba(8, 94, 175, 0.7);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.btn--seller-customer-admin-contact:active, .btn--seller-customer-admin-contact:focus {
  outline: none;
  transform: translateY(-2px);
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: #085eaf !important;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

.btn--blue-like {
  width: fit-content;
  border: none !important;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.05);
  color: white;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue-like__like {
  width: 100%;
}
.btn--blue-like__background {
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.05);
  color: white;
  margin: 1rem auto;
  padding: 0.5rem 2rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.btn--blue-like__background::after {
  content: "";
  display: table;
  clear: both;
}
.btn--blue-like__background [class^=col-] {
  float: left;
}
.btn--blue-like__background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.btn--blue-like__background .col-1-of-3 {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.btn--blue-like__background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 0.5rem) / 3 + 0.5rem);
}
.btn--blue-like__background .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
}
.btn--blue-like:hover {
  transform: translateY(-0.5px);
  -webkit-transform: translateY(-0.5px);
  background-color: rgb(50, 94, 168);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  -moz-transform: translateY(-0.5px);
  -ms-transform: translateY(-0.5px);
  -o-transform: translateY(-0.5px);
}
.btn--blue-like:active, .btn--blue-like:focus {
  outline: none;
  transform: translateY(-1px);
  background-color: rgba(50, 94, 168, 0.5);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
}

.btn--blue-like-edit-comment {
  width: 100%;
  border: none !important;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(239, 242, 247, 0.7);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
  color: white;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.btn--blue-like-edit-comment__like {
  width: 100%;
}
.btn--blue-like-edit-comment__background {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.05);
  color: white;
  margin-top: 1rem;
  padding: 1.5rem 1rem 0 1rem;
}

.btn-block:hover {
  outline: none;
  transform: translateY(0);
  background-color: rgba(86, 67, 250, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(86, 67, 250, 0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
.btn-block:active {
  outline: none;
  transform: translateY(0);
  background-color: rgba(8, 94, 175, 0.5);
  box-shadow: 0 0.5rem 1rem rgba(86, 67, 250, 0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
.btn-block:focus {
  outline: none;
  transform: translateY(0);
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(86, 67, 250, 0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.btn-shipping-option-details {
  width: 30%;
  margin: 1rem 37.5% 1rem 37.5%;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.05);
  border: none;
}
@media (max-width: 37.5em) {
  .btn-shipping-option-details {
    width: 40%;
    margin: 1rem auto 1rem 30%;
    font-size: 2.1rem;
  }
}
.btn-shipping-option-details:hover {
  outline: none;
  transform: translateY(0.5px);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(0.5px);
  -moz-transform: translateY(0.5px);
  -ms-transform: translateY(0.5px);
  -o-transform: translateY(0.5px);
}
.btn-shipping-option-details:active {
  outline: none;
  transform: translateY(-0.2px);
  background-color: rgba(8, 94, 175, 0.3);
  box-shadow: 0.2 0.2rem 0.2rem rgba(0, 0, 0, 0.05);
  -webkit-transform: translateY(-0.2px);
  -moz-transform: translateY(-0.2px);
  -ms-transform: translateY(-0.2px);
  -o-transform: translateY(-0.2px);
}
.btn-shipping-option-details:focus {
  outline: none;
  transform: translateY(-0.2px);
  background-color: rgba(255, 255, 255, 0.02);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.05);
  -webkit-transform: translateY(-0.2px);
  -moz-transform: translateY(-0.2px);
  -ms-transform: translateY(-0.2px);
  -o-transform: translateY(-0.2px);
}

.btn-subs-category-seller {
  width: fit-content;
  display: flex;
  background-color: rgba(255, 255, 255, 0.4);
  color: #0e55b3 !important;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  margin-top: -7rem;
  margin-bottom: 5rem;
  margin-right: 3rem;
  padding: 2rem 3rem !important;
  border: 0.1rem rgba(255, 255, 255, 0.3) solid;
  border-radius: 5%;
  transition: all 0.2s;
  position: relative;
  -webkit-transition: all 0.2s;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
.btn-subs-category-seller:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
.btn-subs-category-seller:active, .btn-subs-category-seller:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  color: white !important;
  background-color: rgba(86, 67, 250, 0.1) !important;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.2);
}

.Toastify .Toastify__toast-body {
  width: 500rem !important;
  background-color: rgba(0, 0, 0, 0.02) !important;
  color: #0e55b3;
}

.btn-next {
  padding: 0.5rem 1rem;
}

.carousel-main-home-display-main-carousel {
  width: 94% !important;
  max-height: 50rem !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  position: absolute;
  top: 0;
  margin: 1rem 0.5rem -1rem 4rem !important;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  z-index: 100;
}
@media (min-width: 112.51em) {
  .carousel-main-home-display-main-carousel {
    width: 93% !important;
    display: flex;
    margin: -1rem 3rem 1rem 2rem !important;
  }
}
@media (max-width: 112.5em) {
  .carousel-main-home-display-main-carousel {
    width: 93% !important;
    display: flex;
    margin: 1rem 3rem 1rem 2rem !important;
  }
}
@media (max-width: 75em) {
  .carousel-main-home-display-main-carousel {
    width: 100% !important;
    height: 60vh !important;
    display: flex;
    margin: 1rem 3rem 1rem 2rem !important;
  }
}
@media (max-width: 56.25em) {
  .carousel-main-home-display-main-carousel {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    margin: 1rem 0.5rem -1rem 4rem !important;
  }
}
.carousel-main-home-display-main-carousel__img {
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.5);
  object-fit: fill !important;
  width: 93% !important;
  max-height: 47rem !important;
  display: flex !important;
  justify-content: center !important;
  justify-items: center !important;
}
@media (min-width: 112.51em) {
  .carousel-main-home-display-main-carousel__img {
    width: 100% !important;
    max-height: 70vh !important;
    margin-top: -8rem !important;
  }
}
@media (max-width: 112.5em) {
  .carousel-main-home-display-main-carousel__img {
    width: 95% !important;
    max-height: 65vh !important;
  }
}
@media (max-width: 75em) {
  .carousel-main-home-display-main-carousel__img {
    width: 95% !important;
    max-height: 60vh !important;
  }
}
@media (max-width: 56.25em) {
  .carousel-main-home-display-main-carousel__img {
    width: 93% !important;
    max-height: 60rem !important;
  }
}
@media (max-width: 37.5em) {
  .carousel-main-home-display-main-carousel__img {
    width: 93% !important;
    max-height: 40rem !important;
  }
}
@media (max-width: 23.4375em) {
  .carousel-main-home-display-main-carousel__img {
    width: 93% !important;
    max-height: 35rem !important;
  }
}

.carousel .thumb {
  max-height: 4.7rem;
  margin-bottom: 0.2rem;
  padding: 0 !important;
}

.thumbs {
  height: fit-content;
  padding-top: 0 !important;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  border-radius: none;
  margin-top: 5rem;
  margin-bottom: 2rem !important;
  padding-left: 5rem;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  -ms-border-radius: none;
  -o-border-radius: none;
}

.column-main-home-main-carousel-categories-subs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.45rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.column-main-home-categories-subs-show-categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: solid;
  color: rgba(14, 85, 179, 0.6);
  text-decoration-line: underline;
  -moz-text-decoration-line: underline;
}

.column-home-categories-select-subs-products {
  width: 100% !important;
  height: 65vh;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  background-color: rgba(40, 180, 133, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2rem;
  border: none;
  z-index: 1000;
}
@media (max-width: 75em) {
  .column-home-categories-select-subs-products {
    width: 95%;
    height: 63vh;
    margin-top: 1rem;
  }
}
@media (max-width: 56.25em) {
  .column-home-categories-select-subs-products {
    width: 95%;
    height: 47vh;
    margin-top: 0.2rem;
  }
}
@media (max-width: 37.5em) {
  .column-home-categories-select-subs-products {
    width: 90%;
    height: 20vh;
  }
}
@media (max-width: 23.4375em) {
  .column-home-categories-select-subs-products {
    width: 90%;
    height: 20vh !important;
  }
}

.column-home-categories-select-subs-products-with-clicked-category-or-subCategory {
  width: 100% !important;
  height: 65vh;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  background-color: rgba(40, 180, 133, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2rem;
  border: none;
  z-index: 1000;
}
@media (max-width: 75em) {
  .column-home-categories-select-subs-products-with-clicked-category-or-subCategory {
    width: 95%;
    height: 63vh;
    margin-top: 1rem;
  }
}
@media (max-width: 56.25em) {
  .column-home-categories-select-subs-products-with-clicked-category-or-subCategory {
    width: 95%;
    height: 47vh;
    margin-top: 0.2rem;
  }
}
@media (max-width: 37.5em) {
  .column-home-categories-select-subs-products-with-clicked-category-or-subCategory {
    width: 90%;
    height: 40vh;
  }
}
@media (max-width: 23.4375em) {
  .column-home-categories-select-subs-products-with-clicked-category-or-subCategory {
    width: 90%;
    height: 40vh !important;
  }
}

.column-main-home-main-carousel-display-subs {
  width: 90% !important;
  height: 55rem;
  margin-bottom: 5rem !important;
  padding-top: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap !important;
  color: #085eaf;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: rgba(40, 180, 133, 0.02);
  z-index: 10000;
  cursor: pointer;
}
.column-main-home-main-carousel-display-subs__hide-products {
  width: 15rem !important;
}
.column-main-home-main-carousel-display-subs__show-subs {
  width: 15%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  margin: 0 2rem 0 1rem !important;
  padding: 1.5rem 1rem;
  align-items: center;
  text-align: center;
  cursor: pointer;
  z-index: 20000;
  background-color: white !important;
  box-shadow: 0.2rem 0.1rem 0.2rem black;
}
.column-main-home-main-carousel-display-subs__show-subs:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}

.column-main-home-carousel-category-products-display {
  width: 100%;
  height: 65vh;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  background-color: rgba(40, 180, 133, 0.02);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2rem;
  padding: 0 auto;
  border: none;
}
@media (max-width: 56.25em) {
  .column-main-home-carousel-category-products-display {
    height: 45vh;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
  }
}
@media (max-width: 37.5em) {
  .column-main-home-carousel-category-products-display {
    height: 35vh;
  }
}
@media (max-width: 23.4375em) {
  .column-main-home-carousel-category-products-display {
    height: 28vh;
  }
}

.column-main-home-carousel-category-products-box-card {
  width: 90%;
  height: 100%;
  border-radius: 0.5%;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
.column-main-home-carousel-category-products-box-card__height-scroll {
  width: 100% !important;
  height: 65vh;
  padding-bottom: 10rem;
  overflow: scroll;
  scrollbar-width: thin;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
@media (min-width: 112.51em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 75vw !important;
    height: 65vh;
    padding: 2rem 2rem 10rem 2rem !important;
    margin-left: -2rem !important;
    margin-top: -1rem !important;
  }
}
@media (max-width: 112.5em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 75vw !important;
    height: 65vh;
    padding: 2rem 2rem 10rem 2rem !important;
    margin-left: -2rem !important;
    margin-top: -1rem !important;
  }
}
@media (max-width: 75em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 75vw !important;
    height: 65vh;
    padding: 2rem 2rem 10rem 2rem !important;
    margin-left: -2rem !important;
    margin-top: -1rem !important;
  }
}
@media (max-width: 56.25em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 70vw !important;
    height: 45vh;
    padding: 2rem 2rem !important;
  }
}
@media (max-width: 37.5em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 95vw !important;
    height: 58vh !important;
    margin: -2rem 1rem 2rem 0;
    padding: 2rem 0 2rem 10rem !important;
  }
}
@media (max-width: 31.25em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 95vw !important;
    height: 58vh !important;
    margin: 2rem auto 2rem auto !important;
    padding: 2rem 0 2rem 4rem !important;
  }
}
@media (max-width: 25em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 96vw !important;
    height: 50vh !important;
    margin: 2rem auto 2rem auto !important;
    padding: 2rem auto 2rem auto !important;
  }
}
@media (max-width: 23.4375em) {
  .column-main-home-carousel-category-products-box-card__height-scroll {
    width: 100vw !important;
    height: 50vh !important;
    margin: 0 auto !important;
    padding: 0 auto !important;
  }
}
.column-main-home-carousel-category-products-box-card:not(:last-child) {
  margin-bottom: 5rem;
}
.column-main-home-carousel-category-products-box-card::after {
  content: "";
  display: table;
  clear: both;
}
.column-main-home-carousel-category-products-box-card [class^=col-] {
  float: left;
}
.column-main-home-carousel-category-products-box-card .col-1-of-2 {
  width: calc((100% - 6 * 0.5rem) / 7) !important;
  border: 0.3rem blue solid;
  background-color: rgba(255, 119, 48, 0.15);
  margin: 1rem 3rem;
  height: 18vh;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
}
@media (max-width: 37.5em) {
  .column-main-home-carousel-category-products-box-card .col-1-of-2 {
    width: 20rem !important;
  }
}
.column-main-home-carousel-category-products-box-card .col-1-of-7 {
  width: 20vw;
  background-color: rgba(255, 119, 48, 0.5);
  margin: 1rem 3rem;
  height: 14vh;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
}
@media (max-width: 37.5em) {
  .column-main-home-carousel-category-products-box-card .col-1-of-7 {
    width: 15% !important;
    height: 15vh !important;
    margin: 1rem 1rem !important;
    background-color: rgba(255, 185, 0, 0.5);
  }
}
@media (max-width: 23.4375em) {
  .column-main-home-carousel-category-products-box-card .col-1-of-7 {
    width: 135% !important;
    height: 15vh !important;
    margin: 1rem 5rem !important;
    background-color: rgba(255, 185, 0, 0.5);
  }
}

.loading-main-home-carousel-categories-products-display-title {
  width: 70rem;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1rem 0.5rem 2rem rgba(86, 67, 250, 0.1);
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 2rem;
  margin: 2rem auto;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 0.3rem;
}

.card-tour {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;
}
.card-tour__side {
  height: 52rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card-tour__side--front {
  background-color: #fff;
}
.card-tour__side--back {
  transform: rotateY(180deg);
}
.card-tour__side--back-1 {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730);
}
.card-tour__side--back-2 {
  background-image: linear-gradient(to right bottom, #0e55b3, rgba(40, 180, 133, 0.568627451));
}
.card-tour__side--back-3 {
  background-image: linear-gradient(to right bottom, #085eaf, #5643fa);
}
.card-tour:hover .card-tour__side--front {
  transform: rotateY(-180deg);
}
.card-tour:hover .card-tour__side--back {
  transform: rotateY(0);
}
.card-tour__picture {
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.card-tour__picture--1 {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730), url(../images/dyagonline-black&white.jpg);
}
.card-tour__picture--2 {
  background-image: linear-gradient(to right bottom, #0e55b3, #ff7730), url(../images/DYAshopping.jpg);
}
.card-tour__picture--3 {
  background-image: linear-gradient(to right bottom, #085eaf, #5643fa), url(../images/dyagoonline.jpg);
}
.card-tour__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  position: absolute;
  top: 20rem;
  right: 0.5rem;
  width: 95%;
}
.card-tour__heading-span {
  padding: 0.2rem 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-tour__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.5), rgba(255, 119, 48, 0.5));
}
.card-tour__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(14, 85, 179, 0.5), rgba(40, 180, 133, 0.5));
}
.card-tour__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.5), rgba(86, 67, 250, 0.5));
}
.card-tour__details {
  padding: 3rem;
}
.card-tour__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}
.card-tour__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}
.card-tour__details ul li:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
.card-tour__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card-tour__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 3rem;
  border: 0.1rem red solid;
}
.card-tour__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}
.card-tour__price-value {
  font-size: 6rem;
  font-weight: 100;
}

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 35rem;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  margin-bottom: 2rem;
  box-shadow: none;
}
.card__side {
  height: 35rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  overflow: hidden;
}
.card__side--front {
  background-color: white;
}
.card__side--back {
  transform: rotateY(180deg);
}
.card__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.7), #5643fa);
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card__picture {
  background-size: cover;
  overflow: hidden;
  width: fit-content;
  height: 35rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.card__heading {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: right;
  color: rgb(14, 81, 189);
  position: absolute;
  top: 0rem;
  right: 3rem;
  width: 100%;
}
.card__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 26.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: black;
  position: absolute;
  top: 12rem;
  width: 100%;
}
.card__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  margin-top: 15%;
}
.card__price-value {
  font-size: 4rem;
  font-weight: 100;
}
.card__view-product {
  display: block;
  padding-top: -2rem;
  padding-bottom: 0.5rem;
  margin-top: -1rem;
}
.card__view-product--video {
  display: inline-block;
  margin-left: 1rem;
  color: #839cf6;
}
.card__view-product--view {
  display: inline-block;
  margin-left: 5rem;
  color: #839cf6;
}
.card__seller-video-name {
  display: inline-block;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 3rem;
}
.card__seller-name-1 {
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
}
.card__seller-name-2 {
  display: block;
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
}
.card__rating {
  font-size: 1.5rem;
  text-align: center;
}
.card__rating-showAverage {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.card-sub-category {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  background-color: transparent;
  border: none;
}
.card-sub-category__side {
  height: 30rem !important;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}
.card-sub-category__side--front {
  background-color: white;
}
.card-sub-category__side--front::after {
  width: 90%;
}
.card-sub-category__side--back {
  transform: rotateY(180deg);
}
.card-sub-category__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-sub-category__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-sub-category__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.1) 0%, black 35%, #5643fa 100%);
}
.card-sub-category:hover .card-sub-category__side--back {
  transform: rotateY(0);
}
.card-sub-category__picture {
  position: absolute;
  top: -0.25rem;
  object-fit: cover;
  width: 100%;
  min-height: 30rem !important;
  max-height: 30rem !important;
  transition: filter 0.5s;
  background-blend-mode: color-burn;
  -webkit-transition: filter 0.5s;
  -moz-transition: filter 0.5s;
  -ms-transition: filter 0.5s;
  -o-transition: filter 0.5s;
}
.card-sub-category__picture::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-sub-category__picture::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-sub-category__picture:hover::after {
  width: 0;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-sub-category__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.6rem;
  font-weight: 700;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
@media (max-width: 37.5em) {
  .card-sub-category__seller-name-1 {
    width: 0;
    opacity: 0;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__seller-name-1 {
    width: 0;
    opacity: 0;
  }
}
.card-sub-category__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-sub-category__heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  top: 0.1rem;
  left: 0.5rem;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
.card-sub-category__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card-sub-category__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-sub-category__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-sub-category__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-sub-category__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-sub-category__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 30rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 10rem;
  width: 85%;
  z-index: 10;
}
.card-sub-category__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-sub-category__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-sub-category__back-header-box {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  background-color: #182a41;
  position: absolute;
}
@media (max-width: 37.5em) {
  .card-sub-category__back-header-box {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.card-sub-category__back-footer-box {
  width: 100%;
  height: 15%;
  color: white;
  background-color: #232f3e;
  position: absolute;
  bottom: 0.2%;
}
.card-sub-category__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 25%;
  width: 100%;
  height: 59%;
  text-align: center;
}
.card-sub-category__cta::before {
  content: "";
  position: absolute;
  top: 0.1vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
@media (max-width: 37.5em) {
  .card-sub-category__cta::before {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.card-sub-category__cta::after {
  content: "";
  position: absolute;
  top: 15%;
  left: 2rem;
  width: 85%;
  height: 0.1rem;
  background-color: white;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
@media (max-width: 37.5em) {
  .card-sub-category__cta::after {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.card-sub-category__cta:hover::after {
  width: 95%;
  transition: width 0.5s 0.1s;
  -webkit-transition: width 0.5s 0.1s;
  -moz-transition: width 0.5s 0.1s;
  -ms-transition: width 0.5s 0.1s;
  -o-transition: width 0.5s 0.1s;
}
.card-sub-category__price-box {
  align-items: center;
  text-align: center;
  color: rgb(237, 237, 243);
  position: absolute;
  top: 24%;
  left: 45%;
}
@media (max-width: 37.5em) {
  .card-sub-category__price-box {
    top: 3%;
    left: 42%;
  }
}
.card-sub-category__price-value {
  font-size: 2rem;
  font-weight: 400;
}
@media (max-width: 37.5em) {
  .card-sub-category__price-value {
    font-size: 3.5rem;
  }
}
.card-sub-category__view-product {
  display: flex;
  width: 100% !important;
  height: fit-content;
  justify-content: space-around !important;
  font-size: 1.5rem;
  padding: 5rem auto;
  position: absolute;
  bottom: -3vh;
}
@media (max-width: 56.25em) {
  .card-sub-category__view-product {
    font-size: 2.5rem !important;
    bottom: -2vh !important;
  }
}
@media (max-width: 37.5em) {
  .card-sub-category__view-product {
    font-size: 3.5rem !important;
    bottom: -3.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__view-product {
    font-size: 2.5rem !important;
    top: 7vh;
    bottom: 0 !important;
  }
}
.card-sub-category__view-product--video {
  display: flex;
  margin-left: 1rem;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  z-index: 100;
}
@media (max-width: 75em) {
  .card-sub-category__view-product--video {
    font-size: 1.3rem !important;
  }
}
@media (max-width: 56.25em) {
  .card-sub-category__view-product--video {
    font-size: 3rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__view-product--video {
    font-size: 2.5rem !important;
    margin-top: -11rem !important;
  }
}
.card-sub-category__view-product--view {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 56.25em) {
  .card-sub-category__view-product--view {
    margin-top: 6rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-sub-category__view-product--view {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__view-product--view {
    margin-top: 3rem !important;
  }
}
.card-sub-category__seller-video-name {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 0 5rem;
  color: white;
  margin-bottom: 3rem;
  position: absolute;
  top: -1%;
  right: 20%;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .card-sub-category__seller-video-name {
    height: fit-content;
    position: absolute;
    top: -6%;
    font-size: 3rem;
  }
}
.card-sub-category__related-product-seller-name {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 0.5rem auto;
  margin-top: -0.5rem;
  color: white;
  margin-bottom: 3rem;
  position: absolute;
  cursor: pointer !important;
}
.card-sub-category__seller-name-2 {
  width: 85%;
  display: flex;
  font-size: 1.6rem;
  color: #fff;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
  position: absolute;
  left: 30%;
  cursor: pointer;
  margin-top: -2%;
}
@media (max-width: 56.25em) {
  .card-sub-category__seller-name-2 {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-sub-category__seller-name-2 {
    font-size: 3rem !important;
    margin-top: -7rem !important;
    left: 25%;
  }
}
.card-sub-category__add-to-cart {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 40%;
  margin-top: 1rem;
}
.card-sub-category__add-to-cart--home {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 40%;
  font-size: 1.7rem;
  font-weight: 700 !important;
  color: #ff7730 !important;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 17vh;
  left: 30%;
  z-index: 10000;
  cursor: pointer !important;
}
@media (max-width: 75em) {
  .card-sub-category__add-to-cart--home {
    top: 20vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 56.25em) {
  .card-sub-category__add-to-cart--home {
    font-size: 2.7rem;
    top: 10.5vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 37.5em) {
  .card-sub-category__add-to-cart--home {
    font-size: 2.7rem;
    top: 6.3vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__add-to-cart--home {
    font-size: 2.7rem;
    top: 6vh;
    width: 60%;
    left: 20%;
  }
}
.card-sub-category__product-color {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: center;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 9vh;
  left: 0%;
}
@media (max-width: 75em) {
  .card-sub-category__product-color {
    top: 11vh;
    margin-right: 2rem !important;
  }
}
@media (max-width: 56.25em) {
  .card-sub-category__product-color {
    top: 5vh;
    font-size: 2.5rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-sub-category__product-color {
    position: absolute;
    top: 1.5vh;
    left: -5%;
    font-size: 3rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__product-color {
    position: absolute;
    top: 1vh;
    left: -5%;
    font-size: 2.5rem !important;
    margin-right: 2rem !important;
  }
}
.card-sub-category__product-size {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: space-around;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 9vh;
  left: 52%;
}
@media (max-width: 75em) {
  .card-sub-category__product-size {
    width: fit-content;
    margin-left: 2rem !important;
    top: 11vh;
  }
}
@media (max-width: 56.25em) {
  .card-sub-category__product-size {
    width: fit-content;
    font-size: 2.5rem !important;
    top: 5vh;
    left: 60%;
  }
}
@media (max-width: 37.5em) {
  .card-sub-category__product-size {
    width: fit-content;
    font-size: 3rem !important;
    top: 1.5vh;
    left: 62%;
  }
}
@media (max-width: 23.4375em) {
  .card-sub-category__product-size {
    width: fit-content;
    font-size: 2.5rem !important;
    top: 1vh;
    left: 62%;
  }
}
.card-sub-category__rating {
  width: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 0%;
}
@media (max-width: 56.25em) {
  .card-sub-category__rating {
    margin-top: -5rem;
    font-size: 2.5rem !important;
  }
}
.card-sub-category__rating-showAverage {
  width: 100%;
  display: flex;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  position: absolute;
  top: -1.5rem;
  left: 50%;
}
@media (max-width: 56.25em) {
  .card-sub-category__rating-showAverage {
    top: 0%;
    font-size: 2.5rem !important;
  }
}
.card-sub-category__rating-message {
  width: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  margin-top: -1.5rem;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}
@media (max-width: 56.25em) {
  .card-sub-category__rating-message {
    font-size: 2.5rem !important;
  }
}

.card-category-products-2 {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 100%;
  background-color: transparent;
  border: none;
}
.card-category-products-2:hover .card-category-products-2__side--back {
  transform: rotateY(0);
}
.card-category-products-2__picture {
  position: absolute;
  top: -0.25rem;
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.card-category-products-2__picture::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-category-products-2__picture::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-category-products-2__picture:hover::after {
  width: 0;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-category-products-2__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 2rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.3rem;
  font-weight: 700;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-category-products-2__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-category-products-2__heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
.card-category-products-2__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card-category-products-2__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-category-products-2__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-category-products-2__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-category-products-2__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-category-products-2__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 30rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 10rem;
  width: 85%;
  z-index: 10;
}
.card-category-products-2__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-category-products-2__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-category-products-2__back-header-box {
  width: 100%;
  height: 25%;
  background-color: #182a41;
  position: absolute;
}
.card-category-products-2__back-footer-box {
  width: 100%;
  height: 15%;
  color: white;
  background-color: #232f3e;
  position: absolute;
  bottom: 0.2%;
}
.card-category-products-2__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 25%;
  width: 100%;
  height: 59%;
  text-align: center;
}
.card-category-products-2__cta::before {
  content: "";
  position: absolute;
  top: 2vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-category-products-2__cta::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 2rem;
  width: 90%;
  height: 0.1rem;
  background-color: white;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-category-products-2__price-box {
  align-items: center;
  text-align: center;
  color: rgb(237, 237, 243);
  position: absolute;
  top: 2%;
  left: 40%;
}
.card-category-products-2__price-value {
  font-size: 2rem;
  font-weight: 400;
}
.card-category-products-2__view-product {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-evenly;
  font-size: 1.5rem;
  padding: 5rem auto;
  position: absolute;
  bottom: 5%;
}
.card-category-products-2__view-product--video {
  display: inline-block;
  margin-left: 1rem;
  color: #839cf6;
}
.card-category-products-2__view-product--view {
  display: inline-block;
  margin-left: 5rem;
  color: #839cf6;
}
.card-category-products-2__seller-video-name {
  display: inline-block;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.card-category-products-2__seller-name-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: #fff;
  padding: 0 1rem;
  position: absolute;
  top: 25%;
  left: 5%;
}
.card-category-products-2__add-to-cart {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 40%;
  margin-top: 1rem;
}
.card-category-products-2__rating {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 1%;
}
.card-category-products-2__rating-showAverage {
  width: 100%;
  display: flex;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  position: absolute;
  left: 50%;
}
.card-category-products-2__rating-message {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 1rem;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.card-category-products-3 {
  perspective: 150rem;
  -moz-perspective: 150rem;
  height: 100%;
  background-color: transparent;
  border: none;
}
.card-category-products-3__picture {
  position: relative;
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.card-category-products-3__picture::before {
  content: "";
  position: absolute;
  top: 20%;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-category-products-3__picture::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-category-products-3__picture:hover::after {
  width: 0;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-category-products-3__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 3rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-category-products-3__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-category-products-3__heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
.card-category-products-3__heading--small-2-products {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  position: absolute;
  top: 0.5rem;
  left: 0.3rem;
  width: 75%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}

.card-seller-home-sub-category {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}
@media (max-width: 56.25em) {
  .card-seller-home-sub-category {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 37.5em) {
  .card-seller-home-sub-category {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 23.4375em) {
  .card-seller-home-sub-category {
    width: 100%;
    height: 28rem !important;
  }
}
.card-seller-home-sub-category__category-sub-product-logo {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 4rem;
  height: 2.5rem !important;
}
.card-seller-home-sub-category__picture {
  position: absolute;
  top: 0;
  left: 0;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
  max-width: 100%;
  max-height: 100% !important;
}
.card-seller-home-sub-category__seller-name-1 {
  width: 50%;
  position: absolute;
  top: 20%;
  left: 1rem;
  color: #5643fa;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-seller-home-sub-category__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-seller-home-sub-category__seller-name-category-sub-product {
  width: 60%;
  position: absolute;
  top: 40%;
  left: 40%;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  justify-items: center;
  padding: 1rem 2rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-seller-home-sub-category__seller-name-category-sub-product__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-seller-home-sub-category__heading {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  z-index: 1000;
  position: absolute;
  top: 5rem;
  left: 6rem;
  width: 100%;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
@media (max-width: 37.5em) {
  .card-seller-home-sub-category__heading {
    position: absolute;
    top: 2rem;
    left: 4rem;
  }
}
.card-seller-home-sub-category__view-product {
  display: flex;
  width: 100%;
  padding: 2rem 1rem;
  justify-content: flex-start;
  justify-items: center;
  font-size: 1rem;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
}
.card-seller-home-sub-category__view-product-category-sub-product {
  display: flex;
  width: 13rem;
  height: fit-content;
  position: absolute;
  top: 10rem;
  left: -6rem;
  padding: 0.8rem 1rem !important;
  justify-content: center;
  justify-items: center;
  font-size: 1.2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.card-seller-home-sub-category__view-product-category-sub-product:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
}
.card-seller-home-sub-category__view-product-category-sub-product:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.card-seller-home-sub-category__view-product-category-sub-product:active, .card-seller-home-sub-category__view-product-category-sub-product:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(8, 94, 175, 0.2) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .card-seller-home-sub-category__view-product-category-sub-product {
    position: absolute;
    top: 9rem;
    left: -3rem;
  }
}

.card-product-shop {
  display: flex;
  justify-content: space-between;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  width: 98%;
  height: 35rem;
  background-color: transparent;
  border: none;
  padding: 2rem 1.5rem;
}
@media (max-width: 56.25em) {
  .card-product-shop {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop {
    height: 35vh;
  }
}
.card-product-shop__side {
  height: 35rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}
@media (max-width: 37.5em) {
  .card-product-shop__side {
    height: 35vh;
  }
}
.card-product-shop__side--front {
  background-color: rgba(255, 255, 255, 0.8);
}
.card-product-shop__side--front::after {
  width: 100%;
}
.card-product-shop__side--back {
  transform: rotateY(180deg);
  height: 100%;
  width: 100% !important;
}
.card-product-shop__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-product-shop__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-product-shop__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.1) 0%, black 35%, #5643fa 100%);
}
.card-product-shop:hover .card-product-shop__side--back {
  transform: rotateY(0);
}
.card-product-shop__picture {
  position: absolute;
  object-fit: fill;
  width: 98% !important;
  height: 97% !important;
  min-height: 35rem;
  max-height: 35rem;
  transition: filter 0.5s;
  background-blend-mode: color-burn;
  -webkit-transition: filter 0.5s;
  -moz-transition: filter 0.5s;
  -ms-transition: filter 0.5s;
  -o-transition: filter 0.5s;
}
@media (max-width: 37.5em) {
  .card-product-shop__picture {
    width: 90vw;
    height: 34vh !important;
    max-height: 34vh;
  }
}
.card-product-shop__picture::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
@media (max-width: 37.5em) {
  .card-product-shop__picture::before {
    top: 14vw;
    left: 90%;
    width: 0.1rem;
    height: 50%;
    background-color: #ff7730;
  }
}
.card-product-shop__picture::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-product-shop__picture:hover::after {
  width: 0 !important;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-product-shop__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 54%;
  right: 5%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-product-shop__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-product-shop__video-category-sub-product {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 81%;
  right: 5%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
  font-weight: 500;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
  padding: 0.3rem 3rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-product-shop__video-category-sub-product:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
}
.card-product-shop__video-category-sub-product:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.card-product-shop__video-category-sub-product:active, .card-product-shop__video-category-sub-product:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(8, 94, 175, 0.2) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.card-product-shop__heading {
  font-size: 1.6rem;
  font-weight: bolder !important;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
@media (max-width: 56.25em) {
  .card-product-shop__heading {
    font-size: 2.5rem !important;
  }
}
.card-product-shop__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card-product-shop__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-product-shop__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-product-shop__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-product-shop__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-product-shop__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 30rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 10rem;
  width: 85%;
  z-index: 10;
}
.card-product-shop__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-product-shop__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-product-shop__back-header-box {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  justify-items: center;
  background-color: #182a41;
  position: absolute;
}
.card-product-shop__back-footer-box {
  width: 100%;
  height: 10%;
  color: white;
  background-color: #232f3e;
  position: absolute;
  bottom: 0.2%;
}
.card-product-shop__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 25%;
  width: 100%;
  height: 64%;
  text-align: center;
}
.card-product-shop__cta::before {
  content: "";
  position: absolute;
  top: 0.1vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
@media (max-width: 37.5em) {
  .card-product-shop__cta::before {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.card-product-shop__cta::after {
  content: "";
  position: absolute;
  top: 10%;
  left: 3rem;
  width: 85%;
  height: 0.01rem;
  background-color: white;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-product-shop__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  position: absolute;
  top: 24%;
  left: 45%;
}
@media (max-width: 37.5em) {
  .card-product-shop__price-box {
    position: absolute;
    top: 5vh;
    left: 42%;
  }
}
.card-product-shop__price-value {
  font-size: 2rem !important;
  font-weight: 400;
}
@media (max-width: 56.25em) {
  .card-product-shop__price-value {
    font-size: 5rem !important;
  }
}
.card-product-shop__view-product {
  display: flex;
  width: 100% !important;
  height: fit-content;
  justify-content: space-around !important;
  font-size: 1.5rem;
  padding: 5rem auto;
  position: absolute;
  bottom: -3vh;
}
@media (max-width: 56.25em) {
  .card-product-shop__view-product {
    font-size: 2.5rem !important;
    bottom: -2vh !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__view-product {
    font-size: 3.5rem !important;
    top: 15vh;
    bottom: 0 !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__view-product {
    font-size: 2.5rem !important;
    top: 7vh;
    bottom: 0 !important;
  }
}
.card-product-shop__view-product--video {
  display: flex;
  margin-left: 1rem;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  z-index: 100;
}
@media (max-width: 75em) {
  .card-product-shop__view-product--video {
    font-size: 1.3rem !important;
  }
}
@media (max-width: 56.25em) {
  .card-product-shop__view-product--video {
    font-size: 3rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__view-product--video {
    margin-top: 3.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__view-product--video {
    font-size: 2.5rem !important;
    margin-top: -11rem !important;
  }
}
.card-product-shop__view-product--view {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  margin-top: 3rem !important;
  color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 56.25em) {
  .card-product-shop__view-product--view {
    margin-top: 6rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__view-product--view {
    margin-top: 3.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__view-product--view {
    margin-top: 3rem !important;
  }
}
.card-product-shop__seller-video-name {
  width: 100%;
  display: flex;
  position: absolute;
  top: -2%;
  right: 20%;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  cursor: pointer;
}
.card-product-shop__seller-name-2 {
  width: 85%;
  display: flex;
  font-size: 1.6rem;
  color: #fff;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
  position: absolute;
  left: 30%;
  cursor: pointer;
  margin-top: -5%;
}
@media (max-width: 56.25em) {
  .card-product-shop__seller-name-2 {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__seller-name-2 {
    font-size: 3rem !important;
    margin-top: 5% !important;
    left: 25%;
  }
}
.card-product-shop__add-to-cart {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 60%;
  font-size: 1.5rem;
  color: #ff7730;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 18vh;
  left: 40%;
}
@media (max-width: 37.5em) {
  .card-product-shop__add-to-cart {
    font-size: 3.2rem;
    top: 14vh;
    width: 60%;
    left: 40%;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__add-to-cart {
    font-size: 2.7rem;
    top: 6vh;
    width: 60%;
    left: 40%;
  }
}
.card-product-shop__add-to-cart--home {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 40%;
  font-size: 1.7rem;
  font-weight: 700 !important;
  color: #ff7730 !important;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 17vh;
  left: 30%;
  z-index: 10000;
  cursor: pointer !important;
}
@media (max-width: 75em) {
  .card-product-shop__add-to-cart--home {
    top: 20vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 56.25em) {
  .card-product-shop__add-to-cart--home {
    font-size: 2.7rem;
    top: 10.5vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__add-to-cart--home {
    font-size: 2.7rem;
    top: 7vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__add-to-cart--home {
    font-size: 2.7rem;
    top: 6vh;
    width: 60%;
    left: 20%;
  }
}
.card-product-shop__product-color {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: center;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 9vh;
  left: 0%;
}
@media (max-width: 75em) {
  .card-product-shop__product-color {
    top: 11vh;
    margin-right: 2rem !important;
  }
}
@media (max-width: 56.25em) {
  .card-product-shop__product-color {
    top: 5vh;
    font-size: 2.5rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__product-color {
    position: absolute;
    top: 7.5vh;
    left: -5%;
    font-size: 3rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__product-color {
    position: absolute;
    top: 1vh;
    left: -5%;
    font-size: 2.5rem !important;
    margin-right: 2rem !important;
  }
}
.card-product-shop__product-size {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: space-around;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 9vh;
  left: 52%;
}
@media (max-width: 75em) {
  .card-product-shop__product-size {
    width: fit-content;
    margin-left: 2rem !important;
    top: 11vh;
  }
}
@media (max-width: 56.25em) {
  .card-product-shop__product-size {
    width: fit-content;
    font-size: 2.5rem !important;
    top: 5vh;
    left: 60%;
  }
}
@media (max-width: 37.5em) {
  .card-product-shop__product-size {
    width: fit-content;
    font-size: 3rem !important;
    top: 7.5vh;
    left: 62%;
  }
}
@media (max-width: 23.4375em) {
  .card-product-shop__product-size {
    width: fit-content;
    font-size: 2.5rem !important;
    top: 1vh;
    left: 62%;
  }
}
.card-product-shop__rating {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  padding-top: 1rem !important;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
}
@media (max-width: 37.5em) {
  .card-product-shop__rating {
    padding-top: 2rem !important;
  }
}
.card-product-shop__rating-showAverage {
  width: 100%;
  display: flex;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  position: absolute;
  left: 50%;
}
@media (max-width: 37.5em) {
  .card-product-shop__rating-showAverage {
    font-size: 0.02rem !important;
    left: 30%;
  }
}
.card-product-shop__rating-message {
  width: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}
@media (max-width: 37.5em) {
  .card-product-shop__rating-message {
    font-size: 3.5rem;
    padding-top: 2rem;
    justify-content: center !important;
    justify-items: center !important;
  }
}

.card-product-related {
  display: flex;
  justify-content: space-between;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  width: 98%;
  height: 35rem;
  background-color: transparent;
  border: none;
  padding: 2rem 1.5rem;
}
@media (max-width: 56.25em) {
  .card-product-related {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-related {
    height: 35vh;
  }
}
.card-product-related__side {
  height: 35rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}
@media (max-width: 37.5em) {
  .card-product-related__side {
    height: 35vh;
  }
}
.card-product-related__side--front {
  background-color: rgba(255, 255, 255, 0.8);
}
.card-product-related__side--front::after {
  width: 100%;
}
.card-product-related__side--back {
  transform: rotateY(180deg);
  height: 100%;
  width: 100% !important;
}
.card-product-related__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-product-related__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-product-related__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.1) 0%, black 35%, #5643fa 100%);
}
.card-product-related:hover .card-product-related__side--back {
  transform: rotateY(0);
}
.card-product-related__picture {
  position: absolute;
  object-fit: fill;
  width: 98% !important;
  height: 97% !important;
  min-height: 35rem;
  max-height: 35rem;
  transition: filter 0.5s;
  background-blend-mode: color-burn;
  -webkit-transition: filter 0.5s;
  -moz-transition: filter 0.5s;
  -ms-transition: filter 0.5s;
  -o-transition: filter 0.5s;
}
@media (max-width: 37.5em) {
  .card-product-related__picture {
    width: 90vw;
    height: 34vh !important;
    max-height: 34vh;
  }
}
.card-product-related__picture::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
@media (max-width: 37.5em) {
  .card-product-related__picture::before {
    top: 14vw;
    left: 90%;
    width: 0.1rem;
    height: 50%;
    background-color: #ff7730;
  }
}
.card-product-related__picture::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-product-related__picture:hover::after {
  width: 0 !important;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-product-related__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 54%;
  right: 5%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
@media (max-width: 37.5em) {
  .card-product-related__seller-name-1 {
    font-size: 3rem;
    right: 0%;
    padding-left: 15rem;
  }
}
.card-product-related__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-product-related__video-category-sub-product {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 81%;
  right: 5%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
  font-weight: 500;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
  padding: 0.3rem 3rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-product-related__video-category-sub-product:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
}
.card-product-related__video-category-sub-product:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.card-product-related__video-category-sub-product:active, .card-product-related__video-category-sub-product:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(8, 94, 175, 0.2) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.card-product-related__heading {
  font-weight: bolder !important;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
@media (max-width: 37.5em) {
  .card-product-related__heading {
    top: 3rem;
  }
}
.card-product-related__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card-product-related__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-product-related__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-product-related__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-product-related__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-product-related__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 30rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 10rem;
  width: 85%;
  z-index: 10;
}
.card-product-related__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-product-related__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-product-related__back-header-box {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  justify-items: center;
  background-color: #182a41;
  position: absolute;
}
.card-product-related__back-footer-box {
  width: 100%;
  height: 10%;
  color: white;
  background-color: #232f3e;
  position: absolute;
  bottom: 0.2%;
}
.card-product-related__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 25%;
  width: 100%;
  height: 64%;
  text-align: center;
}
.card-product-related__cta::before {
  content: "";
  position: absolute;
  top: 0.1vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
@media (max-width: 37.5em) {
  .card-product-related__cta::before {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.card-product-related__cta::after {
  content: "";
  position: absolute;
  top: 18%;
  left: 3rem;
  width: 85%;
  height: 0.01rem;
  background-color: white;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-product-related__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  position: absolute;
  top: 30%;
  left: 45%;
}
@media (max-width: 37.5em) {
  .card-product-related__price-box {
    position: absolute;
    top: 5vh;
    left: 42%;
  }
}
.card-product-related__related-product-seller-name {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 0.5rem auto;
  margin-top: -0.5rem;
  color: white;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 3rem;
  position: absolute;
  cursor: pointer !important;
}
@media (max-width: 37.5em) {
  .card-product-related__related-product-seller-name {
    margin-top: 5rem;
  }
}
.card-product-related__price-value {
  font-size: 2rem !important;
  font-weight: 400;
}
@media (max-width: 56.25em) {
  .card-product-related__price-value {
    font-size: 5rem !important;
  }
}
.card-product-related__view-product {
  display: flex;
  width: 100% !important;
  height: fit-content;
  justify-content: space-around !important;
  font-size: 1.5rem;
  padding: 5rem auto;
  position: absolute;
  bottom: -3vh;
}
@media (max-width: 56.25em) {
  .card-product-related__view-product {
    font-size: 2.5rem !important;
    bottom: -2vh !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__view-product {
    font-size: 3.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__view-product {
    font-size: 2.5rem !important;
    top: 7vh;
    bottom: 0 !important;
  }
}
.card-product-related__view-product--video {
  display: flex;
  margin-left: 1rem;
  padding-bottom: 5rem;
  color: rgba(255, 255, 255, 0.9);
  z-index: 100;
}
@media (max-width: 75em) {
  .card-product-related__view-product--video {
    font-size: 1.3rem !important;
  }
}
@media (max-width: 56.25em) {
  .card-product-related__view-product--video {
    font-size: 3rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__view-product--video {
    font-size: 4rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__view-product--video {
    font-size: 2.5rem !important;
    margin-top: -11rem !important;
  }
}
.card-product-related__view-product--view {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  margin-top: 3rem !important;
  color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 56.25em) {
  .card-product-related__view-product--view {
    margin-top: 6rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__view-product--view {
    margin-top: 3.5rem !important;
    padding-bottom: 5rem;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__view-product--view {
    margin-top: 3rem !important;
  }
}
.card-product-related__seller-video-name {
  width: 100%;
  display: flex;
  position: absolute;
  top: -2%;
  right: 20%;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  cursor: pointer;
}
.card-product-related__seller-name-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  color: #fff;
  padding: 0 1rem;
  position: absolute;
  left: 20%;
  cursor: pointer;
  margin-top: 5%;
}
@media (max-width: 56.25em) {
  .card-product-related__seller-name-2 {
    font-size: 2.5rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__seller-name-2 {
    margin-top: -1rem;
    font-size: 4rem !important;
  }
}
.card-product-related__add-to-cart {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 60%;
  font-size: 1.5rem;
  color: #ff7730;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 19vh;
  left: 40%;
}
@media (max-width: 37.5em) {
  .card-product-related__add-to-cart {
    font-size: 4rem;
    top: 14vh;
    width: 60%;
    left: 40%;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__add-to-cart {
    font-size: 2.7rem;
    top: 6vh;
    width: 60%;
    left: 40%;
  }
}
.card-product-related__add-to-cart--home {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 40%;
  font-size: 1.7rem;
  font-weight: 700 !important;
  color: #ff7730 !important;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 17vh;
  left: 30%;
  z-index: 10000;
  cursor: pointer !important;
}
@media (max-width: 75em) {
  .card-product-related__add-to-cart--home {
    top: 20vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 56.25em) {
  .card-product-related__add-to-cart--home {
    font-size: 2.7rem;
    top: 10.5vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__add-to-cart--home {
    font-size: 2.7rem;
    top: 7vh;
    width: 60%;
    left: 20%;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__add-to-cart--home {
    font-size: 2.7rem;
    top: 6vh;
    width: 60%;
    left: 20%;
  }
}
.card-product-related__product-color {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: center;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 11vh;
  left: 0%;
}
@media (max-width: 75em) {
  .card-product-related__product-color {
    top: 11vh;
    margin-right: 2rem !important;
  }
}
@media (max-width: 56.25em) {
  .card-product-related__product-color {
    top: 5vh;
    font-size: 2.5rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__product-color {
    position: absolute;
    top: 7.5vh;
    left: -5%;
    font-size: 3rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__product-color {
    position: absolute;
    top: 1vh;
    left: -5%;
    font-size: 2.5rem !important;
    margin-right: 2rem !important;
  }
}
.card-product-related__product-size {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: space-around;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 11vh;
  left: 52%;
}
@media (max-width: 75em) {
  .card-product-related__product-size {
    width: fit-content;
    margin-left: 2rem !important;
    top: 11vh;
  }
}
@media (max-width: 56.25em) {
  .card-product-related__product-size {
    width: fit-content;
    font-size: 2.5rem !important;
    top: 5vh;
    left: 60%;
  }
}
@media (max-width: 37.5em) {
  .card-product-related__product-size {
    width: fit-content;
    font-size: 3rem !important;
    top: 7.5vh;
    left: 62%;
  }
}
@media (max-width: 23.4375em) {
  .card-product-related__product-size {
    width: fit-content;
    font-size: 2.5rem !important;
    top: 1vh;
    left: 62%;
  }
}
.card-product-related__rating {
  padding: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: -10%;
}
.card-product-related__rating-showAverage {
  width: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 1rem;
  padding-left: 8rem;
}
@media (max-width: 37.5em) {
  .card-product-related__rating-showAverage {
    display: flex;
    font-size: 3rem;
    padding-top: 5rem;
    padding-left: 10rem;
  }
}
.card-product-related__rating-message {
  width: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}
@media (max-width: 37.5em) {
  .card-product-related__rating-message {
    font-size: 3.5rem;
    padding-top: 5rem;
    justify-content: center !important;
    justify-items: center !important;
  }
}

.card-product-video {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 98%;
  height: 20rem;
  background-color: transparent;
  border: none;
  padding: 1rem;
  margin-bottom: -5rem;
}
.card-product-video__side {
  height: 35rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}
.card-product-video__side--front {
  background-color: white;
}
.card-product-video__side--front::after {
  width: 100%;
}
.card-product-video__side--back {
  transform: rotateY(180deg);
}
.card-product-video__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-product-video__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-product-video__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.1) 0%, black 35%, #5643fa 100%);
}
.card-product-video:hover .card-product-video__side--back {
  transform: rotateY(0);
}
.card-product-video__picture {
  position: absolute;
  object-fit: fill;
  width: 98%;
  height: 97% !important;
  min-height: 35rem;
  max-height: 35rem;
  transition: filter 0.5s;
  background-blend-mode: color-burn;
  -webkit-transition: filter 0.5s;
  -moz-transition: filter 0.5s;
  -ms-transition: filter 0.5s;
  -o-transition: filter 0.5s;
}
.card-product-video__picture::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-product-video__picture::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-product-video__picture:hover::after {
  width: 0;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-product-video__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 54%;
  right: 5%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-product-video__seller-name-1__seller-name-1:hover {
  width: 0;
  opacity: 0;
  transition: width opacity 0.5s 0;
  -webkit-transition: width opacity 0.5s 0;
  -moz-transition: width opacity 0.5s 0;
  -ms-transition: width opacity 0.5s 0;
  -o-transition: width opacity 0.5s 0;
}
.card-product-video__video-category-sub-product {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 81%;
  right: 5%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
  font-weight: 500;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
  padding: 0.3rem 3rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-product-video__video-category-sub-product:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: transparent !important;
}
.card-product-video__video-category-sub-product:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.card-product-video__video-category-sub-product:active, .card-product-video__video-category-sub-product:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(8, 94, 175, 0.2) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.card-product-video__heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
.card-product-video__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card-product-video__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-product-video__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-product-video__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-product-video__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-product-video__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 30rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 10rem;
  width: 85%;
  z-index: 10;
}
.card-product-video__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-product-video__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-product-video__back-header-box {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  justify-items: center;
  background-color: #182a41;
  position: absolute;
}
.card-product-video__back-footer-box {
  width: 100%;
  height: 10%;
  color: white;
  background-color: #232f3e;
  position: absolute;
  bottom: 0.2%;
}
.card-product-video__cta {
  font-size: 1.1rem;
  display: block;
  position: absolute;
  top: 25%;
  width: 100%;
  height: 64%;
  text-align: center;
}
.card-product-video__cta::before {
  content: "";
  position: absolute;
  top: 1vw;
  left: 90%;
  width: 0.1rem;
  height: 25%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-product-video__cta::after {
  content: "";
  position: absolute;
  top: 18%;
  left: 3rem;
  width: 85%;
  height: 0.01rem;
  background-color: rgba(255, 255, 255, 0.7);
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-product-video__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  position: absolute;
  top: 24%;
  left: 45%;
}
.card-product-video__price-value {
  font-size: 2rem;
  font-weight: 400;
}
.card-product-video__view-product {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-around;
  font-size: 1.5rem;
  padding: 5rem auto;
  position: absolute;
  bottom: -3vh;
}
.card-product-video__view-product--video {
  display: flex;
  margin-left: 1rem;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  z-index: 100;
}
.card-product-video__view-product--view {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  color: rgba(255, 255, 255, 0.9);
}
.card-product-video__seller-video-name {
  width: 100%;
  display: flex;
  position: absolute;
  top: -2%;
  right: 20%;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  cursor: pointer;
}
.card-product-video__seller-name-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  padding: 0 1rem;
  position: absolute;
  left: 20%;
  cursor: pointer;
}
.card-product-video__add-to-cart {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 60%;
  font-size: 1.5rem;
  color: #ff7730;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 16vh;
  left: 40%;
}
.card-product-video__add-to-cart--home {
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap;
  width: 60%;
  font-size: 1.5rem;
  color: #ff7730;
  border-bottom: 0.2rem rgba(255, 255, 255, 0.2) solid;
  position: absolute;
  top: 17vh;
  left: 40%;
  z-index: 10000;
  cursor: pointer;
}
.card-product-video__product-color {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: center;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 9vh;
  left: 0%;
}
.card-product-video__product-size {
  display: flex;
  width: 50%;
  height: fit-content;
  justify-content: space-around;
  justify-items: space-around;
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 0.5rem;
  position: absolute;
  top: 9vh;
  right: 0%;
}
.card-product-video__rating {
  padding: 0 2rem;
  width: 100%;
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: -10%;
}
.card-product-video__rating-showAverage {
  width: 100%;
  display: flex;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  position: absolute;
  left: 50%;
}
.card-product-video__rating-message {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.card-shop-sub-category {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 80%;
  background-color: transparent;
  border: none;
  border: 0.1rem green solid;
}
.card-shop-sub-category__side {
  height: 28rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}
.card-shop-sub-category__side--front {
  background-color: white;
}
.card-shop-sub-category__side--front::after {
  width: 90%;
}
.card-shop-sub-category__side--back {
  transform: rotateY(180deg);
}
.card-shop-sub-category__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-shop-sub-category__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-shop-sub-category__side--back-3 {
  background-image: linear-gradient(to bottom, rgba(8, 94, 175, 0.1) 0%, black 35%, #5643fa 100%);
}
.card-shop-sub-category:hover .card-shop-sub-category__side--back {
  transform: rotateY(0);
}
.card-shop-sub-category__picture {
  position: absolute;
  top: 0;
  width: 26.2rem;
  min-height: 35rem;
  max-height: 35rem;
  transition: filter 0.5s;
  background-blend-mode: color-burn;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  -webkit-transition: filter 0.5s;
  -moz-transition: filter 0.5s;
  -ms-transition: filter 0.5s;
  -o-transition: filter 0.5s;
}
.card-shop-sub-category__picture::before {
  content: "";
  position: absolute;
  top: 4vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-shop-sub-category__picture::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 2rem;
  width: 90%;
  opacity: 1;
  height: 0.1rem;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem black;
  transition: width 0.4s;
  z-index: 10;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  -ms-transition: width 0.4s;
  -o-transition: width 0.4s;
}
.card-shop-sub-category__picture:hover::after {
  width: 0;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-shop-sub-category__seller-name-1 {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 2rem;
  font-weight: 700;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -ms-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.card-shop-sub-category__heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  width: 60%;
  transition: width 0.3s;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}
.card-shop-sub-category__heading-span {
  padding: 0 1.5rem 0 1.5rem;
}
.card-shop-sub-category__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-shop-sub-category__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-shop-sub-category__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-shop-sub-category__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-shop-sub-category__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 30rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 10rem;
  width: 85%;
  z-index: 10;
}
.card-shop-sub-category__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-shop-sub-category__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-shop-sub-category__back-header-box {
  width: 100%;
  height: 25%;
  background-color: #182a41;
  position: absolute;
}
.card-shop-sub-category__back-footer-box {
  width: 100%;
  height: 15%;
  color: white;
  background-color: #232f3e;
  position: absolute;
  bottom: 0.2%;
}
.card-shop-sub-category__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 25%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 59%;
  text-align: center;
}
.card-shop-sub-category__cta::before {
  content: "";
  position: absolute;
  top: 2vw;
  left: 90%;
  width: 0.1rem;
  height: 50%;
  background-color: white;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: height 0.3s;
  z-index: 10;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
}
.card-shop-sub-category__cta::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 2rem;
  width: 90%;
  height: 0.1rem;
  background-color: white;
  transition: width 0.5s 0;
  -webkit-transition: width 0.5s 0;
  -moz-transition: width 0.5s 0;
  -ms-transition: width 0.5s 0;
  -o-transition: width 0.5s 0;
}
.card-shop-sub-category__price-box {
  align-items: center;
  text-align: center;
  color: rgb(237, 237, 243);
  position: absolute;
  top: 2%;
  left: 40%;
}
.card-shop-sub-category__price-value {
  font-size: 2rem;
  font-weight: 400;
}
.card-shop-sub-category__view-product {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-evenly;
  font-size: 1.5rem;
  padding: 5rem auto;
  position: absolute;
  bottom: 5%;
}
.card-shop-sub-category__view-product--video {
  display: inline-block;
  margin-left: 1rem;
  color: #839cf6;
}
.card-shop-sub-category__view-product--view {
  display: inline-block;
  margin-left: 5rem;
  color: #839cf6;
}
.card-shop-sub-category__seller-video-name {
  display: inline-block;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.card-shop-sub-category__seller-name-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: #fff;
  padding: 0 1rem;
  position: absolute;
  top: 25%;
  left: 5%;
}
.card-shop-sub-category__add-to-cart {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 40%;
  margin-top: 1rem;
}
.card-shop-sub-category__rating {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 1%;
}
.card-shop-sub-category__rating-showAverage {
  width: 100%;
  display: flex;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  position: absolute;
  left: 50%;
}
.card-shop-sub-category__rating-message {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 1rem;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.card-seller-videos-new-arrivals {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  object-fit: fill !important;
  box-shadow: 0 1rem 1.5rem rgba(255, 255, 255, 0.5);
}
@media (max-width: 56.25em) {
  .card-seller-videos-new-arrivals {
    width: 35vw !important;
    height: 37rem !important;
    margin: 1rem !important;
  }
}
@media (max-width: 43.75em) {
  .card-seller-videos-new-arrivals {
    width: 35vw !important;
    height: 37rem !important;
    margin: 1rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-seller-videos-new-arrivals {
    width: 38vw !important;
    height: 30rem !important;
  }
}
@media (max-width: 31.25em) {
  .card-seller-videos-new-arrivals {
    width: 38vw !important;
    height: 100% !important;
  }
}
@media (max-width: 25em) {
  .card-seller-videos-new-arrivals {
    width: 37vw !important;
    height: 100% !important;
  }
}
@media (max-width: 23.4375em) {
  .card-seller-videos-new-arrivals {
    width: 37vw !important;
    height: 100% !important;
  }
}
.card-seller-videos-new-arrivals__picture {
  background-size: cover;
  overflow: hidden;
  width: 100% !important;
  height: 18rem !important;
  object-fit: fill !important;
}
@media (max-width: 56.25em) {
  .card-seller-videos-new-arrivals__picture {
    object-fit: fill !important;
    width: 100% !important;
    height: 37rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-seller-videos-new-arrivals__picture {
    object-fit: fill !important;
    width: 100% !important;
    min-height: 30rem !important;
    max-height: 31rem;
  }
}
@media (max-width: 31.25em) {
  .card-seller-videos-new-arrivals__picture {
    object-fit: fill !important;
    width: 100% !important;
    min-height: 100% !important;
  }
}
.card-seller-videos-new-arrivals__heading {
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  color: rgb(14, 81, 189);
  position: absolute;
  top: 0rem;
  width: cover;
}
.card-seller-videos-new-arrivals__heading-span {
  width: 20rem;
  padding: 0 1.5rem 0 1.5rem;
}
.card-seller-videos-new-arrivals__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-seller-videos-new-arrivals__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-seller-videos-new-arrivals__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-seller-videos-new-arrivals__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}

.card-seller-podcasts-new-arrivals {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  object-fit: fill !important;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 56.25em) {
  .card-seller-podcasts-new-arrivals {
    width: 35vw !important;
    height: 30% !important;
    margin: 1rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-seller-podcasts-new-arrivals {
    width: 37vw !important;
    height: 30rem !important;
  }
}
.card-seller-podcasts-new-arrivals__picture {
  background-size: cover;
  width: 100% !important;
  margin: 0 auto;
  overflow: hidden;
  object-fit: fill !important;
  height: 18rem;
}
@media (max-width: 56.25em) {
  .card-seller-podcasts-new-arrivals__picture {
    object-fit: fill !important;
    width: 100% !important;
    height: 37rem !important;
  }
}
@media (max-width: 37.5em) {
  .card-seller-podcasts-new-arrivals__picture {
    object-fit: fill !important;
    width: 100% !important;
    height: 30rem !important;
  }
}
.card-seller-podcasts-new-arrivals__heading {
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  color: rgb(14, 81, 189);
  position: absolute;
  top: 0;
  left: -1rem;
  width: 60%;
}
.card-seller-podcasts-new-arrivals__heading-span {
  width: 20rem;
  padding: 0 1.5rem 0 1.5rem;
}
.card-seller-podcasts-new-arrivals__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-seller-podcasts-new-arrivals__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-seller-podcasts-new-arrivals__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-seller-podcasts-new-arrivals__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-seller-podcasts-new-arrivals__details {
  padding: 0.5rem 1rem;
  margin-top: 22.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: black;
  position: absolute;
  width: 80%;
}
.card-seller-podcasts-new-arrivals__details ul {
  list-style: none;
  width: 100%;
  margin: -22rem auto 5rem auto;
}
.card-seller-podcasts-new-arrivals__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card-seller-podcasts-new-arrivals__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  margin-top: 15%;
}
.card-seller-podcasts-new-arrivals__price-value {
  font-size: 4rem;
  font-weight: 100;
}
.card-seller-podcasts-new-arrivals__view-product {
  display: block;
  padding-top: -2rem;
  padding-bottom: 0.5rem;
  margin-top: -1rem;
}
.card-seller-podcasts-new-arrivals__view-product--video {
  display: inline-block;
  margin-left: 1rem;
  color: #839cf6;
}
.card-seller-podcasts-new-arrivals__view-product--view {
  display: inline-block;
  margin-left: 5rem;
  color: #839cf6;
}
.card-seller-podcasts-new-arrivals__seller-video-name {
  display: inline-block;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.card-seller-podcasts-new-arrivals__seller-name-1 {
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
}
.card-seller-podcasts-new-arrivals__seller-name-2 {
  display: block;
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
}
.card-seller-podcasts-new-arrivals__rating {
  font-size: 1.5rem;
  text-align: center;
}
.card-seller-podcasts-new-arrivals__rating-showAverage {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.card-seller-single-podcast {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 100%;
  background-color: rgba(6, 57, 112, 0.4);
  border: none;
  margin: 0.5rem 3rem;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
}
.card-seller-single-podcast__side {
  height: 35rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  overflow: hidden;
}
.card-seller-single-podcast__side--front {
  background-color: white;
}
.card-seller-single-podcast__side--back {
  transform: rotateY(180deg);
}
.card-seller-single-podcast__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-seller-single-podcast__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-seller-single-podcast__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.7), #5643fa);
}
.card-seller-single-podcast:hover .card-seller-single-podcast__side--front {
  transform: rotateY(-180deg);
}
.card-seller-single-podcast:hover .card-seller-single-podcast__side--back {
  transform: rotateY(0);
}
.card-seller-single-podcast__picture {
  background-size: cover;
  overflow: hidden;
  width: 91%;
  margin: 0 auto;
}
.card-seller-single-podcast__heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  color: rgb(14, 81, 189);
  position: absolute;
  top: 0.1rem;
  left: -1rem;
  width: 60%;
}
.card-seller-single-podcast__heading-span {
  width: 20rem;
  padding: 0 1.5rem 0 1.5rem;
}
.card-seller-single-podcast__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-seller-single-podcast__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-seller-single-podcast__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-seller-single-podcast__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-seller-single-podcast__details {
  padding: 0.5rem 1rem;
  margin-top: 22.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: black;
  position: absolute;
  width: 80%;
}
.card-seller-single-podcast__details ul {
  list-style: none;
  width: 100%;
  margin: -22rem auto 5rem auto;
}
.card-seller-single-podcast__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card-seller-single-podcast__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  margin-top: 15%;
}
.card-seller-single-podcast__price-value {
  font-size: 4rem;
  font-weight: 100;
}
.card-seller-single-podcast__view-product {
  display: block;
  padding-top: -2rem;
  padding-bottom: 0.5rem;
  margin-top: -1rem;
}
.card-seller-single-podcast__view-product--video {
  display: inline-block;
  margin-left: 1rem;
  color: #839cf6;
}
.card-seller-single-podcast__view-product--view {
  display: inline-block;
  margin-left: 5rem;
  color: #839cf6;
}
.card-seller-single-podcast__seller-video-name {
  display: inline-block;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.card-seller-single-podcast__seller-name-1 {
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
}
.card-seller-single-podcast__seller-name-2 {
  display: block;
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
}
.card-seller-single-podcast__rating {
  font-size: 1.5rem;
  text-align: center;
}
.card-seller-single-podcast__rating-showAverage {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.card-single-video-column {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 35rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.15);
}
.card-single-video-column__side {
  height: 35rem;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 6px;
  overflow: hidden;
}
.card-single-video-column__side--front {
  background-color: white;
}
.card-single-video-column__side--back {
  transform: rotateY(180deg);
}
.card-single-video-column__side--back-1 {
  background-image: linear-gradient(to right, rgba(255, 185, 0, 0.3), rgba(255, 119, 48, 0.3));
}
.card-single-video-column__side--back-2 {
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-single-video-column__side--back-3 {
  background-image: linear-gradient(to right, rgba(8, 94, 175, 0.7), #5643fa);
}
.card-single-video-column:hover .card-single-video-column__side--front {
  transform: rotateY(-180deg);
}
.card-single-video-column:hover .card-single-video-column__side--back {
  transform: rotateY(0);
}
.card-single-video-column__picture {
  overflow: hidden;
  width: 100%;
  height: 35rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.card-single-video-column__heading {
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  color: rgb(14, 81, 189);
  position: absolute;
  top: 0rem;
  width: cover;
}
.card-single-video-column__heading-span {
  width: 20rem;
  padding: 0 1.5rem 0 1.5rem;
}
.card-single-video-column__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.1), rgba(255, 119, 48, 0.1));
}
.card-single-video-column__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.3), rgba(40, 180, 133, 0.3));
}
.card-single-video-column__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.3), rgba(86, 67, 250, 0.3));
}
.card-single-video-column__heading-span--4 {
  background-image: linear-gradient(to left bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
}
.card-single-video-column__details {
  padding: 2.5rem 0.2rem 1rem 0.2rem;
  margin-top: 25.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  position: absolute;
  top: 12rem;
  width: 100%;
}
.card-single-video-column__details ul {
  list-style: none;
  width: 100%;
  margin: -10rem auto;
}
.card-single-video-column__details-span {
  text-align: left;
  padding-left: 0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card-single-video-column__cta {
  font-size: 1.1rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card-single-video-column__price-box {
  text-align: center;
  color: rgb(237, 237, 243);
  margin-top: 15%;
}
.card-single-video-column__price-value {
  font-size: 4rem;
  font-weight: 100;
}
.card-single-video-column__view-product {
  display: block;
  padding-top: -2rem;
  padding-bottom: 0.5rem;
  margin-top: -1rem;
}
.card-single-video-column__view-product--video {
  display: inline-block;
  margin-left: 1rem;
  color: #839cf6;
}
.card-single-video-column__view-product--view {
  display: inline-block;
  margin-left: 5rem;
  color: #839cf6;
}
.card-single-video-column__seller-video-name {
  display: inline-block;
  justify-content: space-between;
  justify-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.card-single-video-column__seller-name-1 {
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding-left: 5rem;
}
.card-single-video-column__seller-name-2 {
  display: block;
  color: #839cf6;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
}
.card-single-video-column__rating {
  font-size: 1.5rem;
  text-align: center;
}
.card-single-video-column__rating-showAverage {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.videoCard {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  background-color: #063970;
  margin-bottom: 5rem;
  overflow: hidden;
  transition: all 0.2s;
  z-index: 1;
}
.videoCard__heading-seller-video-title {
  position: absolute;
  left: 0;
  font-weight: 700;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  padding-left: 2rem;
  margin-top: -3rem;
  padding-right: 1rem;
}
.videoCard__details-seller-video-description {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem !important;
  font-weight: 500;
  padding: 1.3rem auto 1.3rem auto;
  padding-left: 1.5rem;
  color: rgba(82, 80, 80, 0.7);
  background-color: rgba(8, 94, 175, 0.1);
  margin: -1.3rem -1rem -1.2rem -1rem;
  overflow: hidden;
}
.videoCard__play-video-add-to-cart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 0;
  color: rgba(255, 255, 255, 0.9) !important;
  background-color: rgba(6, 57, 112, 0.4);
  padding-top: 1rem;
  padding-bottom: 1rem !important;
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 0rem;
  width: 100%;
  text-align: center;
  z-index: 0;
}
.videoCard__play-video-add-to-cart:hover {
  transform: scale(1.1) translateY(-0.5rem);
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.2);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.5rem);
  -moz-transform: scale(1.1) translateY(-0.5rem);
  -ms-transform: scale(1.1) translateY(-0.5rem);
  -o-transform: scale(1.1) translateY(-0.5rem);
}
.videoCard__play-podcast-add-to-cart {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  justify-content: center;
  justify-items: center;
  z-index: 0;
}
.videoCard__play-podcast-add-to-cart:hover {
  transform: scale(1.1) translateY(-0.5rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.5rem);
  -moz-transform: scale(1.1) translateY(-0.5rem);
  -ms-transform: scale(1.1) translateY(-0.5rem);
  -o-transform: scale(1.1) translateY(-0.5rem);
}
.videoCard__select--podcast {
  display: flex;
  position: absolute;
  left: 5;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 1rem;
  padding-right: 2rem;
  margin-top: -5rem;
  padding-bottom: 0rem;
  width: 90%;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  justify-content: center;
  justify-items: center;
  z-index: 0;
}
.videoCard__select--podcast:hover {
  transform: scale(1.1) translateY(-0.5rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.5rem);
  -moz-transform: scale(1.1) translateY(-0.5rem);
  -ms-transform: scale(1.1) translateY(-0.5rem);
  -o-transform: scale(1.1) translateY(-0.5rem);
}
.videoCard__play-video {
  display: inline-block;
}
.videoCard__add-to-cart {
  display: inline-block;
  padding-left: 3rem;
}
.videoCard__logo {
  width: fit-content;
}

.single-video {
  background-image: linear-gradient(to left bottom, rgba(6, 14, 247, 0.7), rgba(8, 16, 247, 0.7));
}

.card-seller-videos-products {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  padding: 1rem 0 0 2rem;
}
.card-seller-videos-products__height-scroll {
  height: 95rem;
  width: 100%;
  padding-bottom: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.card-seller-videos-products__height-post-scroll {
  height: 60vh;
  width: 100%;
  margin-top: 1.5rem;
  overflow-y: scroll;
}

.card-seller-single-video {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  padding: 1rem 0 2rem 2rem;
}
.card-seller-single-video__height-scroll {
  height: 105.5rem;
  width: 100%;
  padding-bottom: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.card-seller-single-video__height-post-scroll {
  height: 60vh;
  width: 100%;
  margin-top: 1.5rem;
  overflow-y: scroll;
}

.card-reviews-content {
  background-color: rgba(255, 255, 255, 0.55);
  color: #085eaf;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 2rem;
}
.card-reviews-content__height-scroll {
  height: 95%;
  width: 100%;
  padding-right: 1rem;
  padding-left: -1rem;
  overflow: scroll;
}

.card-single-video-reviews-content {
  background-color: rgba(8, 94, 175, 0.05);
  color: #085eaf;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  margin: 2rem auto;
  padding: 3rem 2rem 2.5rem 3rem !important;
}
.card-single-video-reviews-content__height-scroll {
  height: 50vh;
  width: 97%;
  overflow: scroll;
}

.card-single-product-reviews-content {
  width: 100% !important;
  background-color: rgba(86, 67, 250, 0.015);
  color: #085eaf;
  height: 65vh !important;
  overflow: scroll;
  backface-visibility: hidden;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
}
.card-single-product-reviews-content::-webkit-scrollbar {
  display: none;
}
@media (max-width: 37.5em) {
  .card-single-product-reviews-content {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    overflow: scroll;
    height: 60vh !important;
    padding-bottom: 5rem !important;
  }
}

.carousel-buttons {
  margin: 1rem 2rem;
  justify-content: center !important;
}

.card-single-product {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
  justify-items: space-around !important;
  color: #0e55b3;
  background-color: rgba(86, 67, 250, 0.04) !important;
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 37.5em) {
  .card-single-product {
    width: 100%;
    padding: 2rem;
  }
}

.card-single-video-not-rate-yet {
  margin-top: 21rem !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.card-single-video {
  color: #0e55b3;
  background-color: rgba(180, 172, 252, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.003);
}

.card-single-video-display {
  margin: -5rem auto -5rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.ant-card-body {
  padding: 1rem !important;
  font-size: 1rem;
  margin-bottom: 0rem;
}

.ant-card .ant-card-actions {
  background-color: rgba(86, 67, 250, 0.02);
}

.list-group-item {
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (max-width: 37.5em) {
  .list-group-item {
    font-size: 4rem;
    padding: 5rem;
  }
}
.list-group-item:hover {
  transform: scale(1) translateY(-0.2rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1) translateY(-0.2rem);
  -moz-transform: scale(1) translateY(-0.2rem);
  -ms-transform: scale(1) translateY(-0.2rem);
  -o-transform: scale(1) translateY(-0.2rem);
}

.like {
  font-size: 3rem;
  padding: 0.5rem;
  margin-right: 5rem;
}
.like::after {
  content: "";
  display: table;
  clear: both;
}

.card-share {
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #085eaf !important;
  justify-items: center;
  padding: 0.25rem;
}
.card-share::after {
  content: "";
  display: table;
  clear: both;
}

.selectPodcast {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  background-color: white;
  margin-bottom: 0rem;
  padding-bottom: 4rem;
  overflow: hidden;
  transition: all 0.2s;
  z-index: 1;
}
.selectPodcast__select-video {
  display: flex;
  position: absolute;
  left: 5;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 1rem;
  padding-right: 2rem;
  margin-top: -5rem;
  padding-bottom: 0rem;
  width: 90%;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  justify-content: center;
  justify-items: center;
  z-index: 0;
}
.selectPodcast__select-video:hover {
  transform: scale(1.1) translateY(-0.5rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.5rem);
  -moz-transform: scale(1.1) translateY(-0.5rem);
  -ms-transform: scale(1.1) translateY(-0.5rem);
  -o-transform: scale(1.1) translateY(-0.5rem);
}

.delete-customer-image {
  width: 94%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  justify-items: center;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.4);
  padding: 1rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.delete-customer-image:hover {
  transform: scale(0.9) translateY(-0.5rem);
  font-size: 2.3rem !important;
  font-weight: 700;
  color: rgba(255, 0, 0, 0.8) !important;
  background-color: rgba(8, 94, 175, 0.4);
  border: 0.1rem rgba(255, 255, 255, 0.8) solid;
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(0.9) translateY(-0.5rem);
  -moz-transform: scale(0.9) translateY(-0.5rem);
  -ms-transform: scale(0.9) translateY(-0.5rem);
  -o-transform: scale(0.9) translateY(-0.5rem);
}
.delete-customer-image__select--podcast {
  display: flex;
  position: absolute;
  top: 50%;
  color: rgba(255, 255, 255, 0.9);
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 0rem;
  width: 90%;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  justify-content: center;
  justify-items: center;
  z-index: 0;
}
.delete-customer-image__select--podcast:hover {
  transform: scale(1.1) translateY(-0.5rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.5rem);
  -moz-transform: scale(1.1) translateY(-0.5rem);
  -ms-transform: scale(1.1) translateY(-0.5rem);
  -o-transform: scale(1.1) translateY(-0.5rem);
}

.composition {
  position: relative;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2rem auto !important;
}
@media (max-width: 37.5em) {
  .composition {
    height: 0;
  }
}
.composition__single-pict {
  width: 95%;
  height: 80%;
  margin: 3rem auto;
}
.composition__single-pict:hover {
  outline: 0.5rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.3rem);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.3rem);
  -moz-transform: scale(1.1) translateY(-0.3rem);
  -ms-transform: scale(1.1) translateY(-0.3rem);
  -o-transform: scale(1.1) translateY(-0.3rem);
}
.composition__podcast {
  width: 100%;
  height: 100%;
}
.composition__podcast:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.3) translateY(-0.3rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.3) translateY(-0.3rem);
  -moz-transform: scale(1.3) translateY(-0.3rem);
  -ms-transform: scale(1.3) translateY(-0.3rem);
  -o-transform: scale(1.3) translateY(-0.3rem);
}
.composition__photo {
  width: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  margin: 2rem auto;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  position: absolute;
  transition: all 0.2s;
}
@media (max-width: 56.25em) {
  .composition__photo {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    transition: all 0.2s;
  }
}
@media (max-width: 37.5em) {
  .composition__photo {
    width: 0 !important;
    height: 0 !important;
  }
}
.composition__photo--p1 {
  left: 3rem;
  top: 4rem;
}
@media (max-width: 56.25em) {
  .composition__photo--p1 {
    left: 1%;
    top: 10%;
  }
}
.composition__photo--p2 {
  right: 6rem;
  top: -1rem;
  z-index: 2;
}
@media (max-width: 56.25em) {
  .composition__photo--p2 {
    left: 33%;
    top: -1%;
  }
}
.composition__photo--p3 {
  left: 15rem;
  top: 8rem;
}
@media (max-width: 56.25em) {
  .composition__photo--p3 {
    left: 60%;
    top: 20%;
  }
}
.composition__photo:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.5) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.5) translateY(-0.5rem);
  -moz-transform: scale(1.5) translateY(-0.5rem);
  -ms-transform: scale(1.5) translateY(-0.5rem);
  -o-transform: scale(1.5) translateY(-0.5rem);
}
.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

.composition-green-and-pink-pict-egg {
  position: relative;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2rem auto !important;
}
.composition-green-and-pink-pict-egg__single-pict {
  width: 90%;
  height: 98%;
  object-fit: fill;
  margin: 3rem auto;
  padding: 3rem;
  border-radius: 7%;
  -webkit-border-radius: 7%;
  -moz-border-radius: 7%;
  -ms-border-radius: 7%;
  -o-border-radius: 7%;
}
.composition-green-and-pink-pict-egg__single-pict:hover {
  transform: scale(1.1) translateY(-0.3rem);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.3rem);
  -moz-transform: scale(1.1) translateY(-0.3rem);
  -ms-transform: scale(1.1) translateY(-0.3rem);
  -o-transform: scale(1.1) translateY(-0.3rem);
}

.composition-create-form-3 {
  position: relative;
  height: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2rem auto;
}
.composition-create-form-3__single-pict {
  width: 70%;
  height: 80%;
  margin: 3rem auto 3rem auto;
  border-radius: 5%;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
}
.composition-create-form-3__single-pict:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.3) translateY(-0.3rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.3) translateY(-0.3rem);
  -moz-transform: scale(1.3) translateY(-0.3rem);
  -ms-transform: scale(1.3) translateY(-0.3rem);
  -o-transform: scale(1.3) translateY(-0.3rem);
}
.composition-create-form-3__podcast {
  width: 100%;
  height: 100%;
}
.composition-create-form-3__podcast:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.3) translateY(-0.3rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.3) translateY(-0.3rem);
  -moz-transform: scale(1.3) translateY(-0.3rem);
  -ms-transform: scale(1.3) translateY(-0.3rem);
  -o-transform: scale(1.3) translateY(-0.3rem);
}
.composition-create-form-3__photo {
  width: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  margin: 2rem auto;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  position: absolute;
  transition: all 0.2s;
}
.composition-create-form-3__photo--p1 {
  left: 3rem;
  top: 4rem;
}
.composition-create-form-3__photo--p2 {
  right: 6rem;
  top: -1rem;
  z-index: 1;
}
.composition-create-form-3__photo--p3 {
  left: 15rem;
  top: 8rem;
}
.composition-create-form-3__photo:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.5) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.5) translateY(-0.5rem);
  -moz-transform: scale(1.5) translateY(-0.5rem);
  -ms-transform: scale(1.5) translateY(-0.5rem);
  -o-transform: scale(1.5) translateY(-0.5rem);
}
.composition-create-form-3:hover .composition-create-form-3__photo:not(:hover) {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

.composition-seller-home-form-5 {
  object-fit: cover;
  height: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 0 !important;
}
.composition-seller-home-form-5__single-pict {
  width: 100%;
  height: 100%;
  margin-top: -3rem;
}
.composition-seller-home-form-5__single-pict:hover {
  outline: 0.3rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.3rem);
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.3);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.3rem);
  -moz-transform: scale(1.1) translateY(-0.3rem);
  -ms-transform: scale(1.1) translateY(-0.3rem);
  -o-transform: scale(1.1) translateY(-0.3rem);
}
.composition-seller-home-form-5__podcast {
  width: 100%;
  height: 100%;
}
.composition-seller-home-form-5__podcast:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.3) translateY(-0.3rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.3) translateY(-0.3rem);
  -moz-transform: scale(1.3) translateY(-0.3rem);
  -ms-transform: scale(1.3) translateY(-0.3rem);
  -o-transform: scale(1.3) translateY(-0.3rem);
}
.composition-seller-home-form-5__photo {
  width: 30rem;
  min-height: 30rem;
  max-height: 35rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  position: absolute;
  transition: all 0.2s;
}
.composition-seller-home-form-5__photo--p1 {
  left: 3rem;
  top: 4rem;
}
.composition-seller-home-form-5__photo--p2 {
  right: 8rem;
  top: -1rem;
  z-index: 1;
}
.composition-seller-home-form-5__photo--p3 {
  left: 15rem;
  top: 8rem;
}
.composition-seller-home-form-5__photo:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.5) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.5) translateY(-0.5rem);
  -moz-transform: scale(1.5) translateY(-0.5rem);
  -ms-transform: scale(1.5) translateY(-0.5rem);
  -o-transform: scale(1.5) translateY(-0.5rem);
}
.composition-seller-home-form-5:hover .composition-seller-home-form-5__photo:not(:hover) {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

.composition-cover-background {
  width: 100vw;
  border: 0.1rem red solid;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem auto !important;
}
.composition-cover-background__background-pict {
  width: 100%;
  height: 80%;
  margin: 0 auto;
}

.spinner {
  animation: spin 1s infinite ease-in-out;
  border-radius: 50%;
  border-top: 2px solid #fff;
  display: inline-block;
  height: 2em;
  margin: calc(50vh - 1em) calc(50vw - 1em);
  width: 2em;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.feature-box__icon {
  font-size: 6rem;
  margin-bottom: 0.5rem;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  color: transparent;
}
.feature-box:hover {
  transform: translateY(-1.5rem) scale(1.03);
}

.featureTopSeller-box {
  font-size: 1.5rem;
  padding: 2.5rem;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 3px;
  padding-bottom: 0 !important;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}

.feature-topSeller--p {
  margin-bottom: 10px;
  padding-bottom: 18px;
}

.feature-topSeller--div {
  margin-bottom: -5px;
  padding-bottom: -10px;
}

.col-image {
  max-height: 45vh;
  width: 30vw;
}

.carousel .thumbs-wrapper {
  margin: 1px !important;
  overflow: visible;
}

.new-form-control {
  width: 95% !important;
  height: 3.8rem !important;
  font-size: 1.8rem;
  color: #04399c;
  border: 0.1rem rgba(255, 255, 255, 0.05) solid;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0.8rem auto;
  margin-right: 0.5rem !important;
  padding: 0 2rem !important;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.new-form-control:active, .new-form-control:focus {
  outline: none !important;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-1px);
  box-shadow: 0 0.1rem 0.1rem rgba(14, 14, 15, 0.3);
  padding-left: 2rem !important;
}

.new-form-control-search {
  width: 95% !important;
  height: 3.8rem !important;
  font-size: 1.8rem;
  color: #04399c;
  border: 0.1rem rgba(255, 255, 255, 0.05) solid;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0.8rem auto;
  margin-right: 0.5rem !important;
  padding: 0 2rem !important;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .new-form-control-search {
    margin-right: 2rem !important;
  }
}
.new-form-control-search:active, .new-form-control-search:focus {
  outline: none !important;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-1px);
  box-shadow: 0 0.1rem 0.1rem rgba(14, 14, 15, 0.3);
  padding-left: 2rem !important;
}

.new-form-control-search-hidden {
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 0;
}

.register-form {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  justify-items: center !important;
  margin: 2rem auto !important;
  padding: 2rem;
  background-color: rgba(8, 94, 175, 0.02);
}

.form-delivery-confirmation-input-group {
  width: 85%;
  height: 65%;
  margin: 1rem auto !important;
  padding: 0 2rem !important;
}

.form__group-seller-create-home-forms {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 2rem auto;
  padding: 2rem 1rem 2rem 6rem;
  background-color: rgba(8, 94, 175, 0.02);
}
.form__group-seller-create-home-forms--subgroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 2rem 4rem 2rem -2rem;
  padding: 2rem 0 2rem 4rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.form__quantity-group {
  display: flex;
  justify-items: center;
  margin: 0.1rem 0 !important;
  padding: 0 0 0 2rem;
}
.form__quantity-group--color-discount {
  justify-content: flex-start !important;
  justify-items: center !important;
  padding: 0.5rem 2rem !important;
  background-color: rgba(255, 255, 255, 0.35);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  color: rgba(255, 119, 48, 0.8);
}
.form__quantity-group--option4-all-sizes-discount {
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  padding: 0.5rem 2rem !important;
  background-color: rgba(255, 255, 255, 0.35);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  color: rgba(255, 119, 48, 0.8);
}
.form__quantity-group--discount {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 119, 48, 0.8);
  border: rgba(255, 255, 255, 0.5) 0.1rem solid;
}
.form__quantity-group--invalidDiscount {
  margin-top: 2rem !important;
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(255, 119, 48, 0.3);
}
.form__option-2-quantity-group {
  display: flex;
  justify-content: center !important;
  justify-items: center;
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1.5rem auto !important;
  background-color: rgba(6, 57, 112, 0.03);
  border: 0.1rem white solid;
}
.form__group:not(:last-child) {
  margin: bottom 2rem;
}
.form__input {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-register {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin: 1rem auto;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__input-register {
    width: 71.5vw;
  }
}
.form__input-register:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-register:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-forgot-password {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin: 1rem auto;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__input-forgot-password {
    width: 75vw;
    font-size: 4rem;
  }
}
.form__input-forgot-password:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-forgot-password:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-post-text-area {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: black;
  border: none;
  border-bottom: 3px solid transparent !important;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input-post-text-area:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a !important;
}
.form__input-post-text-area:focus:invalid {
  border-bottom: 3px solid #ff7730 !important;
}
.form__input-select {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.05);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.3s;
}
.form__input-select:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-select:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-select::-webkit-input-placeholder {
  color: #999;
}
.form__input-1 {
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.05);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input-1:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-1:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-1::-webkit-input-placeholder {
  color: #999;
}
@media (max-width: 37.5em) {
  .form__input-1 {
    font-size: 3rem;
    padding: 0.1rem auto;
  }
}
.form__input-cart {
  font-size: 1.3rem;
  font-family: inherit;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  color: inherit;
  background-color: rgba(86, 67, 250, 0.01);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: fit-content;
  transition: all 0.3s;
}
.form__input-cart:active, .form__input-cart:focus {
  outline: none !important;
  border: none !important;
  color: #085eaf;
}
.form__input-cart:active:invalid, .form__input-cart:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-cart::-webkit-input-placeholder {
  color: #ff7730;
}
.form__input-cart-single-page {
  display: flex;
  flex-direction: row;
  justify-items: center !important;
  font-size: 1.4rem;
  font-family: inherit;
  font-weight: 600;
  text-align: center !important;
  text-transform: capitalize;
  color: #085eaf;
  margin-top: 0.4rem !important;
  padding: 0.5rem auto 1rem 1rem !important;
  background-color: rgba(255, 255, 255, 0.7);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 100%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 56.25em) {
  .form__input-cart-single-page {
    font-size: 2.5rem !important;
  }
}
.form__input-cart-single-page:active, .form__input-cart-single-page:focus, .form__input-cart-single-page:hover {
  outline: none !important;
  border: none !important;
  background-color: rgba(246, 236, 236, 0.5);
  color: #085eaf;
}
.form__input-cart-single-page:active:invalid, .form__input-cart-single-page:focus:invalid, .form__input-cart-single-page:hover:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-cart-single-page--color {
  padding: 1rem !important;
  margin-top: -1rem !important;
  margin-bottom: 1rem !important;
  text-align: center;
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 100%;
  transition: all 0.3s;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
.form__input-cart-single-page--color:active, .form__input-cart-single-page--color:focus, .form__input-cart-single-page--color:hover {
  outline: none !important;
  border: none !important;
  background-color: rgba(17, 126, 235, 0.5);
  color: #085eaf;
}
.form__input-cart-single-page--color:active:invalid, .form__input-cart-single-page--color:focus:invalid, .form__input-cart-single-page--color:hover:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-cart-single-page:hover {
  transform: scale(1) translateY(-0.2rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1) translateY(-0.2rem);
  -moz-transform: scale(1) translateY(-0.2rem);
  -ms-transform: scale(1) translateY(-0.2rem);
  -o-transform: scale(1) translateY(-0.2rem);
}
.form__input-product-items-list-single-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  justify-items: center !important;
  font-size: 1.4rem;
  font-family: inherit;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  color: #085eaf;
  margin-top: 0.4rem !important;
  padding: 2rem 1rem 0.1rem 1rem !important;
  background-color: rgba(255, 255, 255, 0.7);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 100%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .form__input-product-items-list-single-page {
    font-size: 4rem;
    color: #085eaf;
    padding: 2.5rem 5rem !important;
  }
}
.form__input-product-items-list-single-page:active, .form__input-product-items-list-single-page:focus, .form__input-product-items-list-single-page:hover {
  outline: none !important;
  border: none !important;
  background-color: rgba(246, 236, 236, 0.5);
  color: #085eaf;
}
.form__input-product-items-list-single-page:active:invalid, .form__input-product-items-list-single-page:focus:invalid, .form__input-product-items-list-single-page:hover:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-product-items-list-single-page--color {
  padding: 1rem !important;
  margin-top: -1rem !important;
  margin-bottom: 1rem !important;
  text-align: center;
  border: none !important;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
.form__input-product-items-list-single-page--color:active, .form__input-product-items-list-single-page--color:focus, .form__input-product-items-list-single-page--color:hover {
  outline: none !important;
  border: none !important;
  background-color: rgba(255, 255, 255, 0.07);
  color: #085eaf;
}
.form__input-product-items-list-single-page--color:active:invalid, .form__input-product-items-list-single-page--color:focus:invalid, .form__input-product-items-list-single-page--color:hover:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-product-items-list-single-page:hover {
  transform: scale(1) translateY(-0.2rem);
  color: rgba(8, 94, 175, 0.8);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1) translateY(-0.2rem);
  -moz-transform: scale(1) translateY(-0.2rem);
  -ms-transform: scale(1) translateY(-0.2rem);
  -o-transform: scale(1) translateY(-0.2rem);
}
.form__admin-dashboard-delivery-status {
  font-size: 1.6rem;
  font-family: inherit;
  color: #ff7730;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.13);
  border: none;
  border-bottom: 3px solid white;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__admin-dashboard-delivery-status:active, .form__admin-dashboard-delivery-status:focus {
  outline: none;
  border: none;
  background-color: rgba(119, 173, 240, 0.1);
  color: #085eaf;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.form__admin-dashboard-delivery-status:active:invalid, .form__admin-dashboard-delivery-status:focus:invalid {
  border-bottom: 3px solid #ff7730;
  background-color: black;
}
.form__admin-dashboard-delivery-status:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(119, 173, 240, 0.2);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.form__seller-detail-order-list-delivery-status {
  font-size: 1.6rem;
  font-family: inherit;
  color: #ff7730;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.13);
  border: none;
  border-bottom: 3px solid white;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__seller-detail-order-list-delivery-status:active, .form__seller-detail-order-list-delivery-status:focus {
  outline: none;
  border: none;
  background-color: rgba(119, 173, 240, 0.1);
  color: #085eaf;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.form__seller-detail-order-list-delivery-status:active:invalid, .form__seller-detail-order-list-delivery-status:focus:invalid {
  border-bottom: 3px solid #ff7730;
  background-color: black;
}
.form__seller-detail-order-list-delivery-status:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  background-color: rgba(119, 173, 240, 0.2);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.form__input-shipping-address-info {
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.05);
  border: none !important;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input-shipping-address-info:focus {
  outline: none !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-shipping-address-info:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-shipping-address-info:focus--city-state-code {
  width: 10%;
}
.form__input-shipping-address-info::-webkit-input-placeholder {
  color: #999;
}
@media (max-width: 37.5em) {
  .form__input-shipping-address-info {
    font-size: 2.5rem;
  }
}
.form__input-seller-create-home-forms {
  border: 0.3rem rgb(35, 30, 30) solid;
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin: 1rem 5rem 2rem 0;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.05);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input-seller-create-home-forms:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-seller-create-home-forms:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-seller-create-home-forms::-webkit-input-placeholder {
  color: #999;
}
.form__input-2 {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.15);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input-2:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-2:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-2::-webkit-input-placeholder {
  color: #999;
}
.form__input-general-coupon {
  font-size: 1.9rem;
  font-family: inherit;
  color: inherit;
  margin-bottom: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1rem black;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__input-general-coupon {
    font-size: 3rem;
  }
}
.form__input-general-coupon:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}
.form__input-general-coupon:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-general-coupon::-webkit-input-placeholder {
  color: #999;
}
.form__input-comment-label {
  font-size: 1.6rem;
  font-family: inherit;
  color: inherit;
  margin: 1rem auto;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.15);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input-comment-label:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-comment-label:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-comment-label::-webkit-input-placeholder {
  color: #999;
}
.form__input-post {
  width: 100%;
  font-size: 1.6rem;
  text-transform: none;
  font-family: inherit;
  color: #085eaf;
  margin: 1rem auto -3rem -1rem !important;
  padding: 0.5rem 0.2rem;
  border-radius: 2px;
  background-color: rgba(8, 94, 175, 0.05);
  border: none;
  border-bottom: 3px solid transparent;
  display: block;
  transition: all 0.3s;
}
.form__input-post:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-post:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-post::-webkit-input-placeholder {
  color: #999;
}
.form__input-comment-text {
  width: 100%;
  font-size: 1.6rem;
  text-transform: none;
  font-family: inherit;
  color: #085eaf;
  margin: 1rem auto 3rem auto;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(8, 94, 175, 0.08);
  border: none;
  border-bottom: 3px solid transparent;
  display: block;
  transition: all 0.3s;
}
.form__input-comment-text:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-comment-text:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-comment-text::-webkit-input-placeholder {
  color: #999;
}
.form__input-post-title {
  width: 100%;
  font-size: 1.8rem;
  text-transform: none;
  font-family: inherit;
  color: white;
  margin: 1rem auto;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(8, 94, 175, 0.2);
  border: none;
  border-bottom: 3px solid transparent;
  display: block;
  transition: all 0.3s;
}
.form__input-post-title:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-post-title:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-post-title::-webkit-input-placeholder {
  color: #999;
}
.form__input-post-title-seller-pub-vid {
  width: 100%;
  font-size: 1.8rem;
  text-transform: capitalize;
  font-family: inherit;
  color: white;
  margin: 1rem auto 0.1rem auto !important;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(8, 94, 175, 0.2);
  border: none;
  border-bottom: 3px solid transparent;
  display: block;
  transition: all 0.3s;
}
.form__input-post-title-seller-pub-vid:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input-post-title-seller-pub-vid:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input-post-title-seller-pub-vid::-webkit-input-placeholder {
  color: #999;
}
.form__input-post-text-area-seller-public-vid {
  font-size: 1.2rem;
  font-family: inherit;
  color: #085eaf;
  margin-top: 1rem;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  width: 100%;
  height: 100%;
  min-height: 35rem;
  transition: all 0.3s;
}
.form__input--create-category {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  margin-left: 2rem;
  margin-bottom: 2rem;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.12);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
.form__input--create-category:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input--create-category:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input--create-category::-webkit-input-placeholder {
  color: #999;
}
.form__label {
  font-size: 1.9rem;
  font-weight: 700 !important;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  letter-spacing: 0.3rem;
  display: flex;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__label {
    font-size: 3rem;
  }
}
.form__label-ship-address-region {
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__label-ship-address-region {
    color: black;
    font-size: 2rem;
    font-weight: 700;
  }
}
.form__label-contact-seller-customer-admin-topic {
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: -1rem;
  letter-spacing: 0.3rem;
  display: flex;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__label-contact-seller-customer-admin-topic {
    font-size: 2rem;
  }
}
.form__label-shipping {
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
.form__create-color-form {
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
.form__choose-color-option-note {
  width: fit-content;
  color: rgba(82, 80, 80, 0.8);
  border-bottom: rgba(86, 67, 250, 0.09) 0.5rem solid;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.02);
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.7rem auto !important;
  padding-bottom: 0.7rem;
  letter-spacing: 0.2rem;
  display: flex;
  transition: all 0.3s;
}
.form__shipping-label {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  transition: all 0.3s;
}
.form__color-name-label {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 1rem 0 0.5rem !important;
  padding: 1.5rem 1rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  transition: all 0.3s;
}
.form__color-name-label--text {
  text-transform: capitalize;
}
.form__option-5-quantity-label {
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 3rem;
  padding: 3rem;
  padding-left: 4rem;
  letter-spacing: 0.5rem;
  transition: all 0.3s;
}
.form__option-5-quantity-label--text {
  text-transform: capitalize;
}
.form--large-background {
  width: 75%;
  margin: 2rem auto !important;
  padding: 1rem auto;
  background-color: rgba(86, 67, 250, 0.05);
}
.form__size-quatities-label {
  width: 45%;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0 0.15rem 0.15rem rgba(86, 67, 250, 0.3);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 1rem 0 0.2rem 3rem !important;
  padding: 1.5rem;
  padding-left: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  transition: all 0.3s;
}
.form__size-quatities-label--text {
  text-transform: capitalize;
}
.form__additional-sizes-quatities-label {
  width: 60%;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(14, 85, 179, 0.7);
  background-color: rgba(255, 119, 48, 0.03);
  box-shadow: 0 0.3rem 0.3rem rgba(86, 67, 250, 0.3);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 1rem auto 1.3rem auto;
  padding: 1.5rem;
  padding-left: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.form__additional-sizes-quatities-label--text {
  text-transform: capitalize;
}
.form__additional-sizes-quatities-label:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: rgba(255, 119, 48, 0.05) !important;
  border: 0.2rem rgba(255, 255, 255, 0.5) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
.form__additional-sizes-quatities-label:active, .form__additional-sizes-quatities-label:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(255, 119, 48, 0.09) !important;
  border: 0.2rem rgba(255, 255, 255, 0.4) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-0.1rem);
  -ms-transform: translateY(-0.1rem);
  -o-transform: translateY(-0.1rem);
}
.form__additional-sizes-quatities-label-option-2 {
  width: 80%;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: rgba(255, 119, 48, 0.05);
  box-shadow: 0 0.3rem 0.3rem rgba(86, 67, 250, 0.3);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 1rem auto 1.3rem auto;
  padding: 1.5rem;
  padding-left: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.form__additional-sizes-quatities-label-option-2--text {
  text-transform: capitalize;
}
.form__additional-sizes-quatities-label-option-2:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: rgba(86, 67, 250, 0.1) !important;
  border: 0.2rem rgba(255, 255, 255, 0.5) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
.form__additional-sizes-quatities-label-option-2:active, .form__additional-sizes-quatities-label-option-2:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(86, 67, 250, 0.13) !important;
  border: 0.2rem rgba(255, 255, 255, 0.4) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-0.1rem);
  -ms-transform: translateY(-0.1rem);
  -o-transform: translateY(-0.1rem);
}
.form__view-option-1-and-2 {
  width: 70%;
  font-size: 1.3rem;
  font-weight: 400;
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0 0.3rem 0.3rem rgba(86, 67, 250, 0.3);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 1rem auto;
  padding: 1.5rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.form__view-option-1-and-2--text {
  text-transform: capitalize;
}
.form__view-option-1-and-2:hover {
  transform: translateY(-0.3rem);
  -webkit-transform: translateY(-0.3rem);
  background-color: rgba(86, 67, 250, 0.1) !important;
  border: 0.2rem rgba(255, 255, 255, 0.5) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
.form__view-option-1-and-2:active, .form__view-option-1-and-2:focus {
  outline: none;
  transform: translateY(-0.1rem);
  -webkit-transform: translateY(-0.1rem);
  background-color: rgba(86, 67, 250, 0.13) !important;
  border: 0.2rem rgba(255, 255, 255, 0.4) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
  -moz-transform: translateY(-0.1rem);
  -ms-transform: translateY(-0.1rem);
  -o-transform: translateY(-0.1rem);
}
.form__size-quatities-option-label {
  width: 80%;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.15rem 0.15rem rgba(86, 67, 250, 0.3);
  border: 0.2rem rgba(255, 255, 255, 0.8) solid;
  margin: 0.8rem auto 0.2rem auto !important;
  padding: 1.5rem;
  padding-left: 2rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  transition: all 0.3s;
}
.form__size-quatities-option-label--text {
  text-transform: capitalize;
}
.form__size-qty-label {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1.5rem 1rem 1rem -1rem !important;
  letter-spacing: 0.2rem;
  display: flex;
  justify-content: flex-start !important;
  justify-items: center;
  transition: all 0.3s;
}
.form__size-qty-label--text {
  text-transform: capitalize;
}
.form__sizes-qtys-label {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 2.3rem 1rem 1rem auto !important;
  letter-spacing: 0.2rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  transition: all 0.3s;
}
.form__sizes-qtys-label--text {
  text-transform: capitalize;
}
.form__label-upload-pic {
  font-size: 1.6rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
.form__label-2 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
.form__label--profile-address-edit-form {
  font-size: 1.7rem !important;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__label--profile-address-edit-form {
    font-size: 3rem !important;
  }
}
.form__label-shipping-address-update {
  color: black;
  font-size: 1.6rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5rem;
  display: flex;
  transition: all 0.3s;
}
@media (max-width: 37.5em) {
  .form__label-shipping-address-update {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 0.2rem;
  }
}
.form__label-press-release-name {
  width: 20% !important;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 2rem;
  margin-top: 7rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5rem;
  transition: all 0.3s;
}
.form__label-seller-profile--name {
  width: 50%;
  font-size: 1.7rem;
  font-weight: 700;
  color: rgba(8, 94, 175, 0.5);
  margin-right: 1rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  letter-spacing: 0.5rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding-bottom: -12rem;
  transition: all 0.3s;
}
.form__label-rating {
  font-size: 2.5rem;
  font-weight: 500;
  margin-left: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  letter-spacing: 0.3rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  color: #085eaf;
  transition: all 0.3s;
}
.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}
.form__radio-group {
  width: 49%;
  display: inline-block;
}
.form__radio-input {
  display: none;
}
.form__radio-label {
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  padding-left: 4.5rem;
}
.form__radio-button {
  height: 3rem;
  width: 3rem;
  border: 5px solid #55c57a;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -0.4rem;
  left: 0;
}
.form__radio-button::after {
  content: "";
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #55c57a;
  opacity: 0;
  transition: opacity 0.2s;
}
.form__radio-input:checked + .form__radio-label .form__radio-button::after {
  opacity: 1;
}

.form-1 {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-1__width {
  width: 90%;
  padding-left: 10rem;
  padding-right: 10rem;
  margin: 0 auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-dashboard-order {
  padding: 2rem 2rem 8rem 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-dashboard-order__width {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}
@media (max-width: 37.5em) {
  .form-dashboard-order__width {
    padding: 1rem auto !important;
  }
}
.form-dashboard-order__width-orders {
  width: 95%;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: -4rem auto auto auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}
.form-dashboard-order__width-list-users {
  width: 95%;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 38rem auto auto auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-single-product-add {
  height: fit-content;
  border: 2rem rgba(56, 116, 236, 0.6) solid;
  background-color: rgba(252, 244, 237, 0.5);
}
.form-single-product-add__width {
  width: 100%;
  max-height: fit-content;
  padding-top: 5rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 5rem auto 0 auto;
  border: 1px solid rgba(239, 240, 248, 0.9921568627);
}

.form-cart-order {
  height: fit-content;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.75);
  font-weight: 700;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-cart-order::after {
  content: "";
  display: table;
  clear: both;
}
.form-cart-order [class^=form-] {
  float: left;
}
.form-cart-order [class^=form-]:not(:last-child) {
  margin-right: 5rem;
}
.form-cart-order__width {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem auto 5rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-cart-order-summary {
  height: fit-content;
  padding: 2rem 0.1rem !important;
  padding-bottom: 4rem !important;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
}
.form-cart-order-summary::after {
  content: "";
  display: table;
  clear: both;
}
.form-cart-order-summary [class^=form-] {
  float: left;
}
.form-cart-order-summary [class^=form-]:not(:last-child) {
  margin-right: 5rem;
}
.form-cart-order-summary__width {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem auto 5rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-checkout-shipping-address-update {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-checkout-shipping-address-update__width {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 1rem auto 5rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}
@media (max-width: 37.5em) {
  .form-checkout-shipping-address-update__width {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 1rem 0 5rem 0;
  }
}

.form-checkout-payment {
  display: flex;
  justify-content: center;
  justify-items: center !important;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
}
.form-checkout-payment__width {
  display: flex;
  justify-items: center !important;
  padding: 4rem;
  margin: 5rem auto 31rem auto;
  border: 1px solid rgba(226, 226, 238, 0.9921568627);
}

.form-checkout-coupon {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.4);
}
.form-checkout-coupon__width {
  width: 80%;
  height: fit-content !important;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 1rem auto 1rem auto !important;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}
@media (max-width: 37.5em) {
  .form-checkout-coupon__width {
    width: 90%;
  }
}

.form-profile-shipping-address-update {
  padding: 2rem;
}
.form-profile-shipping-address-update__width {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: -3rem auto 5rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-checkout-summary {
  justify-content: center;
  justify-items: center;
  margin-top: 3rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-checkout-summary__width {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5rem;
  font-weight: 400;
}

.form-cart-summary {
  padding: 1rem 3rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-cart-summary__width {
  width: 90%;
  padding-bottom: 5rem;
  font-weight: 400;
  margin: 5rem auto !important;
}
@media (max-width: 37.5em) {
  .form-cart-summary__width {
    margin-top: 0 !important;
  }
}
.form-cart-summary__width-orders {
  width: 95%;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 0 auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-cart-table {
  margin-top: 5rem;
  padding-top: 2rem;
}

.form-dashboard-profile {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-dashboard-profile__width {
  width: 100%;
  padding-left: 15rem;
  padding-right: 5rem;
  margin: 5rem auto;
  border: 1px solid rgba(11, 27, 238, 0.9921568627);
}

.form-profile {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-profile__width {
  width: 85%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 5rem 0;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-create-videos {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-create-videos__width {
  width: 90%;
  padding-left: 10rem;
  padding-right: 10rem;
  margin: 0 auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-create-coupon {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-create-coupon__width {
  width: 90%;
  padding-left: 10rem;
  padding-right: 10rem;
  margin: 7rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-admin-create-coupon {
  margin-top: 25rem !important;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-admin-create-coupon__width {
  width: 90%;
  padding-left: 10rem;
  padding-right: 10rem;
  margin: 7rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-seller-create-product {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-seller-create-product__width {
  width: 90%;
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  margin: 7rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-seller-upload-delivery-pictures {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);
}
.form-seller-upload-delivery-pictures__width {
  width: 75%;
  height: fit-content;
  padding: 1rem;
  padding-top: 7rem;
  margin: 3rem auto;
  margin-top: 2rem;
  border-radius: 1rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.form-seller-upload-delivery-pictures:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.form-seller-upload-delivery-pictures:active, .form-seller-upload-delivery-pictures:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.form-contact-seller-customer-admin-topic {
  background-color: rgba(86, 67, 250, 0.04);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);
  border: 0.01rem solid rgba(0, 0, 0, 0.05);
}
.form-contact-seller-customer-admin-topic__width {
  width: 95%;
  height: fit-content;
  font-size: 1.7rem;
  padding: 1rem;
  margin: 0 2.5%;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
@media (max-width: 37.5em) {
  .form-contact-seller-customer-admin-topic__width {
    font-size: 3rem;
  }
}
.form-contact-seller-customer-admin-topic:hover {
  transform: translateY(-0.2px);
  -webkit-transform: translateY(-0.2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.02);
  -moz-transform: translateY(-0.2px);
  -ms-transform: translateY(-0.2px);
  -o-transform: translateY(-0.2px);
}
.form-contact-seller-customer-admin-topic:active, .form-contact-seller-customer-admin-topic:focus {
  outline: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.form-seller-press-release-pulic {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-seller-press-release-pulic__width {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  margin: 7rem auto;
  margin-left: 2rem;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-seller-customer-pictures {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
}
.form-seller-customer-pictures__width {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  margin: 7rem auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-admin-create-product {
  padding: 5rem;
  padding-left: 8rem;
  padding-right: 1rem;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
  color: #04399c;
}
.form-admin-create-product__width {
  width: 100%;
  margin: 0 auto 5rem auto;
}

.form-seller-create-product {
  padding: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
  color: #04399c;
}
.form-seller-create-product__width {
  width: 100%;
  margin: 0 auto 5rem auto;
}

.form-seller-create-home-form {
  padding: 5rem;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
  color: #04399c;
}
.form-seller-create-home-form__width {
  width: 100%;
  margin: 0 auto 5rem auto;
}

.form-create-category {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
.form-create-category__width {
  width: 90%;
  padding-left: 4rem;
  padding-right: 4rem;
  margin: 0 auto;
  border: 1px solid rgba(227, 228, 241, 0.9921568627);
}

.form-2 {
  padding: 5rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.02);
}

.form-general-coupon {
  padding: 5rem;
  background-color: rgba(86, 67, 250, 0.04);
  border: 0.1rem rgba(0, 0, 0, 0.08) solid;
}

.form-update-profile {
  padding: 5rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}

.form-2-shipping-address {
  padding: 6rem;
  padding-top: 12rem;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.02);
  margin-top: 5rem;
}
.form-2-shipping-address__width {
  width: 100%;
  margin: 2rem auto;
}

.form-2-profile {
  padding: 6rem 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.05);
}
.form-2-profile__width {
  width: 100%;
}

.form-3 {
  text-align: start;
  margin: -5rem 0 5rem 0;
  padding: 5rem 0rem 5rem 7rem;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.form-3__width {
  width: 75%;
  margin: 0 auto;
  border: 0.1rem solid #6b75fa;
}

.form-inline {
  width: 100%;
  height: 3.95rem;
  display: flex;
  font-size: 2.5rem;
}

.form-inline-search {
  width: 100%;
  height: 3.95rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-size: 2.5rem;
}

.form-control {
  width: 40vw !important;
  height: fit-content;
  font-size: 2.5rem;
  color: #04399c;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 1rem 0 0 !important;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
@media (max-width: 37.5em) {
  .form-control {
    width: 70vw !important;
    font-size: 3.5rem;
  }
}
.form-control:active, .form-control:focus {
  outline: none;
  font-size: 3rem;
  background-color: rgba(255, 255, 255, 0.8);
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(69, 81, 248, 0.1);
  padding-left: 2rem;
}

.form-tracking-input {
  width: 75%;
  height: 50vh;
  font-size: 1.5rem;
  color: #04399c;
  margin: 3rem auto !important;
  border: none !important;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.form-tracking-input__text-area {
  width: 90%;
  font-size: 1.5rem;
  color: #04399c;
  background-color: rgba(86, 67, 250, 0.03);
  padding: 2rem 3rem !important;
  margin: 3rem auto 0 auto !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}
.form-tracking-input__text-area:active, .form-tracking-input__text-area:focus {
  outline: none;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  background-color: rgba(151, 151, 152, 0.03);
}
.form-tracking-input__text-area-title {
  width: 65% !important;
  font-size: 1.7rem;
  font-weight: 700;
  color: #04399c;
  background-color: rgba(86, 67, 250, 0.03);
  box-shadow: 0 0.5rem 1rem rgba(69, 81, 248, 0.05);
  padding: 2rem 3rem !important;
  margin: 3rem auto 0 auto !important;
  border-radius: 1rem;
}

.form-delivery-confirmation {
  width: 100%;
  font-size: 1.5rem;
  color: #04399c;
  padding-bottom: 1rem;
  border: rgba(255, 255, 255, 0.8) solid 0.2rem !important;
  background-color: rgba(255, 255, 255, 0.01);
  box-shadow: 5rem 5rem 5rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
}
.form-delivery-confirmation__background {
  width: 65%;
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
  margin: 3rem auto !important;
  margin-top: -3rem !important;
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 5rem 5rem 5rem rgba(0, 0, 0, 0.3);
}
.form-delivery-confirmation__note {
  width: 75% !important;
  display: flex;
  flex-direction: row;
  margin: 0.5rem auto;
  padding: 0 2rem;
  background-color: rgba(40, 180, 133, 0.02);
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.2rem;
  text-align: justify;
}
.form-delivery-confirmation__note--title {
  display: flex;
  justify-content: flex-start;
  color: #ff7730;
}
.form-delivery-confirmation__note--text {
  display: flex;
}
.form-delivery-confirmation__text-area {
  width: 90%;
  font-size: 1.5rem;
  color: #04399c;
  background-color: rgba(86, 67, 250, 0.03);
  padding: 2rem 3rem !important;
  margin: 3rem auto 0 auto !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}
.form-delivery-confirmation__text-area:active, .form-delivery-confirmation__text-area:focus {
  outline: none;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  background-color: rgba(151, 151, 152, 0.03);
}
.form-delivery-confirmation__text-area-title {
  width: 90% !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: #04399c;
  background-color: rgba(255, 119, 48, 0.03);
  box-shadow: 0 0.5rem 1rem rgba(69, 81, 248, 0.1);
  padding: 2rem 3rem !important;
  margin: 3rem auto 0 auto !important;
  border-radius: 1rem;
}
.form-delivery-confirmation__upload-delivery-picture-title {
  width: 65% !important;
  font-size: 1.5rem;
  font-weight: 500;
  color: #04399c;
  background-color: rgba(86, 67, 250, 0.03);
  box-shadow: 0 0.5rem 1rem rgba(69, 81, 248, 0.05);
  padding: 2rem 3rem !important;
  margin: 0 auto 0 auto !important;
  border-radius: 1rem;
}

.form-contact-seller-customer-admin {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.7rem;
  color: #04399c;
  padding-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.03);
  box-shadow: 5rem 5rem 5rem rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}
.form-contact-seller-customer-admin__background {
  width: 65%;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  margin: 3rem auto !important;
  margin-top: -3rem !important;
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 5rem 5rem 5rem rgba(0, 0, 0, 0.3);
}
@media (max-width: 37.5em) {
  .form-contact-seller-customer-admin__background {
    width: 80vw;
  }
}
.form-contact-seller-customer-admin__note {
  width: 75% !important;
  display: flex;
  flex-direction: row;
  margin: 0.5rem auto;
  padding: 0 2rem;
  background-color: rgba(40, 180, 133, 0.02);
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
  text-align: justify;
}
@media (max-width: 37.5em) {
  .form-contact-seller-customer-admin__note {
    font-size: 2.5rem !important;
  }
}
.form-contact-seller-customer-admin__note--title {
  display: flex;
  font-size: 1.7rem;
  justify-content: flex-start;
  color: #ff7730;
}
@media (max-width: 37.5em) {
  .form-contact-seller-customer-admin__note--title {
    font-size: 2.5rem;
  }
}
.form-contact-seller-customer-admin__note--text {
  display: flex;
}
.form-contact-seller-customer-admin__text-area {
  width: 90%;
  font-size: 1.5rem;
  color: #04399c;
  background-color: rgba(86, 67, 250, 0.03);
  padding: 2rem 3rem !important;
  margin: 3rem auto 0 auto !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}
.form-contact-seller-customer-admin__text-area:active, .form-contact-seller-customer-admin__text-area:focus {
  outline: none;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  background-color: rgba(151, 151, 152, 0.03);
}
.form-contact-seller-customer-admin__text-area-title {
  width: 90% !important;
  font-size: 1.7rem;
  font-weight: 600;
  color: #04399c;
  background-color: rgba(255, 119, 48, 0.03);
  box-shadow: 0 0.5rem 1rem rgba(69, 81, 248, 0.1);
  padding: 2rem 3rem !important;
  margin: 3rem auto 0 auto !important;
  border-radius: 1rem;
}
@media (max-width: 37.5em) {
  .form-contact-seller-customer-admin__text-area-title {
    font-size: 3rem;
  }
}
.form-contact-seller-customer-admin__upload-delivery-picture-title {
  width: 65% !important;
  font-size: 1.5rem;
  font-weight: 500;
  color: #04399c;
  background-color: rgba(86, 67, 250, 0.03);
  box-shadow: 0 0.5rem 1rem rgba(69, 81, 248, 0.05);
  padding: 2rem 3rem !important;
  margin: 0 auto 0 auto !important;
  border-radius: 1rem;
}

.input, #phone {
  border: none !important;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
}

.shipping-rate {
  width: 15% !important;
  font-size: 1.4rem;
  font-family: inherit;
  color: inherit;
  margin-top: 0.5rem;
  margin-bottom: 0.9rem;
  margin-left: 3rem;
  padding: 0.5rem;
  border-radius: 2px;
  background-color: rgba(71, 15, 174, 0.02);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  width: 90%;
  transition: all 0.3s;
}
.shipping-rate:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-rate:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-rate::-webkit-input-placeholder {
  color: #999;
}

.shipping-plus-or {
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-family: inherit;
  color: inherit;
  margin-top: 0.7rem;
  margin-bottom: 0.9rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background-color: rgba(255, 119, 48, 0.02);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  width: 90%;
  transition: all 0.3s;
}
.shipping-plus-or:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-plus-or:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-plus-or::-webkit-input-placeholder {
  color: #999;
}

.shipping-standard {
  width: 20% !important;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-family: inherit;
  color: inherit;
  margin-top: 0.7rem;
  margin-bottom: 0.9rem;
  margin-right: 1rem;
  padding: 0.5rem 0.2rem 0.5rem 1rem;
  border-radius: 2px;
  background-color: rgba(255, 119, 48, 0.02);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  width: 90%;
  transition: all 0.3s;
}
.shipping-standard:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-standard:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-standard::-webkit-input-placeholder {
  color: #999;
}

.shipping-standard-1 {
  width: 20% !important;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-family: inherit;
  color: inherit;
  margin-top: 0.7rem;
  margin-bottom: 0.9rem;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.5rem 0.2rem 0.5rem 0.1rem;
  border-radius: 2px;
  border-bottom: 3px solid transparent;
  width: 90%;
  transition: all 0.3s;
}
.shipping-standard-1:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-standard-1:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-standard-1::-webkit-input-placeholder {
  color: #999;
}

.shipping-plus {
  width: 10% !important;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: inherit;
  color: red;
  margin-right: 2rem;
  margin-left: 1rem;
  padding: 2rem 1rem;
  border-radius: 2px;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
}
.shipping-plus:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-plus:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-plus::-webkit-input-placeholder {
  color: #999;
}

.shipping-standard-input {
  width: 15% !important;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-family: inherit;
  color: inherit;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background-color: rgba(86, 67, 250, 0.02);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  width: 90%;
  transition: all 0.3s;
}
.shipping-standard-input:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-standard-input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-standard-input::-webkit-input-placeholder {
  color: #999;
}

.shipping-standard-input-1 {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-family: inherit;
  color: inherit;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.5rem 0.1rem;
  padding-left: 0.2rem !important;
  border-radius: 2px;
  background-color: rgba(255, 119, 48, 0.02);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
}
.shipping-standard-input-1:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.shipping-standard-input-1:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.shipping-standard-input-1::-webkit-input-placeholder {
  color: #999;
}

.color-rgb-input {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: inherit;
  color: #085eaf !important;
  margin: 1rem 0 !important;
  margin-right: 1rem;
  padding-right: 0 !important;
  border-radius: 2px;
  background-color: rgba(255, 119, 48, 0.035);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
}
.color-rgb-input:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
  padding-right: 0 !important;
}
.color-rgb-input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.color-rgb-input::-webkit-input-placeholder {
  color: #999;
}

.size-qty-input {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: inherit;
  color: #085eaf;
  margin: 0 0 1rem auto !important;
  padding: 1rem 0.2rem !important;
  padding-left: 1rem !important;
  border-radius: 2px;
  background-color: rgba(255, 119, 48, 0.03);
  border: rgba(0, 0, 0, 0.1) 0.1rem solid;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
}
.size-qty-input:focus {
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #55c57a;
}
.size-qty-input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.size-qty-input::-webkit-input-placeholder {
  color: #999;
}

.ql-editor {
  width: 100%;
  height: 100%;
  min-height: 27rem;
  max-height: 35rem;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 0.5rem;
  margin-bottom: 5rem !important;
}
@media (max-width: 37.5em) {
  .ql-editor {
    height: 25vh !important;
    max-height: none;
    background-color: rgba(255, 255, 255, 0.6);
    font-size: 3.5rem !important;
  }
}

.ql-snow {
  font-size: 1.3rem !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 32rem;
  margin-bottom: 0.2rem;
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
}

.ql-toolbar {
  padding: 1rem !important;
  background-color: rgba(86, 67, 250, 0.05);
}

.single-product {
  display: block;
  font-size: 2.4rem;
  margin-left: 3rem;
  margin-right: 3rem;
  text-decoration: none;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.2);
}
.single-product:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.single-product:active, .single-product:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.single-product__title {
  width: 100% !important;
  background-color: rgba(86, 67, 250, 0.2);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 3rem;
  margin: 1rem auto;
  box-shadow: 0 2.5rem 5rem rgba(0, 0, 0, 0.1);
  border: 0.1rem #f7f7f7 solid;
}
.single-product__title::after {
  background-color: #fff;
}
.single-product__title-related-products {
  width: 80% !important;
  background-color: rgba(255, 255, 255, 0.5);
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 3rem 1rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  margin: 1rem auto -10rem auto;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
}
@media (max-width: 37.5em) {
  .single-product__title-related-products {
    font-size: 4.5rem;
  }
}
.single-product__title-related-products::after {
  background-color: #fff;
}
.single-product__main-single-product-title {
  width: fit-content !important;
  height: fit-content !important;
  background-color: rgba(255, 255, 255, 0.5);
  color: #0e55b3;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding: 2rem 13rem;
  margin: 2rem auto !important;
  border: 0.1rem rgba(0, 0, 0, 0.03) solid;
}
@media (max-width: 37.5em) {
  .single-product__main-single-product-title {
    font-size: 3rem;
  }
}
.single-product__write-customer-review {
  width: 55% !important;
  background-color: rgba(255, 119, 48, 0.045);
  color: #0e55b3;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 3rem 1rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  margin: 2rem auto;
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
}
@media (max-width: 37.5em) {
  .single-product__write-customer-review {
    width: 90% !important;
    font-size: 3rem;
  }
}
.single-product__write-customer-review::after {
  background-color: #fff;
}
.single-product__login-write-review {
  width: 50% !important;
  background-color: rgba(255, 119, 48, 0.035);
  color: #0e55b3;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 4rem 1rem;
  margin: 2rem auto;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
  cursor: pointer;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
@media (max-width: 37.5em) {
  .single-product__login-write-review {
    font-size: 2.5rem;
  }
}
.single-product__login-write-review:hover {
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  background-color: rgba(255, 119, 48, 0.05);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}
.single-product__login-write-review:active, .single-product__login-write-review:focus {
  outline: none;
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}
.single-product__no-reviews {
  width: 100% !important;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.5);
  color: #525050;
  font-size: 1.9rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.5rem;
  padding: 5rem 7rem;
  border: 0.1rem #f7f7f7 solid;
}
@media (max-width: 37.5em) {
  .single-product__no-reviews {
    width: 95% !important;
    font-size: 3rem;
  }
}
.single-product__no-reviews::after {
  background-color: #fff;
}
.single-product__description-content {
  width: 95% !important;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.007);
  color: #525050;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  padding: 5rem 7rem;
  margin: 2rem auto;
  border: 0.1rem rgba(0, 0, 0, 0.07) solid;
}
@media (max-width: 37.5em) {
  .single-product__description-content {
    font-size: 3rem;
  }
}
.single-product__description-content::after {
  background-color: #fff;
}
.single-product__single-product-video--title-related-videos-from-others {
  width: 60% !important;
  background-color: rgba(86, 67, 250, 0.4);
  color: #fff;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 3rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  margin: 2rem auto;
  box-shadow: 0 2.5rem 5rem rgba(0, 0, 0, 0.1);
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
}
.single-product__single-product-video--title-related-videos-from-others::after {
  background-color: #fff;
}
.single-product__title-product-review {
  width: 40% !important;
  background-color: rgba(255, 255, 255, 0.6);
  color: #0e55b3;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 0.5rem;
  padding: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto 4rem auto;
  border: 0.1rem rgba(0, 0, 0, 0.1) solid;
}
@media (max-width: 37.5em) {
  .single-product__title-product-review {
    font-size: 3rem;
  }
}
.single-product__title-product-review::after {
  background-color: #fff;
}
.single-product__title-first-section {
  border-radius: 2%;
  width: 60% !important;
  background-color: rgba(8, 94, 175, 0.1);
  color: rgba(8, 94, 175, 0.8);
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 0 auto 6rem auto;
  box-shadow: 0 2.5rem 5rem rgba(86, 67, 250, 0.2);
  border: 0.1rem #f7f7f7 solid;
}
.single-product__title-first-section::after {
  background-color: #fff;
}
.single-product__title--write-review {
  width: 75% !important;
  background-color: rgba(86, 67, 250, 0.2);
  color: white;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 1rem auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border: 0.1rem rgba(247, 247, 247, 0.5) solid;
}
.single-product__title--write-review::after {
  background-color: #fff;
}
.single-product__write-review-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.23);
  color: #085eaf;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 1rem auto;
  box-shadow: 0 2.5rem 5rem rgba(0, 0, 0, 0.1);
}
.single-product__write-review-1--write {
  height: 45rem !important;
  margin-bottom: 5rem;
  padding: 5rem 3rem 1rem 3rem !important;
  background-color: rgba(0, 0, 0, 0.01);
}
@media (max-width: 37.5em) {
  .single-product__write-review-1--write {
    height: 70% !important;
    padding-bottom: 5rem !important;
    margin-bottom: 5rem;
  }
}
.single-product__write-review-1--seller-profile-description {
  height: 35rem !important;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.07);
  margin: 2rem 5rem 5rem auto !important;
  padding: 2rem 3rem 7rem 3rem;
  background-color: rgba(255, 255, 255, 0.03);
}
@media (max-width: 37.5em) {
  .single-product__write-review-1--seller-profile-description {
    height: 30vh !important;
    margin: 2rem 1rem 1rem -1rem !important;
    padding: 2rem 3rem 4rem 3rem;
  }
}
.single-product__write-review-upload-pic {
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  background-color: rgba(86, 67, 250, 0.023);
  color: #085eaf;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 1rem auto;
  margin-top: -5rem;
}
.single-product__write-review-upload-pic--write {
  height: 45rem !important;
  margin-bottom: 2rem;
  padding: 1rem 3rem 7rem 3rem !important;
  background-color: rgba(255, 255, 255, 0.3);
}
.single-product__write-review-upload-pic--seller-profile-description {
  height: 45rem !important;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.07);
  margin: 2rem 5rem 2rem auto !important;
  padding: 2rem 3rem 7rem 3rem;
  background-color: rgba(255, 255, 255, 0.03);
}
.single-product__write-review-2 {
  width: 80% !important;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  color: #085eaf;
  font-size: 1.7rem;
  font-weight: 400;
  text-align: center;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 1rem auto;
  box-shadow: 0 0.3rem 0.3em rgba(0, 0, 0, 0.1);
  border: 0.1rem #f7f7f7 solid;
}
@media (max-width: 37.5em) {
  .single-product__write-review-2 {
    width: 100% !important;
    font-size: 3rem !important;
    padding: 0;
  }
}
.single-product__write-review-2::after {
  content: "";
  display: table;
  clear: both;
}
.single-product__write-review-2 [class^=col-] {
  float: left;
}
.single-product__write-review-2 [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.single-product__write-review-2 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.single-product__write-review-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  margin: 4rem auto !important;
}
@media (max-width: 37.5em) {
  .single-product__write-review-2 .col-2-of-3 {
    width: calc(3 * (100% - 1 * 0.5rem) / 3 + 5rem);
  }
}
.single-product__write-review-2--write {
  border: 0.2rem rgb(255, 247, 0) solid;
  background-color: rgba(255, 255, 255, 0.6);
}
.single-product--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}
.single-product__seller-name-rating {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 600;
  color: #0e55b3;
  letter-spacing: 0.2rem;
  text-align: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 1rem;
  margin-top: 0.5rem;
}
@media (max-width: 37.5em) {
  .single-product__seller-name-rating {
    font-size: 3rem;
  }
}
.single-product__seller-name-rating:hover {
  transform: translateY(-0.3px);
  -webkit-transform: translateY(-0.3px);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-0.3px);
  -ms-transform: translateY(-0.3px);
  -o-transform: translateY(-0.3px);
}
.single-product__seller-podcast-name-rating {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 1rem;
  margin-top: 1.5rem;
}
.single-product__seller-podcast-name-rating:hover {
  transform: translateY(-0.3px);
  -webkit-transform: translateY(-0.3px);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -moz-transform: translateY(-0.3px);
  -ms-transform: translateY(-0.3px);
  -o-transform: translateY(-0.3px);
}
.single-product__video-review {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  justify-content: space-around;
  text-align: center;
  background-color: rgba(8, 94, 175, 0.08);
  color: white;
  padding-top: 3rem;
  margin: 2rem 4rem;
}
.single-product__product-review {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  background-color: rgba(8, 94, 175, 0.05);
  color: white;
  padding: 3rem;
  padding-top: 7rem;
  margin-bottom: 0.5rem;
}
.single-product__related-products {
  width: 85%;
  margin: 0 auto -85rem auto;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  justify-content: space-around;
  text-align: center;
  color: white;
}
.single-product__related-videos-from-others {
  width: 75%;
  display: flex;
  margin: 1rem auto;
  margin-top: 5rem;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  justify-content: space-around;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: 1px rgba(143, 248, 129, 0.631372549) solid;
  margin-bottom: 5rem;
}
.single-product__card {
  display: row;
  color: rgba(14, 67, 240, 0.52);
  width: 100%;
  font-size: 1.8rem;
  background-color: rgba(226, 241, 237, 0.6);
  border: 1px rgba(51, 241, 67, 0.631372549) solid;
  padding: 2rem;
  box-shadow: 0.5rem 2.5rem 2rem rgba(0, 0, 0, 0.2);
  margin-right: 1rem;
}
.single-product__list-group {
  display: block;
  font-size: 1.6rem;
  justify-content: flex-start;
  background-color: rgba(156, 240, 213, 0.2);
  padding-top: 2rem;
  margin-bottom: 1rem;
}

.horizontalSlider {
  position: relative;
  overflow: hidden;
}
[dir=rtl] .horizontalSlider {
  direction: ltr;
  transform: scaleX(-1);
}
.horizontalSliderTray {
  overflow: hidden;
  width: 100%;
}

.verticalSlider {
  position: relative;
  overflow: hidden;
}
.verticalSliderTray {
  overflow: hidden;
}

.verticalTray {
  float: left;
}

.verticalSlideTrayWrap {
  overflow: hidden;
}

.sliderTray {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sliderAnimation {
  transition: transform 500ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
  will-change: transform;
}

.masterSpinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4;
}

.slider img {
  object-fit: fill !important;
  width: fit-content;
  height: 72rem !important;
  border-radius: 0.5rem;
  overflow: hidden;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.thumbnails-wrapper {
  padding: 0rem;
  margin: 5rem 0 3rem 0;
  box-shadow: 2rem 3rem 2rem rgba(0, 0, 0, 0.1);
}

.thumbnails-wrapper--single-product {
  height: 100% !important;
  width: 100% !important;
  min-height: 75rem !important;
  max-height: 75rem !important;
  padding: 0 1rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: -3rem !important;
  overflow: hidden;
  background-color: rgba(255, 119, 48, 0.1);
  box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
  border: 0.2rem white solid;
}

.thumbnails-wrapper--single-product-carousel {
  position: relative;
  max-width: 90rem !important;
  max-height: 70rem !important;
  margin: -5rem 0 13rem -12rem !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}
.thumbnails-wrapper--single-product-carousel .slide {
  position: absolute;
  width: 100% !important;
  object-fit: fill !important;
  height: 100% !important;
}
.thumbnails-wrapper--single-product-carousel .thumb {
  min-height: 5.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  justify-items: center !important;
  object-fit: fill !important;
  margin-top: 0.2rem;
  border-radius: 5% !important;
}

.ul {
  background-color: red;
}

.background-box-header {
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 2rem 2rem 1rem rgba(0, 0, 0, 0.3);
}

.background-box-header--inner {
  box-shadow: 2rem 2rem 1rem rgba(0, 0, 0, 0.3);
  color: #0e55b3 !important;
  background-color: rgba(0, 0, 0, 0.55) !important;
}

.background-header-skeleton {
  background-color: rgba(255, 255, 255, 0.4);
  width: 90% !important;
  margin: 10rem !important;
  padding: 5rem !important;
}

.background-header-categories {
  background-color: rgba(255, 255, 255, 0.01);
  color: white !important;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center !important;
  justify-items: center !important;
  text-align: center;
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  padding: 35% 0.5rem 30% 0.5rem !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.background-box-main-home-categories-section {
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  color: #0e55b3 !important;
  font-weight: 700 !important;
  padding: 0.3rem;
  background-color: rgba(86, 67, 250, 0.01) !important;
}

.background-box-main-home-main-carousel {
  width: 100%;
  background-color: white;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.01);
}

.background-box-main-home-main-carousel-special-shape {
  width: 95% !important;
  height: 100% !important;
  clip-path: polygon(0 20%, 85% 0, 100% 80%, 10% 100%);
  margin-top: -5rem;
  z-index: 1;
  background-image: linear-gradient(to left, rgba(34, 44, 157, 0.4), rgba(34, 44, 157, 0.4));
  position: absolute;
  top: 0;
}
@media (min-width: 112.51em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 80vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -10rem;
  }
}
@media (min-width: 112.51em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 70vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -5rem;
  }
}
@media (max-width: 75em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 70vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -3rem;
  }
}
@media (max-width: 56.25em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 55vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -5rem;
  }
}
@media (max-width: 37.5em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 28vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -4% !important;
  }
}
@media (max-width: 31.25em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 24.5vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -4% !important;
  }
}
@media (max-width: 23.4375em) {
  .background-box-main-home-main-carousel-special-shape {
    width: 100% !important;
    height: 22.5vh !important;
    clip-path: polygon(0 20%, 90% 0, 100% 80%, 10% 100%);
    margin-top: -5% !important;
  }
}

.background-skelton-main-loading {
  background-color: rgba(255, 255, 255, 0.4);
  width: 90% !important;
  margin: 10rem !important;
  padding: 5rem !important;
}

.background-category-subs-category-page {
  width: 80%;
  margin: 8rem auto;
  padding: 5rem;
  background-color: rgba(0, 0, 0, 0.028);
}

.background-customer-pict-upload {
  background-color: rgba(0, 0, 0, 0.03);
  width: 45%;
  margin: 2rem auto 1rem 25% !important;
  padding: 37ren auto 55rem auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
  justify-items: center;
}
.background-customer-pict-upload__upload {
  width: 50%;
  margin: 0 auto;
  background-color: rgba(248, 246, 244, 0.3);
}

.background-box-shadow {
  box-shadow: 2rem 2rem 1rem rgba(0, 0, 0, 0.3);
  color: #0e55b3 !important;
  background-color: rgba(255, 255, 255, 0.55) !important;
}

.background-box-shadow2 {
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 2rem 2rem 1rem rgba(0, 0, 0, 0.3);
}

.background-white {
  background-color: rgba(255, 255, 255, 0.35);
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 10rem;
  padding-top: 4rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.background-white-single-product-related-products {
  background-color: rgba(255, 255, 255, 0.03);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: -1rem;
  padding-top: 3rem !important;
  padding-bottom: -4rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.background-white-categories-list {
  background-color: rgba(255, 255, 255, 0.25);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 7%;
  -webkit-border-radius: 7%;
  -moz-border-radius: 7%;
  -ms-border-radius: 7%;
  -o-border-radius: 7%;
}

.background-white-single-product-display {
  background-color: rgba(255, 255, 255, 0.4);
  width: 97%;
  margin: 3rem auto !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0.1rem !important;
  padding-right: 0.1rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.background-whiteish {
  background-color: rgba(255, 255, 255, 0.4);
}

.background-whiteish-shop {
  background-color: rgba(255, 255, 255, 0.4);
  width: 90% !important;
  margin: 10rem !important;
  padding: 5rem !important;
}

.background-whiteish-2 {
  background-color: rgba(86, 67, 250, 0.05);
  width: 95% !important;
  margin: 5rem auto;
  padding: 3rem;
  border: 0.1rem rgba(0, 0, 0, 0.08) solid;
}

.background-blueish {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.background-whiteish-single-product {
  padding: 5rem 0.1rem;
}

.color-background {
  background-color: rgba(0, 0, 0, 0.005);
  border: 0.1rem solid rgba(0, 0, 0, 0.07);
  margin: 2rem auto !important;
  padding: 3rem;
}
.color-background:not(:last-child) {
  margin-right: 3rem;
}
@media (max-width: 37.5em) {
  .color-background {
    height: 55vh;
  }
}

.product-items-flex {
  width: 90%;
  display: flex;
  justify-content: space-around !important;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
  color: #0e55b3 !important;
  font-size: 1.4rem !important;
  margin: -2rem auto 0 auto;
  padding: 1rem 5rem 1rem 0 !important;
}
@media (max-width: 37.5em) {
  .product-items-flex {
    width: 100%;
    display: flex;
    justify-content: space-around !important;
    justify-items: center;
    font-size: 4rem !important;
    padding: 4rem 15rem 4rem 0 !important;
  }
}
.product-items-flex__add-to-cart {
  display: flex;
  margin: -1rem 1rem !important;
  color: #0e55b3 !important;
  display: flex;
  justify-content: space-around !important;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 0.1rem solid rgba(0, 0, 0, 0.07);
  padding: 0.5rem 1rem 0.5rem 1rem !important;
}
@media (max-width: 37.5em) {
  .product-items-flex__add-to-cart {
    padding: 3rem 1rem 3rem 1rem !important;
    margin-top: -2rem !important;
  }
}
.product-items-flex__to-see-price {
  text-align: center;
  text-transform: none;
  font-size: 1.6rem;
}
@media (max-width: 37.5em) {
  .product-items-flex__to-see-price {
    font-size: 4rem;
  }
}

.font-add-to-wish {
  font-size: 1.4rem !important;
  font-weight: 400;
  text-transform: none !important;
  color: #0e55b3 !important;
}
@media (max-width: 37.5em) {
  .font-add-to-wish {
    width: 33% !important;
    font-size: 4rem !important;
  }
}

.background-single-product-description {
  width: 100% !important;
  height: fit-content !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  margin: 2rem 0 !important;
  padding: 3rem;
}

.background-single-product-review {
  width: 100% !important;
  height: fit-content !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  margin: 2rem 5rem !important;
  padding: 0;
}
@media (max-width: 37.5em) {
  .background-single-product-review {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0;
  }
}

.background-single-product-description--outside {
  width: 95% !important;
  background-color: rgba(86, 67, 250, 0.02);
  margin: auto;
  margin-bottom: 10rem;
  padding: 2rem !important;
}

.ant-tabs-nav-wrap {
  width: 100% !important;
  height: fit-content !important;
  padding: 1.2rem 3rem;
  background-color: rgba(0, 0, 0, 0.015);
}

.ant-tabs, .ant-tabs-tab {
  padding: 5rem auto !important;
  color: #0e55b3 !important;
  letter-spacing: 0.1rem;
  font-size: 1.3rem;
  border: 0.2rem rgba(0, 0, 0, 0.04) solid;
}

.ant-tabs {
  padding: 5rem auto !important;
  color: #0e55b3 !important;
  letter-spacing: 0.1rem;
  font-size: 1.3rem;
  border: 0.2rem rgba(0, 0, 0, 0.04) solid;
}

.ant-tabs-tab {
  padding: 1.5rem 3rem !important;
  margin-right: -2rem !important;
  color: #0e55b3 !important;
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.4) !important;
  border: 0.2rem rgba(0, 0, 0, 0.4) solid;
}

.ant-tabs-tab-active {
  width: fit-content;
  outline: none !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 600;
  z-index: 10000;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0e55b3;
  border-bottom: whit solid 1rem;
}

.ant-modal-body {
  padding-top: 3rem !important;
}

.ant-tabs-nav-list {
  font-size: 1rem;
}

.background-seller-public-products-new-and-best {
  width: 95%;
  height: fit-content;
  margin: 5rem auto;
  padding: 10rem auto !important;
  background-color: rgba(86, 67, 250, 0.04);
}

.header-image {
  height: 100%;
  border: 0.3rem rgba(255, 255, 255, 0.2) solid;
  padding-bottom: 0;
  background-image: linear-gradient(180deg, rgba(23, 41, 239, 0.34) 5%, rgba(23, 41, 239, 0.34) 15%, rgba(23, 41, 239, 0.34) 35%, rgba(23, 41, 239, 0.34) 40%, rgba(23, 41, 239, 0.34) 45%, rgba(23, 41, 239, 0.34) 55%, rgba(23, 41, 239, 0.34) 65%, rgba(23, 41, 239, 0.34) 90%, rgba(23, 41, 239, 0.34) 100%), url(../image/hero.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
}
.header-image__logo-box {
  position: absolute;
  top: 0.1rem;
  left: 1rem;
}
.header-image__logo {
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  margin: 1rem auto 1rem 2rem;
  padding: 5rem 3rem 3rem 3rem;
}
.header-image__text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.new-header-menu {
  width: 100%;
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgb(55, 63, 177) 15%, rgba(127, 134, 243, 0.993) 85%);
  border-bottom: 0.1rem rgba(247, 247, 247, 0.7882352941) solid;
}
@media (max-width: 56.25em) {
  .new-header-menu {
    width: 100vw;
    height: fit-content;
    font-size: 0.5rem !important;
    border: 0.1rem red solid;
  }
}
@media (max-width: 37.5em) {
  .new-header-menu {
    width: 0 !important;
    opacity: 0;
    font-size: 0.5rem !important;
    border: 0.1rem white solid;
  }
}
.new-header-menu__table {
  background-color: rgba(0, 0, 0, 0.02);
}
.new-header-menu--subMenu {
  color: #ff7730 !important;
  background-color: white;
}
.new-header-menu-item {
  color: #fff;
}
.new-header-menu-item--search {
  width: 40% !important;
  height: 90% !important;
  display: block;
  margin: 0.03rem auto 0.02rem auto !important;
  font-size: 2rem;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  background-color: rgba(255, 255, 255, 0.15) !important;
}
@media (max-width: 56.25em) {
  .new-header-menu-item--search {
    max-width: 30%;
    height: 90%;
    font-size: 1rem;
  }
}
@media (max-width: 37.5em) {
  .new-header-menu-item--search {
    max-width: 30%;
    height: 90%;
    font-size: 1rem;
  }
}

.new-header-menu-phone {
  width: 0;
  height: 0;
  opacity: 0;
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgb(55, 63, 177) 15%, rgba(127, 134, 243, 0.993) 85%);
  border-bottom: 0.1rem rgba(247, 247, 247, 0.7882352941) solid;
}
@media (max-width: 37.5em) {
  .new-header-menu-phone {
    width: 100vw !important;
    opacity: 1;
    height: fit-content !important;
    display: flex;
    flex-direction: column !important;
    position: absolute;
    font-size: 0.5rem !important;
    z-index: 100;
  }
  .new-header-menu-phone__viewed {
    width: 100% !important;
    align-items: center !important;
    opacity: 1;
    height: fit-content !important;
    margin-top: -10rem;
    margin-left: 7rem !important;
    padding: -5rem !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    justify-items: center !important;
    font-size: 0.5rem !important;
    z-index: 300;
  }
  .new-header-menu-phone__viewed-profile {
    width: 100vw !important;
    opacity: 1;
    height: 50% !important;
    margin-top: 1rem;
    margin-left: 5rem;
    background-color: white;
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    font-size: 5rem !important;
    z-index: 300;
  }
}
.new-header-menu-phone__table {
  background-color: rgba(0, 0, 0, 0.02);
}
.new-header-menu-phone--subMenu {
  color: #ff7730 !important;
  background-color: white;
}
.new-header-menu-phone-item {
  color: #fff;
  height: fit-content !important;
  background-color: rgba(255, 255, 255, 0.1);
}
@media (max-width: 37.5em) {
  .new-header-menu-phone-item {
    margin: 0 0.1rem !important;
    padding: 0.1rem !important;
    font-size: 0.5rem !important;
  }
}
.new-header-menu-phone-item--search {
  width: 70% !important;
  height: fit-content !important;
  display: block;
  margin: -6rem auto 0.02rem auto !important;
  font-size: 2rem;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  background-color: rgba(255, 255, 255, 0.15) !important;
  z-index: 200;
}
@media (max-width: 56.25em) {
  .new-header-menu-phone-item--search {
    max-width: 30%;
    height: 90%;
    font-size: 1rem;
  }
}
@media (max-width: 37.5em) {
  .new-header-menu-phone-item--search {
    max-width: 70%;
    height: 90%;
    opacity: 1;
    font-size: 1rem;
  }
}
.new-header-menu-phone-item--search-hidden {
  width: 0 !important;
  height: 0 !important;
  opacity: 0;
}
@media (max-width: 56.25em) {
  .new-header-menu-phone-item--search-hidden {
    max-width: 30%;
    height: 90%;
    font-size: 1rem;
  }
}
@media (max-width: 37.5em) {
  .new-header-menu-phone-item--search-hidden {
    max-width: 30%;
    height: 90%;
    font-size: 1rem;
  }
}

.sub-menu-text {
  color: #0e55b3;
}

.ant-menu {
  background-color: rgba(255, 255, 255, 0.9);
}
.ant-menu-submenu {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.header {
  height: 100%;
  padding-bottom: 0;
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgba(69, 81, 248, 0.4) 15%, rgba(67, 70, 241, 0.5) 35%, rgba(69, 81, 248, 0.4) 40%, rgba(69, 81, 248, 0.4) 45%, rgba(69, 81, 248, 0.4) 55%, rgba(69, 81, 248, 0.6) 65%, rgba(56, 116, 236, 0.6) 100%, rgba(15, 91, 241, 0.7) 90%), url(../image/hero.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
}
.header__logo-box {
  position: absolute;
  top: 0.1rem;
  left: 1rem;
}
.header__logo {
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  margin: 1rem auto 1rem 2rem;
  padding: 5rem 3rem 3rem 3rem;
  background-color: rgba(255, 255, 255, 0.017);
  border-bottom: 0.2rem white solid;
  border-top: 0.2rem white solid;
  background-blend-mode: color-dodge;
  box-shadow: 0 0.2rem 0.2rem black;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.header__text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.header-seller-videos-page {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgba(69, 81, 248, 0.4) 15%, rgba(67, 70, 241, 0.5) 35%, rgba(69, 81, 248, 0.4) 40%, rgba(69, 81, 248, 0.4) 45%, rgba(69, 81, 248, 0.4) 55%, rgba(69, 81, 248, 0.6) 65%, rgba(56, 116, 236, 0.6) 100%, rgba(15, 91, 241, 0.7) 90%), url(../image/hero.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
}
.header-seller-videos-page__logo-box {
  position: absolute;
  top: 0.1rem;
  left: 1rem;
}
.header-seller-videos-page__logo {
  background-size: cover;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 6rem 2rem 0 2rem;
  background-color: rgba(86, 67, 250, 0.02);
  background-blend-mode: color-dodge;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.header-seller-videos-page__text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}
.header-seller-videos-page__seller-videos-page {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.header-seller {
  height: 100%;
  background-size: cover;
  background-position: top;
  position: relative;
}
.header-seller__logo-box {
  position: absolute;
  top: 2rem;
  left: 3rem;
}
.header-seller__logo {
  background-size: cover;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 6rem 2rem 0 2rem;
  background-color: rgba(86, 67, 250, 0.02);
  background-blend-mode: color-dodge;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.header-seller__text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}
.header-seller__videos-page {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.header-menu {
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgb(55, 63, 177) 15%, rgba(127, 134, 243, 0.993) 85%);
  border-bottom: 0.1rem rgba(247, 247, 247, 0.7882352941) solid;
}
.header-menu__table {
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 1.3rem;
  color: #0e55b3;
}
.header-menu-item {
  color: #fff;
}
.header-menu-item--search {
  width: 60rem;
  height: fit-content !important;
  display: block;
  margin-top: 0.8rem;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  font-size: 2rem;
  background-color: transparent;
}

.header-menu-seller-home-general-menu {
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgb(55, 63, 177) 15%, rgba(127, 134, 243, 0.993) 85%);
  margin-top: -3rem;
}
.header-menu-seller-home-general-menu-item {
  color: #fff;
}
.header-menu-seller-home-general-menu-item--search {
  width: 60rem;
  display: inline-block;
}

.header-menu-seller-home {
  height: 6rem;
  background-color: rgba(255, 255, 255, 0.3);
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
}
.header-menu-seller-home-item {
  color: #fff;
}
.header-menu-seller-home-item--search {
  width: 60rem;
  display: inline-block;
}

.header-table__tr {
  background-color: rgba(86, 67, 250, 0.1);
  text-align: start;
  font-size: 1.4rem !important;
  width: fit-content !important;
}
.header-table__tr-2 {
  background-color: rgba(48, 79, 254, 0.1);
  color: #0e55b3 !important;
  font-size: 1.7rem;
  font-weight: 500;
}
.header-table__cart-header-table {
  background-color: rgba(48, 79, 254, 0.08);
  color: #0e55b3 !important;
  padding-left: 1.5rem !important;
  font-size: 1.7rem;
  font-weight: 500;
}
@media (max-width: 37.5em) {
  .header-table__cart-header-table {
    font-size: 2.5rem;
  }
}
.header-table__tr-3 {
  background-color: rgba(86, 67, 250, 0.02);
  color: #0e55b3 !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}
@media (max-width: 37.5em) {
  .header-table__tr-3 {
    font-size: 2.5rem !important;
  }
}
.header-table__tr-order-admin-dashboard-header {
  background-color: rgba(86, 67, 250, 0.06);
  color: rgba(8, 94, 175, 0.8) !important;
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}
.header-table__tr-order-customer-history-table-header {
  background-color: rgba(86, 67, 250, 0.05);
  color: rgba(8, 94, 175, 0.8) !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}
@media (max-width: 37.5em) {
  .header-table__tr-order-customer-history-table-header {
    font-size: 2.4rem !important;
  }
}
.header-table__tr-order-customer-history-table-header--cursor {
  cursor: pointer;
}
.header-table__tr-order-admin-dashboard-content {
  background-color: rgba(255, 255, 255, 0.07);
  color: rgba(8, 94, 175, 0.9) !important;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
}
.header-table__tr-customer-history-content {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(8, 94, 175, 0.9) !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}
@media (max-width: 37.5em) {
  .header-table__tr-customer-history-content {
    font-size: 2.4rem !important;
  }
}
.header-table__td {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  font-size: 1.6rem !important;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.8);
  color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 37.5em) {
  .header-table__td {
    font-size: 3rem !important;
  }
}
.header-table__td--title {
  text-align: left;
  padding-left: 1.5rem !important;
}
.header-table__td-2 {
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
.header-table__td-3 {
  background-color: rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
}
.header-table__price {
  display: flex !important;
  flex-wrap: wrap !important;
}

.header-summary-table {
  margin-right: 5rem !important;
  height: fit-content !important;
}
.header-summary-table__inner-table {
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.4rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  margin-bottom: 2rem !important;
  padding: 2rem 1rem;
}
@media (max-width: 37.5em) {
  .header-summary-table__inner-table {
    font-size: 2rem;
  }
}
.header-summary-table__inner-table--standard {
  background-color: rgba(40, 180, 133, 0.02);
}
.header-summary-table__inner-table--border {
  margin-top: 1rem;
  margin-bottom: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.4rem;
  border: rgba(0, 0, 0, 0.03) 0.3rem solid !important;
  background-color: rgba(0, 0, 0, 0.01);
}
.header-summary-table__inner-table--product-title {
  display: flex;
  justify-content: space-between;
}
.header-summary-table__tr {
  background-color: rgba(48, 79, 254, 0.05);
  color: #085eaf !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  padding-left: 1.5rem;
}
@media (max-width: 37.5em) {
  .header-summary-table__tr {
    font-size: 2.5rem !important;
  }
}
.header-summary-table__td {
  background-color: rgba(48, 79, 254, 0.05);
  color: #424242 !important;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .header-summary-table__td {
    font-size: 2.5rem;
  }
}
.header-summary-table__cart {
  width: 95%;
  padding: 1.5rem 2rem;
  color: black;
  font-size: 1.4 !important;
  font-weight: 400;
  background-color: rgba(255, 119, 48, 0.014);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  margin: 3rem auto !important;
}
.header-summary-table__shipping-cost {
  color: #424242 !important;
  font-size: 1.6rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-between;
  margin-top: -0.7rem;
  margin-bottom: 1rem;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
@media (max-width: 37.5em) {
  .header-summary-table__shipping-cost {
    font-size: 2.5rem;
  }
}
.header-summary-table__details {
  box-shadow: none;
  background-color: rgba(86, 67, 250, 0.09);
}
.header-summary-table__details--title {
  text-align: start;
  padding-left: 2rem !important;
}
.header-summary-table__cart-details {
  box-shadow: none;
  background-color: white;
  color: black;
  font-size: 1.6rem;
  justify-content: center;
  justify-items: center;
}
@media (max-width: 37.5em) {
  .header-summary-table__cart-details {
    font-size: 2.5rem;
  }
}
.header-summary-table__cart-details--title {
  text-align: start;
  padding-left: 2rem !important;
}
.header-summary-table__coupon {
  box-shadow: none;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: none;
  background-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 37.5em) {
  .header-summary-table__coupon {
    font-size: 2.5rem;
  }
}

.social-menu {
  display: flex;
  list-style-type: none;
}

.social-option {
  display: flex;
  flex-direction: column;
  width: 90rem;
  justify-content: center;
  justify-items: center;
  margin-top: -3rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.header-shipping-option {
  display: flex;
  margin: 1rem 2rem 0.5rem 2rem;
  padding: 1rem 4rem;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
}
.header-shipping-option__title-background {
  padding: 1rem 3rem;
  background-color: rgba(255, 119, 48, 0.015);
  border: 0.1rem rgba(0, 0, 0, 0.03) solid;
  font-size: 1.5rem;
  font-weight: bold;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
}
@media (max-width: 37.5em) {
  .header-shipping-option__title-background {
    font-size: 1.8rem;
  }
}
.header-shipping-option__title-background--if-available {
  font-size: 1.4rem;
  font-weight: 500;
}
@media (max-width: 37.5em) {
  .header-shipping-option__title-background--if-available {
    font-size: 1.6rem;
  }
}

/* export const Box = styled.div` */
.box {
  background-size: cover;
  margin-top: -4rem;
  padding: 8rem 6rem;
  background-color: #131A22;
  position: relative;
  width: 100%;
}

/* @media (max-width: 1000px) {
	padding: 70px 30px;
} */
/* background: red; */
.column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;
}
@media row {
  .row {
    max-width: 1000px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.footLink {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
}

.footLink:hover {
  color: #085eaf;
  transition: 200ms ease-in;
}

.footer-header {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
  text-align: center;
}

.footer-copyRight {
  width: 100%;
  border-top: 0.1rem #f7f7f7 solid;
  text-align: center;
  margin-top: 5rem;
  padding-top: 5rem;
  color: rgba(247, 247, 247, 0.8);
}

.new-row-new-arrivals {
  max-width: 100vw;
  height: 50vh;
  background-color: rgba(247, 247, 247, 0.7882352941);
  margin: 0;
  padding: 0;
}
@media (max-width: 56.25em) {
  .new-row-new-arrivals {
    max-width: 100vw;
    height: 35vh;
  }
}
@media (max-width: 37.5em) {
  .new-row-new-arrivals {
    max-width: 100vw !important;
    height: 25vh !important;
  }
}
@media (max-width: 23.4375em) {
  .new-row-new-arrivals {
    max-width: 100vw !important;
    height: 25vh !important;
  }
}
.new-row-new-arrivals:not(:last-child) {
  margin-bottom: 5rem;
}
.new-row-new-arrivals::after {
  content: "";
  display: table;
  clear: both;
}
.new-row-new-arrivals [class^=col-] {
  float: left;
}
.new-row-new-arrivals [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
@media (max-width: 56.25em) {
  .new-row-new-arrivals [class^=col-]:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 56.25em) {
  .new-row-new-arrivals [class^=col-] {
    width: 100% !important;
  }
}

.row-background-main-home-page {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin: 0.1rem auto 1.3rem auto;
  padding-top: 1rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-main-home-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-main-home-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-main-home-page [class^=col-] {
  float: left;
}
.row-background-main-home-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
@media (max-width: 56.25em) {
  .row-background-main-home-page [class^=col-]:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 56.25em) {
  .row-background-main-home-page [class^=col-] {
    width: 100% !important;
  }
}
.row-background-main-home-page .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-background-main-home-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}

.row-main-home-categories-background {
  width: 95vw;
  height: fit-content !important;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  margin: 4rem auto 1rem auto !important;
  padding-top: 2rem !important;
  padding-bottom: 0;
  padding: 2rem auto 0 5% !important;
}
@media (min-width: 112.51em) {
  .row-main-home-categories-background {
    width: 95%;
    height: 35% !important;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    padding: 2rem 5% 0 5% !important;
    margin: 7rem 5rem 1rem 5rem !important;
  }
}
@media (max-width: 75em) {
  .row-main-home-categories-background {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    padding: 2rem 3% 0 3% !important;
  }
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background {
    width: 100% !important;
    height: 35% !important;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    margin-left: 0 !important;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background {
    width: 100% !important;
    height: 35% !important;
    margin: 10rem 10rem 0 1rem !important;
    padding: 3rem 0.5rem 1rem 0.5rem !important;
  }
}
@media (max-width: 31.25em) {
  .row-main-home-categories-background {
    width: 100% !important;
    height: 35% !important;
    margin: 10rem 10rem 0 1rem !important;
    padding: 3rem 0.5rem 1rem 0.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background {
    width: 100% !important;
    height: fit-content !important;
    font-size: 2rem !important;
    margin: 10rem auto 1rem auto !important;
    padding: -20rem 0 2rem 0 !important;
  }
}
.row-main-home-categories-background:not(:last-child) {
  margin-bottom: 5rem;
}
@media (max-width: 75em) {
  .row-main-home-categories-background:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background:not(:last-child) {
    margin-bottom: 5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background:not(:last-child) {
    margin-bottom: 2rem !important;
  }
}
.row-main-home-categories-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-main-home-categories-background [class^=col-] {
  float: left;
}
.row-main-home-categories-background [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background [class^=col-]:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background [class^=col-]:not(:last-child) {
    margin-right: 0.2rem;
    padding-right: 0;
  }
}
.row-main-home-categories-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-main-home-categories-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-main-home-categories-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-main-home-categories-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  background-color: white;
}
.row-main-home-categories-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 5rem) / 4);
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding-left: 1rem;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: space-around;
}
.row-main-home-categories-background .col-1-of-4-seller-home:last-child {
  margin-bottom: 0.5rem;
}
.row-main-home-categories-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-main-home-categories-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-main-home-categories-background .col-1-of-5 {
  width: calc((100% - 5 * 2rem) / 5);
  height: 75%;
  margin: 2rem 1rem 2rem 1rem !important;
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background .col-1-of-5 {
    width: 30% !important;
    height: 45vh;
  }
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background .col-1-of-5 {
    width: calc((100% - 5 * 2rem) / 5);
    height: 35vh;
    margin: 2rem 1rem 2rem 1rem !important;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-1-of-5 {
    width: calc((100% - 5 * 2rem) / 5);
    height: 35vh;
    margin: 2rem 1rem 2rem 1rem !important;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background .col-1-of-5 {
    width: calc((100% - 5 * 2rem) / 5);
    height: 18vh;
    margin: 2rem 1rem -6rem 1rem !important;
  }
}
.row-main-home-categories-background .col-1-of-6 {
  width: calc((100% - 5 * 0.1rem) / 6 + 5rem);
  height: 70vh !important;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding: 2rem;
  padding-left: 0.1rem;
  color: rgba(14, 85, 179, 0.8);
  font-size: 1.3rem;
  font-weight: 700;
}
@media (min-width: 112.51em) {
  .row-main-home-categories-background .col-1-of-6 {
    width: 20vw !important;
    height: 65vh !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background .col-1-of-6 {
    width: 20vw !important;
    height: 50vh !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-1-of-6 {
    width: 0vw !important;
    height: 0vh !important;
    opacity: 0;
  }
}
.row-main-home-categories-background .col-1-of-6:last-child {
  margin-bottom: 0.5rem;
}
.row-main-home-categories-background .col-1-of-6-phone {
  width: 0;
  height: 0;
  opacity: 0;
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-1-of-6-phone {
    width: 65vw !important;
    height: 25vh !important;
    opacity: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 2rem auto !important;
    margin-bottom: 10rem !important;
    font-size: 3rem !important;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background .col-1-of-6-phone {
    width: 60vw !important;
    height: 25vh !important;
    margin: 2rem auto 10rem auto !important;
  }
}
.row-main-home-categories-background .col-1-of-6-phone:last-child {
  margin-bottom: 0.5rem;
}
.row-main-home-categories-background .col-1-of-6-categories {
  width: 0;
  height: 0;
  opacity: 0;
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-1-of-6-categories {
    width: 35vw !important;
    height: fit-content !important;
    opacity: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.2rem auto 1rem 2rem !important;
    font-size: 3rem !important;
    z-index: 10;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background .col-1-of-6-categories {
    width: 35vw !important;
    height: fit-content !important;
    display: flex;
    flex-direction: column;
    font-size: 2rem !important;
    font-weight: 700 !important;
    margin: 2rem 10rem 1rem 2rem !important;
  }
}
.row-main-home-categories-background .col-1-of-6-categories:last-child {
  margin-bottom: 0.5rem;
}
.row-main-home-categories-background .col-1-of-6--height-scroll {
  width: 100%;
  height: 100% !important;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  margin: 0.5rem 0 0 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  border: 0.3rem rgba(6, 57, 112, 0.5) solid !important;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-main-home-categories-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
  margin-top: 6rem;
  padding-top: 5rem !important;
  position: relative;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  height: 80vh;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
}
@media (min-width: 112.51em) {
  .row-main-home-categories-background .col-5-of-6 {
    width: 100% !important;
  }
}
@media (max-width: 75em) {
  .row-main-home-categories-background .col-5-of-6 {
    width: 70vw !important;
    height: 50vh !important;
  }
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background .col-5-of-6 {
    width: 73vw !important;
    height: 50vh !important;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-5-of-6 {
    width: 90vw !important;
    height: 30vh !important;
    margin: 2rem 5% !important;
    padding: 0 auto !important;
  }
}
@media (max-width: 31.25em) {
  .row-main-home-categories-background .col-5-of-6 {
    width: 90vw !important;
    height: 20vh !important;
    margin: 3rem 5% !important;
    padding: 0 auto !important;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background .col-5-of-6 {
    width: 100vw !important;
    height: 23vh !important;
  }
}
.row-main-home-categories-background .col-5-of-6-with-clicked-category {
  margin-top: 6rem;
  padding-top: 5rem !important;
  position: relative;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  height: 80vh;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
}
@media (min-width: 112.51em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 100% !important;
  }
}
@media (max-width: 112.5em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 75vw !important;
    height: 69vh !important;
    padding: 2rem auto !important;
  }
}
@media (max-width: 75em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 70vw !important;
    height: 68vh !important;
  }
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 73vw !important;
    height: 50vh !important;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 90vw !important;
    height: 60vh !important;
    margin: 0 5% !important;
    padding: 0 auto !important;
  }
}
@media (max-width: 31.25em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 100vw !important;
    height: 60vh !important;
    margin: 0 0 0 0 !important;
    padding: 0 auto !important;
  }
}
@media (max-width: 25em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 100vw !important;
    height: 60vh !important;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background .col-5-of-6-with-clicked-category {
    width: 100vw !important;
    height: 60vh !important;
    margin-bottom: -1rem !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 0 auto !important;
  }
}
.row-main-home-categories-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-main-home-categories-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-main-home-categories-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
@media (max-width: 56.25em) {
  .row-main-home-categories-background .col-1-of-7 {
    width: 30% !important;
    height: 45vh;
  }
}
@media (max-width: 37.5em) {
  .row-main-home-categories-background .col-1-of-7 {
    width: 35% !important;
    height: 35vh;
    margin: -2rem 0.3rem 2rem 0.3rem;
  }
}
@media (max-width: 23.4375em) {
  .row-main-home-categories-background .col-1-of-7 {
    width: 50%;
    height: 28vh;
  }
}
.row-main-home-categories-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row {
  max-width: 114rem;
  background-color: rgba(242, 242, 249, 0.05);
  margin: -5rem auto;
  padding-bottom: -5rem;
  padding-top: -10rem;
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^=col-] {
  float: left;
}
.row [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
@media (max-width: 56.25em) {
  .row [class^=col-]:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 56.25em) {
  .row [class^=col-] {
    width: 100% !important;
  }
}
.row .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-checkout-steps {
  width: 90%;
  background-color: rgba(242, 242, 249, 0.05);
  display: flex;
  margin: -5rem auto;
  padding-bottom: -5rem;
  padding-top: -10rem;
}
@media (max-width: 37.5em) {
  .row-checkout-steps {
    width: 100%;
  }
}

.row-home-section-about {
  width: 70%;
  background-color: rgba(86, 67, 250, 0.041);
  margin: -5rem auto 5rem auto !important;
  padding: 1rem 1rem 1rem 7rem;
}
@media (max-width: 37.5em) {
  .row-home-section-about {
    width: 100% !important;
    padding: 2rem 3rem;
  }
}
.row-home-section-about::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-section-about [class^=col-] {
  float: left;
}
@media (max-width: 56.25em) {
  .row-home-section-about [class^=col-]:not(:last-child) {
    margin-right: 1rem !important;
  }
}
.row-home-section-about .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  padding-top: 4rem;
  padding-left: 5rem;
}
@media (max-width: 56.25em) {
  .row-home-section-about .col-1-of-2 {
    width: 0;
    height: 0;
  }
}
.row-home-section-about .col-1-of-2-our-mission-text {
  width: calc((100% - 0.5rem) / 2);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5rem 4rem 4rem 4rem;
  margin-bottom: -5rem;
  margin-top: 5rem;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
}
@media (max-width: 56.25em) {
  .row-home-section-about .col-1-of-2-our-mission-text {
    width: 100% !important;
  }
}
.row-home-section-about .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-home-section-about .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-section-about .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-home-section-about .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-section-about .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-product-update-form {
  max-width: 114rem;
  background-color: rgba(86, 67, 250, 0.05);
  margin: -5rem auto 5rem auto;
  padding: 4rem;
}
.row-product-update-form__list-title {
  margin-right: 1rem;
}

.row-seller-home-form1 {
  width: 100%;
  height: 45rem;
  display: flex;
  flex-direction: row;
  color: #0e55b3;
  background-color: rgba(40, 180, 133, 0.15);
  margin: 2rem auto 2rem auto;
}
.row-seller-home-form1::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form1 [class^=col-] {
  float: left;
}
.row-seller-home-form1 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-form1 .col-1-of-2-form1 {
  width: calc((100% - 0.5rem) / 2);
  background-color: rgba(255, 255, 255, 0.5);
  min-height: 40rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 3rem 0 3rem 3rem;
  padding: 5rem 3rem 1rem 3rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-form1 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 35rem;
  max-height: 35rem;
  margin: 1rem 3rem 5rem -5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1rem 2rem 5rem 2rem;
}
.row-seller-home-form1 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-form1 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form1 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form1 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form1 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-3 {
  width: 100%;
  height: 45rem;
  color: #0e55b3;
  background-color: rgba(255, 119, 48, 0.15);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  margin: 3rem auto 3rem auto;
  padding: 0 2rem 5rem 2rem;
}
.row-seller-home-form-3::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-3 [class^=col-] {
  float: left;
}
.row-seller-home-form-3 [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-form-3 .col-1-of-2-form1 {
  width: calc((100% - 5rem) / 2);
  background-color: rgba(255, 255, 255, 0.5);
  min-height: 40rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 3rem auto;
  padding: 5rem 3rem 1rem 3rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-form-3 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  min-height: 35rem;
  max-height: 35rem;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1rem 2rem 5rem 2rem;
}
.row-seller-home-form-3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-form-3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form-3 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-create-form-3 {
  width: 100%;
  height: 45rem;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  margin: 0 auto 3rem auto;
  padding: 5rem auto;
}
.row-seller-home-create-form-3::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-create-form-3 [class^=col-] {
  float: left;
}
.row-seller-home-create-form-3 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem !important;
}
.row-seller-home-create-form-3 .col-1-of-2-form1 {
  width: calc((100% - 5rem) / 2);
  background-color: rgba(255, 255, 255, 0.5);
  min-height: 40rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 2rem auto 2rem auto;
  padding: 10rem 5rem 5rem 5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-create-form-3 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  max-height: 40rem;
  margin: 5 auto 5rem auto;
  justify-content: center;
  justify-items: center;
  padding: 0 3rem 2rem 3rem;
}
.row-seller-home-create-form-3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-create-form-3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-create-form-3 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-create-form-3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-create-form-3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form1-2nd-design-with-clip-path {
  width: 85%;
  height: 45rem;
  color: #0e55b3;
  background-color: rgba(40, 180, 133, 0.15);
  box-shadow: 0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.3);
  margin: 0 auto;
}
.row-seller-home-form1-2nd-design-with-clip-path::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form1-2nd-design-with-clip-path [class^=col-] {
  float: left;
}
.row-seller-home-form1-2nd-design-with-clip-path .col-1-of-2-form1-2nd-design-with-clip-path {
  width: calc((100% - 5rem) / 2);
  background-color: rgba(255, 255, 255, 0.5);
  min-height: 40rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 3rem 0 2rem -2rem;
  padding: 5rem 3rem 1rem 3rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-form1-2nd-design-with-clip-path .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  min-height: 35rem;
  max-height: 35rem;
  margin: 1rem auto;
  padding: 1rem 2rem 5rem 2rem;
}
.row-seller-home-form1-2nd-design-with-clip-path .col-1-of-2-view {
  width: calc((100% - 5rem) / 2);
  width: 44%;
  min-height: 35rem;
  max-height: 35rem;
  margin: 2rem 0 2rem 1.5rem;
  padding: 1rem 2rem 3rem -0.5rem;
}
.row-seller-home-form1-2nd-design-with-clip-path .col-1-of-3 {
  width: calc((100% - 1 * 5rem) / 2);
  min-height: 35rem;
  max-height: 35rem;
  padding: 1rem 2rem 5rem 2rem;
}
.row-seller-home-form1-2nd-design-with-clip-path .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form1-2nd-design-with-clip-path .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form1-2nd-design-with-clip-path .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form1-2nd-design-with-clip-path .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form2 {
  width: 95%;
  display: flex;
  background-color: rgba(242, 242, 249, 0.05);
  margin: -3rem auto -5rem auto;
}
.row-seller-home-form2::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form2 [class^=col-] {
  float: left;
}
.row-seller-home-form2 [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-form2 .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-form2 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-form2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-form2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form2 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form2-public {
  width: 75vw;
  display: flex;
  border-radius: 50%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  background-color: rgba(233, 14, 138, 0.4);
  margin: 2rem auto 2rem auto;
}
.row-seller-home-form2-public::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form2-public [class^=col-] {
  float: left;
}
.row-seller-home-form2-public [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-form2-public .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 40rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 1rem 0;
  padding: 3rem 4rem 0 4rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 5%;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
}
.row-seller-home-form2-public .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 45rem;
  max-height: 45rem;
  margin: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 3rem 2rem 3rem 1rem;
}
.row-seller-home-form2-public .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-form2-public .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form2-public .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form2-public .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form2-public .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form2-public-with-orange-background {
  width: 75vw;
  display: flex;
  border-radius: 50%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  color: #0e55b3;
  background-color: rgba(233, 14, 138, 0.1);
  margin: 2rem auto 2rem auto;
}
.row-seller-home-form2-public-with-orange-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form2-public-with-orange-background [class^=col-] {
  float: left;
}
.row-seller-home-form2-public-with-orange-background [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-form2-public-with-orange-background .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 40rem;
  max-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 1rem 0;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
  background-color: rgba(255, 255, 255, 0.7);
}
.row-seller-home-form2-public-with-orange-background .col-1-of-2-form2__background {
  width: 100%;
  height: 100%;
  background-color: rgba(40, 180, 133, 0.25);
  padding: 6rem 4rem 0 4rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-seller-home-form2-public-with-orange-background .col-1-of-2-form2__background--blue {
  background-color: rgba(86, 67, 250, 0.15);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.row-seller-home-form2-public-with-orange-background .col-1-of-2-form2__background--orange {
  background-color: rgba(255, 119, 48, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.row-seller-home-form2-public-with-orange-background .col-1-of-2-form2__background--green {
  background-color: rgba(40, 180, 133, 0.15);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.row-seller-home-form2-public-with-orange-background .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 45rem;
  max-height: 45rem;
  margin: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 3rem 2rem 3rem 1rem;
}
.row-seller-home-form2-public-with-orange-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-form2-public-with-orange-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form2-public-with-orange-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form2-public-with-orange-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form2-public-with-orange-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-new-arrivals {
  width: 95%;
  display: flex;
  background-color: rgba(242, 242, 249, 0.01);
  margin: 3rem auto -5rem auto;
}
.row-seller-home-new-arrivals::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-new-arrivals .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-new-arrivals .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-new-arrivals .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-new-arrivals .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-best-sellers {
  width: 95%;
  display: flex;
  height: 35rem;
  background-color: white;
  margin: 3rem auto 1.5rem auto;
  padding: 1rem 2rem;
}
@media (max-width: 31.25em) {
  .row-seller-home-best-sellers {
    width: 95vw;
    display: flex !important;
    flex-wrap: wrap;
    padding: 1rem auto;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}
.row-seller-home-best-sellers::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-best-sellers .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-best-sellers .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-best-sellers .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-best-sellers .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-best-sellers .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  margin: 2rem auto;
}
@media (max-width: 37.5em) {
  .row-seller-home-best-sellers .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin: 2rem 2rem 2rem 2rem !important;
  }
}
@media (max-width: 31.25em) {
  .row-seller-home-best-sellers .col-1-of-4 {
    width: calc((100vw - 3 * 5rem) / 2) !important;
    height: 30rem !important;
    margin: 2rem 1rem !important;
    border: 0.2rem blue solid !important;
  }
}
.row-seller-home-best-sellers .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-best-sellers-2 {
  width: 98%;
  display: flex;
  height: fit-content;
  margin: 0 auto 1rem auto;
  padding: 1rem 3rem;
}
@media (max-width: 37.5em) {
  .row-seller-home-best-sellers-2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content !important;
    margin: 0;
    padding: 0rem 2rem;
  }
}
.row-seller-home-best-sellers-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers-2 [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers-2 [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-best-sellers-2 .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-best-sellers-2 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-best-sellers-2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-best-sellers-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-best-sellers-2 .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  margin: 2rem auto;
}
@media (max-width: 37.5em) {
  .row-seller-home-best-sellers-2 .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin: 2rem 2rem 2rem 2rem !important;
  }
}
.row-seller-home-best-sellers-2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers-2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-best-sellers-3 {
  width: 95%;
  display: flex;
  height: 38rem;
  background-color: rgba(34, 44, 157, 0.15);
  margin: 5rem auto;
  padding: 3rem 3rem;
}
@media (max-width: 37.5em) {
  .row-seller-home-best-sellers-3 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content !important;
    margin: 0;
    padding: 3rem 1rem;
  }
}
.row-seller-home-best-sellers-3::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers-3 [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers-3 [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-best-sellers-3 .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-best-sellers-3 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-best-sellers-3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-best-sellers-3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-best-sellers-3 .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  margin: 2rem auto;
}
@media (max-width: 37.5em) {
  .row-seller-home-best-sellers-3 .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin: 2rem 2rem 2rem 2rem !important;
  }
}
.row-seller-home-best-sellers-3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers-3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-home-category-3-products {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 55vh;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 3rem auto 3rem auto;
  padding: 2rem 2rem 10rem 5rem;
}
@media (max-width: 37.5em) {
  .row-home-category-3-products {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem auto 1rem auto !important;
    padding: 2rem 5rem 5rem 3rem;
    overflow-y: scroll;
    height: 55vh;
  }
}
.row-home-category-3-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-category-3-products [class^=col-] {
  float: left;
}
.row-home-category-3-products [class^=col-]:not(:last-child) {
  margin-right: 0.1rem !important;
}
@media (max-width: 37.5em) {
  .row-home-category-3-products [class^=col-]:not(:last-child) {
    margin-bottom: 1vh !important;
  }
}
.row-home-category-3-products .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-home-category-3-products .col-1-of-2 {
  width: calc((100% - 0.1rem) / 2);
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.row-home-category-3-products .col-1-of-3 {
  width: calc((100% - 2 * 0.1rem) / 3);
}
@media (max-width: 37.5em) {
  .row-home-category-3-products .col-1-of-3 {
    width: 100% !important;
    margin-top: 1rem !important;
  }
}
.row-home-category-3-products .col-1-of-3-middle-one {
  width: calc((100% - 2 * 0.1rem) / 3);
}
@media (max-width: 37.5em) {
  .row-home-category-3-products .col-1-of-3-middle-one {
    width: 100% !important;
    margin-top: 2rem !important;
  }
}
.row-home-category-3-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 0.1rem) / 3 + 0.1rem);
}
.row-home-category-3-products .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  margin: 2rem auto;
}
.row-home-category-3-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-category-3-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-home-category-3-products-card-2-column {
  width: 100% !important;
  height: 100%;
  display: flex;
  border: 2rem white solid;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .row-home-category-3-products-card-2-column {
    height: 40vh;
  }
}
.row-home-category-3-products-card-2-column [class^=col-] {
  float: left;
}
.row-home-category-3-products-card-2-column [class^=col-]:not(:last-child) {
  margin-right: 0.1rem;
}
.row-home-category-3-products-card-2-column .col-1-of-2 {
  width: calc((100% - 0.1rem) / 2) !important;
  height: 100%;
  flex-direction: column;
  border: 1.5rem rgba(0, 0, 0, 0.085) solid;
}
.row-home-category-3-products-card-2-column .col-1-of-2:not(:last-child) {
  border-right: 0.1rem rgba(255, 255, 255, 0.5) solid;
}
.row-home-category-3-products-card-2-column .col-1-of-2__flex-column {
  width: 100%;
  flex-direction: column !important;
  justify-content: center;
  height: 50%;
}
.row-home-category-3-products-card-2-column .col-1-of-2__flex-column:not(:last-child) {
  border-bottom: 0.3rem rgba(255, 255, 255, 0.5) solid;
}
@media (max-width: 37.5em) {
  .row-home-category-3-products-card-2-column .col-1-of-2__flex-column:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.row-home-category-2-products-card-2-column {
  width: 100% !important;
  height: 80%;
  display: flex;
  margin-top: 3.5rem;
  border: 2rem white solid;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .row-home-category-2-products-card-2-column {
    height: 35vh !important;
    margin-top: 2rem;
  }
}
.row-home-category-2-products-card-2-column [class^=col-] {
  float: left;
}
.row-home-category-2-products-card-2-column [class^=col-]:not(:last-child) {
  margin-right: 0.1rem;
}
.row-home-category-2-products-card-2-column .col-1-of-2 {
  width: calc((100% - 0.1rem) / 2) !important;
  height: 100%;
  border: 1.5rem rgba(0, 0, 0, 0.085) solid;
}
.row-home-category-2-products-card-2-column .col-1-of-2:not(:last-child) {
  border-right: 0.1rem rgba(255, 255, 255, 0.5) solid;
}

.row-home-products-videos--4-vids {
  width: 100%;
  display: flex;
  color: white;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 4rem 2rem !important;
  padding: 3rem 1rem 3rem 0.1rem !important;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.row-home-products-videos--4-vids::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-products-videos--4-vids [class^=col-] {
  float: left;
}
.row-home-products-videos--4-vids [class^=col-]:not(:last-child) {
  margin-right: 0.5rem !important;
}
.row-home-products-videos--4-vids .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-home-products-videos--4-vids .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-home-products-videos--4-vids .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin: 0 auto;
}
.row-home-products-videos--4-vids .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-products-videos--4-vids .col-1-of-4 {
  width: calc((96% - 3 * 2rem) / 4) !important;
  color: white;
  margin: 1.5rem auto !important;
}
.row-home-products-videos--4-vids .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-products-videos--4-vids .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-products-videos--4-vids {
  width: 95%;
  color: white;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 4rem auto !important;
  padding: 4rem 10rem 4rem 5rem !important;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.row-seller-products-videos--4-vids::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-products-videos--4-vids [class^=col-] {
  float: left;
}
.row-seller-products-videos--4-vids [class^=col-]:not(:last-child) {
  margin-right: 0.5rem !important;
}
.row-seller-products-videos--4-vids .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-products-videos--4-vids .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-products-videos--4-vids .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin: 0 auto;
}
.row-seller-products-videos--4-vids .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-products-videos--4-vids .col-1-of-4 {
  width: calc((96% - 3 * 2rem) / 4) !important;
  color: white;
  margin: 5rem auto !important;
}
.row-seller-products-videos--4-vids .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-products-videos--4-vids .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-podcasts-best-sellers {
  width: 100%;
  display: flex;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem;
}
.row-seller-home-podcasts-best-sellers::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-podcasts-best-sellers [class^=col-] {
  float: left;
}
.row-seller-home-podcasts-best-sellers [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-podcasts-best-sellers .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-podcasts-best-sellers .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-podcasts-best-sellers .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-podcasts-best-sellers .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-podcasts-best-sellers .col-1-of-4 {
  width: calc((100% - 3 * 1.5rem) / 4);
  margin: 2rem 5rem;
  border: 0.2rem red solid;
}
.row-seller-home-podcasts-best-sellers .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-podcasts-best-sellers .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-best-sellers-pagination {
  width: 65%;
  display: flex;
  justify-content: center;
  background-color: rgba(242, 242, 249, 0.05);
  margin: 1rem auto 2rem auto;
}
.row-seller-home-best-sellers-pagination::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers-pagination [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers-pagination [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-best-sellers-pagination .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-best-sellers-pagination .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-best-sellers-pagination .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-best-sellers-pagination .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  display: flex;
  margin: 1rem auto !important;
  padding: 2rem auto !important;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-best-sellers-pagination .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-best-sellers-pagination .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers-pagination .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-best-sellers-pagination--category-clothing {
  width: 65%;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
}
.row-seller-home-best-sellers-pagination--category-clothing::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers-pagination--category-clothing [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers-pagination--category-clothing [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-best-sellers-pagination--category-clothing .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-best-sellers-pagination--category-clothing .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-best-sellers-pagination--category-clothing .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-best-sellers-pagination--category-clothing .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.03);
  margin: 0.1rem auto !important;
  padding: 1rem 10rem !important;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-best-sellers-pagination--category-clothing .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-best-sellers-pagination--category-clothing .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers-pagination--category-clothing .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-home-products-videos-pagination {
  width: 65%;
  display: flex;
  justify-content: center;
  justify-items: center;
  background-color: rgba(242, 242, 249, 0.05);
  margin: 1rem auto;
  padding: 2rem;
}
.row-home-products-videos-pagination::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-products-videos-pagination [class^=col-] {
  float: left;
}
.row-home-products-videos-pagination [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-home-products-videos-pagination .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-home-products-videos-pagination .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-home-products-videos-pagination .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-home-products-videos-pagination .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-products-videos-pagination .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-home-products-videos-pagination .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-products-videos-pagination .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-other-sellers-podcasts-pagination {
  width: 65%;
  display: flex;
  margin: 1rem auto -1rem auto;
  padding: 2rem;
}
.row-seller-home-other-sellers-podcasts-pagination::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-other-sellers-podcasts-pagination [class^=col-] {
  float: left;
}
.row-seller-home-other-sellers-podcasts-pagination [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-other-sellers-podcasts-pagination .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-other-sellers-podcasts-pagination .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-other-sellers-podcasts-pagination .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-other-sellers-podcasts-pagination .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-other-sellers-podcasts-pagination .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-other-sellers-podcasts-pagination .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-other-sellers-podcasts-pagination .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-new-arrivals-sellers-pagination {
  width: 65%;
  display: flex;
  margin: 3rem auto -5rem auto;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.1);
}
.row-seller-home-new-arrivals-sellers-pagination::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-sellers-pagination [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-sellers-pagination [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-new-arrivals-sellers-pagination .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-new-arrivals-sellers-pagination .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-new-arrivals-sellers-pagination .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals-sellers-pagination .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-sellers-pagination .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-new-arrivals-sellers-pagination .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-sellers-pagination .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-new-arrivals-sellers-pagination-1 {
  width: 65%;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: -5rem auto -5rem auto;
  padding: 1.5rem !important;
  background-color: rgba(8, 94, 175, 0.08);
  z-index: 3;
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-sellers-pagination-1 {
    width: 70%;
    padding: 0.5rem auto !important;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-sellers-pagination-1 {
    width: fit-content !important;
    height: fit-content !important;
    padding: 2rem 0 2rem 0 !important;
    margin: -3rem auto -2rem auto !important;
    font-size: 1.5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .row-seller-home-new-arrivals-sellers-pagination-1 {
    width: fit-content !important;
    height: fit-content !important;
    padding: 2rem 0 2rem 0 !important;
    font-size: 1.5rem !important;
  }
}
.row-seller-home-new-arrivals-sellers-pagination-1::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-sellers-pagination-1 [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-sellers-pagination-1 [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-1-of-2-form2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem 3rem 5rem 1rem;
  padding: 3rem 2rem;
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: 60rem;
  max-height: 60rem;
  margin: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 10rem 2rem 10rem 1rem;
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-sellers-pagination-1 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form3 {
  width: 100%;
  display: flex;
  height: fit-content;
  background-color: rgba(242, 242, 249, 0.05);
  margin: -3rem auto -5rem auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-home-form3::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form3 [class^=col-] {
  float: left;
}
.row-seller-home-form3 [class^=col-]:not(:last-child) {
  margin-right: 2rem !important;
}
.row-seller-home-form3 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: rgba(8, 94, 175, 0.04);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2.5rem;
}
.row-seller-home-form3 .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2 + 5rem);
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: rgba(8, 94, 175, 0.04);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1rem 2rem;
}
.row-seller-home-form3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-form3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-form3 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-single-pict-width-100 {
  width: 75%;
  display: flex;
  height: fit-content;
  background-color: rgba(242, 242, 249, 0.5);
  margin: 0 auto 0 20rem;
  padding: 3rem;
}
.row-seller-home-form-single-pict-width-100::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-single-pict-width-100 [class^=col-] {
  float: left;
}
.row-seller-home-form-single-pict-width-100 [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-seller-home-form-single-pict-width-100 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  padding: 1rem 2rem;
  padding-bottom: 5rem;
}
.row-seller-home-form-single-pict-width-100 .col-1-of-2-image {
  width: calc((100% - 0.5rem) / 2);
  height: 50rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  padding: 0 5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-form-single-pict-width-100 .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-single-pict-width-100 .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
  padding: 0;
}
.row-seller-home-form-single-pict-width-100 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem;
  margin-left: 5rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-home-form-single-pict-width-100 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-single-pict-width-100 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-single-pict-width-100 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-single-pict-width-100-pink-squarish {
  width: 85%;
  display: flex;
  height: 75vh;
  background-color: rgba(196, 243, 204, 0.7);
  margin: 5rem auto 5rem auto;
  padding: 3rem;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-form-single-pict-width-100-pink-squarish::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-single-pict-width-100-pink-squarish [class^=col-] {
  float: left;
}
.row-seller-home-form-single-pict-width-100-pink-squarish [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  margin: 3rem 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  padding: 2rem;
  padding-bottom: 5rem;
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-1-of-2-image {
  width: calc((100% - 0.5rem) / 2);
  height: 50rem;
  margin: 3rem 5rem 3rem -0.5rem !important;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  padding: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
  padding: 0;
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem;
  margin-left: 5rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-single-pict-width-100-pink-squarish .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-single-pict-pink-and-green-squarish-create-home {
  width: 85%;
  display: flex;
  height: 75vh;
  background-color: rgba(185, 243, 239, 0.2);
  margin: 5rem auto 5rem auto;
  padding: 3rem;
  border: 0.1rem rgba(0, 0, 0, 0.05) solid;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home [class^=col-] {
  float: left;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home [class^=col-]:not(:last-child) {
  margin-right: 5rem !important;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  margin: 3rem 1rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 2rem;
  padding-bottom: 15rem;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-1-of-2-image {
  width: calc((100% - 0.5rem) / 2);
  height: 50rem;
  margin: 1rem auto 3rem auto !important;
  margin-right: -5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  padding: 0;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
  padding: 0;
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem;
  margin-left: 5rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-single-pict-pink-and-green-squarish-create-home .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-single-pict-green-blue-squarish-create-home {
  width: 85%;
  display: flex;
  height: 75vh;
  background-color: rgba(63, 116, 248, 0.1);
  margin: 5rem auto 5rem auto;
  padding: 3rem;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home [class^=col-] {
  float: left;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home [class^=col-]:not(:last-child) {
  margin-right: 5rem !important;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  margin: 3rem 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 2rem;
  padding-bottom: 15rem;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-1-of-2-image {
  width: calc((100% - 0.5rem) / 2);
  height: 50rem;
  margin: 3rem auto !important;
  margin-right: -5rem !important;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  padding: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
  padding: 0;
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem;
  margin-left: 5rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-single-pict-green-blue-squarish-create-home .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-product-page-form1-green-blue-squarish {
  width: 100%;
  display: flex;
  height: fit-content;
  padding: 0.3rem 0 !important;
  border: 0.2rem green solid;
}

.row-seller-home-form-single-pict-width-100-green-egg {
  width: 85%;
  display: flex;
  height: fit-content;
  background-color: rgba(242, 242, 249, 0.5);
  margin: 0 auto;
  padding: 4rem 3rem;
}
.row-seller-home-form-single-pict-width-100-green-egg::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-single-pict-width-100-green-egg [class^=col-] {
  float: left;
}
.row-seller-home-form-single-pict-width-100-green-egg [class^=col-]:not(:last-child) {
  margin-right: 2rem !important;
}
.row-seller-home-form-single-pict-width-100-green-egg .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1rem 2rem;
  padding-bottom: 5rem;
}
.row-seller-home-form-single-pict-width-100-green-egg .col-1-of-2-image {
  width: calc((100% - 0.5rem) / 2);
  height: 50rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: rgba(255, 119, 48, 0.05);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  justify-items: center;
  padding: 0 5rem;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-seller-home-form-single-pict-width-100-green-egg .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-single-pict-width-100-green-egg .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
  padding: 0;
}
.row-seller-home-form-single-pict-width-100-green-egg .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem;
  margin-left: 5rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-home-form-single-pict-width-100-green-egg .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-single-pict-width-100-green-egg .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-single-pict-width-100-green-egg .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-cover-background-pict {
  width: 100%;
  display: flex;
  height: fit-content;
  margin: 0 auto;
  padding: 2rem;
}
.row-seller-home-form-cover-background-pict::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-cover-background-pict [class^=col-] {
  float: left;
}
.row-seller-home-form-cover-background-pict [class^=col-]:not(:last-child) {
  margin-right: 0.5rem !important;
}
.row-seller-home-form-cover-background-pict .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: -2rem;
  background-color: rgba(8, 94, 175, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1.5rem;
}
.row-seller-home-form-cover-background-pict .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-cover-background-pict .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-seller-home-form-cover-background-pict .col-2-of-3 {
  width: calc(2 * (100% - 2 * 0.5rem) / 3 + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5rem 10rem;
}
.row-seller-home-form-cover-background-pict .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-cover-background-pict .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-cover-background-pict .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-podcast-width-100 {
  width: 100%;
  display: flex;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  background-color: rgba(6, 57, 112, 0.9);
  box-shadow: 0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.5);
  margin: 3rem auto;
  padding: 1rem 3rem;
}
.row-seller-home-form-podcast-width-100::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-podcast-width-100 [class^=col-] {
  float: left;
}
.row-seller-home-form-podcast-width-100 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-form-podcast-width-100 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: -2rem;
  border-radius: 4%;
  background-color: rgba(247, 247, 247, 0.9);
  color: #085eaf;
  text-align: justify;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1.5rem 3rem;
}
.row-seller-home-form-podcast-width-100 .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2);
  height: 35rem;
  padding: 0 0 0 2rem;
  margin: 6rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-podcast-width-100 .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-seller-home-form-podcast-width-100 .col-2-of-3 {
  width: calc(1 * (100% - 2 * 5rem) / 3 + 0.5rem);
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 1rem auto;
  padding: 5rem 10rem;
  background-color: rgba(255, 255, 255, 0.5);
}
.row-seller-home-form-podcast-width-100 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-podcast-width-100 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-podcast-width-100 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-podcast-width-100-new-design {
  width: 100%;
  display: flex;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  background-color: rgba(6, 57, 112, 0.393);
  box-shadow: 0.2rem 0.2rem 1rem rgba(6, 57, 112, 0.9);
  outline: 0.3rem solid rgba(255, 255, 255, 0.9);
  margin: 2rem 1rem 2rem 0.2rem;
  padding: 0 2rem 2rem 2rem;
}
.row-seller-home-form-podcast-width-100-new-design::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-podcast-width-100-new-design [class^=col-] {
  float: left;
}
.row-seller-home-form-podcast-width-100-new-design [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-seller-home-form-podcast-width-100-new-design .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  margin-top: 6rem;
  margin-bottom: 4rem;
  margin-right: 5rem;
  margin-left: 5rem;
  border-radius: 2%;
  background-color: rgba(0, 0, 0, 0.06);
  color: white;
  font-size: 1.3rem;
  box-shadow: 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.3);
  outline: 0.14rem solid rgba(255, 255, 255, 0.9);
  outline-offset: 2rem;
  text-align: justify;
  box-shadow: 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1.5rem 3rem;
}
.row-seller-home-form-podcast-width-100-new-design .col-1-of-2-pict {
  width: calc((100% - 5rem) / 2);
  height: 39rem;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 2%;
  padding: 0;
  margin: 6rem 6rem 1rem -1rem;
  background-color: rgba(6, 57, 112, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-podcast-width-100-new-design .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-seller-home-form-podcast-width-100-new-design .col-2-of-3 {
  width: calc(1 * (100% - 2 * 5rem) / 3 + 0.5rem);
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 1rem auto;
  padding: 5rem 10rem;
  background-color: rgba(255, 255, 255, 0.5);
}
.row-seller-home-form-podcast-width-100-new-design .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-podcast-width-100-new-design .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-podcast-width-100-new-design .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-home-form-podcast-width-100-black-design {
  width: 100%;
  display: flex;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  background-color: rgba(86, 67, 250, 0.4);
  box-shadow: 0.2rem 0.2rem 1rem rgba(6, 57, 112, 0.9);
  outline: 0.1rem solid rgba(255, 255, 255, 0.9);
  margin: 2rem 1rem 2rem 0.2rem;
  padding: 0 2rem 2rem 2rem;
}
.row-seller-home-form-podcast-width-100-black-design::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-form-podcast-width-100-black-design [class^=col-] {
  float: left;
}
.row-seller-home-form-podcast-width-100-black-design [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-seller-home-form-podcast-width-100-black-design .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  margin-top: 6rem;
  margin-bottom: 4rem;
  margin-right: 5rem;
  margin-left: 5rem;
  border-radius: 2%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.3rem;
  box-shadow: 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.3);
  outline: 0.1rem solid rgba(255, 255, 255, 0.9);
  outline-offset: 2rem;
  text-align: justify;
  box-shadow: 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1.5rem 3rem;
}
.row-seller-home-form-podcast-width-100-black-design .col-1-of-2-pict {
  width: calc((100% - 5rem) / 2);
  height: 39rem;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 2%;
  padding: 0;
  margin: 6rem 6rem 1rem -1rem;
  background-color: rgba(6, 57, 112, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.row-seller-home-form-podcast-width-100-black-design .col-1-of-3-pict {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-seller-home-form-podcast-width-100-black-design .col-2-of-3 {
  width: calc(1 * (100% - 2 * 5rem) / 3 + 0.5rem);
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 1rem auto;
  padding: 5rem 10rem;
  background-color: rgba(255, 255, 255, 0.5);
}
.row-seller-home-form-podcast-width-100-black-design .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-form-podcast-width-100-black-design .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-form-podcast-width-100-black-design .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-create-home-form4 {
  width: 90%;
  height: fit-content;
  display: flex;
  height: fit-content;
  background-color: rgba(86, 67, 250, 0.025);
  margin: 0 auto 2rem auto;
  padding: 2rem 2rem 0 2rem;
}
.row-seller-create-home-form4::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-create-home-form4 [class^=col-] {
  float: left;
}
.row-seller-create-home-form4 [class^=col-]:not(:last-child) {
  margin-right: 2rem !important;
}
.row-seller-create-home-form4 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 1rem;
  margin-bottom: 5rem;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2.5rem;
}
.row-seller-create-home-form4 .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2 + 5rem);
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 1rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 0 0;
}
.row-seller-create-home-form4 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-create-home-form4 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-create-home-form4 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-create-home-form4 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-create-home-form4 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-create-home-form5 {
  width: 100%;
  display: flex;
  height: fit-content;
  background-color: rgba(86, 67, 250, 0.03);
  margin: 2em auto 2rem auto;
  padding: 0 3rem 0 3rem;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-seller-create-home-form5::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-create-home-form5 [class^=col-] {
  float: left;
}
.row-seller-create-home-form5 [class^=col-]:not(:last-child) {
  margin-right: 2rem !important;
}
.row-seller-create-home-form5 .col-1-of-2 {
  width: calc((100% - 0.5rem) / 2);
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 5rem;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 2.5rem;
}
.row-seller-create-home-form5 .col-1-of-2-pict {
  width: calc((100% - 0.5rem) / 2 + 5rem);
  height: 35rem;
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1rem 2rem;
}
.row-seller-create-home-form5 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-create-home-form5 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-create-home-form5 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-create-home-form5 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-create-home-form5 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}

.row-seller-analysis {
  display: flex;
  max-width: 114rem;
  background-color: rgba(242, 242, 249, 0.05);
  margin: 0 auto;
  padding-bottom: 0;
}
.row-seller-analysis:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-analysis::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-analysis [class^=col-] {
  float: left;
}
.row-seller-analysis [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}

.row-new-arrivals {
  max-width: 100vw;
  height: 50vh;
  background-color: rgba(247, 247, 247, 0.7882352941);
  margin: 0;
  padding: 0;
}
.row-new-arrivals:not(:last-child) {
  margin-bottom: 5rem;
}
.row-new-arrivals::after {
  content: "";
  display: table;
  clear: both;
}
.row-new-arrivals [class^=col-] {
  float: left;
}
.row-new-arrivals [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-new-arrivals .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-new-arrivals .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-new-arrivals .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-new-arrivals .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-new-arrivals .col-1-of-5 {
  width: calc((100% - 5 * 2rem) / 5);
}
.row-new-arrivals .col-2-of-5 {
  width: calc(2 * (100% - 5 * 2rem) / 5 + 1 * 2rem);
}
.row-new-arrivals .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-new-arrivals .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-new-arrivals .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-new-arrivals .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-new-arrivals .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-new-arrivals .col-2-of-6 {
  width: calc(2 * (100% - 5 * 2rem) / 6 + 1 * 2rem);
}
.row-new-arrivals .col-3-of-6 {
  width: calc(3 * (100% - 5 * 2rem) / 6 + 2 * 2rem);
}
.row-new-arrivals .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-new-arrivals .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-new-arrivals .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-topSeller {
  max-width: 60vw;
  max-height: 50vh;
  background-color: rgba(255, 255, 255, 0.05);
  margin: -15rem auto auto auto;
}
.row-topSeller:not(:last-child) {
  margin-bottom: 5rem;
}
.row-topSeller::after {
  content: "";
  display: table;
  clear: both;
}
.row-topSeller .col-2-of-3-topSeller {
  max-width: 114rem;
}

.row-videos {
  max-width: 100vw;
  background-color: rgba(86, 67, 250, 0.15);
  margin: 5rem auto;
  padding: 10rem 3rem;
}
.row-videos:not(:last-child) {
  margin-bottom: 5rem;
}
.row-videos::after {
  content: "";
  display: table;
  clear: both;
}
.row-videos [class^=col-] {
  float: left;
}
.row-videos [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-videos .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-videos .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-videos .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-videos .col-1-of-4 {
  width: calc((100% - 4 * 2rem) / 4);
}
.row-videos .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-videos .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-videos .col-1-of-5 {
  width: calc((100% - 5 * 2rem) / 5);
}
.row-videos .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-videos .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-videos .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-videos .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-videos .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 5 * 2rem);
}

.row-single-video-main-vid {
  max-width: 100%;
  background-color: rgba(86, 67, 250, 0.01);
  margin: -13rem 0 5rem 0;
  padding: 15rem 0rem;
}
.row-single-video-main-vid:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-video-main-vid::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-video-main-vid [class^=col-] {
  float: left;
}
.row-single-video-main-vid [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-single-video-main-vid .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-single-video-main-vid .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-single-video-page-comment {
  max-width: 100%;
  background-color: rgba(86, 67, 250, 0.01);
  margin: 0 0 0 0;
  padding: 9rem 0 0 0;
}
.row-single-video-page-comment:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-video-page-comment::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-video-page-comment [class^=col-] {
  float: left;
}
.row-single-video-page-comment [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-single-video-page-comment .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-single-video-page-comment .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-seller-single-product-video-col-5-of-6-section {
  max-width: 100%;
  background-color: rgba(86, 67, 250, 0.01);
  margin: 0 0 0 0;
  padding: 9rem 0 0 0;
}
.row-seller-single-product-video-col-5-of-6-section:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-product-video-col-5-of-6-section::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-product-video-col-5-of-6-section [class^=col-] {
  float: left;
}
.row-seller-single-product-video-col-5-of-6-section [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-single-product-video-col-5-of-6-section .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-seller-single-product-video-col-5-of-6-section .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row- {
  max-width: 100%;
  background-color: rgba(86, 67, 250, 0.01);
  margin: -13rem 0 5rem 0;
  padding: 15rem 0rem;
}
.row-:not(:last-child) {
  margin-bottom: 5rem;
}
.row-::after {
  content: "";
  display: table;
  clear: both;
}
.row- [class^=col-] {
  float: left;
}
.row- [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row- .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row- .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-single-video-page {
  width: 99%;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 auto;
  padding: 0rem;
}
.row-single-video-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-video-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-video-page [class^=col-] {
  float: left;
}

.row-home-seller-carousel-outside {
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.09);
}
.row-home-seller-carousel-outside:not(:last-child) {
  margin-bottom: 5rem;
}
.row-home-seller-carousel-outside::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-seller-carousel-outside [class^=col-] {
  float: left;
}

.row-home-seller-carousel {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(71, 15, 174, 0.3);
  margin: 0 auto 0 auto;
  padding: 0 auto;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);
}
.row-home-seller-carousel::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-seller-carousel [class^=col-] {
  float: left;
}

.row-seller-header-messages-carousel {
  width: 100%;
  height: 22rem !important;
  display: flex;
  flex-direction: column;
  text-align: justify;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.03);
}
@media (max-width: 37.5em) {
  .row-seller-header-messages-carousel {
    width: 95%;
    height: 25rem !important;
    margin-bottom: 1rem;
  }
}
.row-seller-header-messages-carousel__scroll {
  overflow-y: scroll;
  width: 100%;
  height: 22rem;
  padding: 3rem 2rem !important;
  margin: -0.8rem 0 3rem 0 !important;
  font-size: 1.4rem;
  color: white !important;
  background-color: rgba(255, 119, 48, 0.15);
}
@media (max-width: 37.5em) {
  .row-seller-header-messages-carousel__scroll {
    overflow-y: scroll;
    width: 95%;
    height: 25rem !important;
    font-size: 3rem;
  }
}

.row-single-page-categories-carousel {
  object-fit: fill;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  padding: 0 auto;
  overflow: hidden;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}
.row-single-page-categories-carousel__img {
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
  object-fit: fill !important;
  max-height: 45rem !important;
  min-height: 45rem;
  display: flex !important;
  justify-content: center !important;
  justify-items: center !important;
}

.row-seller-home-categories-carousel {
  object-fit: fill;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  position: absolute;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  padding: 0 auto !important;
  overflow: hidden;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-categories-carousel__img {
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
  object-fit: fill !important;
  max-height: 44.3vh !important;
  min-height: 44.3vh;
  display: flex !important;
  justify-content: flex-end !important;
  justify-items: center !important;
}

.row-seller-home-categories-select-category-products {
  width: 100%;
  height: 68vh;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  background-color: rgba(40, 180, 133, 0.07);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 auto;
  border: none;
}
@media (max-width: 112.5em) {
  .row-seller-home-categories-select-category-products {
    width: 100%;
    height: 70vh !important;
  }
}
@media (max-width: 75em) {
  .row-seller-home-categories-select-category-products {
    width: 100%;
    height: 66vh;
    padding-top: 1rem;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-categories-select-category-products {
    width: 100%;
    height: 50vh !important;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-categories-select-category-products {
    width: 100%;
    height: 60vh !important;
  }
}
@media (max-width: 23.4375em) {
  .row-seller-home-categories-select-category-products {
    width: 100% !important;
    height: 100% !important;
  }
}

.row-home-categories-select-subs-products {
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(40, 180, 133, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5rem 0 5rem;
  border: none;
  z-index: 1;
}

.row-single-product {
  width: 85vw;
  height: fit-content;
  border-radius: 1%;
  border: 0.2rem rgba(255, 255, 255, 0.2) solid;
  background-image: linear-gradient(to top left, rgba(247, 247, 247, 0.9), rgba(14, 85, 179, 0.2));
  transform: skewY(-5deg);
  box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.6);
  margin: 5rem auto;
}
.row-single-product:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product [class^=col-] {
  float: left;
}
.row-single-product [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-single-product .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-single-product .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-single-product .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-single-product .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-single-product .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-single-product .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-single-product .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-single-product .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 3 * 5rem);
}
.row-single-product .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 2rem);
}

.row-shop {
  max-width: 100vw;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin: 0;
  padding: 0;
}
@media (max-width: 37.5em) {
  .row-shop {
    width: 100vw;
    height: fit-content !important;
    position: relative !important;
    padding-top: 6rem !important;
    display: flex;
    flex-wrap: wrap;
  }
}
.row-shop:not(:last-child) {
  margin-bottom: 5rem;
}
.row-shop::after {
  content: "";
  display: table;
  clear: both;
}
.row-shop [class^=col-] {
  float: left;
}
.row-shop [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-shop .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-shop .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-shop .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-shop .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-shop .col-1-of-5-phone {
  width: calc((100% - 5 * 0.5rem) / 5);
  margin-top: 15rem;
}
@media (max-width: 37.5em) {
  .row-shop .col-1-of-5-phone {
    width: 50% !important;
    height: fit-content !important;
    margin-top: 5rem;
  }
}
.row-shop .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-shop .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
  margin-top: 10rem;
}
@media (max-width: 37.5em) {
  .row-shop .col-4-of-5 {
    width: 100vw !important;
    height: fit-content !important;
    padding-bottom: 5rem;
    margin-top: 5rem;
    margin-bottom: 12rem;
  }
}
.row-shop .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-shop .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-shop .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-shop .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-shop .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-shop .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-product-page {
  max-width: 100vw;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: -5rem;
  padding: 0 1rem 2rem 2rem !important;
}
.row-product-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-product-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-product-page [class^=col-] {
  float: left;
}
.row-product-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-product-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-product-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-product-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-product-page .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-product-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-product-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-product-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-product-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-product-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-product-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-product-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-product-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-product-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-profile-page {
  max-width: 100vw;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  padding: 0 1rem 2rem 2rem !important;
}
.row-seller-profile-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-profile-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-profile-page [class^=col-] {
  float: left;
}
.row-seller-profile-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-profile-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-profile-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-profile-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-profile-page .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  min-height: 70rem !important;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  justify-items: flex-start;
}
.row-seller-profile-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-profile-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-profile-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-profile-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-seller-profile-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-profile-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-profile-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-profile-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-profile-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-admin-user-list-page {
  max-width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: -5rem;
  padding: 0 1rem 2rem 2rem !important;
}
.row-admin-user-list-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-user-list-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-user-list-page [class^=col-] {
  float: left;
}
.row-admin-user-list-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem !important;
}
.row-admin-user-list-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-admin-user-list-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-admin-user-list-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-admin-user-list-page .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-admin-user-list-page .col-1-of-5 {
  width: calc((100% - 4 * 0.5rem) / 5) !important;
}
.row-admin-user-list-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-admin-user-list-page .col-4-of-5 {
  width: calc(4 * (100% - 4 * 0.5rem) / 5 + 3 * 0.5rem) !important;
}
.row-admin-user-list-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-admin-user-list-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-admin-user-list-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-admin-user-list-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-admin-user-list-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-admin-user-list-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-dashboard-profile-page {
  max-width: 100vw;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  padding: 1rem 1rem 2rem 1rem !important;
}
.row-seller-dashboard-profile-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-dashboard-profile-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-dashboard-profile-page [class^=col-] {
  float: left;
}
.row-seller-dashboard-profile-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-dashboard-profile-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-dashboard-profile-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-dashboard-profile-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-dashboard-profile-page .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
@media (max-width: 37.5em) {
  .row-seller-dashboard-profile-page .col-1-of-4 {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.row-seller-dashboard-profile-page .col-1-of-4-seller-menu {
  width: 0;
  height: 0;
  opacity: 0;
}
@media (max-width: 37.5em) {
  .row-seller-dashboard-profile-page .col-1-of-4-seller-menu {
    width: 100vw;
    height: fit-content;
    opacity: 1;
  }
}
.row-seller-dashboard-profile-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-dashboard-profile-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-dashboard-profile-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-dashboard-profile-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-seller-dashboard-profile-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
@media (max-width: 37.5em) {
  .row-seller-dashboard-profile-page .col-3-of-4 {
    width: 100%;
  }
}
.row-seller-dashboard-profile-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-dashboard-profile-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-dashboard-profile-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-dashboard-profile-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-products-videos-page {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  padding: 10rem 1rem 20rem 0.1rem !important;
  padding-right: -1rem !important;
}
.row-seller-products-videos-page__top-section {
  padding: 0 0 2rem 0 !important;
}
.row-seller-products-videos-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-products-videos-page [class^=col-] {
  float: left;
}
.row-seller-products-videos-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-products-videos-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-products-videos-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-products-videos-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-products-videos-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-products-videos-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 2rem) / 4);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-products-videos-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
  height: fit-content !important;
  margin-top: 20rem;
  padding-top: 15rem;
  padding-bottom: 8rem;
  background-color: rgba(86, 67, 250, 0.05);
}
.row-seller-products-videos-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-products-videos-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-products-videos-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 0.5rem);
  padding-left: 2rem;
  padding-right: 2rem;
}
.row-seller-products-videos-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-products-videos-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-products-videos-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-products-videos-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-products-videos-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-customer-pictures-page {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  padding-right: -1rem !important;
}
.row-seller-customer-pictures-page__top-section {
  margin-top: -0.6rem !important;
  padding: 0 0 2rem 0 !important;
}
.row-seller-customer-pictures-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-customer-pictures-page [class^=col-] {
  float: left;
}
.row-seller-customer-pictures-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-customer-pictures-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-customer-pictures-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-customer-pictures-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-customer-pictures-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-customer-pictures-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 2rem) / 4);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-customer-pictures-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-customer-pictures-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-customer-pictures-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-customer-pictures-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 0.5rem);
  padding-left: 2rem;
  padding-right: 2rem;
}
.row-seller-customer-pictures-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-customer-pictures-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-customer-pictures-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-customer-pictures-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-customer-pictures-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-videos-products-section-background {
  width: 100%;
  height: fit-content;
  background-color: rgba(63, 116, 248, 0.35);
  padding: 7rem 1rem 10rem 0.1rem !important;
}
.row-seller-videos-products-section-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-videos-products-section-background [class^=col-] {
  float: left;
}
.row-seller-videos-products-section-background [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-videos-products-section-background .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-videos-products-section-background .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-videos-products-section-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-videos-products-section-background .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-videos-products-section-background .col-1-of-4-product-info {
  width: calc((100% - 3 * 2rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-videos-products-section-background .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-videos-products-section-background .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-videos-products-section-background .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-videos-products-section-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 0.5rem);
}
.row-seller-videos-products-section-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-videos-products-section-background .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-videos-products-section-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-videos-products-section-background .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-videos-products-section-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-admin-seller-order-list-not-dashboard {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  padding: 3rem 0 !important;
}
.row-admin-seller-order-list-not-dashboard__top-section {
  margin-top: 0.6rem !important;
  padding: 0 0 2rem 0 !important;
}
.row-admin-seller-order-list-not-dashboard::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-seller-order-list-not-dashboard [class^=col-] {
  float: left;
}
.row-admin-seller-order-list-not-dashboard [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-admin-seller-order-list-not-dashboard .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-admin-seller-order-list-not-dashboard .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-admin-seller-order-list-not-dashboard .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-admin-seller-order-list-not-dashboard .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-admin-seller-order-list-not-dashboard .col-1-of-4-product-info {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-admin-seller-order-list-not-dashboard .col-1-of-5 {
  width: calc((100% - 1.2rem) / 5);
  margin-right: 0.1rem !important;
  padding-right: 2rem !important;
  padding-left: 0.1rem;
}
.row-admin-seller-order-list-not-dashboard .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-admin-seller-order-list-not-dashboard .col-4-of-5 {
  width: calc(4 * (100% - 5 * 0.5rem) / 5 + 3 * 0.5rem);
}
.row-admin-seller-order-list-not-dashboard .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 0.5rem);
}
.row-admin-seller-order-list-not-dashboard .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-admin-seller-order-list-not-dashboard .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-admin-seller-order-list-not-dashboard .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-admin-seller-order-list-not-dashboard .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-admin-seller-order-list-not-dashboard .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-public-podcasts-page {
  width: 100%;
  height: fit-content;
  background-color: rgba(63, 116, 248, 0.09);
  margin-top: -5rem !important;
  padding: 5rem 0 2rem 0 !important;
}
.row-seller-public-podcasts-page__top-section {
  margin-top: -0.5rem !important;
}
.row-seller-public-podcasts-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-public-podcasts-page [class^=col-] {
  float: left;
}
.row-seller-public-podcasts-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-public-podcasts-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-public-podcasts-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-public-podcasts-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-public-podcasts-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-public-podcasts-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-public-podcasts-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-public-podcasts-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-public-podcasts-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-public-podcasts-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 0.5rem);
}
.row-seller-public-podcasts-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-public-podcasts-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-public-podcasts-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-public-podcasts-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-public-podcasts-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-podcasts-edit-delete-page {
  max-width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  padding: 0 1rem !important;
}
.row-seller-podcasts-edit-delete-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-podcasts-edit-delete-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-podcasts-edit-delete-page [class^=col-] {
  float: left;
}
.row-seller-podcasts-edit-delete-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-podcasts-edit-delete-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-podcasts-edit-delete-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-podcasts-edit-delete-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-podcasts-edit-delete-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-podcasts-edit-delete-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-podcasts-edit-delete-page .col-1-of-5 {
  width: calc((100% - 4 * 0.5rem) / 5);
}
.row-seller-podcasts-edit-delete-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-podcasts-edit-delete-page .col-4-of-5 {
  width: calc(4 * (100% - 4 * 0.5rem) / 5 + 3 * 0.5rem);
}
.row-seller-podcasts-edit-delete-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 0.5rem);
}
.row-seller-podcasts-edit-delete-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-podcasts-edit-delete-page .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-podcasts-edit-delete-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-podcasts-edit-delete-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-podcasts-edit-delete-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-podcasts-page {
  max-width: 100vw;
  height: fit-content;
  background-color: rgba(63, 116, 248, 0.35);
  margin-top: -5rem;
  padding: 0 0 2rem 2rem !important;
}
.row-seller-podcasts-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-podcasts-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-podcasts-page [class^=col-] {
  float: left;
}
.row-seller-podcasts-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-podcasts-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-podcasts-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-podcasts-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-podcasts-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-podcasts-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-podcasts-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-podcasts-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-podcasts-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 0.5rem) / 5 + 3 * 0.5rem);
}
.row-seller-podcasts-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 0.5rem);
}
.row-seller-podcasts-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-podcasts-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-podcasts-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-podcasts-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-podcasts-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-single-podcast-background {
  max-width: 100vw;
  height: fit-content;
  background-color: rgba(63, 116, 248, 0.35);
  margin-top: -5rem;
  padding: 0 0 2rem 2rem !important;
}
.row-seller-single-podcast-background:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-podcast-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-podcast-background [class^=col-] {
  float: left;
}
.row-seller-single-podcast-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-single-podcast-background .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-single-podcast-background .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-single-podcast-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-single-podcast-background .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-single-podcast-background .col-1-of-4-product-info {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-single-podcast-background .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-single-podcast-background .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-single-podcast-background .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-single-podcast-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 0.5rem);
}
.row-seller-single-podcast-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-single-podcast-background .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-single-podcast-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-single-podcast-background .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-single-podcast-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-single-product-video-background-page {
  max-width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
}
.row-seller-single-product-video-background-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-product-video-background-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-product-video-background-page [class^=col-] {
  float: left;
}
.row-seller-single-product-video-background-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-single-product-video-background-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-single-product-video-background-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-single-product-video-background-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-single-product-video-background-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 0 !important;
}
.row-seller-single-product-video-background-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 3rem) / 4);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-single-product-video-background-page .col-1-of-5 {
  width: calc((100% - 5 * 2rem) / 5);
}
.row-seller-single-product-video-background-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-single-product-video-background-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-single-product-video-background-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 3rem) / 4 + 3rem);
  padding-left: 2rem;
  padding-right: 2rem;
}
.row-seller-single-product-video-background-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-single-product-video-background-page .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-single-product-video-background-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-single-product-video-background-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-single-product-video-background-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-single-video-seller-products-videos-page {
  max-width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: -5rem;
  padding: 5rem 0 2rem 0 !important;
}
.row-seller-single-video-seller-products-videos-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-video-seller-products-videos-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-video-seller-products-videos-page [class^=col-] {
  float: left;
}
.row-seller-single-video-seller-products-videos-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-single-video-seller-products-videos-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-single-video-seller-products-videos-page .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-single-video-seller-products-videos-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-single-video-seller-products-videos-page .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-single-video-seller-products-videos-page .col-1-of-4-product-info {
  width: calc((100% - 3 * 2rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-single-video-seller-products-videos-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-single-video-seller-products-videos-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-single-video-seller-products-videos-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-single-video-seller-products-videos-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-seller-single-video-seller-products-videos-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-single-video-seller-products-videos-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-single-video-seller-products-videos-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-single-video-seller-products-videos-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-single-video-seller-products-videos-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-single-video-related-videos-from-others {
  max-width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: -5rem;
  padding: 3rem 1rem 2rem 2rem !important;
}
.row-seller-single-video-related-videos-from-others:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-video-related-videos-from-others::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-video-related-videos-from-others [class^=col-] {
  float: left;
}
.row-seller-single-video-related-videos-from-others [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-single-video-related-videos-from-others .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-single-video-related-videos-from-others .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-seller-single-video-related-videos-from-others .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-seller-single-video-related-videos-from-others .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  background-color: rgba(0, 0, 0, 0.02);
}
.row-seller-single-video-related-videos-from-others .col-1-of-4-product-info {
  width: calc((100% - 3 * 2rem) / 4);
  background-color: rgba(255, 255, 255, 0.2);
}
.row-seller-single-video-related-videos-from-others .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-single-video-related-videos-from-others .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-single-video-related-videos-from-others .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-single-video-related-videos-from-others .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-seller-single-video-related-videos-from-others .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-single-video-related-videos-from-others .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-single-video-related-videos-from-others .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-single-video-related-videos-from-others .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-single-video-related-videos-from-others .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-products-public-page {
  max-width: 100%;
  height: fit-content;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: 5rem;
}
.row-seller-products-public-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-products-public-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-products-public-page [class^=col-] {
  float: left;
}
.row-seller-products-public-page [class^=col-]:not(:last-child) {
  margin-right: 0.1rem;
}
.row-seller-products-public-page .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-seller-products-public-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  height: 70% !important;
  padding: 7rem 1rem 7rem 0 !important;
  background-color: rgba(86, 67, 250, 0.03);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  margin-top: 30rem;
}
.row-seller-products-public-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.1rem);
}
.row-seller-products-public-page .col-1-of-4 {
  width: calc((100% - 3 * 0.1rem) / 4);
  height: 70% !important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  margin-top: 30rem;
}
.row-seller-products-public-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-seller-products-public-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-products-public-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 0.1rem) / 5 + 3 * 0.1rem);
}
.row-seller-products-public-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-seller-products-public-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-products-public-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-products-public-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-products-public-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-products-public-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-single-product-page {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: 5rem;
}
.row-seller-single-product-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-product-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-product-page [class^=col-] {
  float: left;
}
.row-seller-single-product-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  height: fit-content !important;
  background-color: rgba(86, 67, 250, 0.03);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.row-seller-single-product-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 0.1rem);
}
@media (max-width: 37.5em) {
  .row-seller-single-product-page .col-2-of-3 {
    width: 95%;
  }
}
.row-seller-single-product-page .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  height: 70% !important;
  padding: 7rem auto 7rem auto !important;
  background-color: rgba(86, 67, 250, 0.03);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  margin-top: 30rem;
}
.row-seller-single-product-page .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
  height: fit-content !important;
  padding: 15rem auto !important;
  background-color: rgba(86, 67, 250, 0.03);
  margin-top: 25rem;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
@media (max-width: 37.5em) {
  .row-seller-single-product-page .col-1-of-5 {
    width: 100vw !important;
    margin-top: 5rem;
  }
}
.row-seller-single-product-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-single-product-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
@media (max-width: 37.5em) {
  .row-seller-single-product-page .col-4-of-5 {
    width: 100%;
  }
}
.row-seller-single-product-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-seller-single-product-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 1rem) / 4 + 2 * 1rem);
  margin-right: 1rem;
}
.row-seller-single-product-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-single-product-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-single-product-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-single-product-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-shop--col-4-of-5 {
  width: 100%;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  height: fit-content;
  margin: 5rem auto;
  padding: 10rem 0 10rem 1rem;
  padding-left: 3rem;
}
.row-shop--col-4-of-5:not(:last-child) {
  margin-bottom: 5rem;
}
.row-shop--col-4-of-5::after {
  content: "";
  display: table;
  clear: both;
}
.row-shop--col-4-of-5 [class^=col-] {
  float: left;
}
.row-shop--col-4-of-5 [class^=col-]:not(:last-child) {
  margin-right: 1rem;
}
.row-shop--col-4-of-5 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-shop--col-4-of-5 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-shop--col-4-of-5 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-shop--col-4-of-5 .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  height: fit-content !important;
  margin-bottom: 3rem;
}
@media (max-width: 37.5em) {
  .row-shop--col-4-of-5 .col-1-of-4 {
    width: calc((100% - 3 * 2rem) / 2) !important;
    height: fit-content !important;
  }
}
.row-shop--col-4-of-5 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-shop--col-4-of-5 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-shop--col-4-of-5 .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-shop--col-4-of-5 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-shop--col-4-of-5 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-related-products {
  width: 100%;
  height: 95vh;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center !important;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  margin: 5rem auto 10rem auto;
  padding: 4rem 1rem 2rem 2rem !important;
  overflow: hidden;
  -ms-overflow-style: none !important;
  overflow-y: scroll;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}
.row-seller-related-products ::-webkit-scrollbar {
  width: 0 !important; /* Remove scrollbar space */
  background: transparent !important; /* Optional: just make scrollbar invisible */
  display: none !important;
  scrollbar-width: none !important;
}
.row-seller-related-products:not(:last-child) {
  margin-bottom: 5rem;
}
@media (max-width: 37.5em) {
  .row-seller-related-products {
    height: 55vh;
  }
}
.row-seller-related-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-related-products [class^=col-] {
  float: left;
}
.row-seller-related-products [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-related-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-related-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-seller-related-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-related-products .col-1-of-4 {
  width: calc((95% - 3 * 2rem) / 4) !important;
  height: fit-content !important;
  margin: 1rem 1.3rem 1rem 1.3rem !important;
  padding: 1rem 0.1rem 1rem 0.1rem;
  background-color: white;
}
@media (max-width: 37.5em) {
  .row-seller-related-products .col-1-of-4 {
    width: 95% !important;
    height: 35vh !important;
    margin-bottom: 3rem !important;
  }
}
.row-seller-related-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-related-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-related-products .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-related-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-related-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-single-product-display {
  width: fit-content;
  height: fit-content !important;
  border-radius: 0.5%;
  -webkit-border-radius: 0.5%;
  -moz-border-radius: 0.5%;
  -ms-border-radius: 0.5%;
  -o-border-radius: 0.5%;
  display: flex;
  flex-wrap: wrap;
  margin: 5rem auto 10rem 0;
  padding: 5rem auto 5rem auto !important;
}
.row-seller-single-product-display:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-product-display::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-product-display [class^=col-] {
  float: left;
}
.row-seller-single-product-display [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-single-product-display .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-single-product-display .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-seller-single-product-display .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-single-product-display .col-1-of-4 {
  width: calc((90% - 3 * 0.5rem) / 4);
  height: fit-content !important;
  margin: 1rem 0.1rem 10rem 0.1rem;
  padding: 5rem 0.1rem 13rem 0.1rem;
}
@media (max-width: 37.5em) {
  .row-seller-single-product-display .col-1-of-4 {
    width: 100%;
  }
}
.row-seller-single-product-display .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-single-product-display .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
@media (max-width: 37.5em) {
  .row-seller-single-product-display .col-3-of-4 {
    width: 100%;
  }
}
.row-seller-single-product-display .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-single-product-display .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-single-product-display .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-single-video-related-products {
  width: 95%;
  border-radius: 0.5%;
  -webkit-border-radius: 0.5%;
  -moz-border-radius: 0.5%;
  -ms-border-radius: 0.5%;
  -o-border-radius: 0.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 5rem auto 10rem auto;
  padding: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
.row-seller-single-video-related-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-video-related-products [class^=col-] {
  float: left;
}
.row-seller-single-video-related-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-single-video-related-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-seller-single-video-related-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-single-video-related-products .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  height: fit-content !important;
  background-color: rgba(8, 94, 175, 0.04);
  margin: 1rem 0;
  padding: 2rem;
}
.row-seller-single-video-related-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-single-video-related-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-single-video-related-products .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-single-video-related-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-single-video-related-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-new-products-public {
  width: 90%;
  height: fit-content !important;
  border-radius: 0.5%;
  -webkit-border-radius: 0.5%;
  -moz-border-radius: 0.5%;
  -ms-border-radius: 0.5%;
  -o-border-radius: 0.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 2rem auto 5rem auto;
  padding-bottom: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.row-seller-new-products-public::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-new-products-public [class^=col-] {
  float: left;
}
.row-seller-new-products-public .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-new-products-public .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-seller-new-products-public .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-new-products-public .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  height: 100% !important;
  margin: 5rem auto 5rem auto !important;
  padding: 1rem 0.1rem 22rem 0.1rem !important;
}
.row-seller-new-products-public .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-new-products-public .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-new-products-public .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-new-products-public .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-new-products-public .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-public-sub-category-products {
  width: 95%;
  height: fit-content !important;
  border-radius: 0.5%;
  -webkit-border-radius: 0.5%;
  -moz-border-radius: 0.5%;
  -ms-border-radius: 0.5%;
  -o-border-radius: 0.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 5rem auto 5rem auto;
  padding-bottom: 10rem;
  padding-left: 0;
  padding-right: 0;
}
.row-seller-public-sub-category-products__heigth-scroll {
  overflow-y: scroll;
  width: 92vw;
  max-height: 65vh;
  box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  margin: 2rem auto;
  border: 0.5rem solid rgba(255, 255, 255, 0.3);
}
.row-seller-public-sub-category-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-public-sub-category-products [class^=col-] {
  float: left;
}
.row-seller-public-sub-category-products [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-seller-public-sub-category-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-public-sub-category-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-seller-public-sub-category-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-public-sub-category-products .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  height: 100% !important;
  margin: 5rem auto 5rem auto !important;
  padding: 1rem 0.1rem 22rem 0.1rem !important;
}
.row-seller-public-sub-category-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-public-sub-category-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-public-sub-category-products .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-public-sub-category-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-public-sub-category-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-videos-col-4-of-5 {
  width: 100%;
  border-radius: 0.5%;
  -webkit-border-radius: 0.5%;
  -moz-border-radius: 0.5%;
  -ms-border-radius: 0.5%;
  -o-border-radius: 0.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 10rem auto;
  padding: 10rem 0 10rem 1rem;
  padding-left: 3rem;
}
.row-videos-col-4-of-5:not(:last-child) {
  margin-bottom: 5rem;
}
.row-videos-col-4-of-5::after {
  content: "";
  display: table;
  clear: both;
}
.row-videos-col-4-of-5 [class^=col-] {
  float: left;
}
.row-videos-col-4-of-5 [class^=col-]:not(:last-child) {
  margin-right: 1rem;
}
.row-videos-col-4-of-5 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-videos-col-4-of-5 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-videos-col-4-of-5 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-videos-col-4-of-5 .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  margin-bottom: 2rem;
  height: fit-content;
}
@media (max-width: 37.5em) {
  .row-videos-col-4-of-5 .col-1-of-4 {
    width: calc((100% - 3 * 2rem) / 2) !important;
    height: fit-content !important;
  }
}
.row-videos-col-4-of-5 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-videos-col-4-of-5 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-videos-col-4-of-5 .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-videos-col-4-of-5 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-videos-col-4-of-5 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-2 {
  width: 85vw;
  border-radius: 1%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 5rem auto;
  padding-left: 4rem;
  padding-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-2:not(:last-child) {
  margin-bottom: 5rem;
}
.row-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-2 [class^=col-] {
  float: left;
}
.row-2 [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-2 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-2 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-2 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 3rem);
}
.row-2 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 3rem);
}

.row-reviews-pic {
  width: 100%;
  border-radius: 1%;
  height: 35%;
  margin-bottom: 1rem;
  margin-top: 2rem;
  object-fit: fill !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}

.row-reviews-modal-pic {
  width: 100% !important;
  border-radius: 1%;
  height: 35%;
  overflow: scroll;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-reviews-modal-pic:not(:last-child) {
  margin-bottom: 5rem;
}
.row-reviews-modal-pic::after {
  content: "";
  display: table;
  clear: both;
}
.row-reviews-modal-pic [class^=col-] {
  float: left;
}
.row-reviews-modal-pic [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-reviews-modal-pic .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-reviews-modal-pic .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-reviews-modal-pic .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-reviews-modal-pic .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-reviews-modal-pic .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-reviews-modal-pic .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-reviews-modal-pic .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 3rem);
}
.row-reviews-modal-pic .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 3rem);
}

.row-single-product-modal-small-sample {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-around !important;
  justify-items: center;
  width: 20%;
  border-radius: 1%;
  height: 14vh;
}

.row-single-product-modal-pic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 5%;
  height: 13rem !important;
  object-fit: contain !important;
  padding: 0;
  margin-top: 1rem;
  padding-bottom: 0 !important;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
}
.row-single-product-modal-pic:not(:last-child) {
  margin-bottom: -5rem;
}
.row-single-product-modal-pic .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-single-product-modal-pic .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-single-product-modal-pic .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-single-product-modal-pic .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-single-product-modal-pic .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-single-product-modal-pic .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-single-product-modal-pic .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 3rem);
}
.row-single-product-modal-pic .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 3rem);
}

.row-login {
  max-width: 114rem;
  background-color: rgba(242, 242, 249, 0.05);
  margin: 0 auto;
}
.row-login:not(:last-child) {
  margin-bottom: 5rem;
}
.row-login::after {
  content: "";
  display: table;
  clear: both;
}
.row-login [class^=col-] {
  float: left;
}
.row-login [class^=col-]:not(:last-child) {
  margin-right: 6rem;
}
.row-login .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}
.row-login .col-1-of-3 {
  width: calc((100% - 2 * 6rem) / 3);
}
.row-login .col-2-of-3 {
  width: calc(2 * (100% - 2 * 6rem) / 3 + 6rem);
}
.row-login .col-1-of-4 {
  width: calc((100% - 3 * 6rem) / 4);
}
.row-login .col-2-of-4 {
  width: calc(2 * (100% - 3 * 6rem) / 4 + 6rem);
}
.row-login .col-3-of-4 {
  width: calc(3 * (100% - 3 * 6rem) / 4 + 2 * (6rem));
}

.row-3 {
  width: 95vw;
  border-radius: 1%;
  background-color: rgba(247, 252, 250, 0.65);
  margin: 5rem auto;
  padding-left: 5rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
}
.row-3:not(:last-child) {
  margin-bottom: 5rem;
}
.row-3::after {
  content: "";
  display: table;
  clear: both;
}
.row-3 [class^=col-] {
  float: left;
}
.row-3 [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-3 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-3 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-3 .col-1-of-5 {
  width: calc((100% - 5 * 5rem) / 5);
}
.row-3 .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}

.row-new-arrivals-home {
  width: 95vw;
  border-radius: 1%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 5rem auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
}
.row-new-arrivals-home:not(:last-child) {
  margin-bottom: 5rem;
}
.row-new-arrivals-home::after {
  content: "";
  display: table;
  clear: both;
}
.row-new-arrivals-home [class^=col-] {
  float: left;
}
.row-new-arrivals-home [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-new-arrivals-home .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-new-arrivals-home .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-new-arrivals-home .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-new-arrivals-home .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-new-arrivals-home .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-new-arrivals-home .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-new-arrivals-home .col-1-of-5 {
  width: calc((100% - 5 * 5rem) / 5);
}
.row-new-arrivals-home .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}

.row-new-arrivals-seller-home {
  width: 95%;
  border-radius: 1%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 5rem auto;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-new-arrivals-seller-home:not(:last-child) {
  margin-bottom: 5rem;
}
.row-new-arrivals-seller-home::after {
  content: "";
  display: table;
  clear: both;
}
.row-new-arrivals-seller-home [class^=col-] {
  float: left;
}
.row-new-arrivals-seller-home .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-new-arrivals-seller-home .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-new-arrivals-seller-home .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-new-arrivals-seller-home .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-new-arrivals-seller-home .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-new-arrivals-seller-home .col-1-of-5 {
  width: calc((100% - 5 * 5rem) / 5);
}
.row-new-arrivals-seller-home .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}

.row-podcasts-new-arrivals {
  width: 95vw;
  border-radius: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(8, 94, 175, 0.07);
  margin: 5rem auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.row-podcasts-new-arrivals:not(:last-child) {
  margin-bottom: 5rem;
}
.row-podcasts-new-arrivals::after {
  content: "";
  display: table;
  clear: both;
}
.row-podcasts-new-arrivals [class^=col-] {
  float: left;
}
.row-podcasts-new-arrivals [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-podcasts-new-arrivals .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-podcasts-new-arrivals .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-podcasts-new-arrivals .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-podcasts-new-arrivals .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-podcasts-new-arrivals .col-1-of-4:not(:first-child) {
  margin-bottom: 3rem;
}
.row-podcasts-new-arrivals .col-1-of-4:not(:first-child) {
  margin-left: 0.5rem;
}
.row-podcasts-new-arrivals .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-podcasts-new-arrivals .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-podcasts-new-arrivals .col-1-of-5 {
  width: calc((100% - 5 * 5rem) / 5);
}
.row-podcasts-new-arrivals .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}

.row-seller-new-product-list {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0);
  margin: 5rem auto;
}
.row-seller-new-product-list:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-new-product-list::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-new-product-list [class^=col-] {
  float: left;
}
.row-seller-new-product-list [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-new-product-list .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-new-product-list .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-new-product-list .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-new-product-list .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-seller-new-product-list .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-new-product-list .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-new-product-list .col-1-of-5 {
  width: calc((100% - 5 * 5rem) / 5);
}
.row-seller-new-product-list .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}

.row-single-product-info {
  width: 35vw;
  height: fit-content;
  padding: 3rem;
  border-radius: 1%;
  border: 0.3rem rgba(255, 255, 255, 0.8) solid;
  background-image: linear-gradient(to left, rgba(86, 67, 250, 0.05), rgba(86, 67, 250, 0.01));
  box-shadow: 0 0.5rem 2rem black;
  margin: 1rem;
}
.row-single-product-info:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product-info::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product-info [class^=col-] {
  float: left;
}
.row-single-product-info [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-single-product-info .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-single-product-info .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-single-product-info .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-single-product-info .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-single-product-info .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-single-product-info .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-single-product-info .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-single-product-info .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 3 * 5rem);
}
.row-single-product-info .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 2rem);
}

.row-admin-dashboard {
  width: 85vw;
  border-radius: 1%;
  background-image: linear-gradient(125deg, rgb(95, 148, 253) 0%, rgb(250, 250, 250) 50%, rgb(253, 253, 253) 51%, rgb(101, 152, 253) 90%);
  margin: 5rem auto;
  padding-left: 4rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
}
.row-admin-dashboard:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-dashboard::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-dashboard [class^=col-] {
  float: left;
}
.row-admin-dashboard [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-admin-dashboard .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-admin-dashboard .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-admin-dashboard .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-admin-dashboard .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-admin-dashboard .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-admin-dashboard .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-admin-dashboard .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-admin-dashboard .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-orders {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, rgb(69, 81, 248) 0%, rgba(69, 81, 248, 0.4) 8%, rgba(67, 70, 241, 0.01) 15%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(67, 70, 241, 0.4) 40%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 0 auto 3rem auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-orders:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-orders::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-orders [class^=col-] {
  float: left;
}
.row-seller-orders [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-orders .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-orders .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-orders .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-orders .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-orders .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-orders .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-orders .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-orders .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-orders .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-orders .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-background-page {
  width: 100%;
  height: 100% !important;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-top: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-background-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page [class^=col-] {
  float: left;
}
.row-background-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page .col-1-of-5 {
  width: calc((100% - 4 * 0.5rem) / 5);
}
.row-background-page .col-4-of-5 {
  width: calc(4 * (100% - 4 * 0.5rem) / 5 + 0.5rem);
}
.row-background-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page .col-sub-category {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 1rem 8rem 2.5rem;
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.4));
}
.row-background-page .col-single-video-related {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 3rem 8rem;
  padding: 5rem 0rem 5rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-page .col-single-video-related .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-background-page .col-single-video-related-vid {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto 5rem 0 !important;
  padding: 1rem 0;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-page .col-single-video-related-vid__sub-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.row-background-page .col-3-of-4--password-update {
  max-width: 50%;
  margin: 43rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-page .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 3 * 0.5rem);
}
.row-background-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page .col-1-of-7-sellerVideos {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-background-page .col-6-of-7-sellerVideos {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 4 * 2rem);
}

.row-login-background-page {
  width: 100%;
  height: fit-content !important;
  border-radius: 0.5%;
  background-color: rgba(63, 116, 248, 0.2);
  margin-top: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-login-background-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-login-background-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-login-background-page [class^=col-] {
  float: left;
}
.row-login-background-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-login-background-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-login-background-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-login-background-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-login-background-page .col-1-of-5 {
  width: calc((100% - 4 * 0.5rem) / 5);
}
.row-login-background-page .col-4-of-5 {
  width: calc(4 * (100% - 4 * 0.5rem) / 5 + 0.5rem);
}
.row-login-background-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-login-background-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-login-background-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-login-background-page .col-sub-category {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 1rem 8rem 2.5rem;
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.4));
}
.row-login-background-page .col-single-video-related {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 3rem 8rem;
  padding: 5rem 0rem 5rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-login-background-page .col-single-video-related .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-login-background-page .col-single-video-related-vid {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto 5rem 0 !important;
  padding: 1rem 0;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-login-background-page .col-single-video-related-vid__sub-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.row-login-background-page .col-3-of-4--password-update {
  max-width: 50%;
  margin: 43rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-login-background-page .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-login-background-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 3 * 0.5rem);
}
.row-login-background-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-login-background-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-login-background-page .col-1-of-7-sellerVideos {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-login-background-page .col-6-of-7-sellerVideos {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 4 * 2rem);
}

.row-background-admin-products-page {
  width: 100%;
  border-radius: 0.5%;
  background-color: rgba(63, 116, 248, 0.35);
  margin: 2rem auto 3rem auto;
  padding: 2rem 2rem 2rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-background-admin-products-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-admin-products-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-admin-products-page [class^=col-] {
  float: left;
}
.row-background-admin-products-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-admin-products-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-admin-products-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-admin-products-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-admin-products-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-admin-products-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-admin-products-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-admin-products-page .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-background-admin-products-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-background-admin-products-page .col-sub-category {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 1rem 8rem 2.5rem;
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.4));
}
.row-background-admin-products-page .col-single-video-related {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 3rem 8rem;
  padding: 5rem 0rem 5rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-admin-products-page .col-single-video-related .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-background-admin-products-page .col-single-video-related-vid {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8rem auto 0 0;
  padding: 1rem 0;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-admin-products-page .col-single-video-related-vid__sub-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.row-background-admin-products-page .col-3-of-4--password-update {
  max-width: 50%;
  margin: 43rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-admin-products-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-background-admin-products-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-background-admin-products-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-admin-products-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-admin-products-page .col-1-of-7-sellerVideos {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-background-admin-products-page .col-6-of-7-sellerVideos {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 4 * 2rem);
}

.row-background-page-press-release-public {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: -0.3rem auto 3rem auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-press-release-public:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-page-press-release-public::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-press-release-public [class^=col-] {
  float: left;
}
.row-background-page-press-release-public [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-press-release-public .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-press-release-public .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-press-release-public .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-press-release-public .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-page-press-release-public .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-press-release-public .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-page-press-release-public .col-sub-category {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 1rem 8rem 2.5rem;
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.4));
}
.row-background-page-press-release-public .col-single-video-related {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 3rem 8rem;
  padding: 5rem 0rem 5rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-page-press-release-public .col-single-video-related .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-background-page-press-release-public .col-single-video-related-vid {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8rem auto 0 0;
  padding: 1rem 0;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-page-press-release-public .col-single-video-related-vid__sub-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.row-background-page-press-release-public .col-3-of-4--password-update {
  max-width: 50%;
  margin: 43rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-page-press-release-public .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-background-page-press-release-public .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 3 * 2rem);
}
.row-background-page-press-release-public .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-press-release-public .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-press-release-public .col-1-of-7-sellerVideos {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-background-page-press-release-public .col-6-of-7-sellerVideos {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 4 * 2rem);
}

.row-background-page-seller-profile-public {
  width: 100%;
  border-radius: 0.5%;
  background-color: rgba(243, 149, 149, 0.2);
  margin: -0.3rem auto 3rem auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-profile-public:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-page-seller-profile-public::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-profile-public [class^=col-] {
  float: left;
}
.row-background-page-seller-profile-public [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-profile-public .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-profile-public .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-seller-profile-public .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-profile-public .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-page-seller-profile-public .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-profile-public .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-page-seller-profile-public .col-sub-category {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 1rem 8rem 2.5rem;
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.4));
}
.row-background-page-seller-profile-public .col-single-video-related {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 3rem 8rem;
  padding: 5rem 0rem 5rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-page-seller-profile-public .col-single-video-related .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-background-page-seller-profile-public .col-single-video-related-vid {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8rem auto 0 0;
  padding: 1rem 0;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-page-seller-profile-public .col-single-video-related-vid__sub-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.row-background-page-seller-profile-public .col-3-of-4--password-update {
  max-width: 50%;
  margin: 43rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-page-seller-profile-public .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-background-page-seller-profile-public .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 3 * 2rem);
}
.row-background-page-seller-profile-public .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-profile-public .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-profile-public .col-1-of-7-sellerVideos {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-background-page-seller-profile-public .col-6-of-7-sellerVideos {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 4 * 2rem);
}

.row-background-seller-home-forms {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin: 0 0 3rem 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-seller-home-forms:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-seller-home-forms::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-seller-home-forms [class^=col-] {
  float: left;
}
.row-background-seller-home-forms [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-home-forms .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-seller-home-forms .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-seller-home-forms .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-seller-home-forms .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-seller-home-forms .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-seller-home-forms .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 5rem);
}
.row-background-seller-home-forms .col-sub-category {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 1rem 8rem 2.5rem;
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.4));
}
.row-background-seller-home-forms .col-single-video-related {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 3rem 8rem;
  padding: 5rem 0rem 5rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-seller-home-forms .col-single-video-related .col-1-of-5 {
  width: calc((100% - 5 * 0.5rem) / 5);
}
.row-background-seller-home-forms .col-single-video-related-vid {
  width: calc(5 * (100% - 1 * 0.5rem) / 6 + 3 * 5rem);
  margin: 8rem 8rem 0 8rem;
  padding: 1rem 0rem 1rem 7rem;
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
}
.row-background-seller-home-forms .col-single-video-related-vid__sub-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.row-background-seller-home-forms .col-seller-profile--categories-sub {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
  margin: 2rem 5rem 5rem 0;
  background-image: linear-gradient(to bottom, rgba(8, 94, 175, 0.2), rgba(86, 67, 250, 0.2)) !important;
}
.row-background-seller-home-forms .col-seller-profile--categories-sub__list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  padding: 2rem 3rem;
}
.row-background-seller-home-forms .col-seller-profile--categories-sub__list--item {
  width: 100%;
  max-width: 22rem;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  margin: 1rem;
  padding: 1.5rem 3rem;
}
.row-background-seller-home-forms .col-3-of-4--password-update {
  max-width: 50%;
  margin: 43rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-seller-home-forms .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-background-seller-home-forms .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 3 * 2rem);
}
.row-background-seller-home-forms .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-seller-home-forms .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-seller-home-forms .col-1-of-7-sellerVideos {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-background-seller-home-forms .col-6-of-7-sellerVideos {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 4 * 2rem);
}

.row-background-page-seller-video {
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.7) 50%, rgba(67, 70, 241, 0.7) 51%, rgba(178, 179, 243, 0.5) 85%, rgba(88, 91, 245, 0.8) 100%, rgba(67, 70, 241, 0.8) 100%);
  margin: 0.5rem 1rem -1rem 1rem;
  padding-bottom: 10rem;
  padding-top: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-video::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-video [class^=col-] {
  float: left;
}
.row-background-page-seller-video [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page-seller-video .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-video .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-background-page-seller-video .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-video .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
  height: 35rem;
}
.row-background-page-seller-video .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-video .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-video .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-seller-video .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-seller-video .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-seller-video .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-video .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-video .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-video .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-home-new-arrivals-4-cards-and-pagination {
  width: 100%;
  height: 40rem;
  clip-path: polygon(5% 0, 95% 0, 100% 100%, 0 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(8, 94, 175, 0.2), rgba(8, 94, 175, 0.9));
  margin: 0 auto 5rem auto;
  padding: 5rem 7rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination {
    width: 85vw;
    display: flex;
    flex-wrap: wrap;
    margin: 0 2vw 5rem -3vw !important;
    padding: 5rem 3rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination {
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin: 0 2vw 5rem -5vw !important;
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination {
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin: 0 5vw 1rem -6.5vw !important;
    padding-right: 7vw !important;
    padding-left: 7vw !important;
    clip-path: polygon(5% 0, 95% 0, 95% 95%, 5% 95%);
  }
}
@media (max-width: 23.4375em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination {
    width: 95vw;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin: 0 1vw 1rem -3vw !important;
    padding-right: 1vw !important;
    padding-left: 1vw !important;
    clip-path: polygon(5% 0, 95% 0, 95% 95%, 5% 95%);
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem auto;
  padding: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
  margin: 0.2rem 1rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination__cards-background {
  background-color: rgba(6, 57, 112, 0.8);
  display: flex;
  justify-content: center;
  justify-items: center;
}

.row-seller-home-new-arrivals-4-cards-and-pagination--outside {
  width: 100%;
  height: fit-content !important;
  margin: 1rem auto 0 auto;
  padding: 5rem 10rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside {
    display: flex;
    flex-wrap: wrap !important;
    padding: 2rem 2rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside {
    display: flex;
    flex-wrap: wrap !important;
    height: fit-content;
    margin: 1rem auto 1rem auto;
    padding: 5rem 5rem !important;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside {
    display: flex;
    flex-wrap: wrap !important;
    height: fit-content !important;
    margin: 1rem auto 1rem auto;
    padding: 5rem 5rem !important;
  }
}
@media (max-width: 23.4375em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside {
    display: flex;
    flex-wrap: wrap !important;
    height: fit-content !important;
    margin: 1rem auto 2rem auto;
    padding: 5rem 2rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 4) !important;
    height: 30rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin-bottom: 3rem !important;
    margin-right: 3rem !important;
    margin-left: 2rem !important;
    padding-bottom: -5rem !important;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin-bottom: 3rem !important;
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem auto;
  padding: 4rem 10rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background {
    height: 60rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background {
    height: fit-content !important;
    padding: 4rem 3.4vw 4rem 6.5vw !important;
  }
}
@media (max-width: 23.4375em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background {
    height: fit-content !important;
    padding: 4rem 3vw 4rem 3vw !important;
    margin-right: 0 !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background [class^=col-]:not(:last-child) {
    margin-right: 0 !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
  height: 40rem;
  margin: 0.2rem 1rem;
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-4 {
    height: 30rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination--outside__cards-background {
  background-color: rgba(6, 57, 112, 0.8);
}

.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos {
  height: fit-content !important;
  margin: 0 auto 2rem auto;
  padding: 15rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos {
    display: flex;
    flex-wrap: wrap !important;
    margin: 1rem auto 1rem auto;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos {
    display: flex;
    flex-wrap: wrap !important;
    height: 125rem;
    border: 1rem blue solid;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos {
    height: 100%;
    border: 0.1rem orange solid;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background {
  width: 95vw;
  height: fit-content !important;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 11rem auto !important;
  padding: 7rem 5rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background {
    height: fit-content !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background {
    height: fit-content !important;
  }
}
@media (max-width: 37.5em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background {
    width: 100%;
    height: fit-content !important;
  }
}
@media (max-width: 31.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background {
    width: 100% !important;
    height: fit-content !important;
    padding: 5rem 3rem !important;
  }
}
@media (max-width: 25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background {
    width: 100%;
    height: fit-content !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos__cards-background {
  background-color: rgba(6, 57, 112, 0.8);
}

.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts {
  width: 100% !important;
  height: fit-content !important;
  margin: 1rem auto 2rem auto;
  border: 0.5rem rgb(225, 255, 0) solid;
  padding-bottom: 11rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts {
    display: flex;
    flex-wrap: wrap !important;
    margin: 1rem auto 1rem auto;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts {
    display: flex;
    flex-wrap: wrap !important;
    height: 125rem;
    border: 1rem blue solid;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin-bottom: 2rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background {
  width: 100%;
  height: 60rem !important;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem auto;
  padding: 4rem 10rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background {
    height: 60rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background {
    height: 95rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
  height: 40rem;
  margin: 0.2rem 1rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts__cards-background {
  background-color: rgba(6, 57, 112, 0.8);
}

.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery {
  width: 100% !important;
  height: fit-content !important;
  border: 1rem orange solid;
  margin: 1rem auto 2rem auto;
  padding-bottom: 11rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery {
    display: flex;
    flex-wrap: wrap !important;
    margin: 1rem auto 1rem auto;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery {
    display: flex;
    flex-wrap: wrap !important;
    height: 125rem;
    border: 1rem blue solid;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-4 {
    width: calc((100% - 2 * 5rem) / 2) !important;
    height: 30rem !important;
    margin-bottom: 2rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background {
  width: 100%;
  height: 60rem !important;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem auto;
  padding: 4rem 10rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
@media (max-width: 75em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background {
    height: 60rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background {
    height: 95rem !important;
  }
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
  height: 40rem;
  margin: 0.2rem 1rem;
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery__cards-background {
  background-color: rgba(6, 57, 112, 0.8);
}

.row-home-category-products-3-and-2 {
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.04), rgba(8, 94, 175, 0.2), rgba(8, 94, 175, 0.2), rgba(8, 94, 175, 0.2), rgba(0, 0, 0, 0.04));
  margin: -1rem auto;
  padding: 5rem 7rem 5rem 7rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
@media (max-width: 37.5em) {
  .row-home-category-products-3-and-2 {
    padding: 5rem 2rem 5rem 2rem;
  }
}
.row-home-category-products-3-and-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-category-products-3-and-2 [class^=col-] {
  float: left;
}
.row-home-category-products-3-and-2 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-category-products-3-and-2 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-category-products-3-and-2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-home-category-products-3-and-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-category-products-3-and-2 .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-home-category-products-3-and-2 .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-category-products-3-and-2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-category-products-3-and-2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-category-products-3-and-2 .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-category-products-3-and-2 .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-category-products-3-and-2 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-category-products-3-and-2 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-category-products-3-and-2 .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-category-products-3-and-2 .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-category-products-3-and-2__inside-background {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 1rem auto;
  padding: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.2);
}
.row-home-category-products-3-and-2__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-category-products-3-and-2__inside-background [class^=col-] {
  float: left;
}
.row-home-category-products-3-and-2__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-category-products-3-and-2__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-category-products-3-and-2__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-category-products-3-and-2__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-category-products-3-and-2__inside-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
  height: 29rem;
  margin: 0.2rem 1rem;
}
.row-home-category-products-3-and-2__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-category-products-3-and-2__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-category-products-3-and-2__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-category-products-3-and-2__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-category-products-3-and-2__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-category-products-3-and-2__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-category-products-3-and-2__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-category-products-3-and-2__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-category-products-3-and-2__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-category-products-3-and-2__cards-background {
  background-color: rgba(6, 57, 112, 0.8);
}

.row-background-page-seller-home-podcasts-and-others {
  width: 100%;
  background-color: rgba(86, 67, 250, 0.1);
  margin: 1rem auto 3rem auto;
  padding: 5rem 10rem 5rem 10rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-home-podcasts-and-others::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-home-podcasts-and-others [class^=col-] {
  float: left;
}
.row-background-page-seller-home-podcasts-and-others [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-home-podcasts-and-others .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-home-podcasts-and-others .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-background-page-seller-home-podcasts-and-others .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-home-podcasts-and-others .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-and-others .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-and-others .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-home-podcasts-and-others .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-seller-home-podcasts-and-others .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-seller-home-podcasts-and-others .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-seller-home-podcasts-and-others .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-home-podcasts-and-others .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-home-podcasts-and-others .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-home-podcasts-and-others .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-home-best-sellers-background {
  width: 98%;
  height: fit-content;
  background-color: rgba(255, 119, 48, 0.05);
  margin: 1rem auto;
  padding: 5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-home-best-sellers-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers-background [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-best-sellers-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-best-sellers-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-best-sellers-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-best-sellers-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-seller-home-best-sellers-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-best-sellers-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-best-sellers-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-best-sellers-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-best-sellers-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-best-sellers-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-best-sellers-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-best-sellers-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-best-sellers-background__inside-background {
  width: 95%;
  height: fit-content;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto;
  padding: 5rem auto !important;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-best-sellers-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-best-sellers-background__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-best-sellers-background__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-best-sellers-background__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-best-sellers-background__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-best-sellers-background__inside-background .col-1-of-4 {
  width: 33rem;
  height: 30rem;
  margin: 2rem auto 1rem auto;
}
.row-seller-home-best-sellers-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-best-sellers-background__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-best-sellers-background__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-best-sellers-background__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-best-sellers-background__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-best-sellers-background__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-best-sellers-background__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-best-sellers-background__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-best-sellers-background__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-home-best-sellers-category-clothing-background {
  width: 100%;
  height: fit-content;
  background-color: rgba(86, 67, 250, 0.05);
  margin: 0 auto;
  padding: 10rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-home-best-sellers-category-clothing-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-category-clothing-background [class^=col-] {
  float: left;
}
.row-home-best-sellers-category-clothing-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-best-sellers-category-clothing-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-category-clothing-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-category-clothing-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-category-clothing-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-category-clothing-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-category-clothing-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-category-clothing-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-category-clothing-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-category-clothing-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-best-sellers-category-clothing-background__inside-background {
  width: 95%;
  height: 80vh;
  background-color: rgba(6, 57, 112, 0.85);
  margin: 1rem auto 1rem auto;
  padding: 4rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  flex-wrap: nowrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.2);
}
.row-home-best-sellers-category-clothing-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-category-clothing-background__inside-background [class^=col-] {
  float: left;
}
.row-home-best-sellers-category-clothing-background__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-category-clothing-background__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-1-of-4 {
  width: 33rem;
  height: 30rem;
  margin: 2rem auto 1rem auto;
}
.row-home-best-sellers-category-clothing-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-category-clothing-background__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 {
  width: 100%;
  height: fit-content;
  background-color: rgba(255, 119, 48, 0.07);
  border: 0.3rem white solid;
  margin: 5rem auto;
  padding: 4rem auto 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .row-home-best-sellers-category-clothing-background__inside-background-2 {
    margin-top: 1rem;
  }
}
.row-home-best-sellers-category-clothing-background__inside-background-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-category-clothing-background__inside-background-2 [class^=col-] {
  float: left;
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-1-of-4 {
  width: 33rem;
  height: 30rem;
  margin: 2rem auto 1rem auto;
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-category-clothing-background__inside-background-2 .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 {
  width: 100%;
  height: fit-content;
  background-color: rgba(34, 44, 157, 0.15);
  margin: 1rem auto;
  padding: 2rem 4rem 0 1rem !important;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.row-home-best-sellers-category-clothing-background__inside-background-3::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-category-clothing-background__inside-background-3 [class^=col-] {
  float: left;
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-1-of-4 {
  width: 33rem;
  height: 30rem;
  margin: 2rem auto 1rem auto;
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-category-clothing-background__inside-background-3 .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-home-best-sellers-category-clothing-background-2 {
  width: 100%;
  height: fit-content;
  background-color: rgba(86, 67, 250, 0.05);
  margin: 0 auto;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-home-best-sellers-category-clothing-background-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-category-clothing-background-2 [class^=col-] {
  float: left;
}
.row-home-best-sellers-category-clothing-background-2 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-best-sellers-category-clothing-background-2 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-category-clothing-background-2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-category-clothing-background-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-category-clothing-background-2 .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background-2 .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background-2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-category-clothing-background-2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background-2 .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-category-clothing-background-2 .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background-2 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-category-clothing-background-2 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-category-clothing-background-2 .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-category-clothing-background-2 .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background {
  width: 95% !important;
  height: fit-content;
  background-color: rgba(6, 57, 112, 0.85);
  margin: 1rem auto 1rem auto;
  padding: 4rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  flex-wrap: nowrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .row-home-best-sellers-category-clothing-background-2__inside-background {
    width: 100%;
    display: flex;
    margin-top: -5rem;
    margin-bottom: 5rem;
  }
}
@media (max-width: 31.25em) {
  .row-home-best-sellers-category-clothing-background-2__inside-background {
    width: 100% !important;
    margin-top: -5rem;
    margin-bottom: 5rem;
  }
}
.row-home-best-sellers-category-clothing-background-2__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-category-clothing-background-2__inside-background [class^=col-] {
  float: left;
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-category-clothing-background-2__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-home-best-sellers-all-categories-background {
  width: 85%;
  height: fit-content !important;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 1rem auto !important;
  padding: 3rem 7rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
@media (max-width: 56.25em) {
  .row-home-best-sellers-all-categories-background {
    margin: 1rem auto 5rem auto !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 37.5em) {
  .row-home-best-sellers-all-categories-background {
    width: 95%;
  }
}
.row-home-best-sellers-all-categories-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-all-categories-background [class^=col-] {
  float: left;
}
.row-home-best-sellers-all-categories-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-best-sellers-all-categories-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-all-categories-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-all-categories-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-all-categories-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-all-categories-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-all-categories-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-all-categories-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-all-categories-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-all-categories-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-all-categories-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-all-categories-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-all-categories-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-best-sellers-all-categories-background__inside-background {
  width: 95%;
  height: 70vh;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto;
  padding: 4rem auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-home-best-sellers-all-categories-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-best-sellers-all-categories-background__inside-background [class^=col-] {
  float: left;
}
.row-home-best-sellers-all-categories-background__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-best-sellers-all-categories-background__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-best-sellers-all-categories-background__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-best-sellers-all-categories-background__inside-background .col-1-of-4 {
  width: 33rem;
  height: 30rem;
  margin: 2rem auto 1rem auto;
}
.row-home-best-sellers-all-categories-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-best-sellers-all-categories-background__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-best-sellers-all-categories-background__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-best-sellers-all-categories-background__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-best-sellers-all-categories-background__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-best-sellers-all-categories-background__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-best-sellers-all-categories-background__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-best-sellers-all-categories-background__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-best-sellers-all-categories-background__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-home-seller-podcasts-background {
  width: 100%;
  height: 90vh;
  background-color: rgba(8, 94, 175, 0.18);
  margin: 8rem auto -1rem auto;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-home-seller-podcasts-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-seller-podcasts-background [class^=col-] {
  float: left;
}
.row-seller-home-seller-podcasts-background__inside-background {
  width: 90%;
  height: 95%;
  justify-content: center !important;
  justify-items: center;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto !important;
  padding: 2rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-seller-podcasts-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-seller-podcasts-background__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-seller-podcasts-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-seller-podcasts-background__inside-background--inside {
  width: 95% !important;
  padding: 2rem !important;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  margin-bottom: 4rem !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.row-seller-home-seller-podcasts-background__inside-background--inside .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4) !important;
  justify-items: center;
  margin: 1rem 1rem 1rem 2rem !important;
}

.row-home-product-videos-navy-dark-background {
  width: 100%;
  height: 90vh;
  background-color: rgba(8, 94, 175, 0.18);
  margin: 8rem auto -1rem auto;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-home-product-videos-navy-dark-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-product-videos-navy-dark-background [class^=col-] {
  float: left;
}
.row-home-product-videos-navy-dark-background__inside-background {
  width: 90% !important;
  height: fit-content !important;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto !important;
  padding: 2rem 1rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
@media (max-width: 37.5em) {
  .row-home-product-videos-navy-dark-background__inside-background {
    width: 100% !important;
  }
}
.row-home-product-videos-navy-dark-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-product-videos-navy-dark-background__inside-background [class^=col-] {
  float: left;
}
.row-home-product-videos-navy-dark-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-product-videos-navy-dark-background__inside-background--inside {
  width: 95%;
  height: fit-content !important;
  padding-top: 5rem !important;
  padding-left: 2rem !important;
  margin-bottom: 4rem !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.row-home-product-videos-navy-dark-background__inside-background--inside .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4) !important;
  justify-items: center;
  margin: 1rem 1rem 1rem 2rem !important;
}

.row-home-podcasts-background-1 {
  width: 100%;
  height: 90vh;
  margin: 8rem auto -1rem auto;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-home-podcasts-background-1::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-podcasts-background-1 [class^=col-] {
  float: left;
}
.row-home-podcasts-background-1__inside-background {
  width: 95% !important;
  height: fit-content !important;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto !important;
  padding: 2rem 1rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-home-podcasts-background-1__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-podcasts-background-1__inside-background [class^=col-] {
  float: left;
}
.row-home-podcasts-background-1__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-podcasts-background-1__inside-background--inside {
  width: 95%;
  height: 30vh !important;
  padding-top: 2rem !important;
  padding-left: 1rem !important;
  margin-bottom: 4rem !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.row-home-podcasts-background-1__inside-background--inside .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  height: 100%;
  justify-items: center;
  margin: 1rem 1rem 2rem 1rem !important;
  border: 0.2rem red solid;
}

.row-seller-products-videos-section {
  width: 100%;
  height: fit-content !important;
  background-color: rgba(8, 94, 175, 0.18);
  margin: 8rem auto 11rem auto !important;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-products-videos-section::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-products-videos-section [class^=col-] {
  float: left;
}
.row-seller-products-videos-section__inside-background {
  width: 95% !important;
  height: fit-content !important;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto !important;
  padding: 2rem 1rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-products-videos-section__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-products-videos-section__inside-background [class^=col-] {
  float: left;
}
.row-seller-products-videos-section__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-products-videos-section__inside-background--inside {
  width: 95%;
  height: fit-content !important;
  padding-top: 2rem !important;
  padding-left: 1rem !important;
  margin-bottom: 4rem !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.row-seller-products-videos-section__inside-background--inside .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  height: fit-content;
  justify-items: center;
  margin: 1rem 1rem 2rem 1rem !important;
}

.row-seller-other-seller-videos {
  width: 95%;
  height: fit-content !important;
  background-color: rgba(8, 94, 175, 0.18);
  margin: 2rem 2rem 2rem 3rem !important;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-other-seller-videos::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-other-seller-videos [class^=col-] {
  float: left;
}
.row-seller-other-seller-videos__inside-background {
  width: 95% !important;
  height: fit-content !important;
  min-height: 50rem !important;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto !important;
  padding: 3rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-other-seller-videos__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-other-seller-videos__inside-background [class^=col-] {
  float: left;
}
.row-seller-other-seller-videos__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-other-seller-videos__inside-background--inside {
  width: 95%;
  height: fit-content !important;
  padding-top: 2rem !important;
  padding-left: 1rem !important;
  margin-bottom: 4rem !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.row-seller-other-seller-videos__inside-background--inside .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  height: fit-content;
  justify-items: center;
  margin: 1rem 1rem 2rem 1rem !important;
}

.row-seller-podcasts-background-1 {
  width: 100%;
  height: 90vh;
  background-color: rgba(8, 94, 175, 0.18);
  margin: 8rem auto 8rem auto !important;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-podcasts-background-1::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-podcasts-background-1 [class^=col-] {
  float: left;
}
.row-seller-podcasts-background-1__inside-background {
  width: 95%;
  height: 95%;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto 5rem auto !important;
  padding: 1rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-podcasts-background-1__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-podcasts-background-1__inside-background [class^=col-] {
  float: left;
}
.row-seller-podcasts-background-1__inside-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4) !important;
  justify-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.row-seller-podcasts-background-1__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-podcasts-background-1__inside-background--inside {
  background-color: rgba(255, 255, 255, 0.1);
}

.row-seller-single-podcast-section-seller-podcasts-background {
  width: 100%;
  height: 90vh;
  background-color: rgba(8, 94, 175, 0.18);
  margin: 8rem auto 8rem auto !important;
  padding: 5rem 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-single-podcast-section-seller-podcasts-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-podcast-section-seller-podcasts-background [class^=col-] {
  float: left;
}
.row-seller-single-podcast-section-seller-podcasts-background__inside-background {
  width: 90%;
  height: 95%;
  background-color: rgba(6, 57, 112, 0.9);
  margin: 1rem auto !important;
  padding: 1rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-single-podcast-section-seller-podcasts-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-podcast-section-seller-podcasts-background__inside-background [class^=col-] {
  float: left;
}
.row-seller-single-podcast-section-seller-podcasts-background__inside-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4) !important;
  justify-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.row-seller-single-podcast-section-seller-podcasts-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-single-podcast-section-seller-podcasts-background__inside-background--inside {
  background-color: rgba(255, 255, 255, 0.1);
}

.row-seller-home-seller-podcasts-carousel-background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 1rem auto;
  padding: 2rem 7rem 2rem 7rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background {
  width: 100%;
  height: 80%;
  background-color: rgba(6, 57, 112, 0.8);
  border-left: 8rem solid rgba(0, 0, 0, 0.3);
  border-top: 4rem solid rgba(0, 0, 0, 0.3);
  border-right: 8rem solid rgba(0, 0, 0, 0.3);
  border-bottom: 4rem solid rgba(0, 0, 0, 0.3);
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-2-createPlayer {
  width: 45%;
  margin: 0 0 4rem 2rem;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-2 {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 5rem auto;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.25);
  border: 0.5rem rgba(255, 255, 255, 0.05) solid;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-4 {
  width: 33rem;
  height: 45rem;
  margin: 0 auto 0 2.2rem;
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-seller-home-seller-podcasts-carousel-background__inside-background--carousel {
  width: 95%;
  margin: 0 auto 5rem auto;
  padding: 4rem 3rem 1rem 3rem;
  background-color: rgba(255, 255, 255, 0.12);
  border-top: 0.2rem solid rgba(255, 255, 255, 0.51);
  border-bottom: 0.5rem solid rgba(255, 255, 255, 0.51);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}

.row-home-products-videos-background-new-name {
  width: 100%;
  margin: 1rem auto;
  padding: 5rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(255, 255, 255, 0.3);
}
.row-home-products-videos-background-new-name::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-products-videos-background-new-name [class^=col-] {
  float: left;
}
.row-home-products-videos-background-new-name [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-products-videos-background-new-name .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-products-videos-background-new-name .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-products-videos-background-new-name .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-products-videos-background-new-name .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-products-videos-background-new-name .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-products-videos-background-new-name .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-products-videos-background-new-name .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-products-videos-background-new-name .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-products-videos-background-new-name .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-products-videos-background-new-name .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-products-videos-background-new-name .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-products-videos-background-new-name .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-products-videos-background-new-name__inside-background {
  width: 95%;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 1rem auto 1rem auto;
  padding: 3rem 2rem !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
}
.row-home-products-videos-background-new-name__inside-background:not(:last-child) {
  margin-right: 5rem !important;
}
@media (max-width: 56.25em) {
  .row-home-products-videos-background-new-name__inside-background {
    width: 100%;
    height: 66vh !important;
    padding: 2rem 1rem 2rem 1rem !important;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
    scroll-behavior: smooth;
    border: none;
  }
}
@media (max-width: 37.5em) {
  .row-home-products-videos-background-new-name__inside-background {
    width: 95%;
    height: 45vh !important;
    margin: 0 -1rem 0 3rem !important;
    padding: 1rem 0 1rem 3rem !important;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
    scroll-behavior: smooth;
  }
}
@media (max-width: 31.25em) {
  .row-home-products-videos-background-new-name__inside-background {
    width: 96%;
    height: fit-content !important;
    margin: 0 auto 0 auto !important;
    padding: 2rem !important;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
    scroll-behavior: smooth;
  }
}
.row-home-products-videos-background-new-name__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-products-videos-background-new-name__inside-background [class^=col-] {
  float: left;
}
.row-home-products-videos-background-new-name__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-products-videos-background-new-name__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-products-videos-background-new-name__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-products-videos-background-new-name__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-products-videos-background-new-name__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-products-videos-background-new-name__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-products-videos-background-new-name__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-products-videos-background-new-name__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-products-videos-background-new-name__inside-background .col-1-of-6-videos {
  width: calc((100% - 5 * 2rem) / 4);
}
@media (max-width: 112.5em) {
  .row-home-products-videos-background-new-name__inside-background .col-1-of-6-videos {
    margin: 0.2rem auto;
    border: 0.2rem white solid;
  }
}
@media (max-width: 56.25em) {
  .row-home-products-videos-background-new-name__inside-background .col-1-of-6-videos {
    width: calc((100% - 5 * 2rem) / 2) !important;
    margin: 2rem 2rem !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: none;
  }
}
@media (max-width: 37.5em) {
  .row-home-products-videos-background-new-name__inside-background .col-1-of-6-videos {
    width: calc((100% - 5 * 0.5rem) / 2);
  }
}
@media (max-width: 31.25em) {
  .row-home-products-videos-background-new-name__inside-background .col-1-of-6-videos {
    width: fit-content !important;
    height: fit-content !important;
    margin: 1rem auto !important;
  }
}
.row-home-products-videos-background-new-name__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-products-videos-background-new-name__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-products-videos-background-new-name__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-products-videos-background-new-name__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-products-videos-background-new-name__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-home-podcasts-background {
  width: 100%;
  background-color: rgba(255, 119, 48, 0.1);
  margin: 1rem auto;
  padding: 5rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-home-podcasts-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-podcasts-background [class^=col-] {
  float: left;
}
.row-home-podcasts-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-podcasts-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-podcasts-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-podcasts-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-podcasts-background .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-home-podcasts-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-podcasts-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-podcasts-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-podcasts-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-podcasts-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-podcasts-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-podcasts-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-podcasts-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-podcasts-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-podcasts-background__inside-background {
  width: 100%;
  background-color: rgba(6, 57, 112, 0.65);
  margin: 1rem 2rem 1rem 0;
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.row-home-podcasts-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-podcasts-background__inside-background [class^=col-] {
  float: left;
}
.row-home-podcasts-background__inside-background [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-home-podcasts-background__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-podcasts-background__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-home-podcasts-background__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-podcasts-background__inside-background .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  margin: 0 auto 0 2.2rem;
}
.row-home-podcasts-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-podcasts-background__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-podcasts-background__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-home-podcasts-background__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-home-podcasts-background__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-home-podcasts-background__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-podcasts-background__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-podcasts-background__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-podcasts-background__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-home-other-sellers-podcasts-background {
  width: 95%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 119, 48, 0.1);
  margin: 3rem auto 15rem auto !important;
  padding: 5rem 2rem 2rem 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);
}
.row-seller-home-other-sellers-podcasts-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-other-sellers-podcasts-background [class^=col-] {
  float: left;
}
.row-seller-home-other-sellers-podcasts-background [class^=col-]:not(:last-child) {
  margin-right: 5rem !important;
}
.row-seller-home-other-sellers-podcasts-background__inside-background {
  width: 95%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  justify-items: center !important;
  background-color: rgba(6, 57, 112, 0.7);
  margin: 1rem auto;
  padding: 3rem 2rem 3rem 4rem;
  box-shadow: 0.3rem 0.5rem 0.1rem rgba(0, 0, 0, 0.3);
}
.row-seller-home-other-sellers-podcasts-background__inside-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-other-sellers-podcasts-background__inside-background [class^=col-] {
  float: left;
}
.row-seller-home-other-sellers-podcasts-background__inside-background [class^=col-]:not(:last-child) {
  margin-right: 2rem !important;
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-1-of-4 {
  width: calc((100% - 3 * 3rem) / 4) !important;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
  margin: 0 auto;
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-other-sellers-podcasts-background__inside-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-seller-home-podcasts-carousel {
  width: 100%;
  background-color: rgba(8, 94, 175, 0.01);
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: 5rem auto 3rem auto;
  padding: 5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-home-podcasts-carousel::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-home-podcasts-carousel [class^=col-] {
  float: left;
}
.row-background-page-seller-home-podcasts-carousel [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-home-podcasts-carousel .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-home-podcasts-carousel .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-background-page-seller-home-podcasts-carousel .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-home-podcasts-carousel .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-carousel .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-carousel .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-home-podcasts-carousel .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-seller-home-podcasts-carousel .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-seller-home-podcasts-carousel .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-seller-home-podcasts-carousel .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-home-podcasts-carousel .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-home-podcasts-carousel .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-home-podcasts-carousel .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-seller-home-podcasts-outside-frame {
  width: 100%;
  background-color: rgba(40, 180, 133, 0.15);
  margin: 5rem auto 3rem auto;
  padding: 5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-home-podcasts-outside-frame::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-home-podcasts-outside-frame [class^=col-] {
  float: left;
}
.row-background-page-seller-home-podcasts-outside-frame [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-home-podcasts-outside-frame .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-home-podcasts-outside-frame .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-background-page-seller-home-podcasts-outside-frame .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-outside-frame .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-outside-frame .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-seller-home-podcasts-outside-frame .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-seller-home-podcasts-outside-frame .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-seller-home-podcasts-outside-frame .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-home-podcasts-outside-frame .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-seller-home-podcasts-outside-frame-black-design {
  width: 100%;
  background-color: rgba(8, 94, 175, 0.19);
  margin: 5rem auto 3rem auto;
  padding: 5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-home-podcasts-outside-frame-black-design [class^=col-] {
  float: left;
}
.row-background-page-seller-home-podcasts-outside-frame-black-design [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-home-podcasts-outside-frame-black-design .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-seller-video-1 {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(165, 166, 247, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: -3rem auto 3rem auto;
  padding-bottom: 10rem;
  padding-top: 7rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-video-1::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-video-1 [class^=col-] {
  float: left;
}
.row-background-page-seller-video-1 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page-seller-video-1 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-video-1 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-seller-video-1 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-video-1 .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-seller-video-1 .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-video-1 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-video-1 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-seller-video-1 .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-seller-video-1 .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-seller-video-1 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-video-1 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-video-1 .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-video-1 .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-single-product-desc-write-reviews {
  width: 100%;
  background-color: rgba(88, 91, 245, 0.2);
  margin: 1rem auto 8rem auto;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 15rem;
  padding-top: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-single-product-desc-write-reviews::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-single-product-desc-write-reviews [class^=col-] {
  float: left;
}
.row-background-page-single-product-desc-write-reviews [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page-single-product-desc-write-reviews .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-single-product-desc-write-reviews .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-single-product-desc-write-reviews .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-single-product-desc-write-reviews .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-single-product-desc-write-reviews .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-single-product-desc-write-reviews .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-single-product-desc-write-reviews .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-single-product-desc-write-reviews .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-single-product-desc-write-reviews .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-single-product-desc-write-reviews .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-single-product-desc-write-reviews .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-single-product-desc-write-reviews .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-single-product-desc-write-reviews .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-single-product-reviews {
  width: 85vw;
  padding: 5rem !important;
  height: fit-content !important;
  border-radius: 0.5%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0.1rem auto -1rem auto;
  padding-bottom: 3rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-single-product-reviews::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-single-product-reviews [class^=col-] {
  float: left;
}
.row-background-page-single-product-reviews [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page-single-product-reviews .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-single-product-reviews .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-single-product-reviews .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-single-product-reviews .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-single-product-reviews .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-single-product-reviews .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-single-product-reviews .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-single-product-reviews .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-single-product-reviews .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-single-product-reviews .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-single-product-reviews .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-single-product-reviews .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-single-product-reviews .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-single-product-reviews-first-section {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: -4rem auto -10rem auto;
  padding-top: 8rem;
  padding-bottom: 35rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}

.row-background-page-single-product-related-products {
  width: 95%;
  border-radius: 0.5%;
  -webkit-border-radius: 0.5%;
  -moz-border-radius: 0.5%;
  -ms-border-radius: 0.5%;
  -o-border-radius: 0.5%;
  margin: 0.1rem auto 55rem auto;
  padding: 10rem !important;
  background-color: rgba(86, 67, 250, 0.04);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
@media (max-width: 37.5em) {
  .row-background-page-single-product-related-products {
    padding: 5rem !important;
  }
}
.row-background-page-single-product-related-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-single-product-related-products [class^=col-] {
  float: left;
}
.row-background-page-single-product-related-products [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page-single-product-related-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-single-product-related-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-single-product-related-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-single-product-related-products .col-1-of-4 {
  width: calc((100% - 9 * 0.5rem) / 4);
}
.row-background-page-single-product-related-products .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-single-product-related-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-single-product-related-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-single-product-related-products .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-single-product-related-products .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-single-product-related-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-single-product-related-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-single-product-related-products .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-single-product-related-products .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-related-products-above-title {
  width: 95%;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.row-background-page-single-video-related-videos {
  width: 95%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: 2rem auto !important;
  padding: 3rem 2rem 5rem 4rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-single-video-related-videos::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-single-video-related-videos [class^=col-] {
  float: left;
}
.row-background-page-single-video-related-videos [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-single-video-related-videos .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-single-video-related-videos .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-single-video-related-videos .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-single-video-related-videos .col-1-of-4 {
  width: calc((95% - 3 * 0.5rem) / 4);
  margin-top: 4rem;
}
.row-background-page-single-video-related-videos .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-single-video-related-videos .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-single-video-related-videos .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}
.row-background-page-single-video-related-videos .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6);
}
.row-background-page-single-video-related-videos .col-5-of-6 {
  width: calc(5 * (100% - 5 * 0.5rem) / 6 + 4 * 0.5rem);
}
.row-background-page-single-video-related-videos .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-single-video-related-videos .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-single-video-related-videos .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-single-video-related-videos .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-single-product--first-section-title {
  width: 65%;
  background-color: rgba(86, 67, 250, 0.15);
  margin: 5rem auto -3rem auto;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
  border-radius: 3%;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  -ms-border-radius: 3%;
  -o-border-radius: 3%;
}

.row-background-page-single-video {
  width: 100%;
  height: fit-content;
  position: relative;
  margin: 0.5rem auto 8rem auto;
  padding: 2rem auto;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
  border-radius: 3%;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  -ms-border-radius: 3%;
  -o-border-radius: 3%;
}

.row-background-page-single-video--first-section-title {
  width: fit-content !important;
  background-color: rgba(255, 255, 255, 0.5);
  color: #0e55b3;
  font-size: 1.8rem;
  font-weight: 700 !important;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding: 2.2rem 13rem;
  margin: -1rem auto 5rem auto;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
}
.row-background-page-single-video--first-section-title::after {
  background-color: #fff;
}

.row-background-page-seller-podcasts--first-section-title {
  width: fit-content !important;
  background-color: rgba(255, 255, 255, 0.5);
  color: #0e55b3;
  font-size: 1.8rem;
  font-weight: 700 !important;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding: 2.2rem 13rem;
  margin: -1rem auto 5rem auto;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
  border: 0.1rem rgba(247, 247, 247, 0.1) solid;
}
.row-background-page-seller-podcasts--first-section-title::after {
  background-color: #fff;
}

.row-background-page-single-product-video-first-section-title {
  width: 85%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: -2rem auto 7rem auto;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}

.row-background-page-single-product-write-review {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  border-radius: 0.5%;
  border: 0.1rem transparent solid;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 3rem auto 0 auto;
  padding-bottom: 2rem;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.03);
}

.row-background-page-seller-home {
  width: 98vw;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: 1.3rem auto;
  padding-bottom: 5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-home:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-page-seller-home::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-home [class^=col-] {
  float: left;
}
.row-background-page-seller-home [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-home .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-home .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-seller-home .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-home .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-page-seller-home .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-home .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-home .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-background-page-seller-home .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-background-page-seller-home .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-background-page-seller-home .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-home .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-home .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-home .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-page-seller-home-public {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin: 0 auto 1rem auto;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-home-public:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-page-seller-home-public::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-home-public [class^=col-] {
  float: left;
}
.row-background-page-seller-home-public [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-page-seller-home-public .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-home-public .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-seller-home-public .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-home-public .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-background-page-seller-home-public .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-home-public .col-shit {
  width: calc((100% - 6 * 0.5rem) / 7);
}
.row-background-page-seller-home-public .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-home-public .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-background-page-seller-home-public .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-background-page-seller-home-public .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-background-page-seller-home-public .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-home-public .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-home-public .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-home-public .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-background-home-page-public {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: 0.1rem auto 1.3rem auto;
  padding-top: 1rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-home-page-public:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-home-page-public::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-home-page-public [class^=col-] {
  float: left;
}
.row-background-home-page-public [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-home-page-public .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-home-page-public .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-home-page-public .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-home-page-public .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-background-home-page-public .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-home-page-public .col-shit {
  width: calc((100% - 6 * 0.5rem) / 7);
}
.row-background-home-page-public .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-home-page-public .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-background-home-page-public .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-background-home-page-public .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-background-home-page-public .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-home-page-public .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-home-page-public .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-home-page-public .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-home-public-category-products {
  width: 100% !important;
  height: 100%;
  border-radius: 0.5%;
}
.row-seller-home-public-category-products__height-scroll {
  width: 100%;
  height: 45vh;
  padding-bottom: 10rem;
  overflow: scroll;
  scroll-behavior: smooth;
}
.row-seller-home-public-category-products:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-home-public-category-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-public-category-products [class^=col-] {
  float: left;
}
.row-seller-home-public-category-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-public-category-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-public-category-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-public-category-products .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-home-public-category-products .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-home-public-category-products .col-shit {
  width: calc((100% - 6 * 0.5rem) / 7);
}
.row-seller-home-public-category-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-public-category-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-home-public-category-products .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-seller-home-public-category-products .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-seller-home-public-category-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-public-category-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-public-category-products .col-1-of-7 {
  width: calc((100% - 6 * 0.5rem) / 7) !important;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 3rem 2rem 3rem;
  height: 14vh;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
}
.row-seller-home-public-category-products .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-all-products-categories-products {
  width: 95rem !important;
  height: 40vh;
  border-radius: 0.5%;
}
.row-seller-all-products-categories-products__height-scroll {
  width: 100%;
  background-color: rgba(40, 180, 133, 0.05);
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.row-seller-all-products-categories-products:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-all-products-categories-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-all-products-categories-products [class^=col-] {
  float: left;
}
.row-seller-all-products-categories-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-all-products-categories-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-all-products-categories-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-all-products-categories-products .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-all-products-categories-products .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-all-products-categories-products .col-shit {
  width: calc((100% - 6 * 0.5rem) / 7);
}
.row-seller-all-products-categories-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-all-products-categories-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-all-products-categories-products .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-seller-all-products-categories-products .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-seller-all-products-categories-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-all-products-categories-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-all-products-categories-products .col-1-of-7 {
  width: calc((100% - 6 * 0.5rem) / 7) !important;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 3rem 2rem 3rem;
  height: 14vh;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
}
.row-seller-all-products-categories-products .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-home-public-sub-products {
  width: 100% !important;
  height: 68vh !important;
  border-radius: 0.5%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
}
@media (min-width: 112.51em) {
  .row-home-public-sub-products {
    width: 100%;
    margin-top: -1rem;
    padding: 0 2rem 0.5rem 2rem !important;
  }
}
@media (max-width: 75em) {
  .row-home-public-sub-products {
    width: 100%;
    margin-top: -1rem;
    padding: 0 0.1rem 0.5rem 0.1rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-home-public-sub-products {
    width: 100%;
    height: 50vh !important;
    margin-top: -1rem;
    padding: 0 2rem 0.5rem 0 !important;
  }
}
@media (max-width: 37.5em) {
  .row-home-public-sub-products {
    width: 100%;
    height: 60vh !important;
  }
}
@media (max-width: 23.4375em) {
  .row-home-public-sub-products {
    width: 100% !important;
    height: 60vh !important;
    margin-top: -2rem;
    padding: 0.1rem 0.1rem 0.1rem 0.1rem !important;
  }
}
.row-home-public-sub-products__height-scroll {
  width: 80vw !important;
  height: 65vh;
  z-index: 10000;
  padding: 3rem 5rem 3rem 10rem;
  overflow: scroll !important;
  scrollbar-width: thin;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.row-home-public-sub-products:not(:last-child) {
  margin-bottom: 5rem;
}
.row-home-public-sub-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-public-sub-products [class^=col-] {
  float: left;
}
.row-home-public-sub-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-public-sub-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-home-public-sub-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-public-sub-products .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-home-public-sub-products .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-home-public-sub-products .col-shit {
  width: calc((100% - 6 * 0.5rem) / 7);
}
.row-home-public-sub-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-public-sub-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-home-public-sub-products .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-home-public-sub-products .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-home-public-sub-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-public-sub-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-public-sub-products .col-1-of-7 {
  width: calc((100% - 6 * 0.5rem) / 7);
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 3rem 2rem 3rem;
  height: 14vh;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
}
.row-home-public-sub-products .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}
.row-home-public-sub-products .col-1-of-5 {
  width: calc((100% - 4 * 0.5rem) / 5);
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 3rem 2rem 3rem;
  font-size: 0.2rem !important;
  border-radius: 5%;
  height: 20rem;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
}
@media (min-width: 112.51em) {
  .row-home-public-sub-products .col-1-of-5 {
    width: 17% !important;
    margin: 1rem 1.5rem 2rem 1.5rem !important;
  }
}
@media (max-width: 75em) {
  .row-home-public-sub-products .col-1-of-5 {
    width: 22% !important;
    margin: 1rem 1rem 2rem 1rem !important;
  }
}
@media (max-width: 56.25em) {
  .row-home-public-sub-products .col-1-of-5 {
    width: 30% !important;
    margin: 1rem 1.5rem 2rem 1.5rem !important;
  }
}
@media (max-width: 37.5em) {
  .row-home-public-sub-products .col-1-of-5 {
    width: 45% !important;
    margin: 1rem 2rem 2rem 2rem;
  }
}
.row-home-public-sub-products .col-4-of-5 {
  width: calc(5 * (100% - 5 * 1rem) / 7 + 4 * 1rem);
}

.row-category-page-sub-products {
  width: 100%;
  height: 100%;
  border-radius: 0.5%;
  background-color: rgba(255, 255, 255, 0.35);
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
}
.row-category-page-sub-products__height-scroll {
  width: 95%;
  height: 55vh !important;
  padding: 3rem auto !important;
  margin: 10rem auto;
  overflow-y: scroll;
  scrollbar-width: thin;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.row-category-page-sub-products:not(:last-child) {
  margin-bottom: 5rem;
}
.row-category-page-sub-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-category-page-sub-products [class^=col-] {
  float: left;
}
.row-category-page-sub-products [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-category-page-sub-products .col-1-of-7 {
  width: calc((100% - 3 * 5rem) / 4);
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 2rem;
  height: 19.1vh;
  overflow: hidden;
  box-shadow: 0.9rem 0.9rem 0.9rem rgba(0, 0, 0, 0.5);
}

.row-wish-page-products {
  width: 100%;
  height: 100%;
  justify-content: center !important;
  justify-items: center !important;
  border-radius: 0.5%;
  background-color: rgba(86, 67, 250, 0.03);
  padding: 10rem 0 5rem 6rem;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
}
.row-wish-page-products__height-scroll {
  width: 100%;
  height: 100vh;
  margin: 10rem 2rem;
  overflow-y: scroll;
  scrollbar-width: thin;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
@media (max-width: 37.5em) {
  .row-wish-page-products {
    padding: 10rem 1rem 5rem 0.1rem !important;
    margin-left: 0 !important;
  }
}
.row-wish-page-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-wish-page-products [class^=col-] {
  float: left;
}
.row-wish-page-products [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-wish-page-products .col-1-of-7 {
  width: calc((100% - 3 * 0.5rem) / 4);
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem 4rem 3rem 4rem !important;
  padding: 2rem auto;
  height: 32vh;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
}
@media (max-width: 37.5em) {
  .row-wish-page-products .col-1-of-7 {
    width: calc((100% - 1 * 5rem) / 2);
    height: 20rem;
    margin: 1rem 0.5rem 3rem 0 !important;
  }
  .row-wish-page-products .col-1-of-7:not(:last-child) {
    margin-right: 2rem !important;
  }
}

.row-background-page-seller-public-products {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  margin: -0.3rem auto 3rem auto;
  padding-bottom: 2rem;
  padding-right: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-seller-public-products:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-page-seller-public-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-seller-public-products [class^=col-] {
  float: left;
}
.row-background-page-seller-public-products [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-background-page-seller-public-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-seller-public-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-background-page-seller-public-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-background-page-seller-public-products .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-page-seller-public-products .col-1-of-4-seller-home {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-background-page-seller-public-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-background-page-seller-public-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-background-page-seller-public-products .col-1-of-6 {
  width: calc((100% - 5 * 3rem) / 6);
}
.row-background-page-seller-public-products .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 4 * 3rem);
}
.row-background-page-seller-public-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-background-page-seller-public-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-background-page-seller-public-products .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-background-page-seller-public-products .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-all-products-categories-background {
  width: 90%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  background-color: rgba(126, 213, 111, 0.02);
  margin: 1rem auto 10rem auto !important;
}
.row-seller-all-products-categories-background:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-all-products-categories-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-all-products-categories-background [class^=col-] {
  float: left;
}
.row-seller-all-products-categories-background [class^=col-]:not(:last-child) {
  margin-right: 1rem;
}
.row-seller-all-products-categories-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-all-products-categories-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-all-products-categories-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-all-products-categories-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  background-color: white;
}
.row-seller-all-products-categories-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 5rem) / 4);
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding-left: 1rem;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: space-around;
}
.row-seller-all-products-categories-background .col-1-of-4-seller-home:last-child {
  margin-bottom: 0.5rem;
}
.row-seller-all-products-categories-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-all-products-categories-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-all-products-categories-background .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
  color: #0e55b3;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.5);
}
.row-seller-all-products-categories-background .col-1-of-6:last-child {
  margin-bottom: 0.5rem;
}
.row-seller-all-products-categories-background .col-1-of-6__height-scroll {
  height: 50%;
  overflow: scroll;
}
.row-seller-all-products-categories-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
  position: relative;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
}
.row-seller-all-products-categories-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-all-products-categories-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-all-products-categories-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-all-products-categories-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-home-categories-background {
  width: 95%;
  height: 45vh;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  margin: 2rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-home-categories-background:not(:last-child) {
  margin-bottom: 5rem;
}
.row-home-categories-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-categories-background [class^=col-] {
  float: left;
}
.row-home-categories-background [class^=col-]:not(:last-child) {
  margin-right: 1rem;
}
.row-home-categories-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-home-categories-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-home-categories-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-home-categories-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  background-color: white;
}
.row-home-categories-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 5rem) / 4);
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding-left: 1rem;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: space-around;
}
.row-home-categories-background .col-1-of-4-seller-home:last-child {
  margin-bottom: 0.5rem;
}
.row-home-categories-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-home-categories-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-home-categories-background .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.3rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: none !important;
}
.row-home-categories-background .col-1-of-6:last-child {
  margin-bottom: 0.5rem;
}
.row-home-categories-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
  position: relative;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
}
.row-home-categories-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-home-categories-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-home-categories-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-home-categories-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-seller-home-categories-background {
  width: 85%;
  height: 45vh;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
  margin: -5rem auto 5rem auto !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-home-categories-background:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-home-categories-background::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-categories-background [class^=col-] {
  float: left;
}
.row-seller-home-categories-background [class^=col-]:not(:last-child) {
  margin-right: 1rem;
}
.row-seller-home-categories-background .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-home-categories-background .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-home-categories-background .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-home-categories-background .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  background-color: white;
}
.row-seller-home-categories-background .col-1-of-4-seller-home {
  width: calc((100% - 3 * 5rem) / 4);
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding-left: 1rem;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: space-around;
}
.row-seller-home-categories-background .col-1-of-4-seller-home:last-child {
  margin-bottom: 0.5rem;
}
.row-seller-home-categories-background .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-home-categories-background .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-home-categories-background .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
  color: #0e55b3;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.5);
}
.row-seller-home-categories-background .col-1-of-6:last-child {
  margin-bottom: 0.5rem;
}
.row-seller-home-categories-background .col-1-of-6__height-scroll {
  height: 50%;
  overflow: scroll;
}
.row-seller-home-categories-background .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
  position: relative;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
}
.row-seller-home-categories-background .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-home-categories-background .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-home-categories-background .col-1-of-7 {
  width: calc((100% - 6 * 1rem) / 7);
}
.row-seller-home-categories-background .col-6-of-7 {
  width: calc(6 * (100% - 6 * 1rem) / 7 + 5 * 1rem);
}

.row-reviews-carousel {
  width: 95%;
  border-radius: 0.5%;
  background-color: rgba(86, 67, 250, 0.05);
  display: flex;
  justify-content: center;
  margin: -0.3rem auto 3rem auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-reviews-carousel:not(:last-child) {
  margin-bottom: 5rem;
}
.row-reviews-carousel::after {
  content: "";
  display: table;
  clear: both;
}
.row-reviews-carousel [class^=col-] {
  float: left;
}
.row-reviews-carousel [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-reviews-carousel .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}

.row-seller-public-videos-page {
  width: 100%;
  margin: 0 0 -10rem 0;
  padding-top: 0;
  padding-bottom: 12rem;
}
.row-seller-public-videos-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-public-videos-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-public-videos-page [class^=col-] {
  float: left;
}
.row-seller-public-videos-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-public-videos-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-public-videos-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 1 * 0.5rem);
}
.row-seller-public-videos-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-public-videos-page .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-seller-public-videos-page .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-seller-single-podcast-upper-section-col-5-of-6 {
  width: 100%;
  margin: 0 0 -10rem 0;
  padding-left: 1rem;
  padding-right: 0;
  padding-top: 5rem;
  padding-bottom: 12rem;
}
.row-seller-single-podcast-upper-section-col-5-of-6:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-single-podcast-upper-section-col-5-of-6::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-single-podcast-upper-section-col-5-of-6 [class^=col-] {
  float: left;
}
.row-seller-single-podcast-upper-section-col-5-of-6 [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-single-podcast-upper-section-col-5-of-6 .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-single-podcast-upper-section-col-5-of-6 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 1 * 0.5rem);
}
.row-seller-single-podcast-upper-section-col-5-of-6 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-seller-single-podcast-upper-section-col-5-of-6 .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-seller-single-podcast-upper-section-col-5-of-6 .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-single-product-first-section {
  width: 94% !important;
  height: fit-content !important;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem !important;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.01);
}
@media (max-width: 37.5em) {
  .row-single-product-first-section {
    width: 100% !important;
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 30rem !important;
  }
}
.row-single-product-first-section:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product-first-section::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product-first-section [class^=col-] {
  float: left;
}
.row-single-product-first-section [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-single-product-first-section .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4 + 1 * 5rem);
  margin-top: 5rem;
  padding-top: 2rem;
  margin-right: 2rem !important;
  margin-left: 0.1rem;
  height: 50vh !important;
  background-color: transparent !important;
}
@media (max-width: 37.5em) {
  .row-single-product-first-section .col-1-of-4 {
    width: 100%;
    margin-right: 2rem !important;
    margin-left: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.row-single-product-first-section .col-1-of-4-color-selected {
  width: calc((100% - 3 * 5rem) / 4 + 1 * 5rem);
  margin-top: 1rem;
  margin-bottom: 2rem !important;
  padding-top: 2rem;
  margin-right: 2rem !important;
  margin-left: 1rem;
  height: 50vh !important;
}
@media (max-width: 37.5em) {
  .row-single-product-first-section .col-1-of-4-color-selected {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.row-single-product-first-section .col-1-of-4-color-selected-no-size {
  width: calc((100% - 3 * 5rem) / 4 + 1 * 5rem);
  margin-top: 1rem;
  margin-bottom: 2rem !important;
  padding-top: 2rem;
  margin-right: 2rem !important;
  margin-left: 1rem;
  height: 50vh !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 37.5em) {
  .row-single-product-first-section .col-1-of-4-color-selected-no-size {
    width: 90%;
  }
}
.row-single-product-first-section .col-2-of-4 {
  width: calc(2 * (100% - 3 * 1.5rem) / 4 + 1 * 5rem);
}
.row-single-product-first-section .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 1 * 5rem) !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  justify-items: center !important;
  margin: 1rem 0 !important;
  padding: 0 !important;
  background-color: rgba(255, 255, 255, 0.05);
}
@media (max-width: 37.5em) {
  .row-single-product-first-section .col-3-of-4 {
    width: 100vw !important;
  }
}
.row-single-product-first-section .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-single-product-first-section .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-home-seller-page {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  padding-left: auto;
  padding-right: auto;
  padding-top: 8rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-home-seller-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-home-seller-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-seller-page [class^=col-] {
  float: left;
}
.row-home-seller-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-home-seller-page .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-home-seller-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 0.5rem) / 4 + 1 * 0.5rem);
}
.row-home-seller-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 5rem);
}
.row-home-seller-page .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-home-seller-page .col-3-of-5 {
  width: calc(3 * (100% - 4 * 2rem) / 5 + 2 * 2rem);
}

.row-seller-public-videos-header {
  width: 80%;
  height: 45vh;
  background-image: linear-gradient(99deg, rgba(88, 91, 245, 0.4) 0%, rgba(178, 179, 243, 0.3) 15%, rgba(67, 70, 241, 0.3) 50%, rgba(67, 70, 241, 0.3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%, rgba(67, 70, 241, 0.4) 100%);
  display: flex;
  justify-content: center;
  justify-items: center !important;
  margin: 2rem 5rem 3rem 16rem;
  padding-left: 1rem;
  padding-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
}
.row-seller-public-videos-header:not(:last-child) {
  margin-bottom: 5rem;
}
@media (max-width: 37.5em) {
  .row-seller-public-videos-header {
    width: 80vw !important;
    height: 17vh !important;
    margin: 12rem auto 2rem auto;
  }
}
.row-seller-public-videos-header::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-public-videos-header [class^=col-] {
  float: left;
}
.row-seller-public-videos-header [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-public-videos-header .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  text-decoration: none;
  padding: 1rem;
  margin: 2rem 1rem auto 1rem;
}
@media (max-width: 37.5em) {
  .row-seller-public-videos-header .col-1-of-4 {
    width: 10vw !important;
    height: 5vh !important;
    margin-bottom: 25rem !important;
    margin-top: -1rem !important;
  }
}
.row-seller-public-videos-header .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 0.5rem);
  text-align: justify;
}
@media (max-width: 37.5em) {
  .row-seller-public-videos-header .col-3-of-4 {
    width: 100%;
  }
}

.row-home-header-categories {
  width: 80%;
  height: 26vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center !important;
  margin: 5rem 0 5rem 18rem;
  padding: 2rem auto !important;
  padding-left: 10rem;
  padding-bottom: 5rem;
}
.row-home-header-categories:not(:last-child) {
  margin-bottom: 5rem;
}
.row-home-header-categories::after {
  content: "";
  display: table;
  clear: both;
}
.row-home-header-categories [class^=col-] {
  float: left;
}
.row-home-header-categories [class^=col-]:not(:last-child) {
  margin-right: 6rem;
}
@media (max-width: 75em) {
  .row-home-header-categories {
    width: 0 !important;
    height: 0 !important;
  }
}
.row-home-header-categories .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 7);
  height: 14rem;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(246, 242, 247, 0.15);
  text-align: center;
  color: #7ed56f !important;
  text-decoration: none;
  border-radius: 50%;
  margin-top: 4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
@media (max-width: 75em) {
  .row-home-header-categories .col-1-of-4 {
    width: 0 !important;
    height: 0 !important;
  }
}

.row-successful-payment {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin: -5.2rem auto 3rem auto;
  border-top: none;
  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 10rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0);
}
.row-successful-payment:not(:last-child) {
  margin-bottom: 5rem;
}
.row-successful-payment::after {
  content: "";
  display: table;
  clear: both;
}
.row-successful-payment [class^=col-] {
  float: left;
}
.row-successful-payment [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-successful-payment .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-successful-payment .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-successful-payment .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-successful-payment .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-successful-payment .col-2-of-4 {
  width: calc(2 * (100% - 3 * 3rem) / 4 + 1 * 5rem);
}
.row-successful-payment .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-successful-payment .col-3-of-4--password-update {
  max-width: 50%;
  margin: 25rem 35rem;
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-successful-payment .col-1-of-5 {
  width: calc((100% - 4 * 5rem) / 5);
}
.row-successful-payment .col-1-of-6 {
  width: calc((100% - 5 * 0.5rem) / 6 + 4 * 5rem);
}
.row-successful-payment .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 3 * 2rem);
}
.row-successful-payment .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-successful-payment .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-background-cart-page {
  width: 100%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-cart-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-cart-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-cart-page [class^=col-] {
  float: left;
}
.row-background-cart-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-cart-page .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
  padding-left: 3rem;
  padding-right: 2rem;
  margin: 10rem 0;
}
.row-background-cart-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
  padding-left: 0;
  padding-right: 0;
  margin: 10rem 0;
}
.row-background-cart-page .col-1-of-3 {
  width: calc((100% - 2 * 0.5rem) / 3);
  padding-left: 0.1rem;
  padding-right: 2rem;
  margin: 10rem 0;
}
@media (max-width: 37.5em) {
  .row-background-cart-page .col-1-of-3 {
    width: 100%;
    margin-top: 1rem;
  }
}
.row-background-cart-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  padding-left: 0;
  padding-right: 0;
  margin: 10rem 0;
}
@media (max-width: 37.5em) {
  .row-background-cart-page .col-2-of-3 {
    width: 100%;
  }
}
.row-background-cart-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  padding-left: 0.1rem;
  padding-right: 2rem;
  margin: 10rem 0;
}
@media (max-width: 37.5em) {
  .row-background-cart-page .col-1-of-2 {
    width: 100%;
    margin-top: 1rem;
  }
}

.row-background-page-profile {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(99deg, rgba(196, 198, 249, 0.4) 0%, rgba(193, 194, 242, 0.3) 15%, rgba(188, 189, 248, 0.3) 50%, rgba(192, 193, 250, 0.3) 51%, rgba(192, 193, 239, 0.3) 85%, rgba(193, 194, 243, 0.4) 100%, rgba(194, 195, 246, 0.4) 100%);
  margin-bottom: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-page-profile::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-page-profile [class^=col-] {
  float: left;
}
.row-background-page-profile [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-background-page-profile .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-page-profile .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-top: 5rem;
}
.row-background-page-profile .col-1-of-3:not(:last-child) {
  margin-right: 5rem;
}
.row-background-page-profile .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  display: flex;
  flex-direction: column;
}
.row-background-page-profile .col-2-of-3:not(:last-child) {
  margin-right: 5rem;
}
.row-background-page-profile .col-1-of-4 {
  width: calc((100% - 9 * 2rem) / 4);
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-1-of-4 {
    width: 95%;
    margin: 5rem auto 5rem 2.5%;
    padding: 2rem auto;
    height: 20rem;
  }
}
.row-background-page-profile .col-1-of-4-order-summary-column {
  width: calc((100% - 9 * 2rem) / 4);
  margin-top: 20rem !important;
  background-color: rgba(255, 255, 255, 0.8);
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  padding-bottom: 5rem !important;
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-1-of-4-order-summary-column {
    width: 85%;
    margin: 10rem auto 10rem 8rem !important;
    padding: 2rem 5rem !important;
  }
}
.row-background-page-profile .col-1-of-4-wishlist {
  width: calc((100% - 10 * 5rem) / 4);
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-1-of-4-wishlist {
    width: calc((100% - 1 * 5rem) / 3) !important;
  }
}
.row-background-page-profile .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
  display: block;
  margin-top: 15rem;
}
.row-background-page-profile .col-2-of-4:not(:last-child) {
  margin-right: 2rem;
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-2-of-4 {
    margin-top: 5rem !important;
    width: 85%;
    margin: 2rem auto 2rem 8rem !important;
  }
}
.row-background-page-profile .col-3-of-4 {
  width: calc(3 * (100% - 2 * 2rem) / 4 + 2 * 2rem);
  margin-top: 10rem;
  padding-left: 2rem;
}
.row-background-page-profile .col-3-of-4:not(:last-child) {
  margin-right: 5rem;
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-3-of-4 {
    width: 95%;
  }
}
.row-background-page-profile .col-3-of-4-wishlist {
  width: calc(3 * (100% - 2 * 2rem) / 4 + 2 * 2rem);
  margin-top: 10rem;
  padding-left: 2rem;
}
.row-background-page-profile .col-3-of-4-wishlist:not(:last-child) {
  margin-right: 5rem;
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-3-of-4-wishlist {
    width: calc(2 * (100% - 2 * 5rem) / 3 + 1 * 5rem);
  }
  .row-background-page-profile .col-3-of-4-wishlist:not(:last-child) {
    margin-right: 0;
    padding: 1rem !important;
  }
}
.row-background-page-profile .col-3-of-4-shipping-address {
  width: calc(3 * (90% - 2 * 2rem) / 4 + 2 * 2rem);
  margin: -5rem 10% 57rem 25%;
  padding-left: 2rem;
}
.row-background-page-profile .col-3-of-4-shipping-address:not(:last-child) {
  margin-right: 5rem;
}
@media (max-width: 37.5em) {
  .row-background-page-profile .col-3-of-4-shipping-address {
    width: 100%;
    margin: 5rem auto 5rem auto;
    padding-left: 0;
    padding-right: 0;
  }
}
.row-background-page-profile .col-1-of-6 {
  width: calc((100% - 5 * 3rem) / 6 + 1 * 3rem);
}
.row-background-page-profile .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 4 * 3rem);
  padding: 5rem 0rem;
}
.row-background-page-profile .col-3-of-7 {
  width: calc(3 * (100% - 2 * 0.5rem) / 7 + 2 * 0.5rem);
}
.row-background-page-profile .col-4-of-7 {
  width: calc(4 * (100% - 3 * 5rem) / 7 + 1 * 5rem);
}

.row-background-seller-profile {
  width: 100%;
  border-radius: 0.5%;
  background-color: rgba(86, 67, 250, 0.03);
  margin-top: 1.2rem;
  margin-bottom: 5rem !important;
  padding-left: 5rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 5rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-background-seller-profile:not(:last-child) {
  margin-bottom: 5rem;
}
@media (max-width: 37.5em) {
  .row-background-seller-profile {
    padding-left: 1rem;
  }
}
.row-background-seller-profile::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-seller-profile [class^=col-] {
  float: left;
}
.row-background-seller-profile [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-background-seller-profile .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-seller-profile .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-top: 5rem;
}
.row-background-seller-profile .col-1-of-3:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-profile .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  display: flex;
  flex-direction: column;
}
.row-background-seller-profile .col-2-of-3:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-profile .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-seller-profile .col-2-of-4 {
  width: calc(2 * (100% - 3 * 3rem) / 4 + 2rem);
  display: block;
}
.row-background-seller-profile .col-2-of-4:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-profile .col-3-of-4 {
  width: calc(3 * (100% - 2 * 3rem) / 4 + 3 * 3rem);
  padding-left: 5rem;
}
.row-background-seller-profile .col-3-of-4:not(:last-child) {
  margin-right: 3rem;
}
.row-background-seller-profile .col-1-of-6 {
  width: calc((100% - 5 * 3rem) / 6 + 1 * 3rem);
}
.row-background-seller-profile .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 4 * 3rem);
  padding: 5rem 0rem;
}
.row-background-seller-profile .col-3-of-7 {
  width: calc((100% - 6 * 2rem) / 7 + 4 * 2rem);
  margin: 3rem auto auto auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media (max-width: 37.5em) {
  .row-background-seller-profile .col-3-of-7 {
    width: calc((100% - 6 * 5rem) / 7 + 4 * 5rem);
    font-size: 3rem;
    margin: 3rem -1rem 3rem 0 !important;
  }
}
.row-background-seller-profile .col-4-of-7 {
  width: calc(6 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
  margin: auto 2rem 10rem 2rem;
}
@media (max-width: 37.5em) {
  .row-background-seller-profile .col-4-of-7 {
    width: calc(6 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
    margin-left: -2rem;
    margin-right: 1rem !important;
  }
}

.row-background-seller-view-profile {
  width: 95%;
  border-radius: 0.5%;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 5rem 2rem;
  padding: 2rem 5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.02);
}
.row-background-seller-view-profile:not(:last-child) {
  margin-bottom: 5rem;
}
.row-background-seller-view-profile::after {
  content: "";
  display: table;
  clear: both;
}
.row-background-seller-view-profile [class^=col-] {
  float: left;
}
.row-background-seller-view-profile [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-background-seller-view-profile .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-background-seller-view-profile .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-top: 5rem;
}
.row-background-seller-view-profile .col-1-of-3:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-view-profile .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
  display: flex;
  flex-direction: column;
}
.row-background-seller-view-profile .col-2-of-3:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-view-profile .col-1-of-4 {
  width: calc((100% - 9 * 5rem) / 4);
}
.row-background-seller-view-profile .col-2-of-4 {
  width: calc(2 * (100% - 3 * 3rem) / 4 + 2rem);
  display: block;
}
.row-background-seller-view-profile .col-2-of-4:not(:last-child) {
  margin-right: 5rem;
}
.row-background-seller-view-profile .col-3-of-4 {
  width: calc(3 * (100% - 2 * 3rem) / 4 + 3 * 3rem);
  padding-left: 5rem;
}
.row-background-seller-view-profile .col-3-of-4:not(:last-child) {
  margin-right: 3rem;
}
.row-background-seller-view-profile .col-1-of-6 {
  width: calc((100% - 5 * 3rem) / 6 + 1 * 3rem);
}
.row-background-seller-view-profile .col-5-of-6 {
  width: calc(5 * (100% - 5 * 3rem) / 6 + 4 * 3rem);
  padding: 5rem 0rem;
}
.row-background-seller-view-profile .col-3-of-7 {
  width: calc(3 * (100% - 6 * 2rem) / 7 + 2 * 2rem);
}
.row-background-seller-view-profile .col-4-of-7 {
  width: calc(4 * (100% - 6 * 2rem) / 7 + 1 * 5rem);
}

.row-admin-create-video {
  width: 95%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, rgb(69, 81, 248) 0%, rgba(69, 81, 248, 0.4) 8%, rgba(67, 70, 241, 0.01) 15%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(67, 70, 241, 0.4) 40%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 0 auto 3rem auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-admin-create-video:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-create-video::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-create-video [class^=col-] {
  float: left;
}
.row-admin-create-video [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-admin-create-video .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-admin-create-video .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-admin-create-video .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-admin-create-video .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-admin-create-video .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-admin-create-video .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-admin-create-video .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-admin-create-video .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-admin-create-video .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-admin-create-video .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-order {
  width: 100%;
  border-radius: 1%;
  margin: 2rem auto;
  padding-left: 4rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-seller-order:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-order::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-order [class^=col-] {
  float: left;
}
.row-seller-order [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-seller-order .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-order .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-order .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-order .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-order .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-order .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-order .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-order .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-order__info {
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-admin-table {
  width: 70vw !important;
  border-radius: 1%;
  margin: 2rem auto !important;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-admin-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-table [class^=col-] {
  float: left;
}
.row-admin-table__info {
  background-color: rgba(8, 94, 175, 0.05);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-admin-dashboard-table {
  width: 65vw !important;
  border-radius: 1%;
  margin: 2rem auto 3rem -1rem !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-admin-dashboard-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-dashboard-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-dashboard-table [class^=col-] {
  float: left;
}
.row-admin-dashboard-table__info {
  background-color: rgba(8, 94, 175, 0.05);
  font-size: 1.1rem;
  font-weight: 700;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-seller-general-coupon-table {
  width: 55vw !important;
  border-radius: 1%;
  margin: 2rem auto 3rem -1rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-seller-general-coupon-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-general-coupon-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-general-coupon-table [class^=col-] {
  float: left;
}
.row-seller-general-coupon-table__info {
  background-color: rgba(8, 94, 175, 0.05);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-admin-general-coupon-table {
  width: 55vw !important;
  border-radius: 1%;
  margin: 2rem auto 3rem -1rem !important;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-admin-general-coupon-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-general-coupon-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-general-coupon-table [class^=col-] {
  float: left;
}
.row-admin-general-coupon-table__info {
  background-color: rgba(8, 94, 175, 0.05);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-customer-history-table {
  width: 65vw !important;
  border-radius: 1%;
  margin: 2rem -2rem !important;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
@media (max-width: 37.5em) {
  .row-customer-history-table {
    width: 81vw !important;
    margin: 2rem -2rem !important;
  }
}
.row-customer-history-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-customer-history-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-customer-history-table [class^=col-] {
  float: left;
}
.row-customer-history-table__info {
  background-color: rgba(0, 0, 0, 0.01);
  font-size: 1.1rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-seller-detail-order-list-table {
  width: 70vw !important;
  border-radius: 1%;
  margin: 2rem auto !important;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 0.2rem !important;
  box-shadow: 1 1.5rem 3rem black !important;
}
.row-seller-detail-order-list-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-detail-order-list-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-detail-order-list-table [class^=col-] {
  float: left;
}
.row-seller-detail-order-list-table__info {
  background-color: rgba(8, 94, 175, 0.05);
  font-size: 1.1rem;
  font-weight: 700;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-seller-public-press-release-table {
  width: 100%;
  border-radius: 1%;
  margin: -2rem auto 2rem auto !important;
  padding: 3rem 6rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-seller-public-press-release-table:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-public-press-release-table::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-public-press-release-table [class^=col-] {
  float: left;
}
.row-seller-public-press-release-table__info {
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-seller-public-press-release-table__customer-pictures {
  width: 85%;
  height: fit-content;
  margin: 5rem 10rem 5rem 10rem;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: justify;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: rgba(14, 85, 179, 0.7);
  border-radius: 1%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-seller-public-press-release-table__customer-pictures--font-color {
  color: rgba(14, 85, 179, 0.7);
  margin-left: 0.5rem;
}

.row-admin-create-product {
  width: 70%;
  border-radius: 1%;
  margin: 8rem auto;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-admin-create-product:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-create-product::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-create-product [class^=col-] {
  float: left;
}
.row-admin-create-product [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-admin-create-product__info {
  padding: 3rem 1rem 1rem 1rem;
  background-color: rgba(86, 67, 250, 0.02);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  border: 0.1rem rgba(0, 0, 0, 0.1) solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-customer-write-review {
  width: 80%;
  border-radius: 1%;
  margin: 2rem auto;
  padding: 10rem 3rem 3rem 3rem;
  background-color: rgba(255, 119, 48, 0.02);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
@media (max-width: 37.5em) {
  .row-customer-write-review {
    width: 80vw;
  }
}
.row-customer-write-review:not(:last-child) {
  margin-bottom: 5rem;
}
.row-customer-write-review::after {
  content: "";
  display: table;
  clear: both;
}
.row-customer-write-review [class^=col-] {
  float: left;
}
.row-customer-write-review [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-customer-write-review__info {
  background-color: rgba(86, 67, 250, 0.04);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-all-products {
  width: 98%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, rgb(69, 81, 248) 0%, rgba(69, 81, 248, 0.4) 8%, rgba(67, 70, 241, 0.01) 15%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(67, 70, 241, 0.4) 40%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 0 auto 3rem auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-all-products:not(:last-child) {
  margin-bottom: 5rem;
}
.row-all-products::after {
  content: "";
  display: table;
  clear: both;
}
.row-all-products [class^=col-] {
  float: left;
}
.row-all-products [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-all-products .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-all-products .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-all-products .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-all-products .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-all-products .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-all-products .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-all-products .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-all-products .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-all-products .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-all-products .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-customer-images {
  width: 99%;
  border-radius: 0.5%;
  margin-left: 1.4rem;
  margin-right: 0.1rem;
  margin-bottom: 7rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-customer-images:not(:last-child) {
  margin-bottom: 5rem;
}
.row-customer-images::after {
  content: "";
  display: table;
  clear: both;
}
.row-customer-images [class^=col-] {
  float: left;
}
.row-customer-images [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-customer-images .col-1-of-3 {
  width: calc((99.5% - 2 * 5rem) / 3);
  position: relative;
  margin: 2rem;
}

.row-singele-product-inside {
  width: 80vw;
  border-radius: 1%;
  background-image: linear-gradient(125deg, rgb(128, 167, 247) 0%, rgb(250, 250, 250) 50%, rgb(253, 253, 253) 51%, rgba(247, 172, 128, 0.5) 90%);
  margin: 5rem auto;
  padding-left: 4rem;
  padding-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-singele-product-inside:not(:last-child) {
  margin-bottom: 5rem;
}
.row-singele-product-inside::after {
  content: "";
  display: table;
  clear: both;
}
.row-singele-product-inside [class^=col-] {
  float: left;
}
.row-singele-product-inside [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-singele-product-inside .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-singele-product-inside .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-singele-product-inside .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-singele-product-inside .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-singele-product-inside .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-singele-product-inside .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-singele-product-inside .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-singele-product-inside .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-user-history-orders {
  width: 100%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, #74aaf1 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.5) 40%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 0;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-user-history-orders:not(:last-child) {
  margin-bottom: 5rem;
}
.row-user-history-orders::after {
  content: "";
  display: table;
  clear: both;
}
.row-user-history-orders [class^=col-] {
  float: left;
}
.row-user-history-orders [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-user-history-orders .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-user-history-orders .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-user-history-orders .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-user-history-orders .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-user-history-orders .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-user-history-orders .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-user-history-orders .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-user-history-orders .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-list-products-page {
  width: 98vw;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, #74aaf1 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.5) 40%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-seller-list-products-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-list-products-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-list-products-page [class^=col-] {
  float: left;
}
.row-seller-list-products-page [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}
.row-seller-list-products-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-list-products-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-list-products-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-list-products-page .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-seller-list-products-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-list-products-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-list-products-page .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-list-products-page .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-seller-list-products-page .col-4-of-5 {
  width: calc(4 * (100% - 4 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-list-products-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-list-products-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-seller-list-products-page .col-1-of-6 {
  width: calc((100% - 5 * 2rem) / 6);
}
.row-seller-list-products-page .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-seller-list-products-page .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-seller-list-products-page .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-seller-product-list-outside {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  justify-items: center;
  background-image: linear-gradient(125deg, rgba(95, 148, 253, 0.1) 0%, rgba(250, 250, 250, 0.9) 50%, rgba(253, 253, 253, 0.1) 51%, rgba(101, 152, 253, 0.1) 90%);
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-seller-product-list-outside:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-product-list-outside::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-product-list-outside [class^=col-] {
  float: left;
}
.row-seller-product-list-outside [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-seller-product-list-outside .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-product-list-outside .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-product-list-outside .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-product-list-outside .col-1-of-4 {
  width: calc((100% - 3 * 3rem) / 4);
}
.row-seller-product-list-outside .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-product-list-outside .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-product-list-outside .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-product-list-outside .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-product-list-outside .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-product-list-inside {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-image: linear-gradient(125deg, rgba(95, 148, 253, 0.1) 0%, rgba(250, 250, 250, 0.9) 50%, rgba(253, 253, 253, 0.1) 51%, rgba(101, 152, 253, 0.1) 90%);
  margin: 5rem auto;
  margin-left: 0rem;
  padding-left: 4rem;
  padding-top: 6rem;
  padding-bottom: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-seller-product-list-inside:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-product-list-inside::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-product-list-inside [class^=col-] {
  float: left;
}
.row-seller-product-list-inside [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-seller-product-list-inside .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-product-list-inside .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-seller-product-list-inside .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-product-list-inside .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  margin-bottom: 5rem;
}
.row-seller-product-list-inside .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-product-list-inside .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-product-list-inside .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-product-list-inside .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-product-list-inside .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-admin-product-list-inside {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09);
  margin: 15rem auto 5rem auto;
  padding-left: 4rem;
  padding-top: 6rem;
  padding-bottom: 4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-admin-product-list-inside:not(:last-child) {
  margin-bottom: 5rem;
}
.row-admin-product-list-inside::after {
  content: "";
  display: table;
  clear: both;
}
.row-admin-product-list-inside [class^=col-] {
  float: left;
}
.row-admin-product-list-inside [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-admin-product-list-inside .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-admin-product-list-inside .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-admin-product-list-inside .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-admin-product-list-inside .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
  margin-bottom: 5rem;
}
.row-admin-product-list-inside .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-admin-product-list-inside .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-admin-product-list-inside .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-admin-product-list-inside .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-admin-product-list-inside .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-all-videos-editDelete-page {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 10rem 0 !important;
  padding: 10rem 1rem 10rem 0 !important;
}
.row-seller-all-videos-editDelete-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-all-videos-editDelete-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-all-videos-editDelete-page [class^=col-] {
  float: left;
}
.row-seller-all-videos-editDelete-page [class^=col-]:not(:last-child) {
  margin-right: 0.5rem !important;
}
.row-seller-all-videos-editDelete-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-all-videos-editDelete-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin: 1rem auto;
  background-color: transparent;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: fit-content !important;
}
.row-seller-all-videos-editDelete-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-all-videos-editDelete-page .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
  margin: 2rem 0.1rem !important;
  margin-bottom: 4rem;
  height: fit-content;
}
.row-seller-all-videos-editDelete-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-all-videos-editDelete-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-all-videos-editDelete-page .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}
.row-seller-all-videos-editDelete-page .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-seller-all-videos-editDelete-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-all-videos-editDelete-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-seller-all-podcasts-edit-delete-page {
  width: 100%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 20rem auto 5rem auto !important;
  padding: 10rem 3rem !important;
  box-shadow: 0 0.5rem 3rem rgba(86, 67, 250, 0.1);
}
.row-seller-all-podcasts-edit-delete-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-all-podcasts-edit-delete-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-all-podcasts-edit-delete-page [class^=col-] {
  float: left;
}
.row-seller-all-podcasts-edit-delete-page [class^=col-]:not(:last-child) {
  margin-right: 1.5rem !important;
}
.row-seller-all-podcasts-edit-delete-page .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-seller-all-podcasts-edit-delete-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  margin-bottom: 5rem;
  height: 25rem;
}
.row-seller-all-podcasts-edit-delete-page .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-seller-all-podcasts-edit-delete-page .col-1-of-4 {
  width: calc((100% - 3 * 1.5rem) / 4);
  margin-bottom: 2rem;
  height: fit-content;
}
.row-seller-all-podcasts-edit-delete-page .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-seller-all-podcasts-edit-delete-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-seller-all-podcasts-edit-delete-page .col-1-of-5 {
  width: calc((100% - 4 * 3rem) / 5);
}
.row-seller-all-podcasts-edit-delete-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-seller-all-podcasts-edit-delete-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-page-title {
  width: 65%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, #74aaf1 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.5) 40%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 5rem auto;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-page-title:not(:last-child) {
  margin-bottom: 5rem;
}
.row-page-title::after {
  content: "";
  display: table;
  clear: both;
}
.row-page-title [class^=col-] {
  float: left;
}

.row-page-title-2 {
  width: 45%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, white 51%, white 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 5rem auto 5rem 4rem;
  padding-left: 5rem;
  padding-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 1rem 1rem 0.5rem rgba(0, 0, 0, 0.05);
}
.row-page-title-2:not(:last-child) {
  margin-bottom: 5rem;
}
.row-page-title-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-page-title-2 [class^=col-] {
  float: left;
}

.row-product-card {
  width: 100%;
  border-radius: 0.5%;
  color: #74aaf1;
  margin: 1rem auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(14, 85, 179, 0.2);
}
.row-product-card:not(:last-child) {
  margin-bottom: 5rem;
}
.row-product-card::after {
  content: "";
  display: table;
  clear: both;
}
.row-product-card [class^=col-] {
  float: left;
}
.row-product-card [class^=col-]:not(:last-child) {
  margin-right: 1.5rem;
}

.row-profile {
  max-width: 95vw;
  background-color: rgba(247, 247, 247, 0.7882352941);
  margin: 0 auto;
  padding: 3rem 2rem;
}
.row-profile:not(:last-child) {
  margin-bottom: 5rem;
}
.row-profile::after {
  content: "";
  display: table;
  clear: both;
}
.row-profile [class^=col-] {
  float: left;
}
.row-profile [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-profile .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row-profile .col-1-of-3 {
  width: calc((100% - 2 * 2rem) / 3);
}
.row-profile .col-2-of-3 {
  width: calc(2 * (100% - 2 * 2rem) / 3 + 2rem);
}
.row-profile .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-profile .col-1-of-5 {
  width: calc((100% - 5 * 2rem) / 5);
}
.row-profile .col-2-of-5 {
  width: calc(2 * (100% - 5 * 2rem) / 5 + 1 * 2rem);
}
.row-profile .col-3-of-5 {
  width: calc(3 * (100% - 5 * 2rem) / 5 + 2 * 2rem);
}
.row-profile .col-4-of-5 {
  width: calc(4 * (100% - 5 * 2rem) / 5 + 3 * 2rem);
}
.row-profile .col-2-of-4 {
  width: calc(2 * (100% - 3 * 2rem) / 4 + 2rem);
}
.row-profile .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-profile .col-1-of-6 {
  width: calc((100% - 6 * 5rem) / 6);
}
.row-profile .col-2-of-6 {
  width: calc(2 * (100% - 6 * 5rem) / 6 + 1 * 2rem);
  border: 0.1rem solid rgb(216, 216, 243);
  padding: 3rem;
  margin-top: 4rem;
}
.row-profile .col-3-of-6 {
  width: calc(3 * (100% - 6 * 5rem) / 6 + 2 * 2rem);
}
.row-profile .col-5-of-6 {
  width: calc(5 * (100% - 5 * 2rem) / 6 + 4 * 2rem);
}
.row-profile .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-profile .col-6-of-7 {
  width: calc(6 * (100% - 6 * 2rem) / 7 + 6 * 2rem);
}

.row-profile-2 {
  width: 98%;
  border-radius: 0.5%;
  background-image: linear-gradient(110deg, #74aaf1 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.5) 40%, white 51%, white 53%, white 57%, rgba(116, 170, 241, 0.5) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 0 auto 3rem auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-profile-2:not(:last-child) {
  margin-bottom: 5rem;
}
.row-profile-2::after {
  content: "";
  display: table;
  clear: both;
}
.row-profile-2 [class^=col-] {
  float: left;
}
.row-profile-2 [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-profile-2 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-profile-2 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-profile-2 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-profile-2 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-profile-2 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-profile-2 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-profile-2 .col-1-of-5 {
  width: calc((100% - 4 * 5rem) / 5);
}
.row-profile-2 .col-4-of-5 {
  width: calc(4 * (100% - 4 * 5rem) / 5 + 1 * 5rem);
}
.row-profile-2 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-profile-2 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}

.row-profile-3 {
  border-radius: 0.5%;
  justify-content: center;
  justify-items: center;
  background-image: linear-gradient(110deg, #74aaf1 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.5) 40%, white 51%, white 53%, white 57%, rgba(116, 170, 241, 0.5) 65%, rgba(255, 255, 255, 0.1) 90%);
  margin: 5rem auto 3rem auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
.row-profile-3:not(:last-child) {
  margin-bottom: 5rem;
}
.row-profile-3::after {
  content: "";
  display: table;
  clear: both;
}
.row-profile-3 [class^=col-] {
  float: left;
}
.row-profile-3 [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-profile-3 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
  display: flex;
}
.row-profile-3 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  padding-left: 2rem;
  padding-top: 5rem;
  display: flex;
}
.row-profile-3 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 2 * 5rem);
  padding-left: 2rem;
  padding-top: 5rem;
}
.row-profile-3 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-profile-3 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 3rem) / 4 + 1 * 3rem);
  padding-left: 2rem;
  padding-top: 5rem;
  display: flex;
  justify-content: space-between;
  border: 1rem red solid;
}
.row-profile-3 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 3rem) / 4 + 3 * 5rem);
  padding-left: 2rem;
  padding-top: 5rem;
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}
.row-profile-3 .col-1-of-5 {
  width: calc((100% - 4 * 5rem) / 5);
  display: flex;
}
.row-profile-3 .col-4-of-5 {
  width: calc(4 * (100% - 4 * 5rem) / 5 + 3 * 5rem);
  display: flex;
  justify-content: flex-end;
}
.row-profile-3 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
  display: flex;
}
.row-profile-3 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
  display: flex;
}

.row-upload-file {
  width: 75%;
  border-radius: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  background-color: rgba(86, 67, 250, 0.2);
  margin: 0 auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.row-upload-file:not(:last-child) {
  margin-bottom: 5rem;
}
.row-upload-file::after {
  content: "";
  display: table;
  clear: both;
}
.row-upload-file [class^=col-] {
  float: left;
}
.row-upload-file [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-upload-file .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-upload-file .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-upload-file .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-upload-file .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-upload-file .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-upload-file .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-upload-file .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 3rem);
}
.row-upload-file .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 3rem);
}
.row-upload-file--2 {
  margin: 3rem 1rem 0 8rem;
  background-color: rgba(86, 67, 250, 0.2);
}

.row-create-category-1 {
  width: 65vw;
  height: fit-content;
  padding: 3rem;
  border-radius: 1%;
  border: 0.05rem rgba(0, 0, 0, 0.05) solid;
  box-shadow: 0.2rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}
.row-create-category-1:not(:last-child) {
  margin-bottom: 5rem;
}
.row-create-category-1::after {
  content: "";
  display: table;
  clear: both;
}
.row-create-category-1 [class^=col-] {
  float: left;
}
.row-create-category-1 [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-create-category-1 .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-create-category-1 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-create-category-1 .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-create-category-1 .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-create-category-1 .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-create-category-1 .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-create-category-1 .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-create-category-1 .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 3 * 5rem);
}
.row-create-category-1 .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 2rem);
}

.row-create-category {
  width: 80%;
  display: block;
  justify-content: center;
  justify-items: flex-start;
  background-color: rgba(86, 67, 250, 0.04);
  padding-left: 2rem;
  padding-bottom: 3rem;
  padding-top: 2rem;
  border-radius: 1%;
  border: 0.3rem rgba(255, 255, 255, 0.3) solid;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  margin: 0 auto 5rem auto;
}
.row-create-category:not(:last-child) {
  margin-bottom: 5rem;
}
.row-create-category::after {
  content: "";
  display: table;
  clear: both;
}
.row-create-category [class^=col-] {
  float: left;
}
.row-create-category [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-create-category .col-1-of-2 {
  width: calc((100% - 1 * 5rem) / 2);
}
.row-create-category .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-create-category .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-create-category .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-create-category .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-create-category .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-create-category .col-1-of-7 {
  width: calc((100% - 6 * 2rem) / 7);
}
.row-create-category .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 3 * 5rem);
}
.row-create-category .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 2rem);
}
.row-create-category__categories {
  width: 100%;
  border-radius: 1%;
  padding-left: 4rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.08);
}
.row-create-category__categories::after {
  content: "";
  display: table;
  clear: both;
}
.row-create-category__categories [class^=col-] {
  float: left;
}
.row-create-category__categories [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-create-category__categories .col-1-of-2 {
  width: calc((100% - 5rem) / 2);
}
.row-create-category__categories .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-create-category__categories .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}
.row-create-category__categories .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-create-category__categories .col-2-of-4 {
  width: calc(2 * (100% - 3 * 5rem) / 4 + 5rem);
}
.row-create-category__categories .col-3-of-4 {
  width: calc(3 * (100% - 3 * 5rem) / 4 + 2 * 5rem);
}
.row-create-category__categories .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-create-category__categories .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 1 * 5rem);
}
.row-create-category__categories__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: flex-start;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-create-category__categories--outlined {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-wish-list {
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  padding-left: 3rem;
  padding-right: -5rem !important;
  padding-top: 8rem;
  padding-bottom: 8rem;
  border: 0.3rem rgba(255, 255, 255, 0.3) solid;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0);
  margin: 22rem auto 0 auto;
}
.row-wish-list:not(:last-child) {
  margin-bottom: 5rem;
}
.row-wish-list::after {
  content: "";
  display: table;
  clear: both;
}
.row-wish-list [class^=col-] {
  float: left;
}
.row-wish-list [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-wish-list .col-1-of-2 {
  width: calc((100% - 1 * 5rem) / 2);
}
.row-wish-list .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-wish-list .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-wish-list .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
  min-height: 23rem;
  max-height: 23rem;
  object-fit: cover;
  overflow: hidden;
}
.row-wish-list .col-1-of-3__info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-color: rgba(255, 119, 48, 0.09);
  font-size: 1.6rem;
  font-weight: 300;
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.01);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-wish-list .col-1-of-3__info:not(:last-child) {
  margin-bottom: 5rem;
}
.row-wish-list .col-1-of-3__info::after {
  content: "";
  display: table;
  clear: both;
}
.row-wish-list .col-1-of-3__info [class^=col-] {
  float: left;
}
.row-wish-list .col-1-of-3__info [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-wish-list .col-1-of-3__info .col-1-of-2 {
  width: calc((100% - 1 * 0.5rem) / 2);
}
.row-wish-list .col-1-of-3__info .col-1-of-2-title-delete {
  width: calc((100% - 1 * 0.5rem) / 2);
  margin-left: 1rem;
  margin-right: -2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  text-align: center;
  font-size: 1.3rem;
  padding-top: 0.5rem;
  background-color: rgba(255, 255, 255, 0.19);
}
.row-wish-list .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-wish-list .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 0.5rem);
}
.row-wish-list .col-5-of-7 {
  width: calc(5 * (100% - 6 * 5rem) / 7 + 4 * 0.5rem);
}
.row-wish-list__info-add {
  width: 100%;
  min-height: 100rem;
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items: center;
  padding: 5rem 1rem;
  background-color: rgba(86, 67, 250, 0.09);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.01);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-wish-list__info-add:not(:last-child) {
  margin-bottom: 5rem;
}
.row-wish-list__info-add::after {
  content: "";
  display: table;
  clear: both;
}
.row-wish-list__info-add [class^=col-] {
  float: left;
}
.row-wish-list__info-add [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-wish-list__info-add .col-1-of-2 {
  width: calc((100% - 1 * 6rem) / 2);
}
.row-wish-list__info-add .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
}

.row-single-product-page {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 10rem;
  padding-bottom: 1rem;
  border-radius: 0.5%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0);
  margin: -1rem auto 0 auto;
}
.row-single-product-page:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product-page::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product-page [class^=col-] {
  float: left;
}
.row-single-product-page [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}
.row-single-product-page .col-1-of-2 {
  width: calc((100% - 1 * 5rem) / 2);
}
.row-single-product-page .col-1-of-4 {
  width: calc((100% - 3 * 2rem) / 4);
}
.row-single-product-page .col-3-of-4 {
  width: calc(3 * (100% - 3 * 2rem) / 4 + 2 * 2rem);
}
.row-single-product-page .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-single-product-page .col-3-of-7 {
  width: calc(3 * (100% - 6 * 5rem) / 7 + 2 * 5rem);
}
.row-single-product-page .col-4-of-7 {
  width: calc(4 * (100% - 6 * 5rem) / 7 + 3 * 0.5rem);
}
.row-single-product-page .col-5-of-7 {
  width: calc(5 * (100% - 6 * 5rem) / 7 + 4 * 0.5rem);
}
.row-single-product-page__info {
  height: fit-content;
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items: center;
  padding-left: 3rem;
  padding-right: 0;
  padding-top: 5rem;
  padding-bottom: 1rem;
  background-color: rgba(86, 67, 250, 0.09);
  font-size: 1.6rem;
  font-weight: 500;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.01);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-single-product-page__info:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product-page__info::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product-page__info [class^=col-] {
  float: left;
}
.row-single-product-page__info [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row-single-product-page__info .col-1-of-2 {
  width: calc((100% - 1 * 6rem) / 2);
}
.row-single-product-page__info-add {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items: center;
  padding: 5rem 1rem 0 1rem;
  background-color: rgba(86, 67, 250, 0.03);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.01);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.row-single-product-page__info-add:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product-page__info-add::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product-page__info-add [class^=col-] {
  float: left;
}
.row-single-product-page__info-add [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-single-product-page__info-add .col-1-of-2 {
  width: calc((100% - 1 * 6rem) / 2);
}
.row-single-product-page__info-add .col-1-of-5 {
  width: calc((100% - 4 * 2rem) / 5);
  height: 35rem;
}

.row-seller-videos-page-header {
  width: 100%;
  height: 50vh;
  background-color: white;
  border-radius: 0.5%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0);
  margin: 0 0 5.4rem 0 !important;
}
.row-seller-videos-page-header:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-videos-page-header::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-videos-page-header [class^=col-] {
  float: left;
}
.row-seller-videos-page-header [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}

.row-seller-home-page-header {
  width: 100%;
  background-color: rgba(8, 94, 175, 0.15);
  border-radius: 0.5%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.09);
  margin: 2rem auto 2rem auto;
}
.row-seller-home-page-header:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-home-page-header::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-page-header [class^=col-] {
  float: left;
}
.row-seller-home-page-header [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}

.row-seller-home-create-forms-page-header {
  width: 100%;
  background-color: rgba(8, 94, 175, 0.15);
  border-radius: 0.5%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.09);
  margin: -2rem auto 2rem auto;
}
.row-seller-home-create-forms-page-header:not(:last-child) {
  margin-bottom: 5rem;
}
.row-seller-home-create-forms-page-header::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-home-create-forms-page-header [class^=col-] {
  float: left;
}
.row-seller-home-create-forms-page-header [class^=col-]:not(:last-child) {
  margin-right: 2rem;
}

.row-single-product-02 {
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-items: center;
  background-color: rgba(86, 67, 250, 0.1);
  padding: 3rem;
  border-radius: 1%;
  border: 0.3rem rgba(255, 255, 255, 0.3) solid;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  margin: -1rem auto 5rem auto;
}
.row-single-product-02:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-product-02::after {
  content: "";
  display: table;
  clear: both;
}
.row-single-product-02 [class^=col-] {
  float: left;
}
.row-single-product-02 [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-single-product-02 .col-1-of-2 {
  width: calc((100% - 1 * 5rem) / 2);
}
.row-single-product-02 .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-single-product-02__info {
  width: 100%;
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items: center;
  margin-top: 2rem;
  padding: 5rem;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 1.6rem;
  font-weight: 300;
  color: rgb(7, 5, 139);
  border-radius: 1%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}

.row-single-product-reviews {
  width: 95%;
  border: 0.2rem rgb(30, 255, 0) solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  background-color: rgba(86, 67, 250, 0.1);
  padding-bottom: 5rem;
  padding-top: 3rem;
  padding-right: -5rem;
  padding-left: 5rem;
  border-radius: 1%;
  border: 0.3rem rgba(255, 255, 255, 0.3) solid;
  box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.3);
  margin: -1rem auto 5rem auto;
}

.row-review-card {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 2rem;
  border-radius: 1%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.row-review-card__name {
  width: fit-content;
}

.seller-public-vid-post-comment {
  width: fit-content;
  height: fit-content;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
  border-radius: 1%;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
.seller-public-vid-post-comment__name {
  width: fit-content;
}

.row-review-card-remove {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  border-radius: 1%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.05);
}
.row-review-card-remove__name {
  width: fit-content;
}

.row-reviews-main-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 2rem;
  border-radius: 1%;
  padding: 2rem 1rem;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.05);
}
.row-reviews-main-container::after {
  content: "";
  display: table;
  clear: both;
}
.row-reviews-main-container [class^=col-] {
  float: left;
}
.row-reviews-main-container [class^=col-] .col-1-of-2 {
  width: calc((100% - 1 * 6rem) / 2);
}
.row-reviews-main-container [class^=col-] .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-reviews-main-container [class^=col-] .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 3);
}
.row-reviews-main-container [class^=col-] .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}

.row-reviews-video-container {
  width: 89%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 2rem;
  margin-left: -0.5rem;
  margin-right: 1rem;
  border-radius: 1%;
  padding: 1rem 1rem 3rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 1.5rem 3rem rgba(8, 94, 175, 0.2);
}
.row-reviews-video-container::after {
  content: "";
  display: table;
  clear: both;
}
.row-reviews-video-container [class^=col-] {
  float: left;
}
.row-reviews-video-container [class^=col-] .col-1-of-2 {
  width: calc((100% - 1 * 0.5rem) / 2);
}
.row-reviews-video-container [class^=col-] .col-1-of-3 {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-reviews-video-container [class^=col-] .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 3);
}
.row-reviews-video-container [class^=col-] .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}

.row-single-product-reviews-container {
  width: 100% !important;
  height: 72%;
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center !important;
  justify-items: center;
  overflow: scroll;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  scrollbar-width: thin !important;
  border-radius: 1%;
  padding: 0.1rem 1.5rem !important;
  background-color: rgba(86, 67, 250, 0.01);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 37.5em) {
  .row-single-product-reviews-container {
    height: 58vh;
    overflow: scroll;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    padding: 2rem 0.5rem !important;
  }
}
.row-single-product-reviews-container:not(:last-child) {
  margin-right: 5rem;
}
.row-single-product-reviews-container .col-1-of-2 {
  width: calc((100% - 1 * 0.5rem) / 2);
}
@media (max-width: 37.5em) {
  .row-single-product-reviews-container .col-1-of-2 {
    width: 95%;
    height: fit-content !important;
  }
}
.row-single-product-reviews-container .col-1-of-3 {
  width: calc((100% - 2 * 0.5rem) / 3);
}
@media (max-width: 37.5em) {
  .row-single-product-reviews-container .col-1-of-3 {
    width: 95%;
    height: fit-content !important;
  }
}
.row-single-product-reviews-container .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 3);
}
.row-single-product-reviews-container .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}

.row-review-card-name-star {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 1%;
  padding: 0 1rem;
  padding-top: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
}

.row-review-card-name {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  font-size: 1.4rem;
  font-weight: 400;
}
@media (max-width: 37.5em) {
  .row-review-card-name {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.row-review-card-name-2 {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  justify-items: center;
  text-align: justify;
  margin-bottom: 2rem;
  border-radius: 1%;
  padding: 4rem 5rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.row-single-product-descriptions {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  justify-items: center;
  text-align: justify;
  margin-bottom: 2rem;
  border-radius: 1%;
  padding: 4rem 5rem;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.row-review-card-name-3 {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  justify-items: center;
  text-align: justify;
  margin-bottom: 2rem;
  border-radius: 1%;
  padding: 0 2rem;
  background-color: rgba(0, 0, 0, 0.05);
}

.row-review-card-name-date-star {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 1%;
  padding: -11rem 0.5rem 0.2rem;
}

.row-review-card-name-modal {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 5rem;
  border-radius: 1%;
  padding: 2rem;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.05);
}

.star-ratings {
  margin-left: -5rem;
  display: flex;
  justify-content: flex-start;
}

.row-review-card-content {
  width: 45rem;
  display: flex;
  height: 35rem;
  flex-direction: row;
  flex-wrap: wrap;
  color: #085eaf;
  background-color: rgba(247, 247, 247, 0.6);
  justify-content: center;
  justify-items: center;
  overflow: hidden;
  overflow-wrap: break-word;
  border-radius: 1%;
  padding: 1rem 1rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.05);
}
.row-review-card-content:not(:last-child) {
  margin-bottom: 5rem;
}
.row-review-card-content::after {
  content: "";
  display: table;
  clear: both;
}
.row-review-card-content [class^=col-] {
  float: left;
}
.row-review-card-content [class^=col-]:not(:last-child) {
  margin-right: 5rem;
}
.row-review-card-content [class^=col-] .col-1-of-2 {
  width: calc((100% - 1 * 6rem) / 2);
}
.row-review-card-content [class^=col-] .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-review-card-content [class^=col-] .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}

.row-single-video-review-card-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #085eaf;
  background-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  justify-items: center;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-right: 5rem !important;
  border-radius: 1%;
  padding: 2rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.12);
}
@media (max-width: 37.5em) {
  .row-single-video-review-card-content {
    display: flex;
    flex-direction: column !important;
    overflow: scroll;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    height: 65vh;
  }
}
.row-single-video-review-card-content:not(:last-child) {
  margin-bottom: 5rem;
}
.row-single-video-review-card-content:not(:first-child) {
  margin-right: 5rem !important;
}
.row-single-video-review-card-content:not(:last-child) {
  margin-right: 2rem;
}
.row-single-video-review-card-content .col-1-of-2 {
  width: 48% !important;
}
@media (max-width: 37.5em) {
  .row-single-video-review-card-content .col-1-of-2 {
    width: 95% !important;
    height: 29vh !important;
    margin: 1rem auto;
  }
}
.row-single-video-review-card-content .col-1-of-2-product-review {
  width: calc((100% - 1 * 2rem) / 2);
}
@media (max-width: 37.5em) {
  .row-single-video-review-card-content .col-1-of-2-product-review {
    width: 100% !important;
    border: 0.2rem blue solid;
  }
}
.row-single-video-review-card-content .col-1-of-4 {
  width: calc((100% - 3 * 5rem) / 4);
}
.row-single-video-review-card-content .col-1-of-3 {
  width: calc((100% - 2 * 5rem) / 3);
}
.row-single-video-review-card-content .col-2-of-3 {
  width: calc(2 * (100% - 2 * 5rem) / 3 + 5rem);
}

.row-single-video-review-card-text {
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column !important;
  color: #333333;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  justify-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  overflow: hidden;
  overflow-wrap: break-word;
  overflow: scroll;
  scrollbar-width: thin;
  margin-top: 1rem;
  border-radius: 1%;
  padding: 2rem 1rem 1rem 1rem !important;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.03);
}
@media (max-width: 37.5em) {
  .row-single-video-review-card-text {
    font-size: 3.5rem;
    -webkit-backface-visibility: visible;
  }
}

.row-review-card-content-modal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 3rem 0;
  margin-right: -2rem;
  border-radius: 1%;
  padding: 0;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.05);
}
.row-review-card-content-modal:not(:last-child) {
  margin-bottom: 5rem;
}
.row-review-card-content-modal::after {
  content: "";
  display: table;
  clear: both;
}
.row-review-card-content-modal [class^=col-] {
  float: left;
}
.row-review-card-content-modal [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-review-card-content-modal .col-1-of-3 {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-review-card-content-modal .col-2-of-3 {
  width: calc(2 * (100% - 2 * 0.5rem) / 3 + 0.5rem);
}
.row-review-card-content-modal .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-review-card-content-modal .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}

.row-review-img-slider {
  width: 100%;
  display: flex;
  height: 30rem;
  justify-content: center;
  justify-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1%;
  padding: 0.5rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.05);
}

.row-seller-profile-picture-and-bio {
  width: 100%;
  display: flex;
  justify-items: center;
  margin: 2rem auto;
  padding: 2rem;
}
.row-seller-profile-picture-and-bio::after {
  content: "";
  display: table;
  clear: both;
}
.row-seller-profile-picture-and-bio [class^=col-] {
  float: left;
}
.row-seller-profile-picture-and-bio [class^=col-]:not(:last-child) {
  margin-right: 0.5rem;
}
.row-seller-profile-picture-and-bio .col-1-of-3 {
  width: calc((100% - 2 * 0.5rem) / 3);
}
.row-seller-profile-picture-and-bio .col-2-of-3 {
  width: calc(2 * (100% - 2 * 0.5rem) / 3 + 0.5rem);
}
.row-seller-profile-picture-and-bio .col-1-of-4 {
  width: calc((100% - 3 * 0.5rem) / 4);
}
.row-seller-profile-picture-and-bio .col-3-of-4 {
  width: calc(3 * (100% - 3 * 0.5rem) / 4 + 2 * 0.5rem);
}

.row-register {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  top: 0;
  left: 0;
}

.ant-pagination-item {
  color: #0e55b3;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.ant-pagination-item-active {
  background-color: white !important;
  border: none !important;
}

.anticon-right, .anticon-left {
  display: flex;
  justify-content: center;
  justify-items: center;
  color: white;
  font-size: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;
}
@media (max-width: 75em) {
  .anticon-right, .anticon-left {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: 37.5em) {
  .row-page-fit {
    height: fit-content !important;
    padding-bottom: 5rem !important;
    margin-bottom: 50rem;
  }
}

.section-about {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 7rem 5rem 10rem 5rem;
  margin: 10rem 10rem 10rem 10rem;
}

.section-profile {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 7rem 5rem 10rem 5rem;
  margin: 2rem;
}

.section-homeForm {
  width: 85%;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(86, 67, 250, 0.08);
  padding: 5rem 5rem 10rem 5rem;
  margin: 10rem auto 5rem auto;
  background-size: cover;
}

.section-home-seller-form2-public--orange-background {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, rgba(255, 119, 48, 0.1), rgba(255, 119, 48, 0.15), rgba(255, 119, 48, 0.15));
  clip-path: polygon(12% 0, 88% 0, 100% 100%, 0 100%);
  z-index: 0;
  box-shadow: 0.2rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 1rem 5rem 1rem 5rem;
  margin: 5rem auto 5rem auto;
}

.section-home-seller-form2-public--green-background {
  width: 100%;
  height: fit-content;
  clip-path: polygon(12% 0, 88% 0, 100% 100%, 0 100%);
  z-index: 0;
  box-shadow: 0.2rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 1rem 5rem 1rem 5rem;
  margin: 5rem auto 5rem auto;
}

.section-home-seller-form2-public--blue-background {
  width: 85%;
  height: fit-content;
  background-image: linear-gradient(to bottom, rgba(86, 67, 250, 0.03), rgba(86, 67, 250, 0.03), rgba(86, 67, 250, 0.03));
  z-index: 0;
  box-shadow: 0.2rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 1rem 5rem 1rem 5rem;
  margin: 5rem auto !important;
}

.section-home-seller-form-public {
  width: 85%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 1rem 10rem;
  margin: 7rem auto 5rem auto;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.section-home-seller-form-public__background {
  background-color: rgba(245, 222, 179, 0.1);
}

.section-seller-create-home-form1 {
  width: 85%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 1rem 10rem 5rem 10rem;
  margin: 1rem auto 5rem auto;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.section-seller-create-home-form1__background {
  background-color: rgba(245, 222, 179, 0.3);
}

.section-home-seller-form1-public-new-design {
  width: 85%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 3rem 3rem 5rem 3rem;
  margin: 1rem auto;
}

.section-seller-create-homeForm {
  width: 95%;
  background-color: rgba(86, 67, 250, 0.08);
  padding: 5rem 5rem 10rem 5rem;
  margin: -1rem 0 5rem 0;
  background-size: cover;
}

.section-seller-home-page-form4-and-5-users {
  width: 100%;
  background-color: rgba(86, 67, 250, 0.1);
  padding: 5rem 10rem;
  margin: 10rem 0 5rem 0;
  background-size: cover;
}

.section-seller-home-Form3 {
  width: 85%;
  height: fit-content;
  background-color: rgba(86, 67, 250, 0.02);
  padding: 1rem 10rem 1rem 10rem;
  margin: 3rem auto 1rem auto;
  border-radius: 1%;
  -webkit-border-radius: 1%;
  -moz-border-radius: 1%;
  -ms-border-radius: 1%;
  -o-border-radius: 1%;
}
.section-seller-home-Form3__background {
  background-color: rgba(245, 222, 179, 0.3);
}

.section-seller-home-form-6 {
  width: 95%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.03);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
  padding: 5rem 10rem 5rem 10rem;
  margin: 1rem auto 1rem auto;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.section-seller-home-form-6-green-background {
  width: 95%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.03);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
  padding: 5rem 10rem 5rem 10rem;
  margin: 1rem auto 1rem auto;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.section-seller-home-Form-width-100 {
  width: 95%;
  background-color: rgba(255, 119, 48, 0.2);
  clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 100%);
  padding: 0 auto;
  margin: 0 auto;
}

.section-seller-home-public-pink-green-square {
  width: 90%;
  background-color: rgba(255, 119, 48, 0.2);
  clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 100%);
  padding: 0 auto;
  margin: 0 auto;
}

.section-seller-home-create-pink-green-square {
  width: 95%;
  background-color: rgba(86, 67, 250, 0.1);
  padding: 0 auto;
  margin: 0 auto;
}

.section-seller-home-Form-width-100-green-background {
  width: 85%;
  background-color: rgba(40, 180, 133, 0.07);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
  border: 0.5rem rgba(255, 255, 255, 0.5) solid;
  border-radius: 50%;
  padding: 0 auto;
  margin: 0 auto;
  -webkit-border-radius: 1%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.section-seller-home-Form-width-100-green-blue-background {
  width: 100%;
  height: fit-content;
  border-radius: 50%;
  padding: 0 auto;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.section-seller-product-page-form1-green-blue-squarish-circle {
  width: 100%;
  height: fit-content;
  background-color: rgba(40, 180, 133, 0.05);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.section-seller-home-Form-podcasts-width-100-new-design {
  width: 100%;
  height: 50vh !important;
  color: white;
  background-color: rgba(8, 94, 175, 0.08);
  box-shadow: 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.3);
  outline: 0.7rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 1rem;
  padding: 1rem 5rem;
  margin: 0 auto;
}

.section-seller-home-Form-podcasts-width-100-black-design {
  width: 100%;
  color: white;
  background-color: black;
  box-shadow: 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.3);
  outline: 0.7rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 1rem;
  padding: 1rem 5rem;
  margin: 0 auto;
}

.section-seller-home-Form-podcasts-width-100 {
  width: 100%;
  color: white;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3rem 5rem;
  margin: 0 auto;
}

.section-seller-home-Form7-width-100 {
  width: 85%;
  height: 75vh;
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  outline: 0.7rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 10rem auto;
}
@media (max-width: 37.5em) {
  .section-seller-home-Form7-width-100 {
    width: 100%;
    height: 75%;
  }
}
.section-seller-home-Form7-width-100__pict {
  width: 100%;
  height: 100%;
}
.section-seller-home-Form7-width-100__message {
  width: 50%;
  height: 55%;
  position: absolute;
  top: 17%;
  right: 13%;
  margin: 5rem 0;
  padding: 3rem 5rem;
  color: black;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0.3rem 0.3rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 100;
}
@media (max-width: 37.5em) {
  .section-seller-home-Form7-width-100__message {
    width: 55%;
    height: fit-content;
  }
}

.section-seller-home-Form7-width-100-with-two-pictures {
  width: 90%;
  position: relative;
  background-color: rgba(40, 180, 133, 0.06);
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 1rem auto;
}
.section-seller-home-Form7-width-100-with-two-pictures__pict {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.section-seller-home-Form7-width-100-with-two-pictures__pict:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.1rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.1rem);
  -moz-transform: scale(1.1) translateY(-0.1rem);
  -ms-transform: scale(1.1) translateY(-0.1rem);
  -o-transform: scale(1.1) translateY(-0.1rem);
}
.section-seller-home-Form7-width-100-with-two-pictures__message {
  width: 40%;
  position: absolute;
  top: 17%;
  right: 13%;
  margin: 5rem 0;
  padding: 3rem 5rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.3rem 0.3rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.section-seller-home-Form7-width-100-with-two-texts-and-apicture {
  width: 85%;
  position: relative;
  background-color: rgba(40, 180, 133, 0.03);
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
  outline: 0.7rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 15rem auto;
}
.section-seller-home-Form7-width-100-with-two-texts-and-apicture__pict {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.section-seller-home-Form7-width-100-with-two-texts-and-apicture__pict:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.1rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.1rem);
  -moz-transform: scale(1.1) translateY(-0.1rem);
  -ms-transform: scale(1.1) translateY(-0.1rem);
  -o-transform: scale(1.1) translateY(-0.1rem);
}
.section-seller-home-Form7-width-100-with-two-texts-and-apicture__message {
  width: 40%;
  position: absolute;
  top: 17%;
  right: 13%;
  margin: 5rem 0;
  padding: 3rem 5rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.3rem 0.3rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.section-seller-home-Form7-width-100-with-green-background {
  width: 50%;
  height: 80vh;
  position: relative;
  background-color: rgba(40, 180, 133, 0.15);
  outline: 0.7rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 1rem auto;
}
.section-seller-home-Form7-width-100-with-green-background__pict {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.section-seller-home-Form7-width-100-with-green-background__pict:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.1rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.1rem);
  -moz-transform: scale(1.1) translateY(-0.1rem);
  -ms-transform: scale(1.1) translateY(-0.1rem);
  -o-transform: scale(1.1) translateY(-0.1rem);
}
.section-seller-home-Form7-width-100-with-green-background__message {
  width: 40%;
  position: absolute;
  top: 17%;
  right: 13%;
  margin: 5rem 0;
  padding: 3rem 5rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.3rem 0.3rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.section-seller-home-Form7-width-100-with-blue-background {
  width: 50%;
  height: 80vh;
  position: relative;
  background-color: rgba(8, 94, 175, 0.15);
  outline: 0.7rem solid rgba(255, 255, 255, 0.5);
  outline-offset: 2rem;
  padding: 5rem 3rem;
  margin: 8rem auto;
}
.section-seller-home-Form7-width-100-with-blue-background__pict {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.section-seller-home-Form7-width-100-with-blue-background__pict:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.1rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.1rem);
  -moz-transform: scale(1.1) translateY(-0.1rem);
  -ms-transform: scale(1.1) translateY(-0.1rem);
  -o-transform: scale(1.1) translateY(-0.1rem);
}
.section-seller-home-Form7-width-100-with-blue-background__message {
  width: 40%;
  position: absolute;
  top: 17%;
  right: 13%;
  margin: 5rem 0;
  padding: 3rem 5rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.3rem 0.3rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.section-seller-home-Form-video-background-width-100 {
  width: 75%;
  position: relative;
  height: 55vh;
  background-color: rgba(86, 67, 250, 0.15);
  margin: 5rem auto 10rem auto;
}
.section-seller-home-Form-video-background-width-100__pict {
  width: 100%;
  height: 100%;
}
.section-seller-home-Form-video-background-width-100__pict:hover {
  outline: 0.7rem solid rgba(255, 255, 255, 0.3);
  outline-offset: 2rem;
  transform: scale(1.1) translateY(-0.1rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.1rem);
  -moz-transform: scale(1.1) translateY(-0.1rem);
  -ms-transform: scale(1.1) translateY(-0.1rem);
  -o-transform: scale(1.1) translateY(-0.1rem);
}
.section-seller-home-Form-video-background-width-100__message {
  width: 40%;
  position: absolute;
  top: 8%;
  right: 5%;
  margin: 5rem 0;
  padding: 3rem 5rem;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
}

.section-seller-create-home-Form-width-100 {
  width: 90%;
  background-color: rgba(86, 67, 250, 0.15);
  padding: 0 auto;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.section-home-form-display {
  width: 75vw;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5rem 5rem 15rem 5rem;
  margin: 10rem auto;
  background-size: cover;
}

.section-podcast {
  margin-bottom: 10rem;
  margin-top: 10rem;
  padding: 3rem 0 0 0;
  background-image: linear-gradient(to right, rgba(14, 85, 179, 0.15), rgba(40, 180, 133, 0.13));
  background-size: cover;
}

.section-features {
  margin-bottom: 10rem;
  margin-top: 10rem;
  padding: 3rem 0 0 0;
  background-image: linear-gradient(to right, rgba(14, 85, 179, 0.15), rgba(40, 180, 133, 0.13));
  background-size: cover;
}

.section-features-single-product {
  width: 95vw;
  height: fit-content;
  margin: -2rem auto 10rem auto;
  padding-top: 15rem;
  padding-bottom: 2rem;
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgba(69, 81, 248, 0.4) 15%, rgba(67, 70, 241, 0.5) 35%, rgba(69, 81, 248, 0.4) 40%, rgba(69, 81, 248, 0.4) 45%, rgba(69, 81, 248, 0.4) 55%, rgba(69, 81, 248, 0.6) 65%, rgba(56, 116, 236, 0.6) 100%, rgba(15, 91, 241, 0.7) 90%), url(../image/hero.jpg);
  background-size: cover;
  transform: skewY(-1deg);
}
.section-features-single-product > * {
  transform: skewY(1deg);
  -webkit-transform: skewY(1deg);
  -moz-transform: skewY(1deg);
  -ms-transform: skewY(1deg);
  -o-transform: skewY(1deg);
}

.section-tours {
  background-color: rgba(86, 67, 250, 0.05);
  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.06);
  padding: 5rem 5rem 5rem 5rem;
  margin-top: -10vh;
  margin-left: 10rem;
  margin-right: 10rem;
  margin-bottom: 15rem;
}

.section-features-best-seller {
  margin-bottom: 15rem;
  padding: 25rem 0 10rem 0;
  background-image: linear-gradient(to right, #0e55b3, rgba(40, 180, 133, 0.568627451)), url(../image/nat-4.jpg);
  background-size: cover;
}

.section-product-card {
  background-color: rgba(247, 247, 247, 0.7882352941);
  padding: 28rem 0 10rem 0;
  margin-top: -28rem;
}

.section-video {
  background-color: rgba(247, 247, 247, 0.7882352941);
  padding: 5rem 0;
}

.section-book {
  padding: 15rem 0;
  background-image: linear-gradient(180deg, rgba(69, 81, 248, 0.6) 5%, rgba(69, 81, 248, 0.4) 15%, rgba(67, 70, 241, 0.5) 35%, rgba(69, 81, 248, 0.4) 40%, rgba(69, 81, 248, 0.4) 45%, rgba(69, 81, 248, 0.4) 55%, rgba(69, 81, 248, 0.6) 65%, rgba(56, 116, 236, 0.6) 100%, rgba(15, 91, 241, 0.7) 90%);
}

.book {
  width: 50%;
  margin: 10rem auto !important;
  color: white !important;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.09) 50%, rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0.9) 90%), url(../image/nat-10.jpg);
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem black;
  height: 100% !important;
}
@media (max-width: 37.5em) {
  .book {
    width: 90%;
    height: fit-content !important;
    margin-top: 10rem;
  }
}
.book__form {
  width: 50%;
  padding: 6rem;
}

.book-login {
  width: 50%;
  margin: 10rem auto !important;
  color: white !important;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 70%, rgba(0, 0, 0, 0.9) 100%), url(../image/nat-10.jpg);
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem black;
  height: 100% !important;
}
.book-login__form {
  width: 50%;
  padding: 6rem;
}

.section-features-admin-dashboard {
  width: 95vw;
  height: fit-content;
  margin: 5rem auto 10rem auto;
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-image: linear-gradient(125deg, rgba(69, 81, 248, 0.1) 0%, rgba(69, 81, 248, 0.3) 50%, rgba(69, 81, 248, 0.3) 51%, rgba(69, 81, 248, 0.1) 90%);
  background-size: cover;
  transform: skewY(-1deg);
}
.section-features-admin-dashboard > * {
  transform: skewY(1deg);
  -webkit-transform: skewY(1deg);
  -moz-transform: skewY(1deg);
  -ms-transform: skewY(1deg);
  -o-transform: skewY(1deg);
}

.section-admin-column {
  background-color: rgba(86, 67, 250, 0.05);
  background-size: fill;
  border-radius: 3px;
  width: fit-content;
  height: fit-content !important;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 25rem;
  margin-bottom: 25rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.section-admin-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-seller-home-menu {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  height: fit-content !important;
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
}
.section-seller-home-menu__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-seller-public-column {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 30rem;
  padding-top: 5rem;
}
.section-seller-public-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-seller-single-product-row-nav {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.2), rgba(86, 67, 250, 0.2));
  display: flex;
  flex-direction: row;
  background-size: fill;
  border-radius: 0.1rem;
  -webkit-border-radius: 0.1rem;
  -moz-border-radius: 0.1rem;
  -ms-border-radius: 0.1rem;
  -o-border-radius: 0.1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  height: 20rem;
  font-size: 1.6rem;
  font-weight: 500;
  width: fit-content !important;
  margin-top: 13rem;
  margin-left: 10rem;
  padding-top: 9rem;
}

.section-seller-home-nav {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.2), rgba(86, 67, 250, 0.2));
  display: flex;
  flex-direction: row;
  background-size: fill;
  border-radius: 0.1rem;
  -webkit-border-radius: 0.1rem;
  -moz-border-radius: 0.1rem;
  -ms-border-radius: 0.1rem;
  -o-border-radius: 0.1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  height: 20rem;
  font-size: 1.6rem;
  font-weight: 500;
  width: fit-content !important;
  margin-top: 13rem;
  margin-bottom: 7rem;
  margin-left: 10rem;
  padding-top: 9rem;
}

.section-seller-podcasts-nav {
  background-color: rgba(86, 67, 250, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  height: fit-content !important;
  font-size: 1.4rem;
  font-weight: 700;
  margin: -1rem auto 12rem auto;
  padding: 2rem 1rem 2rem 3rem !important;
  cursor: pointer;
}

.section-seller-product-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  height: fit-content !important;
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content !important;
  height: fit-content;
  margin: 30rem 2.4rem 12rem auto !important;
  padding: 15rem 1rem 7rem 1rem !important;
  cursor: pointer;
}

.section-seller-menu {
  background-color: rgba(255, 255, 255, 0.6);
  justify-content: center;
  justify-items: center;
  border-radius: 0.1rem;
  -webkit-border-radius: 0.1rem;
  -moz-border-radius: 0.1rem;
  -ms-border-radius: 0.1rem;
  -o-border-radius: 0.1rem;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.1);
  height: fit-content !important;
  width: fit-content !important;
  font-weight: 400 !important;
  margin-top: 4rem !important;
  margin-left: 1rem;
  margin-right: 3rem;
  z-index: 1000;
  cursor: pointer;
  padding: 0.5rem !important;
  color: #0e55b3 !important;
}
.section-seller-menu:hover {
  transform: scale(1.1) translateY(-0.1rem);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 100;
  -webkit-transform: scale(1.1) translateY(-0.1rem);
  -moz-transform: scale(1.1) translateY(-0.1rem);
  -ms-transform: scale(1.1) translateY(-0.1rem);
  -o-transform: scale(1.1) translateY(-0.1rem);
}

.section-seller-profile-public-column {
  background-color: rgba(86, 67, 250, 0.04);
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 40rem;
  padding-top: 15rem !important;
}
.section-seller-profile-public-column__form {
  width: 100%;
  padding: 5rem 0 5rem 0;
}

.section-seller-videos-column {
  background-color: rgba(86, 67, 250, 0.2);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 15rem;
  padding-bottom: 42rem;
}
.section-seller-videos-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-sub-category {
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.section-sub-category__form {
  width: 100%;
  padding: 5rem 0rem 5rem 5rem;
}

.section-wishlist-column {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 22rem;
  margin-left: -2rem;
  padding-top: 5rem;
}
.section-wishlist-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-user-order-details {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.02));
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  height: 122rem;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 15rem;
  padding-top: 5rem;
}

.section-user-cart-summary-column {
  background-color: rgba(86, 67, 250, 0.015);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.02);
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 25rem;
}
.section-user-cart-summary-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-profile-column {
  background-color: rgba(86, 67, 250, 0.05);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 40rem;
  padding-right: 2rem;
  padding-left: 1rem !important;
}
.section-profile-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-updated-seller-column {
  background-color: rgba(86, 67, 250, 0.05);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 40rem;
  padding-right: 2rem;
  padding-left: 1rem !important;
}
.section-updated-seller-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}
@media (max-width: 37.5em) {
  .section-updated-seller-column {
    background-color: transparent !important;
    height: fit-content;
    margin-top: 3rem;
  }
}

.section-history-column {
  background-color: rgba(86, 67, 250, 0.04);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  width: fit-content;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 40rem;
  padding-right: 4rem;
  padding-left: 1rem !important;
  padding-top: 4rem;
}
@media (max-width: 37.5em) {
  .section-history-column {
    height: 0;
    background-color: none;
  }
}
.section-history-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-customer-order-details-column {
  background-color: rgba(86, 67, 250, 0.04);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  width: fit-content;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20rem;
  padding-right: 4rem;
  padding-left: 1rem !important;
  padding-top: 4rem;
}
@media (max-width: 37.5em) {
  .section-customer-order-details-column {
    height: 0;
    background-color: none;
  }
}
.section-customer-order-details-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-updated-wishlist-column {
  background-color: rgba(86, 67, 250, 0.04);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  width: fit-content;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 30rem;
  padding-right: 4rem;
  padding-left: 1rem !important;
  padding-top: 4rem;
}
.section-updated-wishlist-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}
@media (max-width: 37.5em) {
  .section-updated-wishlist-column {
    font-size: 6rem !important;
    padding-right: 1rem !important;
  }
}

.section-seller-video-column {
  background-image: linear-gradient(to right bottom, rgba(8, 94, 175, 0.1), rgba(86, 67, 250, 0.2));
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.01);
  font-size: 1.6rem;
  font-weight: 500;
}
.section-seller-video-column__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-seller-videos-jumbtron {
  border: 0.1rem red solid;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem black;
  height: fit-content;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  padding-top: 15rem;
}
.section-seller-videos-jumbtron__form {
  width: 80%;
  padding: 5rem 0 5rem 0;
}

.section-shop-column {
  background-color: rgba(178, 178, 223, 0.05);
  background-size: fill;
  border-radius: 3px;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 15rem;
  padding-left: 1rem;
}
.section-shop-column__form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  padding: 5rem 1rem 10rem 0;
  margin-top: 0rem;
  margin-left: 2rem;
  margin-right: 1rem;
}
@media (max-width: 37.5em) {
  .section-shop-column__form {
    width: 100% !important;
    height: fit-content !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.section-user-column {
  background-image: linear-gradient(110deg, rgba(116, 170, 241, 0) 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.45) 40%, white 51%, rgba(0, 0, 0, 0.1) 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem white;
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 19rem;
  padding-top: 7rem;
}
.section-user-column__form {
  width: 80%;
  padding: 15rem;
}

.section-seller-column {
  background-image: linear-gradient(110deg, rgba(116, 170, 241, 0) 0%, rgba(116, 170, 241, 0.1) 8%, rgba(255, 255, 255, 0.5) 15%, rgba(255, 255, 255, 0.6) 20%, rgba(116, 170, 241, 0.45) 40%, white 51%, rgba(0, 0, 0, 0.1) 53%, white 57%, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 90%);
  background-size: fill;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem white;
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20rem;
  padding-top: 7rem;
}
.section-seller-column__form {
  width: 80%;
  padding: 15rem;
}

