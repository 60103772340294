*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
 html {
   font-size: 62.5%;  // 1 rem = 10px; 10px/16px = 62.5%


   @include respond(lab-top){ // width <1200 ?
    font-size: 56.25%;            // 1 rem = 9px, 9/16 = 56.25%
   }
   @include respond(tab-land){ // width <1200 ?
    font-size: 56.25%;            // 1 rem = 9px, 9/16 = 56.25%
   }

   @include respond(tab-port){  // width < 900 ?
    font-size: 30%;             // 1 rem = 8px, 8/16 = 50%
   }

      @include respond(phone){ // width < 600 ?
    font-size: 25%;            // .40 * 16 = 6.4
   }
      @include respond(small-phone){ // width < 375 ?
    font-size: 20%;            // .40 * 16 = 6.4
   }

   @include respond(big-desktop){
    font-size: 75%;  // 1rem = 12, 12/16 = 75%
   }
 }

  body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /* font-size: 1.6rem; */
  line-height: 1.7;
  color: $color-grey-dark;
  // background-color: rgb(23, 130, 238);
  box-sizing: border-box;
  // padding: 3rem;

  @include respond(tab-port){
    padding:0;
  }

}
