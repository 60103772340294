// .spinner {
//   animation: rotate 2s linear infinite;
//   z-index: 2;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   margin: -25px 0 0 -25px;
//   width: 10rem;
//   height: 10rem;
// }

// .path {
//   stroke: hsl(210, 70, 75);
//   stroke-linecap: round;
//   animation: dash 1.5s ease-in-out infinite;
// }

// @keyframes rotate {
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes dash {
//   0% {
//     stroke-dasharray: 1, 150;
//     stroke-dashoffset: 0;
//   }
//   50% {
//     stroke-dasharray: 90, 150;
//     stroke-dashoffset: -35;
//   }
//   100% {
//     stroke-dasharray: 90, 150;
//     stroke-dashoffset: -124;
//   }
// }

.spinner {
  animation: spin 1s infinite ease-in-out;
//  animation: dash 2s infinite ease-in-out;
  border-radius: 50%;
  border-top: 2px solid #fff;
  display: inline-block;
  height: 2em;
  margin: calc(50vh - 1em) calc(50vw - 1em);
  width: 2em;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
