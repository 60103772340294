.card-tour {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;

  &__side {
    height: 52rem;
    transition:all .8s ease;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 3rem;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

    &--front {
      background-color: $color-white;
    }

    &--back {
      transform: rotateY(180deg);

      &-1{
        background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
      }
      &-2{
        background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
      }
      &-3{
        background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark);
      }
    }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }
  &:hover &__side--back {
    transform: rotateY(0);
  }

  //FRONT SIDE STYLING
  &__picture {
    background-size: cover;
    height: 23rem;
    background-blend-mode:screen;
    -webkit-clip-path:polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;

    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, 1), rgba($color-secondary-dark, 1)),url(../images/dyagonline-black&white.jpg);
    }

    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-primary-light, 1), rgba($color-secondary-dark,1)),url(../images/DYAshopping.jpg);

    }

    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, 1), rgba($color-tertiary-dark, 1)),url(../images/dyagoonline.jpg);
    }

  }


  &__heading {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: $color-white;
    position: absolute;
    top: 20rem;
    right:.5rem;
    width: 95%;
  }


  /////////////////
  &__heading-span {
        padding: .2rem .5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        &--1 {
            background-image: linear-gradient(to right bottom,
            rgba($color-secondary-light, .5),
            rgba($color-secondary-dark, .5));
        }

        &--2 {
            background-image: linear-gradient(to right bottom,
            rgba($color-primary-light, .5),
            rgba($color-primary-dark, .5));
        }

        &--3 {
            background-image: linear-gradient(to right bottom,
            rgba($color-tertiary-light, .5),
            rgba($color-tertiary-dark, .5));
        }
    }

  //////////////////

  &__details {
    padding: 3rem;
    ul {
      list-style: none;
      width: 80%;
      margin: 0 auto;
      li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem;

        &:not(:last-child){
          border-bottom: 1px solid $color-grey-light-2;
        }
      }
    }
  }


  //BACK SIDE STYLING
  &__cta {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 90%;
    text-align: center;
  }

  &__price-box {
    text-align: center;
    color: $color-white;
    margin-bottom: 3rem;
    border: .1rem red solid;
  }

  &__price-only {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  &__price-value {
    font-size: 6rem;
    font-weight: 100;
  }


}


.card {
  //forfunctionalilty
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 35rem;
  background-color: rgba($color-white, .5);
  border: none;
  margin-bottom: 2rem;
  box-shadow: none ;


    &__side {
      height:35rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      border-radius: 6px;
      // border: 1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      &--front {
        background-color: rgba($color-white, 1);
        // border: .1rem red solid;
      }

      &--back {
        transform: rotateY(180deg);
        // border: .1rem red solid;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          background-image: linear-gradient(to right, rgba($color-tertiary-light, .7), rgba($color-tertiary-dark, 1));
        }

      }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    // position: absolute;
    //   top: -.8px;
    //   left: -.5px;
    background-size: cover;
    overflow: hidden;
    width:fit-content;
    height: 35rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // border-top-left-radius: 1px;
    // border-top-right-radius: 1px;

    // &--1 {
    //   background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
    // }
    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }
  }

  &__heading {
    margin-top: 0;
    // padding-bottom: -50rem;
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: right;
    color: rgb(14, 81, 189);
    //  font-weight: bold;
    position: absolute;
    top: 0rem;
    right:3rem;
    width: 100%;
  }

  &__heading-span {
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 26.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-black, 1);
    position: absolute;
    top: 12rem;
    // left:;
    width: 100%;

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;
      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }






  // Back side style:
  &__cta {
    font-size: 1.1rem;

    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, -50%);
    width: 90%;
    // height: fit-content;
    text-align: center;
    // margin-bottom: 5rem;
  }

  &__price-box {
    // height:fit-content;
    text-align: center;
    color: rgb(237, 237, 243);

    margin-top: 15%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 4rem;
    font-weight: 100;
  }

  &__view-product {
    display: block;


    // justify-content:space-between;
    // justify-items:center;
    // border: 1rem red solid;
    padding-top: -2rem;
    padding-bottom: .5rem;
    margin-top: -1rem;

    &--video {
      display: inline-block;
      margin-left: 1rem;
      color: $color-green-yellow;

    }

    &--view {
      display: inline-block;
      margin-left: 5rem;
      color: $color-green-yellow;
    }
  }



  &__seller-video-name {
    display: inline-block;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    // margin-top: 1rem;
    margin-bottom: 3rem;


  }
  &__seller-name-1 {
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;


  }

  &__seller-name-2 {
    display: block;
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding: 0 1rem;
    // border: 1rem red solid;



  }

   &__rating {
    // padding: 1rem ;
    font-size: 1.5rem;
    text-align: center;

    &-showAverage {
      margin-top: 1rem;
      margin-bottom: -1rem;
    }
  }
}

.card-sub-category {
  //forfunctionalilty
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  // height:100% !important;
  background-color: transparent;
  border: none;
  // margin-bottom: -1rem;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  // border: .1rem red solid;




    &__side {
      height:30rem !important;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      // border-radius: 6px;
      // border: 1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      &--front {
        background-color: rgba($color-white, 1);
         &::after{
          width: 90%;
          // opacity: 0;
          // visibility: hidden;
        }

      }

      &--back {
        transform: rotateY(180deg);
        // border: .1rem red solid;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          // background-image: linear-gradient(to bottom,
         background-image: linear-gradient(to right,
          rgba($color-tertiary-light, .1)0%,
          rgba($color-black, 1)35%,
          rgba($color-tertiary-dark, 1)100%);
        }

      }
  }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    position: absolute;
    top: -.25rem;
    //left: -.5px;
    //background-size: cover;
    //object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    object-fit: cover;
    width: 100%;
    // height: 100%;
    min-height:30rem !important;
    max-height: 30rem !important;
    transition:filter .5s ;
    background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    -webkit-transition:filter .5s ;
    -moz-transition:filter .5s ;
    -ms-transition:filter .5s ;
    -o-transition:filter .5s ;

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    &::before {
        content: "";
        position:absolute;
        top: 4vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
}

    &::after {
      content: "";
      position:absolute;
      top: 50%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 50%;
    left: 5rem;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;

    @include respond(phone){
      width: 0;
      opacity: 0;
      // font-size: 3rem;
    }
    @include respond(small-phone){
      width: 0 ;
      opacity: 0;
      // height: 0 !important;
      // font-size: 3rem;
    }
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .1rem;
    left:.5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
}

  &__heading-span {
    // width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 30rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-white, 1);
    position: absolute;
    top: 10rem;
    // left:;
    width:85%;
    z-index: 10;
    // opacity: 0;
    //  &:hover {
    //   opacity: 1;
    //   visibility: visible;
      // transition: opacity 1s 1s;
      // -webkit-transition: opacity 1s 1s;
      // -moz-transition: opacity 1s 1s;
      // -ms-transition: opacity 1s 1s;
      // -o-transition: opacity 1s 1s;
      // }

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }

  // Back side style:
  &__back-header-box{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    background-color: #182a41;
    position: absolute;
    // top: .2%;
    @include respond(phone){
      width: 0;
      height: 0;
      opacity: 0;
    }
  }

  &__back-footer-box{
    width: 100%;
    height: 15%;
    color: rgba($color-white, 1);
    background-color: #232f3e;
    position: absolute;
    bottom: .2%;

  }



  &__cta {
    font-size: 1.1rem;
    // border: .1rem yellow solid;
    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:25%;
    // left: 50%;
    // transform: translate(-50%, 50%);
    // -webkit-transform: translate(-50%, -50%);
    width: 100%;
    height:59%;
    text-align: center;
    // margin-bottom: 5rem;

        &::before {
        content: "";
        position:absolute;
        top: .1vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;

        @include respond(phone){
          width: 0;
          height: 0;
          opacity: 0;
        }

        }

    &::after{
      content: "";
      position:absolute;
      top: 15%;
      left:2rem;
      width: 85%;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
      transition: width .5s 0;
      -webkit-transition: width .5s 0;
      -moz-transition: width .5s 0;
      -ms-transition: width .5s 0;
      -o-transition: width .5s 0;

      @include respond(phone){
        // top: 1% !important;
        width: 0;
        height: 0;
        opacity: 0;
      }

    }

    &:hover::after{
          width: 95%;
          transition: width .5s .1s;
          -webkit-transition: width .5s .1s;
          -moz-transition: width .5s .1s;
          -ms-transition: width .5s .1s;
          -o-transition: width .5s .1s;
    }

  }



  &__price-box {
    // height:fit-content;
    // display: flex;
    // justify-content: center;
    // justify-items: center;
    align-items: center;
    text-align: center;
    color: rgb(237, 237, 243);
    position: absolute;
    top: 24%;
    left: 45%;

    @include respond(phone){
      // position: absolute;
      // font-size: 5rem;
      top: 3%;
      left: 42%;
    }
    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 2rem;
    font-weight: 400;
    @include respond(phone){
       font-size: 3.5rem;
    }
  }

 &__view-product {
    display: flex;
    width: 100% !important;
    height: fit-content;
    justify-content: space-around !important;
    font-size: 1.5rem;


    // justify-content:space-between;
    // justify-items:center;
    // border: .1rem red solid;
    padding:5rem auto;
    // padding-bottom: .5rem;
    position: absolute;
    // top: 100%;
    bottom: -3vh;
    // left: 25%;
    // margin-top: 21rem;
     @include respond(tab-port){
        font-size: 2.5rem !important;
        // top: 10vh !important;
          bottom: -2vh !important;
      }
     @include respond(phone){
        font-size: 3.5rem !important;
        // top: 5vh;
          bottom: -3.5rem !important;
      }
     @include respond(small-phone){
        font-size: 2.5rem !important;
        top: 7vh;
          bottom: 0 !important;
      }


    &--video {
      display: flex;
      margin-left: 1rem;
      padding-bottom: 2rem;
      color: rgba($color-white, .9);
      z-index: 100;

      @include respond(tab-land){
        font-size: 1.3rem !important;
      }
      @include respond(tab-port){
        font-size: 3rem !important;
        // top: 10vh !important;
          // bottom: -5vh !important;
      }
      @include respond(phone){
        // font-size: rem !important;
        //  margin-top: 3.5rem !important;
        // margin-top: -11rem !important;
        // position: absolute;
        // top: 10vh !important;
          // bottom: 7vh !important;
      }
      @include respond(small-phone){
        font-size: 2.5rem !important;
        margin-top: -11rem !important;
        // position: absolute;
        // top: 10vh !important;
          // bottom: -7vh !important;
      }

    }

    &--view {
      display: flex;
      justify-content:center;
      margin-left: 1rem;
      padding-bottom: 2rem;
      //  margin-top: 2rem !important;
      color: rgba($color-white, .9);

      @include respond(tab-port){
         margin-top: 6rem !important;
      }
      @include respond(phone){
        //  bottom: -2.5rem !important;
         font-size: 2.5rem !important;
      }
      @include respond(small-phone){
         margin-top: 3rem !important;
      }
    }
  }



  &__seller-video-name {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: 0 5rem;
    // margin-top: 1rem;
    color: rgba($color-white, 1);
  //  background-color: rgba($color-secondary-dark, .5);
    // margin-left: auto;
    margin-bottom: 3rem;
    //  border: .1rem red solid;
     position: absolute;
     top: -1%;
    right: 20%;
    cursor: pointer;

    @include respond(phone){
      height: fit-content;
      position: absolute;
     top: -6%;
     font-size: 3rem;
    }
    // @include respond(phone){
    //   height: fit-content;
    //   position: absolute;
    //  top: -6%;
    //  font-size: 1rem;
    // }

  }

  &__related-product-seller-name {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: .5rem auto;
    // padding-right: 10rem !important;
    margin-top: -.5rem;
    color: rgba($color-white, 1);
  //  background-color: rgba($color-secondary-dark, .5);
    // margin-left: auto;
    margin-bottom: 3rem;
    //  border: .1rem red solid;
     position: absolute;
    // right: 20%;
    cursor: pointer !important;

  }


  &__seller-name-2 {
  //  &__seller-name-2 {
    width: 85%;
    display: flex;
    // justify-content: center;
    font-size: 1.6rem;
    color: $color-white;
    // background-color: rgba($color-black,.1);
    justify-content: center;
    justify-items: center;
    padding: 0 1rem;
    position: absolute;
    // top:-1%;
    left: 30%;
    cursor: pointer;
    margin-top: -2%;
    // border: 1rem red solid;
//      &:hover {
//       transform: translateY(-2rem);
//       -webkit-transform: translateY(-2rem);
//       box-shadow: 0 1rem 2rem rgba($color-black, .2);
//       -moz-transform: translateY(-2rem);
//       -ms-transform: translateY(-2rem);
//       -o-transform: translateY(-2rem);
// }
    @include respond(tab-port){
      font-size: 2.5rem !important;
    }

    @include respond(phone){
      font-size: 3rem !important;
      margin-top: -7rem !important;
      left: 25%;
      // text-align: center !important;
    }
  }
  // }

  &__add-to-cart {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $color-white;
    // justify-content: center;
    // justify-items: center;
    // padding: 0 1rem;
    position: absolute;
    top:50%;
    left: 40%;
    margin-top: 1rem;
    // border: 1rem red solid;

     &--home{
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 40%;
    font-size: 1.7rem;
    font-weight: 700 !important;
    color: $color-secondary-dark !important;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    position: absolute;
    top: 17vh;
    left:30%;
    z-index: 10000;
    cursor: pointer !important;

    @include respond(tab-land){
      // font-size: 1.4rem;
      top: 20vh;
       width: 60%;
       left:20%;
    }
    @include respond(tab-port){
       font-size: 2.7rem;
      top: 10.5vh;
       width: 60%;
       left:20%;
    }
    @include respond(phone){
       font-size: 2.7rem;
      top: 6.3vh;
       width: 60%;
       left:20%;
    }
    @include respond(small-phone){
       font-size: 2.7rem;
      top: 6vh;
       width: 60%;
       left:20%;
    }
    }
  }


   &__product-color {
    display: flex;
    width: 50%;
    height: fit-content;
    justify-content:space-around;
    justify-items: center;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .05);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 9vh;
    left: 0%;
    // margin-top: 1rem;
    // border: 1rem red solid;
    @include respond(tab-land){
       top: 11vh;
      //  left: 1%;
       margin-right: 2rem !important;
    }
    @include respond(tab-port){
       top: 5vh;
      //  left: 1%;
      font-size: 2.5rem !important;
       margin-right: 2rem !important;
    }
    @include respond(phone){
      position: absolute;
       top: 1.5vh;
       left: -5%;
      font-size: 3rem !important;
       margin-right: 1rem !important;
    }
    @include respond(small-phone){
      position: absolute;
       top: 1vh;
       left: -5%;
      font-size: 2.5rem !important;
       margin-right: 2rem !important;
    }
  }

  &__product-size {
    display: flex;
     width: 50%;
     height: fit-content;
     justify-content: space-around;
    justify-items: space-around;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .05);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 9vh;
    left: 52%;
    // margin-top: 1rem;
    // border: .1rem yellow solid;

    @include respond(tab-land){
       width: fit-content;
       margin-left: 2rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 11vh;
      //  left: 51%;
    }
    @include respond(tab-port){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 2.5rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 5vh;
       left: 60%;
    }
    @include respond(phone){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 3rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 1.5vh;
       left: 62%;
    }
    @include respond(small-phone){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 2.5rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 1vh;
       left: 62%;
    }
  }




   &__rating {
    // padding: 1rem ;
    width: 100%;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    top: 0%;
    // left: 50%;
    @include respond(tab-port){
      // top: 10%;
      margin-top: -5rem;
      font-size: 2.5rem !important;
    }

    &-showAverage {
      width: 100%;
      display: flex;
      // justify-content: center;
      // justify-items: center;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      // color: rgba($color-white, 1);
      position: absolute;
      top: -1.5rem;
      left: 50%;

       @include respond(tab-port){
        top: 0%;
      font-size: 2.5rem !important;
    }

    }

    &-message {
      width: 100%;
      display: flex;
      justify-content: center !important;
      justify-items: center !important;
      margin-top: -1.5rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      color: rgba($color-white, 1);

       @include respond(tab-port){
      font-size: 2.5rem !important;
    }

    }
  }
}

.card-category-products-2 {
  //forfunctionalilty
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height:100%;
  background-color: transparent;
  border: none;
  // margin-bottom: -1rem;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  // border: .1rem green solid;




  //   &__side {
  //     height:28.4rem;
  //     transition: all .8s ease;
  //     -webkit-transition: all .8s ease;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     backface-visibility: hidden;
  //     // border-radius: 6px;
  //     // border: 1rem red solid;
  //     overflow: hidden;
  //     // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

  //     &--front {
  //       background-color: rgba($color-white, 1);
  //        &::after{
  //         width: 90%;
  //         // opacity: 0;
  //         // visibility: hidden;
  //       }

  //     }

  //     &--back {
  //       transform: rotateY(180deg);
  //       // border: .1rem red solid;

  //       &-1 {
  //         background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
  //       }
  //       &-2 {
  //         background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
  //       }
  //       &-3 {
  //         // background-image: linear-gradient(to bottom,
  //        background-image: linear-gradient(to right,
  //         rgba($color-tertiary-light, .1)0%,
  //         rgba($color-black, 1)35%,
  //         rgba($color-tertiary-dark, 1)100%);
  //       }

  //     }
  // }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    position: absolute;
    top: -.25rem;
    //left: -.5px;
    //background-size: cover;
    //object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    object-fit: fill;
    width: 100%;
    height: 100%;
    // min-height:36rem;
    // max-height: 36rem;
    // transition:filter .5s ;
    // background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    // -webkit-transition:filter .5s ;
    // -moz-transition:filter .5s ;
    // -ms-transition:filter .5s ;
    // -o-transition:filter .5s ;

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    &::before {
        content: "";
        position:absolute;
        top: 4vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
}

    &::after {
      content: "";
      position:absolute;
      top: 60%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 50%;
    left: 2rem;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 1.3rem;
    font-weight: 700;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .5rem;
    left:.5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
}

  &__heading-span {
    // width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 30rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-white, 1);
    position: absolute;
    top: 10rem;
    // left:;
    width:85%;
    z-index: 10;
    // opacity: 0;
    //  &:hover {
    //   opacity: 1;
    //   visibility: visible;
      // transition: opacity 1s 1s;
      // -webkit-transition: opacity 1s 1s;
      // -moz-transition: opacity 1s 1s;
      // -ms-transition: opacity 1s 1s;
      // -o-transition: opacity 1s 1s;
      // }

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }

  // Back side style:
  &__back-header-box{
    width: 100%;
    height: 25%;
    background-color: #182a41;
    position: absolute;
    // top: .2%;
  }

  &__back-footer-box{
    width: 100%;
    height: 15%;
    color: rgba($color-white, 1);
    background-color: #232f3e;
    position: absolute;
    bottom: .2%;

  }



  &__cta {
    font-size: 1.1rem;
    // border: .1rem yellow solid;
    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:25%;
    // left: 50%;
    // transform: translate(-50%, 50%);
    // -webkit-transform: translate(-50%, -50%);
    width: 100%;
    height:59%;
    text-align: center;
    // margin-bottom: 5rem;

        &::before {
        content: "";
        position:absolute;
        top: 2vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
        }

    &::after{
      content: "";
      position:absolute;
      top: 50%;
      left:2rem;
      width: 90%;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
      transition: width .5s 0;
      -webkit-transition: width .5s 0;
      -moz-transition: width .5s 0;
      -ms-transition: width .5s 0;
      -o-transition: width .5s 0;

    }

    // &:hover::after{
    //       width: 90%;
    //       transition: width .5s .1s;
    //       -webkit-transition: width .5s .1s;
    //       -moz-transition: width .5s .1s;
    //       -ms-transition: width .5s .1s;
    //       -o-transition: width .5s .1s;
    // }

  }



  &__price-box {
    // height:fit-content;
    // display: flex;
    // justify-content: center;
    // justify-items: center;
    align-items: center;
    text-align: center;
    color: rgb(237, 237, 243);
    position: absolute;
    top: 2%;
    left: 40%;

    // margin-top: 11%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 2rem;
    font-weight: 400;
  }

  &__view-product {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: space-evenly;
    font-size: 1.5rem;


    // justify-content:space-between;
    // justify-items:center;
    // border: .1rem red solid;
    padding:5rem auto;
    // padding-bottom: .5rem;
    position: absolute;
    // top: 100%;
    bottom: 5%;
    // left: 25%;
    // margin-top: 21rem;

    &--video {
      display: inline-block;
      margin-left: 1rem;
      color: $color-green-yellow;

    }

    &--view {
      display: inline-block;
      margin-left: 5rem;
      color: $color-green-yellow;
    }
  }



  &__seller-video-name {
    display: inline-block;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    //  border: .1rem red solid;


  }


  &__seller-name-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: $color-white;
    // justify-content: center;
    // justify-items: center;
    padding: 0 1rem;
    position: absolute;
    top:25%;
    left: 5%;
    // margin-top: 50%;
    // border: 1rem red solid;
  }

  &__add-to-cart {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $color-white;
    // justify-content: center;
    // justify-items: center;
    // padding: 0 1rem;
    position: absolute;
    top:50%;
    left: 40%;
    margin-top: 1rem;
    // border: 1rem red solid;
  }

   &__rating {
    // padding: 1rem ;
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    top: 1%;
    // left: 50%;

    &-showAverage {
      width: 100%;
      display: flex;
      // justify-content: center;
      // justify-items: center;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      // color: rgba($color-white, 1);
      position: absolute;
      // top: 1%;
      left: 50%;

    }

    &-message {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      color: rgba($color-white, 1);

    }
  }
}

.card-category-products-3 {

  perspective: 150rem;
  -moz-perspective: 150rem;
  // position: relative;
  height:100%;
  background-color: transparent;
  border: none;


  //   &__side {
  //     height:100%;
  //     transition: all .8s ease;
  //     -webkit-transition: all .8s ease;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     backface-visibility: hidden;

  //     overflow: hidden;


  //     &--front {
  //       background-color: rgba($color-white, 1);
  //        &::after{
  //         width: 90%;

  //       }

  //     }


  // }


  // &:hover &__side--back {
  //   transform: rotateY(0);
  // }

  &__picture {
    position: relative;
    // top: -.25rem;

    object-fit: fill;
    width: 100%;
    height: 100%;
    // min-height:36rem;
    // max-height: 36rem;
    // transition:filter .5s ;
    // background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);

    // -webkit-transition:filter .5s ;
    // -moz-transition:filter .5s ;
    // -ms-transition:filter .5s ;
    // -o-transition:filter .5s ;


    &::before {
        content: "";
        position:absolute;
        top: 20%;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
}

    &::after {
      content: "";
      position:absolute;
      top: 60%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 50%;
    left: 3rem;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .5rem;
    left:.5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;

  }

  &__heading--small-2-products {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .5rem;
    left:.3rem;
    width:75%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;

  }

}

.card-seller-home-sub-category {
 //width: fit-content;
 //perspective: 150rem;
 //-moz-perspective: 150rem;
//  border: .1rem blue solid;
 position: relative;
 width: 100%;
 height: 100%;
 background-color: rgba($color-black, .1);
//  padding: 1rem;
margin-bottom: 1rem;

 @include respond(tab-port){
  width: 100%;
  height: 100%;
 }
 @include respond(phone){
  width: 100%;
  height: 100%;
 }
 @include respond(small-phone){
  width: 100%;
  height: 28rem !important;
  // border: .2rem red solid;

 }
 //border: none;
//  border-radius: 50%;
//  -webkit-border-radius: 50%;
//  -moz-border-radius: 50%;
//  -ms-border-radius: 50%;
//  -o-border-radius: 50%;
  &__category-sub-product-logo {
    position: absolute;
    top: .5rem;
    left: .5rem;
    // border: #060ef7 .1rem solid;
    width: 4rem;
    height: 2.5rem !important;
  }

  //   &__category-sub-product-seller-name {
  //   width: 15rem;
  //   position: absolute;
  //   top: 50%;
  //   right: 50%;
  //   color: $color-white;
  //   border: .2rem red solid;
  //   background-color: rgba($color-black, 1);
  //   font-size: 2rem;
  //   font-weight: 700;
  //   justify-content: center;
  //   justify-items: center;
  //   padding-left: 5rem;
  //   transition:width .5s;
  //   -webkit-transition:width .5s;
  //   -moz-transition:width .5s;
  //   -ms-transition:width .5s;
  //   -o-transition:width .5s;
  //   z-index: 1000;
  //   &__seller-name-1:hover {
  //     width: 0;
  //     opacity: 0;
  //     transition: width opacity .5s 0;
  //     // filter: blur(.2rem);
  //     //-webkit-filter: blur(.2rem);
  //     -webkit-transition: width opacity .5s 0;
  //     -moz-transition: width opacity .5s 0;
  //     -ms-transition: width opacity .5s 0;
  //     -o-transition: width opacity .5s 0;
  //   }
  // }


&__picture {
    position: absolute;
    top: 0;
    left:0;
    // background-size: cover;
    // object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    // object-fit: cover !important;
    // width: fit-content;
   flex-shrink:0;
    -webkit-flex-shrink: 0;
    max-width:100%;
    max-height:100% !important;
    // padding: .3rem;


  // margin-bottom: 5rem;

//    border-radius: 50% ;
//  -webkit-border-radius: 50% ;
//  -moz-border-radius: 50% ;
//  -ms-border-radius: 50% ;
//  -o-border-radius: 50% ;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  // border: .1rem green solid;




    // &__side {
    //   height:12rem;
    //   transition: all .8s ease;
    //   -webkit-transition: all .8s ease;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 70%;
    //   backface-visibility: hidden;
      // border-radius: 6px;
      // border: 1rem red solid;
      // overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      // &--front {
      //   background-color: rgba($color-white, 1);
      //    &::after{
      //     width: 90%;
          // opacity: 0;
          // visibility: hidden;
      //   }

      // }

      // &--back {
      //   transform: rotateY(180deg);
      //   // border: .1rem red solid;

      //   &-1 {
      //     background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      //   }
      //   &-2 {
      //     background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      //   }
      //   &-3 {
      //     // background-image: linear-gradient(to bottom,
      //    background-image: linear-gradient(to right,
      //     rgba($color-tertiary-light, .1)0%,
      //     rgba($color-black, 1)35%,
      //     rgba($color-tertiary-dark, 1)100%);
      //   }

      // }
  }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  // &:hover &__side--back {
  //   transform: rotateY(0);
  // }

  //Front Side Styling
  // &__picture {
  //   position: absolute;
  //   top: -.25rem;
  //   left: -.5px;
  //   background-size: cover;
    // object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    // object-fit: cover;
    // width: 100%;
    // height: 100%;
    // min-height:36rem;
    // max-height: 36rem;
    // transition:filter .5s ;
    // background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    // -webkit-transition:filter .5s ;
    // -moz-transition:filter .5s ;
    // -ms-transition:filter .5s ;
    // -o-transition:filter .5s ;

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    // &::before {
    //     content: "";
    //     position:absolute;
    //     top: 4vw;
    //     left: 90%;
    //     width: 0.1rem;
    //     height: 50%;
    //     background-color: rgba($color-white, 1);
    //     box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
    //     transition: height .3s;
    //     z-index: 10;
    //     -webkit-transition: height .3s;
    //     -moz-transition: height .3s;
    //     -ms-transition: height .3s;
    //     -o-transition: height .3s;
    // }

    // &::after {
    //   content: "";
    //   position:absolute;
    //   top: 60%;
    //   left:2rem;
    //   width: 90%;
    //   opacity: 1;
    //   height: 0.1rem;
    //   background-color: rgba($color-white, 1);
    //    box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
    //   transition: width .4s ;
    //   z-index: 10;
    //   -webkit-transition: width .4s ;
    //   -moz-transition: width .4s ;
    //   -ms-transition: width .4s ;
    //   -o-transition: width .4s ;
    // }

    //  &:hover::after{
    //    width: 0;
    //    transition: width .5s 0;
    //    -webkit-transition: width .5s 0;
    //    -moz-transition: width .5s 0;
    //    -ms-transition: width .5s 0;
    //    -o-transition: width .5s 0;
    //  }

    // }


    &__seller-name-1 {
    width: 50%;
    position: absolute;
    top: 20%;
    left: 1rem;
    color: $color-tertiary-dark;
    background-color: rgba($color-black, .1);
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }

    &__seller-name-category-sub-product {
    width: 60%;
    position: absolute;
    top: 40%;
    left: 40%;
    color: $color-tertiary-light;
    background-color: rgba($color-white, .5);
    font-size: 1.5rem;
    font-weight: 700;
    justify-content: center;
    justify-items: center;
    padding: 1rem 2rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }


  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    z-index: 1000;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: 5rem;
    left:6rem;
    width:100%;
    // opacity: 0;
    transition: width 0.3s;
    // overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
@include respond(phone){
    position: absolute;
    top: 2rem;
    left:4rem;
}
}

  // &__heading-span {
  //   // width: 20rem;
  //   padding: 0 1.5rem 0 1.5rem;
  //   // -webkit-box-decoration-break: clone;
  //   // box-decoration-break: clone;
  //   &--1 {
  //     background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
  //   }
  //   &--2 {
  //     background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
  //   }
  //   &--3 {
  //     background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
  //   }

  //   &--4 {
  //       background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
  //     }
  // }

  // &__details {
  //   padding: 2.5rem .2rem 1rem .2rem;
  //   margin-top: 30rem;
  //   font-size: 1.5rem;
  //   font-weight: 400;
  //   // font-style: italic;
  //   // font-weight: bold;
  //   // letter-spacing: .2rem;
  //   // text-transform: uppercase;
  //   // text-align: center;
  //   color:rgba($color-white, 1);
  //   position: absolute;
  //   top: 10rem;
  //   // left:;
  //   width:85%;
  //   z-index: 10;
  //   // opacity: 0;
  //   //  &:hover {
  //   //   opacity: 1;
  //   //   visibility: visible;
  //     // transition: opacity 1s 1s;
  //     // -webkit-transition: opacity 1s 1s;
  //     // -moz-transition: opacity 1s 1s;
  //     // -ms-transition: opacity 1s 1s;
  //     // -o-transition: opacity 1s 1s;
  //     // }

  //   ul {
  //     list-style: none;
  //     width: 100%;
  //     margin: -10rem auto;

  //     // justify-content: center;
  //     // &:not(:last-child){
  //     //   border-bottom: 1px solid $color-grey-light-2;
  //     // }
  //   }

  //   &-span {
  //     text-align: left;
  //     // padding-top: 3rem;

  //     padding-left: .5rem;
  //     -webkit-box-decoration-break: clone;
  //     box-decoration-break: clone;
  //     // &--1 {
  //     //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
  //     // }
  //     // &--2 {
  //     //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
  //     // }
  //     // &--3 {
  //     //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
  //     // }
  //     // &--4 {
  //     //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
  //     // }
  //   }
  // }

  // Back side style:
  // &__back-header-box{
  //   width: 100%;
  //   height: 25%;
  //   background-color: #182a41;
  //   position: absolute;
  //   // top: .2%;
  // }

  // &__back-footer-box{
  //   width: 100%;
  //   height: 15%;
  //   color: rgba($color-white, 1);
  //   background-color: #232f3e;
  //   position: absolute;
  //   bottom: .2%;

  // }



  // &__cta {
  //   font-size: 1.1rem;
  //   // border: .1rem yellow solid;
  //   // display: block;
  //   //  justify-content: center;
  //   //  justify-items:center;
  //   position: absolute;
  //   top:25%;
  //   // left: 50%;
  //   // transform: translate(-50%, 50%);
  //   // -webkit-transform: translate(-50%, -50%);
  //   width: 0%;
  //   height:0%;
  //   text-align: center;
  //   // margin-bottom: 5rem;

  //       &::before {
  //       content: "";
  //       position:absolute;
  //       top: 2vw;
  //       left: 90%;
  //       width: 0.1rem;
  //       height: 50%;
  //       background-color: rgba($color-white, 1);
  //       box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
  //       transition: height .3s;
  //       z-index: 10;
  //       -webkit-transition: height .3s;
  //       -moz-transition: height .3s;
  //       -ms-transition: height .3s;
  //       -o-transition: height .3s;
  //       }

  //   &::after{
  //     content: "";
  //     position:absolute;
  //     top: 50%;
  //     left:2rem;
  //     width: 90%;
  //     height: 0.1rem;
  //     background-color: rgba($color-white, 1);
  //     transition: width .5s 0;
  //     -webkit-transition: width .5s 0;
  //     -moz-transition: width .5s 0;
  //     -ms-transition: width .5s 0;
  //     -o-transition: width .5s 0;

  //   }

  //   // &:hover::after{
  //   //       width: 90%;
  //   //       transition: width .5s .1s;
  //   //       -webkit-transition: width .5s .1s;
  //   //       -moz-transition: width .5s .1s;
  //   //       -ms-transition: width .5s .1s;
  //   //       -o-transition: width .5s .1s;
  //   // }

  // }



  // &__price-box {
  //   // height:fit-content;
  //   // display: flex;
  //   // justify-content: center;
  //   // justify-items: center;
  //   align-items: center;
  //   text-align: center;
  //   color: rgb(237, 237, 243);
  //   position: absolute;
  //   top: 2%;
  //   left: 40%;

  //   // margin-top: 11%;

  //   // display:block;
  //   // justify-content:end;
  //   // margin-bottom: -2rem;
  // }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  // &__price-value {
  //   font-size: 2rem;
  //   font-weight: 400;
  // }

  &__view-product {
    display: flex;
    width: 100%;
    // height: fit-content;
    padding: 2rem 1rem;
    justify-content: flex-start;
    justify-items: center;
    font-size: 1rem;
    color:rgba($color-black, 1);
    background-color:rgba($color-white, .5);
    // z-index: 2000;


    // justify-content:space-between;
    // justify-items:center;
    // border: .1rem red solid;
    // padding:5rem auto;
    // padding-bottom: .5rem;
    // position: relative;
    // top: 100%;
    // bottom: 5%;
    // left: 25%;
    // margin-top: 21rem;

    // &--video {
    //   display: inline-block;
    //   margin-left: 1rem;
    //   color: $color-green-yellow;

    // }

    // &--view {
    //   width: 14rem;
    //   // height: fit-content;
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: flex-start;
    //   justify-items: center;
    //   // position: absolute;
    //   // top: 0;
    //   // bottom: -15%;
    //   margin-top: -1rem;
    //   margin-left: -.5rem;
    //   padding: 1rem 0;
    //   font-size: 1rem;
    //   color: $color-white;
    //   background-color: rgba($color-black, .2);
    // }
  }

  &__view-product-category-sub-product {
    display: flex;
    width: 13rem;
    height: fit-content;
    position: absolute;
    top: 10rem;
    left: -6rem;
    // margin-top: 100%;
    padding: .8rem 1rem !important;
    justify-content: center;
    justify-items: center;
    font-size: 1.2rem;
    color:rgba($color-white, 1);
    background-color:rgba($color-black, .3);
    cursor: pointer;
    &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;

    // box-shadow: 0 1rem 2rem rgba($color-tertiary-light,.5);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-light, .2)!important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  @include respond(phone){
    position: absolute;
    top:  9rem;
    left: -3rem;
  }

  }



  // &__seller-video-name {
  //   display: inline-block;
  //   justify-content: space-between;
  //   justify-items: center;
  //   // padding-left: 2rem;
  //   margin-top: 1rem;
  //   margin-bottom: 3rem;
  //   //  border: .1rem red solid;


  // }


  // &__seller-name-2 {
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   font-size: 1rem;
  //   color: $color-white;
  //   // justify-content: center;
  //   // justify-items: center;
  //   padding: 0 1rem;
  //   position: absolute;
  //   top:2%;
  //   left: 5%;
  //   // margin-top: 50%;
  //   // border: 1rem red solid;
  // }

  // &__add-to-cart {
  //   display: flex;
  //   justify-content: center;
  //   font-size: 1.5rem;
  //   color: $color-white;
  //   // justify-content: center;
  //   // justify-items: center;
  //   // padding: 0 1rem;
  //   position: absolute;
  //   top:50%;
  //   left: 40%;
  //   margin-top: 1rem;
  //   // border: 1rem red solid;
  // }

  //  &__rating {
  //   // padding: 1rem ;
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   justify-items: center;
  //   font-size: 1.5rem;
  //   text-align: center;
  //   position: absolute;
  //   top: 1%;
  //   // left: 50%;

  //   &-showAverage {
  //     width: 100%;
  //     display: flex;
  //     // justify-content: center;
  //     // justify-items: center;
  //     // margin-top: 1rem;
  //     //margin-bottom: -1rem;
  //     text-align: center;
  //     text-transform: capitalize;
  //     font-size: 1.6rem;
  //     font-weight: 500;
  //     // color: rgba($color-white, 1);
  //     position: absolute;
  //     // top: 1%;
  //     left: 50%;

  //   }

  //   &-message {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     justify-items: center;
  //     margin-top: 1rem;
  //     //margin-bottom: -1rem;
  //     text-align: center;
  //     text-transform: capitalize;
  //     font-size: 1.6rem;
  //     font-weight: 500;
  //     color: rgba($color-white, 1);

  //   }
  // }
}

.card-product-shop {
  //forfunctionalilty
  display: flex;
  justify-content: space-between;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  width: 98%;
  height:35rem;
  background-color: transparent;
  border: none;
  padding: 2rem 1.5rem;
  // margin-bottom: -5rem;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  // border: .1rem red solid;
  @include respond(tab-port){
    font-size: 2.5rem !important;
  }

  @include respond(phone){
    height: 35vh;
  }



    &__side {
      height:35rem;
      // margin-top: .2rem;
      // padding-top: 0rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      // border-radius: 6px;
      // border: .1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
      @include respond(phone){
        height: 35vh;
        // border: 1rem blue solid;
      }

      &--front {
        // height: 100% !important;
        background-color: rgba($color-white, .8);
         &::after{
          width: 100%;
          // opacity: 0;
          // visibility: hidden;
        }

      }

      &--back {
        transform: rotateY(180deg);
        // border: 1rem red solid;
        height: 100%;
        width: 100% !important;
        // margin-top: 1rem;
        // margin: .1rem .1rem .1rem .1rem !important;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          // background-image: linear-gradient(to bottom,
         background-image: linear-gradient(to right,
          rgba($color-tertiary-light, .1)0%,
          rgba($color-black, 1)35%,
          rgba($color-tertiary-dark, 1)100%);
        }

      }
  }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    position: absolute;
    // top: -2%;
    //left: -.5px;
    //background-size: cover;
    //object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    object-fit: fill;
    width: 98% !important;
    height: 97% !important;
    min-height:35rem;
    max-height: 35rem;
    transition:filter .5s ;
    background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    -webkit-transition:filter .5s ;
    -moz-transition:filter .5s ;
    -ms-transition:filter .5s ;
    -o-transition:filter .5s ;
    @include respond(phone){
      width: 90vw;
      height: 34vh !important;
       max-height: 34vh;
      // border: 1rem blue solid;
    }

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    &::before {
        content: "";
        position:absolute;
        top: 4vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;

        @include respond(phone){
           top: 14vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-secondary-dark, 1);

        }
}

    &::after {
      content: "";
      position:absolute;
      top: 60%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: .1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0 !important;
      //  height: 0 !important;
      //  opacity: 0;
      //  z-index: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 54%;
    right: 5%;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 1.5rem;
    font-weight: 500;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }


    &__video-category-sub-product {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 81%;
    right: 5%;
    color: $color-white;
    background-color: rgba($color-black, .3);
    // border: .01rem white solid;
    font-size: 1.3rem;
    font-weight: 500;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    // padding-left: 5rem;
    padding: .3rem 3rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;

     &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;

    // box-shadow: 0 1rem 2rem rgba($color-tertiary-light,.5);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-light, .2)!important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  }

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: bolder !important;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .5rem;
    left:.5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
  @include respond(tab-port){
   font-size: 2.5rem !important;
  }
}

  &__heading-span {
    // width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 30rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-white, 1);
    position: absolute;
    top: 10rem;
    // left:;
    width:85%;
    z-index: 10;
    // opacity: 0;
    //  &:hover {
    //   opacity: 1;
    //   visibility: visible;
      // transition: opacity 1s 1s;
      // -webkit-transition: opacity 1s 1s;
      // -moz-transition: opacity 1s 1s;
      // -ms-transition: opacity 1s 1s;
      // -o-transition: opacity 1s 1s;
      // }

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }

  // Back side style:
  &__back-header-box{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: #182a41;
    position: absolute;
    // top: .2%;
    // padding-bottom: 5rem;
  }

  &__back-footer-box{
    width: 100%;
    height: 10%;
    color: rgba($color-white, 1);
    background-color: #232f3e;
    position: absolute;
    bottom: .2%;

  }



  &__cta {
    font-size: 1.1rem;
    // border: .1rem yellow solid;
    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:25%;
    // left: 50%;
    // transform: translate(-50%, 50%);
    // -webkit-transform: translate(-50%, -50%);
    width: 100%;
    height:64%;
    text-align: center;
    // margin-bottom: 5rem;

        &::before {
        content: "";
        position:absolute;
        top: .1vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;

        @include respond(phone){
          width: 0;
          height: 0;
          opacity: 0;
        }
        }

    &::after{
      content: "";
      position:absolute;
      top:10%;
      left:3rem;
      width: 85%;
      height: 0.01rem;
      background-color: rgba($color-white, 1);
      transition: width .5s 0;
      -webkit-transition: width .5s 0;
      -moz-transition: width .5s 0;
      -ms-transition: width .5s 0;
      -o-transition: width .5s 0;

    }

    // &:hover::after{
    //       width: 90%;
    //       transition: width .5s .1s;
    //       -webkit-transition: width .5s .1s;
    //       -moz-transition: width .5s .1s;
    //       -ms-transition: width .5s .1s;
    //       -o-transition: width .5s .1s;
    // }

  }



  &__price-box {
    // height:fit-content;
    // display: flex;
    // justify-content: center;
    // justify-items: center;
    // align-items: center;
    text-align: center;
    color: rgb(237, 237, 243);
    position: absolute;
    top: 24%;
    left: 45%;

    @include respond(phone){
      position: absolute;
      // font-size: 5rem;
      top: 5vh;
      left: 42%;
    }



    // margin-top: 11%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 2rem !important;
    font-weight: 400;

    @include respond(tab-port){
      font-size: 5rem !important;
    }
  }

  &__view-product {
    display: flex;
    width: 100% !important;
    height: fit-content;
    justify-content: space-around !important;
    font-size: 1.5rem;


    // justify-content:space-between;
    // justify-items:center;
    // border: .1rem red solid;
    padding:5rem auto;
    // padding-bottom: .5rem;
    position: absolute;
    // top: 100%;
    bottom: -3vh;
    // left: 25%;
    // margin-top: 21rem;
     @include respond(tab-port){
        font-size: 2.5rem !important;
        // top: 10vh !important;
          bottom: -2vh !important;
      }
     @include respond(phone){
        font-size: 3.5rem !important;
        top: 15vh;
          bottom: 0 !important;
      }
     @include respond(small-phone){
        font-size: 2.5rem !important;
        top: 7vh;
          bottom: 0 !important;
      }


    &--video {
      display: flex;
      margin-left: 1rem;
      padding-bottom: 2rem;
      color: rgba($color-white, .9);
      z-index: 100;

      @include respond(tab-land){
        font-size: 1.3rem !important;
      }
      @include respond(tab-port){
        font-size: 3rem !important;
        // top: 10vh !important;
          // bottom: -5vh !important;
      }
      @include respond(phone){
        // font-size: rem !important;
         margin-top: 3.5rem !important;
        // margin-top: -11rem !important;
        // position: absolute;
        // top: 10vh !important;
          // bottom: 7vh !important;
      }
      @include respond(small-phone){
        font-size: 2.5rem !important;
        margin-top: -11rem !important;
        // position: absolute;
        // top: 10vh !important;
          // bottom: -7vh !important;
      }

    }

    &--view {
      display: flex;
      justify-content:center;
      margin-left: 1rem;
       margin-top: 3rem !important;
      color: rgba($color-white, .9);

      @include respond(tab-port){
         margin-top: 6rem !important;
      }
      @include respond(phone){
         margin-top: 3.5rem !important;
      }
      @include respond(small-phone){
         margin-top: 3rem !important;
      }
    }
  }



  &__seller-video-name {
    width: 100%;
    display: flex;

    position: absolute;
    top: -2%;
    right: 20%;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
//     &:hover {
//       transform: translateY(2rem);
//       -webkit-transform: translateY(2rem);
//       box-shadow: 1rem 1rem 2rem rgba($color-black, 1);
//       -moz-transform: translateY(2rem);
//       -ms-transform: translateY(2rem);
//       -o-transform: translateY(2rem);
// }
    //  border: .1rem red solid;


  }


  &__seller-name-2 {
    width: 85%;
    display: flex;
    // justify-content: center;
    font-size: 1.6rem;
    color: $color-white;
    // background-color: rgba($color-black,.1);
    justify-content: center;
    justify-items: center;
    padding: 0 1rem;
    position: absolute;
    // top:-1%;
    left: 30%;
    cursor: pointer;
    margin-top: -5%;
    // border: 1rem red solid;
//      &:hover {
//       transform: translateY(-2rem);
//       -webkit-transform: translateY(-2rem);
//       box-shadow: 0 1rem 2rem rgba($color-black, .2);
//       -moz-transform: translateY(-2rem);
//       -ms-transform: translateY(-2rem);
//       -o-transform: translateY(-2rem);
// }
    @include respond(tab-port){
      font-size: 2.5rem !important;
    }

    @include respond(phone){
      font-size: 3rem !important;
      margin-top: 5% !important;
      left: 25%;
      // text-align: center !important;
    }
  }

  &__add-to-cart {
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 60%;
    font-size: 1.5rem;
    color: $color-secondary-dark;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    position: absolute;
    top: 18vh;
    left:40%;

     @include respond(phone){
       font-size: 3.2rem;
      top: 14vh;
       width: 60%;
       left:40%;
    }
    @include respond(small-phone){
       font-size: 2.7rem;
      top: 6vh;
       width: 60%;
       left:40%;
    }


    &--home{
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 40%;
    font-size: 1.7rem;
    font-weight: 700 !important;
    color: $color-secondary-dark !important;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    position: absolute;
    top: 17vh;
    left:30%;
    z-index: 10000;
    cursor: pointer !important;

    @include respond(tab-land){
      // font-size: 1.4rem;
      top: 20vh;
       width: 60%;
       left:20%;
    }
    @include respond(tab-port){
       font-size: 2.7rem;
      top: 10.5vh;
       width: 60%;
       left:20%;
    }
    @include respond(phone){
       font-size: 2.7rem;
      top: 7vh;
       width: 60%;
       left:20%;
    }
    @include respond(small-phone){
       font-size: 2.7rem;
      top: 6vh;
       width: 60%;
       left:20%;
    }
    }
  }

  &__product-color {
    display: flex;
    width: 50%;
    height: fit-content;
    justify-content:space-around;
    justify-items: center;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .05);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 9vh;
    left: 0%;
    // margin-top: 1rem;
    // border: 1rem red solid;
    @include respond(tab-land){
       top: 11vh;
      //  left: 1%;
       margin-right: 2rem !important;
    }
    @include respond(tab-port){
       top: 5vh;
      //  left: 1%;
      font-size: 2.5rem !important;
       margin-right: 2rem !important;
    }
    @include respond(phone){
      position: absolute;
       top: 7.5vh;
       left: -5%;
      font-size: 3rem !important;
       margin-right: 2rem !important;
    }
    @include respond(small-phone){
      position: absolute;
       top: 1vh;
       left: -5%;
      font-size: 2.5rem !important;
       margin-right: 2rem !important;
    }
  }

  &__product-size {
    display: flex;
     width: 50%;
     height: fit-content;
     justify-content: space-around;
    justify-items: space-around;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .05);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 9vh;
    left: 52%;
    // margin-top: 1rem;
    // border: .1rem yellow solid;

    @include respond(tab-land){
       width: fit-content;
       margin-left: 2rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 11vh;
      //  left: 51%;
    }
    @include respond(tab-port){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 2.5rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 5vh;
       left: 60%;
    }
    @include respond(phone){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 3rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 7.5vh;
       left: 62%;
    }
    @include respond(small-phone){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 2.5rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 1vh;
       left: 62%;
    }
  }

   &__rating {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    // padding: 25% auto !important;
    padding-top: 1rem !important;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    // top: -10%;
    // border: .1rem yellow solid;
    // margin-bottom: 1rem;
    // left: 50%;
    @include respond(phone){
      // left: 50%;
      padding-top: 2rem !important;
      // padding-right: 2rem !important;
    }

    &-showAverage {
      width: 100%;
      display: flex;
      // justify-content: center !important;
      // justify-items: center !important;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      //  background-color: wheat;
      // border: .1rem yellow solid;
      position: absolute;
      // top: 1%;
      left: 50%;
      // padding: 1rem !important;

      @include respond(phone){
        font-size: .02rem !important;
        left: 30%;
      }


    }

    &-message {
      width: 100%;
      display: flex;
      justify-content: center !important;
      justify-items: center !important;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      color: rgba($color-white, 1);

      @include respond(phone){
        font-size: 3.5rem;
        padding-top: 2rem;
        // border: .2rem white solid;
          justify-content: center !important;
      justify-items: center !important;
      }

    }
  }
}

.card-product-related {
  //forfunctionalilty
  display: flex;
  justify-content: space-between;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  width: 98%;
  height:35rem;
  background-color: transparent;
  border: none;
  padding: 2rem 1.5rem;
  // margin-bottom: -5rem;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  // border: .1rem red solid;
  @include respond(tab-port){
    font-size: 2.5rem !important;
  }

  @include respond(phone){
    height: 35vh;
  }



    &__side {
      height:35rem;
      // margin-top: .2rem;
      // padding-top: 0rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      // border-radius: 6px;
      // border: .1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
      @include respond(phone){
        height: 35vh;
        // border: 1rem blue solid;
      }

      &--front {
        // height: 100% !important;
        background-color: rgba($color-white, .8);
         &::after{
          width: 100%;
          // opacity: 0;
          // visibility: hidden;
        }

      }

      &--back {
        transform: rotateY(180deg);
        // border: 1rem red solid;
        height: 100%;
        width: 100% !important;
        // margin-top: 1rem;
        // margin: .1rem .1rem .1rem .1rem !important;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          // background-image: linear-gradient(to bottom,
         background-image: linear-gradient(to right,
          rgba($color-tertiary-light, .1)0%,
          rgba($color-black, 1)35%,
          rgba($color-tertiary-dark, 1)100%);
        }

      }
  }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    position: absolute;
    // top: -2%;
    //left: -.5px;
    //background-size: cover;
    //object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    object-fit: fill;
    width: 98% !important;
    height: 97% !important;
    min-height:35rem;
    max-height: 35rem;
    transition:filter .5s ;
    background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    -webkit-transition:filter .5s ;
    -moz-transition:filter .5s ;
    -ms-transition:filter .5s ;
    -o-transition:filter .5s ;
    @include respond(phone){
      width: 90vw;
      height: 34vh !important;
       max-height: 34vh;
      // border: 1rem blue solid;
    }

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    &::before {
        content: "";
        position:absolute;
        top: 4vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;

        @include respond(phone){
           top: 14vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-secondary-dark, 1);

        }
}

    &::after {
      content: "";
      position:absolute;
      top: 60%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: .1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0 !important;
      //  height: 0 !important;
      //  opacity: 0;
      //  z-index: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 54%;
    right: 5%;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 1.5rem;
    font-weight: 500;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;

    @include respond(phone){
      font-size: 3rem;
      right: 0%;
      padding-left: 15rem;
    }
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }


    &__video-category-sub-product {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 81%;
    right: 5%;
    color: $color-white;
    background-color: rgba($color-black, .3);
    // border: .01rem white solid;
    font-size: 1.3rem;
    font-weight: 500;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    // padding-left: 5rem;
    padding: .3rem 3rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;

     &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;

    // box-shadow: 0 1rem 2rem rgba($color-tertiary-light,.5);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-light, .2)!important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  }

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    // font-size: 1.6rem;
    font-weight: bolder !important;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .5rem;
    left:.5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
  @include respond(tab-port){
  //  font-size: 2.5rem !important;
  }
  @include respond(phone){
    top: 3rem;
  //  font-size: 15rem !important;
  //  border: white .2rem solid;
  }
}

  &__heading-span {
    // width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 30rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-white, 1);
    position: absolute;
    top: 10rem;
    // left:;
    width:85%;
    z-index: 10;
    // opacity: 0;
    //  &:hover {
    //   opacity: 1;
    //   visibility: visible;
      // transition: opacity 1s 1s;
      // -webkit-transition: opacity 1s 1s;
      // -moz-transition: opacity 1s 1s;
      // -ms-transition: opacity 1s 1s;
      // -o-transition: opacity 1s 1s;
      // }

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }

  // Back side style:
  &__back-header-box{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: #182a41;
    position: absolute;
    // top: .2%;
    // padding-bottom: 5rem;
  }

  &__back-footer-box{
    width: 100%;
    height: 10%;
    color: rgba($color-white, 1);
    background-color: #232f3e;
    position: absolute;
    bottom: .2%;

  }



  &__cta {
    font-size: 1.1rem;
    // border: .1rem yellow solid;
    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:25%;
    // left: 50%;
    // transform: translate(-50%, 50%);
    // -webkit-transform: translate(-50%, -50%);
    width: 100%;
    height:64%;
    text-align: center;
    // margin-bottom: 5rem;

        &::before {
        content: "";
        position:absolute;
        top: .1vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;

        @include respond(phone){
          width: 0;
          height: 0;
          opacity: 0;
        }
        }

    &::after{
      content: "";
      position:absolute;
      top: 18%;
      left:3rem;
      width: 85%;
      height: 0.01rem;
      background-color: rgba($color-white, 1);
      transition: width .5s 0;
      -webkit-transition: width .5s 0;
      -moz-transition: width .5s 0;
      -ms-transition: width .5s 0;
      -o-transition: width .5s 0;

    }

    // &:hover::after{
    //       width: 90%;
    //       transition: width .5s .1s;
    //       -webkit-transition: width .5s .1s;
    //       -moz-transition: width .5s .1s;
    //       -ms-transition: width .5s .1s;
    //       -o-transition: width .5s .1s;
    // }

  }



  &__price-box {
    // height:fit-content;
    // display: flex;
    // justify-content: center;
    // justify-items: center;
    // align-items: center;
    text-align: center;
    color: rgb(237, 237, 243);
    position: absolute;
    top: 30%;
    left: 45%;

    @include respond(phone){
      position: absolute;
      // font-size: 5rem;
      top: 5vh;
      left: 42%;
    }



    // margin-top: 11%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }
      &__related-product-seller-name {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: .5rem auto;
    // padding-right: 10rem !important;
    margin-top: -.5rem;
    color: rgba($color-white, 1);
   background-color: rgba($color-white, .5);
    // margin-left: auto;
    margin-bottom: 3rem;
    //  border: .1rem red solid;
     position: absolute;
    // right: 20%;
    cursor: pointer !important;

    @include respond(phone){
      margin-top: 5rem;
    }

  }

  &__price-value {
    font-size: 2rem !important;
    font-weight: 400;

    @include respond(tab-port){
      font-size: 5rem !important;
    }
  }

  &__view-product {
    display: flex;
    width: 100% !important;
    height: fit-content;
    justify-content: space-around !important;
    font-size: 1.5rem;


    // justify-content:space-between;
    // justify-items:center;
    // border: .1rem red solid;
    padding:5rem auto;
    // padding-bottom: .5rem;
    position: absolute;
    // top: 100%;
    bottom: -3vh;
    // left: 25%;
    // margin-top: 21rem;
     @include respond(tab-port){
        font-size: 2.5rem !important;
        // top: 10vh !important;
          bottom: -2vh !important;
      }
     @include respond(phone){
        font-size: 3.5rem !important;
        // top: 20vh;
        //   bottom: 0 !important;
      }
     @include respond(small-phone){
        font-size: 2.5rem !important;
        top: 7vh;
          bottom: 0 !important;
      }


    &--video {
      display: flex;
      margin-left: 1rem;
      padding-bottom: 5rem;
      color: rgba($color-white, .9);
      z-index: 100;

      @include respond(tab-land){
        font-size: 1.3rem !important;
      }
      @include respond(tab-port){
        font-size: 3rem !important;
        // top: 10vh !important;
          // bottom: -5vh !important;
      }
      @include respond(phone){
        font-size: 4rem !important;
        //  margin-top: 3.5rem !important;
        // margin-top: -11rem !important;
        // position: absolute;
        // top: 10vh !important;
          // bottom: 7vh !important;
          // padding-bottom: 5rem;
      }

      @include respond(small-phone){
        font-size: 2.5rem !important;
        margin-top: -11rem !important;
        // position: absolute;
        // top: 10vh !important;
          // bottom: -7vh !important;
      }

    }

    &--view {
      display: flex;
      justify-content:center;
      margin-left: 1rem;
       margin-top: 3rem !important;

      color: rgba($color-white, .9);

      @include respond(tab-port){
         margin-top: 6rem !important;
      }
      @include respond(phone){
         margin-top: 3.5rem !important;
         padding-bottom: 5rem;
      }
      @include respond(small-phone){
         margin-top: 3rem !important;
      }
    }
  }



  &__seller-video-name {
    width: 100%;
    display: flex;

    position: absolute;
    top: -2%;
    right: 20%;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
//     &:hover {
//       transform: translateY(2rem);
//       -webkit-transform: translateY(2rem);
//       box-shadow: 1rem 1rem 2rem rgba($color-black, 1);
//       -moz-transform: translateY(2rem);
//       -ms-transform: translateY(2rem);
//       -o-transform: translateY(2rem);
// }
    //  border: .1rem red solid;


  }


  &__seller-name-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
    color: $color-white;
    // justify-content: center;
    // justify-items: center;
    padding: 0 1rem;
    position: absolute;
    // top:-1%;
    left: 20%;
    cursor: pointer;
    margin-top: 5%;
    // border: 1rem red solid;
//      &:hover {
//       transform: translateY(-2rem);
//       -webkit-transform: translateY(-2rem);
//       box-shadow: 0 1rem 2rem rgba($color-black, .2);
//       -moz-transform: translateY(-2rem);
//       -ms-transform: translateY(-2rem);
//       -o-transform: translateY(-2rem);
// }
    @include respond(tab-port){
      font-size: 2.5rem !important;
    }

    @include respond(phone){
     margin-top: -1rem;
      font-size: 4rem !important;
    }
  }

  &__add-to-cart {
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 60%;
    font-size: 1.5rem;
    color: $color-secondary-dark;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    position: absolute;
    top: 19vh;
    left:40%;

     @include respond(phone){
       font-size: 4rem;
      top: 14vh;
       width: 60%;
       left:40%;
    }
    @include respond(small-phone){
       font-size: 2.7rem;
      top: 6vh;
       width: 60%;
       left:40%;
    }


    &--home{
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 40%;
    font-size: 1.7rem;
    font-weight: 700 !important;
    color: $color-secondary-dark !important;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    position: absolute;
    top: 17vh;
    left:30%;
    z-index: 10000;
    cursor: pointer !important;

    @include respond(tab-land){
      // font-size: 1.4rem;
      top: 20vh;
       width: 60%;
       left:20%;
    }
    @include respond(tab-port){
       font-size: 2.7rem;
      top: 10.5vh;
       width: 60%;
       left:20%;
    }
    @include respond(phone){
       font-size: 2.7rem;
      top: 7vh;
       width: 60%;
       left:20%;
    }
    @include respond(small-phone){
       font-size: 2.7rem;
      top: 6vh;
       width: 60%;
       left:20%;
    }
    }
  }

  &__product-color {
    display: flex;
    width: 50%;
    height: fit-content;
    justify-content:space-around;
    justify-items: center;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .05);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 11vh;
    left: 0%;
    // margin-top: 1rem;
    // border: 1rem red solid;
    @include respond(tab-land){
       top: 11vh;
      //  left: 1%;
       margin-right: 2rem !important;
    }
    @include respond(tab-port){
       top: 5vh;
      //  left: 1%;
      font-size: 2.5rem !important;
       margin-right: 2rem !important;
    }
    @include respond(phone){
      position: absolute;
       top: 7.5vh;
       left: -5%;
      font-size: 3rem !important;
       margin-right: 2rem !important;
    }
    @include respond(small-phone){
      position: absolute;
       top: 1vh;
       left: -5%;
      font-size: 2.5rem !important;
       margin-right: 2rem !important;
    }
  }

  &__product-size {
    display: flex;
     width: 50%;
     height: fit-content;
     justify-content: space-around;
    justify-items: space-around;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .05);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 11vh;
    left: 52%;
    // margin-top: 1rem;
    // border: .1rem yellow solid;

    @include respond(tab-land){
       width: fit-content;
       margin-left: 2rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 11vh;
      //  left: 51%;
    }
    @include respond(tab-port){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 2.5rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 5vh;
       left: 60%;
    }
    @include respond(phone){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 3rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 7.5vh;
       left: 62%;
    }
    @include respond(small-phone){
       width: fit-content;
      //  margin-left: 2rem !important;
       font-size: 2.5rem !important;
      //  padding: 0 .5rem;
      //  border: .1rem yellow solid;
       top: 1vh;
       left: 62%;
    }
  }

   &__rating {
    padding: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    top: -10%;
    // border: .1rem yellow solid;
    // margin-bottom: 1rem;
    // left: 50%;

    &-showAverage {
      width: 100%;
      display: flex;
      justify-content: center !important;
      justify-items: center !important;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      padding-top: 1rem;
       padding-left: 8rem;
      //  background-color: wheat;
      // border: .1rem yellow solid;
      // position: absolute;
      // top: 1%;
      // left: 50%;
      // padding: 1rem !important;

      @include respond(phone){
        display: flex;
        // top:5%;
      // justify-content: center !important;
      // justify-items: center !important;
        // left: 75% !important;
         font-size: 3rem;
         padding-top: 5rem;
         padding-left: 10rem;

        // padding: 5rem auto;
      }


    }

    &-message {
      width: 100%;
      display: flex;
      justify-content: center !important;
      justify-items: center !important;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      color: rgba($color-white, 1);

      @include respond(phone){
        font-size: 3.5rem;
        padding-top: 5rem;
        // border: .2rem white solid;
          justify-content: center !important;
      justify-items: center !important;
      }

    }
  }
}

.card-product-video {
  //forfunctionalilty
  display: flex;
  justify-content: space-between;
  // perspective: 150rem;
  // -moz-perspective: 150rem;
  position: relative;
  width: 98%;
  height:20rem;
  background-color: transparent;
  border: none;
  padding: 1rem;
  margin-bottom: -5rem;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  // border: .1rem red solid;




    &__side {
      height:35rem;
      // margin-top: .2rem;
      // padding-top: 0rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      // border-radius: 6px;
      // border: .1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      &--front {
        // height: 100% !important;
        background-color: rgba($color-white, 1);
         &::after{
          width: 100%;
          // opacity: 0;
          // visibility: hidden;
        }

      }

      &--back {
        transform: rotateY(180deg);
        // border: 1rem red solid;
        // height: 32rem;
        // margin-top: -.2rem;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          // background-image: linear-gradient(to bottom,
         background-image: linear-gradient(to right,
          rgba($color-tertiary-light, .1)0%,
          rgba($color-black, 1)35%,
          rgba($color-tertiary-dark, 1)100%);
        }

      }
  }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    position: absolute;
    // top: -2%;
    //left: -.5px;
    //background-size: cover;
    //object-fit:100%;
    //overflow: hidden;
    // width:26.2rem;
    object-fit: fill;
    width: 98%;
    height: 97% !important;
    min-height:35rem;
    max-height: 35rem;
    transition:filter .5s ;
    background-blend-mode:color-burn;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    -webkit-transition:filter .5s ;
    -moz-transition:filter .5s ;
    -ms-transition:filter .5s ;
    -o-transition:filter .5s ;

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    &::before {
        content: "";
        position:absolute;
        top: 4vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
}

    &::after {
      content: "";
      position:absolute;
      top: 60%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 54%;
    right: 5%;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 1.5rem;
    font-weight: 500;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;
    &__seller-name-1:hover {
      width: 0;
      opacity: 0;
      transition: width opacity .5s 0;
      // filter: blur(.2rem);
      //-webkit-filter: blur(.2rem);
      -webkit-transition: width opacity .5s 0;
      -moz-transition: width opacity .5s 0;
      -ms-transition: width opacity .5s 0;
      -o-transition: width opacity .5s 0;
    }
  }


    &__video-category-sub-product {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 81%;
    right: 5%;
    color: $color-white;
    background-color: rgba($color-black, .3);
    // border: .01rem white solid;
    font-size: 1.3rem;
    font-weight: 500;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    // padding-left: 5rem;
    padding: .3rem 3rem;
    transition:width .5s;
    -webkit-transition:width .5s;
    -moz-transition:width .5s;
    -ms-transition:width .5s;
    -o-transition:width .5s;
    //z-index: 10;

     &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;

    // box-shadow: 0 1rem 2rem rgba($color-tertiary-light,.5);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-light, .2)!important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  }

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    position: absolute;
    top: .5rem;
    left:.5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
}

  &__heading-span {
    // width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 30rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-white, 1);
    position: absolute;
    top: 10rem;
    // left:;
    width:85%;
    z-index: 10;
    // opacity: 0;
    //  &:hover {
    //   opacity: 1;
    //   visibility: visible;
      // transition: opacity 1s 1s;
      // -webkit-transition: opacity 1s 1s;
      // -moz-transition: opacity 1s 1s;
      // -ms-transition: opacity 1s 1s;
      // -o-transition: opacity 1s 1s;
      // }

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }

  // Back side style:
  &__back-header-box{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: #182a41;
    position: absolute;
    // top: .2%;
    // padding-bottom: 5rem;
  }

  &__back-footer-box{
    width: 100%;
    height: 10%;
    color: rgba($color-white, 1);
    background-color: #232f3e;
    position: absolute;
    bottom: .2%;

  }



  &__cta {
    font-size: 1.1rem;
    // border: .1rem yellow solid;
    display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:25%;
    // left: 50%;
    // transform: translate(-50%, 50%);
    // -webkit-transform: translate(-50%, -50%);
    width: 100%;
    height:64%;
    text-align: center;
    // margin-bottom: 5rem;

        &::before {
        content: "";
        position:absolute;
        top: 1vw;
        left: 90%;
        width: 0.1rem;
        height: 25%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
        }

    &::after{
      content: "";
      position:absolute;
      top: 18%;
      left:3rem;
      width: 85%;
      height: 0.01rem;
      background-color: rgba($color-white, .7);
      transition: width .5s 0;
      -webkit-transition: width .5s 0;
      -moz-transition: width .5s 0;
      -ms-transition: width .5s 0;
      -o-transition: width .5s 0;

    }

    // &:hover::after{
    //       width: 90%;
    //       transition: width .5s .1s;
    //       -webkit-transition: width .5s .1s;
    //       -moz-transition: width .5s .1s;
    //       -ms-transition: width .5s .1s;
    //       -o-transition: width .5s .1s;
    // }

  }



  &__price-box {
    // height:fit-content;
    // display: flex;
    // justify-content: center;
    // justify-items: center;
    // align-items: center;
    text-align: center;
    color: rgb(237, 237, 243);
    position: absolute;
    top: 24%;
    left: 45%;

    // margin-top: 11%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 2rem;
    font-weight: 400;
  }

  &__view-product {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: space-around;
    font-size: 1.5rem;


    // justify-content:space-between;
    // justify-items:center;
    // border: .1rem red solid;
    padding:5rem auto;
    // padding-bottom: .5rem;
    position: absolute;
    // top: 100%;
    bottom: -3vh;
    // left: 25%;
    // margin-top: 21rem;

    &--video {
      display: flex;
      margin-left: 1rem;
      padding-bottom: 2rem;
      color: rgba($color-white, .9);
      z-index: 100;

    }

    &--view {
      display: flex;
      justify-content:center;
      margin-left: 1rem;
      color: rgba($color-white, .9);
    }
  }



  &__seller-video-name {
    width: 100%;
    display: flex;

    position: absolute;
    top: -2%;
    right: 20%;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
//     &:hover {
//       transform: translateY(2rem);
//       -webkit-transform: translateY(2rem);
//       box-shadow: 1rem 1rem 2rem rgba($color-black, 1);
//       -moz-transform: translateY(2rem);
//       -ms-transform: translateY(2rem);
//       -o-transform: translateY(2rem);
// }
    //  border: .1rem red solid;


  }


  &__seller-name-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $color-white;
    // justify-content: center;
    // justify-items: center;
    padding: 0 1rem;
    position: absolute;
    // top:-1%;
    left: 20%;
    cursor: pointer;
    // margin-top: 50%;
    // border: 1rem red solid;
//      &:hover {
//       transform: translateY(-2rem);
//       -webkit-transform: translateY(-2rem);
//       box-shadow: 0 1rem 2rem rgba($color-black, .2);
//       -moz-transform: translateY(-2rem);
//       -ms-transform: translateY(-2rem);
//       -o-transform: translateY(-2rem);
// }
  }

  &__add-to-cart {
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 60%;
    font-size: 1.5rem;
    color: $color-secondary-dark;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    position: absolute;
    top: 16vh;
    left:40%;
    &--home{
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    width: 60%;
    font-size: 1.5rem;
    color: $color-secondary-dark;
    border-bottom: .2rem  rgba($color-white, .2) solid;
    position: absolute;
    top: 17vh;
    left:40%;
    z-index: 10000;
    cursor: pointer;
    }
  }

  &__product-color {
    display: flex;
    width: 50%;
    height: fit-content;
    justify-content:space-around;
    justify-items: center;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .2);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 9vh;
    left: 0%;
    // margin-top: 1rem;
    // border: 1rem red solid;
  }

  &__product-size {
    display: flex;
     width: 50%;
     height: fit-content;
     justify-content: space-around;
    justify-items: space-around;
    font-size: 1.5rem;
    color: $color-white;
    background-color: rgba($color-black, .2);
    // box-shadow: .0 .1rem .1rem rgba($color-white, .5);
    // justify-content: center;
    // justify-items: center;
    padding-bottom: .5rem;
    position: absolute;
    top: 9vh;
    right: 0%;
    // margin-top: 1rem;
    // border: .1rem yellow solid;
  }

   &__rating {
    padding: 0 2rem;
    width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    top: -10%;
    // border: .1rem yellow solid;
    // margin-bottom: 1rem;
    // left: 50%;

    &-showAverage {
      width: 100%;
      display: flex;
      // justify-content: center !important;
      // justify-items: center !important;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      //  background-color: wheat;
      // border: .1rem yellow solid;
      position: absolute;
      // top: 1%;
      left: 50%;
      // padding: 1rem !important;


    }

    &-message {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      // margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      color: rgba($color-white, 1);

    }
  }
}

.card-shop-sub-category {
  //forfunctionalilty
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  // width: 80%;
  height:80%;
  background-color: transparent;
  border: none;
  // margin-bottom: 30rem;
  // overflow: hidden;
  // box-shadow: 0 1rem 1.5rem rgba($color-black, .2) ;
  border: .1rem green solid;




    &__side {
      height:28rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width:100%;
      backface-visibility: hidden;
      // border-radius: 6px;
      // border: .1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      &--front {
        background-color: rgba($color-white, 1);
         &::after{
          width: 90%;
          // opacity: 0;
          // visibility: hidden;
        }

      }

      &--back {
        transform: rotateY(180deg);
        // border: .1rem red solid;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          // background-image: linear-gradient(to bottom,
         background-image: linear-gradient(to bottom,
          rgba($color-tertiary-light, .1)0%,
          rgba($color-black, 1)35%,
          rgba($color-tertiary-dark, 1)100%);
        }

      }
  }

  // &:hover &__side--front  {
  //   transform: rotateY(-180deg);
    // filter: blur(0.5rem);
    // opacity: 0.5;
    // transition: all .5s;
    // -webkit-filter: blur(0.8rem);
    // -webkit-transition: all .5s;
    // -moz-transition: all .5s;
    // -ms-transition: all .5s;
    // -o-transition: all .5s;
    // -webkit-filter: blur(0.5rem);
// }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    position: absolute;
    top: 0;
    //overflow: hidden;
    width:26.2rem;
    min-height:35rem;
    max-height: 35rem;
    transition:filter .5s ;
    background-blend-mode:color-burn;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    //border-top-left-radius: 1px;
    //border-top-right-radius: 1px;
    //&--1:;
    //background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
        // :;
    -webkit-transition:filter .5s ;
    -moz-transition:filter .5s ;
    -ms-transition:filter .5s ;
    -o-transition:filter .5s ;

    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }

    // &:hover {
    //   filter: blur(0.5rem);
    //   -webkit-filter: blur(0.5rem);
    //   opacity: 0.5;
    //   transform:scale(1.1);
    //   transition: filter 25s;
    //   -webkit-transition: filter 25s;
    //   -moz-transition: filter 25s;
    //   -ms-transition: filter 25s;
    //   -o-transition: filter 25s;
    // }


    &::before {
        content: "";
        position:absolute;
        top: 4vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .5);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
}

    &::after {
      content: "";
      position:absolute;
      top: 60%;
      left:2rem;
      width: 90%;
      opacity: 1;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
       box-shadow: .3rem .3rem .5rem rgba($color-black, 1);
      transition: width .4s ;
      z-index: 10;
      -webkit-transition: width .4s ;
      -moz-transition: width .4s ;
      -ms-transition: width .4s ;
      -o-transition: width .4s ;
}

     &:hover::after{
       width: 0;
       transition: width .5s 0;
       -webkit-transition: width .5s 0;
       -moz-transition: width .5s 0;
       -ms-transition: width .5s 0;
       -o-transition: width .5s 0;
     }

    }


    &__seller-name-1 {
    width: 75%;
    position: absolute;
    top: 50%;
    left: 5rem;
    color: $color-white;
    background-color: rgba($color-black, .1);
    font-size: 2rem;
    font-weight: 700;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;
    transition: width .5s;
    // z-index: 10;
    -webkit-transition: width .5s;
    -moz-transition: width .5s;
    -ms-transition: width .5s;
    -o-transition: width .5s;
}

  &__heading {
    // margin-top: 30%;
    //padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    // color: rgb(14, 81, 189);
    //font-weight: bold;
    // position: absolute;
    // top: -5rem;
    // left: 5rem;
    width:60%;
    // opacity: 0;
    transition: width 0.3s;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
//     &:hover {
//       opacity: 1;
//       width: 100%;
//       z-index: 10;
//       transition: width 1.5s 0.5s;
//       -webkit-transition: width 1.5s 0.5s;
//       -moz-transition: width 1.5s 0.5s;
//       -ms-transition: width 1.5s 0.5s;
//       -o-transition: width 1.5s 0.5s;
// }
}

  &__heading-span {
    // width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 30rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-white, 1);
    position: absolute;
    top: 10rem;
    // left:;
    width:85%;
    z-index: 10;
    // opacity: 0;
    //  &:hover {
    //   opacity: 1;
    //   visibility: visible;
      // transition: opacity 1s 1s;
      // -webkit-transition: opacity 1s 1s;
      // -moz-transition: opacity 1s 1s;
      // -ms-transition: opacity 1s 1s;
      // -o-transition: opacity 1s 1s;
      // }

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }

  // Back side style:
  &__back-header-box{
    width: 100%;
    height: 25%;
    background-color: #182a41;
    position: absolute;
  }

  &__back-footer-box{
    width: 100%;
    height: 15%;
    color: rgba($color-white, 1);
    background-color: #232f3e;
    position: absolute;
    bottom: .2%;
  }



  &__cta {
    font-size: 1.1rem;

    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:25%;
    // left: 50%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    height: 59%;
    text-align: center;
    // margin-bottom: 5rem;

       &::before {
        content: "";
        position:absolute;
        top: 2vw;
        left: 90%;
        width: 0.1rem;
        height: 50%;
        background-color: rgba($color-white, 1);
        box-shadow: .3rem .3rem .5rem rgba($color-black, .2);
        transition: height .3s;
        z-index: 10;
        -webkit-transition: height .3s;
        -moz-transition: height .3s;
        -ms-transition: height .3s;
        -o-transition: height .3s;
        }

    &::after{
      content: "";
      position:absolute;
      top: 50%;
      left:2rem;
      width: 90%;
      height: 0.1rem;
      background-color: rgba($color-white, 1);
      transition: width .5s 0;
      -webkit-transition: width .5s 0;
      -moz-transition: width .5s 0;
      -ms-transition: width .5s 0;
      -o-transition: width .5s 0;

    }

    // &:hover::after{
    //       width: 90%;
    //       transition: width .5s .5s;
    //       -webkit-transition: width .5s .5s;
    //       -moz-transition: width .5s .5s;
    //       -ms-transition: width .5s .5s;
    //       -o-transition: width .5s .5s;
    // }

  }



  &__price-box {
   align-items: center;
    text-align: center;
    color: rgb(237, 237, 243);
    position: absolute;
    top: 2%;
    left: 40%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 2rem;
    font-weight: 400;
  }

  &__view-product {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: space-evenly;
    font-size: 1.5rem;
    padding:5rem auto;
    position: absolute;
    bottom: 5%;


    &--video {
      display: inline-block;
      margin-left: 1rem;
      color: $color-green-yellow;

    }

    &--view {
      display: inline-block;
      margin-left: 5rem;
      color: $color-green-yellow;
    }
  }



  &__seller-video-name {
    display: inline-block;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;


  }


  &__seller-name-2 {
     width: 100%;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: $color-white;
    padding: 0 1rem;
    position: absolute;
    top:25%;
    left: 5%;
  }

   &__add-to-cart {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $color-white;
    position: absolute;
    top:50%;
    left: 40%;
    margin-top: 1rem;
    // border: 1rem red solid;
  }

   &__rating {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    top: 1%;
    // left: 50%;


    &-showAverage {
      width: 100%;
      display: flex;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      // color: rgba($color-white, 1);
      position: absolute;
      // top: 1%;
      left: 50%;

    }
     &-message {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      margin-top: 1rem;
      //margin-bottom: -1rem;
      text-align: center;
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 500;
      color: rgba($color-white, 1);

    }
  }
}

.card-seller-videos-new-arrivals {
  //forfunctionalilty
  // perspective: 150rem;
  // -moz-perspective: 150rem;
  position: relative;
  // height: 30rem!important;
  background-color: rgba($color-white, .8);
  border: none;
  // margin-bottom: 5rem;
  object-fit: fill !important;
// border: 1rem red solid;
  // overflow: hidden;
  box-shadow: 0 1rem 1.5rem rgba($color-white, .5) ;
  @include respond(tab-port){
  // border: 1rem red solid;
    width: 35vw !important;
    height: 37rem !important;
    margin: 1rem !important;
    // padding-bottom: 1rem !important;
    //  border: 1rem red solid;
  }
  @include respond(tab-700){
  // border: 1rem red solid;
    width: 35vw !important;
    height: 37rem !important;
    margin: 1rem !important;
    // padding-bottom: 1rem !important;
    //  border: 1rem red solid;
  }
  @include respond(phone){
    // border: 1rem rgba($color-Greenprimary-light, 1) solid;
    width: 38vw !important;
    height: 30rem !important;
    // margin: 1rem !important;
    // padding-bottom: 1rem !important;
  }
  @include respond(phone-500){
  // border: 1rem rgba($color-green-yellow, 1) solid;
    width: 38vw !important;
    height: 100% !important;
    // margin: 1rem !important;
    // padding-bottom: 1rem !important;
  }
  @include respond(phone-400){
    width: 37vw !important;
    height: 100% !important;
    //  border: 1rem rgba($color-Greenprimary-dark, 1) solid;
    // margin: 1rem !important;
    // padding-bottom: 1rem !important;
  }
  @include respond(small-phone){
  // border: 1rem rgba($color-secondary-light, 1) solid;
    width: 37vw !important;
    height:100% !important;
    //  border: 1rem rgba($color-Greenprimary-dark, 1) solid;
    // margin: 1rem !important;
    // padding-bottom: 1rem !important;
  }


  //   &__side {
  //     // height:35rem;
  //     transition: all .8s ease;
  //     -webkit-transition: all .8s ease;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100% !important;
  //     backface-visibility: hidden;
  //     border-radius: 6px;
  //     // border: 1rem red solid;
  //     overflow: hidden;
  //     // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

  //     &--front {
  //       background-color: rgba($color-white, 1);
  //       // border: .1rem red solid;
  //     }

  //     &--back {
  //       transform: rotateY(180deg);
  //       // border: .1rem red solid;

  //       &-1 {
  //         background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
  //       }
  //       &-2 {
  //         background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
  //       }
  //       &-3 {
  //         background-image: linear-gradient(to right, rgba($color-tertiary-light, .7), rgba($color-tertiary-dark, 1));
  //       }

  //     }
  // }

  // &:hover &__side--front {
  //   transform: rotateY(-180deg);
  // }

  // &:hover &__side--back {
  //   transform: rotateY(0);
  // }

  //Front Side Styling
  &__picture {
    // position: absolute;
    //   top: -.8px;
    //   left: -.5px;
    background-size: cover;
    overflow: hidden;
    width:100% !important;
    height: 18rem !important;
    object-fit: fill !important;
    //  border: .1rem red solid;

     @include respond(tab-port){
      object-fit: fill !important;
      width: 100% !important;
      height: 37rem !important;
      // border: .1rem red solid;
     }
     @include respond(phone){
      object-fit: fill !important;
      width: 100% !important;
      min-height: 30rem !important;
      max-height: 31rem;

     }
     @include respond(phone-500){
      object-fit: fill !important;
      width: 100% !important;
      min-height: 100% !important;
      // max-height: 31rem;
    //    width:100% !important;
    // height: 18rem !important;
    // object-fit: fill !important;

     }
    // background-blend-mode: screen;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // border-top-left-radius: 1px;
    // border-top-right-radius: 1px;

    // &--1 {
    //   background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
    // }
    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }
  }

  &__heading {
    margin-top: 0;
    // padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    color: rgb(14, 81, 189);
    //  font-weight: bold;
    position: absolute;
    top: 0rem;
    // right:3rem;
    width:cover;
  }

  &__heading-span {
    width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  // &__details {
  //   // height: 1rem !important;
  //   // padding: 2.5rem .2rem 1rem .2rem;
  //   // margin-top: 25.5rem;
  //   font-size: 1.5rem;
  //   font-weight: 400;
  //   padding:.5rem 1rem;
  //   // font-style: italic;
  //   // font-weight: bold;
  //   // letter-spacing: .2rem;
  //   // text-transform: uppercase;
  //   // text-align: center;
  //   color:rgba($color-white, 1);
  //   position: absolute;
  //   // top: 12rem;
  //   // left:;
  //   // width: 100%;

  //   ul {
  //     list-style: none;
  //     width: fit-content;
  //     // margin: -24rem auto auto auto;

  //     // justify-content: center;
  //     // &:not(:last-child){
  //     //   border-bottom: 1px solid $color-grey-light-2;
  //     // }
  //   }


  //   &-span-description {
  //     width:26rem !important;
  //     // height:fit-content;
  //     text-align: left;
  //     // margin: .1rem !important;
  //     // border: #060ef7 solid .1rem;
  //     // padding-left: .5rem;
  //     padding: 1rem;
  //     -webkit-box-decoration-break: clone;
  //     box-decoration-break: clone;
  //   }
  // }

  // Back side style:
  // &__cta {
  //   font-size: 1.1rem;

  //   // display: block;
  //   //  justify-content: center;
  //   //  justify-items:center;
  //   position: absolute;
  //   top:40%;
  //   left: 50%;
  //   transform: translate(-50%, 50%);
  //   -webkit-transform: translate(-50%, -50%);
  //   width: 90%;
  //   // height: fit-content;
  //   text-align: center;
  //   // margin-bottom: 5rem;
  // }

  // &__price-box {
  //   // height:fit-content;
  //   text-align: center;
  //   color: rgb(237, 237, 243);

  //   margin-top: 15%;

  //   // display:block;
  //   // justify-content:end;
  //   // margin-bottom: -2rem;
  // }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  // &__price-value {
  //   font-size: 4rem;
  //   font-weight: 100;
  // }

  // &__view-product {
  //   display: block;


  //   // justify-content:space-between;
  //   // justify-items:center;
  //   // border: 1rem red solid;
  //   padding-top: -2rem;
  //   padding-bottom: .5rem;
  //   margin-top: -1rem;

  //   &--video {
  //     display: inline-block;
  //     margin-left: 1rem;
  //     color: $color-green-yellow;

  //   }

  //   &--view {
  //     display: inline-block;
  //     margin-left: 5rem;
  //     color: $color-green-yellow;
  //   }
  // }



  // &__seller-video-name {
  //   display: inline-block;
  //   justify-content: space-between;
  //   justify-items: center;
  //   // padding-left: 2rem;
  //   margin-top: 1rem;
  //   margin-bottom: 3rem;


  // }
  // &__seller-name-1 {
  //   color: $color-green-yellow;
  //   justify-content: center;
  //   justify-items: center;
  //   padding-left: 5rem;


  // }

  // &__seller-name-2 {
  //   display: block;
  //   color: $color-green-yellow;
  //   justify-content: center;
  //   justify-items: center;
  //   padding: 0 1rem;
  //   // border: 1rem red solid;



  // }

  //  &__rating {
  //   // padding: 1rem ;
  //   font-size: 1.5rem;
  //   text-align: center;

  //   &-showAverage {
  //     margin-top: 1rem;
  //     margin-bottom: -1rem;
  //   }
  // }
}

.card-seller-podcasts-new-arrivals {
  //forfunctionalilty
  // perspective: 150rem;
  // -moz-perspective: 150rem;
  position: relative;
    // height: 30% !important;
  // width: 100%;
  // height: 100%;
  // padding: 2rem ;
  background-color: rgba($color-white, .5);
  border: none;
  object-fit: fill !important;
  // margin: 5rem auto;
  // margin-bottom: -1rem;
  // overflow: hidden;
  box-shadow: 0 1rem 1.5rem rgba($color-black, .15) ;
  // border: .1rem red solid;

   @include respond(tab-port){
  // border: 1rem red solid;
    width: 35vw !important;
    height: 30% !important;
    margin: 1rem !important;
    // padding-bottom: 1rem !important;
    //  border: 1rem red solid;
  }
  @include respond(phone){
  // border: 1rem red solid;
    width: 37vw !important;
    height: 30rem !important;
    // margin: 1rem !important;
    // padding-bottom: 1rem !important;
  }



  //   &__side {
  //     height:35rem;
  //     transition: all .8s ease;
  //     -webkit-transition: all .8s ease;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     backface-visibility: hidden;
  //     border-radius: 6px;
  //     // border: 1rem red solid;
  //     overflow: hidden;
  //     // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

  //     &--front {
  //       background-color: rgba($color-white, 1);
  //       // border: .1rem red solid;
  //     }

  //     &--back {
  //       transform: rotateY(180deg);
  //       // border: .1rem red solid;

  //       &-1 {
  //         background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
  //       }
  //       &-2 {
  //         background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
  //       }
  //       &-3 {
  //         background-image: linear-gradient(to right, rgba($color-tertiary-light, .7), rgba($color-tertiary-dark, 1));
  //       }

  //     }
  // }

  // &:hover &__side--front {
  //   transform: rotateY(-180deg);
  // }

  // &:hover &__side--back {
  //   transform: rotateY(0);
  // }

  //Front Side Styling
  &__picture {
    // position: absolute;
      // top: -.8rem;
      // left: -.5rem;
// border: .1rem red solid;

    background-size: cover;
    // box-shadow: 0 1rem 1.5rem rgba($color-black, .25) ;
    width:100% !important;
    margin: 0 auto;
    overflow: hidden;
    object-fit: fill !important;
    // padding: .1rem 2rem 3rem 2rem ;
    height: 18rem;

     @include respond(tab-port){
      object-fit: fill !important;
      width: 100% !important;
      height: 37rem !important;
     }
     @include respond(phone){
      object-fit: fill !important;
      width: 100% !important;
      height: 30rem !important;

     }
    // background-blend-mode:darken;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // border-top-left-radius: 1px;
    // border-top-right-radius: 1px;

    // &--1 {
    //   background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
    // }
    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }
  }

  &__heading {
    margin-top: 0;
    // padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    color: rgb(14, 81, 189);
    //  font-weight: bold;
    position: absolute;
    top: 0;
    left:-1rem;
    width:60%;
  }

  &__heading-span {
    width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: .5rem 1rem;
    margin-top: 22.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    // font-style: italic;
    // font-weight: bold;
    letter-spacing: .2rem;
    // background-color: rgba($color-tertiary-dark, .9) !important;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-black, 1);
    position: absolute;
    // top: 100rem;
    // left:;
    width: 80%;

    ul {
      list-style: none;
      width: 100%;
      margin: -22rem auto 5rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }



  }

  // Back side style:
  &__cta {
    font-size: 1.1rem;

    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, -50%);
    width: 90%;
    // height: fit-content;
    text-align: center;
    // margin-bottom: 5rem;
  }

  &__price-box {
    // height:fit-content;
    text-align: center;
    color: rgb(237, 237, 243);

    margin-top: 15%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 4rem;
    font-weight: 100;
  }

  &__view-product {
    display: block;


    // justify-content:space-between;
    // justify-items:center;
    // border: 1rem red solid;
    padding-top: -2rem;
    padding-bottom: .5rem;
    margin-top: -1rem;

    &--video {
      display: inline-block;
      margin-left: 1rem;
      color: $color-green-yellow;

    }

    &--view {
      display: inline-block;
      margin-left: 5rem;
      color: $color-green-yellow;
    }
  }



  &__seller-video-name {
    display: inline-block;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;


  }
  &__seller-name-1 {
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;


  }

  &__seller-name-2 {
    display: block;
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding: 0 1rem;
    // border: 1rem red solid;



  }

   &__rating {
    // padding: 1rem ;
    font-size: 1.5rem;
    text-align: center;

    &-showAverage {
      margin-top: 1rem;
      margin-bottom: -1rem;
    }
  }
}

.card-seller-single-podcast {
  //forfunctionalilty
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 100%;
  // padding: -1rem auto ;
  background-color: rgba($color-navy-dark, .4);
  border: none;
  margin: .5rem 3rem;
  // margin-bottom: -1rem;
  // overflow: hidden;
  box-shadow: 0 .5rem .5rem rgba($color-black, .1) ;
  // border: 1rem red solid;


    &__side {
      height:35rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      border-radius: 6px;
      // border: 1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      &--front {
        background-color: rgba($color-white, 1);
        // border: .1rem red solid;
      }

      &--back {
        transform: rotateY(180deg);
        // border: .1rem red solid;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          background-image: linear-gradient(to right, rgba($color-tertiary-light, .7), rgba($color-tertiary-dark, 1));
        }

      }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    // position: absolute;
      // top: -.8rem;
      // left: -.5rem;

    background-size: cover;
    // box-shadow: 0 1rem 1.5rem rgba($color-black, .25) ;
    overflow: hidden;
    width:91%;
    margin: 0 auto ;
    // height: 100%;
    // background-blend-mode:darken;
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // border-top-left-radius: 1px;
    // border-top-right-radius: 1px;

    // &--1 {
    //   background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
    // }
    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }
  }

  &__heading {
    // margin-top: 1rem;
    // padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    color: rgb(14, 81, 189);
    //  font-weight: bold;
    position: absolute;
    top: .1rem;
    left:-1rem;
    width:60%;
  }

  &__heading-span {
    width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: .5rem 1rem;
    margin-top: 22.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    // font-style: italic;
    // font-weight: bold;
    letter-spacing: .2rem;
    // background-color: rgba($color-tertiary-dark, .9) !important;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-black, 1);
    position: absolute;
    // top: 100rem;
    // left:;
    width: 80%;

    ul {
      list-style: none;
      width: 100%;
      margin: -22rem auto 5rem auto;

      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }



  }

  // Back side style:
  &__cta {
    font-size: 1.1rem;

    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, -50%);
    width: 90%;
    // height: fit-content;
    text-align: center;
    // margin-bottom: 5rem;
  }

  &__price-box {
    // height:fit-content;
    text-align: center;
    color: rgb(237, 237, 243);

    margin-top: 15%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 4rem;
    font-weight: 100;
  }

  &__view-product {
    display: block;


    // justify-content:space-between;
    // justify-items:center;
    // border: 1rem red solid;
    padding-top: -2rem;
    padding-bottom: .5rem;
    margin-top: -1rem;

    &--video {
      display: inline-block;
      margin-left: 1rem;
      color: $color-green-yellow;

    }

    &--view {
      display: inline-block;
      margin-left: 5rem;
      color: $color-green-yellow;
    }
  }



  &__seller-video-name {
    display: inline-block;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;


  }
  &__seller-name-1 {
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;


  }

  &__seller-name-2 {
    display: block;
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding: 0 1rem;
    // border: 1rem red solid;



  }

   &__rating {
    // padding: 1rem ;
    font-size: 1.5rem;
    text-align: center;

    &-showAverage {
      margin-top: 1rem;
      margin-bottom: -1rem;
    }
  }
}

.card-single-video-column {
  //forfunctionalilty
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 35rem;
  background-color: rgba($color-white, .7);
  border: none;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 1rem 1.5rem rgba($color-black, .15) ;
  // border: 1rem red solid;


    &__side {
      height:35rem;
      transition: all .8s ease;
      -webkit-transition: all .8s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      border-radius: 6px;
      // border: 1rem red solid;
      overflow: hidden;
      // box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

      &--front {
        background-color: rgba($color-white, 1);
        // border: .1rem red solid;
      }

      &--back {
        transform: rotateY(180deg);
        // border: .1rem red solid;

        &-1 {
          background-image: linear-gradient(to right, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
        }
        &-2 {
          background-image: linear-gradient(to right, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
        }
        &-3 {
          background-image: linear-gradient(to right, rgba($color-tertiary-light, .7), rgba($color-tertiary-dark, 1));
        }

      }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front Side Styling
  &__picture {
    // position: absolute;
    //   top: -.8px;
    //   left: -.5px;
    // background-size: cover;
    overflow: hidden;
    width:100%;
    height: 35rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // border-top-left-radius: 1px;
    // border-top-right-radius: 1px;

    // &--1 {
    //   background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark), url(../image/nat-5.jpg);
    // }
    // &--2 {
    //   background-image:  linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark), url(../image/nat-6.jpg);
    // }
    // &--3 {
    //   background-image:  linear-gradient(to right, $color-tertiary-light, $color-tertiary-dark), url(../image/nat-7.jpg);
    // }
  }

  &__heading {
    margin-top: 0;
    // padding-bottom: -50rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    color: rgb(14, 81, 189);
    //  font-weight: bold;
    position: absolute;
    top: 0rem;
    // right:3rem;
    width:cover;
  }

  &__heading-span {
    width: 20rem;
    padding: 0 1.5rem 0 1.5rem;
    // -webkit-box-decoration-break: clone;
    // box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .1), rgba($color-secondary-dark, .1));
    }
    &--2 {
      background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    }
    &--3 {
      background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
    }

    &--4 {
        background-image: linear-gradient(to left bottom, rgba($color-white, .9), rgba($color-white, .5));
      }
  }

  &__details {
    padding: 2.5rem .2rem 1rem .2rem;
    margin-top: 25.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    // font-style: italic;
    // font-weight: bold;
    // letter-spacing: .2rem;
    // text-transform: uppercase;
    // text-align: center;
    color:rgba($color-black, 1);
    position: absolute;
    top: 12rem;
    // left:;
    width: 100%;

    ul {
      list-style: none;
      width: 100%;
      margin: -10rem auto;
      // justify-content: center;
      // &:not(:last-child){
      //   border-bottom: 1px solid $color-grey-light-2;
      // }
    }

    &-span {
      text-align: left;
      // padding-top: 3rem;

      padding-left: .5rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // &--1 {
      //   background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
      // }
      // &--2 {
      //   background-image: linear-gradient(to right bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
      // }
      // &--3 {
      //   background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .3), rgba($color-tertiary-dark, .3));
      // }
      // &--4 {
      //   background-image: linear-gradient(to right bottom, rgba($color-black, .4), rgba($color-black, .3));
      // }
    }
  }






  // Back side style:
  &__cta {
    font-size: 1.1rem;

    // display: block;
    //  justify-content: center;
    //  justify-items:center;
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%, 50%);
    -webkit-transform: translate(-50%, -50%);
    width: 90%;
    // height: fit-content;
    text-align: center;
    // margin-bottom: 5rem;
  }

  &__price-box {
    // height:fit-content;
    text-align: center;
    color: rgb(237, 237, 243);

    margin-top: 15%;

    // display:block;
    // justify-content:end;
    // margin-bottom: -2rem;
  }

    // &__price-only {
    //   font-size: 1.4rem;
    //   text-transform: uppercase;
    // }

  &__price-value {
    font-size: 4rem;
    font-weight: 100;
  }

  &__view-product {
    display: block;


    // justify-content:space-between;
    // justify-items:center;
    // border: 1rem red solid;
    padding-top: -2rem;
    padding-bottom: .5rem;
    margin-top: -1rem;

    &--video {
      display: inline-block;
      margin-left: 1rem;
      color: $color-green-yellow;

    }

    &--view {
      display: inline-block;
      margin-left: 5rem;
      color: $color-green-yellow;
    }
  }



  &__seller-video-name {
    display: inline-block;
    justify-content: space-between;
    justify-items: center;
    // padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;


  }
  &__seller-name-1 {
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding-left: 5rem;


  }

  &__seller-name-2 {
    display: block;
    color: $color-green-yellow;
    justify-content: center;
    justify-items: center;
    padding: 0 1rem;
    // border: 1rem red solid;



  }

   &__rating {
    // padding: 1rem ;
    font-size: 1.5rem;
    text-align: center;

    &-showAverage {
      margin-top: 1rem;
      margin-bottom: -1rem;
    }
  }
}


.videoCard {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  // width: fit-content;
  // height:fit-content;
  background-color: rgba($color-navy-dark, 1);
  margin-bottom: 5rem;
  // padding-top: 1rem;
  // padding-bottom: 4rem;
  overflow: hidden;
  transition:all .2s;
  // margin-top: 8rem;
  // border: .1rem red solid;
  //&:hover ;

  // transform:scale(1.5) translateY(-.5rem);
  //     box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 1;
  // }

  &__heading-seller-video-title {
    position: absolute;
    left: 0;
    font-weight: 700;
    color: rgba($color-black, 1);
    background-color: rgba($color-white, .5);
    padding-left: 2rem;
    margin-top: -3rem;
    padding-right: 1rem;

  }

  &__details-seller-video-description {
    // position: absolute;
    // left: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem !important;
    font-weight: 500;
    padding: 1.3rem auto 1.3rem auto;
    padding-left: 1.5rem;
    color: rgba($color-grey-dark-1, .7);

    // padding: -1rem 1rem !important;
     background-color: rgba($color-tertiary-light, .1);
    margin: -1.3rem -1rem -1.2rem -1rem;
    // margin-bottom: -1rem;
    overflow: hidden;

  }


  &__play-video-add-to-cart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    height: fit-content;
    position: absolute;
    top: 50%;
    left:0;
    color: rgba($color-white, .9) !important;
    background-color: rgba($color-navy-dark, .4);
    padding-top: 1rem;
    padding-bottom: 1rem !important;
    font-size: 1.5rem;
    font-weight: 500;
    // margin-top: -5rem;
    padding-bottom: 0rem;
    width: 100%;
    text-align: center;
    z-index: 0;

    &:hover {
      transform:scale(1.1) translateY(-.5rem);
      background-color: rgba(255, 255, 255, .5);
       color: rgba($color-white, .9) !important;
      box-shadow: 0 2.5rem 4rem rgba($color-black, .2);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.5rem);
      -moz-transform:scale(1.1) translateY(-.5rem);
      -ms-transform:scale(1.1) translateY(-.5rem);
      -o-transform:scale(1.1) translateY(-.5rem);
    }
  }

  &__play-podcast-add-to-cart {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    // left:3rem;
    color: rgba($color-white, .9);
    background-color: rgba($color-white, .5);
    // padding-left: 1rem;
    // padding-right: 2rem;
    // margin-top: -8rem;
    // margin-bottom:25rem;
    // padding-bottom: 0rem;
    // width: 70%;
    font-size: 1.5rem;
    letter-spacing: .2rem;
    justify-content: center;
    justify-items: center;
    z-index: 0;

    &:hover {
      transform:scale(1.1) translateY(-.5rem);
      color: rgba($color-tertiary-light, .8);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.5rem);
      -moz-transform:scale(1.1) translateY(-.5rem);
      -ms-transform:scale(1.1) translateY(-.5rem);
      -o-transform:scale(1.1) translateY(-.5rem);
    }
  }

  &__select--podcast {
    display: flex;
    position: absolute;
    left:5;
    color: rgba($color-white, .9);
    background-color: rgba($color-black, .5);
    padding-left: 1rem;
    padding-right: 2rem;
    margin-top: -5rem;
    padding-bottom: 0rem;
    width: 90%;
    font-size: 1.5rem;
    letter-spacing: .2rem;
    justify-content: center;
    justify-items: center;
    z-index: 0;

    &:hover {
      transform:scale(1.1) translateY(-.5rem);
      color: rgba($color-tertiary-light, .8);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.5rem);
      -moz-transform:scale(1.1) translateY(-.5rem);
      -ms-transform:scale(1.1) translateY(-.5rem);
      -o-transform:scale(1.1) translateY(-.5rem);
    }
  }

  &__play-video {
    display: inline-block;
    // padding-left: 3rem;
    // border: 1rem red solid;
  }

  &__add-to-cart {
    display: inline-block;
    // border: 1rem red solid;
    padding-left: 3rem;

  }

  &__logo {
    width: fit-content;
  }

}


// Single Video: should have its own file

.single-video {
  background-image: linear-gradient(to left bottom, rgba(#060ef7, .7), rgba(#0810f7, .7));
}

.card-seller-videos-products {
  // background-color: rgba($color-navy-dark, .8);
  color: $color-white;
  // border: .1rem red solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  // margin: auto -1rem;
  padding: 1rem 0 0 2rem;


  &__height-scroll {
    height: 95rem;
    width: 100%;
    padding-bottom: 0 !important;
    overflow-y: scroll;
    overflow-x: hidden;
    // border: .1rem red solid;
  }
  &__height-post-scroll {
    height: 60vh;
    width: 100%;
    margin-top: 1.5rem;
    overflow-y: scroll;
    //  &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}

.card-seller-single-video {
  // background-color: rgba($color-navy-dark, .8);
  color: $color-white;
  // border: .1rem red solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  // margin: auto -1rem;
  padding: 1rem 0 2rem 2rem;


  &__height-scroll {
    height: 105.5rem;
    width: 100%;
    padding-bottom: 0 !important;
    overflow-y: scroll;
    overflow-x: hidden;
    // border: .1rem red solid;
  }
  &__height-post-scroll {
    height: 60vh;
    width: 100%;
    margin-top: 1.5rem;
    overflow-y: scroll;
    //  &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}


.card-reviews-content {
  background-color: rgba($color-white, .55);
  color: $color-tertiary-light;
  // text-align: left;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // justify-items: center;
  // margin: auto -1rem;
  margin-top: 2rem;
  padding:   2rem;


  &__height-scroll {
    height: 95%;
    width: 100%;
    padding-right: 1rem;
    padding-left: -1rem;
    // padding:  2rem 5rem 2rem -1rem;
    overflow: scroll;
  }
}

.card-single-video-reviews-content {
  background-color: rgba($color-tertiary-light, .05);
  color: $color-tertiary-light;
  // text-align: left;
  // border: .1rem red solid;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  // margin: auto -1rem;
   margin: 2rem auto;
  padding: 3rem 2rem  2.5rem 3rem !important;

  &__height-scroll {
    height: 50vh;
    width: 97%;
    // padding-right: 1rem;
    // padding-left: -1rem;
    // padding:  2rem 5rem 2rem -1rem;
    overflow: scroll;
  }
}

.card-single-product-reviews-content {
  width: 100% !important;
    background-color: rgba($color-tertiary-dark, .015);
    color: $color-tertiary-light;
    height: 65vh !important;
    //width:max-content;
    overflow: scroll;
    backface-visibility:hidden;
    // border: .2rem blue solid;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    //margin: auto -1rem;
    // margin: 2rem 3rem;
    //padding:   0 2.5rem !important;
    // &__height-scroll
    // -webkit-backface-visibility:hidden;

    &::-webkit-scrollbar {
      display: none;
    }
// {

    // padding: 2rem auto;
    // padding-right: -1rem;
    // padding-left: -3rem;
    // padding:  2rem 5rem 2rem -1rem;

  // }

  @include respond(phone){
    // width: 100vw !important;
    // height: 30vh !important;
    // display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    overflow: scroll;
     height: 60vh !important;
      padding-bottom: 5rem !important;
    // overflow: hidden;
    // margin-bottom: 25rem !important;
    // border: 1rem green solid;
  }
}

.carousel-buttons {
  margin: 1rem 2rem;
  justify-content: center !important;
}

.card-single-product {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
  justify-items: space-around !important;
  color: rgba($color-primary-light, 1);
  // font-size: 1rem;
  // padding: 5rem;
  background-color: rgba($color-tertiary-dark, 0.04) !important;
  box-shadow: .2rem .2rem .1rem rgba($color-black, .1);

  @include respond(phone){
    width: 100%;
    padding: 2rem;
  }

}
.card-single-video-not-rate-yet {
  // font-size: 1rem;
  // padding: 5rem;
  margin-top: 21rem !important;
  // background-color: rgba(180, 172, 252, .4);
  box-shadow: 0 .5rem 1rem rgba($color-black, .3);

}
.card-single-video {
  // font-size: 1rem;
  // padding: 5rem;
  // margin-top: 25rem !important;
  color: rgba($color-primary-light, 1);
  background-color: rgba(180, 172, 252, .05);
  box-shadow: 0 .5rem 1rem rgba($color-black, .003);

}

.card-single-video-display {
  // padding: 1rem;
  margin: -5rem auto -5rem auto;
  // padding: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba($color-white, .4);
  //  background-color: #060ef7;
  box-shadow: 0 .5rem 1rem rgba($color-black, .15);
}

.ant-card-body {
  padding: 1rem !important;
  font-size: 1rem;
  margin-bottom: 0rem;
  // background-color: #060ef7;
}

.ant-card .ant-card-actions {
  background-color: rgba($color-tertiary-dark, .02);
  // border: .1rem rgba($color-black, .1) solid;
  // margin-bottom: 1rem;
}
.list-group-item{
  font-size: 1.5rem;
  background-color: rgba($color-white, .7);
  box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include respond(phone){
    font-size: 4rem;
    padding: 5rem;
  }
  &:hover {
    transform:scale(1) translateY(-.2rem);
    color: rgba($color-tertiary-light, .8);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 .5rem .5rem rgba($color-black, .2);
    -webkit-transform:scale(1) translateY(-.2rem);
    -moz-transform:scale(1) translateY(-.2rem);
    -ms-transform:scale(1) translateY(-.2rem);
    -o-transform:scale(1) translateY(-.2rem);
  }
}

.like {
  font-size: 3rem;
  padding: .5rem;

  @include clearfix;

      margin-right: 5rem;


  // background-color: rgba($color-white, .5);
  // color: fade-in($color: #000000, $amount: 50);
}

.card-share {
  font-size: 2rem ;
  margin-top: 1rem;
  margin-bottom: .5rem;
  // margin-right: 5rem;

  color: rgba($color-tertiary-light, 1) !important;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  justify-items: center;
  padding: .25rem;

  @include clearfix;

      // margin-right: 5rem;


  // background-color: rgba($color-white, .5);
  // color: fade-in($color: #000000, $amount: 50);
}

.selectPodcast {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  // width: fit-content;
  // height:fit-content;
  background-color: rgba($color-white, 1);
  margin-bottom: 0rem;
  // padding-top: 1rem;
  padding-bottom: 4rem;
  overflow: hidden;
  transition:all .2s;
  //margin-top: 8rem;
  //border: 1rem blue solid;
  //&:hover ;

  // transform:scale(1.5) translateY(-.5rem);
  //     box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 1;
  // }
  &__select-video {
     display: flex;
    position: absolute;
    left:5;
    color: rgba($color-white, .9);
    background-color: rgba($color-black, .5);
    padding-left: 1rem;
    padding-right: 2rem;
    margin-top: -5rem;
    padding-bottom: 0rem;
    width: 90%;
    font-size: 1.5rem;
    letter-spacing: .2rem;
    justify-content: center;
    justify-items: center;
    z-index: 0;

    &:hover {
      transform:scale(1.1) translateY(-.5rem);
      color: rgba($color-tertiary-light, .8);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.5rem);
      -moz-transform:scale(1.1) translateY(-.5rem);
      -ms-transform:scale(1.1) translateY(-.5rem);
      -o-transform:scale(1.1) translateY(-.5rem);
    }
  }
  }


.delete-customer-image {
  width: 94%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  justify-items: center;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: absolute;
  top:50%;
  left: 1.5rem;
  //width: fit-content;
  //height:fit-content;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: .5rem;
  color: rgba($color-white, .8);
  background-color: rgba($color-white, .4);
  // margin-bottom: 5rem;
  padding: 1rem;
  //padding-bottom: 4rem;
  //overflow: hidden;
  transition:all .2s;
  -webkit-transition:all .2s;
  -moz-transition:all .2s;
  -ms-transition:all .2s;
  -o-transition:all .2s;
  &:hover {
      transform:scale(.90) translateY(-.5rem);
      //margin-left: 5rem;
      //margin-right: 5rem;
       font-size: 2.3rem !important;
      font-weight: 700;
      color: rgba(red, .8) !important;
      background-color: rgba($color-tertiary-light, .4);
      border: .1rem rgba($color-white, .8) solid;
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(.90) translateY(-.5rem);
      -moz-transform:scale(.90) translateY(-.5rem);
      -ms-transform:scale(.90) translateY(-.5rem);
      -o-transform:scale(.90) translateY(-.5rem);
}
  // margin-top: 8rem;
  // border: .1rem red solid;
  //&:hover ;

  // transform:scale(1.5) translateY(-.5rem);
  //     box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      // z-index: 1;
  // }





  // &__play-podcast-add-to-cart {
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   position: absolute;
  //   left:3rem;
  //   color: rgba($color-white, .9);
  //   background-color: rgba($color-black, .5);
  //   // padding-left: 1rem;
  //   // padding-right: 2rem;
  //   // margin-top: -5rem;
  //   // margin-bottom:25rem;
  //   // padding-bottom: 0rem;
  //   // width: 70%;
  //   font-size: 1.5rem;
  //   letter-spacing: .2rem;
  //   justify-content: center;
  //   justify-items: center;
  //   z-index: 0;

  //   &:hover {
  //     transform:scale(1.1) translateY(-.5rem);
  //     color: rgba($color-tertiary-light, .8);
  //     background-color: rgba(255, 255, 255, 1);
  //     box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
  //     z-index: 100;
  //     -webkit-transform:scale(1.1) translateY(-.5rem);
  //     -moz-transform:scale(1.1) translateY(-.5rem);
  //     -ms-transform:scale(1.1) translateY(-.5rem);
  //     -o-transform:scale(1.1) translateY(-.5rem);
  //   }
  // }

  &__select--podcast {
    display: flex;
    position: absolute;
    top:50%;
    // left:5;
    color: rgba($color-white, .9);
    // background-color: rgba($color-white, .5);
    padding-left: 1rem;
    padding-right: 2rem;
    // margin-top: -5rem;
    padding-bottom: 0rem;
    width: 90%;
    font-size: 1.5rem;
    letter-spacing: .2rem;
    justify-content: center;
    justify-items: center;
    z-index: 0;

    &:hover {
      transform:scale(1.1) translateY(-.5rem);
      color: rgba($color-tertiary-light, .8);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.5rem);
      -moz-transform:scale(1.1) translateY(-.5rem);
      -ms-transform:scale(1.1) translateY(-.5rem);
      -o-transform:scale(1.1) translateY(-.5rem);
    }
  }






}

// .video{
//   object-fit: fill !important;
//   width: 100% !important;
//   height: 50% !important;
// }
