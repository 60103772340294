.single-product {

  display: block;
   font-size: 2.4rem;
    // padding:  7rem;
    margin-left: 3rem;
    margin-right: 3rem;
    text-decoration: none;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    transition: all .2s;
    -webkit-transition: all .2s;

    box-shadow: 1rem 1rem 2rem rgba($color-black, .2);

  &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);

  }

  &__title {
    width: 100% !important;
    background-color: rgba($color-tertiary-dark, .2);
    color:$color-white;
    font-size: 1.4rem;
    font-weight: 700;
    // text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 3rem;
    // padding-top: 1.5rem;
    // padding-bottom: 1.5rem;
    margin: 1rem auto;
    box-shadow: 0 2.5rem 5rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, 1) solid;



    &::after {

    background-color: $color-white;
    }
  }


  &__title-related-products {
    width: 80% !important;
    background-color: rgba($color-white, .5);
    color:$color-primary-light;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 3rem 1rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    margin: 1rem auto -10rem auto;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, .1) solid;

    @include respond(phone){
      font-size: 4.5rem;
      // margin-top: 10rem;
      // margin-bottom: 10rem;
    }



    &::after {

    background-color: $color-white;
    }
  }

     &__main-single-product-title {
    width: fit-content !important;
    height: fit-content !important;
    background-color: rgba($color-white, .5);
    color:$color-primary-light;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .3rem;
    padding: 2rem 13rem;
    // padding-top: 1.8rem;
    // padding-bottom: 1.8rem;
    margin: 2rem auto !important;
    // box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    border: .1rem rgba($color-black, .03) solid;

    @include respond(phone){
      font-size: 3rem;
    }



    // &::after {

    // background-color: $color-white;
    // }
  }

  &__write-customer-review {
    width: 55% !important;
    background-color: rgba($color-secondary-dark, .045);
    color:$color-primary-light;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 3rem 1rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    margin: 2rem auto;
    // box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, .1) solid;

    @include respond(phone){
      width: 90% !important;
      font-size: 3rem;
    }



    &::after {

    background-color: $color-white;
    }
  }


  &__login-write-review {
    width: 50% !important;
    background-color: rgba($color-secondary-dark, .035);
    color:$color-primary-light;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 4rem 1rem;
    //padding-top: 1.8rem;
    //padding-bottom: 1.8rem;
    margin: 2rem auto;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, .1) solid;
    cursor: pointer;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;

    @include respond(phone){
      font-size: 2.5rem;
    }

      &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
   background-color:rgba($color-secondary-dark, .05) ;
    box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }



    // &::after {

    // background-color: $color-white;
    // }
  }


  &__no-reviews {
    width: 100% !important;
    height: fit-content;
    background-color: rgba($color-white, .5);
    color:$color-grey-dark-1;
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    // text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 5rem 7rem;
    // padding-top: 1.8rem;
    // padding-bottom: 1.8rem;
    // margin: 2rem auto;
    // margin-bottom: 3rem !important;
    // box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, 1) solid;

    @include respond(phone){
      width: 95% !important;
      font-size: 3rem;
    }

    &::after {

    background-color: $color-white;
    }
  }

  &__description-content {
    width: 95% !important;
    height: fit-content;
    background-color: rgba($color-black, .007);
    color:$color-grey-dark-1;
    font-size: 1.6rem;
    font-weight: 600;
    // text-align: center;
    // text-transform: uppercase;
    letter-spacing: .2rem;
    padding: 5rem 7rem;
    // padding-top: 1.8rem;
    // padding-bottom: 1.8rem;
    margin: 2rem auto;
    // margin-bottom: 3rem !important;
    // box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    border: .1rem rgba($color-black, .07) solid;

    @include respond(phone){
      font-size: 3rem;
    }



    &::after {

    background-color: $color-white;
    }
  }


// specific to the title of related videos from others in the single product video page//
  &__single-product-video--title-related-videos-from-others {
    width: 60% !important;
    background-color: rgba($color-tertiary-dark, .4);
    color:$color-white;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 3rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    margin: 2rem auto;
    box-shadow: 0 2.5rem 5rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, .1) solid;



    &::after {

    background-color: $color-white;
    }
  }

  &__title-product-review {
    width: 40% !important;
    background-color: rgba($color-white, .6);
    color:$color-primary-light;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: .5rem;
    padding: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0 auto 4rem auto;
    // box-shadow: 0 .1rem .1rem rgba($color-black, .2);
    border: .1rem rgba($color-black, .1) solid;

    @include respond(phone){
      font-size: 3rem;
    }


    &::after {

    background-color: $color-white;
    }
  }

  &__title-first-section {
    border-radius: 2%;
    width: 60% !important;
    background-color: rgba($color-tertiary-light, .1);
    color:rgba($color-tertiary-light, .8);
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0 auto 6rem auto;
    box-shadow: 0 2.5rem 5rem rgba($color-tertiary-dark, .2);
    border: .1rem rgba($color-grey-light-1, 1) solid;



    &::after {

    background-color: $color-white;
    }
  }

  &__title--write-review {
    width: 75% !important;
    background-color: rgba($color-tertiary-dark, .2);
    color:rgba($color-white, 1);
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    // margin-top: 1rem;
    // margin-bottom: 0rem;
    margin: 1rem auto;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, .5) solid;



    &::after {

    background-color: $color-white;
    }
  }

  &__write-review-1 {
    // width: 95% !important;
  // border: .2rem rgb(0, 255, 4) solid !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
    background-color: rgba($color-white, .23);
    color:$color-tertiary-light;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 1rem auto;
    box-shadow: 0 2.5rem 5rem rgba($color-black, .1);
    // border: .1rem rgba($color-grey-light-1, 1) solid;


    &--write {
      // min-width: 30rem;
      // max-width: 50rem;
      height: 45rem !important;

      // border: .2rem rgb(255, 247, 0) solid;
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .07);
      margin-bottom: 5rem;
      padding: 5rem 3rem 1rem 3rem !important;
      // padding-bottom: 5rem;
      background-color: rgba($color-black, .01);
        @include respond(phone){
          height: 70% !important;
      // font-size: 13rem;
      padding-bottom: 5rem !important;
      margin-bottom: 5rem;
      // background-color: red;
    }

    }
    &--seller-profile-description {
      // min-width: 30rem;
      // max-width: 50rem;
      height: 35rem !important;

      // border: .2rem rgb(255, 247, 0) solid;
      box-shadow: 0 .2rem .2rem rgba($color-black, .07);
      margin: 2rem 5rem 5rem auto !important;
      // margin-left: -12rem !important;
      padding: 2rem 3rem 7rem 3rem;
      // padding-bottom: 5rem;
      background-color: rgba($color-white, .03);

      @include respond(phone){
        height: 30vh !important;
      margin: 2rem 1rem 1rem -1rem !important;
      padding: 2rem 3rem 4rem 3rem;
      }

    }
  }

  &__write-review-upload-pic {
    height: fit-content !important;
    // width: 95% !important;
  // border: .2rem rgb(0, 255, 4) solid !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
    background-color: rgba($color-tertiary-dark, .023);
    color:$color-tertiary-light;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5rem;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 1rem auto;
    margin-top: -5rem;
    // box-shadow: 0 2.5rem 5rem rgba($color-black, .1);
    // border: .2rem rgba($color-grey-light-1, 1) solid;

    &--write {
      // min-width: 30rem;
      // max-width: 50rem;
      height: 45rem !important;

      // border: .2rem rgb(255, 247, 0) solid;
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .07);
      margin-bottom: 2rem;
      padding: 1rem 3rem 7rem 3rem !important;
      // padding-bottom: 5rem;
      background-color: rgba($color-white, .3);

    }
    &--seller-profile-description {
      // min-width: 30rem;
      // max-width: 50rem;
      height: 45rem !important;

      // border: .2rem rgb(255, 247, 0) solid;
      box-shadow: 0 .2rem .2rem rgba($color-black, .07);
      margin: 2rem 5rem 2rem auto !important;
      // margin-left: -12rem !important;
      padding: 2rem 3rem 7rem 3rem;
      // padding-bottom: 5rem;
      background-color: rgba($color-white, .03);

    }
  }

  &__write-review-2 {
    width: 80% !important;
  // border: .2rem rgb(255, 0, 21) solid !important;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    background-color: rgba($color-white, .4);
    color:$color-tertiary-light;
    font-size: 1.7rem;
    font-weight: 400;
    text-align: center;
    // text-transform: uppercase;
    // letter-spacing: .5rem;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 1rem auto;
    box-shadow: 0 .3rem .3em rgba($color-black, .1);
    border: .1rem rgba($color-grey-light-1, 1) solid;

    @include respond(phone){
      width: 100% !important;
      font-size: 3rem !important;
      padding: 0;
    }

    @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);


    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    margin: 4rem auto !important;

      @include respond(phone){
    width: calc( 3*((100% - 1 * #{$gutter-horizontal-3})/3) + #{$gutter-horizontal-1});
    // height: 75vh;
        // width: 85%;
      }
    }



    &--write {
      // min-width: 30rem;
      // max-width: 50rem;
      border: .2rem rgb(255, 247, 0) solid;
      background-color: rgba($color-white, .6);
    }
  }

  &--animated {
      animation: moveInBottom .5s ease-out .75s;
      animation-fill-mode: backwards;
  }

  &__seller-name-rating {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 600;
    color: $color-primary-light;
    letter-spacing: .2rem;
    text-align: center;
    justify-content: center;
    background-color: rgba($color-white, .8);
    // border: 1px #8ff881a1 solid;
    padding-top: 1rem;
    // padding-bottom: .5rem;
    // margin-bottom: .5rem;
    margin-top: .5rem;

    @include respond(phone){
      font-size: 3rem;
    }


    &:hover {
      transform: translateY(-.3px);
      -webkit-transform: translateY(-.3px);
      box-shadow: 0 .5rem .5rem rgba($color-black, .1);
      -moz-transform: translateY(-.3px);
      -ms-transform: translateY(-.3px);
      -o-transform: translateY(-.3px);
}
  }


  &__seller-podcast-name-rating {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    background-color: rgba($color-white, .8);
    // border: 1px #8ff881a1 solid;
    padding-top: 1rem;
    // padding-bottom: .5rem;
    // margin-bottom: .5rem;
    margin-top: 1.5rem;


    &:hover {
      transform: translateY(-.3px);
      -webkit-transform: translateY(-.3px);
      box-shadow: 0 .5rem .5rem rgba($color-black, .1);
      -moz-transform: translateY(-.3px);
      -ms-transform: translateY(-.3px);
      -o-transform: translateY(-.3px);
}
  }

  &__video-review {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 600;
    justify-content: space-around;
    text-align: center;
    background-color: rgba($color-tertiary-light, .08);
    color: rgba($color-white, 1);
    // border: 1px #8ff881a1 solid;
    // padding: 3rem;
    padding-top: 3rem;
    // padding-top: .5rem;
    // padding-bottom: .5rem;
    margin: 2rem 4rem;

  }

  &__product-review {
    // border: .1rem red solid;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    background-color: rgba($color-tertiary-light, .05);
    color: rgba($color-white, 1);
    // border: 1px #8ff881a1 solid;
    padding: 3rem;
    padding-top: 7rem;
    // padding-top: .5rem;
    // padding-bottom: .5rem;
    margin-bottom: .5rem;
    // margin-top: -4rem;


    // &:hover {
    //   transform: translateY(-.3px);
    //   -webkit-transform: translateY(-.3px);
    //   box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    //   -moz-transform: translateY(-.3px);
    //   -ms-transform: translateY(-.3px);
    //   -o-transform: translateY(-.3px);
    // }
  }

  &__related-products {
    // border: 1rem red solid;
    width: 85%;
    margin: 0 auto -85rem auto;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 600;
    justify-content: space-around;
    text-align: center;
    // background-color: rgba($color-white, .15);
    color: rgba($color-white, 1);
    // border: 1px #8ff881a1 solid;
    // padding: 3rem;
    // padding-top: 7rem;
    // padding-top: .5rem;
    // padding-bottom: .5rem;
    // margin-bottom: .5rem;
  }

  &__related-videos-from-others {
    // border: 1rem red solid;
    width: 75%;
    display: flex;
    margin: 1rem auto;
    margin-top: 5rem;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 600;
    justify-content: space-around;
    text-align: center;
    background-color: rgba($color-white, .3);
    color: rgba($color-white, 1);
    border: 1px #8ff881a1 solid;
    // padding: 3rem;
    // padding-top: 7rem;
    // padding-top: .5rem;
    // padding-bottom: .5rem;
    margin-bottom: 5rem;

  }

  &__card {
    display: row;
    color: rgba(14, 67, 240, 0.52);
    width: 100%;
    font-size: 1.8rem;
    // justify-content: space-between;
    // justify-items:center;
    background-color: rgba(#e2f1ede8, .6);
    border: 1px #33f143a1 solid;
    padding: 2rem;
    box-shadow: .5rem 2.5rem 2rem rgba($color-black, .2);
    // margin-bottom: 1rem;
    margin-right: 1rem;


  }

  &__list-group {
     display: block;
    font-size: 1.6rem;
    justify-content: flex-start;
    background-color: rgba(#9cf0d5e8, .2);
    // border: 5px #e02a09a1 solid;
    padding-top: 2rem;
    margin-bottom: 1rem;
  }
}


