.section-about {
  background-color: rgba($color-white, .3);
  padding: 7rem 5rem 10rem 5rem;
  margin: 10rem 10rem 10rem 10rem;
}

.section-profile {
  background-color: rgba($color-white, .3);





  padding: 7rem 5rem 10rem 5rem;
  margin: 2rem;
}

.section-homeForm {
  width: 85%;
  background-color: rgba($color-white, .3);
  background-color: rgba($color-tertiary-dark, .08);
  padding: 5rem 5rem 10rem 5rem;
  margin: 10rem auto 5rem auto ;
  background-size: cover;
}

.section-home-seller-form2-public--orange-background {
  width: 100%;
  height: fit-content;
  //background-color: rgba($color-Greenprimary-dark, .2);
  // background-color: rgba($color-black, .05);
background-image:
linear-gradient(to bottom,
rgba($color-secondary-dark, .1),
rgba($color-secondary-dark, .15),
rgba($color-secondary-dark, .15));
//  background-image: linear-gradient(to bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
//  background-image: linear-gradient(to bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    // background-image: linear-gradient(to bottom,
    //       rgba($color-tertiary-light, .1)0%,
    //       rgba($color-black, 1)35%,
    //       rgba($color-tertiary-dark, 1)100%);


  //  background-blend-mode:exclusion;
    // box-shadow: 0 .2rem 1rem rgba($color-black, .2);
    // -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    clip-path: polygon(12% 0, 88% 0, 100% 100%, 0 100%);
    // overflow:visible !important;
    z-index:0;


  box-shadow: .2rem .5rem 1rem rgba($color-black, .1);
  padding: 1rem 5rem 1rem 5rem;
  margin: 5rem auto 5rem auto ;
  // border-radius: 3%;
  //background-size: cover;
  // -webkit-border-radius: 3%;
  // -moz-border-radius: 3%;
  // -ms-border-radius: 3%;
  // -o-border-radius: 3%;
}

.section-home-seller-form2-public--green-background {
  width: 100%;
  height: fit-content;
  //background-color: rgba($color-Greenprimary-dark, .2);
  // background-color: rgba($color-black, .05);
// background-image: linear-gradient(to bottom, rgba($color-Greenprimary-light, .1), rgba($color-Greenprimary-dark, .3), rgba($color-Greenprimary-dark, .1));
//  background-image: linear-gradient(to bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
//  background-image: linear-gradient(to bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    // background-image: linear-gradient(to bottom,
    //       rgba($color-tertiary-light, .1)0%,
    //       rgba($color-black, 1)35%,
    //       rgba($color-tertiary-dark, 1)100%);


  //  background-blend-mode:exclusion;
    // box-shadow: 0 .2rem 1rem rgba($color-black, .2);
    // -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    clip-path: polygon(12% 0, 88% 0, 100% 100%, 0 100%);
    // overflow:visible !important;
    z-index:0;


  box-shadow: .2rem .5rem 1rem rgba($color-black, .1);
  padding: 1rem 5rem 1rem 5rem;
  margin: 5rem auto 5rem auto ;
  // border-radius: 3%;
  //background-size: cover;
  // -webkit-border-radius: 3%;
  // -moz-border-radius: 3%;
  // -ms-border-radius: 3%;
  // -o-border-radius: 3%;
}

.section-home-seller-form2-public--blue-background {
  width:85%;
  height: fit-content;
  //background-color: rgba($color-Greenprimary-dark, .2);
  // background-color: rgba($color-black, .05);
background-image:
linear-gradient(to bottom,
rgba($color-tertiary-dark, .03),
rgba($color-tertiary-dark, .03),
rgba($color-tertiary-dark, .03));
//  background-image: linear-gradient(to bottom, rgba($color-secondary-light, .3), rgba($color-secondary-dark, .3));
//  background-image: linear-gradient(to bottom, rgba($color-Greenprimary-light, .3), rgba($color-Greenprimary-dark, .3));
    // background-image: linear-gradient(to bottom,
    //       rgba($color-tertiary-light, .1)0%,
    //       rgba($color-black, 1)35%,
    //       rgba($color-tertiary-dark, 1)100%);


  //  background-blend-mode:exclusion;
    // box-shadow: 0 .2rem 1rem rgba($color-black, .2);
    // -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    // clip-path: polygon(12% 0, 88% 0, 100% 100%, 0 100%);
    // overflow:visible !important;
    z-index:0;


  box-shadow: .2rem .5rem 1rem rgba($color-black, .1);
  padding: 1rem 5rem 1rem 5rem;
  margin: 5rem auto !important;
  // border-radius: 3%;
  //background-size: cover;
  // -webkit-border-radius: 3%;
  // -moz-border-radius: 3%;
  // -ms-border-radius: 3%;
  // -o-border-radius: 3%;
}

.section-home-seller-form-public {
    width: 85%;
    height: fit-content;
    //background-color: rgba($color-white, .3);
    background-color: rgba($color-black, .03);
     padding: 1rem 10rem;
    margin: 7rem auto 5rem auto ;
    // margin: 1rem auto 5rem auto ;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    //background-size: cover;
    &__background
{
    background-color: rgba(wheat, .1);
}
}


.section-seller-create-home-form1 {
    width: 85%;
    height: fit-content;
    //background-color: rgba($color-white, .3);
    background-color: rgba($color-black, .03);
   padding: 1rem 10rem 5rem 10rem;
    margin: 1rem auto 5rem auto ;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    //background-size: cover;
        &__background {
    background-color: rgba(wheat, .3);
}
}


.section-home-seller-form1-public-new-design {
    width: 85%;
    height: fit-content;
    //background-color: rgba($color-white, .3);
    background-color: rgba($color-black, .03);
    padding: 3rem 3rem 5rem 3rem;
    margin: 1rem auto ;
    // border-radius: 2%;
    // -webkit-border-radius: 2%;
    // -moz-border-radius: 2%;
    // -ms-border-radius: 2%;
    // -o-border-radius: 2%;

    // clip-path: polygon(0 0, 100% 0, 93% 100%, 7% 100%);
    //background-size: cover;
//     &__background
// {
//     background-color: rgba(wheat, .3);
// }
}



.section-seller-create-homeForm {
  width: 95%;
  // background-color: rgba($color-white, .3);
  background-color: rgba($color-tertiary-dark, .08);
  padding: 5rem 5rem 10rem 5rem;
  margin: -1rem 0 5rem 0 ;
  background-size: cover;
}

.section-seller-home-page-form4-and-5-users {
  width: 100%;
  // background-color: rgba($color-white, .09);
  background-color: rgba($color-tertiary-dark, .1);
  padding: 5rem 10rem;
  margin: 10rem 0 5rem 0 ;
  background-size: cover;
}

.section-seller-home-Form3 {
    width: 85%;
    height: fit-content;
    //background-color: rgba($color-white, .3);
    background-color: rgba($color-tertiary-dark, .02);
    // clip-path: polygon(5% 0, 95% 0, 100% 100%, 0 100%);
    padding: 1rem 10rem 1rem 10rem;
    margin: 3rem auto 1rem auto ;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    //background-size: cover;
    &__background {
    background-color: rgba(wheat, .3);
}
}

.section-seller-home-form-6 {
  width: 95%;
    height: fit-content;
    //background-color: rgba($color-white, .3);
    background-color: rgba($color-black, .03);
    clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
    padding: 5rem 10rem 5rem 10rem;
    margin: 1rem auto 1rem auto ;
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
    //background-size: cover;

}

.section-seller-home-form-6-green-background {
  width: 95%;
    height: fit-content;
    //background-color: rgba($color-white, .3);
    background-color: rgba($color-black, .03);
    clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
    padding: 5rem 10rem 5rem 10rem;
    margin: 1rem auto 1rem auto ;
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
    //background-size: cover;

}

.section-seller-home-Form-width-100 {
  width: 95%;
  // height: 90vh;
  // background-color: rgba($color-white, .3);
  background-color: rgba($color-secondary-dark, .2);
  clip-path: polygon( 0 0, 100% 0, 97% 100%, 3% 100%);
  padding: 0 auto;
  margin: 0 auto  ;
  // background-size: cover;
}

.section-seller-home-public-pink-green-square {
  width: 90%;
  // height: 90vh;
  // background-color: rgba($color-white, .3);
  background-color: rgba($color-secondary-dark, .2);
  clip-path: polygon( 0 0, 100% 0, 97% 100%, 3% 100%);
  padding: 0 auto;
  margin: 0 auto  ;
  // background-size: cover;
}

.section-seller-home-create-pink-green-square {
  width: 95%;
  // height: 90vh;
  // background-color: rgba($color-white, .3);
  background-color: rgba($color-tertiary-dark, .1);
  // clip-path: polygon( 0 0, 100% 0, 97% 100%, 3% 100%);
  padding: 0 auto;
  margin: 0 auto  ;
  // background-size: cover;
}

.section-seller-home-Form-width-100-green-background {
  width: 85%;
  //height: 90vh;
  //background-color: rgba($color-white, .3);
  background-color: rgba($color-Greenprimary-dark, .07);
  box-shadow: .2rem .2rem .2rem rgba($color-black, .3);
  border: .5rem rgba($color-white, .5) solid;
  // clip-path: polygon( 0 0, 100% 0, 97% 100%, 3% 100%);
  border-radius: 50%;
  padding: 0 auto;
  margin: 0 auto  ;
  //background-size: cover;
  -webkit-border-radius: 1%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.section-seller-home-Form-width-100-green-blue-background {
  width: 100%;
  height: fit-content;
  //background-color: rgba($color-white, .3);
  // background-color: rgba($color-Greenprimary-dark, .03);
  // box-shadow: .2rem .2rem .2rem rgba($color-black, .3);
  // border: .5rem rgba($color-white, .5) solid;
  // clip-path: polygon( 0 0, 100% 0, 97% 100%, 3% 100%);
  border-radius: 50%;
  padding: 0 auto;
  margin: 0 auto  ;
  //background-size: cover;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.section-seller-product-page-form1-green-blue-squarish-circle {
  width: 100%;
  height: fit-content;
  //background-color: rgba($color-white, .3);
  background-color: rgba($color-Greenprimary-dark, .05);
  box-shadow: .2rem .2rem .2rem rgba($color-black, .3);
  // border: .5rem rgba($color-white, .5) solid;
  // clip-path: polygon( 0 0, 100% 0, 97% 100%, 3% 100%);
  border-radius: 50%;
  // padding: 5rem 0 !important;
  margin: 0 auto  ;
  //background-size: cover;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.section-seller-home-Form-podcasts-width-100-new-design {
  width: 100%;
  height: 50vh !important;
  // background-color: rgba($color-white, .3);
  color: rgba($color-white, 1);
  // background-color: rgb(11, 71, 140);
  background-color: rgba($color-tertiary-light, .08);
  // border-bottom: .3rem rgba($color-navy-dark, .5) solid;
  box-shadow: .4rem .3rem .3rem rgba($color-black, .3);
   outline: .7rem solid rgba($color-white, .5);
  outline-offset: 1rem;

  padding:1rem 5rem;
  margin: 0 auto  ;
  // background-size: cover;
}

.section-seller-home-Form-podcasts-width-100-black-design {
  width: 100%;
  // height: 90vh;
  // background-color: rgba($color-white, .3);
  color: rgba($color-white, 1);
  // background-color: rgb(11, 71, 140);
  background-color: rgba($color-black, 1);
  // border-bottom: .3rem rgba($color-navy-dark, .5) solid;
  box-shadow: .4rem .3rem .3rem rgba($color-black, .3);
   outline: .7rem solid rgba($color-white, .5);
  outline-offset: 1rem;

  padding:1rem 5rem;
  margin: 0 auto  ;
  // background-size: cover;
}

.section-seller-home-Form-podcasts-width-100 {
  width: 100%;
  // height: 90vh;
  // background-color: rgba($color-white, .3);
  color: rgba($color-white, 1);
  // background-color: rgb(11, 71, 140);
  background-color: rgba($color-white, .5);
  padding:3rem 5rem;
  margin: 0 auto  ;
  // background-size: cover;
}

.section-seller-home-Form7-width-100 {
  width: 85%;
  //max-width: fit-content;
  height: 75vh;
  position: relative;
  //height: 90vh;
  //background-color: rgba($color-white, .3);
  background-color: rgba($color-white, .4);
  outline: .7rem solid rgba($color-white, .5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 10rem auto  ;

  @include respond(phone){
    width:100%;
    height: 75%;
  }
  &__pict {
    // background-size: cover;
    width: 100%;
    height: 100%;
  //   border-radius:50% ;
  // -webkit-border-radius:50% ;
  // -moz-border-radius:50% ;
  // -ms-border-radius:50% ;
  // -o-border-radius:50% ;
    // padding: 5rem auto ;
    // margin: auto;
    // margin-top: 1rem;
    // position: relative;
    // top: 0;
    // left: 2;

//      &:hover {
//       outline: .7rem solid rgba($color-white, .3);
//       outline-offset: 2rem;
//       //border-radius: 0;
//       transform:scale(1.1) translateY(-.1rem);
//       box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
//       z-index: 100;
//       -webkit-transform:scale(1.1) translateY(-.1rem);
//       -moz-transform:scale(1.1) translateY(-.1rem);
//       -ms-transform:scale(1.1) translateY(-.1rem);
//       -o-transform:scale(1.1) translateY(-.1rem);
// }


  }

  &__message {
    width: 50%;
    height: 55%;
    position: absolute;
    top: 17%;
    right: 13%;
    margin: 5rem 0;
    padding: 3rem 5rem;
    color: rgba($color-black, 1);
    // font-size: 1.7rem;
    font-weight: 600;
    background-color: rgba($color-white, .8) !important;
    box-shadow: .3rem .3rem 3rem rgba($color-black, .3);
    z-index: 100;

    @include respond(phone){
      width: 55%;
      height: fit-content;
    }
  }
}

.section-seller-home-Form7-width-100-with-two-pictures {
  width: 90%;
  //max-width: fit-content;
  // height: 75vh;
  position: relative;
  //height: 90vh;
  //background-color: rgba($color-white, .3);
  // background-color: rgba($color-Greenprimary-dark, .07);
  background-color: rgba($color-Greenprimary-dark, .06);
  box-shadow: .2rem .2rem .2rem rgba($color-black, .2);
  // outline: .1rem solid rgba($color-white, .5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 1rem auto  ;
  &__pict {
    // background-size: cover;
    width: 100%;
    height: 100%;
    border-radius:50% ;
  -webkit-border-radius:50% ;
  -moz-border-radius:50% ;
  -ms-border-radius:50% ;
  -o-border-radius:50% ;
    // padding: 5rem auto ;
    // margin: auto;
    // margin-top: 1rem;
    // position: relative;
    // top: 0;
    // left: 2;

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.1rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.1rem);
      -moz-transform:scale(1.1) translateY(-.1rem);
      -ms-transform:scale(1.1) translateY(-.1rem);
      -o-transform:scale(1.1) translateY(-.1rem);
}


  }

  &__message {
    width: 40%;
    position: absolute;
    top: 17%;
    right: 13%;
    margin: 5rem 0;
    padding: 3rem 5rem;
    background-color: rgba($color-white, .5);
    box-shadow: .3rem .3rem 3rem rgba($color-black, .3);
    z-index: 100;
  }
}

.section-seller-home-Form7-width-100-with-two-texts-and-apicture {
  width: 85%;
  //max-width: fit-content;
  // height: 75vh;
  position: relative;
  //height: 90vh;
  //background-color: rgba($color-white, .3);
  background-color: rgba($color-Greenprimary-dark, .03);
  box-shadow: .5rem .5rem 1rem rgba($color-black, .2);
  outline: .7rem solid rgba($color-white, .5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 15rem auto  ;
  &__pict {
    // background-size: cover;
    width: 100%;
    height: 100%;
    border-radius:50% ;
  -webkit-border-radius:50% ;
  -moz-border-radius:50% ;
  -ms-border-radius:50% ;
  -o-border-radius:50% ;
    // padding: 5rem auto ;
    // margin: auto;
    // margin-top: 1rem;
    // position: relative;
    // top: 0;
    // left: 2;

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.1rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.1rem);
      -moz-transform:scale(1.1) translateY(-.1rem);
      -ms-transform:scale(1.1) translateY(-.1rem);
      -o-transform:scale(1.1) translateY(-.1rem);
}


  }

  &__message {
    width: 40%;
    position: absolute;
    top: 17%;
    right: 13%;
    margin: 5rem 0;
    padding: 3rem 5rem;
    background-color: rgba($color-white, .5);
    box-shadow: .3rem .3rem 3rem rgba($color-black, .3);
    z-index: 100;
  }
}

.section-seller-home-Form7-width-100-with-green-background {
  width: 50%;
  //max-width: fit-content;
  height: 80vh;
  position: relative;
  //height: 90vh;
  //background-color: rgba($color-white, .3);
  background-color: rgba($color-Greenprimary-dark, .15);
  outline: .7rem solid rgba($color-white, .5);
  outline-offset: 2rem;
  padding: 2rem 3rem;
  margin: 8rem auto 1rem auto  ;
  &__pict {
    // background-size: cover;
    width: 100%;
    height: 100%;
    border-radius:50% ;
  -webkit-border-radius:50% ;
  -moz-border-radius:50% ;
  -ms-border-radius:50% ;
  -o-border-radius:50% ;
    // padding: 5rem auto ;
    // margin: auto;
    // margin-top: 1rem;
    // position: relative;
    // top: 0;
    // left: 2;

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.1rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.1rem);
      -moz-transform:scale(1.1) translateY(-.1rem);
      -ms-transform:scale(1.1) translateY(-.1rem);
      -o-transform:scale(1.1) translateY(-.1rem);
}


  }

  &__message {
    width: 40%;
    position: absolute;
     top: 17%;
    right: 13%;
    margin: 5rem 0;
    padding: 3rem 5rem;
    background-color: rgba($color-white, .5);
    box-shadow: .3rem .3rem 3rem rgba($color-black, .3);
    z-index: 100;
  }
}

.section-seller-home-Form7-width-100-with-blue-background {
  width: 50%;
  //max-width: fit-content;
  height: 80vh;
  position: relative;
  //height: 90vh;
  //background-color: rgba($color-white, .3);
  background-color: rgba($color-tertiary-light, .15);
  outline: .7rem solid rgba($color-white, .5);
  outline-offset: 2rem;
  padding: 5rem 3rem;
  margin: 8rem auto ;
  &__pict {
    // background-size: cover;
    width: 100%;
    height: 100%;
    border-radius:50% ;
  -webkit-border-radius:50% ;
  -moz-border-radius:50% ;
  -ms-border-radius:50% ;
  -o-border-radius:50% ;
    // padding: 5rem auto ;
    // margin: auto;
    // margin-top: 1rem;
    // position: relative;
    // top: 0;
    // left: 2;

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.1rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.1rem);
      -moz-transform:scale(1.1) translateY(-.1rem);
      -ms-transform:scale(1.1) translateY(-.1rem);
      -o-transform:scale(1.1) translateY(-.1rem);
}


  }

  &__message {
    width: 40%;
    position: absolute;
    top: 17%;
    right: 13%;
    margin: 5rem 0;
    padding: 3rem 5rem;
    background-color: rgba($color-white, .5);
    box-shadow: .3rem .3rem 3rem rgba($color-black, .3);
    z-index: 100;
  }
}

.section-seller-home-Form-video-background-width-100 {
  width: 75%;
  // max-width: fit-content;
  // height: 100%;
  position: relative;
  height: 55vh;
  // background-color: rgba($color-white, .3);
  background-color: rgba($color-tertiary-dark, .15);
  // padding: 0 auto;
  margin: 5rem auto 10rem auto ;
  &__pict {
    // background-size: cover;
    width: 100%;
    height: 100%;
    // padding: 5rem auto ;
    // margin: auto;
    // margin-top: 1rem;
    // position: absolute;
    // top: 0;
    // left: 2;

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.1rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.1rem);
      -moz-transform:scale(1.1) translateY(-.1rem);
      -ms-transform:scale(1.1) translateY(-.1rem);
      -o-transform:scale(1.1) translateY(-.1rem);
}


  }

  &__message {
    width: 40%;
    position: absolute;
    top: 8%;
    right: 5%;
    margin: 5rem 0;
    padding: 3rem 5rem;
    background-color: rgba($color-white, .7);
    z-index: 100;
  }
}

.section-seller-create-home-Form-width-100 {
  width: 90%;
  // height: 90vh;
  // background-color: rgba($color-white, .3);
  background-color: rgba($color-tertiary-dark, .15);
  padding: 0 auto;
  margin: 0 auto  ;
  margin-bottom: 5rem;
  // background-size: cover;
}

.section-home-form-display {
  width: 75vw;
  background-color: rgba($color-white, .3);
  // background-color: rgba($color-primary-light-2, .05);
  padding: 5rem 5rem 15rem 5rem;
  margin: 10rem auto ;
  background-size: cover;
}

.section-podcast {
  margin-bottom: 10rem;
  margin-top: 10rem;
  padding: 3rem 0 0 0;
  //padding-top: 14rem ;
  //padding-bottom: 2rem;
  // background-image: linear-gradient(    to right,    $color-primary-light,    $color-primary-dark),    url(../image/nat-4.jpg);
  background-image: linear-gradient(    to right,    rgba($color-primary-light, .15),    rgba($color-primary-dark, .13));
  background-size: cover;
  // transform: skewY(-7deg) ;
  //   & > * {
  //   transform: skewY(7deg);
  // }
}

.section-features {
  margin-bottom: 10rem;
  margin-top: 10rem;
  padding: 3rem 0 0 0;
  //padding-top: 14rem ;
  //padding-bottom: 2rem;
  // background-image: linear-gradient(    to right,    $color-primary-light,    $color-primary-dark),    url(../image/nat-4.jpg);
  background-image: linear-gradient(    to right,    rgba($color-primary-light, .15),    rgba($color-primary-dark, .13));
  background-size: cover;
  // transform: skewY(-7deg) ;
  //   & > * {
  //   transform: skewY(7deg);
  // }
}
.section-features-single-product {
  width: 95vw;
  height: fit-content;
  //margin-bottom: 15rem;
  //margin-top: 10rem;
  margin: -2rem auto 10rem auto;
  //padding: 15rem auto 2rem auto;
  padding-top: 15rem ;
  padding-bottom: 2rem;
  // background-image: linear-gradient(  to top right,   $color-primary-light-3,    $color-tertiary-light);

  background-image: linear-gradient(180deg,
    rgba(69, 81, 248, 0.6) 5%,
    rgba(69, 81, 248, 0.4) 15%,
    rgba(67, 70, 241, 0.5) 35%,
    rgba(69, 81, 248, 0.4) 40%,
    rgba(69, 81, 248, 0.4) 45%,

    rgba(69, 81, 248, 0.4) 55%,
    rgba(69, 81, 248, 0.6) 65%,
    rgba(56, 116, 236, 0.6) 100%,
    rgba(15, 91, 241, 0.7) 90%),


    url(../image/hero.jpg);


    // professional blue look//
    //  background-image: linear-gradient(125deg,
    // rgba(8, 75, 209, 1) 0%,
    // rgba(8, 75, 209, .5) 50%,
    // rgba(8, 75, 209, .5) 51%,
    // rgba(8, 75, 209, 1)  90%);

    // box-shadow: 0 .5rem 3rem rgba($color-black, .5);
  background-size: cover;
  // border-bottom: .5rem $color-tertiary-light solid;
  // border-right: .5rem $color-tertiary-light solid;
  transform: skewY(-1deg) ;
  &>* {
    transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -moz-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
  }
}

.section-tours {
  background-color:rgba($color-tertiary-dark, .05);
  box-shadow: 0 1rem 2.5rem rgba($color-black, .06);
  padding: 5rem 5rem 5rem 5rem;
  margin-top: -10vh;
  margin-left: 10rem;
  margin-right: 10rem;
  margin-bottom: 15rem;

}


.section-features-best-seller {
    margin-bottom: 15rem;
    padding: 25rem 0 10rem 0;
  // padding-top: 14rem ;
  // padding-bottom: 2rem;
    background-image: linear-gradient(
      to right,
      $color-primary-light,
      $color-primary-dark),
      url(../image/nat-4.jpg);
  background-size: cover;


}

.section-product-card {
  background-color: $color-grey-light-1;
  padding: 28rem 0 10rem 0;
  margin-top: -28rem;
}
// .section-jumbotron {
//   background-image: linear-gradient(
//       to right,
//       $color-primary-light,
//       $color-primary-dark),
//       url(../image/nat-4.jpg);
//   padding: 10rem 0 10rem 0;
//   font-size: 6rem;
//   // margin-top: -28rem;
// }

// .section-jumbotron {
//   display: inline-block;
//   justify-items:center;
//   background-image: linear-gradient(
//       to right,
//       $color-primary-light,
//       $color-primary-dark),
//       url(../image/nat-4.jpg);
//   padding: -5rem 0 ;
//   font-size: 4rem;
//   margin-top: -5rem;
// }

.section-video {
  background-color: $color-grey-light-1;
  padding: 5rem 0;
  // margin-bottom: 2rem;
}

.section-book {
  padding: 15rem 0;

   background-image: linear-gradient(180deg,
    rgba(69, 81, 248, 0.6) 5%,
    rgba(69, 81, 248, 0.4) 15%,
    rgba(67, 70, 241, 0.5) 35%,
    rgba(69, 81, 248, 0.4) 40%,
    rgba(69, 81, 248, 0.4) 45%,

    rgba(69, 81, 248, 0.4) 55%,
    rgba(69, 81, 248, 0.6) 65%,
    rgba(56, 116, 236, 0.6) 100%,
    rgba(15, 91, 241, 0.7) 90%);


    // url(../image/hero.jpg);




  // background-image: linear-gradient(to right bottom, $color-primary-light-1, $color-primary-dark-1);
    // background-image: linear-gradient(180deg,
    //     rgba($color-tertiary-light, .9) 0%,
    //     rgba($color-white,.5) 30%,
    //     rgba($color-tertiary-light,.9) 50%,
    //     rgba($color-tertiary-light,.4) 60%,
    //     rgba($color-tertiary-light,.2) 70%,
    //     rgba($color-primary-light,.2) 80%,
    //     //rgba(235, 154, 34, 0.3) 100%,
    //     rgba($color-primary-light, .2) 90%,
    //     rgba($color-primary-light, .5) 100%);
}

.book {
  width:50%;
  margin:10rem auto !important;
  color: white !important;
  background-image: linear-gradient(180deg,
        rgba($color-white, .9) 0%,
        rgba($color-white, .09) 50%,

        rgba(transparent, .01) 50%,
        rgba(transparent, .9) 90%),
        url(../image/nat-10.jpg);
  // background-image: linear-gradient(105deg,
  //       rgba($color-white, .5) 0%,
  //       rgba($color-white, .5) 50%,
  //       rgba(rgb(231, 90, 47), .5) 50%,
  //       rgba(transparent, .1) 90%),
  //       url(../img/nat-10.jpg);
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 1);
  height: 100% !important;

  @include respond(phone){
    width: 90%;
    height: fit-content !important;
    margin-top: 10rem;
    // padding-bottom: 5rem;
  // box-shadow: 0 1rem 2rem rgba($color-black, 1);
  }

  &__form {
    width: 50%;
    padding: 6rem;
  }
}

.book-login {
  width:50%;
  margin:10rem auto !important;
  color: white !important;
  background-image: linear-gradient(180deg,
        rgba($color-white, .7) 0%,
        rgba($color-white, .7) 70%,

        // rgba(transparent, .1) 50%,
        rgba(transparent, .9) 100%),
        url(../image/nat-10.jpg);
  // background-image: linear-gradient(105deg,
  //       rgba($color-white, .5) 0%,
  //       rgba($color-white, .5) 50%,
  //       rgba(rgb(231, 90, 47), .5) 50%,
  //       rgba(transparent, .1) 90%),
  //       url(../img/nat-10.jpg);
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 1);
  height: 100% !important;

  &__form {
    width: 50%;
    padding: 6rem;
  }
}

.section-features-admin-dashboard {
  width: 95vw;
  height: fit-content;
  //margin-bottom: 15rem;
  //margin-top: 10rem;
  margin: 5rem auto 10rem auto;
  //padding: 15rem auto 2rem auto;
  padding-top: 5rem ;
  padding-bottom: 2rem;
  // background-image: linear-gradient(  to top right,   $color-primary-light-3,    $color-tertiary-light);

  //  background-image: linear-gradient(180deg,
  //   rgba(69, 81, 248, 0.6) 5%,
  //   rgba(69, 81, 248, 0.4) 15%,
  //   rgba(67, 70, 241, 0.5) 35%,
  //   rgba(69, 81, 248, 0.4) 40%,
  //   rgba(69, 81, 248, 0.4) 45%,

  //   rgba(69, 81, 248, 0.4) 55%,
  //   rgba(69, 81, 248, 0.6) 65%,
  //   rgba(56, 116, 236, 0.6) 100%,
  //   rgba(15, 91, 241, 0.7)90%),


  //   url(../image/hero.jpg);


//  background-image: linear-gradient(180deg,

//     rgba(15, 91, 241, 0.5)90%),
//     url(../image/hero.jpg);



  // professional blue look//
   background-image: linear-gradient(125deg,
    rgba(69, 81, 248, 0.1) 0%,
    rgba(69, 81, 248, 0.3) 50%,
    rgba(69, 81, 248, 0.3) 51%,
    rgba(69, 81, 248, 0.1)  90%);

    // box-shadow: 0 .5rem 3rem rgba($color-white, .5);
  background-size: cover;
  // border-bottom: .5rem $color-tertiary-light solid;
  // border-right: .5rem $color-tertiary-light solid;
  transform: skewY(-1deg) ;
  &>* {
    transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -moz-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
  }
}


.section-admin-column {

  // background-image:
    // linear-gradient( to right bottom,
    // rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);
  background-color: rgba($color-tertiary-dark, .05);

  background-size:fill;
  border-radius: 3px;
  // box-shadow: 0 .5rem 1rem rgba($color-black, .03);
  width: fit-content;
  height: fit-content !important;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 25rem;
  margin-bottom: 25rem;

  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }
}


.section-seller-home-menu {

  background-image:
    linear-gradient( to right bottom,
    rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  height: fit-content !important;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  // margin-top: 25rem;
  // margin-bottom: 10rem;

  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }
}


.section-seller-public-column {

  background-image:
    linear-gradient( to right bottom,
    rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 30rem;

  padding-top: 5rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }
}


.section-seller-single-product-row-nav {
  background-image:    linear-gradient( to right bottom,    rgba($color-tertiary-light, .2) ,  rgba($color-tertiary-dark, .2) );
  //background-color: rgba($color-tertiary-dark, .2);
  display: flex;
  flex-direction: row;
  background-size:fill;
  border-radius: .1rem;
  -webkit-border-radius: .1rem;
  -moz-border-radius: .1rem;
  -ms-border-radius: .1rem;
  -o-border-radius: .1rem;
  box-shadow: 0 .5rem 1rem rgba($color-black, .1);
  height: 20rem ;
  font-size: 1.6rem;
  font-weight: 500;
  width: fit-content !important;
  //margin-top: 11.8rem;
  margin-top: 13rem;
  // margin-bottom: 5rem;
  margin-left: 10rem;
  // padding: 10rem auto auto auto !important;
  padding-top: 9rem;
  // padding-left: 1rem;
  // border: .1rem red solid;
  // &__form{
  //   width: 10%;
  //   padding: 5rem auto !important;
  // }
}



.section-seller-home-nav {
  background-image:    linear-gradient( to right bottom,    rgba($color-tertiary-light, .2) ,  rgba($color-tertiary-dark, .2) );
  //background-color: rgba($color-tertiary-dark, .2);
  display: flex;
  flex-direction: row;
  background-size:fill;
  border-radius: .1rem;
  -webkit-border-radius: .1rem;
  -moz-border-radius: .1rem;
  -ms-border-radius: .1rem;
  -o-border-radius: .1rem;
  box-shadow: 0 .5rem 1rem rgba($color-black, .1);
  height: 20rem ;
  font-size: 1.6rem;
  font-weight: 500;
  width: fit-content !important;
  //margin-top: 11.8rem;
  margin-top: 13rem;
  margin-bottom: 7rem;
  margin-left: 10rem;
  // padding: 10rem auto auto auto !important;
  padding-top: 9rem;
  // padding-left: 1rem;
  // border: .1rem red solid;
  // &__form{
  //   width: 10%;
  //   padding: 5rem auto !important;
  // }
}

.section-seller-podcasts-nav {
  // background-image:    linear-gradient( to right bottom,    rgba($color-tertiary-light, .2) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background-size:fill;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  // box-shadow: 0 .5rem .5rem rgba($color-black, .1);
  // border: .1rem rgba($color-black, .1) solid;
  height: fit-content !important;
  font-size: 1.4rem;
  font-weight: 700;
  // width: 100% !important;
  //margin-top: 11.8rem;
  // margin-top: 2rem;
  // margin-bottom: 5rem;
  margin: -1rem auto 12rem auto;

  // margin-left: 5rem;
  // margin-right: 5rem !important;
  // padding: 10rem auto auto auto !important;
  padding: 2rem 1rem  2rem 3rem!important;
  cursor: pointer;
  // padding-left: 1rem;
  // border: .1rem red solid;
  // &__form{
  //   width: 10%;
  //   padding: 5rem auto !important;
  // }

}

.section-seller-product-nav {
  // background-image:    linear-gradient( to right bottom,    rgba($color-tertiary-light, .2) ,  rgba($color-tertiary-dark, .2) );
    // background-color: rgba($color-tertiary-dark, .07) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background-size:fill;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  // box-shadow: 0 .5rem .5rem rgba($color-black, .1);
  // border: .1rem rgba($color-black, .1) solid;
  height: fit-content !important;
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content !important;
  height: fit-content;
  //margin-top: 11.8rem;
  // margin-top: 2rem;
  // margin-bottom: 5rem;
  margin: 30rem 2.4rem 12rem auto !important;

  // margin-left: 5rem;
  // margin-right: 5rem !important;
  // padding: 10rem auto auto auto !important;
  padding: 15rem 1rem  7rem 1rem!important;
  cursor: pointer;
  // padding-left: 1rem;
  // border: .1rem red solid;
  // &__form{
  //   width: 10%;
  //   padding: 5rem auto !important;
  // }

}

.section-seller-menu {
  // background-image:    linear-gradient( to right bottom,    rgba($color-tertiary-light, .2) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-white, .6);
  // display: flex;
  // flex-direction: column;
  justify-content: center;
  justify-items: center;
  // background-size:fill;
  border-radius: .1rem;
  -webkit-border-radius: .1rem;
  -moz-border-radius: .1rem;
  -ms-border-radius: .1rem;
  -o-border-radius: .1rem;
  box-shadow: 0 .3rem .3rem rgba($color-black, .1);
  height: fit-content !important ;
  width: fit-content !important;
  font-weight: 400 !important;
  // font-size: 5rem !important;
  // font-weight: 500;
  //margin-top: 11.8rem;
  margin-top: 4rem !important;
  // margin-bottom: -5rem;
  margin-left: 1rem;
  margin-right: 3rem;
  z-index: 1000;
  cursor: pointer;
  padding: .5rem  !important;
  color: rgba($color-primary-light, 1) !important;
  // font-size: '10rem';
  // padding-top: 9rem;
  // padding-left: 1rem;
  // border: .1rem red solid !important;
  // box-shadow: 0 1rem 1rem rgba($color-black, .2) ;

  // &__form{
  //   width: 10%;
  //   padding: 5rem auto !important;
  // }

     &:hover {
      // outline: .7rem solid rgba($color-white, .3);
      // outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.1) translateY(-.1rem);
      box-shadow: 0 .5rem .5rem rgba($color-black, .1);
      z-index: 100;
      -webkit-transform:scale(1.1) translateY(-.1rem);
      -moz-transform:scale(1.1) translateY(-.1rem);
      -ms-transform:scale(1.1) translateY(-.1rem);
      -o-transform:scale(1.1) translateY(-.1rem);
}

}


.section-seller-profile-public-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .04);


  // background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .03);
  // min-height: 50rem !important;
  // max-height: 130rem !important;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 40rem;
  // margin-bottom: 140rem;

  padding-top: 15rem !important;

  &__form {
    width: 100%;
    padding: 5rem 0 5rem 0;

  }
}

.section-seller-videos-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .2);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  // height: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  // margin-top: 10rem;

  padding-top: 15rem;
  padding-bottom: 42rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }
}
.section-sub-category {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);

  // background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .1);
  // height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 20rem;

  padding-top: 5rem;
  padding-bottom: 5rem;

  &__form {
    width: 100%;
    padding: 5rem 0rem 5rem 5rem;

  }

}

.section-wishlist-column {

  background-image:
    linear-gradient( to right bottom,
    rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 22rem;
  margin-left: -2rem;

  padding-top: 5rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }


}

.section-user-order-details {

  background-image:
    linear-gradient( to right bottom,
    rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .02) );
  // background-color: rgba($color-tertiary-dark, .2);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  height: 122rem;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 1rem;
  margin-bottom: 15rem;

  padding-top: 5rem;

  // &__form {
  //   width: 0%;
  //   margin-left: 15rem !important;
  //   padding: 5rem 0 5rem 0;
  //   border: .2rem red solid;

  // }


}
.section-user-cart-summary-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-dark, .15) ,  rgba($color-tertiary-dark, .01) );
  background-color: rgba($color-tertiary-dark, .015);

  background-size:fill;
  border-radius: 3px;
  box-shadow: .5rem .5rem .5rem rgba($color-black, .02);
  // height: 100vh;
  font-size: 1.6rem;
  font-weight: 400;
  // margin-top: 11.8rem;
  margin-top: 25rem;

  // padding-top: 5rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }


}


.section-profile-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .05);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 40rem;
  padding-right: 2rem;
  padding-left: 1rem !important;

  // padding-top: 5rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }

}

.section-updated-seller-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .05);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 40rem;
  padding-right: 2rem;
  padding-left: 1rem !important;

  // padding-top: 5rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;
    // background-color: yellow;

  }

  @include respond(phone){
    background-color: transparent !important;
    height: fit-content;
    margin-top: 3rem;
    // margin-bottom: .5rem;
    // padding-top: 5rem;
    // padding-bottom: 5rem;
  }

}


.section-history-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .04);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  width: fit-content;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 40rem;
  padding-right: 4rem;
  padding-left: 1rem !important;

  padding-top: 4rem;
  @include respond(phone){
    height: 0;
    // padding: 5rem 1rem;
      background-color: none;
  }

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }


}

.section-customer-order-details-column {

  background-color: rgba($color-tertiary-dark, .04);
  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  width: fit-content;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 20rem;
  padding-right: 4rem;
  padding-left: 1rem !important;

  padding-top: 4rem;
  @include respond(phone){
    height: 0;
    // padding: 5rem 1rem;
      background-color: none;
  }

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }


}


.section-updated-wishlist-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  background-color: rgba($color-tertiary-dark, .04);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  width: fit-content;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 30rem;
  padding-right: 4rem;
  padding-left: 1rem !important;

  padding-top: 4rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }

  @include respond(phone){
  font-size: 6rem !important;

  padding-right: 1rem !important;
  }


}



.section-seller-video-column {

  background-image:
    linear-gradient( to right bottom,
    rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);
// border: .1rem yellow solid;
  // background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
  // height:fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  // margin-top: -11rem;
  // border: .1red red solid;

  // padding-top: 5rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }


}

.section-seller-videos-jumbtron {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );
  // background-color: rgba($color-tertiary-dark, .2);
border: .1rem red solid;
  // background-size:cover;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-black, 9);
  height: fit-content;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  // margin: -.1rem auto;

  padding-top: 15rem;

  &__form {
    width: 80%;
    padding: 5rem 0 5rem 0;

  }


}
.section-shop-column {

  // background-image:
  //   linear-gradient( to right bottom,
  //   rgba($color-tertiary-light, .04) ,  rgba($color-tertiary-dark, .06) );
  // background-color: rgba($color-tertiary-dark, .02);
    background-color:rgba(178, 178, 223, .05);

  background-size:fill;
  border-radius: 3px;
  // box-shadow: 0 .5rem 1rem rgba($color-black, 0.05);
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 15rem;
  // margin-left: .5rem
  padding-left: 1rem;


  // padding-top: 5rem;

  &__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    padding: 5rem 1rem 10rem 0;
    margin-top: 0rem;
    margin-left: 2rem;
    margin-right: 1rem;

    @include respond(phone){
      width: 100% !important;
      height: fit-content !important;
      display: flex !important;
      flex-wrap: wrap !important;
      // font-size: .3rem;
    }

  }


}

.section-user-column {


   background-image: linear-gradient(110deg,
  rgba($color-primary-light-3, 0) 0%,
  rgba($color-primary-light-3, .1) 8%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba($color-primary-light-3, .45) 40%,
  rgba($color-white, 1) 51%,
  rgba(transparent, .1) 53%,
  rgba($color-white, 1) 57%,
  rgba($color-white, .8) 65%,


  rgba($color-white, .1) 90%);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-white, 1);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 19rem;

  padding-top: 7rem;

  &__form {
    width: 80%;
    padding: 15rem;
  }

}

.section-seller-column {
  //background-image: linear-gradient(105deg,  //       rgba($color-white, .9) 0%,  //       rgba($color-white, .9) 50%,  //       rgba(transparent, .1) 50%,  //       rgba(transparent, .1) 90%),  //       url(../image/nat-10.jpg);
  // background-image: linear-gradient(10deg,
  // rgba($color-white, 1) 0%,
  // rgba($color-white, .8) 50%,
  // rgba(rgb(81, 114, 187), .5) 50%,
  // rgba(transparent, .1) 90%),
  // url(../image/nat-10.jpg);

   background-image: linear-gradient(110deg,
  rgba($color-primary-light-3, 0) 0%,
  rgba($color-primary-light-3, .1) 8%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba($color-primary-light-3, .45) 40%,
  rgba($color-white, 1) 51%,
  rgba(transparent, .1) 53%,
  rgba($color-white, 1) 57%,
  rgba($color-white, .8) 65%,


  rgba($color-white, .1) 90%);

  background-size:fill;
  border-radius: 3px;
  box-shadow: 0 .5rem 1rem rgba($color-white, 1);
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  // margin-top: 11.8rem;
  margin-top: 20rem;

  padding-top: 7rem;

  &__form {
    width: 80%;
    padding: 15rem;
  }

}


// .section-profile-column {
  //background-image: linear-gradient(105deg,  //       rgba($color-white, .9) 0%,  //       rgba($color-white, .9) 50%,  //       rgba(transparent, .1) 50%,  //       rgba(transparent, .1) 90%),  //       url(../image/nat-10.jpg);
  // background-image: linear-gradient(10deg,
  // rgba($color-white, 1) 0%,
  // rgba($color-white, .8) 50%,
  // rgba(rgb(81, 114, 187), .5) 50%,
  // rgba(transparent, .1) 90%),
  // url(../image/nat-10.jpg);

//    background-image: linear-gradient(110deg,
//   rgba($color-primary-light-3, 0) 0%,
//   rgba($color-primary-light-3, .1) 8%,
//   rgba($color-white, .5) 15%,
//   rgba($color-white, .6) 20%,

//   rgba($color-primary-light-3, .45) 40%,
//   rgba($color-white, 1) 51%,
//   rgba(transparent, .1) 53%,
//   rgba($color-white, 1) 57%,
//   rgba($color-white, .8) 65%,


//   rgba($color-white, .1) 90%);

//   background-size:fill;
//   border-radius: 3px;
//   box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
//   height: 100vh;
//   font-size: 1.6rem;
//   font-weight: 500;
//   margin-top: 5rem;
//   // margin-top: 20rem;

//   padding-top: 12rem;

//   &__form {
//     width: 80%;
//     padding: 15rem;
//   }

// }
