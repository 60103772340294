.new-heading-primary{
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin-bottom:-2rem;
  margin-top: -2rem;

  &--header-domain-logged-out{
    display: block;
    font-size: 8rem;
    font-weight: 700;
    //text-align: ;
    text-transform: none;
    letter-spacing: 1rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

   &--header-slogen-logged-out {
    display: flex;
    margin-top: -1rem;
    justify-content: center;
    // position: absolute;
    // right: 29%;
    font-size: 1.8rem;
    font-weight: 700;
    font-weight: bolder;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

    &--main-logo-domain-logged-in {
    display: block;
    font-size: 2.4rem;
    font-weight: 700;
     text-transform: none;
    letter-spacing: .5rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

    &--sub-logo-slogen-logged-in {
    display: block;
    justify-content: center !important;
    font-size: .7rem;
    font-weight: 500;
    letter-spacing: .35rem;
    margin-bottom: 2rem;
    // margin-right: 12rem;
    // border-bottom: .2rem rgba($color-white, 1) solid;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--home-categories-title {
    color: $color-white;
    background-color: rgba($color-navy-dark, .3) !important;
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    display: flex;
    // margin-top: -15rem !important;
    padding: 1.5rem 1rem ;
    border-bottom: .1rem rgba($color-white, .9) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 1s ;
    -webkit-animation: moveInRight 1s ;
  }
}

//////////////////////////////////////////////////////////////////////////
            // :::::::::::::OLD STYLES BELOW:::::::::
//////////////////////////////////////////////////////////////////////////


.heading-primary{
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin-bottom:-2rem;
  margin-top: -2rem;




  &--no-product-found {
    width: fit-content;
    padding: 3rem 15rem;
    margin: 1rem auto;
    font-size: 2.5rem;
    font-weight: 500;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    color: rgba($color-white, 1);
    background-color:rgba($color-grey-light-1, .02);
  }

  &--main {
    display: block;
    font-size: 6rem;
    font-weight: 500;
    // text-align: ;
    letter-spacing: 2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

    @include respond(phone){
      letter-spacing: 1rem;
      font-size: 5rem;
    }
  }

  &--header-loggedout {
    display: block;
    font-size: 6rem;
    font-weight: 500;
    //text-align: ;
    text-transform: none;
    letter-spacing: 2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /*animation-delay: 3s;
    *//*animation-iteration-count: 3;
    */
    // -webkit-transform:none;
    // -moz-transform:none;
    // -ms-transform:none;
    // -o-transform:none;
}

  &__loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    margin-top: 10rem;
    padding: 25rem;
    // margin: 25rem;
    font-size: 4rem;
    font-weight: 500;
    // border: .2rem red solid;

  color: white;
    // text-align: ;
    letter-spacing: 2rem;
    // animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &--canceled {
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    justify-items: center !important;
    color:rgba($color-tertiary-light, 1) !important;
    background-color: rgba($color-grey-light-1, .25);
    font-size: 2rem;
    font-weight: 500;
    padding: 15rem auto !important;
    margin-bottom: 25rem;
    // text-align: ;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--create-product {
    display: flex;
    font-size: 3rem;
    font-weight: 500;
    margin:auto;
    padding:2rem 5rem ;
    color: rgba($color-white, .9);
    // background-color:rgba($color-white, .1);
    text-align:center;
    justify-content:center;
    justify-items:center;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

  }

  &--pressRelease {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    font-size: 1.6rem;
    font-weight: 400;
    text-align:justify;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }
  &--main-logo-title {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
     text-transform: none;
    letter-spacing: .5rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--main-seller-name-description {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-white, .7);
    text-align: start;
    text-transform: capitalize;
    text-decoration:none;
    // border: .2rem red solid;
    // margin-bottom: 3rem;
    // margin-top: 3rem;
    // margin-right: 5rem;
    padding:1rem;
    // margin-left: -1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
      &:first-child{
        margin-top: $gutter-vertical;
      }

      @include respond(phone){
        width: 0;
        height: 0;
        opacity: 0;
      }
  }

  &--seller-create-product {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-secondary-dark, .13) !important;
    text-align: start;
    text-transform: capitalize;
    text-decoration:none;
    // border: .2rem red solid;
    // margin-bottom: 3rem;
    // margin-top: 3rem;
    // margin-right: 5rem;
    padding:1rem;
    // margin-left: -1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
      &:first-child{
        margin-top: $gutter-vertical;
      }
  }

  // &--seller-create-product--shipping-section {
  //   width: 100%;
  //   display: flex !important;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   color: rgba($color-primary-light, 1);
  //   background-color: rgba($color-tertiary-dark, .03) !important;
  //   text-align: start;
  //   text-transform: capitalize;
  //   text-decoration:none;
  //   // border: .2rem red solid;
  //   // margin-bottom: 3rem;
  //   // margin-top: 3rem;
  //   margin-right: 5rem !important;
  //   padding:1rem;
  //   // margin-left: -1.2rem;
  //   font-size: 1.2rem;
  //   font-weight: 600;
  //   letter-spacing: .2rem;
  //   animation-name: moveInLeft;
  //   animation-duration: 1s;
  //   animation-timing-function: ease-out;
  //   /* animation-delay: 3s; */
  //   /* animation-iteration-count: 3; */
  //     &:first-child{
  //       margin-top: $gutter-vertical;
  //     }
  // }

  &--main-seller-profile-name-description {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-tertiary-dark, .03);
    text-align: start;
    text-transform: capitalize;
    text-decoration:none;
    // border: .2rem red solid;
    // margin-bottom: 3rem;
    // margin-top: 3rem;
    // margin-right: 5rem;
    padding:1rem;
    margin: .2rem;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
      // &:first-child{
      //   margin-top: $gutter-vertical;
      // }
  }
  &--main-seller-box {
    // border: #20a020 .2rem solid;
    width: 100%;
    height: 43% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-white, .7);
    margin-left: -1rem;
    padding:1rem .1rem 1.5rem .1rem!important;
    font-size: 1.4rem;
    font-weight: 400 !important;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &--main-2 {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 1rem;
    padding: 2rem 2rem 2rem 3rem;
    margin-left: -2rem;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--discount-product{
    width: 80%;
    display: flex;
    justify-content: center !important;
    margin: 2rem auto;
    // margin-left: 3rem;
    color: rgba($color-primary-light, 1);
    font-size: 1.4rem;
    font-weight: 500;
    // border: .1rem red solid;
    &__bold {
      color: red;
      margin-left: 1rem;
      font-size: 1.5rem;
      font-weight: 700;
    }

    &__background{
      background-color: rgba($color-secondary-dark, .03);
      padding: 1rem;
    }

  }

  &--user-column {
    display: block;
    color: $color-tertiary-light;
    background-color: rgba($color-tertiary-dark, .1);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .5rem;
    padding: 1rem 1rem 1rem 2rem;
    // margin-left: 6rem;
    margin-right:8rem;
    margin-bottom:.5rem;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--seller-column {
    display: flex;
    // justify-content:space-between;
    color: $color-tertiary-light;
    background-color: rgba($color-tertiary-dark, .02);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.5rem !important;
    font-weight: 700;
    letter-spacing: .3rem;
    padding: 1rem  1.5rem;
    // margin-left: 6rem;
    margin-right:1.7rem;
    margin-bottom:.7rem;

     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone){
      display: flex;
      // flex-direction: column !important;
      // flex-wrap: nowrap;
      // font-size: 5rem !important;
      margin-bottom: 0 !important;
      padding-bottom: .2rem;
       margin-right:.5rem;

    // margin-bottom:.7rem;
      // border: red solid .1rem;
    }

    &__row {
     margin-left: .5rem;
     font-size: 1.7rem !important;
     @include respond(phone){
     font-size: 3rem !important;
      margin-bottom: .3em;
      margin-top: -1rem;
      padding-top: -.5rem !important;

     }

    }
  }

  &--seller-home-header {
    display: flex;
    // justify-content:space-between;
    color: $color-tertiary-light;
    background-color: rgba($color-tertiary-dark, .07);
    border: .1rem solid rgba($color-tertiary-dark, .3);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .3rem;
    padding: .1rem 2rem .1rem 1rem !important ;
    margin-top:.2rem !important;
    margin-bottom:.2rem !important;
    // margin-left: 6rem;
    // margin-right:3.5rem;
    // margin-bottom:.5rem;

     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    &__row {
     margin-left: .8rem !important;

    }

    &__margin-right {
     margin-right: .8rem !important;

    }
  }

  &--admin-column {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 1rem;
    padding: 2rem 2rem 2rem 3rem;
    margin-left: -2rem;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--seller-public-coupon {
    display: block;
    color: $color-primary-light-2;
    background-color: rgba($color-white, .55);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .2rem;
    padding: 1rem 2rem 2rem 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--main-2-checkout-summary {
    display: block;
    color: $color-primary-light;
    background-color: rgba($color-white, .5);
    border-bottom: .5rem solid $color-grey-light-1;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: .3rem;
    padding: 2rem 2rem 2rem 3rem;
    // margin-left: -2rem;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone){
      font-size: 2.5rem;
    }

    &__empty-cart {
      margin: 3rem auto auto auto;
      // margin-left: 1rem;
    }

  }

  &--main-2-product-card-sub-category {
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-black, .4);
    border-bottom: .15rem solid $color-grey-light-1;
    // border-right: .1rem solid $color-grey-light-1;
    // border-top: .2rem solid $color-grey-light-1;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: .3rem;
    margin-top: 1.3rem;
    padding: 1rem .1rem 1rem 2rem;
    margin-left: -1rem;
     box-shadow: 1rem .5rem 1rem rgba($color-black, .4);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone){
      font-size: 2.5rem;
    }


  }

  &--main-2-product-card-coupon {
    width: fit-content !important;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-secondary-dark, .3) !important;
    // border-bottom: .15rem solid $color-grey-light-1;
    border-right: .1rem solid $color-grey-light-1;
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.6rem !important;
    font-weight: 600;
    letter-spacing: .3rem;
    margin: 20.5rem auto 2rem auto;
    // margin-top: 5rem;
    padding: 1rem .1rem 1rem 1rem;
    margin-left: 1rem;
    // margin-right: 5rem;
    //  box-shadow: 1rem .5rem 1rem rgba($color-black, .2);
    // animation-name: moveInLeft;
    // animation-duration: 1s;
    // animation-timing-function: ease-out;
    @include respond(phone){
      font-size: 3.5rem !important;
       margin: 50.5rem auto 2rem 5rem !important;
    }


  }

  &--main-2-product-card-coupon-home-page {
    width: fit-content !important;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-secondary-dark, .3) !important;
    // border-bottom: .15rem solid $color-grey-light-1;
    border-right: .1rem solid $color-grey-light-1;
    // border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.6rem !important;
    font-weight: 700;
    letter-spacing: .3rem;
    margin: 11rem auto 2rem auto;
    // margin-top: 5rem;
    padding: 1rem .1rem 1rem 1rem;
    // margin-left: -1rem;
    // margin-right: 5rem;
    //  box-shadow: 1rem .5rem 1rem rgba($color-black, .2);
    // animation-name: moveInLeft;
    // animation-duration: 1s;
    // animation-timing-function: ease-out;
    @include respond(phone){
      font-size: 3rem !important;
      font-weight: 700;
       margin: 19.5rem auto 2rem 13rem !important;
    border-bottom: .2rem solid $color-grey-light-1;
    }


  }

  &--main-2-product-card-brand {
    width: fit-content !important;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-black, .15) !important;
    //border-bottom: .15rem solid $color-grey-light-1;
    //border-right: .1rem solid $color-grey-light-1;
    //border-bottom: .2rem solid $color-grey-light-1;
    // border-radius:50% ;
    font-size: 1.7rem !important;
    font-weight: 600;
    letter-spacing: .3rem;
    margin: 9rem auto 2rem auto;
    //margin-top: 5rem;
    padding: .3rem .2rem .3rem 0 !important;
    margin-left: -1rem;
    //margin-right: 5rem;
    //box-shadow: 1rem .5rem 1rem rgba($color-black, .2);
    //animation-name: moveInLeft;
    //animation-duration: 1s;
    //animation-timing-function: ease-out;
    // -webkit-border-radius:50% ;
    // -moz-border-radius:50% ;
    // -ms-border-radius:50% ;
    // -o-border-radius:50% ;
    @include respond(phone){
      font-size: 3rem !important;
    }
}

  &--main-2-product-card-coupon-back-side {
    width:fit-content;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-secondary-dark, .3) !important;
    border-bottom: .1rem solid $color-grey-light-1;
    border-right: .1rem solid $color-grey-light-1;
    // border-top: .1rem solid $color-grey-light-1;
    font-size: 1.7rem !important;
    font-weight: 600;
    letter-spacing: .3rem;
    // position: absolute;
    // top: 5rem;
    // right: 0;
    margin: 11rem auto 2rem 60% !important;
    // margin-top: 8.5rem;
    padding: .8rem 1rem .8rem -2rem;
    // margin-left: 60%;
    // margin-right: -2rem;
     box-shadow: 1rem .5rem 1rem rgba($color-white, .5);
     z-index: 100;
    // animation-name: moveInLeft;
    // animation-duration: 1s;
    // animation-timing-function: ease-out;
    @include respond(lab-top){
      margin-left: 40%;
    }
    @include respond(tab-land){
      margin-left: 40%;
    }
    @include respond(tab-port){
      margin-left: 60%;
    }

    @include respond(phone){
      margin-left: 60%;
      margin-top: 14rem !important;
      font-size: 4rem !important;
    }


  }
  &--main-2-product-card-coupon-home-page-back-side {
    width:fit-content;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-secondary-dark, .3) !important;
    border-bottom: .1rem solid $color-grey-light-1;
    border-right: .1rem solid $color-grey-light-1;
    // border-top: .1rem solid $color-grey-light-1;
    font-size: 1.7rem !important;
    font-weight: 600;
    letter-spacing: .3rem;
    // position: absolute;
    // top: 5rem;
    // right: 0;
    margin: 4rem auto 2rem 60% !important;
    // margin-top: 8.5rem;
    padding: .8rem 1rem .8rem -2rem;
    // margin-left: 60%;
    // margin-right: -2rem;
     box-shadow: 1rem .5rem 1rem rgba($color-white, .5);
     z-index: 100;
    // animation-name: moveInLeft;
    // animation-duration: 1s;
    // animation-timing-function: ease-out;
    @include respond(lab-top){
      margin-left: 40%;
    }
    @include respond(tab-land){
      margin-left: 40%;
    }
    @include respond(tab-port){
      margin-left: 60%;
    }

    @include respond(phone){
      margin-left: 60%;
      margin-top: 5rem !important;
      font-size: 3rem !important;
    }
    // @include respond(small-phone){
    //   margin-left: 60%;
    //   margin-top: 14rem !important;
    //   font-size: 1rem !important;
    // }


  }

  &--main-2-product-card-home-category-3-products-small {
    display: flex;
    justify-content: flex-start;
    text-align: start;
    color: $color-white;
    background-color: rgba($color-black, .3);
    border-bottom: .15rem solid $color-grey-light-1;
    // border-right: .1rem solid $color-grey-light-1;
    // border-top: .2rem solid $color-grey-light-1;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: .2rem;
    margin-top: -1rem;
    padding: 2rem 2rem 1rem 3rem;
    // margin-left: -2rem;
     box-shadow: 1rem .5rem 1rem rgba($color-black, .4);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;



  }

  &--main-2-video-card-title {
    position: absolute;
    top: 10%;
    left: 5%;
    display: block;
    color: rgba($color-tertiary-light, 1);
    background-color: rgba($color-white, .7);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .3rem;
    margin-top: -2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    // padding: 2rem 2rem 2rem 3rem;
    // margin-left: -2rem;
    box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    &__empty-cart {
      margin: 3rem auto auto auto;
      // margin-left: 1rem;
    }

  }
  &--main-2-video-filter {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .8);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .3rem;
    text-align: center;
    margin-top: -2rem;
    padding: 2rem ;
    // margin-left: -2rem;
    box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &--main-2-successful-payment-order-details {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .3);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .3rem;
    padding: 2rem 2rem 2rem 3rem;
    // margin-left: -2rem;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    &__empty-cart {
      margin: 3rem auto auto auto;
      // margin-left: 1rem;
    }

  }

  &--main-2-profile-welcome {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .5rem;
    padding: 2rem 1rem 2rem 1rem;
    margin-left: 2rem;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  // &--main-2-shop {
  //   display: block;
  //   color: $color-primary-light-3;
  //   // background-color: rgba($color-white, .5);
  //     background-color: rgba($color-tertiary-light, .2);
  //   border-bottom: .05rem solid $color-grey-light-1;
  //   font-size: 1.8rem;
  //   font-weight: 700;
  //   letter-spacing: .5rem;
  //   padding: 2rem 1rem 2rem 1rem;
  //   margin-left: 2rem;
  //   // border: .2rem red solid;
  //    box-shadow: 0 .5rem 1rem rgba($color-white, .2);
  //   animation-name: moveInLeft;
  //   animation-duration: 1s;
  //   animation-timing-function: ease-out;
  //   /* animation-delay: 3s; */
  //   /* animation-iteration-count: 3; */
  // }

    &__view-seller-menu{
     width: 0;
     height: 0;
     opacity: 0;
     @include respond(phone){
       width: fit-content;
      height: fit-content;
      opacity: 1;
      display: flex;
      flex-direction: column;
      color: rgba($color-primary-light, 1);
      font-size: 3rem;
      font-weight: 700;
      background-color: rgba($color-white, .5);
        box-shadow: 0 .5rem .5rem rgba($color-black, .2);
      margin: 5rem !important;
      padding: 3rem;

       &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 .1rem .1rem rgba($color-black, .1);
      text-decoration: none !important;

    }
     }
    }

   &--main-2-create-product {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .9);
    border-bottom: .5rem solid $color-grey-light-1;
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 1rem;
    padding: 2rem 2rem 2rem 3rem;
    margin-left: -2rem;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .1);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

   &--main-2-admin-all-products-title {
    width: 75%;
    display: block;
    // color: $color-primary-light-3;
    color: $color-white;
    background-color: rgba($color-white, .08);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: .5rem;
    text-align: center;
    padding: 3rem 0rem ;
    // margin-left: -2rem;
    margin: 5rem auto 10rem auto;
    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-black, .12);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }


   &--main-2-admin-nav {
    width: 99%;
    height: fit-content;
    display: block;
    // flex-direction: column;
    justify-content:space-between;
    // justify-items: start;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, 1);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 10rem 0 10rem 1rem;

    // margin-left: -2rem;
    margin: 5rem auto 1rem .5rem;
    // border: .2rem red solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

    @include respond(phone){
      width: 0;
      height: 0;
      opacity: 0;
    }


   &__phone {
   width: 0;
   height: 0;
   opacity: 0;
   position: relative;
   top: -30rem;

   @include respond(phone){
     width: 99%;
    height: fit-content;
    // display: flex;
    // flex-direction: column;


    opacity: 1;
    // flex-direction: column;
    justify-content:space-around;
    // justify-items: start;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, 1);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 5rem 0 5rem 1rem;

    // margin-left: -2rem;
    margin: 10rem auto 1rem .5rem;
    // border: .2rem blue solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */


   }
  }

   &__phone-seller {
   width: 0;
   height: 0;
   opacity: 0;
   position: relative;
  //  top: -30rem;

   @include respond(phone){
     width: 65%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    opacity: 1;
    // flex-direction: column;
    justify-content:space-around;
    // justify-items: start;
    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, 1);
    border-bottom: .2rem solid $color-grey-light-1;
    // font-size: 1.3rem;
    // font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 5rem 0 .5rem 1rem;
    // margin-left: -2rem;
    margin: .5rem auto .1rem .5rem;
    // border: .2rem blue solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    // margin-bottom: 125rem !important;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */


   }
  }

  }

   &--main-2-admin-nav-wishlist {
    width: 99%;
    height: fit-content;
    display: block;
    // flex-direction: column;
    justify-content:space-between;
    // justify-items: start;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, 1);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 10rem 0 10rem 1rem;

    // margin-left: -2rem;
    margin: 5rem auto 1rem .5rem;
    // border: .2rem red solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

    @include respond(phone){
      width: 0;
      height: 0;
      opacity: 0;
    }


   &__phone {
   width: 0;
   height: 0;
   opacity: 0;
   position: relative;
   top: -30rem;

   @include respond(phone){
     width: 99%;
    height: fit-content;
    display: flex;
    opacity: 1;
    // flex-direction: column;
    // justify-content:space-around;
    // justify-items: start;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, 1);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 5rem 0 5rem 1rem;

    // margin-left: -2rem;
    margin: 15rem auto 1rem .5rem;
    // border: .2rem blue solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */


   }
  }



  }


   &--main-single-product-nav {
    width: 100%;
    height: fit-content ;
    display: flex;
    // flex-direction: column;
    justify-content:center;
    justify-items: center;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    // background-color: rgba($color-white, .6);
    border-bottom: .2rem solid $color-grey-light-1;
    // font-size: 1rem;
    // font-weight: 700;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    // padding: 2rem 0 1rem 5rem;

    // margin-left: -2rem;
    // margin: -7rem -1rem 2rem 0 !important;
    // border: .2rem red solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.02) translateY(-.1rem);
      box-shadow: 0 .3rem .3rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.02) translateY(-.1rem);
      -moz-transform:scale(1.02) translateY(-.1rem);
      -ms-transform:scale(1.02) translateY(-.1rem);
      -o-transform:scale(1.02) translateY(-.1rem);
}


  }

   &--main-seller-public-profile-column-nav {
    width: fit-content;
    height: 50rem ;
    display: flex;
    flex-direction: column;
    justify-content:center;
    justify-items: center;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, .6);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 3rem 0 3rem 1rem;

    // margin-left: -2rem;
    margin: -7rem -1rem 2rem 0 !important;
    // border: .2rem red solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.02) translateY(-.1rem);
      box-shadow: 0 .5rem .5rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.02) translateY(-.1rem);
      -moz-transform:scale(1.02) translateY(-.1rem);
      -ms-transform:scale(1.02) translateY(-.1rem);
      -o-transform:scale(1.02) translateY(-.1rem);
}


  }
   &--main-seller-single-product-column-nav {
    width: fit-content;
    height: fit-content ;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    justify-items: flex-start;

    // color: $color-primary-light-2 !important;
    // color: $color-black;
    background-color: rgba($color-white, .6);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    text-align: start;
    text-decoration:none;
    padding: 8rem 0 3rem 1rem;

    // margin-left: -2rem;
    margin: 5rem 1rem 5rem 0 !important;
    // border: .2rem red solid;
     box-shadow: 0 1rem 1.5rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

    @include respond(phone){
      width: 100vw;
      display: flex;
      flex-direction: row !important;
      margin-top: 3rem !important;
      margin-left: 10rem;
      margin-right: 10rem;
      padding-top: 5rem;
    }

     &:hover {
      outline: .7rem solid rgba($color-white, .3);
      outline-offset: 2rem;
      //border-radius: 0;
      transform:scale(1.02) translateY(-.1rem);
      box-shadow: 0 .5rem .5rem rgba($color-black, .5);
      z-index: 100;
      -webkit-transform:scale(1.02) translateY(-.1rem);
      -moz-transform:scale(1.02) translateY(-.1rem);
      -ms-transform:scale(1.02) translateY(-.1rem);
      -o-transform:scale(1.02) translateY(-.1rem);
}


  }


   &--main-2-cart-summary {
    width: 80%;
    height:fit-content;
    display: block;
    // flex-direction: column;
    justify-content:space-between;
    // justify-items: start;
       // color: $color-primary-light-3;
    // color: $color-black;
    // background-color: rgba(#304ffe, .04);
    border-bottom: .2rem solid $color-grey-light-1;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .5rem;
    text-align: start;
    text-decoration:none;
    padding: .5rem 3rem .5rem 3rem;

    // margin-left: -2rem;
    margin: 5rem auto 1rem auto;
    // border: .2rem red solid;
    //  box-shadow: .5rem .5rem .5rem rgba($color-black, .1);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

    @include respond(phone){
      width: 100%;
      // font-size: 5rem !important;
    }

  }

  &--main-2-all-product {
    width: 100%;
    display: block;
    color: $color-primary-light-3;
    // background-color: rgba($color-tertiary-dark, .06);
    // border-bottom: .05rem solid $color-grey-light-1;
    font-size: 2rem;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: .1rem;
    // padding: 4rem 1rem ;
    // margin-right: 1rem;
    // margin-left: auto;
    // margin: 5rem auto 10rem -2rem;

    // border: .2rem red solid;
    //  box-shadow: .5rem .5rem 1rem rgba($color-white, .3);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--main-podcast-video {
    display: block;
    color: $color-primary-light-3;
    // background-color: rgba($color-tertiary-light, .8);
    // border-bottom: .05rem solid $color-grey-light-1;
    font-size: 3rem;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: .3rem;
    padding: 2rem 2rem ;
    // margin-right: 1rem;
    // margin-left: auto;
    // margin: 5rem auto 10rem -2rem;

    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }


  &--seller-home-podcast-video {
    display: block;
    color: $color-primary-light-3;
    // background-color: rgba($color-tertiary-light, .8);
    // border-bottom: .05rem solid $color-grey-light-1;
    font-size: 3rem;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: .3rem;
    padding: 0 auto ;
    // margin-right: 1rem;
    // margin-left: auto;
    // margin: 5rem auto 10rem -2rem;

    // border: .2rem red solid;
    //  box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }


  &--main-2-sub-category {
    display: block;
    color: $color-primary-light-3;
    // background-color: rgba($color-white, .5);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 3rem;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: .3rem;
    padding: 2rem auto;
    margin-bottom: 3rem;
    margin-right: 0rem;
    // margin-left: -1.5rem;
    // margin: 5rem auto 10rem -2rem;

    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--main-2-all-product-title {
    display: block;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    border-bottom: .05rem solid $color-grey-light-1;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: .5rem;
    padding: 2rem 0;
    // margin-left: -2rem;
    margin: 5rem 12rem 10rem 8rem;

    // border: .2rem red solid;
     box-shadow: 0 .5rem 1rem rgba($color-white, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
  }

  &--main-2-main-shop-page-title {
    width: fit-content;
    display: block;
    height: fit-content;
    // color: $color-primary-light-3;
    color: $color-white;
    // background-color: rgba($color-white, .1);
    border-bottom: 1rem solid rgba($color-grey-light-1, .5);
    font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: .5rem;
    text-align: center;
    padding: .5rem 5rem ;
    margin: 1rem auto;
    // margin-left: 25rem;
    // border: .2rem red solid;
     box-shadow: 0 .3rem .3rem rgba($color-white, .1);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */

    &_description{
       width: 75%;
    display: block;
    // color: $color-primary-light-3;
    color: rgba($color-white, .8);
    // background-color: rgba($color-tertiary-dark, 1);
    // border-bottom: 1px solid $color-grey-light-1;
    font-size: 2rem;
    // font-weight: 600;
    letter-spacing: .2rem;
    text-align: center;
    padding: 1rem 0rem ;
    margin: -1rem auto 1rem auto;
    // margin-left: 25rem;
    // border: .2rem red solid;
    //  box-shadow: 0 .1rem .1rem rgba($color-black, .2);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    }
  }

  &--welcome-user-home-page {
    width: fit-content;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: fit-content;
    // color: $color-primary-light-3;
    color: $color-white;
    // background-color: rgba($color-white, .07);
    // border-bottom: .1rem solid rgba($color-grey-light-1, .5);
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: .5rem;
    text-align: center;
    padding: 3rem 10rem ;
    margin: 12rem auto;
    // margin-left: 25rem;
    // border: .2rem red solid;
     box-shadow: 0 .3rem .3rem rgba($color-white, .07);
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /* animation-delay: 3s; */
    /* animation-iteration-count: 3; */
    @include respond(phone){
      font-size: 7rem;
      margin-top: 20rem;
    }


  }

  // &--main-2-main-shop-page-description {

  //   /* animation-delay: 3s; */
  //   /* animation-iteration-count: 3; */
  // }

  &--main-2-seller-pict-seller-video-page {
    width: 60% !important;
    height:40% !important;
    display: block;
    border-radius:50% !important;
    // font-size:.5rem !important;
    // border: yellow .5rem solid;
    padding:  0 ;
    margin: 1rem auto 1rem auto;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &--sub {
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    // font-weight: bolder !important;
    letter-spacing: .7rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

       &:hover {
      transform: translateY(-.5px);
      -webkit-transform: translateY(-.5px);
      box-shadow: 0 .2rem .2rem rgba($color-black, .2);
      -moz-transform: translateY(-.5px);
      -ms-transform: translateY(-.5px);
      -o-transform: translateY(-.5px);
}

    @include respond(phone){
      letter-spacing: .5rem;
    };
  }

  &--sub-filters-shop {
    display: block;
    width: fit-content;
    padding: 1rem 2rem;
    font-size: 3rem;
    font-weight: 700;
    // font-weight: bolder !important;
    letter-spacing: .7rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

       &:hover {
      transform: translateY(-.5px);
      -webkit-transform: translateY(-.5px);
      box-shadow: 0 .2rem .2rem rgba($color-black, .2);
      -moz-transform: translateY(-.5px);
      -ms-transform: translateY(-.5px);
      -o-transform: translateY(-.5px);
}

    @include respond(phone){
      letter-spacing: .5rem;
       box-shadow: 0 .2rem .2rem rgba($color-black, .2);
       font-size: 3.5rem;
    };
  }
  &--sub-filters-videos {
    display: block;
    width: fit-content;
    padding: 1.5rem 3rem;
    font-size: 2.5rem;
    font-weight: 700;
    // font-weight: bolder !important;
    letter-spacing: .7rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

      box-shadow: 0 .1rem .1rem rgba($color-black, .2);
      cursor: pointer;

       &:hover {
      transform: translateY(-.5px);
      -webkit-transform: translateY(-.5px);
      box-shadow: 0 .3rem .3rem rgba($color-black, .2);
      -moz-transform: translateY(-.5px);
      -ms-transform: translateY(-.5px);
      -o-transform: translateY(-.5px);
}

    @include respond(phone){
      letter-spacing: .5rem;
      padding: 1.5rem 5rem;
      box-shadow: 0 .2rem .2rem rgba($color-black, .2);
      font-size: 3.5rem;
    };
  }

  &--sub-order-titles {
    width: fit-content;
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    padding-right: 2rem;
    padding-left: 3rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
    background-color: rgba($color-white, .17);
    // border-bottom: .2rem rgba($color-tertiary-dark, .5) solid ;
    box-shadow: .01rem .4rem .1rem rgba($color-white, .3);
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
    }
  }

  &--sub-order-summary {
    width: 100%;
    display: flex;
    font-size: 1.7rem;
    font-weight: 700 !important;
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin: 2rem auto;
    padding: 2rem 1rem;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-tertiary-dark, .05);
    // border-bottom: .2rem rgba($color-tertiary-dark, .5) solid ;
    box-shadow: .1rem .4rem .1rem rgba($color-white, 1);
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
    }
  }

  &--sub-order-contact-message-button-background {
    width: 100%;
    display: flex;
    font-size: 1.7rem;
    font-weight: 700 !important;
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin: 2rem 0 0 0 !important;
    padding: .2rem .1rem;
    background-color: rgba($color-tertiary-dark, .09);
    border: rgba($color-black, .1) solid .2rem;
    // border-bottom: .2rem rgba($color-tertiary-dark, .5) solid ;
    // box-shadow: .1rem .4rem .1rem rgba($color-white, 1);
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
      margin-bottom: 10rem !important;

    }

  }

  &--sub-order-shipping-type {
    width: fit-content;
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    margin:-1rem 5rem 2rem 0;
    padding-right: 2rem;
    padding-left: 3rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .2);
    // border-bottom: .2rem rgba($color-tertiary-dark, .5) solid ;
    // box-shadow: .01rem .1rem .1rem rgba($color-black, .1);
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 2.5rem;
    }
  }

  &--download-pdf {
    width:100%;
    display: flex;
    justify-content:center;
    justify-items:center;
    margin:1rem auto 2rem auto !important;
    padding: 2rem auto !important;
    // border: .01rem solid rgba($color-black, .08);
    // color: rgba($color-primary-light, .1)!important;
    // background-color: rgba($color-tertiary-dark, .5);
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-logo-title {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .1rem;
    margin-bottom: 2rem;
    // border-bottom: .2rem rgba($color-white, 1) solid;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }
  &--sub-got-coupon {
    display: block;
    color: rgba($color-black, .5) !important;
    font-size: 2.2rem;
    font-weight: 700 !important;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3.3rem;
    }

    &__note{
      font-size: 1.5rem;
      @include respond(phone){
        font-size: 3rem;
      }
    }
  }

  &--sub-successful-payment {
    width: fit-content;
    display: block;
    color: rgba($color-tertiary-light, 1);
    background-color: rgba($color-white, .06);
    margin-top: 0;
    margin-bottom: -2rem;
    padding: 0rem;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .8rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &__margin-bottom {
      margin-bottom: 15rem;
    }
  }


  &--sub-customer-pictures-our-community {
    width: fit-content;
    display: block;
    // color: rgba($color-tertiary-light, 1);
    color: rgba($color-primary-light, 1);
    background-color: rgba(white, .5);
    margin-top: 5rem;
    margin-bottom: .1rem !important;
    padding: 1.3rem 7rem 1.3rem 3rem !important;
    border-bottom: .5rem rgba($color-primary-light, .5) solid;
    font-size: 2.5rem;
    font-weight: 700 !important;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &__margin-bottom {
      margin-bottom: 15rem;
    }
  }

  &--sub-1 {
    display: block;
    // margin-left: 32rem;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-1-checkout-payment {
    display: block;
    margin-top: 5rem;
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: .5rem;
    text-align: center;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &__success {
      color: rgba($color-grey-dark, 1);
    }

    &__no-coupon {
      color: rgba(221, 29, 29, 1);
    }
  }

  &--sub-1-checkout-payment-saved {
    display: block;
    margin-top: .5rem;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .5rem;
    text-align: center;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &__color {
      color: rgba($color-primary-dark, 1);
    }

    // &__color {
    //   color: rgba($color-secondary-dark,  .7);
    // }
  }

  &--sub-2 {
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    display: block;
    padding: 1rem 7rem;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(phone){
      font-size: 3rem;
    }

    &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .2);

    }

  }

  &--setup-stripe-payout-acount {
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    display: block;
    padding: 1rem 7rem;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
    }

    &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .2);
      text-decoration: none !important;

    }

  }

  &--domain {
    height: fit-content;
    color:rgba($color-primary-light, 1);
    background-color: rgba($color-white, 1) !important;
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    padding: 7rem 3rem;
    margin: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    justify-items: center !important;
    border: none;
    border-radius: .5rem;
    text-transform: none;
    text-align:center !important;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: .1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
      padding: 2rem auto;
    }


  }

  &--sub-2-dashboard-admin {
    width: 70%;
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .6);
    box-shadow: .2rem .2rem .2rem rgba($color-black, .2);
    display: block;
    padding: 2rem 7rem 2rem 7rem;
    margin: 0 auto 2rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }


  &--sub-2-dashboard-admin-payment-info {
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .5);
    box-shadow: .2rem .2rem .2rem rgba($color-black, .2);
    display: block;
    padding: 2rem 7rem .5rem 7rem;
    margin: 2rem auto 2rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--sub-2-customer-history-payment-info {
    width: 95%;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-white, 1);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .2);
    display: block;
    padding: 2rem 7rem .5rem 7rem;
    margin: 2rem auto 2rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 2.2rem;
      padding-left: .5rem;
      padding-right: .5rem;
    }

  }


  &--sub-2-product-update {
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .26);
    box-shadow: 0 .5rem .5rem rgba($color-white, .01);
    display: block;
    padding: 2rem 2rem 2rem 2rem;
    margin: 5rem auto 1rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    text-align:center;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(phone){
      font-size: 3rem;
    }

    &__err {
      color:rgba(red, .5);
    }

  }

  &--sub-2-product-customer-review {
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .6);
    box-shadow: 0 .5rem .5rem rgba($color-white, .01);
    display: block;
    padding: 2rem 2rem 2rem 2rem;
    margin: 5rem auto 1rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    text-align:center;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(phone){
      font-size: 3rem;
      padding: 3rem 5rem !important;
    }

    &__err {
      color:rgba(red, .5);
    }

  }

  &--sub-2-customer-pictures {
    width: fit-content;
    color: rgba($color-white, 1);
    background-color: rgba($color-tertiary-dark, .3);
    box-shadow: 0 .5rem .5rem rgba($color-white, .01);
    display: flex;
    padding: 2rem 4rem  !important;
    margin: 5rem auto -1rem auto;
    border: none;
    border-radius: 1rem;
    text-transform: uppercase;
    text-align:center;
    font-size: 1.4rem;
    font-weight: 700 !important;
    letter-spacing: .2rem;
       -webkit-border-radius:1rem;
    -moz-border-radius:1rem;
    -ms-border-radius:1rem;
    -o-border-radius:1rem;
    letter-spacing: .1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
    }
    //&__err:;


    //   color:rgba(red, .5);
    // }

  }

  &--sub-2-shop-card-title {
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-black, .00);
    box-shadow: 0 .5rem .5rem rgba($color-white, .01);
    display: block;
    width: 100%;
    padding: 3rem .5rem .5rem 1rem;
    margin:-3rem 0 3rem 2.5rem;
    // border: .2rem red solid;
    border-radius: .5rem;
    text-transform: uppercase;
    text-align: start;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
}

  &--sub-2-user-single-product{
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .4);
    box-shadow: .2rem .3rem .2rem rgba($color-black, .1);
    display: block;
    padding: 2rem ;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }
  &--sub-2-product-list-items{
    height: fit-content;
    // color: rgba($color-tertiary-light, .8);
    // background-color: rgba($color-white, .5);
    // box-shadow: .2rem .3rem .2rem rgba($color-black, .1);
    display: block;
    padding: .5rem .9rem .5rem .5rem;
    margin: 0 ;
    // border: red solid .1rem;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;



  }

  &--sub-2-successful-payment {
    width: 55%;
    color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .3);
    box-shadow: 0 .5rem .5rem rgba($color-white, .01);
    display: block;
    padding: 3rem 0;
    margin: 5rem auto 10rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }
  &--sub-2-checkout-steps {
    width: 85%;
    // color: rgba($color-tertiary-light, .8);
    background-color: rgba($color-white, .75);
    box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
    display: flex;
    // padding: .1rem auto !important;
    margin: 7rem auto 1rem auto !important;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: .5rem;
    font-weight: 400;
    letter-spacing: .1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(phone){
      width: 90%;
      margin-top: 10rem !important;
      padding: 0 2rem;
      // border: 1rem red solid;
    }

  }

  &--sub-2-create-product {
    color: $color-primary-light-3;
    background-color: rgba($color-white, .1);
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    display: block;
    padding: 1rem 7rem;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .2);
    }

  }

   &--sub-2-create-category {
      color: $color-primary-light-3;
      background-color: rgba($color-white, 1);
      box-shadow: 0 .5rem .5rem rgba($color-black, .05);
      display: block;
      padding: 1rem 7rem;
      margin: 0 auto;
      border: none;
      border-radius: .5rem;
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: 1rem;
      animation: moveInRight 1s ease-out;
      -webkit-animation: moveInRight 1s ease-out;

      &:hover {
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        box-shadow: 0 1rem 2rem rgba($color-black, .2);
      }

  }

  &--sub-2-user-order-history {
    width: 70%;
    color: rgba($color-primary-light,.8);
    background-color: rgba($color-tertiary-dark, .04);
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    display: block;
    padding: 2rem 2rem;
    margin: 5rem auto !important;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 2.5rem;
    }
  }

  &--seller-dashboard-experience-education-title {
    width: 70%;
    color: $color-primary-light-3;
    background-color: rgba($color-secondary-dark, .06);
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    display: block;
    margin: 10rem auto 1rem auto !important;
    padding: 2rem 2rem;
    border: none;
    border-radius: .5rem;
    text-align:center;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--profile-exp-edu-title {
    width: 70%;
    color: $color-primary-light-3;
    background-color: rgba($color-tertiary-dark, .06);
    box-shadow: 0 .5rem .5rem rgba($color-tertiary-dark, .15);
    display: block;
    padding: 2rem 2rem;
    margin: 5rem auto 3rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-2-seller-profile-dashbaord-profile-title {
    width:60%;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    display: block;
    padding: 2rem 2rem;
    // margin: 5rem 15rem 1rem 15rem;
    margin: 8rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    text-align:center;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-2-seller-profile-page-title {
    width: 60%;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    // border:.5rem  red solid;
    box-shadow: 0 1.5rem 2rem rgba($color-tertiary-dark, .06);
    display: block;
    padding: 1rem ;
    margin: 2rem auto 1rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-2-seller-profile-dashbaord-logo {
    width: 100%;
    height:fit-content!important;
    color: $color-tertiary-light;
    background-color: rgba($color-white, .7);
    display: block;
    flex-direction:column;
    padding: 1rem auto 0 auto;
    //margin: 2rem auto;
    border: none;
    border-radius: 1%;
    text-align:center;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    @include respond(phone){
      font-size: 2.2rem;
    }
}

  &--sub-2-user-order-details {
    width: 55%;
    // color: $color-primary-light-3;
    color: $color-white;
    background-color: rgba($color-tertiary-dark, .2);
    display: block;
    padding: 4rem 8rem;
    margin: 2rem auto 10rem auto;
    border-bottom: .2rem solid rgba($color-black, .2);
    border-radius: .5rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .7rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

}
  &--sub-2-user-wish-title {
    width: 60%;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .3);
    display: block;
    padding: 5rem 2rem;
    margin: 5rem auto -5rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3 {

    // margin:4rem auto;
     padding: 2rem 2rem 2rem 3rem;
     margin-top: -3rem;
    // margin-left: -2rem;
    // color: $color-primary-light-2;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(phone){
      width: 100%;
      // padding: 0 !important;
      margin: 1rem auto !important;
      padding: 1rem auto !important;
    }
  }

  &--admin-user-list {
     padding: 0 0 20rem 0;
     margin-top: 3rem;
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--seller-create-home-form {

    width: 85vw;
    padding: 2rem 2rem 2rem 3rem;
    margin: 3rem auto ;
    background-color: rgba($color-black, .01);
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-seller-dashboard {

    // margin:4rem auto;
     padding: 2rem 2rem 2rem 3rem;
     margin-top: 13rem;
    // margin-left: -2rem;
    // color: $color-primary-light-2;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-admin-dashboard {


     padding: 2rem 2rem 2rem 3rem;
     margin-top: 18rem;

    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-admin-order-list {


     padding: 2rem 2rem 2rem 2rem;
     margin-top: 5rem;

    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &__profile {
    // width: fit-content !important;
   display:flex;
   flex-direction:row;
   justify-content:space-between;
   padding-bottom:1rem;

    &--info {
    // width: 75% !important;
    color:rgba($color-black, 1);
    font-weight:500;
    // margin-right:5rem;

   }
   &--description {
      width:100% !important;
      // display:block;
      color:rgba($color-black, .7);
      font-weight:500;
      // flex-direction:column !important;
      justify-content:flex-start;
    }

    &--description-info {
      width:100% !important;
      display:flex;
      margin-right: 2rem;
      justify-content:flex-end !important;
      justify-items:flex-end;
      padding-bottom:1rem;
    }

  }



  &--sub-3-order-details-info {
    width: fit-content;
    margin:4rem auto;
     padding: 2rem ;
    //  margin-top: -3rem;
    // margin-left: -2rem;
    // color: $color-primary-light-2;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }
  &--sub-3-profile {
     padding: 2rem 2rem 2rem 3rem;
     margin-top: 6rem;
    display: flex;
    flex-direction:column;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
      // padding: 4rem 5rem !important;
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
  }
  &--sub-3-edit-profile {
    padding-left: 8rem;
    padding-top: 4rem;
    padding-bottom: 5rem;
    // padding-right: 0rem;
    background-color: rgba($color-white, .7);
    margin: 2rem 5rem ;
    // border: #20a020 solid .2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 2.7rem;

    }
  }

  &--sub-3-checkout-shipping-address {
     padding: 16rem 2rem 2rem 3rem;
     margin-top: 3rem;

    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-order-list-for-seller {
    width: 80vw;
    // margin:4rem auto;
     padding: 2rem 2rem 2rem 3rem;
     margin-top: -3rem;
    // margin-left: -2rem;
    // color: $color-primary-light-2;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-create-product {

    margin:6rem auto;
     padding: 2rem 2rem 2rem 3rem;
     margin-top: 3rem;
    // margin-left: -2rem;
    // color: $color-primary-light-2;
    box-shadow: .1rem .5rem .5rem rgba($color-black, .05);
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-create-coupon {
    width: 85%;
    margin:6rem auto;
     padding: 5rem 2rem 5rem 3rem;
     margin-top: 3rem;
    // margin-left: -2rem;
    color: $color-primary-light-2;
    background-color: rgba($color-white, .5);
    box-shadow: .2rem .5rem 1.5rem rgba($color-black, .05);
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-3-create-category {
    width: 65%;
    margin:6rem auto;
     padding: 2rem 2rem 2rem 6rem;
     margin-top: 3rem;
    // margin-left: -2rem;
    // color: $color-primary-light-2;
    background-color: rgba($color-white, .3);
    box-shadow: .1rem .5rem .5rem rgba($color-black, .05);
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
  }

  &--sub-4 {
    color: $color-primary-light-3;
    background-color: rgba($color-white, .7);
    box-shadow: 0 .5rem 1rem rgba($color-black, .05);
    display: block;
    padding: 2rem 5rem;
    margin: 0 auto;
    border: none;
    border-radius: 8rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);

      box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(-1px);
       -webkit-transform: translateY(-1px);
      box-shadow: 0 .5rem 1rem rgba($color-black, .3);

    }

  }

  &--sub-5 {
    color: $color-primary-light-3;
    background-color: rgba($color-white, .8);
    box-shadow: 0 1rem 2rem rgba($color-black, .05);
    display: block;
    padding: 1.5rem 7rem;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
    }

    @include respond(phone){
      font-size: 3rem;
    }
  }

  &--sub-save-general-cart {
    color: rgba($color-primary-light-3, 1) ;
    background-color: rgba($color-white, .5);
    box-shadow: 0 1rem 2rem rgba($color-black, .05);
    display: block;
    padding: 1rem 7rem;
    margin: 0 auto;
    border: none;
    border-radius: 7rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
    }
  }

  &--sub-save-general-cart-login {
    width: 45%;
    color: rgba($color-primary-light-3, 1) ;
    background-color: rgba($color-white, .5);
    box-shadow: 0 1rem 2rem rgba($color-black, .05);
    display: block;
    padding: 1rem 3rem;
    margin: 0 auto;
    border: none;
    border-radius: 7rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
    }
  }

  &--sub-6 {
    width: 50%;
    color: $color-primary-light-3;
    background-color: rgba($color-white, .9);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: block;
    padding: 2rem ;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;


  }
  &--jumbotron-type-script {
    width: 60%;
    color: $color-primary-light;
    background-color: rgba($color-white, .7);
    box-shadow:   1rem .5rem 2rem rgba($color-black, .1);
    display: block;
    padding: 1rem ;
    margin: 1rem auto 2rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 2.3rem;
    font-weight: 700;
    font-weight: bolder !important;
    letter-spacing: .7rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem !important;
      font-weight: bolder;
    }


  }

  &--new-arrivals-title {
    width:fit-content;
    color: $color-primary-light;
    // font-weight: 700;
    background-color: rgba($color-white, .2);
    box-shadow: 0 .2rem 0 rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  2rem 10rem;
    margin: 5rem auto 3rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(phone){
      font-size: 2.7rem;
      background-color: rgba($color-white, .3);
    }

  }

  &--seller-coupon {
    width: fit-content;
    color: $color-primary-light;
    // font-weight: 700;
    background-color: rgba($color-white, .5);
    border-bottom: .2rem solid rgba($color-tertiary-dark, .5);
    // box-shadow: .2rem .2rem .2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  2rem 10rem;
    margin: 10rem auto 1rem auto !important;
    // border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .15rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
    }

  }

  &--seller-dashboard-company-name {
    width: 75%;
    color: $color-primary-light;
    // font-weight: 700;
    background-color: rgba($color-white, .5);
    // box-shadow: .01rem .2rem .2rem rgba($color-tertiary-dark, .2);
    display: flex;
    justify-content: center;
    padding:  2rem ;
    margin: 2rem auto 5rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .15rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 3rem;
    }

  }

  &--category-products-3-and-2-title {
    width: 100%;
    color: $color-tertiary-light;
    // font-weight: 700;
    background-color: rgba($color-white, .5);
    // box-shadow: 1rem .5rem 2rem rgba($color-secondary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  1rem ;
    margin: 1rem auto 1rem 0;
    border-bottom: .2rem rgba($color-tertiary-light, .2) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    @include respond(phone){
      font-size: 2.7rem !important;
    }

  }

  &--home-1-best-sellers-title {
    width: 60%;
    color: $color-white;
    // font-weight: 700;
    background-color: rgba($color-white, .07);
    box-shadow: 0  .1rem .1rem rgba($color-black, .1);
    display: flex;
    justify-content: center;
    padding: 1.5rem 1.3rem ;
    margin: 4rem auto 0 auto;
    // border-bottom: .1rem white solid;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--home-1-best-sellers-title-2 {
    width: 55%;
    color: rgba($color-black, .7);
    // font-weight: 700;
    background-color: rgba($color-white, .85);
    // box-shadow: 0  .1rem .1rem rgba($color-black, .1) !important;
    display: flex;
    justify-content: center;
    padding: 2rem 1rem ;
   margin: 3rem auto 1.3rem auto;
    // border-bottom: .1rem white solid;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    font-weight: bolder !important;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

     @include respond(tab-port){
      font-size: 3rem;
      font-weight: bolder;
    }
    @include respond(phone){
      width: 75%;
      font-size: 3rem;
      font-weight: bolder;
      // margin: 3rem auto .5rem auto;

    }
    @include respond(small-phone){
      width: 75%;
      font-size: 3rem;
      font-weight: bolder;
    }

  }

  &--home-1-best-sellers-title-3 {
    width: 65%;
    color: rgba(34, 44, 157, 0.6);
    // font-weight: 700;
    // background-color: rgba(#1d497d, 0.2);
    background-color: rgba(34, 44, 157, 0.0513);
    // background-color: rgba(34, 44, 157, 0.13);
    box-shadow: 0  .1rem .1rem rgba($color-black, .1) !important;
    display: flex;
    justify-content: center;
    padding: 2rem 1.3rem ;
    margin: 1rem auto 1rem auto;
    // border-bottom: .1rem white solid;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    // font-weight: 700;
    // font-weight: bolder !important;
    font-weight: bolder !important;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(tab-port){
      font-size: 3rem;
      font-weight: bolder;
    }
    @include respond(phone){
      width: 75%;
      font-size: 3.5rem;
      font-weight: bolder;

    }
    @include respond(small-phone){
      width: 75%;
      font-size: 3.5rem;
      font-weight: bolder;
    }

  }

  &--seller-profile-categories-and-sub-title {
    width: 100%;
    color: $color-white;
    // font-weight: 700;
    background-color: rgba($color-tertiary-dark, .3);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  2rem ;
    margin: 3rem auto 1rem auto;
    // border: red .1rem solid;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--seller-home-new-arrivals-title {
    width: 65%;
    color: $color-tertiary-light;
    // font-weight: 700;
    background-color: rgba($color-tertiary-light, .03);
    box-shadow: .1rem .5rem .2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  2rem ;
    margin: 1rem auto 3rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--seller-home-best-seller-title {
    width: 65%;
    color: $color-white;
    // font-weight: 700;
    // background-color: rgba($color-white, .3);
    box-shadow: .1rem .5rem .1rem rgba($color-tertiary-light, .5);
    display: flex;
    justify-content: center;
    padding:  2rem 1rem;
    margin: 2rem auto -1rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--seller-home-best-seller-podcasts-title {
    width: 65%;
    color: $color-white;
    //font-weight: 700;
    //background-color: rgba($color-white, .3);
    //box-shadow: 0 .2rem .1rem rgba($color-white, .9);
    display: flex;
    justify-content: center;
    padding:  2rem 1rem;
    margin: 2rem auto 5rem auto;
    border: none;
    border-bottom: rgba($color-white, .5) solid .2rem;
    // border-radius: 30%;
    text-transform: uppercase;
    //text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &----home-podcasts-title {
    width: 65%;
    color: $color-white;
    //font-weight: 700;
    //background-color: rgba($color-white, .3);
    //box-shadow: 0 .2rem .1rem rgba($color-white, .9);
    display: flex;
    justify-content: center;
    padding:  2rem 1rem;
    margin: 2rem auto 5rem auto;
    border: none;
    border-bottom: rgba($color-white, .3) solid .2rem;
    // border-radius: 30%;
    text-transform: uppercase;
    //text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
    @include respond(tab-port){
      font-size: 3rem;
    }

  }

  &--home-podcasts-title {
    width: 100%;
    color: $color-white;
    // font-weight: 700;
    // background-color: rgba($color-white, .1);
    box-shadow: .1rem .5rem .1rem rgba($color-tertiary-light, .9);
    display: flex;
    // justify-content: center;
    padding:  2rem 1rem;
    margin: 2rem 2rem 5rem -2rem;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--seller-home-other-sellers-podcasts-title {
    width: 65%;
    color: $color-white;
    // font-weight: 700;
    // background-color: rgba($color-white, .3);
    box-shadow: .01rem .5rem .1rem rgba($color-white, .3);
    display: flex;
    justify-content: center;
    padding:  2rem 1rem;
    // margin: 2rem auto 5rem auto;
    margin: 2rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--public-press-release-page {
    width: 80% !important;
    color: $color-white;
    // font-weight: 700;
    background-color: rgba($color-tertiary-dark, .3);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  1.5rem ;
    margin: 8rem auto 5rem auto !important ;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    // text-align: center !important;
    font-size: 2rem !important;
    font-weight: 600;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &__posts {
      width: 70%;
      display: flex;
      flex-direction: column;
      margin: 1rem auto;
      padding: 2rem 2rem 3rem 2rem ;
      color: rgba($color-tertiary-light, 1);
      font-size: 1.5rem;
      font-weight: 500;
      background-color: rgba($color-white, .5);

    }

  }

  &--profile-public-press-release {
    width: 65%;
    color: $color-tertiary-light;
    // font-weight: 700;
    //  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    // background-color: rgba(#125ff8, .2);
    background-color: rgba($color-white, .7);
    // box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding:  1.7rem ;
    margin: 8rem auto 5rem auto;
    // border-bottom: .1rem (rgba($color-tertiary-light, 1)) solid;
    border-radius: .5rem;
    text-transform: capitalize;
    // text-align: center !important;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .3rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--seller-home-categories-title-loading {
    width: 70rem;
    color: $color-tertiary-light;
    //font-weight: 700;
    background-color: rgba($color-white, .5);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 2rem ;
    margin: 2rem auto;
    // border-bottom: .1rem rgba($color-tertiary-dark, .5) solid;
    border-radius: .5rem;
    text-transform: capitalize;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: .3rem;
    // animation: moveInRight 1s ;
    // -webkit-animation: moveInRight 1s ;
  }

  &--seller-all-producats-page-categories-list {
    width: 100%;
    color: $color-tertiary-light;
    //font-weight: 700;
    background-color: rgba($color-grey-light-2, .5);
    box-shadow: .5rem .5rem .5rem rgba($color-tertiary-dark, .1);
    display: flex;
    // justify-content: center;
    justify-items: center;
    padding: 2rem ;
    margin: 1rem 0;
    // border-bottom: .1rem rgba($color-tertiary-dark, .5) solid;
    border-radius: .5rem;
    text-transform: capitalize;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .3rem;
    // animation: moveInRight 1s ;
    // -webkit-animation: moveInRight 1s ;
  }

  &--seller-home-categories-title {
    // width: 50rem;
    color: $color-white;
    //font-weight: 700;
    background-color: rgba($color-tertiary-dark, .5) !important;
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    display: flex;
    padding: 2rem ;
    // margin: 5rem ;
    border-bottom: .1rem rgba($color-white, .9) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 1s ;
    -webkit-animation: moveInRight 1s ;
  }

  &--seller-home-categories-title {
    // width: 50rem;
    color: $color-white;
    //font-weight: 700;
    background-color: rgba($color-tertiary-dark, .5) !important;
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    display: flex;
    padding: 2rem ;
    // margin: 5rem ;
    border-bottom: .1rem rgba($color-white, .9) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 1s ;
    -webkit-animation: moveInRight 1s ;
  }

  &--home-categories-title {
    // width: 50rem;
    color: $color-white;
    //font-weight: 700;
    // background-color: rgba(67, 82, 242, 0.35) !important;
    background-color: rgba(23, 37, 192, 0.34) !important;
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    display: block;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    // margin: 5rem ;
    border-bottom: .1rem rgba($color-white, .9) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.9rem;
    // font-weight: 700;
    font-weight: bolder;
    text-align: center !important;
    letter-spacing: .3rem;
    animation: moveInRight 1s ;
    -webkit-animation: moveInRight 1s ;

    @include respond(tab-port){
      text-align: left;
      font-size: 2.5rem !important;
      padding: 3rem auto !important;
    }
    @include respond(phone){
      text-align: center !important;
      font-size: 2.3rem !important;
      font-weight: bold;
     padding: 1.5rem .1rem !important;
    }
    @include respond(small-phone){
      text-align: center !important;
       font-size: 2.2rem !important;
      font-weight: bold;
     padding: 1.5rem .1rem !important;
    }

  }

  &--home-categories-title-phone {
    // width: 50rem;
    color: $color-white;
    //font-weight: 700;
    // background-color: rgba(67, 82, 242, 0.35) !important;
    background-color: rgba(23, 37, 192, 0.34) !important;
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    // margin: 5rem ;
    border-bottom: .1rem rgba($color-white, .9) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.9rem;
    // font-weight: 700;
    font-weight: bolder;
    text-align: center !important;
    letter-spacing: .3rem;
    animation: moveInRight 1s ;
    -webkit-animation: moveInRight 1s ;

    @include respond(tab-port){
      text-align: left;
      font-size: 2.5rem !important;
      padding: 3rem auto !important;
    }
    @include respond(phone){
      text-align: center !important;
      font-size: 2.3rem !important;
      font-weight: 700 !important;
     padding: .5rem 1rem 1.5rem 1rem !important;
     margin: 1rem auto 1rem auto !important;
    //  margin-bottom: 2rem;
    //  border: .1rem red solid;
    }
    // @include respond(small-phone){
    //   text-align: center !important;
    //    font-size: 3rem !important;
    //   font-weight: 700;
    //  padding: .1rem 1rem  1.5rem 1rem!important;
    //  margin: 1rem auto 15rem auto !important;
    // //  border: .1rem red solid;
    // }

  }

  // showCategories()::  className=" heading-primary--seller-all-producats-page-categories-list  p-4"
  // hideProducts()::   className="heading-primary--seller-all-producats-page-categories-list"
  // return (
  //   <div className="heading-primary--seller-home-categories-title" >
  // )

  // <h4 className="text-center heading-primary--seller-home-categories-title">Loading...</h4>

  // Categories: <h3 className="text-center heading-primary--seller-home-categories-title p-4">

  &--seller-all-products-page-categories-title {
    // width: 50rem;
    color: $color-white;
    //font-weight: 700;
    background-color: rgba($color-Greenprimary-dark, .1);
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    display: flex;
    padding: 3rem ;
    // margin: 5rem ;
    border-bottom: .1rem rgba($color-white, .9) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 1s ;
    -webkit-animation: moveInRight 1s ;
  }

  &--seller-home-sub-category-products-title {
    width: 75%;
    //color: $color-white;
    //font-weight: 700;
    background-color: rgba($color-white, .5);
    z-index: 100;
    box-shadow: 1rem .5rem 2rem rgba($color-black, .3);
    display: block;
    padding: .5rem 0;
    margin: 2rem auto;
    //border-bottom: .1rem rgba($color-tertiary-dark, .5) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 21s 1s;
    -webkit-animation: moveInRight 21s 1s;
}

  &--home-category-products-title {
    width: 50%;
    //color: $color-white;
    //font-weight: 700;
    background-color: rgba($color-white, .8);
    z-index: 100;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    display: block;
    padding: .5rem 0;
    margin: 2rem auto 3rem auto;
    //border-bottom: .1rem rgba($color-tertiary-dark, .5) solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: .3rem;
    animation: moveInRight 21s 1s;
    -webkit-animation: moveInRight 21s 1s;
}

  &--comments-title {
    width: 100%;
    color: rgba($color-white, 1);
    text-align: center !important;
    font-weight: 700;
    background-color: rgba($color-secondary-dark, .15);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    // justify-content: flex-start;
    padding: 1rem;
    margin: 2rem 1rem 1rem -1rem ;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    // font-weight: 500;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--posts-title {
    width: 80%;
    color: $color-secondary-dark;
    text-align: center;
    // font-weight: 700;
    background-color: rgba($color-tertiary-dark, .07);
    // box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    margin: 5rem auto 0 auto ;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 700 !important;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }


  &--coupon-title {
    width: 100%;
    color: $color-white;
    // font-weight: 700;
    background-color: rgba($color-tertiary-dark, .2);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .1);
    display: block;
    padding: 1.5rem 2rem ;
    margin: 7rem auto 1rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--social-menu {
    width: 60%;
    color: $color-white;
    // font-weight: 700;
    background-color: rgba($color-tertiary-light, .2);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .3);
    display: flex;
    // flex-direction: row;
    justify-content: center;
    text-align: center;
    padding: 1.5rem 2rem ;
    margin:  4rem auto 1rem auto;
    // border: .2rem red solid;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }
  &--social-menu-press-release-page {
    width: 45%;
    color: $color-white;
    // font-weight: 700;
    background-color: rgba($color-tertiary-dark, .2);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-dark, .3);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1.5rem 2rem ;
    margin: 3rem auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }

  &--sub-profile-form {
    color: $color-primary-light-3;
    background-color: rgba($color-white, .5);
    display: block;
    padding: 1rem 7rem;
    margin: 0 auto;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

    &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba($color-black, .2);
    }

  }


}


.heading-secondary {
  display: inline-block;
  font-size: 3rem;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  background-clip:text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to right, $color-tertiary-light, $color-tertiary-light);
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;

  @include respond(tab-port){
    font-size: 3rem;
  }

  @include respond(phone){
    font-size: 2.5rem;
  }

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}

.heading-secondary-seller-form1-title {
  width: 75%;
  // display: inline-block;
  margin: 3rem auto 3rem auto !important;
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-white, .5);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  box-shadow: 0 .5rem .8rem rgba($color-black, .03);
  color: rgba($color-tertiary-light, .8);
  letter-spacing: .2rem;
  transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}

.heading-secondary-seller-form1-title-2nd-design-with-clip-path {
  width: 75%;
  margin: 2rem auto 3rem auto;
  // display: inline-block;
  // margin-top: 2rem;
  // margin-bottom: 3rem;
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-white, .4);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  box-shadow: 0 .5rem .8rem rgba($color-black, .03);
  color: rgba($color-tertiary-light, .8);
  letter-spacing: .2rem;
  transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}

.heading-secondary-seller-form2-title {
  width: 75%;
  margin: 1rem auto 2rem auto;
  font-size: 1.7rem;
  text-transform: capitalize;
  margin-top: 1rem;
  padding: 2rem 1rem;
  font-weight: 600;
  background-color: rgba($color-white, .45);
  box-shadow: 0 .1rem 13rem rgba($color-black, .1);
  color: rgba($color-tertiary-light, .8);
  border-bottom: rgba($color-white, .4) .2rem solid;
  letter-spacing: .2rem;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.heading-seller-home-tri-picts-orange-title {
  width: 75%;
  margin: 1rem auto 2rem auto;
  font-size: 1.4rem;
  text-transform: capitalize;
  margin-top: 1rem;
  padding: 2rem 1rem;
  font-weight: 600;
  background-color: rgba($color-white, .45);
  box-shadow: 0 .1rem 13rem rgba($color-black, .1);
  color: rgba($color-tertiary-light, .8);
  border-bottom: rgba($color-white, .4) .2rem solid;
  letter-spacing: .2rem;
  border-radius: 2%;
  -webkit-border-radius: 2%;
  -moz-border-radius: 2%;
  -ms-border-radius: 2%;
  -o-border-radius: 2%;
}

.heading-secondary-seller-form-title-width-100 {
  // display: inline-block;
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-white, .5);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  box-shadow: .5rem .5rem .8rem rgba($color-black, .03);
  color: rgba($color-black, .7);
  font-weight: 700;
  letter-spacing: .2rem;
  transition: all .2s;
  @include respond(phone){
    font-size: 2rem;
  }

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  // &__sub {
  //   margin-left: 5rem;
  // }
}

.heading-secondary-seller-form-6-col-1-of-2-title-width-100 {
  // display: inline-block;
  font-size: 1.3rem;
  text-transform: capitalize;
  margin: 5rem 0 4rem 0;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-Greenprimary-dark, .5);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  box-shadow: 0 .5rem .8rem rgba($color-black, .2);
  color: rgba($color-white, 1);
  letter-spacing: .2rem;
  transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  // &__sub {
  //   margin-left: 5rem;
  // }
}

.heading-secondary-seller-form-6-col-1-of-2-blue-background-title-width-100 {
  // display: inline-block;
  font-size: 1.3rem;
  text-transform: capitalize;
  margin: 5rem 0 4rem 0;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-tertiary-light, .5);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  box-shadow: 0 .5rem .8rem rgba($color-black, .2);
  color: rgba($color-white, 1);
  letter-spacing: .2rem;
  transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  // &__sub {
  //   margin-left: 5rem;
  // }
}

.heading-secondary-seller-create-home-pink-green-col-1-of-2-title {
  // display: inline-block;///
  font-size: 1.4rem;
  text-transform: capitalize;
  margin:4rem 0 3rem 0;
  padding: 2rem 1rem;
  font-weight: 600;
  // background-color: rgba($color-Greenprimary-dark, .5);
  background-color: rgba(48, 130, 124, 0.3);

  box-shadow: 0 .5rem .8rem rgba($color-black, .2);
  color: rgba($color-white, 1);
  letter-spacing: .2rem;
  transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  // &__sub {
  //   margin-left: 5rem;
  // }
}

.heading-secondary-seller-form-podcasts-title-width-100 {
  // display: inline-block;
  width: 75%;
  margin: 1rem auto;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-tertiary-light, .1);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  // box-shadow: 0 .5rem .8rem rgba($color-black, .03);
  color: rgba($color-tertiary-light, 1);
  border-bottom: rgba($color-black, .2) .2rem solid;
  letter-spacing: .2rem;
  // transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}
.edit-remove-container {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgba($color-secondary-dark, .05);
   border: rgba($color-white, .2) .25rem solid;
   box-shadow: .2rem .2rem .3rem rgba($color-white, .2);
   margin: 5rem auto;

}

.heading-secondary-seller-form-podcasts-title-width-100-new-design {
  // display: inline-block;
  width: 75%;
  margin: 1rem auto;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-white, .5);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  // box-shadow: 0 .5rem .8rem rgba($color-black, .03);
  color: rgba($color-tertiary-light, 1);
  border-bottom: rgba($color-tertiary-light, .6) .4rem solid;
  letter-spacing: .2rem;
  // transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}

.heading-secondary-seller-form-podcasts-title-width-100-black-design {
  // display: inline-block;
  width: 75%;
  margin: 1rem auto;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 600;
  background-color: rgba($color-black, .04);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  // box-shadow: 0 .5rem .8rem rgba($color-black, .03);
  color: rgba($color-white, 1);
  border-bottom: rgba($color-tertiary-light, .9) .4rem solid;
  letter-spacing: .2rem;
  // transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}

.heading-secondary-seller-form-podcasts-title-width-100-black-design-2 {
  // display: inline-block;
  width: 75%;
  margin: 1rem auto 3rem auto;
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  font-weight: 700;
  background-color: rgba($color-white, .0);
  // background-clip:text;
  // -webkit-background-clip: text;
  // background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  // box-shadow: 0 .5rem .8rem rgba($color-black, .03);
  color: rgba($color-white, .7);
  border-bottom: rgba($color-navy-dark, .5) .4rem solid;
  letter-spacing: .2rem;
  // transition: all .2s;

  // &:hover {
  //   transform: skewY(2deg) skewX(15deg) scale(1.1);
  //   text-shadow: .5rem 1rem 2rem rgba($color-black, .2) ;
  // }

  &__sub {
    margin-left: 5rem;
  }
}

.heading-tertiary {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  // text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: -3rem;

  &__our-mission{
    font-size:1.9rem;
    font-weight: bolder !important;
      margin-bottom: .5rem;
    letter-spacing: .1rem;
    color: rgba($color-black, .7);

    @include respond(phone){
      font-size: 3.8rem;
      margin-bottom: .5rem;
      padding-left: 2rem;
    }
  }
}

.heading-order-summary-products {
  display: inline-block;
  color: #085eaf;
  font-size: 1.6rem;
  font-weight: 700;
  // text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: -3rem;

  @include respond(phone){
    font-size: 2.5rem;
    font-weight: 700;
    color:#085eaf;
  }
}

.heading-subtitle--tri-pict-orange {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  // text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: -3rem;
}

.heading-seller-home-public-paragraph-title {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  // text-transform: uppercase;
  margin-bottom: .5rem;
  margin-top: -1.5rem;
}

.heading-seller-home-public-form-6-paragraph-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.5rem;
  font-weight: 500;
  // text-transform: uppercase;
  margin-bottom: 2rem;
  margin-top: 3rem;
  @include respond(phone){
    font-size: 2rem;
  }
}

.heading-seller-home-create-pink-green-form-paragraph-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.4rem;
  font-weight: 500;
  // text-transform: uppercase;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.heading-tertiary--seller-create-form-4-title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  // background-color: rgba($color-Greenprimary-dark, .35);
  // color: rgba($color-tertiary-light, 1);
  // text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.paragraph {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph-our-mission {
  font-size: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  color: rgba($color-black, .8);
  // border: .2rem red solid;


  @include respond(phone){
    font-size: 3.5rem;
    padding: 1rem 2rem;
  }

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph-seller-home-form-7-big-picture {
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  text-align: justify;

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }
  @include respond(phone){
    font-size: 2rem;
}
}

.paragraph-seller-home-public {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: .5rem 3rem;
  // margin-top: 3rem;

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph-seller-home-public {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: .5rem 3rem;
  // margin-top: 3rem;

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph-seller-home-public-form-6 {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: 0 3rem;
  // margin-top: -7rem;

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph-seller-create-pink-green-square {
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  padding: 0 3rem;
  margin-top: -1rem;

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph-podcast-home-form {
  height: 100%;
  font-size: $default-font-size;
  color: rgba($color-black, .8);
  // border: .1rem white solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align: justify;
  margin: auto 1rem;
  padding: 3rem;
  background-color: rgba($color-white, .5);

  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}

.paragraph--seller-create-form-4-paragraph {
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  text-align:justify
  // color: rgba($color-tertiary-light, 1);
  // border: .1rem red solid;

  // &:not(:last-child){
  //   margin-bottom: 3rem;
  // }

}



.heading-primary-upload-file {
  width: 65%;
  border-radius: 1%;
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom: 3rem;
  // margin-top: -4rem;


  &__sub {
     margin:2rem 5rem;
     padding: 2rem;
     //margin-top: -3rem;
     //margin-left: -2rem;
     //color: $color-primary-light-2;
    //  border: .1rem red solid;
     border-radius: .3rem;
     border: none;
     display: block;
     font-size: 1.6rem;
     font-weight: 500;
     letter-spacing: .3rem;
     animation: moveInRight 1s ease-out;
     -webkit-animation: moveInRight 1s ease-out;
     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      border: none;
      box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
      transform: translateY(-.1rem);
      -webkit-transform: translateY(-.1rem);
      box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    }

  }


}
.heading-primary-create-product {
  width: 65%;
  border-radius: 1%;
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom: 3rem;
  // margin-top: -4rem;


  &__sub {
     margin:2rem auto 1rem auto;
     padding: 2rem;
     //margin-top: -3rem;
     //margin-left: -2rem;
     //color: $color-primary-light-2;
    //  border: .1rem red solid;
     border-radius: .3rem;
     border: none;
     display: block;
     font-size: 1.6rem;
     font-weight: 500;
     letter-spacing: .3rem;
     animation: moveInRight 1s ease-out;
     -webkit-animation: moveInRight 1s ease-out;
     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      border: none;
      box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
      transform: translateY(-.1rem);
      -webkit-transform: translateY(-.1rem);
      box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    }

  }


}

.heading-primary-create-videos {
  width: 65%;
  border-radius: 1%;
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom: 3rem;
  // margin-top: -4rem;


  &__sub {
     margin:-5rem auto 11rem auto;
     padding: 2rem;
     border-radius: .3rem;
     border: none;
     display: block;
     font-size: 1.6rem;
     font-weight: 500;
     letter-spacing: .3rem;
     animation: moveInRight 1s ease-out;
     -webkit-animation: moveInRight 1s ease-out;
     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      border: none;
      box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
      transform: translateY(-.1rem);
      -webkit-transform: translateY(-.1rem);
      box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    }

  }


}

.heading-primary-create-podcast {
  width: 65%;
  border-radius: 1%;
  margin: 0 5rem 2rem 8rem !important;
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom: 3rem;
  // margin-top: -4rem;

  &__sub {
     margin:1rem auto 11rem auto;
     padding: 2rem;
     border-radius: .3rem;
     border: none;
     display: block;
     font-size: 1.6rem;
     font-weight: 500;
     letter-spacing: .3rem;
     animation: moveInRight 1s ease-out;
     -webkit-animation: moveInRight 1s ease-out;
     &:hover {
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      border: none;
      box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
      transform: translateY(-.1rem);
      -webkit-transform: translateY(-.1rem);
      box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    }

  }


}

.podcast-form {
    width: 100%;
     margin:8rem auto 5rem auto;
     padding: 2rem 0 !important;
    //  border: .1rem red solid;
     border-radius: .3rem;
    //  border: none;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     box-shadow: 0 .5rem 1rem rgba($color-tertiary-dark, .1);

}

.podcast-player {
    width: 30rem !important;
     margin:8rem auto 0 auto;
     padding: -1rem .2rem 1rem 1.5rem !important;
    //  border: .1rem red solid;
     border-radius: .3rem;
    //  border: none;
     display: flex;
     flex-direction: row;
     justify-content: center;
     justify-items: center;
    //  box-shadow:  0 .5rem 1rem rgba($color-grey-dark,.2);

}

.heading-primary-create-category {
  width: 65%;
  border-radius: 1%;
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom: 3rem;
  // margin-top: -4rem;


  &__sub {
    width: 40%;
     margin: 0 auto 2rem auto;
     padding: 1rem;
     background-color: rgba($color-tertiary-dark, .3);
     box-shadow: 0 .5rem 1.5rem rgba($color-black, .1);
     //border: .1rem solid rgba($color-tertiary-dark, .2);
     border-radius: .3rem;
     border: none;
     display: block;
     font-size: 1.6rem;
     font-weight: 500;
     letter-spacing: .3rem;
     animation: moveInRight 1s ease-out;
     -webkit-animation: moveInRight 1s ease-out;
     &:hover {
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      border: none;
      box-shadow: 0 .5rem 1.5rem rgba($color-black, .1);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      -o-transform: translateY(-5px);
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
      transform: translateY(-.1rem);
      -webkit-transform: translateY(-.1rem);
      box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    }

  }
}

.heading-primary-admin-nav {
  width: 85%;
  border-radius: 1%;
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom: 3rem;
  // margin-top: -4rem;


  &__sub {
     margin: 2rem 2rem 2rem -1rem;
     padding: 4rem 2rem 10rem 2rem;
     //margin-top: -3rem;
     //margin-left: -2rem;
     color: $color-white;
     //border: .1rem red solid;
    background-color: rgba($color-white, .5);
     border-radius: .3rem;
     border: none;
     display: block;
     font-size: 1.8rem;
     font-weight: 500;
     letter-spacing: .3rem;
     animation: moveInRight 1s ease-out;
     -webkit-animation: moveInRight 1s ease-out;
    //  &:hover {
    //   transform: translateY(-2px);
    //   -webkit-transform: translateY(-2px);
    //   border: none;
    //   box-shadow: 0 .5rem .3rem rgba($color-tertiary-dark, .3);
    // }

    // &:active,
    // &:focus {
    //   outline: none;
    //   border: none;
    //   transform: translateY(-.1rem);
    //   -webkit-transform: translateY(-.1rem);
    //   box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    // }

  }


}

 .heading-sub-category--title {
    width: 75%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, .7);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-dark, .2);

    margin: 3rem auto 2rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }




  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }

 .heading-create-video--title {
    width: 75%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-dark, .3);

    margin: 10rem auto 2rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }




  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }
 .heading-create-product--title {
    width: 75%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-dark, .3);

    margin: 10rem auto 2rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }




  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }

 .heading-existing-coupons-title {
    width: 75%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-white, .3);

    margin: 2rem auto 3rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-page-title {
    width: 45%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.5rem;
    font-weight: 500;
    background-color: rgba($color-white, .45);

    margin: 10rem auto 0 auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .3rem .3rem rgba($color-black, .05);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

   &--background{
    background-color: rgba($color-white, 1);
   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-seller-profile-company-name {
    width: 70%;
    border-radius: 1%;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    letter-spacing: .2rem;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin: 8rem auto 5rem auto;
    border-bottom: .1rem (rgba($color-tertiary-light, 1)) solid;
    font-size: 2rem;
    font-weight: 500;
    background-color: rgba($color-tertiary-dark, .3);

    margin: 10rem auto ;
    margin-bottom: 2rem;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;

   }

 }

 .heading-seller-profile-press-release-title {
    width: 80%;
    border-radius: 1%;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.4rem;
    font-weight: 400;
    background-color: rgba($color-white, .3);

    margin: 4rem auto;
    margin-right:2rem;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;

   }

 }

 .heading-order-list-for-seller-total-orders-title {
    width: 70%;
    border-radius: 1%;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.4rem;
    font-weight: 400;
    background-color: rgba($color-white, .3);

    margin: 4rem auto;
    // margin-right:2rem;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;

   }

 }

 .heading-seller-profile-dashboard-info {
    width: 100%;
    border-radius: 1%;
    display: block;
    justify-content: flex-start;
    font-size: 1.6rem;
    font-weight: 400;
    background-color: rgba($color-white, .6);
    margin: 2rem auto 2rem 5rem;
    padding: 3rem;
    box-shadow: 0 .3rem .3rem rgba(112, 90, 206, .2);
    &__content {
      background-color: rgba($color-black, .02);
    }

    @include respond(phone){
      font-size: 3rem;
    }

  //  @include clearfix;

  //  [class^="col-"] {

  //    float: left;


  //  }
 }



 .heading-seller-profile-seller-info {
    width: 100%;
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
    display: block;
    //flex-direction: column;
    justify-content: flex-start;
    //justify-items: center;
    //border: rgba(112, 90, 206, .6) solid .1rem ;
    //color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 400;
    background-color: rgba($color-white, .5);
    margin: 2rem auto 2rem 5rem;
    padding: 5rem 2rem 5rem 2rem;
    //padding-left: 0;
    //padding-right: 0rem;
    //padding-top: 2rem;
    //padding-bottom: 2rem;
    // box-shadow: 0 .5rem .5rem rgba(112, 90, 206, .2);
     box-shadow: 0 1.5rem 2rem rgba($color-black, .03);
    &__content{
       background-color: rgba($color-tertiary-dark, .04);
       border-radius: 3%;
       margin: 1rem ;
        padding:2rem;
       //margin: 2rem auto 2rem 5rem;
       //-webkit-border-radius: 50%;
       //-moz-border-radius: 50%;
       //-ms-border-radius:;
       //-o-border-radius: 50%;
       -webkit-border-radius: 3%;
       -moz-border-radius: 3%;
       -ms-border-radius: 3%;
       -o-border-radius: 3%;
}

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

 }


  .heading-seller-profile-mession {
    width: 100%;
    border-radius: 2%;
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
    display: block;
    //flex-direction: column;
    justify-content: flex-start;
    //justify-items: center;
    //border: rgba(112, 90, 206, .6) solid .1rem ;
    //color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 400;
    background-color: rgba($color-white, .5);
    margin: 4rem auto ;
    padding: 3rem 2rem 3rem 2rem;
    //padding-left: 0;
    //padding-right: 0rem;
    //padding-top: 2rem;
    //padding-bottom: 2rem;
    // box-shadow: 0 .5rem .5rem rgba(112, 90, 206, .2);
     box-shadow: 0 1.5rem 2rem rgba($color-black, .03);
    &__content{
       background-color: rgba($color-tertiary-dark, .04);
       border-radius: 3%;
       margin: 1rem ;
        padding:2rem;
       //margin: 2rem auto 2rem 5rem;
       //-webkit-border-radius: 50%;
       //-moz-border-radius: 50%;
       //-ms-border-radius:;
       //-o-border-radius: 50%;
       -webkit-border-radius: 3%;
       -moz-border-radius: 3%;
       -ms-border-radius: 3%;
       -o-border-radius: 3%;
}

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

 }

 .heading-seller-profile-dashboard-press-release {
    width: 80%;
    border-radius: 1%;
    display: block;
    justify-content: flex-start;
    font-size: 1.6rem;
    font-weight: 500;
    background-color: rgba($color-white, .5);

    margin: 2rem auto;
    padding: 3rem 5rem  ;
    box-shadow: 0 .5rem 1rem rgba(112, 90, 206, .2);
    &__content {
         background-color: rgba($color-tertiary-dark, .03);
         border: rgba($color-black, .03) .2rem solid;
         color: rgba($color-primary-light, 1);
      margin: 2rem auto 2rem auto;

    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

 }


 .heading-seller-videos-page-title {
    width: 100%;
    border-radius: 1%;
    // display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2.3rem;
    font-weight: 500;
    background-color: rgba($color-white, .1);

    margin: 1rem 0 1rem 0 !important;

    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-seller-header-section-business-title {
    width: 100%;
    border-radius: 1%;
    // display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2.5rem;
    font-weight: 400;
    background-color: rgba($color-white, .1);

    margin: 1rem auto 3rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-seller-header-profile-not-edited-title {
    width: 65%;
    border-radius: 1%;
    // display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 3.5rem;
    font-weight: 400;
    background-color: rgba($color-white, .1);

    margin: 1rem auto 3rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .1rem .1rem rgba($color-black, .05);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-seller-header-profile-not-edited-message {
    width: 65%;
    border-radius: 1%;
    // display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2.5rem;
    font-weight: 400;
    background-color: rgba($color-secondary-dark, .05);

    margin: 0 auto 3rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-single-video-related-videos-title {
    width: 95%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.5rem;
    font-weight: 400;
    background-color: rgba($color-white, .3);
    margin: 2rem auto 5rem auto;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);
 }

 .heading-seller-profile-company-site {
    width: 95%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.8rem;
    font-weight: 400;
    background-color: rgba($color-white, .03);
    margin: 2rem auto 2rem auto;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .05);

    @include respond(phone){
      font-size: 3rem;
    }
 }

 .heading-seller-profile-picture {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-black, 1);
    font-size: 5rem;
    font-weight: 700;
    background-color: rgba($color-grey-dark, .1);
    margin: 2rem 0 ;
    //padding: 3rem;
    padding-left:auto;
    padding-right: auto;
    //padding-top: 1.5rem;
    //padding-bottom: 1.5rem;
    //box-shadow: 0 .5rem 1rem rgba($color-black, .1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

 .heading-seller-press-release-picture-public {
    width: 100%;
    border-radius: 2%;
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-black, 1);
    font-size: 5rem;
    font-weight: 700;
    background-color: rgba($color-grey-dark, .05);
    margin: 2rem 0 ;
    //padding: 3rem;
    padding-left:auto;
    padding-right: auto;
    //padding-top: 1.5rem;
    //padding-bottom: 1.5rem;
    //box-shadow: 0 .5rem 1rem rgba($color-black, .1);
    -webkit-border-radius: 2%;
    -moz-border-radius: 2%;
    -ms-border-radius: 2%;
    -o-border-radius: 2%;
}

 .heading-seller-press-release-posts {
    width: 95%;
    border-radius: 1%;
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 500;
    // background-color: rgba($color-white, .25);
    // margin: 2rem auto 2rem auto;
    padding-left: 0rem;
    padding-right: 0rem;
    // padding-top: 1.5rem;
    // padding-bottom: 1.5rem;
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
 }

 .heading-dashboard-analysis {
    width: 95%;
    border-radius: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: center;
    // justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.5rem;
    font-weight: 400;
    background-color: rgba($color-white, .1);
    margin: 2rem auto 2rem auto;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);
 }

 .heading-single-video-sub-column-title {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-dark, .3);
    letter-spacing: .3rem;
    text-transform: uppercase;
    border-bottom: .5rem rgba($color-white, .8) solid;
    margin: 0rem auto 1.5rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }
 .heading-seller-profile-welcome {
    width: 85%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-dark, .3);
    letter-spacing: .3rem;
    text-transform: uppercase;
    border-bottom: .5rem rgba($color-white, .8) solid;
    margin: 0 auto 1.5rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);
    @include respond(phone){
      font-size: 4rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-seller-public-all-podcasts-podcast-info-questions-columns {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 700;
    background-color: rgba($color-white, .7);
    letter-spacing: .3rem;
    text-transform: uppercase;
    border-bottom: .5rem rgba($color-tertiary-dark, .3) solid;
    margin: 0 auto .5rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }

 }


 .heading-seller-public-single-podcast-and-video-info-and-questions-columns {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 700;
    background-color: rgba($color-white, .7);
    letter-spacing: .3rem;
    text-transform: uppercase;
    border-bottom: .5rem rgba($color-tertiary-dark, .3) solid;
    margin: 0 auto .5rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }


 .heading-seller-public-all-products-videos-product-info-questions-columns {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 700;
    background-color: rgba($color-white, .7);
    letter-spacing: .3rem;
    text-transform: uppercase;
    border-bottom: .5rem rgba($color-tertiary-dark, .3) solid;
    margin: 0 auto .5rem auto;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    // box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }


 .heading-seller-single-product-productInfo-column {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.4rem;
    font-weight: 700;
    background-color: rgba($color-secondary-dark, .03);
    letter-spacing: .3rem;
    text-transform: uppercase;
    // border-bottom: .2rem rgba($color-tertiary-light, .3) solid;
    margin: 5rem auto 1.5rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    // box-shadow: 0 .1rem .1rem rgba($color-black, .1);
    @include respond(phone){
      width: 85%;
      font-size: 4rem;
    }

  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-public-video-product-info-columns {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 700;
    background-color: rgba($color-white, .3);
    letter-spacing: .3rem;
    text-transform: uppercase;
    border-bottom: .5rem rgba($color-tertiary-dark, .3) solid;
    margin: 0rem auto 1.5rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);



 }


 .heading-search-title {
    width: 75%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-light, .05);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 10rem auto 2rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .01);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }
 .heading-profile-welcome-title {
    width: 85%;
    border-radius: 1%;
    display: block;
    text-align: center;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-white, 1);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 0 auto 2rem auto !important;
    padding: 3rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .01);

    @include respond(phone){
      font-size: 3rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-profile-update-title {
   width: 85%;
    border-radius: 1%;
    display: block;
    text-align: center;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-white, 1);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 2rem auto 2rem auto !important;
    padding: 3rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .01);

    @include respond(phone){
      font-size: 3rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-update-ship-address-title {
   width: 85%;
    border-radius: 1%;
    display: block;
    text-align: center;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.5rem;
    font-weight: 600;
    background-color: rgba($color-white, 1);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 2rem auto 2rem auto !important;
    padding: 3rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .01);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-profile-update-shipping-address {
   width: 55%;
    border-radius: 1%;
    display: block;
    text-align: center;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.9rem;
    font-weight: 600;
    background-color: rgba($color-white, .5);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 7rem auto 7rem auto !important;
    padding: 3rem;

    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

    @include respond(phone){
      width: 65%;
      font-size: 3rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

   &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba($color-white, .3);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }

 }

 .heading-cart-items {
   width: 40%;
    border-radius: 1%;
    display: block;
    text-align: center;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2.2rem;
    font-weight: 600;
    background-color: rgba(#304ffe, .035);
    // border: .1rem rgba($color-black, .1) solid;
    // border-top:none;
    margin: 2rem auto -5rem auto !important;
    padding: 2.3rem 3rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    // box-shadow: .1rem .5rem .1rem rgba($color-black, .05);
     box-shadow: 0 .1rem 0 rgba($color-black, .2);

     @include respond(phone){
      font-size: 2.6rem;
     }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-view-shipping-cost-options {
    width: fit-content;
    border-radius: 1%;
    display: block;
    text-align: center;
    //flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light-2, 1);
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: .15rem;
    background-color: rgba($color-secondary-dark, .03);
    border:none;
    border-radius: 1rem;
       -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    //border-bottom: .3rem rgba($color-primary-light, .5) solid;
    margin: 2rem auto 6rem auto !important;
    padding: 2rem 3rem;
    cursor: pointer;
    //padding-left: 0rem;
    //padding-right: 0rem;
    //padding-top: 2rem;
    //padding-bottom: 2rem;
    box-shadow: .5rem .5rem .5rem rgba($color-black, .03);
    @include clearfix;
    [class^="col-"] {
     float: left;

   }

   &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba($color-white, .5);
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
     background-color: rgba($color-tertiary-dark, .02);
    //box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
}

  @include respond(phone){
    font-size: 2.5rem;
  }
 }

 .heading-cart-summary {
    width:85%;
    height: fit-content;
    border-radius: 1%;
    display: row;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba(#304ffe, .05);
    border-bottom: .1rem rgba($color-primary-light, .1) solid;
    margin: 5rem auto 0 auto;
    padding: 2rem 3rem;
    text-align: center;
    letter-spacing: .2rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    // box-shadow: .1rem .5rem .1rem rgba($color-black, .1);
    @include respond(phone){
      font-size: 3.5rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-cart-summary-seller {
    width:100%;
    height: fit-content;
    border-radius: 1%;
    display: row;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 1.6rem;
    font-weight: 700;
    background-color: rgba(red, .015);
    // border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 5rem auto 1rem auto !important;
    padding: 3rem;
    text-align: center;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    // box-shadow: 0 .5rem 1rem rgba($color-black, .01);
    @include respond(phone){
      font-size: 3rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }
 }

 .heading-checkout-summary {
    width:100%;
    height: fit-content;
    border-radius: 1%;
    display: row;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-tertiary-light, .05);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 5rem auto 5rem auto;
    padding: 3rem;
    text-align: center;

    box-shadow: 0 .5rem 1rem rgba($color-black, .01);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

 }

 .heading-cart-details {
    width:100%;
    height: fit-content;
    border-radius: 1%;
    display: row;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-black, 1);
    background-color: (rgba($color-white, 1));
    font-size: 2rem;
    font-weight: 500;
    // background-color: rgba($color-tertiary-light, .05);
    // border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 1rem auto 1rem auto;
    padding: 3rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 15rem !important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);



   @include clearfix;

   [class^="col-"] {

     float: left;


   }

  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }

 .heading-orders-dashboard-details {
    width:100%;
    height: fit-content;
    border-radius: 1%;
    display: row;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-black, 1);
    background-color: (rgba($color-white, .5));
    font-size: 1.6rem;
    font-weight: 500;
    // background-color: rgba($color-tertiary-light, .05);
    border-bottom: .1rem rgba($color-primary-light, .5) solid;
    margin: 1rem auto 8rem auto;
    padding: 3rem;
    // padding-left: 0rem;
    // padding-right: 0rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }

  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }

 }

 .heading-existing-videos {
    width: fit-content;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-secondary-dark, .04);

    margin: 3rem auto 10rem auto;

    padding-left: 10rem;
    padding-right: 10rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // padding: 15rem auto !important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .05);

    @include respond(phone){
      font-size: 3rem;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;


   }




  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }

 .heading-create-home-forms-header {
    width: fit-content;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-primary-light, 1);
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .3rem;
    background-color: rgba($color-white, .7);
    margin: 3rem auto ;
    padding: 2rem 10rem;
    box-shadow: 0 .3rem .3rem rgba($color-black, .02);

    @include respond(phone){
      font-size: 2.7rem;
      padding: 2rem 2rem;
      margin: 1rem 5rem;
    justify-content: start;
    // justify-items: start;
    }






  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }


 .heading-edit-profile-button {
    width: 45%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    color: rgba($color-tertiary-light, 1);
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba($color-white, .4);

    margin: 5rem auto 0 auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .05);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }
 }

 .heading-sub-category--input {
    width: 75%;
    border-radius: 1%;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    // color: rgba($color-white, .7);
    font-size: 2rem;
    // font-weight: 700;
    background-color: rgba($color-tertiary-dark, 1);

    margin: 3rem auto 2rem auto;

    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }




  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }

.heading-seller-total-orders {
    width: 45%;
    border-radius: 1%;
    margin: 2rem auto 5rem auto;
    padding-left: 4rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size:2rem;
    box-shadow: 0 .5rem 3rem rgba($color-white, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-white, .5 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }


.checkout-steps > div {
  width: 100%;
  margin-top: 10rem;
  margin-right: .3rem;
  margin-bottom: 7rem;
  border-top: .5rem rgba($color-grey-dark-3, .1) solid;
  color:rgba($color-grey-dark, .5);
  flex:1;
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: bold;
   text-align: end;
  //  @include respond(phone){
    // margin: auto 0 !important;
    // padding: .5rem 1rem;
    // background-color: yellow;
  //  }
}

.checkout-steps > div.active {
  border-top: .5rem rgba(112, 90, 206, .6) solid;
  color: rgb(68, 36, 211);
  font-size: 1.7rem;
  font-weight: 600;

  @include respond(phone){
    font-size: 2rem;
  }
}


.color1{
  color:rgb(252, 186, 3);
  background-color:rgb(238, 211, 135);
}
.color2{
  color:#20a020;
  background-color: #eeffe0;
  /* this for alert green font and lighter green background */
}

ul {
 list-style: none;
//  border: 0.1rem #8b1818 solid;
 width: 100%;

}
 .summary>li {
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  margin: 2rem;
  flex: 1 1 2rem;
  text-decoration: none;
  -moz-list-bullet:none;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.summary-title{
  font-size: 2rem;
  padding: 1rem;
}

.summary-body{
  font-size: 4rem;
  padding: 1rem;
  text-align: center;
  background-color: rgba($color-white, .4);
  // border: 0.1rem #8b1818 solid;


}

.summary-title.color1{
  background-color:#f0e0e0;
}
.summary-title.color2{
  background-color: #e0f0e0;
}
.summary-title.color3{
  background-color: #e0e0f0;
}

.heading-dashboard {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;

  &__background {
    background-color: rgba($color-white, .5);
    margin-top: 20rem;

    @include respond(phone){
      margin-top: 5rem;
    }
  }
}


.heading-seller-page {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;

  &__background {
    background-color: rgba($color-white, .15);
    // margin-top: 20rem;
  }
}

.heading-seller-profile-view-edu-exp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;

  &__background {
    background-color: rgba($color-white, 1);
    // margin-top: 20rem;
  }
}


.heading-seller-dashboard {
  height: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 5rem !important;
  // justify-content: space-between;
  // margin-top: 5rem;
  // margin-bottom: 5rem;
  margin:  auto;

  &__background {
    background-color: rgba($color-white, .15);
    margin-top: 20rem;
  }
}

.heading-seller-view-profile-experience-education {
  width: 85%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 12rem auto;
  padding: 5rem;
  // margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .03);

  &__background {
    background-color: rgba($color-white, .7);
    // margin: 20rem;
    padding: 2rem;
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
  }
}

.heading-seller-single-product-video {
  width: 95%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1.5rem auto 10rem auto;
  padding:  1.5rem 0;
  // margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .03);

  &__background {
    background-color: rgba($color-white, .5);
    border-top: .1rem solid rgba($color-tertiary-dark, .1);
    // margin: 20rem;
    // padding: 2rem;
  }
  //  &__select-form {
  //   margin-bottom: 1rem;
  //   // background-color: rgba($color-tertiary-dark, .1);
  //  }

  // &__experience-education-width {
  //   width: 75%;
  //   margin: 2rem auto;
  // }
}

// .ant-menu-overflow{
//   width: 35rem !important;
//   // background-color: red !important;
// }

.heading-seller-create-home-form-nav {
  // width:85% !important;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: center !important;
    // overflow-x: scroll !important;
    //   overflow-x: visible;

  // padding:  1.5rem 0;
  // margin-bottom: 5rem;
  // box-shadow: 0 1.5rem 2rem rgba($color-black, .3);
  //  border: .1rem red solid !important;

  &__background {
    width:90%;
    height: 0;
    font-size: 2rem !important;
    background-color: rgba($color-white, .5);
    // border-top: .1rem solid rgba($color-tertiary-dark, .1);
    box-shadow: 1rem 1rem 1rem rgba($color-black, .01);
      margin: .5rem auto 12rem auto !important;
      // padding-left: 20rem;

    // padding: 2rem;
    // padding: 1rem;
    @include respond(phone){
      // width: 90%;
      // height: fit-content !important;
      // overflow-x: scroll !important;
      // overflow-x: visible;
      // overscroll-behavior-x: auto;
      // margin-top: 2rem !important;
      // margin-bottom: 5rem !important;
      // margin-right: 5rem !important;
      width: 0;
      height: 0;
      opacity: 0;
    }

  }

  &__background-phone{
    width:0;
    height: 0;
    opacity: 0;
    // font-size: 2rem !important;
    // background-color: rgba($color-white, .5);
    // border-top: .1rem solid rgba($color-tertiary-dark, .1);
    // box-shadow: 1rem 1rem 1rem rgba($color-black, .01);
      // padding-left: 20rem;

    // padding: 2rem;
    // padding: 1rem;
    @include respond(phone){
      width: 90%;
      opacity: 1;
      height: fit-content !important;
      margin: -5rem auto 10rem auto !important;

      // overflow-x: scroll !important;
      // overflow-x: visible;
      // overscroll-behavior-x: auto;
      // margin-top: 2rem !important;
      // margin-bottom: 5rem !important;
      // margin-right: 5rem !important;
      // width: 0;
      // height: 0;
      // opacity: 0;
    }

  }
//   &__main{
//     width: 55rem !important;
// background-color: rgba($color-secondary-dark, .5) !important;
//   }

}

.heading-update-shipping-address-background-in-cart {
  width: 40%;
  //height: 55%;
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem auto 0 auto;
  padding: 2rem 5rem !important;
  border-radius: 1rem;
   -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  //margin-bottom: 5rem;
  box-shadow: .1rem .1rem .1rem rgba($color-black, .05);
  background-color: rgba($color-black, .015);
  border: .1rem solid rgba($color-black, .05);
  // padding: 2rem;

  @include respond(phone){
    width: fit-content;
  }

}


.heading-seller-view-shipping-regions-first {
  width: 100%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1rem auto -1rem auto;
  padding: 5rem;
  // margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .03);

  &__background {
    background-color: rgba($color-tertiary-dark, .1);
    // margin: 20rem;
    padding: 2rem;
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
  }
}

.heading-seller-view-shipping-regions {
  width: 100%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1rem auto;
  padding: 5rem;
  // margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .03);

  &__background {
    background-color: rgba($color-tertiary-dark, .1);
    // margin: 20rem;
    padding: 2rem;
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
  }
}

.heading-seller-dashboard-profile-experience-education {
  width: 95%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem auto 8rem auto !important;
  padding: 5rem;
  // margin-bottom: 5rem;
  box-shadow: 0 .2rem .2rem rgba($color-black, .3);
  @include respond(phone){
    width: 100% !important;
    // border: .3rem red solid;
    margin: 0 0 0 1rem !important;
  }

  &__background {
    background-color: rgba($color-white, .6);
    // margin: 20rem;
    padding: 2rem;
    @include respond(phone){
      padding: 0;
    // border: .3rem red solid;
    }
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
  }
}

.heading-seller-profile-experience-education {
  width: 55%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5rem auto;
  padding: 5rem 10rem;
  // margin-bottom: 5rem;
  // box-shadow: 0 1.5rem 2rem rgba($color-black, 1);

  &__background {
    background-color: rgba($color-white, .7);
    // margin: 20rem;
    padding: 2rem;
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
    padding: 5rem ;
  }
}

.heading-seller-experience-education {
  width: 98%;
  // height: 55%;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem 5rem 2rem 0 !important;
  padding: 5rem 10rem;
  margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .03);

  &__background {
    background-color: rgba($color-white, .5);
    // margin: 20rem;
    margin-bottom: 4rem !important;
    padding: 2rem 10rem;
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
    padding: 5rem ;
  }
}

.heading-seller-profile--name-picture {
  width: 100%;
  // height: 55%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5rem -2rem 5rem -2rem;
  padding: 5rem;
  // border: .1rem red solid;
  // margin-bottom: 5rem;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .03);

  &__background {
    background-color: rgba($color-white, .7);
    // margin: 20rem;
    padding: 2rem 3rem;
  }
   &__select-form {
    margin-bottom: 1rem;
    // background-color: rgba($color-tertiary-dark, .1);
   }

  &__experience-education-width {
    width: 75%;
    margin: 2rem auto;
  }
}

.heading-primary-seller-video-side-description{
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin: auto;
  // border: .2rem red solid;
  box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
  // margin-bottom:-3rem;
}

 .heading-posts{
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap !important;
   justify-content: flex-start;
   justify-items: center;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .2);
  // text-transform: capitalize;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom:-3rem;
  font-size: 1.5rem;
  margin-right: -1rem;
  padding: 2rem 1rem;
  // margin-top: -4rem;



  &--post-postedBy {
    width: 40%;
    font-size: 1rem;
    color: rgba($color-tertiary-light, 1);
    text-align:start;
    // font-weight: 700;
    background-color: rgba($color-grey-light-1, 0);
    box-shadow: 1rem .5rem 2rem rgba($color-tertiary-light, .2);
    display: flex;
    justify-content: space-between;
    padding: 0 0 .2rem .2rem;
    // margin: 3rem auto ;
    // padding-top: 5rem;
    border: none;
    border-radius: .5rem;
    text-transform:initial;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: .5rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;

  }
  &__postedBy {
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
  //  flex-wrap: wrap;
  //  justify-items: center;
  color: rgba($color-black, 1);
  background-color: rgba($color-tertiary-dark, .1);
  font-size: 1rem;
  margin-left: -.2rem;
  margin-right: .2rem;
  // margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: .1rem;
  padding-right: 2rem;
  }

  &__commentedBy {
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: row;
   justify-content:flex-start;
   flex-wrap: wrap;
   justify-items: center;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .3);
    box-shadow: 1rem .5rem 2rem rgba($color-white, .2);
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: .2rem;
  // margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  // padding-right: .5rem;
  padding-left: 1rem;

    &--date{
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content:flex-start;
      color: rgba($color-tertiary-light, 1);
      // border: .1rem red solid;
    }
  }

  &__comments {
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
  //  flex-wrap: wrap;
   justify-items: center;
  color: rgba($color-black, 1);
  background-color: rgba($color-tertiary-dark, .1);
  // border: .2rem red solid;
  font-size: 1rem;
  // margin-top: 5rem;
  margin-left: -.2rem;
  margin-right: -.2rem;
  // margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: .1rem;
  padding-right: 1rem;

  &__height-scroll {
    height: 35vh;
    overflow: scroll;
    margin-top: 2rem;
    // border: .2rem red solid;
  }


  @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-1;
          }
        }
          .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
      }

      .col-3-of-4 {
      width: calc( 3*((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-2});
      }
          .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }
  }

  &__text-likes {
     width: 100%;
   height: fit-content;
   display: block;
   flex-direction: row;
   justify-content: space-between;
  //  flex-wrap: wrap;
  //  justify-items: center;
  color: rgba($color-black, 1);
  background-color: rgba($color-tertiary-light, .05);
  box-shadow: 1rem .5rem 2rem rgba($color-black, .1);
  font-size: 1rem;
  margin-left: -.5rem;
  margin-right: -1rem;
  // margin-bottom: 1rem;
  padding: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  }

  &__post-text {
    width: 100%;
   height: fit-content;
   display: flex !important;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: flex-start;
   text-align: start;
  //  justify-items: center;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .5);
  box-shadow: 1rem .5rem 2rem rgba($color-black, .1);

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom:-3rem;
  font-size: 1.6rem;
  margin-right: -1rem;
  padding: 2rem;
  }

  &__contact-message {
    width: 100%;
   height: fit-content;
   display: flex !important;
   flex-direction: column !important;
   flex-wrap: wrap !important;
   justify-content: flex-start;
   text-align: start;
  //  justify-items: center;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .5);
  box-shadow: 1rem .5rem 2rem rgba($color-black, .1);

  // border: .2rem red solid;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom:-3rem;
  font-size: 1.6rem;
  margin-right: -1rem;
  padding: 2rem;
  }

  &__comment-text {
    width: 100%;
   height: fit-content;
   display: flex ;
   flex-direction:row ;
   flex-wrap: wrap;
   justify-content: flex-start;
   text-align: start;
   white-space:pre-wrap;
  //  overflow: hidden;
  //  overflow-wrap: break-word;
   text-transform: none;
   justify-items: center;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .5);
  box-shadow: 1rem .5rem 2rem rgba($color-black, .1);
  // border: #20a020 .2rem solid;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  // margin-bottom:-3rem;
  font-size: 1.5rem;
  margin: 0 auto;
  padding: 2rem;
  // padding-right: 5rem;

   &__height-scroll {
    height: 15vh;
    // width: 50%;
    padding-right: 5rem;
    overflow: scroll;
    // overflow-wrap: break-word;
    border-color: rgba($color-tertiary-dark, 1);
  }
  }
 }

 .messageBox {
   width: 70%;
   display: flex;
   color: rgba($color-white, 1);
   background-color: rgba($color-tertiary-dark, .8);
   margin: auto;
   padding: 1rem;
   font-size: 1.7rem;
 }

.podcast{
  color: $color-tertiary-dark;
  text-transform: uppercase;
  // border: red .1rem solid;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;


  &--main {
    // font-size: 6rem;
    // font-weight: 500;
    // margin: 2rem 5rem;
    // padding: 5rem 2rem 0 2rem !important;
    // background-color: rgba($color-white, .3);
    // letter-spacing: .5rem;
    // animation-name: moveInLeft;
    // animation-duration: 1s;
    // animation-timing-function: ease-out;

  }


}




.heading-categories-subs-column{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size:1.5rem;
  font-weight:500;
  color: rgba($color-primary-light, 1);
  background-color: rgba($color-white, .1);
  margin-bottom: .25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @include respond(phone){
    font-size: 3rem !important;
    font-weight: bold;
    padding-right: 3rem;
  }
  // border: .1rem blue solid;

// color: #dfe3fb;

  // &__show-subs {
  //   width: 100%;
  //   display: flex;
  //   text-align: center;
  //   //  cursor: pointer;

  // }
}

.heading-seller-home-categories-subs-column {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size:1.45rem;
  font-weight:400;



  // &__show-subs {
  //   width: 100%;
  //   display: flex;
  //   text-align: center;
  //   //  cursor: pointer;

  // }
}


.heading-categories-subs-show-categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  // margin-left: 1rem;
  margin-right: 2rem !important;
  margin-bottom: .5rem;
  padding: .5rem;
  // padding-bottom: .5rem;
  cursor: pointer;
  font-size:1.5rem;
  // font-weight:700 !important;
  font-weight: bolder !important;
  // text-decoration:solid;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .4);
  @include respond(phone){
    font-size: 3rem !important;
    color: rgba($color-primary-light, 1);

  }
  // text-decoration-line: underline;
  // -moz-text-decoration-line: underline;
}


.heading-seller-home-categories-subs-show-categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  cursor: pointer;
  font-size:1.5rem;
  font-weight:500;



  // &__show-subs {
  //   width: 100%;
  //   display: flex;
  //   text-align: center;
  //   //  cursor: pointer;

  // }
}

.heading-categories-subs-display {
  width: 100%;
  margin: 2rem .5rem ;
  // padding: 1rem 1.5rem !important;
  text-align: center;
  display: flex;
  color: rgba($color-tertiary-light, 1);
  font-size: 1.5rem;
  font-weight: 500;
  // background-color: rgba($color-black, .8);
  // box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
  z-index: 10000;

  cursor: pointer;

  &__hide-products {
    width: 15rem !important;
    height: fit-content;
    background-color: rgba($color-white, .3);
     padding: 3rem 3rem !important;
     font-size: 1.7rem;
     font-weight: 600;
  }



  &__show-subs {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    text-align: center;
     cursor: pointer;
     z-index: 20000;
    background-color: rgba($color-white, 1);
    //   &:hover {
    //   transform: translateY(-1px);
    //   -webkit-transform: translateY(-1px);
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
    //   -moz-transform: translateY(-1px);
    //   -ms-transform: translateY(-1px);
    //   -o-transform: translateY(-1px);
    // }
      &:hover {
      transform: translateY(-3px);
      -webkit-transform: translateY(-3px);
      box-shadow: 0 .1rem .1rem rgba($color-black, .2);
      -moz-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      -o-transform: translateY(-3px);
}

    //   &:hover {
    //   transform: translateY(-2px);
    //   -webkit-transform: translateY(-2px);
    //   box-shadow: 0 1rem 2rem rgba($color-black, .2);
    // }

  }


}

.order-product-detail {
  display: flex;
  flex-direction: column;

  &__name {
    display: flex;
    flex-direction: row;
    // padding-right: 3rem;
  }

  &__row {
    display: flex;
    flex-direction: row;
    //  margin-right: 13rem;
  // }

    justify-content: space-between;

    &--bold{
      font-weight: 600;
      padding-right: 5rem;
      // padding-left: 5rem;
    }
  }
}

.order-summary-seller-details{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-summary-background-grey{
  width: 95%;
  margin: 3rem auto 3rem auto;
  padding: 5rem 2rem 5rem 2rem;
  // padding-bottom: 8rem;
  font-size: 1.5rem;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-tertiary-dark, .01);
  border: .05rem rgba($color-black, .1) solid;
  box-shadow: .2rem .2rem .2rem rgba($color-black, .15);

  @include respond(phone){
    font-size: 2.5rem;
  }
}

.contact-seller-admin-customer{
  width: 90%;
  font-size: 1.7rem;
  margin: 3rem auto 3rem auto;
  padding: 2rem ;
  color: rgba($color-tertiary-light, 1);
  background-color: rgba($color-white, .6);
  box-shadow: .2rem .2rem .2rem rgba($color-black, .2);
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    font-size: 3rem;
  }
  &:hover {
  transform:scale(1) translateY(-.4rem);
  background-color: rgba($color-secondary-dark, .15);
  -webkit-transform:scale(1) translateY(-.4rem);
  -moz-transform:scale(1) translateY(-.4rem);
  -ms-transform:scale(1) translateY(-.4rem);
  -o-transform:scale(1) translateY(-.4rem);
 }
   &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    background-color: rgba($color-secondary-dark, .2);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}


.product-description-text {
  width:fit-content;
  height: fit-content;
  text-align: start;
  font-size: 1.2rem;
  color:rgba($color-tertiary-light, 1);
  background-color: rgba($color-black, .03);
  margin: 1.5rem 1rem auto -1rem !important;
  padding: 2rem  !important;
}

.heading {
  font-size: 24px;
  color: rgba($color-tertiary-light,1);
  margin-bottom: 40px;
  font-weight: bold;

}

.heading-mession {
  font-size: 2rem;
  color: rgba($color-primary-light,1);
  margin-bottom: .3rem;
  font-weight: 500;

}


.heading--create-account-title {
  font-size: 24px;
  color: rgba($color-tertiary-light,1);
  // margin-bottom: 40px;
  font-weight: 500;
  text-align:center;

}

.drawer-title {
  font-size: 1.4rem;
  color: rgba($color-tertiary-light,1);
  // margin-bottom: 40px;
  font-weight: 500;
  text-align:center;

}
.title {
  font-size: 1.4rem;
  color: rgba($color-tertiary-light,1);
  // margin-bottom: 40px;
  font-weight: 500;
  text-align:center;

}

.domain-note {
    font-size: 1.5rem;
    font-weight: 600;
    // font-family: inherit;
    color: rgba($color-tertiary-light, .7);
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-secondary-dark, .03);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: flex;
    transition: all .3s;

    @include respond(phone){
      font-size: 3rem;
    }

    &--main{
       background-color:rgba(red, .02);
       color: rgba($color-primary-light, .7);
       font-size:1.5rem;
       font-weight:500;
       letter-spacing:.1rem;
       margin:2rem 0 0 0 !important;
       padding:1rem 2rem;
       text-transform:none;
    }

    &__button{
      // width: 20rem;
      border: .1rem white solid;
      //  background-color:rgba(rgb(236, 226, 228), .31);
    }
}

.additional-sizes-and-quantities-section {
 width:100% !important;
 display: flex;
 flex-direction:column;
 justify-content: center;
 justify-items: center;
 margin: 2rem auto;

 &__background {
  background-color:rgba($color-white, .04);
  padding:1rem ;
  padding-bottom:1rem !important;
  // margin: 2rem auto 0 auto;
  // margin-bottom:2rem !important;
  // padding: .5rem auto !important;
 }
}

.option-5{
 width:100% !important;
 display: flex;
 flex-direction:column;
 justify-content: center;
 justify-items: center;
 margin: 2rem auto;

 &__background {
  background-color:rgba($color-tertiary-dark, .04);
  padding:1rem ;
  padding-bottom:1rem !important;
  margin-top: -3rem;
  // margin: 2rem auto 0 auto;
  // margin-bottom:2rem !important;
  // padding: .5rem auto !important;
 }
}

.sizes-and-quantities-options-A-and-B {
 width:100% !important;
 display: flex;
 flex-direction:row;
 justify-content: center;
 justify-items: center;
//  margin: 2rem auto;
 padding: 2rem  !important;
//  border: .1rem red solid;

 &__background {
  width: 50%;
  background-color:rgba($color-secondary-dark, .04);
  padding:1rem ;
  padding-bottom:1rem !important;
  // margin: 2rem auto;
  // margin-bottom:2rem !important;
  // padding: .5rem auto !important;
 }
}

.rgba-optional{
  font-size: 1rem;
  letter-spacing: .2rem;
  margin-left: .5rem;
}

.shipping-section {
 width:100% !important;
 display: flex;
 flex-direction:column;



 &__background {
  background-color:rgba($color-tertiary-dark, .03);
  padding:2rem;
  padding-bottom:1rem;
  margin-bottom:2rem !important;
 }

 &__free{
  display:flex;
  flex-direction:row!important;
 }

 &__standard-plus-weight{
  display:flex;
  flex-direction:row!important;
  justify-content:space-around;
  justify-items:center !important;
  padding-top:.5rem;
  margin-bottom:1rem;
  background-color:rgba($color-white, .2);
  box-shadow:.1rem .1rem .1rem rgba($color-black, .1);
 }

 &__standard-plus-weight-inputs{
  width:25%;
  display:flex;
  flex-direction:row!important;
  justify-content:space-around;
  justify-items:center !important;
  padding:1rem;
  margin:.5rem 2rem 1rem 2rem;
  background-color:rgba($color-white, .2);
  box-shadow:.1rem .1rem .1rem rgba($color-black, .1);
 }

}

.option-section {
 width:100% !important;
 display: flex;
 flex-direction:column;
 margin-bottom: -1rem;
 margin-top: -1rem;



 &__background {
  background-color:rgba($color-tertiary-dark, .03);
  padding:2rem;
  padding-bottom:1rem;
  margin-bottom:2rem !important;
 }

 &__free{
  display:flex;
  flex-direction:row!important;
 }

 &__standard-plus-weight{
  display:flex;
  flex-direction:row!important;
  justify-content:space-around;
  justify-items:center !important;
  padding-top:.5rem;
  margin-bottom:1rem;
  background-color:rgba($color-white, .2);
  box-shadow:.1rem .1rem .1rem rgba($color-black, .1);
 }

 &__standard-plus-weight-inputs{
  width:25%;
  display:flex;
  flex-direction:row!important;
  justify-content:space-around;
  justify-items:center !important;
  padding:1rem;
  margin:.5rem 2rem 1rem 2rem;
  background-color:rgba($color-white, .2);
  box-shadow:.1rem .1rem .1rem rgba($color-black, .1);
 }

}




.color-background-subs-seller-products{
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba($color-black, .02);
  border: .1rem solid rgba($color-black, .07);
  margin: -3rem auto 10rem auto !important;
  &:not(:last-child){
    margin-right: 3rem;
  }
  // margin-top: 2rem;
  // margin-bottom: 2rem ;
  padding: 1.5rem;
}

.podcast-description-color-background{
  // width: 100rem;
  background-color: rgba($color-black, .02);
  border: .1rem solid rgba($color-black, .07);
  margin: 15rem auto 2rem 6rem !important;
}


.podcast-description-color-background-1-of-4{
  // width: 100rem;
  background-color: rgba($color-black, .02);
  border: .1rem solid rgba($color-black, .07);
  margin: 2rem auto 2rem auto !important;
}


.color-background-seller-products-categories-list{
  // width: 100rem;
  background-color: rgba($color-black, .02);
  border: .1rem solid rgba($color-black, .07);
  margin: 0 auto 0 auto;
  &:not(:last-child){
    margin-right: 3rem;
  }
  // margin-top: 2rem;
  // margin-bottom: 2rem ;
  padding: 3rem;
}


.option4-A-color-background{
  // width: 100rem;
  background-color: rgba($color-Greenprimary-dark, .015);
  border: .2rem solid rgba($color-white, .8);
  margin: 2rem auto;
  // margin-top: 2rem;
  // margin-bottom: 2rem ;
  padding: 3rem;
}

 .colors-section{
  width: 100%;
  display:flex;
  // flex-direction:row!important;
  // justify-content: center !important;
  // justify-items:center !important;
  padding: 1.5rem 3rem 1.5rem 2rem!important;
  // border: .1rem rgb(202, 15, 15) solid;
  // margin-bottom: 5rem !important;
  background-color:rgba($color-tertiary-dark, .05);
  box-shadow: .1rem .1rem .1rem rgba($color-black, .1);

  &__col-6{
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    justify-items:center !important;
    box-shadow:.1rem .1rem .1rem rgba($color-black, .2);
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    padding: .5rem 2rem !important;
    background-color:rgba($color-white, .35);
  }

  &__col-upload-color-pictures{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    justify-items:center !important;
    box-shadow:.1rem .1rem .1rem rgba($color-black, .2);
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    padding: 2rem !important;
    background-color:rgba($color-secondary-dark, .03);
  }
  &__col-12{
    display: flex;
    flex-direction:row !important;
    justify-content:space-evenly !important;
    justify-items:center !important;
    box-shadow:.1rem .1rem .1rem rgba($color-black, .2);
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    padding: 1.5rem auto !important;
    background-color:rgba($color-white, .25);
  }
 }

 .product-colors-options{
  // width: 40rem !important;
  width: fit-content !important;
  // height: fit-content !important;
  display:flex;
  flex-direction: column !important;
  // flex-wrap: wrap;
  // flex-direction:row!important;
  justify-content:center !important;
  justify-items:center !important;
  padding: 2rem .5rem !important;
  // border: .1rem rgb(202, 15, 15) solid;
  // margin: auto 0 !important;
  // margin-right: -5rem !important;
  margin-left: -7rem !important;
  margin-top: 8rem !important;
  z-index: 100;
  // margin-bottom: 55rem !important;
  background-color:rgba($color-white, .15);
  // box-shadow: .3rem .3rem .3rem rgba($color-black, .1);
   height: 45vh;
    overflow-y: scroll;
    // overflow: -moz-scrollbars-none;
      // background: transparent;
     -ms-overflow-style: none;
    scrollbar-width: none;

    @include respond(phone){
      margin-top: 10rem !important;
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
      height: 25vh;
      //  justify-content:space-around !important;
  justify-items:center !important;
    }
    ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    // display: none !important;
      scrollbar-width: none;
    }


  &__background{
    width:fit-content !important;
    height: fit-content;
    display: flex;
    flex-direction: column !important;
    // justify-content: center !important;
    // justify-items: center !important;
    background-color: rgba($color-white, .5);
    padding: .5rem .7rem !important;
    margin: .5rem 1rem !important;
    border: .1rem white solid;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .3);

    @include respond(phone){
      height: fit-content !important;
    //   justify-content: flex-start !important;
    // justify-items: flex-start !important;
    margin: 1rem !important;
    }
}

 }

 .upload-colors-pictures-background-section{
  width: 100%;
  height: 7rem;
  display:flex;
  flex-direction:row!important;
  justify-content: center !important;
  justify-items:center !important;
  margin: 2rem auto 2rem auto;
  padding: 3rem 3rem 1.5rem 2rem!important;
  color: rgba($color-primary-light-2, .6);
  border-radius: 2rem;
  letter-spacing: .2rem;
  // padding: 3rem auto 1rem auto !important;
  //padding: 3rem auto !important;
  border: .1rem rgba($color-white, 1) solid;
  //margin-bottom: 5rem !important;
  background-color:rgba($color-tertiary-dark, .06);
  box-shadow: .3rem .3rem 1rem rgba($color-black, .1);
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  z-index: 10000;
}

 .option4-all-sizes-with-discount{
  width: 75%;
  display:flex;
  justify-content: center;
  justify-items:center;
  margin: 1rem auto;
  padding: 1.5rem 3rem 1.5rem 2rem!important;
  background-color:rgba($color-tertiary-dark, .05);
  box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
 }

 .size-qty-section{
  width: 100%;
  display:flex;
  flex-direction:column!important;
  justify-content: center !important;
  justify-items:center !important;
  padding: 2rem 3rem 2rem 3rem;
  // border: .1rem rgb(202, 15, 15) solid;
  // margin-bottom: 5rem !important;
  background-color:rgba($color-Greenprimary-dark, .01);
  box-shadow:.1rem .1rem .1rem rgba($color-black, .3);
  &__col-6{
    flex-direction:column !important;
    justify-content: center !important;
    justify-items:center !important;
    box-shadow:.1rem .1rem .1rem rgba($color-black, .1);
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    padding: 1.5rem auto !important;
    background-color:rgba($color-white, .25);
  }
  &__col-12{
    display: flex;
    flex-direction:row !important;
    // justify-content:space-evenly !important;
    justify-items:center !important;
    box-shadow:.2rem .2rem .2rem rgba($color-black, .1);
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    margin-bottom: 1rem ;
    padding: 1rem 4rem 1rem 4rem !important;
    background-color:rgba($color-white, .5);
  }
 }

 .nocolor-nosize-quantity-only-section{
  width: 50%;
  height: 55%;
  display: flex !important;
  // flex-direction: column;
  justify-content: center;
  justify-items: center;
  background-color: rgba($color-white, .3);
  box-shadow: .1rem .1rem .2rem rgba($color-black, .15);
  // border: .1rem red solid;
  margin: 3rem auto;
  padding: 5rem auto !important;
 }

 .sizes-qtys-section{
  width: 100%;
  display:flex;
  flex-direction:row!important;
  justify-content: center !important;
  justify-items:center !important;
  // padding: 2rem 3rem 2rem 1rem;
  // border: .1rem rgb(202, 15, 15) solid;
  // margin-bottom: 5rem !important;
  background-color:rgba($color-Greenprimary-dark, .01);
  box-shadow:.1rem .1rem .1rem rgba($color-black, .3);
  &__col-6{
    flex-direction:column !important;
    justify-content: center !important;
    justify-items:center !important;
    box-shadow:.1rem .1rem .1rem rgba($color-black, .1);
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    padding: 1.5rem auto !important;
    background-color:rgba($color-white, .25);
  }
  &__col-12{
    width: 100% !important;
    display: flex;
    flex-direction:row !important;
    justify-content:space-evenly !important;
    justify-items:center !important;
    box-shadow:.2rem .2rem .2rem rgba($color-black, .1);
    margin: 1rem auto;
    // border: .1rem rgb(202, 15, 15) solid;
    // margin: 2rem auto !important;
    // margin-left: 2rem;
    // margin-right: 2rem;
    padding: 0 5rem !important;
    background-color:rgba($color-white, .25);
  }
 }

.sale{
  background-color: rgba($color-secondary-dark, 1);
  size: 100rem!important;
}


.product-discount{
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  margin: .2rem 0;
  &:first-child{
        margin-top: 3rem !important;
      }
  padding: 1.5rem !important;
  padding-left: .1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(red, .7);
  // border: .1rem rgba($color-black, .2) solid;
  background-color: rgba($color-white, .8);

  @include respond(phone){
    font-size: 2.5rem;
  }
}

.create-coupon-message{
  font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin-bottom: 3rem;
    padding: .5rem .7rem;
    border-radius: 2px;
    background-color:rgba(red, .05);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;
}

.create-coupon-discount{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    font-size: 1.4rem;
    font-family: inherit;
    color: rgba($color-black, .5);
    margin: 1rem .5rem;
    padding: .5rem ;
    border-radius: 2px;
    background-color:rgba($color-black, .01);
    border: none!important;
    border-bottom: 3px solid transparent;
    transition: all .3s;
}

.display-coupon-message {
  font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin-bottom: 3rem;
    padding: .5rem .7rem;
    border-radius: 2px;
    background-color:rgba(red, .05);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;
}

.cloud-failed {
  width: 100%;
  height: fit-content;
  display: block;
  flex-direction:row;
  justify-content: center;
  justify-items: center;
  margin:1rem auto !important;
  padding: 5rem 1rem;
  font-size: 15rem;
  color: rgba(red, .8) !important;
  background-color: rgba($color-secondary-dark, .03);
  // border: .1rem solid red;
}

.order-details {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  // flex-direction: row;

  padding: 2rem;
  background-color: rgba($color-tertiary-dark, .03);
  border: rgba($color-black, .05) solid .02rem;

  @include respond(phone){
    font-size: 2.5rem;
  }

  &__qty-price{
    display: flex !important;
    flex-direction: row !important;
    // justify-content: space-between;
    margin-top: .5rem;
    margin-bottom: -1rem;
  }
  &__payment-info{
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    margin-bottom: -2rem;
    margin-top: .5rem;
    &--status-method{
      width: 25%;
    }
  }
  &__order-date{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    margin-top: .5rem;
    font-size: 1.4rem;
    padding: 1rem;
    // border: .1rem red solid;
  }

  &__status{
    color: rgba($color-white, 1);
    font-size: 2rem;
    background-color: rgba($color-secondary-dark, .3);
    padding: 2rem 5rem;
  }

}

.status-color-green {
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-Greenprimary-dark, .3);
    border: .1rem rgba($color-white, 1) solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .12);
    padding: 1rem 3rem;
}

.status-color-not-processed {
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-secondary-dark, .65);
    border: .1rem rgba($color-white, 1) solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .12);
    padding: 1rem 3rem;
}
.status-color-processing {
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-secondary-dark, .25);
    border: .1rem rgba($color-white, 1) solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .12);
    padding: 1rem 3rem;
}
.status-color-shipped {
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-Greenprimary-dark, .2);
    border: .1rem rgba($color-white, 1) solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .12);
    padding: 1rem 3rem;
}
.status-color-delivered {
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-Greenprimary-dark, .5);
    border: .1rem rgba($color-white, 1) solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .12);
    padding: 1rem 3rem;
}
.status-color-cancelled {
    color: rgba($color-white, 1);
    font-size: 2rem;
    font-weight: 600;
    background-color: rgba($color-grey-dark, .5);
    border: .1rem rgba($color-white, 1) solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .12);
    padding: 1rem 3rem;
}

.sample{
  width:5rem !important;
  height: 15rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  margin: 40rem auto;
  margin-top: 40rem;
  //margin-bottom: 20px;
  font-size: 3rem;
  padding: 8rem 55rem;
  text-align: center;
  background-color: rgba($color-white, .1);
  color:  rgba($color-white, .8);
  //border: .1rem red solid;
  border-radius:5rem;
  -webkit-border-radius:5rem;
  -moz-border-radius:5rem;
  -ms-border-radius:5rem;
  -o-border-radius:5rem;
  -webkit-border-radius:5rem;

  &__space{
    width: 20rem!important;
    margin: 1rem 0 2rem -7rem;
    padding: 1rem auto !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    justify-items: center;
    // border: .1rem red solid;
  }
}

.sample-1{
  width:15rem !important;
  height: 7rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  margin: 4rem auto;
  margin-top: 7rem;
  //margin-bottom: 20px;
  font-size: 3rem;
  padding: 3rem 5rem;
  text-align: center;
  background-color: rgba($color-white, .1);
  color:  rgba($color-white, .8);
  //border: .1rem red solid;
  border-radius:5rem;
  -webkit-border-radius:5rem;
  -moz-border-radius:5rem;
  -ms-border-radius:5rem;
  -o-border-radius:5rem;
  -webkit-border-radius:5rem;

  &__space{
    width: 20rem!important;
    margin: 1rem 0 2rem -7rem;
    padding: 1rem auto !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    justify-items: center;
    // border: .1rem red solid;
  }
}

.spin{
  width:6rem;
  height: 6rem;
  display: block;
  justify-content:center;
  justify-items: center;
  margin: 4rem auto 1rem -5rem !important;
  //margin-right: 55rem !important;
  //margin-top: 4rem;
  //margin-bottom: 20px;
  //font-size: 33rem !important;
  // padding:  auto 0 auto!important;
  text-align: center;
  text-size-adjust: 25rem;
  background-color: rgba($color-secondary-light, .3);
  color:  rgba($color-white, .8);
  //border: .1rem red solid;
  border-radius:50%;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  -ms-border-radius:50%;
  -o-border-radius:50%;
  -webkit-border-radius:50%;
}

.spin-reset-password{
  width: 100%;
  height: 6rem;
  display: block;
  justify-content:center;
  justify-items: center;
  margin: 4rem auto !important;
  text-align: center;
  background-color: rgba($color-white, .3);
  color: rgba($color-primary-light, 1);
  font-size: 3rem;

}

.seller-order-details-page {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 500;

  @include respond(phone){
    font-size: 2.4rem;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: .5rem;
    // justify-content: space-between;
    &--name-margin-right {
      margin-right: 4.2rem;

    }

    &--address-margin-right{
      margin-right: 3rem !important;

       &-city {
        margin-right: 6rem;
      }
       &-state {
        margin-right: 2rem;
      }
       &-zipcode {
        margin-right: 2rem;
      }
    }
  }
  &__country{
    display: flex;
    flex-direction: row;
    margin-top: -1.5rem;
    &--country-margin-right {
      margin-right: 3rem;

    }
  }
}

.option-2-sizes-quantities-layout{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

 .create-product-colors-options-second-note {
    width: 95%;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
     color: rgba($color-navy-dark, 1);
    background-color: rgba($color-white, 1);
    box-shadow: 0 .15rem .15rem rgba($color-tertiary-dark, .3);
    border: .2rem rgba($color-white, .8) solid;
    margin: 2rem auto 0 auto !important;
    padding: 5rem ;
    // padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    transition: all .3s;
    &__text{
      color: rgba($color-tertiary-light, .8);
    }
  }


 .create-product-colors-options-first-note {
    width: fit-content;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    // color: rgba($color-primary-light, 1);
    background-color: rgba($color-white, .2);
    box-shadow: 0 .15rem .15rem rgba($color-white, .9);
    border: .2rem rgba($color-white, .8) solid;
    margin: 2rem auto !important;
    padding: 1.5rem 5rem;
    // padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    transition: all .3s;
    &--main-note{
      text-transform: capitalize;
    }
  }

 .create-product-colors-options-third-body-note {
    width: 95%;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    // color: rgba($color-secondary-dark, 1);
    // background-color: rgba($color-tertiary-dark, .5);
    // box-shadow: 0 .15rem .15rem rgba($color-tertiary-dark, .3);
    border: .1rem rgba($color-white, .5) solid;
    margin: .1rem auto 2rem auto !important;
    padding: 2.5rem 5rem 2rem 5rem;
    // padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    transition: all .3s;
    &--main-note{
      text-transform: capitalize;
    }
  }

.dark-background{
  background-color: rgba($color-grey-dark, .035);
  text-align: center;
  margin: 2rem auto  !important;
  // padding: 0 1rem !important;
}

.single-product-main-pict-dark-background{
  height: 100% !important;
  width: 100% !important;
  min-height: 45rem;
  max-height: 45rem;
  object-fit: cover;
  background-color: rgba($color-grey-dark, .05);
  text-align: center;
  // margin: 2rem -5rem 1rem 0!important;
  // margin-right: -1rem !important;
  // padding-right: 0 !important;
  // padding-left: 0 !important;
  // padding-bottom: -500rem !important;
  // border: .2rem white solid;
}

.single-product-main-pict-in-modal{
  // height: 100% !important;
  // width: 100% !important;
  // min-width: 50rem;
  // max-width: 107rem;
  // min-height: 60rem;
  // max-height: 80rem;
  object-fit: fill !important;
  background-color: rgba($color-grey-dark, .05);
  text-align: center;
  // overflow: scroll;
  // margin: 2rem -5rem 1rem 0!important;
  // margin-right: -1rem !important;
  margin-bottom: -1rem !important;
  // padding-right: 0 !important;
  padding-left: -4rem !important;
  // padding-bottom: -500rem !important;
  &__height-scroll {
  // border: .2rem red solid;
  width: 50rem;
  // max-width: 107rem;
  // min-height: 60rem;
  height: 80rem;
    overflow: scroll;
    margin: 2rem;
    // border: .2rem red solid;
  }

}

.light-background{
  background-color: rgba($color-secondary-dark, .04);
  text-align: center;
  margin: 2rem auto  !important;
  // padding: 0 1rem !important;
  // border: .1rem red solid;
}

.single-product-small-column{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    width: 100%;
    border-radius: 1%;
    height: 100%;
    background-color: rgba($color-black, .04);
    margin-bottom: -4rem;
}

.subs-search{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
    width: fit-content;
    border-radius: 1%;
    height: 100%;
    margin: .5rem 1rem .1rem 0;
    // margin: 1rem auto 1rem .1rem;
    padding: 1.3rem;
    font-size: 1.5rem;
    font-weight: 400;
    background-color: rgba($color-tertiary-dark, .05);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .07);
    // margin-bottom: -4rem;
//     &__height-scroll{
//       height: 60vh;
//       overflow-y: scroll;
//       backface-visibility: visible;
//       -webkit-backface-visibility: visible;
// }
@include respond(phone){
  font-size: 3rem;
  font-weight: 400;
}
}


.subs-seller-products{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    width: fit-content;
    border-radius: 5%;
    height: 100%;
    margin: .5rem 1rem .1rem .5rem;
    //margin: 1rem auto 1rem .1rem;
    padding: 1.3rem;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .2rem;
    color: rgba($color-primary-light, 1);
    background-color: rgba($color-white, .45);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .17);
    //margin-bottom: -4rem;
    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;
}

.enlarge-modal-background{
  display: flex;
  justify-content: center !important;
  background-color: rgba($color-white, .4);
  text-align: center !important;
  margin: -7rem 8rem 7rem auto  !important;
  color: rgba(#424242, .7);
  font-size: 1.5rem;
  // z-index: 10000;
  // padding: 0 1rem !important;
  // border: .1rem red solid;
  @include respond(phone){
    // width: 0 !important;
    // height: 0 !important;
    // opacity: 0 !important;
    font-size: 3rem;
    font-weight: 700;
    color: rgba($color-secondary-dark, 1);
  }
  &__model{
    width: 55% !important;
    // height: 100% !important;
    // opacity: 1;

    @include respond(phone){
      width: 100% !important;
      height: 100% !important;
       margin: 20rem auto !important;
      // opacity: 0 !important;
    }
  }
}

.next-button{
  background-color: rgba($color-navy-dark, .4);
  color: rgba($color-white, 1);
  border: none;
  text-align: center;
  letter-spacing: .2rem;
  font-weight: 500;
  margin: 1rem auto  !important;
  margin-right: 2rem !important;
  margin-bottom: 3rem !important;
  box-shadow: 1rem 1rem 1rem rgba($color-black, .1);
  // padding: 0 1rem !important;
}

.size{
  font-size: 2rem;
  text-align: center;
  color: rgba($color-secondary-dark, .15);
}

.height {
  height: 85vh !important;
}

.flexColumn{
  display: flex !important;
  background-color: rgba($color-black, .05);
  flex-direction: column !important;
  height: 70vh !important;
  // margin-top: 5rem;
  padding-bottom: 5rem;

    overflow: scroll;
    // scroll-behavior:smooth;

    backface-visibility: hidden ;
    -webkit-backface-visibility: hidden ;
  // margin-bottom: 10rem !important;
}

.d-flexRow{
  display: flex !important;
  flex-direction: row !important;
  width: 100vw !important;
  // margin-top: 5rem;
}

.categories-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  // width: 50vw !important;
}

.single-product-flexColumn{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  justify-items: center;
  object-fit: scale-down !important;
  width: 100%;
  height: 100%;
  max-height: 50rem;
  min-height: 35rem !important;
  object-fit:fill;
  // margin-top: -3rem;
  // margin-bottom: -1rem;
  // margin-left: .1rem !important;
  // margin-right: .1rem !important;
  // padding: -3rem !important;
  // padding-left: 1rem;
  // padding-right: 1rem;
  background-color: rgba($color-white, .35);
  // margin-right: -1rem;
  // padding: 0 -1rem -2rem -1rem!important;
  // padding-right:-1rem !important;
  // border: .1rem red solid;
   &__height-scroll {
    height: 40vh;
    overflow-y: scroll;
    // overflow: -moz-scrollbars-none;
      background: transparent;
     -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    display: none !important;
      scrollbar-width: none;
    }


    // margin-top: -2rem;
     object-fit: fill !important;
      width: 100% !important;
  height: 80%;
  // max-height: 15rem;
  // min-height: 15rem !important;
    // border: .2rem red solid;
  }
}



// .background-box-shadow3{
//    background-color: rgba($color-navy-dark, .75);
//   box-shadow: 2rem 2rem 1rem rgba($color-black, .3);
// }

.view-order-messages{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: space-around;
  border: .1rem white solid;
  box-shadow: .5rem .5rem .5rem rgba($color-black, .1);
}


.center{
  margin: 1rem  auto 1rem 13rem !important;
  // border: .1rem red solid;
}

.product-video-title{
  color: rgba($color-white, .7);
  text-shadow: #e0e0f0;
  margin-top: .5rem;

  display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .2rem;
    animation: moveInRight 1s ease-out;
    -webkit-animation: moveInRight 1s ease-out;
}

.business-description{
  margin-top: -3rem !important;
}

.font{
  font-size: 1.7rem !important;
  font-weight: 600;
  text-transform: none !important;
  letter-spacing: .1rem;
  color: rgba($color-primary-light, .8) !important;
  // margin-left: 1rem;
  &__center{
    display: block;
    text-align: center;
  }
  @include respond(phone){
    font-size: 3rem !important;
  }
}

.zoom-review{
  font-size: 1.7rem !important;
  font-weight: 600;
  text-transform: none !important;
  letter-spacing: .1rem;
  color: rgba($color-primary-light, .8) !important;
  // margin-left: 1rem;

  @include respond(phone){
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.vid-upload-error{
  background-color: rgba($color-secondary-dark, .5) !important;
  font-size: 1.7rem !important;
  text-align: center !important;

}



