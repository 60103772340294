.carousel-main-home-display-main-carousel {
    // object-fit: fill;
    width:94% !important;
     max-height: 50rem !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    position: absolute;
    top: 0;
    margin: 1rem .5rem -1rem 4rem !important;
    // margin-bottom: 3rem;
    // padding-top: .5rem;
    transition: all .1s;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -ms-transition: all .1s;
    -o-transition: all .1s;
    // overflow: hidden;
    // box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
    // margin-top: -.7rem;
    // margin-left: 0;
    // margin-right: 0;
    z-index: 100;
     @include respond(big-desktop){
       width:93% !important;
        // height: 55vh !important;
        display: flex;
          margin: -1rem 3rem 1rem 2rem !important;
          // border: .5rem red solid;
     }
     @include respond(lab-top){
       width:93% !important;
        // height: 55vh !important;
        display: flex;
          margin: 1rem 3rem 1rem 2rem !important;
          // border: .5rem red solid;
     }
     @include respond(tab-land){
       width:100% !important;
        height: 60vh !important;
        display: flex;
          margin: 1rem 3rem 1rem 2rem !important;
          // border: .5rem red solid;
     }
     @include respond(tab-port){
       width:100% !important;
        height: 100% !important;
        display: flex;
          margin: 1rem .5rem -1rem 4rem !important;
     }

    &__img{
      box-shadow: 1rem 1rem 1rem rgba($color-black, .5);
      object-fit: fill !important;
      width: 93% !important;
      max-height: 47rem !important;
      // min-height: 35rem;
      // padding-top: -1rem;
      display: flex !important;
      justify-content: center !important;
      justify-items: center !important;

       @include respond(big-desktop){
      width: 100% !important;
      max-height: 70vh !important;
      margin-top: -8rem !important;
      // border: .2rem red solid;
     }
       @include respond(lab-top){
      width: 95% !important;
      max-height: 65vh !important;
     }
       @include respond(tab-land){
      width: 95% !important;
      max-height: 60vh !important;
     }
       @include respond(tab-port){
      width: 93% !important;
      max-height: 60rem !important;
     }
       @include respond(phone){
      width: 93% !important;
      max-height: 40rem !important;
      // margin: 2rem !important;
     }
       @include respond(small-phone){
      width: 93% !important;
      max-height: 35rem !important;
     }

    }
}

// .slide {
//   min-width: 33% !important;
// }

.carousel .thumb {
  //width: 10rem !important;
  //height: 5rem !important;
  max-height: 4.7rem;
  // margin-top: 2rem !important;
  margin-bottom: .2rem;
  padding: 0 !important;
  // object-fit: fill !important;
  //border: .2rem red solid;
  // border-radius: none !important;
  //border: .1rem red solid !important;
  // -webkit-border-radius: none !important;
  // -moz-border-radius: none !important;
  // -ms-border-radius: none !important;
  // -o-border-radius: none !important;
}

// .ul .thumb {
//   width: 45vw !important;
//   border: .2rem yellow solid !important;
//   display: block;
//   flex-direction: column !important;
//   flex-wrap: wrap !important;
//   overflow: hidden;
// }
.thumbs {
  // width: 40vw;
  height: fit-content;
  padding-top: 0 !important;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  border-radius: none;
  margin-top: 5rem;
  margin-bottom: 2rem !important;
  // margin-left: 5rem;
  padding-left: 5rem;
  // border: .1rem red solid;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  -ms-border-radius: none;
  -o-border-radius: none;
}

// .star-raings{
//   background-color: red !important;
//   margin: 5rem 5rem 5rem 15rem !important;
// }

