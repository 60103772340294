@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}


@mixin absCenter {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
}


//  MEDIA QUERY MANAGER:
/*
0 - 600px: Phone
600px - 900px: Tablet portrait
900px - 1200px: Tablet landscape
[1200px - 1800px] is where our normal styles apply
1800px + : Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px


*/

@mixin respond($breakpoint){
  @if $breakpoint == small-phone {
    @media (max-width: 23.4375em){ @content }; //375px
  }
  @if $breakpoint == phone-400 {
    @media (max-width: 25em){ @content }; //400px
  }
  @if $breakpoint == phone-500 {
    @media (max-width: 31.25em){ @content }; //500px
  }
  @if $breakpoint == phone {
    @media (max-width: 37.5em){ @content }; //600px
  }
  @if $breakpoint == tab-700 {
    @media (max-width: 43.75em){ @content };  //700px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em){ @content };  //900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em){ @content };  //1200px
  }

  @if $breakpoint == lab-top {
    @media (max-width: 112.5em){ @content };  //1200px
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.51em){ @content };  //1800px
  }
  // @if $breakpoint == phone {
  //   @media (max-width: 600px){ @content };
  // }
}
