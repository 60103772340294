.u-center-text {text-align: center;}

.u-margin-top-Xsmall{ margin-top: 2rem;}
.u-margin-top-small{ margin-top: 4rem;}
.u-margin-top-big{ margin-top: 8rem;}
.u-margin-top-huge{ margin-top: 10rem;}
.u-margin-top-xsmall{ margin-top: -2rem;}
.u-margin-top-xmedium{ margin-top: -6rem;}

.u-margin-bottom-small { margin-bottom: 1.5rem;}
.u-margin-bottom-Xsmall { margin-bottom: -2rem;}
.u-margin-bottom-medium {margin-bottom: 4rem;}
.u-margin-bottom-big{ margin-bottom: 8rem;}
.u-margin-bottom-huge{ margin-bottom: 15rem;}

.u-margin-left-small{ margin-left: 1rem;}
.u-margin-left-medium{ margin-left: 3rem;}
.u-margin-left-big{ margin-left: 6rem;}
.u-margin-right-small{ margin-right: 1rem;}
