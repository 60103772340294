.new-row-new-arrivals {
    max-width: 100vw;
    height: 50vh;
    background-color: $color-grey-light-1;
    margin: 0 ;
    padding: 0;
    // border: .2rem red solid;

    @include respond(tab-port){ // width < 900 ?
     max-width: 100vw;
    height: 35vh;
   }
    @include respond(phone){ // width < 600 ?
     max-width: 100vw !important;
    height: 25vh !important;
   }
    @include respond(small-phone){ // width < 600 ?
     max-width: 100vw !important;
    height: 25vh !important;
   }

    &:not(:last-child){
        margin-bottom: $gutter-vertical;


    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;

       @include respond(tab-port){
          margin-right: 0;
        }
     }

     @include respond(tab-port){
      width: 100% !important;
     }

    }


    //  .col-1-of-2 {
    //  width: calc((100% - #{ $gutter-horizontal-2})/2);

    // }

    // .col-1-of-3 {
    // width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    // }

    // .col-2-of-3 {
    // width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    // }



    // .col-1-of-4 {
    // width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    // }
    // .col-1-of-5 {
    // width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
    // }

    //   .col-2-of-5 {
    //   width: calc(2 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 1 * #{$gutter-horizontal-2});

    // }
    //   .col-3-of-5 {
    //   width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    // }
    //   .col-4-of-5 {
    //   width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    // }

    // .col-2-of-4 {
    // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    // }

    // .col-3-of-4 {
    // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    // }
    // .col-1-of-6 {
    // width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    // }

    // .col-2-of-6 {
    // width: calc( 2 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 1 * #{$gutter-horizontal-2});

    // }
    // .col-3-of-6 {
    // width: calc( 3 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 2 * #{$gutter-horizontal-2});

    // }

    // .col-5-of-6 {
    // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    // }
    // .col-1-of-7 {
    // width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    // }

    // .col-6-of-7 {
    // width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    // }

  }

  .row-background-main-home-page {
    width: 100%;
    // position: relative;
  // background-image: linear-gradient(99deg, what it was first
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);

  // background-image: linear-gradient(99deg,    this is 4 of 5 maybe
  // rgba(172, 174, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(150, 151, 249, 0.3) 50%,
  // rgba(173, 174, 247, 0.3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(170, 171, 245, 0.4) 100%,
  // rgba(169, 170, 245, 0.4) 100%);

  background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

  // ChatGPT:
  // background-color: rgba(#1c5487, .15);
  // background-color: #fff;

    margin: .1rem auto 1.3rem auto;

    padding-top: 1rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;

      @include respond(tab-port){
      margin-right: 0;
     }
     }

      @include respond(tab-port){
      width: 100% !important;
     }


   }


  //  .col-1-of-2 {
  //    width: calc((100% - #{ $gutter-horizontal-1})/2);

  //   }

  //   .col-1-of-3 {
  //   width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
  //   }

  //   .col-2-of-3 {
  //   width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
  //   }

  //   .col-1-of-4 {
  //   width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
  //   }

  //   .col-1-of-4-seller-home {
  //   width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

  //   }

  //   .col-shit {
  //   width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

  //   }

  //   .col-2-of-4 {
  //   width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
  //   }

  //   .col-3-of-4 {
  //   width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
  //   }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    // @include respond(phone){
    //   width: 90vw !important;
    // }
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

  //   .col-3-of-7 {
  //   width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
  //   }
  //   .col-4-of-7 {
  //   width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
  //   }
  //   .col-1-of-7 {
  //   width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
  //   //  margin-top: .5rem;
  //   //  border: .3rem red solid;
  //   }
  //   .col-6-of-7 {
  //   width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
  //   }

}

.row-main-home-categories-background {
    width: 95vw;
    height: fit-content !important;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    //background-image: linear-gradient(99deg,//   rgba(88, 91, 245, 0.4) 0%,//   rgba(178, 179, 243, 0.3) 15%,//   rgba(67, 70, 241, .3) 50%,//   rgba(67, 70, 241, .3) 51%,//  rgba(178, 179, 243, 0.3) 85%,//  rgba(88, 91, 245, 0.4) 100%,//   rgba(67, 70, 241, .4) 100%);
    margin: 4rem auto 1rem auto !important;
    padding-top: 2rem !important;
    padding-bottom: 0;
    padding: 2rem auto 0 5% !important;
    // box-shadow: 0 .5rem .5rem rgba($color-black, .03);
// border: .6rem red solid;
  @include respond(big-desktop){
      width:95%;
      height: 35% !important;
      display: flex;
      // flex-wrap: wrap;
      justify-content: flex-start;
      justify-items: flex-start;
      border-radius: 1%;
      -webkit-border-radius: 1%;
      -moz-border-radius: 1%;
      -ms-border-radius: 1%;
      -o-border-radius: 1%;
      // border: .3rem red solid;
      padding: 2rem 5% 0 5% !important;
      margin: 7rem 5rem 1rem 5rem !important;
     }
  @include respond(tab-land){
    width:100%;
    // height: 40% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    // border: 2rem blue solid;
     padding: 2rem 3% 0 3% !important;
    // margin: 7rem 5rem 1rem 5rem !important;
     }


  @include respond(tab-port){
    width: 100% !important;
    height: 35% !important;
    display: flex;
    // flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    margin-left: 0 !important;
    // border: 1rem blue solid;
    // margin: 7rem 5rem 11rem 5rem !important;
  }

      @include respond(phone){
          width:100% !important;
          height: 35% !important;
          margin: 10rem 10rem 0 1rem !important;
          padding: 3rem .5rem 1rem .5rem !important;
          // border: .2rem blue solid;
        }
      @include respond(phone-500){
          width:100% !important;
          height: 35% !important;
          margin: 10rem 10rem 0 1rem !important;
          padding: 3rem .5rem 1rem .5rem !important;
          // border: .2rem blue solid;
        }

      @include respond(small-phone){
          // margin: 7rem 5rem 15rem 5rem !important;
          // margin-right: .2rem;
          // padding-right: 0;
          // border: .2rem blue solid;
           width:100% !important;
           height: fit-content !important;
           font-size: 2rem !important;
           margin: 10rem auto 1rem auto !important;
           padding: -20rem 0 2rem 0 !important;
        }


    &:not(:last-child){
      margin-bottom: $gutter-vertical;
       @include respond(tab-land){
        margin-bottom: 0 !important;
       }
       @include respond(tab-port){
        margin-bottom: 0 !important;
       }
       @include respond(phone){
        margin-bottom: 5rem !important;
       }
       @include respond(small-phone){
        margin-bottom: 2rem !important;
       }
   }
   @include clearfix;

   [class^="col-"] {

      //  @include respond(tab-port){
      //   width: 80vw !important;
      //  }
      //  @include respond(phone){
      //   width: 80vw !important;
      //  }
     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;

        @include respond(tab-port){
          margin-right: 0;
        }
        @include respond(phone){
          margin-right: .2rem;
          padding-right: 0;
        }
     }

    //   @include respond(tab-port){
    //   width: 100% !important;
    //  }


   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    background-color: rgba($color-white, 1);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
    padding-left: 1rem;
    color: rgba($color-black, 1);
    background-color: rgba($color-white, .5);
    justify-content: space-around;

     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    // background-color: #232f3e;
    }

    .col-1-of-5 {
       width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
       height: 75%;
        margin: 2rem 1rem 2rem 1rem !important;
        @include respond(tab-port){
        width: 30% !important;
      height: 45vh;
    }
    @include respond(tab-port){
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
      height: 35vh;
      margin: 2rem 1rem 2rem 1rem !important;
      // padding: 2rem 3rem !important;

    }
    @include respond(phone){
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
      height: 35vh;
      margin: 2rem 1rem 2rem 1rem !important;
      // padding: 2rem 3rem !important;

    }
    @include respond(small-phone){
      width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
      height: 18vh;
     margin: 2rem 1rem -6rem 1rem !important;
      // flex-direction: row;
      // flex-wrap: wrap;

    }


    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-01})/6) + 5rem);
      height: 70vh !important;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding: 2rem;
    padding-left: .1rem;
    color: rgba($color-primary-light, .8);
    font-size: 1.3rem;
    font-weight: 700;
    // border: red solid .2rem;
    // background-color: rgba($color-secondary-dark, .35);
    // justify-content: space-around;
     @include respond(big-desktop){
      // width: calc(((100vw - 5 * #{$gutter-horizontal-01})/6)) !important;
      width: 20vw !important;
          height: 65vh !important;
         font-size: 1rem !important;
        //  color: red !important;
     }
     @include respond(tab-port){
      // width: calc(((100vw - 5 * #{$gutter-horizontal-01})/6)) !important;
      width: 20vw !important;
          height: 50vh !important;
         font-size: 1rem !important;
        //  color: red !important;
     }
    // justify-content: space-around;
     @include respond(phone){
      width: 0vw !important;
       height: 0vh !important;
       opacity: 0;
      //  margin-right: .3rem !important;
      //    margin-top: -2rem !important;
      // margin: 2rem auto !important;
      // margin-bottom: 5rem !important;
      //    font-size: 3rem !important;
        //  color: red !important;
        //  border: 1rem yellow solid;
     }
    //  @include respond(small-phone){
    //   width: 60vw !important;
    //    height: 25vh !important;
    //   //  padding-bottom: 8rem;
    //   //  padding-right: 10rem !important;
    //   //  font-size: 5rem !important;
    //   //  margin-bottom: -8rem !important;
    //   //  margin-top: -2rem !important;
    //    margin: 2rem auto 5rem auto !important;
    //   //  margin: 5rem !important;
    //     //  font-size: .03rem !important;
    //     //  color: red !important;
    //     //  border: .1rem yellow solid;
    //  }
     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    //  &__height-scroll {
      // overflow-y: scroll;
      // scrollbar-width:thin;
      // // scrollbar-color: blue !important;
      // scrollbar-color: none !important;
    }


      .col-1-of-6-phone {
        width: 0;
        height: 0;
        opacity: 0;
    // width: calc(((100% - 5 * #{$gutter-horizontal-01})/6) + 5rem);
    //   height: 70vh !important;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    // display: flex;
    // flex-direction: column;
    // margin-top: 5rem;
    // padding: 2rem;
    // padding-left: .1rem;
    // color: rgba($color-primary-light, .8);
    // font-size: 1.3rem;
    // font-weight: 700;
    // border: red solid .2rem;
    // background-color: rgba($color-secondary-dark, .35);
    // justify-content: space-around;
    //  @include respond(big-desktop){
    //   // width: calc(((100vw - 5 * #{$gutter-horizontal-01})/6)) !important;
    //   width: 20vw !important;
    //       height: 65vh !important;
    //      font-size: 1rem !important;
    //     //  color: red !important;
    //  }
    //  @include respond(tab-port){
    //   // width: calc(((100vw - 5 * #{$gutter-horizontal-01})/6)) !important;
    //   width: 20vw !important;
    //       height: 50vh !important;
    //      font-size: 1rem !important;
    //     //  color: red !important;
    //  }
    // justify-content: space-around;
     @include respond(phone){
      width: 65vw !important;
       height: 25vh !important;
       opacity: 1;
        display: flex;
    flex-direction: column;
      //  margin-right: .3rem !important;
      //    margin-top: -2rem !important;
         background-color: rgba($color-white, .3);
      margin: 2rem auto !important;
      margin-bottom: 10rem !important;
         font-size: 3rem !important;
        //  color: red !important;
        //  border: 1rem yellow solid;
     }
     @include respond(small-phone){
      width: 60vw !important;
       height: 25vh !important;
      //  padding-bottom: 8rem;
      //  padding-right: 10rem !important;
      //  font-size: 5rem !important;
      //  margin-bottom: -8rem !important;
      //  margin-top: -2rem !important;
       margin: 2rem auto 10rem auto !important;
      //  margin: 5rem !important;
        //  font-size: .03rem !important;
        //  color: red !important;
        //  border: .1rem yellow solid;
     }
     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    //  &__height-scroll {
      // overflow-y: scroll;
      // scrollbar-width:thin;
      // // scrollbar-color: blue !important;
      // scrollbar-color: none !important;
    }

      .col-1-of-6-categories {
        width: 0;
        height: 0;
        opacity: 0;
    // width: calc(((100% - 5 * #{$gutter-horizontal-01})/6) + 5rem);
    //   height: 70vh !important;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    // display: flex;
    // flex-direction: column;
    // margin-top: 5rem;
    // padding: 2rem;
    // padding-left: .1rem;
    // color: rgba($color-primary-light, .8);
    // font-size: 1.3rem;
    // font-weight: 700;
    // border: red solid .2rem;
    // background-color: rgba($color-secondary-dark, .35);
    // justify-content: space-around;
    //  @include respond(big-desktop){
    //   // width: calc(((100vw - 5 * #{$gutter-horizontal-01})/6)) !important;
    //   width: 20vw !important;
    //       height: 65vh !important;
    //      font-size: 1rem !important;
    //     //  color: red !important;
    //  }
    //  @include respond(tab-port){
    //   // width: calc(((100vw - 5 * #{$gutter-horizontal-01})/6)) !important;
    //   width: 20vw !important;
    //       height: 50vh !important;
    //      font-size: 1rem !important;
    //     //  color: red !important;
    //  }
    // justify-content: space-around;
     @include respond(phone){
      width: 35vw !important;
       height: fit-content !important;
       opacity: 1;
        display: flex;
    flex-direction: column;
      //  margin-right: .3rem !important;
      //    margin-top: -2rem !important;
        //  background-color: rgba($color-secondary-dark, .35);
        position: relative;
      margin: .2rem auto 1rem 2rem!important;
      // margin-bottom: 1rem !important;
         font-size: 3rem !important;
         z-index: 10;
        //  color: red !important;
        //  border: 1rem yellow solid;
     }
     @include respond(small-phone){
      width: 35vw !important;
       height: fit-content !important;
      display: flex;
      flex-direction: column;
       font-size: 2rem !important;
       font-weight: 700 !important;
      // padding-left: -5rem !important;
      //  padding-bottom: 8rem;
      //  padding-right: 10rem !important;
      //  font-size: 5rem !important;
      //  margin-bottom: -8rem !important;
      //  margin-top: -2rem !important;
       margin: 2rem 10rem 1rem 2rem!important;
      //  margin: 5rem !important;
        //  font-size: .03rem !important;
        //  color: red !important;
        //  border: 1rem yellow solid;
     }
     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    //  &__height-scroll {
      // overflow-y: scroll;
      // scrollbar-width:thin;
      // // scrollbar-color: blue !important;
      // scrollbar-color: none !important;
    }

      .col-1-of-6--height-scroll {
    width: 100% ;
      height: 100% !important;
      padding: .5rem .5rem .5rem 1.5rem;
      // font-size: .2rem;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    // display: flex;
    // flex-direction: column;
    margin: .5rem 0 0 0;
    // padding-top: 1rem;
    // padding-left: 1rem;
    // color: rgba($color-primary-light, .8);
    // font-size: 1.3rem;
    // font-weight: 500;
    // background-color: rgba($color-white, .35);
    // // justify-content: space-around;
    //  &:last-child{
    //    margin-bottom: $gutter-horizontal-3;
    //  }
    //  &__height-scroll {
       overflow-y: scroll;
       scrollbar-width:thin;
      //  scrollbar-color: blue !important;
      //  scrollbar-color: none !important;
       border: .3rem rgba($color-navy-dark, .5) solid !important;
       border-radius:1%;
       -webkit-border-radius:1%;
       -moz-border-radius:1%;
       -ms-border-radius:1%;
       -o-border-radius:1%;
}

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    margin-top: 6rem;
    padding-top: 5rem !important;
    // margin-bottom: 13rem;
    position: relative;
    transition: all .1s ;
    display: flex;
    flex-direction: row;
    // background-color: black;
    // overflow: hidden;
    //padding-top: -9rem;
    height: 80vh;
    -webkit-transition: all .1s ;
    -moz-transition: all .1s ;
    -ms-transition: all .1s ;
    -o-transition: all .1s ;

      @include respond(big-desktop){
        width: 100% !important;
       }
      @include respond(tab-land){
        width: 70vw !important;
         height: 50vh !important;
       }
      @include respond(tab-port){
        width: 73vw !important;
         height: 50vh !important;
       }
       @include respond(phone){
        width: 90vw !important;
        height: 30vh !important;
        margin: 2rem 5% !important;
        padding: 0 auto !important;
        // margin-bottom: -11rem !important;
        // border: 1rem red solid;
       }
       @include respond(phone-500){
        width: 90vw !important;
        height: 20vh !important;
        margin: 3rem 5% !important;
        padding: 0 auto !important;
        // margin-bottom: -11rem !important;
        // border: 1rem red solid;
       }
       @include respond(small-phone){
        width: 100vw !important;
        height: 23vh !important;
        // margin-bottom: 1rem !important;
        // margin-right: 2rem;

        // border: 2rem red solid;
       }
  }

    .col-5-of-6-with-clicked-category {
    // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    margin-top: 6rem;
    padding-top: 5rem !important;
  //  border: .1rem red solid;
    position: relative;
    transition: all .1s ;
    display: flex;
    flex-direction: row;
    // background-color: black;
    // overflow: hidden;
    //padding-top: -9rem;
    height: 80vh;
    -webkit-transition: all .1s ;
    -moz-transition: all .1s ;
    -ms-transition: all .1s ;
    -o-transition: all .1s ;

      @include respond(big-desktop){
        width: 100% !important;
       }

      @include respond(lab-top){
        width: 75vw !important;
         height: 69vh !important;
         padding: 2rem auto !important;
        //  padding-left: 25rem !important;
        //  border: .1rem red solid;
       }

      @include respond(tab-land){
        width: 70vw !important;
         height: 68vh !important;
       }

      @include respond(tab-port){
        width: 73vw !important;
         height: 50vh !important;
       }
       @include respond(phone){
        width: 90vw !important;
        height: 60vh !important;
        margin: 0 5% !important;
        padding: 0 auto !important;
        // margin-bottom: -11rem !important;
        // border: 1rem blue solid;
        // border: .2rem red solid;
       }
       @include respond(phone-500){
        width: 100vw !important;
        height: 60vh !important;
        margin: 0 0 0 0 !important;
        padding: 0 auto !important;
        // margin-bottom: -11rem !important;
        // border: 1rem blue solid;
        // border: .2rem red solid;
       }
       @include respond(phone-400){
        width: 100vw !important;
        height: 60vh !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;
        // margin-bottom: -11rem !important;
        // border: 1rem blue solid;
        // border: .2rem red solid;
       }
       @include respond(small-phone){
        width: 100vw !important;
        height: 60vh !important;
        margin-bottom: -1rem !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding: 0 auto !important;
        // border: .2rem red solid;
       }
  }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
     @include respond(tab-port){
        width: 30% !important;
      height: 45vh;
    }
    @include respond(phone){
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width: 35% !important;
      height: 35vh;
      margin: -2rem .3rem 2rem .3rem;
      // padding: 2rem 3rem !important;

    }
    @include respond(small-phone){
      width: 50%;
      height: 28vh;
      // display: flex !important;
      // flex-direction: row;
      // flex-wrap: wrap;
    }


    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }
}

//////////////////////////////////////////////////////////////////////////
            // :::::::::::::OLD STYLES BELOW:::::::::
//////////////////////////////////////////////////////////////////////////



 .row {
   max-width: $grid-width;
   background-color: rgba(242, 242, 249, 0.05);
   margin: -5rem auto;
   padding-bottom: -5rem;
   padding-top: -10rem;

  //  &:not(:last-child){
  //     // margin-bottom: $gutter-vertical;
  //     margin-bottom: $gutter-vertical-1;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;

      @include respond(tab-port){
       margin-right: 0;
     }
    }

      @include respond(tab-port){
      width: 100% !important;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-checkout-steps {
   width: 90%;
   background-color: rgba(242, 242, 249, 0.05);
   display: flex;
   margin: -5rem auto;
   padding-bottom: -5rem;
   padding-top: -10rem;

   @include respond(phone){
    width: 100%;
    // padding-left: .5rem !important;
    // padding-right: .5rem !important;
    // border: .2rem red solid;
   }
 }

 .row-home-section-about {
   width: 70%;
  //  background-color: rgba(242, 242, 249, 0.05);
  background-color: rgba($color-tertiary-dark, .041);
   margin: -5rem auto 5rem auto !important;
  //  padding-bottom: 5rem;
  //  padding-top: 10rem;
   padding: 1rem 1rem 1rem 7rem;
   @include respond(phone){
    width:100% !important;
    padding: 2rem 3rem;
   }

  //  &:not(:last-child){
  //     // margin-bottom: $gutter-vertical;
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
      //  margin-right: $gutter-horizontal-2;
       @include respond(tab-port){
        margin-right: 1rem !important;
       }
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
      // border: .1rem red solid;
      padding-top: 4rem;
      padding-left: 5rem;
      // margin-left: .5rem;
     @include respond(tab-port){
      width: 0;
      height: 0;
      // margin:3rem 15rem 3rem -5rem;
      // padding: 5rem 5rem;
      // margin-top: 10rem;
      // margin-right: 15rem;
      // margin-left: 0;
      // padding: 0 15rem 0 .5rem !important;
      // border: .2rem red solid;
     }

    }

   .col-1-of-2-our-mission-text {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     height: 100%;
    //  border: .1rem white solid;
      background-color: rgba($color-white, .7);
     padding:   5rem 4rem 4rem 4rem;
     margin-bottom: -5rem;
     margin-top: 5rem;
     box-shadow: .5rem .5rem .5rem rgba($color-black, .3);

     @include respond(tab-port){
      // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 3 * #{$gutter-horizontal-1}) !important;
      width: 100% !important;

     }

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }


 .row-product-update-form {
   max-width: $grid-width;

  background-color: rgba($color-tertiary-dark, .05);
   margin: -5rem auto 5rem auto;

   padding: 4rem;

   &__list-title {
    margin-right:1rem;
    // display:flex !important;
    // justify-content:center !important;
    // justify-items: center;
    // border: .1rem red solid;
   }


 }

 .row-seller-home-form1 {
   width: 100%;
   height: 45rem;
   display: flex;
   flex-direction: row;
  //  justify-content: center;
  //  justify-items: center;
   color: rgba($color-primary-light, 1);
   background-color: rgba($color-Greenprimary-dark, .15 );
   margin: 2rem auto 2rem auto;
  //  padding-bottom: -8rem;
  //  padding-top: 5rem;

  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2-form1 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     background-color: rgba($color-white, .5);
     min-height: 40rem;
     max-height: 40rem;
     display: flex;
     flex-direction: column;
    //  font-size: .2rem;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     //display: flex;
     //flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 3rem 0 3rem 3rem;
     padding: 5rem 3rem 1rem 3rem;
     border-radius:50%;
     //padding-left:  5rem ;
     //padding-right:  3rem ;
     //border: .2rem red solid;
     //:;
     -webkit-border-radius:50%;
     -moz-border-radius:50%;
     -ms-border-radius:50%;
     -o-border-radius:50%;
}

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 35rem;
     max-height: 35rem;
    margin: 1rem 3rem 5rem -5rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1rem 2rem 5rem 2rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-3 {
   width: 100%;
   height: 45rem;
  //  display: flex;
  //  flex-direction: row;
  //  justify-content: center;
   color: rgba($color-primary-light, 1);
  //  justify-items: center;
   background-color: rgba($color-secondary-dark, .15 );
   box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
   margin: 3rem auto 3rem auto;
  //  padding-bottom: -8rem;
  //  padding-top: 5rem;
  padding: 0 2rem 5rem 2rem;

  // border: .2rem green solid;
  // clip-path: polygon(3% 0, 97% 0, 100% 100%, 0 100%);
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form1 {
     width: calc((100% - #{ $gutter-horizontal-1}
     )/2);
     background-color: rgba($color-white, .5);
     min-height: 40rem;
     max-height: 40rem;
     display: flex;
     flex-direction: column;
    //  font-size: .2rem;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     //display: flex;
     //flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 3rem auto;
     padding: 5rem 3rem 1rem 3rem;
     border-radius:50%;
     //padding-left:  5rem ;
     //padding-right:  3rem ;
    //  border: .2rem red solid;
     //:;
     -webkit-border-radius:50%;
     -moz-border-radius:50%;
     -ms-border-radius:50%;
     -o-border-radius:50%;
}

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
     min-height: 35rem;
     max-height: 35rem;
    margin: 5rem auto;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1rem 2rem 5rem 2rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-create-form-3 {
   width: 100%;
   height: 45rem;
  //  display: flex;
  //  flex-direction: row;
  //  justify-content: center;
  //  justify-items: center;
   background-color: rgba($color-white, .25 );
   box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
   margin: 0 auto 3rem auto;
  //  padding-bottom: -8rem;
  //  padding-top: 5rem;
  padding: 5rem auto;

  // border: .2rem green solid;
  // clip-path: polygon(3% 0, 97% 0, 100% 100%, 0 100%);
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3 !important;
     }
   }


   .col-1-of-2-form1 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
     background-color: rgba($color-white, .5);
     min-height: 40rem;
     max-height: 40rem;
     display: flex;
     flex-direction: column;
    //  font-size: .2rem;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     //display: flex;
     //flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 2rem auto 2rem auto;
     padding: 10rem 5rem 5rem 5rem;
     border-radius:50%;
     //padding-left:  5rem ;
     //padding-right:  3rem ;
    //  border: .2rem red solid;
     //:;
     -webkit-border-radius:50%;
     -moz-border-radius:50%;
     -ms-border-radius:50%;
     -o-border-radius:50%;
}

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
    //  min-height: 40rem;
     max-height: 40rem;
    margin: 5 auto 5rem auto ;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 0 3rem 2rem 3rem;
    //  padding: -2rem -2rem 5rem 5rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form1-2nd-design-with-clip-path {
   width: 85%;
   height: 45rem;
  //  display: flex;
  //  flex-direction: row;
  //  justify-content: center;
   color: rgba($color-primary-light, 1);
  //  justify-items: center;
   background-color: rgba($color-Greenprimary-dark, .15 );
   box-shadow: .2rem .2rem 2rem rgba($color-black, .3);
   margin:  0 auto;
  //  padding-bottom: -8rem;
  //  padding-top: 5rem;

  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-3;
    //  }
   }


   .col-1-of-2-form1-2nd-design-with-clip-path {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
    //  width: 40%;
     background-color: rgba($color-white, .5);
     min-height: 40rem;
     max-height: 40rem;
     display: flex;
     flex-direction: column;
    //  font-size: .2rem;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     //display: flex;
     //flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 3rem 0 2rem -2rem;
     padding: 5rem 3rem 1rem 3rem;
     border-radius:50%;
     //padding-left:  5rem ;
     //padding-right:  3rem ;
     //border: .2rem red solid;
     //:;
     -webkit-border-radius:50%;
     -moz-border-radius:50%;
     -ms-border-radius:50%;
     -o-border-radius:50%;
}

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
     min-height: 35rem;
     max-height: 35rem;
    margin: 1rem auto;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: center;
    //  justify-items: center;
     padding: 1rem 2rem 5rem 2rem;

    //  border: .2rem red solid;
//
    }
   .col-1-of-2-view {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
    width: 44%;
    // height: fit-content;
     min-height: 35rem;
     max-height: 35rem;
    margin: 2rem 0 2rem 1.5rem;

    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: center;
    //  justify-items: center;
      // padding: 1rem -.1rem 5rem 1rem;
      //  background-color: rgba($color-white, .5);
      padding: 1rem 2rem 3rem -.5rem;


    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 1 * #{$gutter-horizontal-1})/2);
     min-height: 35rem;
     max-height: 35rem;
       padding: 1rem 2rem 5rem 2rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form2 {
   width:95%;
   display: flex;

  //  height: fit-content;
   background-color: rgba(242, 242, 249, 0.05);
   margin: -3rem auto -5rem auto;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form2-public {
   width:75vw;
   display: flex;
   border-radius: 50%;
  //  -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //  clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%);
  //  overflow:visible !important;

  //  height: fit-content;
   background-color: rgba(233, 14, 138, 0.4);
   margin: 2rem auto 2rem auto;
  //  z-index: 100;
  //  padding: 3rem;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  width: 40rem;
    min-height: 40rem;
    max-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: 5rem 3rem 1rem 0;
    padding: 3rem 4rem 0 4rem;
    background-color: rgba($color-white, .5);
    //border-radius: 10%;
    //-webkit-border-radius: 10%;
    //-moz-border-radius: 10%;
    //-ms-border-radius: 10%;
    //-o-border-radius: 10%;
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
    border-radius:5% ;
    -webkit-border-radius:5% ;
    -moz-border-radius:5% ;
    -ms-border-radius:5% ;
    -o-border-radius:5% ;
}

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 45rem;
     max-height: 45rem;
     margin: 3rem 2rem;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 3rem 2rem 3rem 1rem;
    //  background-color: rgba($color-tertiary-light, .1);
    //  border-radius: 35%;
     //border: .2rem red solid;
    //  //:;
    //  -webkit-border-radius: 35%;
    //  -moz-border-radius: 35%;
    //  -ms-border-radius: 35%;
    //  -o-border-radius: 35%;
}

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form2-public-with-orange-background {
   width:75vw;
   display: flex;
   border-radius: 50%;
  //  -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //  clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%);
  //  overflow:visible !important;

  //  height: fit-content;
   color: rgba($color-primary-light, 1);
   background-color: rgba(233, 14, 138, 0.1);
   margin: 2rem auto 2rem auto;
  //  z-index: 100;
  //  padding: 3rem;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  width: 40rem;
    min-height: 40rem;
    max-height: 40rem;
       display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: 5rem 3rem 1rem 0;

    border-radius:2% ;
    -webkit-border-radius:2% ;
    -moz-border-radius:2% ;
    -ms-border-radius:2% ;
    -o-border-radius:2% ;
      background-color: rgba($color-white, .7);
    &__background {
    width: 100%;
    height: 100%;
    background-color: rgba($color-Greenprimary-dark, .25);
    padding: 6rem 4rem 0 4rem;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
    border-radius:1% ;
    -webkit-border-radius:1% ;
    -moz-border-radius:1% ;
    -ms-border-radius:1% ;
    -o-border-radius:1% ;
    &--blue {
       background-color: rgba($color-tertiary-dark, .15);
       padding-top: 2rem;
       padding-bottom: 2rem;
    }

    &--orange {
       background-color: rgba($color-secondary-dark, .1);
        padding-top: 2rem;
       padding-bottom: 2rem;
    }

    &--green {
      background-color: rgba($color-Greenprimary-dark, .15 );
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    }
}

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 45rem;
     max-height: 45rem;
     margin: 3rem 2rem;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 3rem 2rem 3rem 1rem;
    //  background-color: rgba($color-tertiary-light, .1);
    //  border-radius: 35%;
     //border: .2rem red solid;
    //  //:;
    //  -webkit-border-radius: 35%;
    //  -moz-border-radius: 35%;
    //  -ms-border-radius: 35%;
    //  -o-border-radius: 35%;
}

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-new-arrivals {
   width:95%;
   display: flex;

  //  height: fit-content;
   background-color: rgba(242, 242, 249, 0.01);
   margin: 3rem auto -5rem auto;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-best-sellers {
   width:95%;
   display: flex;

   height: 35rem;
   background-color: rgba($color-white, 1);
   margin: 3rem auto 1.5rem auto;
   padding: 1rem 2rem;

   @include respond(phone-500){
    width: 95vw;
     display: flex !important;
    flex-wrap:wrap;

    padding: 1rem auto;
    // border: yellow .2rem solid;
    overflow-x: scroll;
    scroll-behavior: smooth;
    // height: 25rem;
  //   &__width-scroll{
  //   display: flex !important;
  //   flex-wrap:nowrap !important;
  //   //  scrollbar-width:auto;
  // //   // flex-wrap:nowrap !important;
  //       // overflow:scroll !important;
  //       width: 100% !important;
  // border: red .5rem solid ;
  //     overflow-x: scroll;
  //       scroll-behavior: smooth;

  //   }
   }
  //  background-color: rgba($color-white, .2);
  // border: .2rem yellow solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;



    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    margin: 2rem auto;

     @include respond(phone){
        width: calc((100% - 2 * #{$gutter-horizontal-1})/2) !important;
        height: 30rem !important;
        margin: 2rem 2rem 2rem 2rem !important;
      }
     @include respond(phone-500){
         width: calc((100vw - 3 * #{$gutter-horizontal-1})/2) !important;
        //  width: 40vw !important;
        height: 30rem !important;
        // margin: .2rem .2rem .2rem .2rem !important;
        margin: 2rem 1rem !important;
        border: .2rem blue solid !important;
      }

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-best-sellers-2 {
   width:98%;
   display: flex;

   height: fit-content;
  //  background-color: rgba($color-white, 1);
   margin: 0 auto 1rem auto;
    padding: 1rem 3rem;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;

    @include respond(phone){
    display: flex;
    flex-wrap: wrap;
    width: 100%;
     height: fit-content !important;
      margin: 0;
   padding: 0rem 2rem;
      // border: .2rem green solid;
  }


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;



    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    margin: 2rem auto;

      @include respond(phone){
        width: calc((100% - 2 * #{$gutter-horizontal-1})/2) !important;
        height: 30rem !important;
        margin: 2rem 2rem 2rem 2rem !important;
      }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-best-sellers-3 {
   width:95%;
   display: flex;
   height: 38rem;
  //  background-color: rgba(#1d497d, .49);
  //  background-color: rgba(105, 11, 11, .05);
   background-color: rgba(34, 44, 157, 0.15);
  //  background-color: rgba(34, 44, 157, 0.15);
   margin: 5rem auto;
   padding: 3rem 3rem;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;
  @include respond(phone){
    display: flex;
    flex-wrap: wrap;
    width: 100%;
     height: fit-content !important;
      margin: 0;
   padding: 3rem 1rem;
      // border: .2rem green solid;
  }


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;



    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    margin: 2rem auto;

    @include respond(phone){
        width: calc((100% - 2 * #{$gutter-horizontal-1})/2) !important;
        height: 30rem !important;
        margin: 2rem 2rem 2rem 2rem !important;
    }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-home-category-3-products {
   width:100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   height: 55vh;
   background-color: rgba($color-white, .4);
   margin: 3rem auto 3rem auto;
   padding: 2rem  2rem 10rem 5rem;
  //  background-color: rgba($color-white, .2);
  // border: .3rem red solid;
  // position: relative;
  @include respond(phone){
     width:100%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
    margin: 1rem auto 1rem auto !important;
    padding: 2rem  5rem 5rem 3rem;
  //  margin: 0 2rem 0 auto;
  //  border: .3rem red solid;
  //  justify-content: space-between;
   overflow-y: scroll;
   height: 55vh;
  }



   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right:$gutter-horizontal-01 !important;
     }

     @include respond(phone){
       &:not(:last-child){
      //  margin-top:$gutter-vertical !important;
       margin-bottom:1vh !important;
     }
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-01})/2);
    //  min-height: 60rem;
    //  max-height: 60rem;
    // margin: 1rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
    //  justify-content: center;
     justify-items: center;
    //  padding:  1rem auto !important;
    // &__flex-column{
    //   width: 100%;
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: flex-end;
    //   height: fit-content;
    //  }

    //  border: .2rem black solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-01})/3);
    // border: 1rem blue solid;
    @include respond(phone){
      width: 100% !important;
       margin-top: 1rem !important;
      // height: 45vh !important;
      // border: 1rem red solid;
    }
    // margin: 0 0 0 0;

    }

    .col-1-of-3-middle-one {
    width: calc((100% - 2 * #{$gutter-horizontal-01})/3);
    // border: 1rem blue solid;
    @include respond(phone){
      width: 100% !important;
      margin-top: 2rem !important;
      // height: fit-content !important;
      // border: 1rem red solid;
    }
    // margin: 0 0 0 0;

    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-01})/3) + #{$gutter-horizontal-01});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    margin: 2rem auto;
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-home-category-3-products-card-2-column {
  width: 100% !important;
  height:100%;
  display: flex;
   border: 2rem rgba($color-white, 1) solid;
   box-shadow: 1rem 1rem 1rem rgba($color-black, .2);

   @include respond(phone){
    height: 40vh;
    // border: .2rem red solid;
   }
  //

 [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-01;
     }
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-01})/2) !important;
     height: 100%;
    //  max-height: 60rem;
    // margin: 1rem;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: center;
    //  justify-items: center;
    //  padding-bottom:  0 ;

      border: 1.5rem rgba($color-black, .085) solid;
      &:not(:last-child){
        border-right: .1rem rgba($color-white, .5) solid;
      }
    &__flex-column{
      width: 100%;
      // display: flex;
      flex-direction: column !important;
      justify-content: center;
      height: 50% ;


      //  border: 1rem rgba($color-black, .5) solid;
        &:not(:last-child){
           border-bottom: .3rem rgba($color-white, .5) solid;

           @include respond(phone)
{
              margin-bottom: 0 !important;
}     }
     }

//
    }



 }

 .row-home-category-2-products-card-2-column {
  width: 100% !important;
  height:80%;
  display: flex;
  margin-top: 3.5rem;
   border: 2rem rgba($color-white, 1) solid;
   box-shadow: 1rem 1rem 1rem rgba($color-black, .2);
   @include respond(phone){
    height: 35vh !important;
      margin-top: 2rem;
   }

 [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-01;
     }
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-01})/2) !important;
     height: 100%;
    //  max-height: 60rem;
    // margin: 1rem;
    //  flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: center;
    //  justify-items: center;
    //  padding-bottom:  0 ;

     border: 1.5rem rgba($color-black, .085) solid;
      &:not(:last-child){
        border-right: .1rem rgba($color-white, .5) solid;
      }
//
    }



 }

 .row-home-products-videos--4-vids {
   width:100% ;
   display: flex;
  color: rgba($color-white, 1);
   background-color: rgba($color-navy-dark, .9);
   margin: 4rem 2rem!important ;
   padding:  3rem 1rem 3rem .1rem !important;
   display:flex;
   flex-direction: row !important;
   flex-wrap: wrap;

   @include clearfix;

   [class^="col-"] {

     float: left;

       &:not(:last-child){
       margin-right:$gutter-horizontal-3 !important;
     }
   }

   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;
    //  border: .3rem red solid;
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin: 0 auto;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }

    .col-1-of-4 {
    width: calc((96% - 3 * #{$gutter-horizontal-2})/4) !important;
    color: rgba($color-white, 1);
    margin: 1.5rem auto!important;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

}

 .row-seller-products-videos--4-vids {
   width:95% ;
  //  display: flex;
   color: rgba($color-white, 1);
   background-color: rgba($color-navy-dark, .9);
   margin: 4rem auto !important ;
   padding:  4rem 10rem 4rem 5rem !important;
   display:flex;
   flex-direction: row !important;
   flex-wrap: wrap;
  //  justify-content: space-around !important;
  //  justify-items: center !important;

   @include clearfix;

   [class^="col-"] {

     float: left;

       &:not(:last-child){
       margin-right:$gutter-horizontal-3 !important;
     }
   }

   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin: 0 auto;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }

    .col-1-of-4 {
    width: calc((96% - 3 * #{$gutter-horizontal-2})/4) !important;
    color: rgba($color-white, 1);
    margin: 5rem auto!important;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

}

 .row-seller-home-podcasts-best-sellers {
   width:100%;
   display: flex;

   height: fit-content;
   background-color: rgba($color-white, .9);
  //  margin: 5rem auto 1rem auto;
   padding: 1rem ;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;

    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    //  border: .2rem red solid;

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-4})/4);
    // width: 55%;
    // height: fit-content;
    margin: 2rem 5rem;
     border: .2rem red solid;
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-best-sellers-pagination {
   width:65%;
   display: flex;
   justify-content: center;

  //  height: fit-content;
   background-color: rgba(242, 242, 249, 0.05);
   margin: 1rem auto 2rem auto;
  //  padding: 5rem auto !important;

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;

     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    display: flex;
    margin: 1rem auto !important;
    padding: 2rem auto !important;
    // border: .05rem white solid;
     justify-content: center;
     justify-items: center;
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-best-sellers-pagination--category-clothing {
    width:65%;
   height: fit-content;
   display: flex;
   justify-content: center;

  //  height: fit-content;
  //  background-color: rgba(242, 242, 249, 0.03);
   margin:1rem auto;
  //  padding: 5rem auto !important;

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;

     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    // height: 7rem;
    // width: fit-content !important;
    display: flex;
    flex-wrap: wrap;
    background-color: rgba($color-white, .03);
    // color: white !important;
    margin: .1rem auto !important;
    padding: 1rem 10rem !important;
    // border: .05rem white solid;
     justify-content: center;
     justify-items: center;
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-home-products-videos-pagination {
   width:65%;
   display: flex;
   justify-content:center;
   justify-items:center;
  // border: .1rem white solid;
  //  height: fit-content;
   background-color: rgba(242, 242, 249, 0.05);
   margin: 1rem auto;
   padding: 2rem;

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;

     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    // border: .1rem white solid;
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }


 .row-seller-home-other-sellers-podcasts-pagination {
   width:65%;
   display: flex;

  //  height: fit-content;
  //  background-color: rgba(242, 242, 249, 0.05);
   margin: 1rem auto -1rem auto;
   padding: 2rem;

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;

     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }


 .row-seller-home-new-arrivals-sellers-pagination {
   width:65%;
   display: flex;

  //  height: fit-content;
  //  background-color: rgba(242, 242, 249, 0.05);
   margin: 3rem auto -5rem auto;
   padding: 3rem;
   background-color: rgba($color-white, .1);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-new-arrivals-sellers-pagination-1 {
   width:65%;
   display: flex;
   justify-content: center;
   justify-items: center;

  //  height: fit-content;
  //  background-color: rgba(242, 242, 249, 0.05);
   margin: -5rem auto -5rem auto;
   padding: 1.5rem  !important;
   background-color: rgba($color-tertiary-light, .08);
  // border: .2rem green solid;
  // position: relative;
  z-index: 3;
  @include respond(tab-land){
    width: 70%;
    padding: .5rem auto !important;
    // border: .1rem red solid;
  }

  @include respond(phone){
    width: fit-content !important;
    height: fit-content !important;
    padding: 2rem 0 2rem 0!important;
    margin: -3rem auto -2rem auto !important;
    font-size: 1.5rem !important;
    // margin-top: 1px !important;
    // border: .1rem red solid;
  }
  @include respond(small-phone){
    width: fit-content !important;
    height: fit-content !important;
    padding: 2rem 0 2rem 0!important;
    font-size: 1.5rem !important;
    // margin-top: 1px !important;
    // border: .1rem red solid;
  }

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2-form2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
     display: flex;
     flex-direction: column;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
    //  display: flex;
    //  flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 5rem 3rem 5rem 1rem;
     padding: 3rem 2rem;
    //  padding-left:  3rem ;
    //  padding-right:  3rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: 60rem;
     max-height: 60rem;
    margin: 5rem 2rem;
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 10rem 2rem 10rem 1rem;

    //  border: .2rem red solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});

    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form3 {
   width:100%;
   display: flex;

   height: fit-content;
   background-color: rgba(242, 242, 249, 0.05);
   margin: -3rem auto -5rem auto;
   padding: 2rem;
   background-color: rgba($color-white, .2);
  // border: .2rem green solid;

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2 !important;
     }
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: fit-content;
     max-height: fit-content;
    margin-top: 5rem ;
    margin-bottom: 5rem;
    background-color: rgba($color-tertiary-light, 0.04);

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 2.5rem ;

    }

   .col-1-of-2-pict {
     width: calc(((100% - #{ $gutter-horizontal-3})/2) + #{$gutter-horizontal-1});
     min-height: fit-content;
     max-height: fit-content;
     margin-top: 5rem ;
     margin-bottom: 5rem;
     background-color: rgba($color-tertiary-light, 0.04);

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1rem 2rem;

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-single-pict-width-100 {
   width:75%;
   display: flex;
   height: fit-content;
   background-color: rgba(242, 242, 249, 0.5);
   margin: 0 auto 0 20rem ;
   padding: 3rem ;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 3rem ;
    margin-bottom: 3rem;
    // margin-right: -2rem;
    background-color: rgba($color-white, 0.2);
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    border-radius: 50%;
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     justify-items: center;
     padding: 1rem 2rem ;
     padding-bottom: 5rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-image {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  object-fit: fill;
     height: 50rem;
    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 3rem ;
    margin-bottom: 3rem;
    // margin-right: -2rem;
    background-color: rgba($color-white, 0.2);
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
     //flex-direction: row;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     justify-items: center;
     padding: 0 5rem ;
     border-radius: 50%;
     //padding-bottom: 5rem;
    //  border: .2rem red solid;
     //:;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
}

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
    //  min-height: fit-content;
    //  max-height: fit-content;
    object-fit: cover;
    //  box-shadow: .1rem .1rem 1rem rgba($color-black, .3);
    //  margin-top: 5rem ;
    //  margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.04);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    // margin: 2rem 5rem 2rem -3rem;
    padding: 0 ;
    // border: .2rem white solid;
    }

    .col-2-of-3 {
    width: calc( (2 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 5rem ;
     margin-left: 5rem;
     background-color: rgba($color-white, .2);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-single-pict-width-100-pink-squarish {
   width:85%;
   display: flex;
   height: 75vh;
   background-color: rgba(196, 243, 204, 0.7);
   margin: 5rem auto 5rem auto ;
   padding: 3rem ;
   box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  min-height: fit-content;
    //  max-height: fit-content;
    // margin-top: 3rem ;
    // margin-bottom: 3rem;
    // margin-right: -2rem;
    margin: 3rem 1rem;
    background-color: rgba($color-white, 0.5);
    box-shadow: .2rem .2rem .3rem rgba($color-black, .3);
    border-radius: 50%;
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     justify-items: center;
     padding:  2rem ;
     padding-bottom: 5rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-image {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  object-fit: fill;
     height: 50rem;
    // margin-top: 3rem ;
    // margin-bottom: 3rem;
    // margin-right: -2rem;
    margin: 3rem 5rem 3rem -.5rem !important;
    background-color: rgba($color-white, 0.4);
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
     //flex-direction: row;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     justify-items: center;
     padding: 0 ;
     border-radius: 50%;
     //padding-bottom: 5rem;
    //  border: .2rem red solid;
     //:;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
}

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
    //  min-height: fit-content;
    //  max-height: fit-content;
    object-fit: cover;
    //  box-shadow: .1rem .1rem 1rem rgba($color-black, .3);
    //  margin-top: 5rem ;
    //  margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.04);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    // margin: 2rem 5rem 2rem -3rem;
    padding: 0 ;
    // border: .2rem white solid;
    }

    .col-2-of-3 {
    width: calc( (2 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 5rem ;
     margin-left: 5rem;
     background-color: rgba($color-white, .2);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-single-pict-pink-and-green-squarish-create-home {
   width:85%;
   display: flex;
   height: 75vh;
   background-color: rgba(185, 243, 239, 0.2);
   margin: 5rem auto 5rem auto ;
   padding: 3rem ;
  //  box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
  //  background-color: rgba($color-white, .2);
  border: .1rem rgba($color-black, .05) solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1!important;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  min-height: fit-content;
    //  max-height: fit-content;
    // margin-top: 3rem ;
    // margin-bottom: 3rem;
    margin: 3rem 1rem;
    // margin-right: -2rem;
    background-color: rgba($color-white, 0.3);
    // box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    border-radius: 50%;
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
    //  justify-content:space-between;
     justify-items: center;
     padding: 2rem ;
     padding-bottom: 15rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-image {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  object-fit: fill;
     height: 50rem;
    // margin-top: 3rem ;
    // margin-bottom: 3rem;
    // margin-right: 12rem;
     margin: 1rem auto 3rem auto !important;
     margin-right: -5rem !important;
    //  border: .1rem red solid;
    // background-color: rgba($color-white, 0.2);
    // box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
     //flex-direction: row;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     justify-items: center;
     padding: 0  ;
    //  border-radius: 50%;
     //padding-bottom: 5rem;
     //border: .2rem red solid;
     //:;
    //  -webkit-border-radius: 50%;
    //  -moz-border-radius: 50%;
    //  -ms-border-radius: 50%;
    //  -o-border-radius: 50%;
}

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
    //  min-height: fit-content;
    //  max-height: fit-content;
    object-fit: cover;
    //  box-shadow: .1rem .1rem 1rem rgba($color-black, .3);
    //  margin-top: 5rem ;
    //  margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.04);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    // margin: 2rem 5rem 2rem -3rem;
    padding: 0 ;
    // border: .2rem white solid;
    }

    .col-2-of-3 {
    width: calc( (2 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 5rem ;
     margin-left: 5rem;
     background-color: rgba($color-white, .2);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-single-pict-green-blue-squarish-create-home {
   width:85%;
   display: flex;
   height: 75vh;
   background-color: rgba(63, 116, 248, 0.1);
   margin: 5rem auto 5rem auto ;
   padding: 3rem ;
   box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1!important;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  min-height: fit-content;
    //  max-height: fit-content;
    // margin-top: 3rem ;
    // margin-bottom: 3rem;
    margin: 3rem 1rem;
    // margin-right: -2rem;
    background-color: rgba($color-white, 0.5);
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    border-radius: 50%;
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
    //  justify-content:space-between;
     justify-items: center;
     padding: 2rem ;
     padding-bottom: 15rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-image {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  object-fit: fill;
     height: 50rem;
    // margin-top: 3rem ;
    // margin-bottom: 3rem;
    // margin-right: 12rem;
     margin: 3rem auto !important;
     margin-right: -5rem !important;
    //  border: .1rem red solid;
    background-color: rgba($color-white, 0.4);
    box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
     //flex-direction: row;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     justify-items: center;
     padding: 0  ;
     border-radius: 50%;
     //padding-bottom: 5rem;
    //  border: .2rem red solid;
     //:;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
}

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
    //  min-height: fit-content;
    //  max-height: fit-content;
    object-fit: cover;
    //  box-shadow: .1rem .1rem 1rem rgba($color-black, .3);
    //  margin-top: 5rem ;
    //  margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.04);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
    //
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    // margin: 2rem 5rem 2rem -3rem;
    padding: 0 ;
    // border: .2rem white solid;
    }

    .col-2-of-3 {
    width: calc( (2 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 5rem ;
     margin-left: 5rem;
     background-color: rgba($color-white, .2);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-product-page-form1-green-blue-squarish {
   width:100%;
   display: flex;
   height: fit-content;
    // background-color: rgba(63, 116, 248, 0.35);
  //  margin: 3rem -5rem 3rem 0 !important;
   padding: .3rem 0 !important;
  //  padding-right: 8rem;
  //  box-shadow: .1rem .1rem .3rem rgba($color-black, .3);
  //  background-color: rgba($color-white, .2);
  border: .2rem green solid;
  // position: relative;



 }

 .row-seller-home-form-single-pict-width-100-green-egg {
   width:85%;
   display: flex;
   height: fit-content;
   background-color: rgba(242, 242, 249, 0.5);
   margin: 0 auto ;
   padding: 4rem 3rem ;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2!important;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 3rem ;
    margin-bottom: 3rem;
    // margin-right: -2rem;
    background-color: rgba($color-white, 0.5);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    border-radius: 50%;
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
    //  justify-content: space-between;
     justify-items: center;
     padding: 1rem 2rem ;
     padding-bottom: 5rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-image {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  object-fit: fill;
     height: 50rem;
    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 3rem ;
    margin-bottom: 3rem;
    // margin-right: -2rem;
    background-color: rgba($color-secondary-dark, 0.05);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
     //flex-direction: row;
     //position: absolute;
     //top: 50%;
     //left: 5%;
     //display: flex;
     //flex-direction: column;
     //justify-content: space-between;
     justify-items: center;
     padding: 0 5rem ;
     border-radius: 1%;
     //padding-bottom: 5rem;
     //border: .2rem red solid;
     //:;
     -webkit-border-radius: 1%;
     -moz-border-radius: 1%;
     -ms-border-radius: 1%;
     -o-border-radius: 1%;
}

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
    //  min-height: fit-content;
    //  max-height: fit-content;
    object-fit: cover;
    //  box-shadow: .1rem .1rem 1rem rgba($color-black, .3);
    //  margin-top: 5rem ;
    //  margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.04);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    // margin: 2rem 5rem 2rem -3rem;
    padding: 0 ;
    // border: .2rem white solid;
    }

    .col-2-of-3 {
    width: calc( (2 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 5rem ;
     margin-left: 5rem;
     background-color: rgba($color-white, .2);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-cover-background-pict {
   width:100%;
   display: flex;
   height: fit-content;
  //  background-color: rgba(242, 242, 249, 0.5);
   margin: 0 auto ;
   padding: 2rem;
  //  background-color: rgba($color-white, .2);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3 !important;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: fit-content;
     max-height: fit-content;
    margin-top: 5rem ;
    margin-bottom: 5rem;
    margin-right: -2rem;
    background-color: rgba($color-tertiary-light, 0.4);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1.5rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
     min-height: fit-content;
     max-height: fit-content;
    //  margin-top: 5rem ;
    //  margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.04);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    }

    .col-2-of-3 {
    width: calc( (2 * ((100% - 2 * #{$gutter-horizontal-3})/3)) + #{$gutter-horizontal-3});
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 5rem 10rem;
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-podcast-width-100 {
   width:100%;
   display: flex;
   border-radius: 1%;
   -webkit-border-radius: 1%;
   -moz-border-radius: 1%;
   -ms-border-radius: 1%;
   -o-border-radius: 1%;
   //height: fit-content;
   //background-color: rgba(242, 242, 249, 0.8);
   //background-color: rgba($color-black, .2);
   //background-color: rgba(#232f3e, .5);
   background-color: rgba($color-navy-dark,.9);
   box-shadow: .2rem .2rem 2rem rgba($color-black, .5);
   margin: 3rem auto ;
   padding:  1rem 3rem;
   //padding-left: 1rem;
   //background-color: rgba($color-tertiary-dark, .05);
   //border: .3rem white solid;
   //position: relative;
      @include clearfix;
      [class^="col-"]
{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 5rem ;
    margin-bottom: 5rem;
    margin-right: -2rem;
    border-radius: 4%;
    background-color: rgba($color-grey-light-1, .9);
    color: rgba($color-tertiary-light, 1);
    text-align: justify;
    box-shadow: .3rem .3rem 1rem rgba($color-black, .1);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1.5rem 3rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-3})/2) ;
     height: 35rem;
    //  min-height: 100%;
    //  max-height: fit-content;
    //  border-radius: 5%;
    padding: 0 0 0 2rem;
    //  margin-top: 3rem ;
    margin: 6rem 0 1rem 0;
    //  margin-bottom: 2rem;
    //  background-color: rgba($color-white, 0.4);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    }

    .col-2-of-3 {
    width: calc( (1 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
    width: 30%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 1rem auto;
     padding: 5rem 10rem;
     background-color: rgba($color-white, .5);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-podcast-width-100-new-design {
   width:100%;
   display: flex;
   border-radius: 1%;
   -webkit-border-radius: 1%;
   -moz-border-radius: 1%;
   -ms-border-radius: 1%;
   -o-border-radius: 1%;
   //height: fit-content;
   //background-color: rgba(242, 242, 249, 0.8);
   //background-color: rgba($color-black, .2);
   //background-color: rgba(#232f3e, .5);
   background-color: rgba($color-navy-dark, .393);
  //  border: .4rem rgba($color-white, .5) solid;
   box-shadow: .2rem .2rem 1rem rgba($color-navy-dark, .9);
  outline: .3rem solid rgba($color-white, .9);
  // outline-offset: 2rem;
  margin: 2rem 1rem 2rem .2rem ;
  padding:  0 2rem 2rem 2rem;
   //padding-left: 1rem;
   //background-color: rgba($color-tertiary-dark, .05);
   //border: .3rem white solid;
   //position: relative;
      @include clearfix;
      [class^="col-"]
{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 6rem ;
    margin-bottom: 4rem;
    margin-right: 5rem;
    margin-left: 5rem;
    border-radius: 2%;
    // border: .5rem rgba($color-navy-dark, .15) solid;
    background-color: rgba($color-black, 0.06);
    color: rgba($color-white, 1);
    font-size: 1.3rem;
     box-shadow: .4rem .3rem .3rem rgba($color-black, .3);
   outline: .14rem solid rgba($color-white, .9);
    outline-offset: 2rem;
    text-align: justify;
     box-shadow: .1rem .2rem .1rem rgba($color-black, .9);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1.5rem 3rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-1})/2) ;
     height: 39rem;
     box-shadow: .3rem .3rem 1rem rgba($color-black, .2);
      // border: .3rem rgba($color-secondary-dark, .15) solid;
    //  max-height: fit-content;
     border-radius: 2%;
    padding: 0 ;
    //  margin-top: 3rem ;
    margin: 6rem 6rem 1rem -1rem;
    //  margin-bottom: 2rem;
     background-color: rgba($color-navy-dark, .09);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    }

    .col-2-of-3 {
    width: calc( (1 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
    width: 30%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 1rem auto;
     padding: 5rem 10rem;
     background-color: rgba($color-white, .5);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-home-form-podcast-width-100-black-design {
   width:100%;
   display: flex;
   border-radius: 1%;
   -webkit-border-radius: 1%;
   -moz-border-radius: 1%;
   -ms-border-radius: 1%;
   -o-border-radius: 1%;
   //height: fit-content;
   //background-color: rgba(242, 242, 249, 0.8);
   //background-color: rgba($color-black, .2);
   //background-color: rgba(#232f3e, .5);
   background-color: rgba($color-tertiary-dark, .4);
  //  border: .4rem rgba($color-white, .5) solid;
   box-shadow: .2rem .2rem 1rem rgba($color-navy-dark, .9);
  outline: .1rem solid rgba($color-white, .9);
  // outline-offset: 2rem;
  margin: 2rem 1rem 2rem .2rem ;
  padding:  0 2rem 2rem 2rem;
   //padding-left: 1rem;
   //background-color: rgba($color-tertiary-dark, .05);
   //border: .3rem white solid;
   //position: relative;
      @include clearfix;
      [class^="col-"]
{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    //  min-height: fit-content;
    //  max-height: fit-content;
    margin-top: 6rem ;
    margin-bottom: 4rem;
    margin-right: 5rem;
    margin-left: 5rem;
    border-radius: 2%;
    // border: .5rem rgba($color-navy-dark, .15) solid;
    background-color: rgba($color-black, 0.6);
    color: rgba($color-white, 1);
    font-size: 1.3rem;
     box-shadow: .4rem .3rem .3rem rgba($color-black, .3);
   outline: .1rem solid rgba($color-white, .9);
    outline-offset: 2rem;
    text-align: justify;
     box-shadow: .1rem .2rem .1rem rgba($color-black, .9);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1.5rem 3rem;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-pict {
     width: calc((100% - #{ $gutter-horizontal-1})/2) ;
     height: 39rem;
     box-shadow: .3rem .3rem 1rem rgba($color-black, .2);
      // border: .3rem rgba($color-secondary-dark, .15) solid;
    //  max-height: fit-content;
     border-radius: 2%;
    padding: 0 ;
    //  margin-top: 3rem ;
    margin: 6rem 6rem 1rem -1rem;
    //  margin-bottom: 2rem;
     background-color: rgba($color-navy-dark, .09);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
    //  padding: 1rem 5rem 1rem 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3-pict {
    width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
    }

    .col-2-of-3 {
    width: calc( (1 * ((100% - 2 * #{$gutter-horizontal-1})/3)) + #{$gutter-horizontal-3});
    width: 30%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     margin: 1rem auto;
     padding: 5rem 10rem;
     background-color: rgba($color-white, .5);
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-create-home-form4 {
   width:90%;
   height: fit-content;
   display: flex;

   height: fit-content;
  //  background-color: rgba(242, 242, 249, 0.5);
   background-color: rgba($color-tertiary-dark, 0.025);
   margin: 0 auto 2rem auto;
   padding:2rem 2rem 0 2rem;
  //  background-color: rgba($color-white, .05);
  // border: .2rem green solid;
  // position: relative;


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2 !important;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: fit-content;
     max-height: fit-content;
    margin-top: 1rem ;
    margin-bottom: 5rem;
    background-color: rgba($color-white, 0.6);

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 2.5rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-pict {
     width: calc(((100% - #{ $gutter-horizontal-3})/2) + #{$gutter-horizontal-1});
     min-height: fit-content;
     max-height: fit-content;
     margin-top: 1rem ;
     margin-bottom: 5rem;
    //  background-color: rgba($color-white, 0.2);

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 0 0;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-create-home-form5 {
   width:100%;
   display: flex;
   height: fit-content;
   background-color: rgba($color-tertiary-dark, 0.03);
   margin: 2em auto 2rem auto;
   padding: 0 3rem 0 3rem;
   border-radius:1%;
   -webkit-border-radius:1%;
   -moz-border-radius:1%;
   -ms-border-radius:1%;
   -o-border-radius:1%;
   //background-color: rgba($color-black, .05);
   //border: .2rem green solid;
   //position: relative;
  @include clearfix;
  [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2 !important;
     }
   }



   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);
     min-height: fit-content;
     max-height: fit-content;
    margin-top: 5rem ;
    // margin-bottom: 5rem;
    background-color: rgba($color-white, 0.4);
    box-shadow: .2rem .2rem .5rem rgba($color-black, .3);

     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 2.5rem ;

    //  border: .2rem red solid;
//
    }

   .col-1-of-2-pict {
     width: calc(((100% - #{ $gutter-horizontal-3})/2) + #{$gutter-horizontal-1});
     height: 35rem;
     min-height: fit-content;
     max-height: fit-content;
     margin-top: 5rem ;
     margin-bottom: 5rem;
    //  background-color: rgba($color-tertiary-light, 0.4);
    // margin-bottom: 5rem;
    //  &:not(:first-child){
    //    margin-right: $gutter-horizontal-1;
    //  }
    //  flex-direction: row;
    //  position: absolute;
    //  top: 50%;
    //  left: 5%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     justify-items: center;
     padding: 1rem 2rem;

    //  border: .2rem rgb(0, 26, 255) solid;
//
    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-analysis {
   display: flex;
   max-width: $grid-width;
   background-color: rgba(242, 242, 249, 0.05);
   margin: 0 auto;
   padding-bottom: 0;

   &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }
 }


//  .row-new-arrivals {
//     max-width: 100vw;
//     // height: 5rem;
//     border: 1rem red solid;
//     background-color: $color-grey-light-1;
//     margin: 0 ;
//     padding: 0;

//     // &:not(:last-child){
//     //     margin-bottom: $gutter-vertical;
//     // }

//     @include clearfix;

//      [class^="col-"] {

//      float: left;


//      &:not(:last-child){
//        margin-right: $gutter-horizontal-2;
//      }
//     }


//      .col-1-of-2 {
//      width: calc((100% - #{ $gutter-horizontal-2})/2);

//     }

//     .col-1-of-3 {
//     width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
//     }

//     .col-2-of-3 {
//     width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
//     }



//     .col-1-of-4 {
//     width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
//     }
//     .col-1-of-5 {
//     width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
//     }

//       .col-2-of-5 {
//       width: calc(2 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 1 * #{$gutter-horizontal-2});

//     }
//       .col-3-of-5 {
//       width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

//     }
//       .col-4-of-5 {
//       width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

//     }

//     .col-2-of-4 {
//     width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
//     }

//     .col-3-of-4 {
//     width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
//     }
//     .col-1-of-6 {
//     width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
//     }

//     .col-2-of-6 {
//     width: calc( 2 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 1 * #{$gutter-horizontal-2});

//     }
//     .col-3-of-6 {
//     width: calc( 3 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 2 * #{$gutter-horizontal-2});

//     }

//     .col-5-of-6 {
//     width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
//     }
//     .col-1-of-7 {
//     width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
//     }

//     .col-6-of-7 {
//     width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
//     }

//   }

 .row-new-arrivals {
    max-width: 100vw;
    height: 50vh;
    background-color: $color-grey-light-1;
    margin: 0 ;
    padding: 0;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
    }

      .col-2-of-5 {
      width: calc(2 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 1 * #{$gutter-horizontal-2});

    }
      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-2-of-6 {
    width: calc( 2 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 1 * #{$gutter-horizontal-2});

    }
    .col-3-of-6 {
    width: calc( 3 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 2 * #{$gutter-horizontal-2});

    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }

  }

 .row-topSeller {
   max-width: 60vw;
   max-height: 50vh;
   background-color: rgba($color-white, .05);
   margin: -15rem auto auto auto;

   &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;




  .col-2-of-3-topSeller {
      max-width: $grid-width;
      // width: calc( 2*((100% - 2 * #{$gutter-horizontal})/3) + #{$gutter-horizontal});
    }


 }


.row-videos {
  // display: block;
  // justify-content: center;
  // justify-items: flex-start;
    max-width: 100vw;
    background-color: rgba($color-tertiary-dark, .15);
    margin: 5rem auto;
    padding:  10rem 3rem;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;

     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 4 * #{$gutter-horizontal-2})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

    .col-1-of-5 {
      width: calc((100% - 5 * #{$gutter-horizontal-2})/5);

    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }

    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }


    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});

    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    // margin-left: 1rem;

    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 5 * #{$gutter-horizontal-2});
    }

  }

.row-single-video-main-vid {

    max-width: 100%;
    background-color: rgba($color-tertiary-dark, .01);
    margin: -13rem 0 5rem 0;
    padding:  15rem 0rem;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-3;

     }
    }

    .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
  }

.row-single-video-page-comment {

    max-width: 100%;
    background-color: rgba($color-tertiary-dark, .01);
    margin: 0 0 0 0;
    padding:  9rem 0 0 0;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-3;

     }
    }

    .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
  }

// seller single product video: the single video component //
.row-seller-single-product-video-col-5-of-6-section {

    max-width: 100%;
    background-color: rgba($color-tertiary-dark, .01);
    margin: 0 0 0 0;
    padding:  9rem 0 0 0;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-3;

     }
    }

    .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
  }

.row- {

    max-width: 100%;
    background-color: rgba($color-tertiary-dark, .01);
    margin: -13rem 0 5rem 0;
    padding:  15rem 0rem;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-3;

     }
    }

    .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
  }

.row-single-video-page {

    width: 99%;
    background-color: rgba($color-white, .2);
    margin: 0 auto;
    padding:  0rem ;
    // box-shadow: 0 .5rem .5rem rgba($color-black, .02);

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;
    }

}

.row-home-seller-carousel-outside {

    width: 100%;
    background-color: transparent;
    margin: 0 auto;
    padding:  0 ;
    box-shadow: 0 1rem 1.5rem rgba($color-black, .09);

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;
    }

}

.row-home-seller-carousel {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba($color-primary-light-01, .3);
    margin: 0 auto 0 auto;
    padding:  0 auto;
    box-shadow: 0 .5rem 1.5rem rgba($color-black, .3);

    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    @include clearfix;

     [class^="col-"] {

     float: left;
    }

}


.row-seller-header-messages-carousel {
  width: 100%;
  height:22rem !important;
  display: flex;
  flex-direction: column;
  text-align: justify;
  box-shadow: 0 .5rem 1.5rem rgba($color-black, .03);

   @include respond(phone){
      width: 95%;
      height: 25rem !important;
      margin-bottom: 1rem;
    }

      &__scroll{
      overflow-y: scroll;
       width: 100%;
      height:22rem;
      // margin-top: -2rem;
       padding:  3rem 2rem !important;
      //  background-color: rgba($color-white, .15);
        margin: -.8rem .0 3rem 0 !important;
        font-size: 1.4rem;
         color: rgba($color-white, 1) !important;
      // justify-content:center;
      background-color: rgba($color-secondary-dark, .15);
// border: .2rem white solid;



    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    // @include clearfix;

    //  [class^="col-"] {

    //  float: left;
    // }

    @include respond(phone){
      overflow-y: scroll;
      width: 95%;
      height: 25rem !important;
      font-size: 3rem;
    }

}

}




.row-single-page-categories-carousel {
    object-fit: fill;
    width:100%;
    //height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    background-color: rgba($color-white, .8);
    position: absolute;
    transition: all .1s;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -ms-transition: all .1s;
    -o-transition: all .1s;
    //margin: 1rem auto !important;
    padding:  0 auto;
    overflow: hidden;
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
    //z-index: 0;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .3);


    //  .thumb{
    //     // min-height: 7rem;
    //     display: flex;
    //     flex-wrap: wrap;
    //     max-height: 5rem !important;
    //     object-fit: fill !important;
    //     margin-top: .2rem;
    //     margin-bottom: -5rem;
    //     // z-index: 10000 !important;
    //   }
        // border: .4rem red solid;


    &__img{
    box-shadow: .1rem .1rem .1rem rgba($color-black, .5);
      // width: 100% !important;
      object-fit: fill !important;
      max-height: 45rem !important;
      min-height: 45rem;
      display: flex !important;
      // flex-direction: column !important;
      justify-content: center !important;
      justify-items: center !important;
      // margin: 1rem auto !important;

    }




    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    // @include clearfix;

    //  [class^="col-"] {

    //  float: left;
    // }

}

.row-seller-home-categories-carousel {
    object-fit: fill;
    width:100%;
    // height: fit-content !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    // background-color: rgba($color-black, .8) !important;
    position: absolute;
    transition: all .1s;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -ms-transition: all .1s;
    -o-transition: all .1s;
    // margin: .1rem auto !important;
    padding:  0 auto !important;
    overflow: hidden;
    // border-radius: 12rem;
    // -webkit-border-radius: 12rem;
    // -moz-border-radius: 12rem;
    // -ms-border-radius: 12rem;
    // -o-border-radius: 12rem;
    //z-index: 0;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
    //.thumb:;
// {
    //     // min-height: 7rem;
    //     display: flex;
    //     flex-wrap: wrap;
    //     max-height: 5rem !important;
    //     object-fit: fill !important;
    //     margin-top: .2rem;
    //     margin-bottom: -5rem;
    //     // z-index: 10000 !important;
    //   }
        // border: .1rem red solid;


    &__img{
    box-shadow: .1rem .1rem .1rem rgba($color-black, .5);
      // width: 100% !important;
      object-fit: fill !important;
      max-height: 44.3vh !important;
      min-height: 44.3vh;
      display: flex !important;
      // flex-direction: column !important;
      justify-content: flex-end !important;
      justify-items: center !important;
      // margin: 1rem auto !important;
      // margin-left: 5rem;

    }




    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    // @include clearfix;

    //  [class^="col-"] {

    //  float: left;
    // }

}

.row-seller-home-categories-select-category-products {
    width:100%;
    height: 68vh;
    // display:ruby-base-container;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    background-color: rgba($color-Greenprimary-dark, .07);
    // background-color: rgba($color-white, 1);
    // margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    // margin-top: 2rem;
    padding:  0 auto;
    border: none;
    @include respond(lab-top){
      width:100%;
      height: 70vh !important;
      // padding-top: 1rem;

    }
    @include respond(tab-land){
      width:100%;
      height: 66vh;
      padding-top: 1rem;
    }
    @include respond(tab-port){
      width:100%;
      height: 50vh !important;
    }
    @include respond(phone){
      width:100%;
      height: 60vh !important;
    }
    @include respond(small-phone){
      width:100% !important;
      height: 100% !important;
    }
    // z-index: 1000;
    // box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
}




.row-home-categories-select-subs-products {
    width:100%;
    height: 45vh;
    display:flex;
    flex-direction: row;
    // flex-wrap: nowrap;
    justify-content: flex-start;
    // justify-items: center;

  background-color: rgba($color-Greenprimary-dark, .2);
    // margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    padding:  0 5rem 0 5rem;
    border: none;
    z-index: 1;
    // box-shadow: .1rem .1rem .1rem rgba($color-black, .1);



    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    // @include clearfix;

    //  [class^="col-"] {

    //  float: left;
    // }

}






 .row-single-product {
      width: 85vw;
      height: fit-content;
      border-radius: 1%;
      border: .2rem rgba($color-white, .2) solid;
      //background-color: rgba($color-tertiary-light, .2);
      background-image: linear-gradient(  to top left,    rgba($color-grey-light-1, .9),    rgba($color-primary-light, .2));
      //padding-top: .1rem;
      transform: skewY(-5deg) ;
//           & > * {
//     transform: skewY(5deg);
// }
      box-shadow: 0 .5rem 5rem rgba($color-black, .6);
      //background-color: rgba(248, 239, 187, 0.5);
      //background-color: #c3f0e291;
        // rgba($color-primary-light, 1),  //           rgba($color-primary-dark, 1)):;
      //background-blend-mode:screen;
      //background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .8), rgba($color-tertiary-dark,.8),  //   url(../image/hero.jpg));
      //background-image:  // linear-gradient(  //   to right,  //   rgba($color-tertiary-light, .5),  //   rgba($color-tertiary-dark, .5)),  //   url(../image/hero.jpg);
  //   margin: 5rem auto 10rem auto;
  // padding: 1rem ;
  //
      margin: 5rem auto;


      &:not(:last-child){
      margin-bottom: $gutter-vertical;
      }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    // margin-left: 1rem;

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-2});
    }


 }

 .row-shop {
    max-width: 100vw;

//     background-image: linear-gradient(99deg, what it was:
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);

  background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);



    // background-color: rgba($color-tertiary-light, .2);


    margin: 0 ;
    padding: 0;
// border: .2rem red solid;
  @include respond(phone){
    width: 100vw;
    height: fit-content !important;
    position: relative !important;
    padding-top: 6rem !important;
    display: flex;
    flex-wrap: wrap;
  }



    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }
    // .col-1-of-5 {
    // width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    // opacity: 1;

    //   @include respond(phone){
    //   width: 0 !important;
    //   height: 0 !important;
    //   opacity: 0;
    //   border: .5rem red solid;
    // }

    // }

    .col-1-of-5-phone {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      // margin-left: 5rem;
    //   width: 0 ;
    //   height: 0;
    // opacity: 0;
      margin-top: 15rem;

      @include respond(phone){
    // width: calc((100% - 5 * #{$gutter-horizontal-3})/2) !important;
    // width: 50% !important;
      width: 50% !important;
      height: fit-content !important;
      margin-top: 5rem ;
      // display: flex;
      // flex-wrap: wrap;
      // background-color: pink;
    // position: relative;
    // // top: 10;
    // left: 5% !important;
      // opacity: 1;
      // z-index: 200;
    }

    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});
      margin-top: 10rem;
      @include respond(phone){
        // position: relative;
        // top: 0;
        width: 100vw !important;
        height: fit-content !important;
        padding-bottom: 5rem;
        margin-top: 5rem;
        margin-bottom: 12rem;
        // display: flex;
        // flex-wrap: wrap;
      // width: calc(4 * ((100% - 5 * #{$gutter-horizontal-3})/2) + 3 * #{$gutter-horizontal-2});
        // width: fit-content !important;
        // z-index: 100;
      }


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }

 .row-product-page {
    max-width: 100vw;
    height: fit-content;
      background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // background-color: rgba($color-tertiary-dark, .15);
      // background-color: rgba(63, 116, 248, 0.35);
    // background-color: rgba($color-secondary-dark, .02);
    margin-top:-5rem ;
    padding: 0 1rem 2rem 2rem!important;




    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-profile-page {
    max-width: 100vw;
    height: fit-content;
    // background-color: rgba(63, 116, 248, 0.3);
    // background-color: rgba(111, 149, 246, 0.3);
    background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

    // margin-top:-5rem ;
    padding: 0 1rem 2rem 2rem!important;
    // border: .2rem red solid;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    min-height: 70rem !important;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    justify-items: flex-start;
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }

    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
     width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
     width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

    .col-1-of-6 {
     width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-admin-user-list-page {
    max-width: 100%;
    height: fit-content;
    // background-color: rgba(63, 116, 248, 0.3);

    background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);


    margin-top:-5rem ;
    padding: 0 1rem 2rem 2rem!important;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-3 !important;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }
    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-3})/5) !important;
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }

    .col-4-of-5 {
      width: calc(4 * ((100% - 4 * #{$gutter-horizontal-3})/5) + 3 * #{$gutter-horizontal-3})!important;

    }

    .col-2-of-4 {
     width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
     width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

    .col-1-of-6 {
     width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-dashboard-profile-page {
    max-width: 100vw;
    height: fit-content;
    // background-color: rgba(63, 116, 248, .3);
    // border: .1rem red solid;
    background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

    // margin-top:-5rem ;
    padding: 1rem 1rem 2rem 1rem!important;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    // @include respond(phone){

    // }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
      // border: .3rem blue solid;
    @include respond(phone){
      width: 0;
      height: 0;
      opacity: 0;

    }
    }
    .col-1-of-4-seller-menu {
    width: 0;
    height: 0;
    opacity: 0;
    @include respond(phone){
      width: 100vw;
      height: fit-content;
      opacity: 1;
      // border: .3rem red solid;

    }
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }

    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
     width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
     width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
     @include respond(phone){
      width: 100%;
     }
    }

    .col-1-of-6 {
     width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-products-videos-page {
    width: 100%;
    height: fit-content;
    // background-color: rgba(63, 116, 248, 0.35);
    background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);


    // border: .2rem red solid;
    // margin-top: -.2rem !important;
    padding: 10rem 1rem 20rem .1rem!important;
    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }
    padding-right: -1rem !important ;

    &__top-section {
      // margin-top: 16rem !important;
      padding: 0 0 2rem 0 !important;
      // margin-bottom: -8rem !important;
      // border: .2rem red solid;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    height: fit-content !important;
    margin-top: 20rem;
    padding-top: 15rem;
    padding-bottom: 8rem;
    background-color: rgba($color-tertiary-dark, .05);
    // border: .1rem red solid;
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-3});
    padding-left: 2rem;
    padding-right: 2rem;
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-customer-pictures-page {
    width: 100%;
    height: fit-content;
    // background-color: rgba(63, 116, 248, 0.35);
    // background-color: rgba(119, 155, 248, 0.35);
     background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // border: .2rem red solid;
    // margin-top: -.2rem !important;
    // padding: 3rem 1rem 2rem .1rem!important;
    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }
    padding-right: -1rem !important ;

    &__top-section {
      margin-top: -.6rem !important;
      padding: 0 0 2rem 0 !important;
      // margin-bottom: -8rem !important;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-3});
    padding-left: 2rem;
    padding-right: 2rem;
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-videos-products-section-background {
    width: 100%;
    height: fit-content;
    background-color: rgba(63, 116, 248, 0.35);
    // border: .2rem red solid;
    // margin-top: -.2rem !important;
    padding: 7rem 1rem 10rem .1rem!important;
    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }
    // padding-right: -1rem !important ;
    //  margin-top: -1rem !important;
    // &__top-section {
    //   // margin-top: -.6rem !important;
    //   padding: 0 0 2rem 0 !important;
    //   // margin-bottom: -8rem !important;
    // }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-3});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-admin-seller-order-list-not-dashboard {
    width: 100%;
    height: fit-content;
    background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // border: .2rem red solid;
    // margin-top: 15rem !important;
    padding: 3rem 0!important;
    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    &__top-section {
      margin-top: .6rem !important;
      padding: 0 0 2rem 0 !important;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 4 * .3rem)/5);
    // width: calc((100% )/4);
    margin-right: .1rem !important;
    padding-right: 2rem !important;
    padding-left: .1rem;
    // border: .1rem red solid;
    // font-size: 1rem !important;
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-3})/5) + 3 * #{$gutter-horizontal-3});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + #{$gutter-horizontal-3});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-public-podcasts-page {
    width: 100%;
    height: fit-content;
    background-color: rgba(63, 116, 248, 0.09);
    // border: .2rem red solid;
    margin-top: -5rem !important;
    padding: 5rem 0 2rem 0!important;
    // &:not(:last-child){
    //     margin-bottom: $gutter-vertical;
    // }

    &__top-section {
      margin-top:-.5rem!important;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + #{$gutter-horizontal-3});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-podcasts-edit-delete-page {
    max-width: 100%;
    height: fit-content;
    // background-color: rgba(63, 116, 248, 0.35);
    background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // margin-top:-5rem ;
    padding: 0 1rem!important;
    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 4 * #{$gutter-horizontal-3})/5) + 3 * #{$gutter-horizontal-3});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + #{$gutter-horizontal-3});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

.row-seller-podcasts-page {
    max-width: 100vw;
    height: fit-content;
    background-color: rgba(63, 116, 248, 0.35);
    margin-top:-5rem ;
    padding: 0 0 2rem 2rem!important;
    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
    }

     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-3})/5) + 3 * #{$gutter-horizontal-3});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + #{$gutter-horizontal-3});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
}

////

 .row-seller-single-podcast-background {
    max-width: 100vw;
    height: fit-content;
//     background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);
    // background-color: rgba($color-tertiary-dark, .15);
      background-color: rgba(63, 116, 248, 0.35);
    // background-color: rgba($color-secondary-dark, .2);
    margin-top:-5rem ;
    padding: 0 0 2rem 2rem!important;




    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + #{$gutter-horizontal-3});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }
// seller public single product video: only for this page //
 .row-seller-single-product-video-background-page {
    max-width: 100%;
    height: fit-content;
    // background-color: rgba(63, 116, 248, 0.35);
        background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // margin-top:-5rem ;
    // padding-top: 5rem;
    // padding: .5rem;
  //  padding-right: -2rem !important ;
  // border: 1rem red solid;




    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    background-color: rgba($color-black, .02);
    padding-left: 1rem;
    padding-right: 1rem;
     margin-right: 0 !important;
    // border: .1rem red solid;
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-0})/4);
     padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgba($color-white, .2);
    //  border: .1rem red solid;

    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-0})/4) + #{$gutter-horizontal-0});
    padding-left: 2rem;
    padding-right: 2rem;
    //  border: .1rem red solid;

    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }

 .row-seller-single-video-seller-products-videos-page {
    max-width: 100%;
    height: fit-content;
//     background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);
    // background-color: rgba($color-tertiary-dark, .15);
      // background-color: rgba(63, 116, 248, 0.35);
          background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // background-color: rgba($color-secondary-dark, .2);
    margin-top:-5rem ;
    padding: 5rem 0 2rem 0 !important;




    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }

 .row-seller-single-video-related-videos-from-others {
    max-width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
//     background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);
    // background-color: rgba($color-tertiary-dark, .15);
      // background-color: rgba(63, 116, 248, 0.35);
          background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
    // background-color: rgba($color-secondary-dark, .2);
    margin-top:-5rem ;
    padding: 3rem 1rem 2rem 2rem!important;




    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    background-color: rgba($color-black, .02);
    }
    .col-1-of-4-product-info {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    background-color: rgba($color-white, .2);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }

 .row-seller-products-public-page {
    max-width: 100%;
    height: fit-content;

      // background-color: rgba(63, 116, 248, 0.35);
      // background-color: rgba(black, .1);
      background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

    margin-top:5rem ;
    // padding: 0 .1rem 2rem .2rem!important;

// border: .1rem red solid ;


    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-01;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    height: 70% !important;
    // margin: 40rem auto;
    padding:  7rem 1rem 7rem 0 !important;
    background-color: rgba($color-tertiary-dark, .03);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    margin-top: 30rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-01});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-01})/4);
     height: 70% !important;
    // margin: 40rem auto;
    // padding:  7rem 1rem 7rem 0 !important;
    // background-color: rgba($color-tertiary-dark, .03);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    margin-top: 30rem;
    // border: .1rem red solid;
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-01})/5) + 3 * #{$gutter-horizontal-01});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }
 .row-seller-single-product-page {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;

      // background-color: rgba(63, 116, 248, 0.35);
      // background-color: rgba(black, .1);
      background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

    margin-top:5rem ;
    // padding: 0 .1rem 2rem .2rem!important;




    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-2;
    //  }
    }

    // @include respond(phone){
    //   display: flex;
    //   flex-direction: row;
    //   flex-wrap: wrap !important;
    //   border: 1rem lighgre;
    // }
    //  .col-1-of-2 {
    //  width: calc((100% - #{ $gutter-horizontal-2})/2);

    // }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    height: fit-content !important;
    // margin: 40rem auto;
    // padding:  7rem 1rem 7rem 0 !important;
    background-color: rgba($color-tertiary-dark, .03);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    margin-top: 3rem;
    margin-bottom: 2rem;
    // border: .2rem red solid;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-01});

      @include respond(phone){
        width: 95%;
      }
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
     height: 70% !important;
    // margin: 40rem auto;
    padding:  7rem auto 7rem auto !important;
    background-color: rgba($color-tertiary-dark, .03);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    margin-top: 30rem;
    // border: .1rem red solid;
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
         height:fit-content !important;
    // margin: 40rem auto;
    padding:15rem auto !important;
    background-color: rgba($color-tertiary-dark, .03);
    // box-shadow: .1rem .1rem .1rem rgba($color-black, .1);
    margin-top: 25rem;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    // margin-right: 3rem !important;
    @include respond(phone){
      width: 100vw !important;
      margin-top: 5rem;
      // border: .2rem red solid;
    }
    }

      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});
      @include respond(phone){
        width: 100%;
      }

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-5})/4) + 2 * #{$gutter-horizontal-5});
    margin-right: 1rem;
    }
    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
  }

  .row-shop--col-4-of-5 {
    width: 100%;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    height: fit-content;
    //background-image: linear-gradient(125deg,    //  rgba(67, 108, 197, .8) 0%,    //   rgba(144, 173, 233, 0.8) 30%,    //   rgba(251, 253, 255, .8) 51%,    //   rgba(67, 108, 197, .8)  90%);
    // background-color: rgba($color-white, .7);
      // background-color: rgba($color-white, .2 );
    //box-shadow: 0 .5rem 1rem rgba($color-tertiary-dark, .1);
    //background-image: linear-gradient(99deg,  rgba(88, 91, 245, 0.4) 0%,  rgba(178, 179, 243, 0.3) 15%,  rgba(67, 70, 241, .3) 50%,  rgba(67, 70, 241, .3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%,  rgba(67, 70, 241, .4) 100%);
    margin: 5rem auto;
    // border: 1rem yellow solid;
    //margin-left: 0rem;
    //padding-right: auto;
    //padding-top: 15rem;
    //padding-bottom: 4rem;
    padding: 10rem 0 10rem 1rem;
    padding-left: 3rem;
    //box-shadow: 0 .5rem 3rem rgba($color-tertiary-dark, 1);
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-5;
     }
    //  &:first-child {
    //    margin-left: $gutter-horizontal-5;
    //  }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    // margin-bottom: -1rem;
    // padding: 5rem 1rem 5rem 1rem;
    // background-color: rgba($color-secondary-dark, 0.5);
    // box-shadow: .1rem .1rem .1rem rgba($color-black, .3);
    // margin: 5rem auto !important;
    height: fit-content !important;
    margin-bottom: 3rem;
    // margin-left: .5rem;
    // margin: auto;
    @include respond(phone){
    width: calc((100% - 3 * #{$gutter-horizontal-2})/2) !important;
    height: fit-content !important;

    }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }


  .row-seller-related-products {
     width: 100%;
     height: 95vh;
     border-radius: 1%;
     -webkit-border-radius: 1%;
     -moz-border-radius: 1%;
     -ms-border-radius: 1%;
     -o-border-radius: 1%;
     display: flex;
     flex-wrap: wrap;
     justify-content:flex-start;
    //  justify-items: center !important;
     align-items: center !important;
    //  background-color: rgba(#bbb3fd, .15);
     background-color: rgba($color-white, .7);
     box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
     margin: 5rem auto 10rem auto;
     padding: 4rem 1rem 2rem 2rem !important;
     overflow: hidden;
     //padding-bottom: 10rem;
     //padding-left: 2rem;
     -ms-overflow-style: none !important;
     ::-webkit-scrollbar{
      width: 0 !important;  /* Remove scrollbar space */
      background: transparent !important;  /* Optional: just make scrollbar invisible */
      display: none !important;
      scrollbar-width: none !important;
    }
     overflow-y: scroll;
    //  scrollbar-width: 17rem !important;
     -webkit-backface-visibility: hidden !important;
     backface-visibility: hidden !important;
    //  scrollbar-width: 7rem !important;
    //padding-right: 5rem;
    //padding: 15rem auto 10rem auto !important;
    //padding-left: 3rem;
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }

    @include respond(phone){
      height: 55vh;
    }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((95% - 3 * #{$gutter-horizontal-2})/4) !important;
    height:fit-content !important;
    // margin-bottom: 15rem;
    margin: 1rem 1.3rem 1rem 1.3rem !important;
    // border: 1rem red solid;

    padding: 1rem .1rem 1rem .1rem;
    background-color: white;

      @include respond(phone){
        width: 95% !important;
        height: 35vh !important;
        margin-bottom: 3rem !important;
      }

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

  .row-seller-single-product-display {
    width: fit-content;
    height: fit-content !important;
    border-radius: .5%;
    -webkit-border-radius: .5%;
    -moz-border-radius: .5%;
    -ms-border-radius: .5%;
    -o-border-radius: .5%;
    display: flex;
    flex-wrap: wrap;
    //justify-content:flex-start;
    //justify-items: flex-start;
    //background-color: rgba($color-Greenprimary-dark, .15);
    // background-color: rgba($color-tertiary-dark, 0.02);
    //background-color: rgba(#304ffe, .2);
    //background-color: rgba($color-white, .02);
    // border: .1rem rgba($color-black, .1) solid;
    //box-shadow: .1rem .1rem .1rem rgba($color-black, .5);
    margin: 5rem auto 10rem 0;
    padding: 5rem auto 5rem auto !important;
    //padding-bottom: 10rem;
    //padding-left: 8rem;
    //padding-right: 5rem;
    //padding: 15rem auto 10rem auto !important;
    //padding-left: 3rem;
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((90% - 3 * #{$gutter-horizontal-3})/4);
    height:fit-content !important;
    // margin-bottom: 15rem;
    margin: 1rem .1rem 10rem .1rem;

    padding: 5rem .1rem 13rem .1rem;
    @include respond(phone){
      width: 100%;
    }

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    @include respond(phone){
      width: 100%;
    }
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

  .row-seller-single-video-related-products {
    width: 95%;
    // height: fit-content !important;
    border-radius: .5%;
     -webkit-border-radius: .5%;
    -moz-border-radius: .5%;
    -ms-border-radius: .5%;
    -o-border-radius: .5%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;

    background-color: rgba($color-white, .3);
    margin: 5rem auto 10rem auto;
    padding: 5rem;
     padding-bottom: 5rem;
     padding-left: 5rem;
     padding-right: 5rem;
    // padding: 15rem auto 10rem auto !important;
    // padding-left: 3rem;
  //   &:not(:last-child){
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-1;
    //  }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    height:fit-content !important;
    background-color: rgba($color-tertiary-light, .04);
    // margin-bottom: 15rem;
    margin: 1rem 0;

    padding: 2rem;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }


  .row-seller-new-products-public {
    width: 90%;
    height: fit-content !important;
    border-radius: .5%;
     -webkit-border-radius: .5%;
    -moz-border-radius: .5%;
    -ms-border-radius: .5%;
    -o-border-radius: .5%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;

    background-color: rgba($color-white, .4);
    margin: 2rem auto 5rem auto;
    // padding: 5rem;
     padding-bottom: 10rem;
     padding-left: 2rem;
     padding-right: 2rem;
    // padding: 15rem auto 10rem auto !important;
    // padding-left: 3rem;
  //   &:not(:last-child){
  //     margin-bottom: $gutter-vertical;
  //  }





   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-1;
    //  }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    height:100% !important;
    // margin-bottom: 15rem;
    margin: 5rem auto 5rem auto !important;

    padding: 1rem .1rem 22rem .1rem !important;
    // border: .2rem red solid;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

  .row-seller-public-sub-category-products {
    width: 95%;
    height: fit-content !important;
    border-radius: .5%;
     -webkit-border-radius: .5%;
    -moz-border-radius: .5%;
    -ms-border-radius: .5%;
    -o-border-radius: .5%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;

    background-color: rgba($color-white, .5);
    margin: 5rem auto 5rem auto;
    // padding: 5rem;
     padding-bottom: 10rem;
     padding-left: 0;
     padding-right: 0;
    // padding: 15rem auto 10rem auto !important;
    // padding-left: 3rem;
  //   &:not(:last-child){
  //     margin-bottom: $gutter-vertical;
  //  }

   &__heigth-scroll{
    overflow-y: scroll;
    width: 92vw;
    max-height: 65vh;
    box-shadow: 2rem 2rem 2rem rgba($color-black, .2);
    margin:2rem auto;
    border: .5rem solid rgba($color-white, .3);
    // padding-top: 5rem;
    // padding-bottom: 5rem;
   }


   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    height:100% !important;
    // margin-bottom: 15rem;
    margin: 5rem auto 5rem auto !important;

    padding: 1rem .1rem 22rem .1rem !important;
    // border: .2rem red solid;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }


 .row-videos-col-4-of-5 {
    width: 100%;
    border-radius: .5%;
     -webkit-border-radius: .5%;
    -moz-border-radius: .5%;
    -ms-border-radius: .5%;
    -o-border-radius: .5%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    height: fit-content;
    // background-image: linear-gradient(125deg,
    //  rgba(67, 108, 197, .8) 0%,
    //   rgba(144, 173, 233, 0.8) 30%,
    //   rgba(251, 253, 255, .8) 51%,
    //   rgba(67, 108, 197, .8)  90%);
    background-color: rgba($color-white, .3);
    //box-shadow: 0 .5rem 1rem rgba($color-tertiary-dark, .1);
    // background-image: linear-gradient(99deg,  rgba(88, 91, 245, 0.4) 0%,  rgba(178, 179, 243, 0.3) 15%,  rgba(67, 70, 241, .3) 50%,  rgba(67, 70, 241, .3) 51%, rgba(178, 179, 243, 0.3) 85%, rgba(88, 91, 245, 0.4) 100%,  rgba(67, 70, 241, .4) 100%);
    margin: 10rem auto;
    //border: .1rem red solid;
    //margin-left: 0rem;
    // padding-right: auto;
    // padding-top: 15rem;
    // padding-bottom: 4rem;
    padding: 10rem 0 10rem 1rem;
    padding-left: 3rem;
    // box-shadow: 0 .5rem 3rem rgba($color-tertiary-dark, 1);
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-5;
     }
    //  &:first-child {
    //    margin-left: $gutter-horizontal-5;
    //  }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    margin-bottom: 2rem;
    // margin: 5rem auto !important;
    height: fit-content;
    // margin-left: .1rem;
    // margin: auto;
      @include respond(phone){
        width: calc((100% - 3 * #{$gutter-horizontal-2})/2) !important;
        height: fit-content !important;

        }


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }


 .row-2 {
    width: 85vw;
    border-radius: 1%;
    // height: 95vh;
    background-color: rgba($color-black, .1);
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    //  background-image: linear-gradient(125deg,
    // rgba(95, 148, 253, .1) 0%,
    // rgba(250, 250, 250, .9) 50%,
    // rgba(253, 253, 253, .1) 51%,
    // rgba(101, 152, 253, .1)  90%);
    margin: 5rem auto;
    // margin-top: 5rem;
    padding-left: 4rem;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-0});

    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-0});

    }

 }

 .row-reviews-pic {
    width: 100%;
    border-radius: 1%;
    height: 35%;
    //  border: green .2rem solid;

    // margin: 5rem;
    margin-bottom: 1rem;
    // padding: 2rem;
    margin-top: 2rem;
    object-fit: fill !important;
    // padding-left: 4rem;
    // padding-right: 0;
    // padding-top: -5rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
  //  @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
//    [class^="col-"] {

//      float: left;

//      &:not(:last-child){
//        margin-right: $gutter-horizontal-0;
//      }
//    }


//    .col-1-of-2 {
//      width: calc((100% - #{ $gutter-horizontal-1})/2);

//     }

//     .col-1-of-3 {
//     width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
//     }

//     .col-2-of-3 {
//     width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
//     }



//     .col-1-of-4 {
//     width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
//     }

//     .col-2-of-4 {
//     width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
//     }

//     .col-3-of-4 {
//     width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
//     }

//     .col-3-of-7 {
//       width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-0});

//     }
//     .col-4-of-7 {
//     width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-0});

//     }

 }

 .row-reviews-modal-pic {
    width: 100% !important;
    border-radius: 1%;
    height: 35%;
    overflow: scroll;
    //  border: green 1rem solid !important;
    // background-color: rgba($color-black, .1);
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    //  background-image: linear-gradient(125deg,
    // rgba(95, 148, 253, .1) 0%,
    // rgba(250, 250, 250, .9) 50%,
    // rgba(253, 253, 253, .1) 51%,
    // rgba(101, 152, 253, .1)  90%);
    // margin: 5rem;
    // margin-bottom: 2rem;
    // padding: 2rem;
    // margin-top: 5rem;
    // padding-left: 4rem;
    // padding-right: 0;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-0});

    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-0});

    }

 }

  .row-single-product-modal-small-sample {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-around !important;
    justify-items: center;
    width: 20%;
    border-radius: 1%;
    height: 14vh;
    // border: .1rem red solid;
}

 .row-single-product-modal-pic {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    border-radius: 5%;
    height: 13rem !important;
    object-fit:contain !important;
    //border: green 1rem solid;
    //background-color: rgba($color-black, .1);
    //background-image: linear-gradient(125deg,    // rgba($color-white, 1) 0%,    // rgba($color-white, .45) 50%,    // rgba(163, 187, 236, 0.842) 51%,    // rgba(67, 108, 197, .8)  90%);
    //background-image: linear-gradient(125deg,    // rgba(95, 148, 253, .1) 0%,    // rgba(250, 250, 250, .9) 50%,    // rgba(253, 253, 253, .1) 51%,    // rgba(101, 152, 253, .1)  90%);
    //margin: 5rem;
    //margin-bottom: 2rem;
    padding: 0;
    margin-top: 1rem;
    //padding-left: 4rem;
    //padding-right: 0;
    //padding-top: 2rem;
    padding-bottom: 0 !important;
    //box-shadow: 0 .5rem 3rem rgba($color-black, .1);


    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;
    &:not(:last-child){
      margin-bottom: -5rem;
   }
  //  @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
  //  [class^="col-"] {

  //    float: left;

  //    &:not(:last-child){
  //      margin-right: $gutter-horizontal-0;
  //    }
  //  }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-0});

    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-0});

    }

 }


.row-login {
  max-width: $grid-width;
  background-color: rgba(242, 242, 249, 0.05);
  //transform: skewY(7deg);
  // background-image: linear-gradient(
  //   to right bottom,
  //   rgba(128, 185, 250, 0.308),
  //   rgba(189, 250, 227, 0.411));
  margin: 0 auto; //making margin: 0 auto, auto left & right centers row block in the middle of the container block//
  &:not(:last-child){   // :not(:last-child) will select all the element inside the row except the last one//
    margin-bottom: $gutter-vertical;
  }

  @include clearfix;       // .row::after//

  // attribute:
  // we selecting classes start ^ with "col-" //
  // we selecting classes end $ with "col-" //
  // we selecting classes include * with "col-" //
  [class^='col-'] {
    float: left;
    &:not(:last-child) {
      margin-right: $gutter-horizontal;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal})/2);
  }

  .col-1-of-3 {
    width: calc((100% - 2* #{$gutter-horizontal})/3);
  }

  .col-2-of-3 {
    width: calc( 2 * ((100% - 2 * #{$gutter-horizontal})/3) + #{$gutter-horizontal});
  }
  .col-1-of-4 {
    width: calc((100% - 3* #{$gutter-horizontal})/4);
  }

  .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal})/4) + #{$gutter-horizontal});
  }
  .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal})/4) + 2 * (#{$gutter-horizontal}));
  }
}

.row-3 {
    width: 95vw;
    border-radius: 1%;
    // height: 55vh;
    background-color: rgba(247, 252, 250, 0.65);
    margin: 5rem auto;
    // margin-top: 5rem;
    padding-left: 5rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .5);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

     .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-1})/5);
    }

     .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});

    }
 }

.row-new-arrivals-home {
    width: 95vw;
    border-radius: 1%;
    display: flex;
    background-color: rgba($color-white, .5);
    margin: 5rem auto;
    // margin-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .5);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

     .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-1})/5);
    }

     .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
 }


.row-new-arrivals-seller-home {
    width: 95%;
    // height: 40rem;
    //  border: .1rem yellow solid;
    border-radius: 1%;
    // border-bottom: 1rem rgba($color-white, 1) !important;
    display: flex;
    // flex-wrap: wrap;
    background-color: rgba($color-white, .1);
    margin: 5rem auto;
    padding: 5rem 2rem;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // padding-top: 2rem;
    // padding-bottom: 5rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-3;
    //   //  margin-right: $gutter-horizontal-1;
    //  }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    // .col-1-of-4 {
    // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    // border: .1rem yellow solid;
    // height: 50%;
    // margin-bottom: 4rem;
    // background-color: wheat;
    // &:not(:last-child){
    //    margin-right: 5rem !important;
    //   //  margin-right: $gutter-horizontal-1;
    //  }

    // }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

     .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-1})/5);
    }

     .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
 }



.row-podcasts-new-arrivals {
    width: 95vw;
    border-radius: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgba($color-tertiary-light, .07);
    margin: 5rem auto;
    // margin-top: 5rem;
    // padding: 3rem auto ;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
    // box-shadow: 0 .5rem 3rem rgba($color-black, .15);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);

    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
     &:not(:first-child){
       margin-bottom: 3rem;;
     }
     &:not(:first-child){
       margin-left: .5rem;;
     }


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

     .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-1})/5);
    }

     .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
 }

.row-seller-new-product-list {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    background-color: rgba($color-white, .0);
    margin: 5rem auto;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // padding: 2rem 0!important;
    // box-shadow: 0 .5rem 3rem rgba($color-black, .5);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4) ;
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

     .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-1})/5);
    }

     .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
 }

 .row-single-product-info {
      width: 35vw;
      height: fit-content;
      // margin-top: -15rem;
      padding: 3rem;
      border-radius: 1%;
      border: .3rem rgba($color-white, .8) solid;
      //background-color: rgba($color-tertiary-light, .2);
      // background-image: linear-gradient(  to  left ,    rgba($color-primary-light, .5),    rgba($color-primary-light-3, .5));
      background-image: linear-gradient(  to  left ,    rgba($color-tertiary-dark, .05),    rgba($color-tertiary-dark, .01));
      //padding-top: .1rem;
      // transform: skewY(5deg) ;
//           & > * {
//     transform: skewY(5deg);
// }
      box-shadow: 0 .5rem 2rem rgba($color-black, 1);
      //background-color: rgba(248, 239, 187, 0.5);
      //background-color: #c3f0e291;
        // rgba($color-primary-light, 1),  //           rgba($color-primary-dark, 1)):;
      //background-blend-mode:screen;
      //background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .8), rgba($color-tertiary-dark,.8),  //   url(../image/hero.jpg));
      //background-image:  // linear-gradient(  //   to right,  //   rgba($color-tertiary-light, .5),  //   rgba($color-tertiary-dark, .5)),  //   url(../image/hero.jpg);
  //   margin: 5rem auto 10rem auto;
  // padding: 1rem ;
  //
      margin: 1rem ;


      &:not(:last-child){
      margin-bottom: $gutter-vertical;
      }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    // margin-left: 1rem;

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-2});
    }


 }

.row-admin-dashboard {
    width: 85vw;
    border-radius: 1%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

     background-image: linear-gradient(125deg,
    rgb(95, 148, 253) 0%,
    rgb(250, 250, 250) 50%,
    rgb(253, 253, 253) 51%,
    rgb(101, 152, 253)  90%);
    margin: 5rem auto;
    // margin-top: 5rem;
    padding-left: 4rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .5);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }


.row-seller-orders {
    width: 100%;
    border-radius: .5%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

  background-image: linear-gradient(110deg,
  rgba(69, 81, 248,  1) 0%,
  rgba(69, 81, 248, .4) 8%,
  rgba(67, 70, 241, .01) 15%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba(67, 70, 241, .4) 40%,
  rgba($color-white, 1) 51%,
  rgba($color-white, 1) 53%,
  rgba($color-white, 1) 57%,
  rgba($color-white, .8) 65%,


  rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 0 auto 3rem auto;
    // margin-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }


.row-background-page {
    width: 100%;
    height:100% !important;
    border-radius: .5%;

// background-color: rgba($color-white, .23);

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);

background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);


  //  background-color: rgba(63, 116, 248, 0.2);
    margin-top:5rem ;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: -15rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .3);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-3})/5);
    }

    .col-4-of-5 {
    width: calc((4 * ((100% - 4 * #{$gutter-horizontal-3})/5)) + #{$gutter-horizontal-3});
    }


    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});

    }

    .col-sub-category {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 1rem 8rem 2.5rem;
    padding: 5rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-white, .3) ,  rgba($color-white, .4) );
    }

    .col-single-video-related {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin: 3rem 8rem;
    padding: 5rem 0rem 5rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );


      .col-1-of-5 {
        width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      }
    }

    .col-single-video-related-vid {
    // width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    width: 100%;
    display: flex;
    justify-content: center;
    margin:  2rem auto 5rem 0 !important;
    padding: 1rem 0  ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );

      &__sub-title {
        width: 50%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // justify-items: center;
        margin: 0 auto;
      }
    }





    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 43rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6)  ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-3});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7-sellerVideos {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7) ;
    }
    .col-6-of-7-sellerVideos {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
    }

 }

.row-login-background-page {
    width: 100%;
    height:fit-content !important;
    border-radius: .5%;

// background-color: rgba($color-white, .23);

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);


   background-color: rgba(63, 116, 248, 0.2);
    margin-top:5rem ;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: -15rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .3);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;


   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-3})/5);
    }

    .col-4-of-5 {
    width: calc((4 * ((100% - 4 * #{$gutter-horizontal-3})/5)) + #{$gutter-horizontal-3});
    }


    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});

    }

    .col-sub-category {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 1rem 8rem 2.5rem;
    padding: 5rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-white, .3) ,  rgba($color-white, .4) );
    }

    .col-single-video-related {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin: 3rem 8rem;
    padding: 5rem 0rem 5rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );


      .col-1-of-5 {
        width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      }
    }

    .col-single-video-related-vid {
    // width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    width: 100%;
    display: flex;
    justify-content: center;
    margin:  2rem auto 5rem 0 !important;
    padding: 1rem 0  ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );

      &__sub-title {
        width: 50%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // justify-items: center;
        margin: 0 auto;
      }
    }





    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 43rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6)  ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-3});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7-sellerVideos {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7) ;
    }
    .col-6-of-7-sellerVideos {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
    }

 }



.row-background-admin-products-page {
    width: 100%;
    border-radius: .5%;

 background-color: rgba(63, 116, 248, 0.35);

    margin: 2rem auto 3rem auto;
    padding: 2rem 2rem 2rem 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .3);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-2})/5);
    }

    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-sub-category {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 1rem 8rem 2.5rem;
    padding: 5rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-white, .3) ,  rgba($color-white, .4) );
    }

    .col-single-video-related {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin: 3rem 8rem;
    padding: 5rem 0rem 5rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );


      .col-1-of-5 {
        width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      }
    }

    .col-single-video-related-vid {
    // width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    width: 100%;
    display: flex;
    justify-content: center;
    margin:  8rem auto 0 0;
    padding: 1rem 0  ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );

      &__sub-title {
        width: 50%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // justify-items: center;
        margin: 0 auto;
      }
    }





    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 43rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6)  ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7-sellerVideos {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7) ;
    }
    .col-6-of-7-sellerVideos {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
    }

 }

.row-background-page-press-release-public {
    width: 100%;
    border-radius: .5%;

// background-color: rgba($color-green-yellow, .23);

  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
 rgba(178, 179, 243, 0.3) 85%,
 rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);


    margin: -.3rem auto 3rem auto;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: -15rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-sub-category {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 1rem 8rem 2.5rem;
    padding: 5rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-white, .3) ,  rgba($color-white, .4) );
    }

    .col-single-video-related {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin: 3rem 8rem;
    padding: 5rem 0rem 5rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );


      .col-1-of-5 {
        width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      }
    }

    .col-single-video-related-vid {
    // width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    width: 100%;
    display: flex;
    justify-content: center;
    margin:  8rem auto 0 0;
    padding: 1rem 0  ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );

      &__sub-title {
        width: 50%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // justify-items: center;
        margin: 0 auto;
      }
    }





    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 43rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6)  ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7-sellerVideos {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7) ;
    }
    .col-6-of-7-sellerVideos {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
    }

 }

.row-background-page-seller-profile-public {
    width: 100%;
    border-radius: .5%;

background-color: rgba($color-grey-light-3, .2);

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);


    margin: -.3rem auto 3rem auto;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: -15rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-sub-category {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 1rem 8rem 2.5rem;
    padding: 5rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-white, .3) ,  rgba($color-white, .4) );
    }

    .col-single-video-related {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin: 3rem 8rem;
    padding: 5rem 0rem 5rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );


      .col-1-of-5 {
        width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      }
    }

    .col-single-video-related-vid {
    // width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    width: 100%;
    display: flex;
    justify-content: center;
    margin:  8rem auto 0 0;
    padding: 1rem 0  ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );

      &__sub-title {
        width: 50%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // justify-items: center;
        margin: 0 auto;
      }
    }



    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 43rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6)  ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7-sellerVideos {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7) ;
    }
    .col-6-of-7-sellerVideos {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
    }

 }

.row-background-seller-home-forms {
    width: 100%;
    border-radius: .5%;

// background-color: rgba($color-green-yellow, .23);

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);

background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);


    margin: 0 0 3rem 0;
    // margin-top: 5rem;
    // padding-left: 1rem;
    // padding-right: -15rem;
    padding-top: 2rem ;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    // visibility: hidden;

    //  &:hover {
    //   width: 0;
    //   visibility: collapse;
    // }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-sub-category {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 1rem 8rem 2.5rem;
    padding: 5rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-white, .3) ,  rgba($color-white, .4) );
    }

    .col-single-video-related {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin: 3rem 8rem;
    padding: 5rem 0rem 5rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );


      .col-1-of-5 {
        width: calc((100% - 5 * #{$gutter-horizontal-3})/5);
      }
    }

    .col-single-video-related-vid {
    width: calc( 5 * ((100% - 1 * #{$gutter-horizontal-3})/6) + 3 * #{$gutter-horizontal-1});
    margin:  8rem 8rem 0 8rem;
    padding: 1rem 0rem 1rem 7rem ;
    background-image:
      linear-gradient( to right bottom,
      rgba($color-tertiary-light, .1) ,  rgba($color-tertiary-dark, .2) );

      &__sub-title {
        width: 50%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // justify-items: center;
        margin: 0 auto;
      }
    }

    .col-seller-profile--categories-sub {
    // width: calc((100% - 4 * #{$gutter-horizontal-3})/5);
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: rgba($color-white, 1);
    // flex-wrap: wrap;
    // justify-content:center;
    // justify-items: center;
    margin: 2rem 5rem 5rem 0;
    // padding: 22rem auto;
    // background-color: #232f3e;
    background-image:
      linear-gradient( to bottom,
      rgba($color-tertiary-light, .2) ,  rgba($color-tertiary-dark, .2) ) !important;
    // border: .2rem rgb(9, 255, 0) solid !important;


      &__list {
        width: 100%;
        // max-width: 22rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:center;
        justify-items: center;
        // margin:2rem auto ;
        padding: 2rem 3rem;
        // border: .2rem yellow solid;

        &--item {
          width: 100%;
          max-width: 22rem;
          background-color: rgba($color-white, .4);
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content:center;
          justify-items: center;
          margin:1rem  ;
          padding: 1.5rem 3rem ;
          // border: .2rem red solid;
        }
      }



    }

    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 43rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6)  ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7-sellerVideos {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7) ;
    }
    .col-6-of-7-sellerVideos {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
    }

 }

.row-background-page-seller-video {
    width: 100%;
    // height: fill;
    // background-color: rgba($color-black, 1);
  background-image: linear-gradient(to bottom,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .7) 50%,
  rgba(67, 70, 241, .7) 51%,
  rgba(178, 179, 243, 0.5) 85%,
  rgba(88, 91, 245, 0.8) 100%,
  rgba(67, 70, 241, .8) 100%);

    margin: .5rem 1rem -1rem 1rem;
    // padding: 5rem 10rem 5rem 10rem;
    padding-bottom: 10rem;
    padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .3rem red solid;
     height: 35rem;
    //  background-color: aqua;
    //  padding-bottom: -8rem !important;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }




.row-seller-home-new-arrivals-4-cards-and-pagination {
    width: 100%;
    height: 40rem;
// border: 1rem red solid;
    clip-path: polygon(5% 0, 95% 0, 100% 100%, 0 100%);
    // background-color: rgba($color-tertiary-dark, .91);
    background-image: linear-gradient(to bottom, rgba($color-black,.1),
    rgba($color-black,.1), rgba($color-tertiary-light,.2), rgba($color-tertiary-light, .9));

    margin: 0 auto 5rem auto;
    padding: 5rem 7rem !important;
    // padding-bottom: 10rem;
    // padding-top: 4rem;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    @include respond(tab-land){
      width: 85vw;
      display: flex;
      flex-wrap: wrap;
      // height: 50rem;
      margin: 0 2vw 5rem -3vw !important;
      // margin-right: -2vw !important;
      // border: .5rem orange solid;
       padding: 5rem 3rem !important;
    }
    @include respond(tab-port){
      width: 90vw;
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      margin: 0 2vw 5rem -5vw !important;
      padding-right: 15rem !important;
      padding-left: 15rem !important;
      // border: .5rem orange solid;

    }
    @include respond(phone){
      width: 90vw;
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      margin: 0 5vw 1rem -6.5vw !important;
      padding-right: 7vw !important;
      padding-left: 7vw !important;
       clip-path: polygon(5% 0, 95% 0, 95% 95%, 5% 95%);
      // border: .5rem orange solid;

    }
    @include respond(small-phone){
      width: 95vw;
      display: flex;
      flex-wrap: wrap;
      height: fit-content;
      margin: 0 1vw 1rem -3vw !important;
      padding-right: 1vw !important;
      padding-left: 1vw !important;
       clip-path: polygon(5% 0, 95% 0, 95% 95%, 5% 95%);
      // border: .5rem orange solid;

    }
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: 2rem orange solid;

    }

    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }


&__inside-background {
    width: 100%;
    // height: fill;
    background-color: rgba($color-white, .5);
    margin: 1rem auto;
    padding: 4rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .2);
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }




   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .3rem red solid;
    //  margin: .2rem 2rem;
    //  height: 135rem;
     margin: .2rem 1rem;
    //  background-color: aqua;
    //  padding-bottom: -8rem !important;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }



 }

&__cards-background {
     background-color:rgba($color-navy-dark, .8);
     display: flex;
     justify-content: center;
     justify-items: center;
  }

 }

.row-seller-home-new-arrivals-4-cards-and-pagination--outside {
    width: 100%;
    height: fit-content !important;
    // border: 1rem red solid;

    // clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // background-color: rgba($color-tertiary-dark, .1);
    // background-image: linear-gradient(to bottom, rgba($color-black,.04), rgba($color-tertiary-light,.2), rgba($color-tertiary-light,.2), rgba($color-tertiary-light, .2), rgba($color-black,.04));

    margin: 1rem auto  0 auto;
    padding: 5rem 10rem;
    // border: .1rem red solid;
    // padding-bottom: -11rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);
    // z-index: 1;


    //  @include respond(big-desktop){
    //   margin: 1rem auto  -11rem auto;

    //  }
     @include respond(tab-land){
      display: flex;
      flex-wrap: wrap !important;
      // margin: 1rem -15%  -1rem -1% !important;
      padding: 2rem 2rem !important;
      // border: .5rem red solid;
     }

     @include respond(tab-port){
      display: flex;
      flex-wrap: wrap !important;
      height: fit-content;
       margin: 1rem auto  1rem auto;
        padding: 5rem 5rem !important;
      // border: .1rem red solid;
     }
     @include respond(phone){
      display: flex;
      flex-wrap: wrap !important;
      height: fit-content !important;
       margin: 1rem auto  1rem auto;
        padding: 5rem 5rem !important;
      // border: .1rem red solid;
     }
     @include respond(small-phone){
      display: flex;
      flex-wrap: wrap !important;
      height: fit-content !important;
       margin: 1rem auto  2rem auto;
        padding: 5rem 2rem !important;
      // border: .1rem red solid;
     }
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    @include respond(tab-land){
        width: calc((100% - 2 * #{ $gutter-horizontal-1})/4) !important;
        height: 30rem !important;
      // border: .2rem red solid;
       margin-bottom: 1rem !important;
      //  padding-bottom: -5rem !important;
     }

    @include respond(tab-port){
        width: calc((100% - 2 * #{ $gutter-horizontal-1})/2) !important;
        height: 30rem !important;
      // border: .1rem blue solid;
       margin-bottom: 3rem !important;
       margin-right: 3rem !important;
       margin-left: 2rem !important;
       padding-bottom: -5rem !important;
     }

    @include respond(phone){
        width: calc((100% - 2 * #{ $gutter-horizontal-1})/2) !important;
        height: 30rem !important;
      // border: .1rem blue solid;
       margin-bottom: 3rem !important;
       margin-right: 1.5rem !important;
       margin-left: 1.5rem !important;

     }

    }

    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }


&__inside-background {
    width: 100%;
    // height: 60rem !important;
    background-color: rgba($color-white, .5);
    // border: .3rem red solid;
    //  clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // border-radius: 50%;
    // -webkit-border-radius: 50%;
    // -moz-border-radius: 50%;
    // -ms-border-radius: 50%;
    // -o-border-radius: 50%;
    //clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
    margin: 1rem auto;
    padding: 4rem 10rem !important;

    //padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);

    @include respond(tab-land){
      height: 60rem !important;
      // border: .3rem red solid;
      // padding: auto 0 auto -20% !important;
      // padding: 4rem 4.5% 4rem 9% !important;
      // border: .3rem red solid;
    }

    @include respond(tab-port){
      height: fit-content !important;
      padding: 4rem 3.4vw 4rem 6.5vw !important;
      // border: 1rem red solid;
    }

    @include respond(small-phone){
      height: fit-content !important;
      padding: 4rem 3vw 4rem 3vw !important;
      margin-right: 0 !important;
      // border: 1rem red solid;
    }
    @include clearfix;
    [class^="col-"]{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
       @include respond(phone){
        margin-right: 0 !important;
       }
     }
   }




   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .3rem red solid;
    //  margin: .2rem 2rem;
     height: 40rem;
     margin: .2rem 1rem;
    //  background-color: aqua;
    //  padding-bottom: -8rem !important;
    @include respond(tab-port){
      height: 30rem !important;
      // margin: 0 3rem 0 3rem !important;
      // border: 1rem red solid;
    }
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }



 }

&__cards-background {
     background-color:rgba($color-navy-dark, .8);
  }

 }

.row-seller-home-new-arrivals-4-cards-and-pagination-outside--product-videos {
    // width: fit-content;
    height: fit-content !important;
    // border: .1rem red solid;

    // clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // background-color: rgba($color-tertiary-dark, .1);
    // background-image: linear-gradient(to bottom, rgba($color-black,.04), rgba($color-tertiary-light,.2), rgba($color-tertiary-light,.2), rgba($color-tertiary-light, .2), rgba($color-black,.04));

    margin: 0 auto  2rem auto;
    padding: 15rem auto !important;
    // border: .1rem red solid;
    // padding-bottom: -11rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);
    // z-index: 1;


    //  @include respond(big-desktop){
    //   margin: 1rem auto  -11rem auto;

    //  }
     @include respond(tab-land){
      display: flex;
      flex-wrap: wrap !important;
      margin: 1rem auto  1rem auto;
     }

     @include respond(tab-port){
      display: flex;
      flex-wrap: wrap !important;
      height: 125rem;
      border: 1rem blue solid;
     }
     @include respond(phone){
      // display: flex;
      // flex-wrap: wrap !important;
      height: 100%;
      border: .1rem orange solid;
     }
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    // .col-1-of-4 {
    // width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    // //  border: .1rem red solid;
    // @include respond(tab-port){
    //     width: calc((100% - 2 * #{ $gutter-horizontal-1})/2) !important;
    //     height: 30rem !important;
    //   // border: .2rem red solid;
    //    margin-bottom: 2rem !important;
    //   //  padding-bottom: -5rem !important;
    //  }
    // @include respond(phone){
    //     // width: calc((100% - 2 * #{ $gutter-horizontal-1})/2) !important;
    //     width: 85% !important;
    //     height: 30rem !important;
    //   border: .2rem red solid;
    //    margin: 2rem 5%!important;
    //   //  padding-bottom: -5rem !important;
    //  }
    // }


    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }


&__inside-background {
    width: 95vw;
    height: fit-content !important;
    background-color: rgba($color-white, .5);
    //  clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // border-radius: 50%;
    // -webkit-border-radius: 50%;
    // -moz-border-radius: 50%;
    // -ms-border-radius: 50%;
    // -o-border-radius: 50%;
    //clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
    margin: 11rem auto!important;
    padding: 7rem 5rem !important;
// border: .1rem red solid;
    //padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);

    @include respond(tab-land){
      height: fit-content !important;
    }

    @include respond(tab-port){
      height: fit-content !important;
    }
    @include respond(phone){
      width: 100%;
       height: fit-content !important;
      //  padding: 7rem 5rem !important;
      // border: .1rem red solid;
    }
    @include respond(phone-500){
      width: 100% !important;
       height: fit-content !important;
        padding: 5rem 3rem !important;
      // border: .1rem red solid;
    }
    @include respond(phone-400){
      width: 100%;
       height: fit-content !important;
      //  padding: 7rem 5rem !important;
      // border: .1rem red solid;
    }
    @include clearfix;
    [class^="col-"]{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }




   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }


    // this one .............////////////
    // .col-1-of-4 {
    // width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    // //  border: .3rem red solid;
    // //  margin: .2rem 2rem;
    //  height: 40rem;
    //  margin: .2rem 1rem;
    // //  background-color: aqua;
    // //  padding-bottom: -8rem !important;
    // }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }



 }

&__cards-background {
     background-color:rgba($color-navy-dark, .8);
  }

 }

.row-seller-home-new-arrivals-4-cards-and-pagination-outside--podcasts {
    width: 100% !important;
    height: fit-content !important;
    // border: .1rem red solid;

    // clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // background-color: rgba($color-tertiary-dark, .1);
    // background-image: linear-gradient(to bottom, rgba($color-black,.04), rgba($color-tertiary-light,.2), rgba($color-tertiary-light,.2), rgba($color-tertiary-light, .2), rgba($color-black,.04));

    margin: 1rem auto  2rem auto;
    // padding: 5rem 3rem;
    border: .5rem rgb(225, 255, 0) solid;
    padding-bottom: 11rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);
    // z-index: 1;


    //  @include respond(big-desktop){
    //   margin: 1rem auto  -11rem auto;

    //  }
     @include respond(tab-land){
      display: flex;
      flex-wrap: wrap !important;
      margin: 1rem auto  1rem auto;
     }

     @include respond(tab-port){
      display: flex;
      flex-wrap: wrap !important;
      height: 125rem;
      border: 1rem blue solid;
     }
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    @include respond(tab-port){
        width: calc((100% - 2 * #{ $gutter-horizontal-1})/2) !important;
        height: 30rem !important;
      // border: .2rem red solid;
       margin-bottom: 2rem !important;
      //  padding-bottom: -5rem !important;
     }
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }


&__inside-background {
    width: 100%;
    height: 60rem !important;
    background-color: rgba($color-white, .5);
    //  clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // border-radius: 50%;
    // -webkit-border-radius: 50%;
    // -moz-border-radius: 50%;
    // -ms-border-radius: 50%;
    // -o-border-radius: 50%;
    //clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
    margin: 1rem auto;
    padding: 4rem 10rem !important;

    //padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);

    @include respond(tab-land){
      height: 60rem !important;
    }

    @include respond(tab-port){
      height: 95rem !important;
    }
    @include clearfix;
    [class^="col-"]{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }




   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .3rem red solid;
    //  margin: .2rem 2rem;
     height: 40rem;
     margin: .2rem 1rem;
    //  background-color: aqua;
    //  padding-bottom: -8rem !important;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }



 }

&__cards-background {
     background-color:rgba($color-navy-dark, .8);
  }

 }

.row-seller-home-new-arrivals-4-cards-and-pagination-outside--category-machinery {
    width: 100% !important;
    height: fit-content !important;
    border: 1rem orange solid;

    // clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // background-color: rgba($color-tertiary-dark, .1);
    // background-image: linear-gradient(to bottom, rgba($color-black,.04), rgba($color-tertiary-light,.2), rgba($color-tertiary-light,.2), rgba($color-tertiary-light, .2), rgba($color-black,.04));

    margin: 1rem auto  2rem auto;
    // padding: 5rem 3rem;
    // border: .5rem rgb(225, 255, 0) solid;
    padding-bottom: 11rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);
    // z-index: 1;


    //  @include respond(big-desktop){
    //   margin: 1rem auto  -11rem auto;

    //  }
     @include respond(tab-land){
      display: flex;
      flex-wrap: wrap !important;
      margin: 1rem auto  1rem auto;
     }

     @include respond(tab-port){
      display: flex;
      flex-wrap: wrap !important;
      height: 125rem;
      border: 1rem blue solid;
     }
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    @include respond(tab-port){
        width: calc((100% - 2 * #{ $gutter-horizontal-1})/2) !important;
        height: 30rem !important;
      // border: .2rem red solid;
       margin-bottom: 2rem !important;
      //  padding-bottom: -5rem !important;
     }
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);


    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }


&__inside-background {
    width: 100%;
    height: 60rem !important;
    background-color: rgba($color-white, .5);
    //  clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // border-radius: 50%;
    // -webkit-border-radius: 50%;
    // -moz-border-radius: 50%;
    // -ms-border-radius: 50%;
    // -o-border-radius: 50%;
    //clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
    margin: 1rem auto;
    padding: 4rem 10rem !important;

    //padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);

    @include respond(tab-land){
      height: 60rem !important;
    }

    @include respond(tab-port){
      height: 95rem !important;
    }
    @include clearfix;
    [class^="col-"]{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }




   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .3rem red solid;
    //  margin: .2rem 2rem;
     height: 40rem;
     margin: .2rem 1rem;
    //  background-color: aqua;
    //  padding-bottom: -8rem !important;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }



 }

&__cards-background {
     background-color:rgba($color-navy-dark, .8);
  }

 }

.row-home-category-products-3-and-2 {
    width: 100%;
    // clip-path: polygon(7% 0, 93% 0, 100% 100%, 0 100%);
    // background-color: rgba($color-tertiary-dark, .1);
    background-image: linear-gradient(to bottom, rgba($color-black,.04), rgba($color-tertiary-light,.2), rgba($color-tertiary-light,.2), rgba($color-tertiary-light, .2), rgba($color-black,.04));

    margin: -1rem auto ;
    padding: 5rem 7rem 5rem 7rem;
    // padding-bottom: 10rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }

  @include respond(phone){
    padding: 5rem 2rem 5rem 2rem;
  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }


&__inside-background {
    width: 100%;
    //height: fill;
    background-color: rgba($color-white, .5);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    //clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
    margin: 1rem auto;
    padding: 4rem;
    //padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .2);
    @include clearfix;
    [class^="col-"]{

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }




   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .3rem red solid;
    //  margin: .2rem 2rem;
     height: 29rem;
     margin: .2rem 1rem;
    //  background-color: aqua;
    //  padding-bottom: -8rem !important;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }



 }

&__cards-background {
     background-color:rgba($color-navy-dark, .8);
  }

 }

.row-background-page-seller-home-podcasts-and-others {
    width: 100%;

    background-color: rgba($color-tertiary-dark, .1);
  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);

    margin: 1rem auto 3rem auto;
    padding: 5rem 10rem 5rem 10rem;
    // padding-bottom: 10rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-seller-home-best-sellers-background {
    width: 98%;
    height: fit-content;
    // overflow: hidden;

    background-color: rgba($color-secondary-dark, .05);

    margin: 1rem auto ;
    padding: 5rem  ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    &__inside-background {
      width: 95%;
      height: fit-content;
      background-color: rgba($color-navy-dark, .9);

      margin: 1rem auto;
      padding: 5rem auto !important;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      // &:not(:last-child){
      //   margin-right: $gutter-horizontal-3;
      // }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      width: 33rem;
      height: 30rem;
      margin: 2rem auto 1rem auto;
      // margin-left: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

 }

.row-home-best-sellers-category-clothing-background {
    width: 100%;
    height: fit-content;
    // overflow: hidden;

    background-color: rgba($color-tertiary-dark, .05);

    margin:  0 auto ;
    padding: 10rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    &__inside-background {
      width: 95%;
      height: 80vh;
      background-color: rgba($color-navy-dark, .85);

      margin: 1rem auto 1rem auto;
      padding: 4rem auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      flex-wrap: nowrap;


      box-shadow: 0 .5rem 3rem rgba($color-black, .2);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      // &:not(:last-child){
      //   margin-right: $gutter-horizontal-3;
      // }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      width: 33rem;
      height: 30rem;
      margin: 2rem auto 1rem auto;
      // margin-left: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }


    &__inside-background-2 {
      width: 100%;
      height: fit-content;
      background-color: rgba($color-secondary-dark, .07);
      border: .3rem white solid;

      margin: 5rem auto;
      padding: 4rem auto 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

    @include respond(phone){
      margin-top: 1rem;
      //  height: 50vh;
      // border: .1rem red solid;
    }
      box-shadow: 0 .5rem 3rem rgba($color-black, .2);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      // &:not(:last-child){
      //   margin-right: $gutter-horizontal-3;
      // }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      width: 33rem;
      height: 30rem;
      margin: 2rem auto 1rem auto;
      // margin-left: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

    &__inside-background-3 {
      width: 100%;
      height: fit-content;
      // background-color: rgba(#204C82, .15);
      background-color: rgba(34, 44, 157, 0.15);

      margin: 1rem auto;
      padding: 2rem 4rem 0 1rem !important;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;


      // box-shadow: 0 .5rem 3rem rgba($color-black, .2);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      // &:not(:last-child){
      //   margin-right: $gutter-horizontal-3;
      // }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      width: 33rem;
      height: 30rem;
      margin: 2rem auto 1rem auto;
      // margin-left: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }


 }

.row-home-best-sellers-category-clothing-background-2 {
    width: 100%;
    height: fit-content;
    // overflow: hidden;

    background-color: rgba($color-tertiary-dark, .05);

    margin:  0 auto ;
    // padding: 10rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    &__inside-background {
      width: 95% !important;
      height: fit-content;
      background-color: rgba($color-navy-dark, .85);

      margin: 1rem auto 1rem auto;
      padding: 4rem auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      flex-wrap: nowrap;


      box-shadow: 0 .5rem 3rem rgba($color-black, .2);

      @include respond(phone){
        width: 100%;
        display: flex;
        margin-top: -5rem;
        margin-bottom: 5rem;
      }
      @include respond(phone-500){
        width: 100% !important;
        margin-top: -5rem;
        margin-bottom: 5rem;
      }
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      // &:not(:last-child){
      //   margin-right: $gutter-horizontal-3;
      // }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      // .col-1-of-4 {
      // // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      // width: 33rem;
      // height: 30rem;
      // margin: 2rem auto 1rem auto;
      // // margin-left: 2rem;
      //   @include respond(phone){
      //   width: calc((100% - 2 * #{$gutter-horizontal-1})/2) !important;
      //   height: 30rem !important;
      //   margin: 2rem 2rem 2rem 2rem !important;
      // }
      //   @include respond(phone-500){
      //   width: 50vw !important;
      //   height: 30rem !important;
      //   margin: 0 !important;
      //   border: .5rem red solid;
      // }



      // }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

 }

.row-home-best-sellers-all-categories-background {
    width: 85%;
    height: fit-content !important;
    // overflow: hidden;

    background-color: rgba($color-white, .3);

    margin: 1rem auto !important;
    padding: 3rem 7rem !important;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

    // @include respond(phone){
    //   display: flex;
    //   flex-wrap: wrap;
      // border: .5rem orange solid !important;
    // }

    @include respond(tab-port){
      margin: 1rem auto 5rem auto !important;
      padding-bottom: 0 !important;
    }
    @include respond(phone){
      width: 95%;
      // padding: 5rem 7rem 2rem 7rem !important;
    }

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    // .col-1-of-4 {
    // width: calc((100% - 9 * #{$gutter-horizontal-3})/4) !important;
    // //  border: 1rem red solid;
    // }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    &__inside-background {
      width: 95%;
      height: 70vh;
      background-color: rgba($color-navy-dark, .9);

      margin: 1rem auto;
      padding: 4rem auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);


    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      // &:not(:last-child){
      //   margin-right: $gutter-horizontal-3;
      // }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      width: 33rem;
      height: 30rem;
      margin: 2rem auto 1rem auto;
      // margin-left: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

 }

.row-seller-home-seller-podcasts-background {
    width: 100%;
    height: 90vh;
    // overflow: hidden;


    background-color: rgba($color-tertiary-light, .18);

    margin: 8rem auto -1rem auto ;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }


    &__inside-background {
      width: 90%;
      height: 95%;
      // display: flex;
      justify-content: center !important;
      justify-items: center;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto !important;
      padding: 2rem auto !important;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

    @include clearfix;

    [class^="col-"] {

      float: left;

    }


    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }


  &--inside {
    width: 95% !important;
    padding: 2rem !important;
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    // padding-left: 4rem !important;
    margin-bottom: 4rem !important;
    background-color: rgba($color-white, .1);
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4) !important ;
    justify-items: center;
    margin: 1rem 1rem 1rem 2rem !important;
    }
  }
  }

}


.row-home-product-videos-navy-dark-background {
    width: 100%;
    height: 90vh;
    // overflow: hidden;

    background-color: rgba($color-tertiary-light, .18);

    margin: 8rem auto -1rem auto ;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }


    &__inside-background {
      width: 90% !important;
      height: fit-content !important;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto !important;
      padding: 2rem 1rem !important;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

      @include respond(phone){
        width: 100% !important;
        // margin-left: .1rem !important;
      }

    @include clearfix;

    [class^="col-"] {

      float: left;

    }


    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }


  &--inside {
    width: 95%;
    height:fit-content !important;
    // padding: 0 !important;
    padding-top: 5rem !important;
    padding-left: 2rem !important;
    margin-bottom: 4rem !important;
    background-color: rgba($color-white, .1);
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4) !important ;
    justify-items: center;
    margin: 1rem 1rem 1rem 2rem !important;
    }
  }
  }

}

// .row-home-products-videos-background {
//     width: 100%;
//     height: 90vh;
//     // overflow: hidden;

//     // background-color: rgba($color-tertiary-light, .18);

//     margin: 8rem auto -1rem auto ;
//     padding: 5rem 2rem ;

//     box-shadow: 0 .5rem 3rem rgba($color-black, .03);

//    @include clearfix;

//    [class^="col-"] {

//      float: left;


//    }


//     &__inside-background {
//       width: 95% !important;
//       height: fit-content !important;
//       background-color: rgba($color-navy-dark, .9);

//        margin: 1rem auto !important;
//       padding: 2rem 1rem !important;


//       box-shadow: 0 .5rem 3rem rgba($color-black, .3);

//     @include clearfix;

//     [class^="col-"] {

//       float: left;

//     }


//     .col-1-of-4-seller-home {
//     width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

//     }


//   &--inside {
//     width: 95%;
//     height:fit-content !important;
//     // padding: 0 !important;
//     padding-top: 2rem !important;
//     padding-left: 1rem !important;
//     margin-bottom: 4rem !important;
//     background-color: rgba($color-white, .1);
//     .col-1-of-4 {
//     width: calc((100% - 3 * #{$gutter-horizontal-3})/4)  ;
//     height:fit-content;
//     justify-items: center;
//     margin: 1rem 1rem 2rem 1rem !important;
//     }
//   }
//   }

// }

.row-home-podcasts-background-1 {
    width: 100%;
    height: 90vh;
    // overflow: hidden;

    // background-color: rgba($color-tertiary-light, .18);

    margin: 8rem auto -1rem auto ;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }


    &__inside-background {
      width: 95% !important;
      height: fit-content !important;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto !important;
      padding: 2rem 1rem !important;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

    @include clearfix;

    [class^="col-"] {

      float: left;

    }


    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }


  &--inside {
    width: 95%;
    height:30vh !important;
    // padding: 0 !important;
    padding-top: 2rem !important;
    padding-left: 1rem !important;
    margin-bottom: 4rem !important;
    background-color: rgba($color-white, .1);
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4)  ;
    height:100%;
    justify-items: center;
    margin: 1rem 1rem 2rem 1rem !important;
    border: .2rem red solid;
    }
  }
  }

}





.row-seller-products-videos-section {
    width: 100%;
    height:fit-content !important;
    // overflow: hidden;

    background-color: rgba($color-tertiary-light, .18);

    margin: 8rem auto 11rem auto !important;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }


    &__inside-background {
      width: 95% !important;
      height: fit-content !important;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto !important;
      padding: 2rem 1rem !important;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

    @include clearfix;

    [class^="col-"] {

      float: left;

    }


    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }


  &--inside {
    width: 95%;
    height:fit-content !important;
    // padding: 0 !important;
    padding-top: 2rem !important;
    padding-left: 1rem !important;
    margin-bottom: 4rem !important;
    background-color: rgba($color-white, .1);
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4)  ;
    height:fit-content;
    justify-items: center;
    margin: 1rem 1rem 2rem 1rem !important;
    }
  }
  }

}

.row-seller-other-seller-videos {
    width: 95%;
    height:fit-content !important;
    // overflow: hidden;

    background-color: rgba($color-tertiary-light, .18);

    margin: 2rem 2rem 2rem 3rem !important;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;


   }


    &__inside-background {
      width: 95% !important;
      height: fit-content !important;
      min-height: 50rem !important;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto !important;
      padding: 3rem  !important;


      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

    @include clearfix;

    [class^="col-"] {

      float: left;

    }


    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }


  &--inside {
    width: 95%;
    height:fit-content !important;
    // padding: 0 !important;
    padding-top: 2rem !important;
    padding-left: 1rem !important;
    margin-bottom: 4rem !important;
    background-color: rgba($color-white, .1);
    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4)  ;
    height:fit-content;
    justify-items: center;
    margin: 1rem 1rem 2rem 1rem !important;
    }
  }
  }

}

.row-seller-podcasts-background-1 {
    width: 100%;
    height: 90vh;
    background-color: rgba($color-tertiary-light, .18);

    margin: 8rem auto 8rem auto !important;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-3;
    //  }
   }


    &__inside-background {
      width: 95%;
      height: 95%;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto 5rem auto !important;
      padding: 1rem auto !important;

      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

    @include clearfix;

    [class^="col-"] {

      float: left;

    }

      .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/4) !important ;
      justify-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      &--inside {
      background-color: rgba($color-white, .1);
      }
  }

}

 // specific to this page //
.row-seller-single-podcast-section-seller-podcasts-background {
    width: 100%;
    height: 90vh;
    background-color: rgba($color-tertiary-light, .18);

    margin: 8rem auto 8rem auto !important;
    padding: 5rem 2rem ;

    box-shadow: 0 .5rem 3rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-3;
    //  }
   }


    &__inside-background {
      width: 90%;
      height: 95%;
      background-color: rgba($color-navy-dark, .9);

       margin: 1rem auto !important;
      padding: 1rem auto !important;

      box-shadow: 0 .5rem 3rem rgba($color-black, .3);

    @include clearfix;

    [class^="col-"] {

      float: left;

    }

      .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/4) !important ;
      justify-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      &--inside {
      background-color: rgba($color-white, .1);
      }
  }

 }


// .row-seller-podcasts-background {
//     width: 100%;
//     height: 90vh;
//     // overflow: hidden;

//     background-color: rgba($color-tertiary-light, .18);

//     // margin: 8rem auto;
//     padding: 5rem 2.5rem ;

//     box-shadow: 0 .5rem 3rem rgba($color-black, .03);

//    @include clearfix;

//    [class^="col-"] {

//      float: left;

//     //  &:not(:last-child){
//     //    margin-right: $gutter-horizontal-3;
//     //  }
//    }


//   //  .col-1-of-2 {
//   //    width: calc((100% - #{ $gutter-horizontal-1})/2);

//   //   }
//   //   .col-1-of-3 {
//   //   width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
//   //   margin-bottom: 5rem;
//   //   }
//   //   .col-2-of-3 {
//   //   width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
//   //   }
//   //   .col-1-of-4 {
//   //   width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
//   //   //  border: .1rem red solid;
//   //   }
//   //   .col-1-of-4-seller-home {
//   //   width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

//   //   }
//   //   .col-2-of-4 {
//   //   width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
//   //   }
//   //   .col-3-of-4 {
//   //   width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
//   //   }
//   //     .col-1-of-6 {
//   //   width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
//   //   }
//   //   .col-5-of-6 {
//   //   width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
//   //   //  border: .1rem red solid;
//   //   //  background-color: black;
//   //   }
//   //   .col-3-of-7 {
//   //   width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
//   //   }
//   //   .col-4-of-7 {
//   //   width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
//   //   }
//   //   .col-1-of-7 {
//   //   width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
//   //   //  margin-top: .5rem;
//   //   //  border: .3rem red solid;
//   //   }
//   //   .col-6-of-7 {
//   //   width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
//   //   }

//     &__inside-background {
//       width: 98%;
//       height: 98%;
//       background-color: rgba($color-navy-dark, .9);

//        margin: 1rem !important;
//       padding: 1rem 3rem;
//       // display: flex;
//       // flex-direction: row;
//       // flex-wrap: nowrap;


//       box-shadow: 0 .5rem 3rem rgba($color-black, .3);
//     //   &:not(:last-child) {
//     //     margin-bottom: $gutter-vertical;
//     //  }
//     @include clearfix;

//     [class^="col-"] {

//       float: left;

//       // &:not(:last-child){
//       //   margin-right: $gutter-horizontal-3;
//       // }
//     }


//     // .col-1-of-2 {
//     //   width: calc((100% - #{ $gutter-horizontal-1})/2);

//     //   }
//     //   .col-1-of-3 {
//     //   width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
//     //   margin-bottom: 5rem;
//     //   }
//     //   .col-2-of-3 {
//     //   width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
//     //   }


//       .col-1-of-4 {
//       width: calc((100% - 3 * #{$gutter-horizontal-2})/4) !important ;
//       justify-items: center;
//       margin-top: 2rem;
//       margin-bottom: 2rem;
//       }
//       .col-1-of-4-seller-home {
//       width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

//       }

//       // .col-2-of-4 {
//       // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
//       // }
//       // .col-3-of-4 {
//       // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
//       // }
//       //   .col-1-of-6 {
//       // width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
//       // }
//       // .col-5-of-6 {
//       // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
//       // //  border: .1rem red solid;
//       // //  background-color: black;
//       // }
//       // .col-3-of-7 {
//       // width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
//       // }
//       // .col-4-of-7 {
//       // width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
//       // }
//       // .col-1-of-7 {
//       // width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
//       // //  margin-top: .5rem;
//       // //  border: .3rem red solid;
//       // }
//       // .col-6-of-7 {
//       // width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
//       // }

//       &--inside {
//       background-color: rgba($color-white, .1);
//       }
//   }

//  }

.row-seller-home-seller-podcasts-carousel-background {
    width: 100%;
    height: 100%;
    //overflow: hidden;
    display: flex;
    flex-direction: row;
    background-color: rgba($color-white, .3);
    margin: 1rem auto ;
    padding: 2rem 7rem 2rem 7rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    //@includeclearfix:;
    //[class^="col-"]:;



  //    float: left;

  //    &:not(:last-child){
  //      margin-right: $gutter-horizontal-3;
  //    }
  //  }


  //  .col-1-of-2 {
  //    width: calc((100% - #{ $gutter-horizontal-1})/2);

  //   }

    // .col-1-of-3 {
    // width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // margin-bottom: 5rem;
    // }

    // .col-2-of-3 {
    // width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    // }



    // .col-1-of-4 {
    // width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    // //  border: .1rem red solid;
    // }
    // .col-1-of-4-seller-home {
    // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    // }

    // .col-2-of-4 {
    // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    // }

    // .col-3-of-4 {
    // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    // }

    //   .col-1-of-6 {
    // width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    // }

    // .col-5-of-6 {
    // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    // }

    // .col-3-of-7 {
    // width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    // }
    // .col-4-of-7 {
    // width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    // }
    // .col-1-of-7 {
    // width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    // }
    // .col-6-of-7 {
    // width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    // }

    &__inside-background {
       width: 100%;
       height: 80%;
       background-color: rgba($color-navy-dark, .8);
       border-left: 8rem solid rgba($color-black, .3);
       border-top: 4rem solid rgba($color-black, .3);
       border-right: 8rem solid rgba($color-black, .3);
       border-bottom: 4rem solid rgba($color-black, .3);
       //padding: 0 2rem;
       //margin: 1rem 2rem 1rem 0;
       margin: 2rem auto;
       display: flex;
       flex-direction: column;
       flex-wrap: nowrap;
       box-shadow: 0 .5rem 3rem rgba($color-black, .3);

       &:not(:last-child) {
        margin-bottom: $gutter-vertical;
     }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-3;
      }
    }


    .col-1-of-2-createPlayer {
      // width: calc((100% - #{ 5rem})/2) ;
      width: 45%;
      // height: 40%;
      margin: 0 0 4rem 2rem;
      //  padding: .5rem 0;
      // border: .1rem rgba($color-white, .3) solid;
      }
    .col-1-of-2 {
      // width: calc((100% - #{5rem})/2) ;
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
       margin: 5rem auto;
      padding: 3rem ;
      background-color: rgba($color-white, .25);
      border: .5rem rgba($color-white, .05) solid;


      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-23})/4);
      width: 33rem;
      height: 45rem;
      // margin-left: 2rem;
      margin: 0 auto 0 2.2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

      &--carousel {
        width: 95%;
        margin: 0 auto 5rem auto;
        padding: 4rem 3rem 1rem 3rem;
        background-color: rgba($color-white, .12);
        border-top: .2rem solid rgba($color-white, .51);
        border-bottom: .5rem solid rgba($color-white, .51);
        // border: .1rem solid rgba($color-black, 1);
        box-shadow: .1rem .1rem .1rem rgba($color-black, .2);
      }

  }

 }

// .row-home-products-videos-background {
//     width: 100%;
//     // height: 80vh;
//     // overflow: hidden;

//     // background-color: rgba($color-tertiary-light, .1);

//     margin: 1rem auto ;
//     padding: 5rem auto !important;

//     box-shadow: 0 .5rem 3rem rgba($color-white, .3);

//    @include clearfix;

//    [class^="col-"] {

//      float: left;

//      &:not(:last-child){
//        margin-right: $gutter-horizontal-3;
//      }
//    }


//    .col-1-of-2 {
//      width: calc((100% - #{ $gutter-horizontal-1})/2);

//     }

//     .col-1-of-3 {
//     width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
//     margin-bottom: 5rem;
//     }

//     .col-2-of-3 {
//     width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
//     }



//     .col-1-of-4 {
//     width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
//     // height: 32rem;
//     // margin-bottom: 5rem;
//     //  border: 1rem red solid;
//     }
//     .col-1-of-4-seller-home {
//     width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

//     }

//     .col-2-of-4 {
//     width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
//     }

//     .col-3-of-4 {
//     width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
//     }

//       .col-1-of-6 {
//     width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
//     }

//     .col-5-of-6 {
//     width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
//     //  border: .1rem red solid;
//     //  background-color: black;
//     }

//     .col-3-of-7 {
//     width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
//     }
//     .col-4-of-7 {
//     width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
//     }
//     .col-1-of-7 {
//     width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
//     //  margin-top: .5rem;
//     //  border: .3rem red solid;
//     }
//     .col-6-of-7 {
//     width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
//     }

//     &__inside-background {
//       width: 100%;
//       // height: 95%;
//       background-color: rgba($color-navy-dark, 1);

//       margin: 1rem 2rem 1rem 0;
//       padding: 1rem 2rem !important;
//       display: flex;
//       flex-direction: row;
//       flex-wrap: nowrap;


//       box-shadow: 0 .5rem 3rem rgba($color-black, .3);
//     //   &:not(:last-child) {
//     //     margin-bottom: $gutter-vertical;
//     //  }
//     @include clearfix;

//     [class^="col-"] {

//       float: left;

//       &:not(:last-child){
//         margin-right: $gutter-horizontal-3;
//       }
//     }


//     .col-1-of-2 {
//       width: calc((100% - #{ $gutter-horizontal-1})/2);

//       }

//       .col-1-of-3 {
//       width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
//       margin-bottom: 5rem;
//       }

//       .col-2-of-3 {
//       width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
//       }



//       .col-1-of-4 {
//       width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
//       // width: 43rem;
//       // height: 25rem;
//       // margin-left: 2rem;
//       margin: 1rem auto 1rem 1.5rem !important;
//       }
//       .col-1-of-4-seller-home {
//       width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

//       }

//       .col-2-of-4 {
//       width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
//       }

//       .col-3-of-4 {
//       width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
//       }

//         .col-1-of-6 {
//       width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
//       }

//       .col-5-of-6 {
//       width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
//       //  border: .1rem red solid;
//       //  background-color: black;
//       }

//       .col-3-of-7 {
//       width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
//       }
//       .col-4-of-7 {
//       width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
//       }
//       .col-1-of-7 {
//       width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
//       //  margin-top: .5rem;
//       //  border: .3rem red solid;
//       }
//       .col-6-of-7 {
//       width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
//       }

//   }

//  }

.row-home-products-videos-background-new-name {
    width: 100%;
    // height: 80vh;
    // overflow: hidden;

    // background-color: rgba($color-tertiary-light, .1);

    margin: 1rem auto ;
    padding: 5rem auto !important;

    box-shadow: 0 .5rem 3rem rgba($color-white, .3);

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    // .col-1-of-4 {
    // width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    // // height: 32rem;
    // // margin-bottom: 5rem;
    // //  border: 1rem red solid;
    // }

    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    &__inside-background {
      width: 95%;
      // height: 95%;
      background-color: rgba($color-black, .15);

      margin: 1rem auto 1rem auto;
      padding: 3rem 2rem !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // border: 1rem rgba($color-white, .5) solid;

      box-shadow: 0 .5rem 3rem rgba($color-black, .3);
      &:not(:last-child) {
        margin-right: $gutter-vertical !important;
     }
      @include respond(tab-port){
        // &__height-scroll {
        width: 100%;
        height: 66vh !important;
        padding:  2rem  1rem 2rem 1rem!important;
        background-color: rgba($color-black, .3);
        overflow: scroll;
        scroll-behavior:smooth;
         border: none;
      // }
      }
      @include respond(phone){
        // &__height-scroll {
        width: 95%;
        height: 45vh !important;
        margin: 0 -1rem 0 3rem !important;
        padding: 1rem 0 1rem 3rem !important;
        background-color: rgba($color-black, .3);
        overflow: scroll;
        scroll-behavior:smooth;
      // }
      }
      @include respond(phone-500){
        // &__height-scroll {
        width: 96%;
        height: fit-content !important;
        margin: 0 auto 0 auto !important;
        padding: 2rem !important;
        background-color: rgba($color-black, .3);
        overflow: scroll;
        scroll-behavior:smooth;
        // border: 1rem white solid;
      // }
      }
      // @include respond(small-phone){
      //   // &__height-scroll {
      //   width: 98%;
      //   height: 38vh !important;
      //   margin: 0 -1rem 0 0 !important;
      //   // padding: 1rem 0 1rem 3rem !important;
      //   background-color: rgba($color-black, .3);
      //   overflow: scroll;
      //   scroll-behavior:smooth;
      //   // border: .1rem white solid;
      // // }
      // }

    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-3;
      }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      // .col-1-of-4 {
      // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      // // width: 43rem;
      // // height: 25rem;
      // // margin-left: 2rem;
      // margin: 1rem auto 1rem 1.5rem !important;

      // // @include respond(phone){
      // //   width: 50%;
      // // }
      // }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }
        .col-1-of-6-videos {
      width: calc((100% - 5 * #{$gutter-horizontal-2})/4) ;
        @include respond(lab-top){
          // width: calc((100% - 5 * #{$gutter-horizontal-3})/4) !important;
          // height: fit-content;
          // margin: .2rem;
          margin: .2rem auto;

            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            border: .2rem white solid;
          // padding: 5rem 10rem;
        }
        @include respond(tab-port){
          width: calc((100% - 5 * #{$gutter-horizontal-2})/2) !important;
          margin: 2rem 2rem !important;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // padding-right: 1rem;
            // padding-left: 1rem;
            border: none;
          // padding: 5rem 10rem;
        }
        @include respond(phone){
         width: calc((100% - 5 * #{$gutter-horizontal-3})/2) ;
          // margin: .2rem;
      //       display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // border: .2rem white solid;
          // padding: auto 5rem !important;
        }
        @include respond(phone-500){
        //  width: calc((100% - 5 * #{$gutter-horizontal-3})/2) ;
        width: fit-content !important;
         height: fit-content !important;
          margin: 1rem auto !important;
          // margin: .2rem;
      //       display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // border: .2rem white solid;
          // padding: auto 5rem !important;
        }
      //   @include respond(phone-400){
      //    width: calc((100% - 5 * #{$gutter-horizontal-3})/2) ;
      //     margin: -5rem 2rem !important;
      //     // margin: .2rem;
      // //       display: flex;
      // // flex-direction: row;
      // // flex-wrap: wrap;
      // // border: .2rem white solid;
      //     // padding: auto 5rem !important;
      //   }

      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

 }

.row-home-podcasts-background {
    width: 100%;
    // height: 80vh;
    // overflow: hidden;

    background-color: rgba($color-secondary-dark, .1);

    margin: 1rem auto ;
    padding: 5rem !important;

    box-shadow: 0 .5rem 3rem rgba($color-black, .1);

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    // height: 32rem;
    // margin-bottom: 5rem;
    //  border: 1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    &__inside-background {
      width: 100%;
      // height: 95%;
      background-color: rgba($color-navy-dark, .65);

      margin: 1rem 2rem 1rem 0;
      padding: 1rem 5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;


      // box-shadow: 0 .5rem 1rem rgba($color-black, .3);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-3;
      }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      // width: 43rem;
      // height: 25rem;
      // margin-left: 2rem;
      margin: 0 auto 0 2.2rem;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

 }


.row-seller-home-other-sellers-podcasts-background {
    width: 95%;
    // height: fit-content;
    // overflow: hidden;
    display: flex;
    flex-direction: column;

    background-color: rgba($color-secondary-dark, .1);

    margin: 3rem auto 15rem auto !important;
    padding: 5rem 2rem 2rem 2rem;

    box-shadow: 0 .5rem 1rem rgba($color-black, .03);

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1 !important;
     }
   }


  //  .col-1-of-2 {
  //    width: calc((100% - #{ $gutter-horizontal-1})/2);

  //   }
  //   .col-1-of-3 {
  //   width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
  //   margin-bottom: 5rem;
  //   }
  //   .col-2-of-3 {
  //   width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
  //   }
  //   .col-1-of-4 {
  //   width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
  //    border: .1rem red solid;
  //   }
  //   .col-1-of-4-seller-home {
  //   width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

  //   }
  //   .col-2-of-4 {
  //   width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
  //   }
  //   .col-3-of-4 {
  //   width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
  //   }
  //     .col-1-of-6 {
  //   width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
  //   }
  //   .col-5-of-6 {
  //   width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
  //    border: .1rem red solid;
  //    background-color: black;
  //   }
  //   .col-3-of-7 {
  //   width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
  //   }
  //   .col-4-of-7 {
  //   width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
  //   }
  //   .col-1-of-7 {
  //   width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
  //    margin-top: .5rem;
  //    border: .3rem red solid;
  //   }
  //   .col-6-of-7 {
  //   width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
  //   }

    &__inside-background {
      width: 95%;
      height: fit-content;
       display: flex;
    flex-direction: column;
    justify-content: center !important;
    justify-items: center !important;
      background-color: rgba($color-navy-dark, .70);

      margin: 1rem auto;
      padding: 3rem 2rem 3rem 4rem;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // border: .2rem red solid;


      box-shadow: .3rem .5rem .1rem rgba($color-black, .3);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-2 !important;
      }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-0})/4) !important ;
      // width: 100% !important;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-items: center;
      margin-top: 2rem !important;
      margin-bottom: 1rem !important;
      margin: 0 auto;
      }
      .col-1-of-4-seller-home {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
      }

        .col-1-of-6 {
      width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
      }

      .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
      //  border: .1rem red solid;
      //  background-color: black;
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }
      .col-1-of-7 {
      width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
      //  margin-top: .5rem;
      //  border: .3rem red solid;
      }
      .col-6-of-7 {
      width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
      }

  }

 }


.row-background-page-seller-home-podcasts-carousel {
    width: 100%;
    background-color: rgba($color-tertiary-light, .01);
    //
  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: 5rem auto 3rem auto;
    padding: 5rem ;
    // padding-bottom: 10rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }


.row-background-page-seller-home-podcasts-outside-frame {
    width: 100%;
    background-color: rgba($color-Greenprimary-dark, .15);
    //
  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);

    margin: 5rem auto 3rem auto;
    padding: 5rem ;
    // padding-bottom: 10rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-page-seller-home-podcasts-outside-frame-black-design {
    width: 100%;
    background-color: rgba($color-tertiary-light, .19);
    //
  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);

    margin: 5rem auto 3rem auto;
    padding: 5rem ;
    // padding-bottom: 10rem;
    // padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    margin-bottom: 5rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-page-seller-video-1 {
    width: 100%;
  background-image: linear-gradient(99deg,
  rgba(165, 166, 247, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: -3rem auto 3rem auto;
    padding-bottom: 10rem;
    padding-top: 7rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-page-single-product-desc-write-reviews {
  width: 100%;
  background-color:rgba(88, 91, 245, 0.2);
    margin: 1rem auto 8rem auto;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 15rem;
    padding-top: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }


.row-background-page-single-product-reviews {
    width: 85vw;
    padding: 5rem !important;
    height:fit-content !important;
    border-radius: .5%;
    // border: .1rem red solid;

  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);

  background-color: rgba($color-white, .5);

    margin: .1rem auto -1rem auto;
    padding-bottom: 3rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-page-single-product-reviews-first-section {
    width: 100%;
    // border-radius: .5%;
    // border: .1rem red solid;

  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: -4rem auto -10rem auto;
    padding-top: 8rem;
    padding-bottom: 35rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }


 }

.row-background-page-single-product-related-products {
    width: 95%;
    border-radius: .5%;
    -webkit-border-radius: .5%;
    -moz-border-radius: .5%;
    -ms-border-radius: .5%;
    -o-border-radius: .5%;
    // border: .1rem red solid;
    // background-image:
    // linear-gradient(99deg,
    // rgba(88, 91, 245, 0.1) 0%,
    // rgba(178, 179, 243, 0.1) 15%,
    // rgba(67, 70, 241, .1) 50%,
    // rgba(67, 70, 241, .1) 51%,
    // rgba(178, 179, 243, 0.1) 85%,
    // rgba(88, 91, 245, 0.1) 100%,
    // rgba(67, 70, 241, .1) 100%);

    margin: .1rem auto 55rem auto;
    padding: 10rem !important;
    //padding-bottom: 2rem;
    background-color: rgba($color-tertiary-dark, .04);
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }

    @include respond(phone){
      padding: 5rem !important;
    }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-3})/4);
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-related-products-above-title {
    width: 95%;
    margin-top: 5rem;
    margin-bottom: 10rem;






 }

.row-background-page-single-video-related-videos {
    width: 95%;
    border-radius: .5%;
    // border: .3rem white solid !important;

  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: 2rem auto !important;
    padding: 3rem 2rem 5rem 4rem !important;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((95% - 3 * #{$gutter-horizontal-3})/4);
    margin-top: 4rem;
    //  border: .1rem red solid;
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-3})/6) ;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-3})/6) + 4 * #{$gutter-horizontal-3});
    //  border: .1rem red solid;
    //  background-color: black;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-page-single-product--first-section-title {
    width: 65%;
    // border-radius: .5%;
    //border: .1rem red solid;
    // background-image: linear-gradient(99deg,  rgba(88, 91, 245, 0.4) 0%,  rgba(178, 179, 243, 0.3) 15%,  rgba(67, 70, 241, .3) 50%,  rgba(67, 70, 241, .3) 51%,  rgba(178, 179, 243, 0.3) 85%,  rgba(88, 91, 245, 0.4) 100%,  rgba(67, 70, 241, .4) 100%);
    background-color: rgba($color-tertiary-dark, .15);
    margin: 5rem auto -3rem auto;
    // padding: .5rem !important;
    //padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    border-radius:3%;
    -webkit-border-radius:3% ;
    -moz-border-radius:3% ;
    -ms-border-radius:3% ;
    -o-border-radius:3% ;
}



.row-background-page-single-video {
    width: 100%;
    height: fit-content;
    position: relative;
    // border-radius: .5%;
    // border: .1rem red solid;
    // background-image: linear-gradient(99deg,  rgba(88, 91, 245, 0.4) 0%,  rgba(178, 179, 243, 0.3) 15%,  rgba(67, 70, 241, .3) 50%,  rgba(67, 70, 241, .3) 51%,  rgba(178, 179, 243, 0.3) 85%,  rgba(88, 91, 245, 0.4) 100%,  rgba(67, 70, 241, .4) 100%);
    margin: .5rem auto 8rem auto;
    padding: 2rem auto;
    //padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    border-radius:3%;
    -webkit-border-radius:3%;
    -moz-border-radius:3%;
    -ms-border-radius:3%;
    -o-border-radius:3%;
}

.row-background-page-single-video--first-section-title {
    width: fit-content !important;
    background-color: rgba($color-white, .5);
    color:$color-primary-light;
    font-size: 1.8rem;
    font-weight: 700 !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .3rem;
    padding: 2.2rem 13rem;
    // padding-top: 1.8rem;
    // padding-bottom: 1.8rem;
    margin: -1rem auto 5rem auto;
    box-shadow: 0 .1rem .1rem rgba($color-black, .05);
    border: .1rem rgba($color-grey-light-1, .1) solid;



    &::after {

    background-color: $color-white;
    }
}



.row-background-page-seller-podcasts--first-section-title {
    width: fit-content !important;
    background-color: rgba($color-white, .5);
    color:$color-primary-light;
    font-size: 1.8rem;
    font-weight: 700 !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .3rem;
    padding: 2.2rem 13rem;
    // padding-top: 1.8rem;
    // padding-bottom: 1.8rem;
    margin: -1rem auto 5rem auto;
    box-shadow: 0 .1rem .1rem rgba($color-black, .05);
    border: .1rem rgba($color-grey-light-1, .1) solid;



    &::after {

    background-color: $color-white;
    }
}

.row-background-page-single-product-video-first-section-title {
    width: 85%;
    border-radius: .5%;
    // border: .1rem red solid;

  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: -2rem auto 7rem auto;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);


 }

.row-background-page-single-product-write-review {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    text-align: center;
    border-radius: .5%;
    border: .1rem transparent solid;

  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);
  background-color: rgba($color-white, .3);

    margin: 3rem auto 0 auto;
    padding-bottom: 2rem;
    box-shadow: 0 .3rem .3rem rgba($color-black, .03);

 }


.row-background-page-seller-home {
    width: 98vw;
    border-radius: .5%;
    // border: .2rem red solid;

  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: 1.3rem auto;

    // padding-top: 2rem;
    padding-bottom: 5rem;
    // padding-left: -5rem;
    // padding-right: 3rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-page-seller-home-public {
    width: 100%;
    // border-radius: .5%;
    // border: .2rem red solid;

 background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);
  // background-color: rgba($color-white, 1);

    margin: 0 auto 1rem auto;

    // padding-top: 2rem;
    // padding-bottom: 5rem;
    // padding-left: -5rem;
    // padding-right: 3rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-shit {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    }



    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-background-home-page-public {
    width: 100%;
  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);
  // background-color: rgba($color-white, 1);
// background-color: rgba(63, 116, 248, 0.35);
    margin: .1rem auto 1.3rem auto;

    padding-top: 1rem;
    // padding-bottom: 5rem;
    // padding-left: -5rem;
    // padding-right: 3rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-shit {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    }



    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

}


.row-seller-home-public-category-products {
    width: 100% !important;
    height: 100%;
    border-radius: .5%;

    &__height-scroll {
    width: 100%;
    height: 45vh;
    padding-bottom: 10rem;

    overflow: scroll;
    scroll-behavior:smooth;
  }


    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-shit {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});

    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7) !important;
    display: flex;

    background-color: rgba($color-white, .5);
     margin: 1rem 3rem 2rem 3rem ;

     height: 14vh;
     overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

}


.row-seller-all-products-categories-products {
    width: 95rem !important;
   height: 40vh;
    border-radius: .5%;
    // margin-right:-25rem!important;
    // padding-bottom: 10rem;

    &__height-scroll {
    width: 100%;
    // height: 50vh;
    background-color: rgba($color-Greenprimary-dark, .05);

    //  margin-bottom: 10rem !important;

    overflow-y: scroll;
    scroll-behavior:smooth;
  }


    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

   }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-shit {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});

    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7) !important;
    display: flex;

    background-color: rgba($color-white, .5);
     margin: 1rem 3rem 2rem 3rem ;

     height: 14vh;
     overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

}





.row-home-public-sub-products {
    width: 100% !important;
    height: 68vh !important;
    border-radius: .5%;
    // border: .2rem rgb(0, 38, 255) solid;

  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);
  // background-color: rgba(transparent, .1);

    // margin:   0 auto;
      // padding: 5rem auto;
    // padding-top: 2rem;
    // padding-bottom: 5rem;
    // padding-left: 5rem;
    // padding-right: 3rem;
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);

    // @include respond(phone){
    //   width: 100%;
    //   height: 35vh !important;
      // border: .2rem red solid;
    // }
    @include respond(big-desktop){
      width: 100%;
      // height: 66vh !important;
      margin-top: -1rem;
      padding: 0 2rem .5rem 2rem !important;
      // border: .2rem red solid;
    }
    @include respond(tab-land){
      width: 100%;
      // height: 66vh !important;
      margin-top: -1rem;
      padding: 0 .1rem .5rem .1rem !important;
      // border: .2rem red solid;
    }

    @include respond(tab-port){
      width: 100%;
      height: 50vh !important;
      margin-top: -1rem;
      padding: 0 2rem .5rem 0 !important;
      // border: .2rem red solid;
    }
    @include respond(phone){
      width: 100%;
      height: 60vh !important;
      // border: .2rem red solid;
    }
    @include respond(small-phone){
      width: 100% !important;
      height: 60vh !important;
      margin-top: -2rem;
      // padding-right: 3rem;
      padding: .1rem .1rem .1rem .1rem !important;
    }

    &__height-scroll {
    width: 80vw !important;
    height: 65vh;
    // padding-top: 3rem;
    // padding-bottom: 10rem;

    z-index: 10000;
    // border: .2rem red solid;
    //padding-right: 1rem;
    //padding-left: -1rem;
    padding:  3rem 5rem 3rem 10rem;
    overflow: scroll !important;
    scrollbar-width: thin;
    // scroll-behavior:smooth;

    backface-visibility: hidden ;
    -webkit-backface-visibility: hidden ;


    //    &::-webkit-scrollbar {
    //   display:block;
    //   width: .1rem;
    // }

  }


    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: -5rem;
    //  }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-shit {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    }



    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});

    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7) ;
    display: flex;
    // z-index: 10000000000;
    background-color: rgba($color-white, .5);
     margin: 1rem 3rem 2rem 3rem ;
    //  padding: 2rem auto;
    // border: .5rem red solid;
     height: 14vh;
     overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-3})/5);
    display: flex;
    //z-index: 10000000000;
    background-color: rgba($color-white, .5);
    margin: 1rem 3rem 2rem 3rem ;
    font-size: .2rem !important;
    //padding: 2rem auto;
    //border: .5rem red solid;
    border-radius: 5%;
    height: 20rem;
    overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;

    @include respond(big-desktop){
      width: 17% !important;
      margin: 1rem 1.5rem 2rem 1.5rem !important;
    }
    @include respond(tab-land){
      width: 22% !important;
      margin: 1rem 1rem 2rem 1rem !important;
    }

    @include respond(tab-port){
      width: 30% !important;
       margin: 1rem 1.5rem 2rem 1.5rem !important;
    }
    @include respond(phone){
      width: 45% !important;
      margin: 1rem 2rem 2rem 2rem ;
    }
}
    .col-4-of-5 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-5})/7) + 4 * #{$gutter-horizontal-5});
    }

 }

.row-category-page-sub-products {
    width: 100%;
    height: 100%;
    border-radius: .5%;
    // border: .2rem rgb(0, 38, 255) solid;

  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);
  background-color: rgba($color-white, .35);

    // margin:   0 auto;
      // padding: 5rem auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 3rem;
    // padding-right: 3rem;
    box-shadow: 0 .5rem .5rem rgba($color-black, .15);
    // @include respond(small-phone){
    //   height: 20vh !important;

    // }

    &__height-scroll {
    width: 95%;
    height: 55vh !important;
    // padding-bottom: 10rem;
    padding: 3rem auto !important;
    margin: 10rem auto;

    // z-index: 100;
    // border: .2rem red solid;
    //padding-right: 1rem;
    //padding-left: -1rem;
    //padding:  2rem 5rem 2rem -1rem;
    overflow-y: scroll;
    scrollbar-width: thin;
    // scroll-behavior:smooth;

    backface-visibility: hidden ;
    -webkit-backface-visibility: hidden ;


    //    &::-webkit-scrollbar {
    //   display:block;
    //   width: .1rem;
    // }

}


    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


  //  .col-1-of-2 {
  //    width: calc((100% - #{ $gutter-horizontal-1})/2);

  //   }

    // .col-1-of-3 {
    // width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    // }

    // .col-2-of-3 {
    // width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    // }



    // .col-1-of-4 {
    // width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    // }

    // .col-1-of-4-seller-home {
    // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    // }

    // .col-shit {
    // width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    // }



    // .col-2-of-4 {
    // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    // }

    // .col-3-of-4 {
    // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    // }

    //   .col-1-of-6 {
    // width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    // }

    // .col-5-of-6 {
    // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    // }

    // .col-3-of-7 {
    // width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    // }

    // .col-4-of-7 {
    // width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});

    // }
    .col-1-of-7 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4) ;
    display: flex;
    flex-direction: column;
    // z-index: 10000000000;
    background-color: rgba($color-white, .5);
     margin: 1rem 2rem ;
    //  padding: 2rem auto;
    // border: .5rem red solid;
     height: 19.1vh;
     overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    }

    // .col-6-of-7 {
    // width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    // }

 }

.row-wish-page-products {
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    justify-content: center !important;
    justify-items: center !important;
    border-radius: .5%;
  // background-color: rgba(178,178,223, .15);
  background-color: rgba($color-tertiary-dark, .03);
  padding: 10rem 0 5rem 6rem;
    // padding-top: 5rem;
    // padding-bottom: 5rem;
    // padding-left: 5rem;
    box-shadow: .2rem .2rem .2rem rgba($color-black, .15);

    &__height-scroll {
    width: 100%;
    height: 100vh;
    // padding: 3rem auto !important;
    margin: 10rem 2rem;

    overflow-y: scroll;
    scrollbar-width: thin;
    // scroll-behavior:smooth;

    backface-visibility: hidden ;
    -webkit-backface-visibility: hidden ;

}

  @include respond(phone){
    padding: 10rem 1rem 5rem .1rem !important;
    margin-left: 0 !important;
  }
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }

    .col-1-of-7 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4) ;
    display: flex;
    flex-direction: column;
    // z-index: 10000000000;
    background-color: rgba($color-white, .5);
     margin: 1rem 4rem 3rem 4rem!important;
     padding: 2rem auto;
    // border: .5rem red solid;
     height: 32vh;
     border-radius: 50%;
     overflow: hidden;
    box-shadow: .2rem .2rem .2rem rgba($color-black, .2);

    @include respond(phone){
    // width: calc((100% - 1 * #{$gutter-horizontal-3})/2) ;
    width: calc((100% - 1 * #{$gutter-horizontal-1})/2) ;
    height: 20rem;
      // width: calc((100% )/2);
     margin: 1rem .5rem 3rem 0!important;

      &:not(:last-child){
       margin-right: $gutter-horizontal-2 !important;
     }

    }
    }

    // .col-6-of-7 {
    // width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    // }

 }


.row-background-page-seller-public-products {
    width: 100%;
    border-radius: .5%;
    // border: .2rem red solid;

  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);

    margin: -.3rem auto 3rem auto;
    padding-bottom: 2rem;
    padding-right: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-0})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 4 * #{$gutter-horizontal-0});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    //  margin-top: .5rem;
    //  border: .3rem red solid;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }

 }

.row-seller-all-products-categories-background {
    width: 90%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
     background-color: rgba($color-Greenprimary-light, .02);
    // background-image: linear-gradient(99deg,  rgba(88, 91, 245, 0.4) 0%,
    //    rgba(178, 179, 243, 0.3) 15%,  rgba(67, 70, 241, .3) 50%,
    //     rgba(67, 70, 241, .3) 51%, rgba(178, 179, 243, 0.3) 85%,
    //       rgba(88, 91, 245, 0.4) 100%,   rgba(67, 70, 241, .4) 100%);
    margin: 1rem auto 10rem auto!important;
    //padding-top: -20rem;
    // box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-5;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    background-color: rgba($color-white, 1);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
    padding-left: 1rem;
    color: rgba($color-black, 1);
    background-color: rgba($color-white, .5);
    justify-content: space-around;

     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    // background-color: #232f3e;
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6) ;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    // margin: 2rem 0 0 0;
    padding-top: 1rem;
    padding-left: 1rem;
    color: rgba($color-primary-light, 1);
    font-size: 1.3rem;
    font-weight: 500;
    background-color: rgba($color-white, .5);
    // justify-content: space-around;
     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
     &__height-scroll {
      height: 50%;
      overflow: scroll;
     }
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    //margin: 1rem;
    position: relative;
    transition: all .1s ;
    display: flex;
    flex-direction: row;
    //overflow: hidden;
    //padding-top: -9rem;
    //height: 100%;
    -webkit-transition: all .1s ;
    -moz-transition: all .1s ;
    -ms-transition: all .1s ;
    -o-transition: all .1s ;
  }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }
}



.row-home-categories-background {
    width: 95%;
    height: 45vh;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    //background-image: linear-gradient(99deg,//   rgba(88, 91, 245, 0.4) 0%,//   rgba(178, 179, 243, 0.3) 15%,//   rgba(67, 70, 241, .3) 50%,//   rgba(67, 70, 241, .3) 51%,//  rgba(178, 179, 243, 0.3) 85%,//  rgba(88, 91, 245, 0.4) 100%,//   rgba(67, 70, 241, .4) 100%);
    margin: 2rem auto !important;
    //padding-top: -20rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-5;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    background-color: rgba($color-white, 1);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
    padding-left: 1rem;
    color: rgba($color-black, 1);
    background-color: rgba($color-white, .5);
    justify-content: space-around;

     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    // background-color: #232f3e;
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6) ;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
     height: 100% !important;
    display: flex;
    flex-direction: column;
    // margin: 2rem 0 0 0;
    padding-top: 1rem;
    padding-left: 1rem;
    color: rgba($color-white, .8);
    font-size: 1.3rem;
    font-weight: 500;
    background-color: rgba($color-white, .1);
    // justify-content: space-around;
     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
     overflow-y: scroll;
      scrollbar-width:thin;
      // scrollbar-color: blue !important;
      scrollbar-color: none !important;
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    //margin: 1rem;
    position: relative;
    transition: all .1s ;
    display: flex;
    flex-direction: row;
    //overflow: hidden;
    //padding-top: -9rem;
    //height: 100%;
    -webkit-transition: all .1s ;
    -moz-transition: all .1s ;
    -ms-transition: all .1s ;
    -o-transition: all .1s ;
  }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }
}

.row-seller-home-categories-background {
    width: 85%;
    height: 45vh;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    //background-image: linear-gradient(99deg,//   rgba(88, 91, 245, 0.4) 0%,//   rgba(178, 179, 243, 0.3) 15%,//   rgba(67, 70, 241, .3) 50%,//   rgba(67, 70, 241, .3) 51%,//  rgba(178, 179, 243, 0.3) 85%,//  rgba(88, 91, 245, 0.4) 100%,//   rgba(67, 70, 241, .4) 100%);
    margin: -5rem auto 5rem auto !important;
    // margin-top: -2rem !important;
    //padding-top: -20rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child){
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-5;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    background-color: rgba($color-white, 1);
    }
    .col-1-of-4-seller-home {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
    padding-left: 1rem;
    color: rgba($color-black, 1);
    background-color: rgba($color-white, .5);
    justify-content: space-around;

     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    // background-color: #232f3e;
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6) ;
    //  width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    display: flex;
    flex-direction: column;
    // margin: 2rem 0 0 0;
    padding-top: 1rem;
    padding-left: 1rem;
    color: rgba($color-primary-light, 1);
    font-size: 1.3rem;
    font-weight: 500;
    background-color: rgba($color-white, .5);
    // justify-content: space-around;
     &:last-child{
       margin-bottom: $gutter-horizontal-3;
     }
     &__height-scroll {
      height: 50%;
      overflow: scroll;
     }
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    //margin: 1rem;
    position: relative;
    transition: all .1s ;
    display: flex;
    flex-direction: row;
    //overflow: hidden;
    //padding-top: -9rem;
    //height: 100%;
    -webkit-transition: all .1s ;
    -moz-transition: all .1s ;
    -ms-transition: all .1s ;
    -o-transition: all .1s ;
  }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-5})/7) ;
    }
    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    }
}




.row-reviews-carousel {
    width: 95%;
    // height: 85%;
    border-radius: .5%;
    // border: 1rem red solid;

    background-color:rgba($color-tertiary-dark, .05);

    display: flex;
    justify-content: center;

    margin: -.3rem auto 3rem auto;
    // margin-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }

   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


    .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
      // margin: 0 auto;

    }
}

.row-seller-public-videos-page {
    width: 100%;
    margin: 0 0 -10rem 0;
    // padding-left: 1rem;
    // padding-right: 0;
    padding-top: 0;
    padding-bottom: 12rem;
    // background-color: rgba($color-tertiary-dark, .1);
    // box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


    .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }


    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 1 * #{$gutter-horizontal-3});
    // margin-right: -3rem;

    }
    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    // margin-right: -3rem;

    }
     .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
 }

// specific to this page: seller single podcast upper section col 5 of 6 //
.row-seller-single-podcast-upper-section-col-5-of-6 {
    width: 100%;
    margin: 0 0 -10rem 0;
    padding-left: 1rem;
    padding-right: 0;
    padding-top: 5rem;
    padding-bottom: 12rem;
    // background-color: rgba($color-tertiary-dark, .1);
    // box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


    .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }


    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 1 * #{$gutter-horizontal-3});
    // margin-right: -3rem;

    }
    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    // margin-right: -3rem;

    }
     .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
 }

.row-single-product-first-section {
    width: 94% !important;
    height: fit-content !important;
    margin: 5rem auto;
    display: flex;
    justify-content: space-between;
    //margin: 1rem 0 -11rem 0;
    //margin-right: -2rem;
    //padding-left: 1rem;
    //padding-right: 1rem;
    // padding-top: 1rem;
    padding-bottom: 5rem !important;
    // border-top: 3rem rgba($color-tertiary-dark, .2) solid;
    // border-bottom: 2rem rgba($color-tertiary-dark, .15) solid;
    // border-left: 1.5rem rgba($color-tertiary-dark, .15) solid;
    // border-right: 1.5rem rgba($color-tertiary-dark, .15) solid;
    //background-color: rgba(63, 116, 248, 0.2);
    // background-color: rgba(#304ffe, .05);
    background-color: rgba(white, .8);
    //background-color: rgba($color-Greenprimary-dark, .1);
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .01);

    @include respond(phone){
      width: 100% !important;
      height: fit-content !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 30rem !important;
    }
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


    .col-1-of-4 {
      width: calc(((100% - 3 * #{$gutter-horizontal-2})/4) + 1 * #{$gutter-horizontal-1});
      // height: fit-content;
      margin-top: 5rem;
      // margin-bottom: 2rem !important;
      padding-top: 2rem;

      margin-right: 2rem !important;
      margin-left: .1rem;
      height: 50vh !important;
      // border: .3rem red solid;
      background-color: transparent !important;
      //  box-shadow: 0 .5rem 3rem rgba($color-black, .1);
      @include respond(phone){
        width: 100%;
        margin-right: 2rem !important;
      margin-left: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      }
    }

    .col-1-of-4-color-selected{
      width: calc(((100% - 3 * #{$gutter-horizontal-1})/4) + 1 * #{$gutter-horizontal-1});
      margin-top: 1rem;
      margin-bottom: 2rem !important;
      padding-top: 2rem;

      margin-right: 2rem !important;
      margin-left: 1rem;
      height: 50vh !important;
      // border: .3rem red solid;
      //  box-shadow: 0 .5rem 3rem rgba($color-black, .1);
      @include respond(phone){
        width: 90%;
        margin-left: auto !important;
        margin-right: auto !important;

      }
    }

    .col-1-of-4-color-selected-no-size{
      width: calc(((100% - 3 * #{$gutter-horizontal-1})/4) + 1 * #{$gutter-horizontal-1});
      margin-top:1rem;
      margin-bottom: 2rem !important;
      padding-top: 2rem;

      margin-right: 2rem !important;
      margin-left: 1rem;
      height: 50vh !important;
      // border: .3rem red solid;
       box-shadow: 0 .5rem 3rem rgba($color-black, .1);
       @include respond(phone){
        width: 90%;
       }
    }


    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-4})/4) + 1 * #{$gutter-horizontal-1});
    // margin-right: -3rem;

    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 1 * #{$gutter-horizontal-1})!important;
    // max-height: 93vh !important;
    display: flex !important;
    flex-direction: row !important;
    // flex-wrap: wrap !important;
    justify-content:space-between !important;
    justify-items: center !important;
    //margin: -2rem auto 5rem auto !important;
    margin: 1rem 0 !important;
    //margin-top: 3rem;
    //margin-left: 3rem;
    //margin-right: 1rem !important;
    padding:  0 !important;
    //padding-left: 5rem !important;
    //padding-right: 2rem !important;
    //padding-top: 4rem !important;
    //padding-bottom: 4rem !important;
    //background-color: rgba($color-Greenprimary-light, .15);
    //background-color: rgba(white, .2);
    background-color: rgba($color-white, .05);
    //box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    // border-radius: 50%;
    //padding-left: 15rem;
    //margin-right: 3rem;
    // border: .1rem rgb(0, 38, 255) solid;
    // -webkit-border-radius: 50%;
    // -moz-border-radius: 50%;
    // -ms-border-radius: 50%;
    // -o-border-radius: 50%;

    @include respond(phone){
      width: 100vw !important;
    }
}
     .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
 }

.row-home-seller-page {
    width: 100%;
    // border-radius: .5%;
  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
  rgba(178, 179, 243, 0.3) 85%,
  rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);


    // margin:4rem 2rem -1rem 3rem;
    // padding: 15rem auto ;
    //  border: 1rem green solid;
    // margin-top: -5rem;
    padding-left: auto;
    padding-right: auto;
    padding-top: 8rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
      //  border: 1rem green solid;
     }
   }


    .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
      // margin: 0 auto;


    }


    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 1 * #{$gutter-horizontal-3});
    // margin-right: -3rem;

    }
    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-1});
    // margin-right: -3rem;
    //  border: .1rem red solid;

    }
     .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);


    }
    .col-3-of-5 {
      width: calc(3 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
 }

.row-seller-public-videos-header {
    width: 80%;
    height: 45vh;
    // font-size:.5rem !important;
    // border-radius: .5%;
    // border: .1rem red solid;


  background-image: linear-gradient(99deg,
  rgba(88, 91, 245, 0.4) 0%,
  rgba(178, 179, 243, 0.3) 15%,
  rgba(67, 70, 241, .3) 50%,
  rgba(67, 70, 241, .3) 51%,
 rgba(178, 179, 243, 0.3) 85%,
 rgba(88, 91, 245, 0.4) 100%,
  rgba(67, 70, 241, .4) 100%);
display: flex;
justify-content: center;
justify-items: center !important;

    margin: 2rem 5rem 3rem 16rem;
    // margin-top: 5rem;
    padding-left: 1rem;
    padding-right:0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }

   @include respond(phone){
    width: 80vw !important;
    height: 17vh !important;
    margin: 12rem auto 2rem auto;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


    .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .12);
      text-decoration:none;
      padding:1rem;
      margin: 2rem 1rem auto 1rem;

      // margin-top:-2rem;
      // padding-right: -1rem;
      // margin-right: 15rem;
      @include respond(phone){
        width: 10vw !important;
        height: 5vh !important;
        margin-bottom: 25rem !important;
        margin-top: -1rem !important;
      }

    }


    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-3});
    // margin-right: -3rem;
    text-align:justify;

    @include respond(phone){
      width: 100%;
    }

    }
 }

.row-home-header-categories {
    width: 80%;
    height: 26vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    // scrollbar-width: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // font-size:.5rem !important;
    // border-radius: .5%;
    // border: .1rem red solid;
//  background-color: rgba($color-secondary-dark, .05);

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   rgba(67, 70, 241, .4) 100%);
// display: flex;
justify-content: center;
justify-items: center !important;

    margin: 5rem 0 5rem 18rem;
    // margin-top: 5rem;
    padding: 2rem auto !important;
    padding-left: 10rem;
    // padding-right:0;
    // padding-top: 5rem;
    padding-bottom: 5rem;
    // box-shadow: 1rem .5rem .5rem rgba($color-white, .32);
    // border: .02rem white solid;
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal;
     }
   }

   @include respond(tab-land){
    width: 0 !important;
    height: 0 !important;
   }


    .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-2} )/7);
      height: 14rem;
      display: flex;
      flex-wrap: wrap;
      // background-color: rgba(235, 74, 250, 0.12);
      background-color: rgba(246, 242, 247, 0.15);
      text-align: center;
      // font-size: 2.5rem !important;
      color: rgba($color-primary-light-1, 1) !important;
      // padding-left: 2rem;
      // padding: 15rem auto 0 auto !important;
      // box-shadow: 0 1.5rem 3rem rgba($color-white, .02);
      text-decoration:none;
      // padding:1rem;
      border-radius:  50%;
      //margin: 2rem 1rem auto 1rem;
      margin-top: 4rem;
      //padding-right: -1rem;
      //margin-right: 15rem;
      -webkit-border-radius:  50%;
      -moz-border-radius:  50%;
      -ms-border-radius:  50%;
      -o-border-radius:  50%;

       @include respond(tab-land){
    width: 0 !important;
    height: 0 !important;
   }
}


    // .col-3-of-4 {
    // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-3});
    // // margin-right: -3rem;
    // text-align:justify;

    // }
 }

.row-successful-payment {
    width: 100%;
    border-radius: .5%;


//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   // rgba(69, 81, 248, .4) 8%,
//   // rgba(67, 70, 241, .01) 15%,
//   // rgba($color-white, .5) 15%,
//   // rgba($color-white, .6) 20%,

//   // rgba(67, 70, 241, .4) 35%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   // rgba($color-white, 1) 53%,
//   // rgba($color-white, 1) 57%,
//   // rgba($color-white, .8) 65%,


//   rgba(67, 70, 241, .4) 100%);

background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);


    margin: -5.2rem auto 3rem auto;
    border-top: none;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 10rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .0);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-0})/4) + 1* #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }
    .col-3-of-4--password-update {
      max-width: 50%;
      margin: 25rem 35rem;
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-1})/5);
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }



 }

.row-background-cart-page {
    width: 100%;
    // border-radius: .5%;

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   // rgba(69, 81, 248, .4) 8%,
//   // rgba(67, 70, 241, .01) 15%,
//   // rgba($color-white, .5) 15%,
//   // rgba($color-white, .6) 20%,

//   // rgba(67, 70, 241, .4) 35%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   // rgba($color-white, 1) 53%,
//   // rgba($color-white, 1) 57%,
//   // rgba($color-white, .8) 65%,
//   rgba(67, 70, 241, .4) 100%);

background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

// background-color:rgba(#f0efef, 1);

    margin: 0;
    // border-top: .5rem red solid;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    padding-left: 3rem;
    padding-right: 2rem;
    margin: 10rem 0;
    // border: .5rem red solid;
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    padding-left: 0;
    padding-right: 0;
    margin: 10rem 0;
    // border: .5rem red solid;
    }

     .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      padding-left: .1rem;
      padding-right: 2rem;
      margin: 10rem 0;
      // border: .5rem red solid;
      @include respond(phone){
        width: 100%;
        margin-top: 1rem;
      }
    }

    .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
        padding-left: 0;
      padding-right: 0;
      margin: 10rem 0;
      // border: .5rem red solid;

      @include respond(phone){
        width: 100%;
      }
    }

    .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
      // width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      padding-left: .1rem;
      padding-right: 2rem;
      margin: 10rem 0;

      // border: .5rem red solid;
      @include respond(phone){
        width: 100%;
        margin-top: 1rem;
      }

    }

 }

.row-background-page-profile {
    width: 100%;
    border-radius: .5%;

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.4) 0%,
//   // rgba(69, 81, 248, .4) 8%,
//   // rgba(67, 70, 241, .01) 15%,
//   // rgba($color-white, .5) 15%,
//   // rgba($color-white, .6) 20%,

//   // rgba(67, 70, 241, .4) 35%,
//   rgba(178, 179, 243, 0.3) 15%,
//   rgba(67, 70, 241, .3) 50%,
//   rgba(67, 70, 241, .3) 51%,
//  rgba(178, 179, 243, 0.3) 85%,
//  rgba(88, 91, 245, 0.4) 100%,
//   // rgba($color-white, 1) 53%,
//   // rgba($color-white, 1) 57%,
//   // rgba($color-white, .8) 65%,


//   rgba(67, 70, 241, .4) 100%);

  background-image: linear-gradient(99deg,
  rgba(196, 198, 249, 0.4) 0%,
  rgba(193, 194, 242, 0.3) 15%,
  rgba(188, 189, 248, 0.3) 50%,
  rgba(192, 193, 250, 0.3) 51%,
  rgba(192, 193, 239, 0.3) 85%,
  rgba(193, 194, 243, 0.4) 100%,
  rgba(194, 195, 246, 0.4) 100%);

  // position: absolute;


// background-color: rgba($color-tertiary-dark, .05);
// border: red solid .2rem;
    // margin: -5.2rem auto 3rem auto;
    // margin-top:5rem;
    margin-bottom: 3rem;
    // padding-left: 1rem;
    // padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
  //   &:not(:last-child) {
  //     margin-bottom: $gutter-vertical;
  //  }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-top: 5rem;
      &:not(:last-child){
        margin-right: $gutter-horizontal-1;
      }
    }

    .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      display: flex;
      flex-direction: column;
       &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
    }




    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-2})/4);
    // margin-top: 40rem;
    @include respond(phone){
      width: 95%;
      margin: 5rem auto 5rem 2.5%;
      padding: 2rem auto;
      height: 20rem;
    }
    }

    .col-1-of-4-order-summary-column {
    width: calc((100% - 9 * #{$gutter-horizontal-2})/4);
    margin-top: 20rem !important;
    background-color: rgba($color-white, .8);
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    padding-bottom: 5rem !important;

    @include respond(phone){
      width: 85%;
      margin: 10rem auto 10rem 8rem !important;
      padding: 2rem 5rem !important;
      // background-color: white;
      // height: 20rem;
    }
    }
    .col-1-of-4-wishlist {
    width: calc((100% - 10 * #{$gutter-horizontal-1})/4);
    @include respond(phone){
    width: calc((100% - 1 * #{$gutter-horizontal-1})/3) !important;


    }
    // margin-top: 40rem;
    }

    .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
      display: block;
      margin-top: 15rem;
      &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }

     @include respond(phone){
      margin-top: 5rem !important;
      width: 85%;
      margin: 2rem auto 2rem 8rem !important;
     }
    }

    .col-3-of-4 {
      width: calc( 3 * ((100% - 2 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
      margin-top:10rem;
      padding-left: 2rem;
      &:not(:last-child){
       margin-right: $gutter-horizontal-1;
      }

      @include respond(phone){
        width: 95%;
      }
    }
    .col-3-of-4-wishlist {
      width: calc( 3 * ((100% - 2 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
      margin-top:10rem;
      padding-left: 2rem;
      &:not(:last-child){
       margin-right: $gutter-horizontal-1;
      }

      @include respond(phone){
      width: calc( 2 * ((100% - 2 * #{$gutter-horizontal-1})/3) + 1 * #{$gutter-horizontal-1});
      &:not(:last-child){
       margin-right: 0;
       padding: 1rem !important;
      //  border: red solid .6rem;
      }

      }
    }

    .col-3-of-4-shipping-address {
      width: calc( 3 * ((90% - 2 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
      margin:-5rem 10% 57rem 25%;
      padding-left: 2rem;
      &:not(:last-child){
       margin-right: $gutter-horizontal-1;
      }
      @include respond(phone){
        width: 100%;
        margin: 5rem auto 5rem auto;
        padding-left: 0;
        padding-right: 0;
        // border: .2rem red solid;
        // background-color: rgba($color-white, .5);
      }
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-0})/6)  + 1 * #{$gutter-horizontal-0});
    }

    .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 4 * #{$gutter-horizontal-0});
      padding: 5rem 0rem;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 2 * #{$gutter-horizontal-3})/7) + 2 * #{$gutter-horizontal-3});

    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 3 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }



 }

 .row-background-seller-profile {
    width: 100%;
    // border: .2rem red solid !important;
    border-radius: .5%;

//   background-image: linear-gradient(99deg,
//   rgba(88, 91, 245, 0.2) 0%,
//   // rgba(69, 81, 248, .4) 8%,
//   // rgba(67, 70, 241, .01) 15%,
//   // rgba($color-white, .5) 15%,
//   // rgba($color-white, .6) 20%,

//   // rgba(67, 70, 241, .4) 35%,
//   rgba(178, 179, 243, 0.1) 15%,
//   rgba(67, 70, 241, .1) 50%,
//   rgba(67, 70, 241, .1) 51%,
//  rgba(178, 179, 243, 0.1) 85%,
//  rgba(88, 91, 245, 0.2) 100%,
//   // rgba($color-white, 1) 53%,
//   // rgba($color-white, 1) 57%,
//   // rgba($color-white, .8) 65%,


//   rgba(67, 70, 241, .2) 100%);
  background-color: rgba($color-tertiary-dark, .03);

    // margin: -5.2rem auto 3rem auto;
    margin-top: 1.2rem;
    margin-bottom: 5rem !important;
    padding-left: 5rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 5rem !important;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }

   @include respond(phone){
    padding-left: 1rem;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-top: 5rem;
      &:not(:last-child){
        margin-right: $gutter-horizontal-1;
      }
    }

    .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      display: flex;
      flex-direction: column;
       &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
    }




    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-0})/4) + #{$gutter-horizontal-2});
      display: block;
      &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
    }

    .col-3-of-4 {

      width: calc( 3 * ((100% - 2 * #{$gutter-horizontal-0})/4) + 3 * #{$gutter-horizontal-0});
      padding-left: 5rem;
      &:not(:last-child){
       margin-right: $gutter-horizontal-0;
      }
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-0})/6)  + 1 * #{$gutter-horizontal-0});
    }

    .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 4 * #{$gutter-horizontal-0});
      padding: 5rem 0rem;
    }

    .col-3-of-7 {
      width: calc(((100% - 6 * #{$gutter-horizontal-2})/7) + 4 * #{$gutter-horizontal-2});
      // border: .3rem red solid;
      // height: 15rem !important;
      margin: 3rem auto auto auto !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      @include respond(phone){
      width: calc(((100% - 6 * #{$gutter-horizontal-1})/7) + 4 * #{$gutter-horizontal-1});
        font-size: 3rem;
        margin: 3rem -1rem 3rem 0 !important;
      }

    }
    .col-4-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    margin: auto 2rem 10rem 2rem;
    // border: .2rem red solid;

    @include respond(phone){
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    // font-size: 5rem;
    margin-left: -2rem;
    margin-right: 1rem !important;

    }
    }



 }
 .row-background-seller-view-profile {
    width: 95%;
    border-radius: .5%;
    background-color: rgba($color-white, .4);

    // background-image: linear-gradient(99deg,
    // rgba(88, 91, 245, 0.2) 0%,
    // rgba(178, 179, 243, 0.1) 15%,
    // rgba(67, 70, 241, .1) 50%,
    // rgba(67, 70, 241, .1) 51%,
    // rgba(178, 179, 243, 0.1) 85%,
    // rgba(88, 91, 245, 0.2) 100%,
    // rgba(67, 70, 241, .2) 100%);
    margin: 5rem 2rem ;
    // padding: 2rem auto;
    // margin-top: 1rem;
    // margin-bottom: 3rem;
    padding: 2rem 5rem;
    // padding-left: 5rem;
    // padding-right: 5rem;
    // padding-top: 1rem;
    // padding-bottom: 5rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .02);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-top: 5rem;
      &:not(:last-child){
        margin-right: $gutter-horizontal-1;
      }
    }

    .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      display: flex;
      flex-direction: column;
       &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
    }




    .col-1-of-4 {
    width: calc((100% - 9 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-0})/4) + #{$gutter-horizontal-2});
      display: block;
      &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
    }

    .col-3-of-4 {

      width: calc( 3 * ((100% - 2 * #{$gutter-horizontal-0})/4) + 3 * #{$gutter-horizontal-0});
      padding-left: 5rem;
      &:not(:last-child){
       margin-right: $gutter-horizontal-0;
      }
    }

      .col-1-of-6 {
    width: calc(((100% - 5 * #{$gutter-horizontal-0})/6)  + 1 * #{$gutter-horizontal-0});
    }

    .col-5-of-6 {
      width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 4 * #{$gutter-horizontal-0});
      padding: 5rem 0rem;
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 1 * #{$gutter-horizontal-1});
    }



 }


.row-admin-create-video {
    width: 95%;
    border-radius: .5%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

  background-image: linear-gradient(110deg,
  rgba(69, 81, 248,  1) 0%,
  rgba(69, 81, 248, .4) 8%,
  rgba(67, 70, 241, .01) 15%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba(67, 70, 241, .4) 40%,
  rgba($color-white, 1) 51%,
  rgba($color-white, 1) 53%,
  rgba($color-white, 1) 57%,
  rgba($color-white, .8) 65%,


  rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 0 auto 3rem auto;
    // margin-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }



 }

.row-seller-order {
    width: 100%;
    border-radius: 1%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    //  background-image: linear-gradient(125deg,
    // rgba($color-primary-light-3, .5) 0%,
    // rgb(253, 253, 253) 35%,
    // rgb(253, 253, 253) 45%,
    // rgb(253, 253, 253) 65%,
    // rgb(253, 253, 253) 85%,
    //  rgba($color-primary-light-3, .5) 100%);

    // background-color: rgb(222, 228, 241);
    margin: 2rem auto;
    //  margin-right: 5rem;
    padding-left: 4rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

  &__info {
    //border: 1rem red solid;
    background-color: rgba($color-white, .5 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem 3rem rgba($color-black, .05);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-admin-table {
    width: 70vw !important;
    border-radius: 1%;
    margin: 2rem auto !important;
    // padding: 2rem auto;
    padding-left: .1rem;
    padding-right: .1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-tertiary-light, .05 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-admin-dashboard-table {
    width: 65vw !important;
    border-radius: 1%;
    margin: 2rem auto 3rem -1rem !important;
    // padding: 2rem auto;
    // padding-left: 1rem;
    // padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-tertiary-light, .05 );
    font-size: 1.1rem;
    font-weight: 700;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-seller-general-coupon-table {
    width: 55vw !important;
    border-radius: 1%;
    margin: 2rem auto 3rem -1rem !important;
    // padding: 2rem auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-tertiary-light, .05 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-admin-general-coupon-table {
    width: 55vw !important;
    border-radius: 1%;
    margin: 2rem auto 3rem -1rem !important;
    // padding: 2rem auto;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-tertiary-light, .05 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-customer-history-table {
    width: 65vw !important;
    border-radius: 1%;
    margin: 2rem -2rem !important;
    // padding: 2rem auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);

    @include respond(phone){
      width: 81vw !important;
      margin: 2rem -2rem !important;
    }
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-black, .01 );
    font-size: 1.1rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-seller-detail-order-list-table {
    width: 70vw !important;
    border-radius: 1%;
    margin: 2rem auto !important;
    // padding: 2rem auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: .2rem !important;
    box-shadow: 1 1.5rem 3rem rgba($color-black, 1) !important;
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-tertiary-light, .05 );
    font-size: 1.1rem;
    font-weight: 700;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-seller-public-press-release-table {
    width: 100%;
    border-radius: 1%;
    margin: -2rem auto 2rem auto !important;
    padding: 3rem 6rem;
    // padding-left: 2rem;
    // padding-right: 2rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;
   }


  &__info {

    background-color: rgba($color-white, .5 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }

  &__customer-pictures {
    width: 85%;
    height: fit-content;
    margin: 5rem 10rem 5rem 10rem;
    background-color: rgba($color-white, .7 );
    text-align: justify;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .2rem;
    color: rgba($color-primary-light, .7)  ;
    border-radius: 1%;
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    &--font-color{
       color: rgba($color-primary-light, .7)  ;
       margin-left: .5rem;
    }
  }
 }

.row-admin-create-product {
    width: 70%;
    border-radius: 1%;
    margin: 8rem auto;
    //  margin-right: 5rem;
    // padding-left: 4rem;
    // padding-right: 2rem;
    // padding-top: 4rem;
    // padding-bottom: 2rem;
    // padding: 2rem 1rem 2rem 1rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;


   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }




  &__info {
    //border: 1rem red solid;
    // width: fit-content;
    padding: 3rem 1rem 1rem 1rem;
    background-color: rgba($color-tertiary-dark, .02);
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    border: .1rem rgba($color-black, .1) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .05);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

.row-customer-write-review {
    width: 80%;
    border-radius: 1%;
    margin: 2rem auto;
    //  margin-right: 5rem;
    // padding-left: 4rem;
    // padding-right: 2rem;
    // padding-top: 4rem;
    // padding-bottom: 2rem;
    padding: 10rem 3rem 3rem 3rem;
    background-color: rgba($color-secondary-dark, .02 );
    box-shadow: 0 .5rem 3rem rgba($color-black, .08);

    @include respond(phone){
      width: 80vw;
    }
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;


   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }




  &__info {
    // border: .1rem rgba($color-tertiary-dark, .2) solid;
    background-color: rgba($color-tertiary-dark, .04 );
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(7, 5, 139)  ;
    border-radius: 1%;
    box-shadow: 0 .3rem .3rem rgba($color-black, .05);
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
  }
 }

 .row-all-products {
    width: 98%;
    border-radius: .5%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

  background-image: linear-gradient(110deg,
  rgba(69, 81, 248,  1) 0%,
  rgba(69, 81, 248, .4) 8%,
  rgba(67, 70, 241, .01) 15%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba(67, 70, 241, .4) 40%,
  rgba($color-white, 1) 51%,
  rgba($color-white, 1) 53%,
  rgba($color-white, 1) 57%,
  rgba($color-white, .8) 65%,


  rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 0 auto 3rem auto;
    // margin-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

      .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }



 }

 .row-customer-images {
    width: 99%;
    border-radius: .5%;


    // border: .2rem white solid;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

  // background-image: linear-gradient(110deg,
  // rgba(69, 81, 248,  1) 0%,
  // rgba(69, 81, 248, .4) 8%,
  // rgba(67, 70, 241, .01) 15%,
  // rgba($color-white, .5) 15%,
  // rgba($color-white, .6) 20%,

  // rgba(67, 70, 241, .4) 40%,
  // rgba($color-white, 1) 51%,
  // rgba($color-white, 1) 53%,
  // rgba($color-white, 1) 57%,
  // rgba($color-white, .8) 65%,


  // rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    // margin: 0 2rem !important;
    margin-left: 1.4rem;
    margin-right: .1rem;
    margin-bottom: 7rem;

    // margin-top: 5rem;
    // padding-left: 2rem;
    // padding-right: 2rem;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }


  //  .col-1-of-2 {
  //    width: calc((100% - #{ $gutter-horizontal-1})/2);

  //   }

    .col-1-of-3 {
    width: calc((99.5% - 2 * #{$gutter-horizontal-1})/3);
     position: relative;
     margin: 2rem;
    // border: .1rem red solid;
    }

    // .col-2-of-3 {
    // width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    // }



    // .col-1-of-4 {
    // width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    // }

    // .col-2-of-4 {
    // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    // }

    // .col-3-of-4 {
    // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    // }

    //   .col-1-of-6 {
    // width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    // }

    // .col-5-of-6 {
    // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    // }

    // .col-3-of-7 {
    // width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    // }
    // .col-4-of-7 {
    // width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    // }



 }

 .row-singele-product-inside {
    width: 80vw;
    border-radius: 1%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

       background-image: linear-gradient(125deg,
    rgb(128, 167, 247) 0%,
    rgb(250, 250, 250) 50%,
    rgb(253, 253, 253) 51%,
    rgba(247, 172, 128,.5)  90%);
    margin: 5rem auto;
    // margin-top: 5rem;
    padding-left: 4rem;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

.row-user-history-orders {
    width: 100%;
    border-radius: .5%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

       background-image: linear-gradient(110deg,
  rgba($color-primary-light-3, 1) 0%,
  rgba($color-primary-light-3, .1) 8%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba($color-primary-light-3, .5) 40%,
  rgba($color-white, 1) 51%,
  rgba($color-white, 1) 53%,
  rgba($color-white, 1) 57%,
  rgba($color-white, .8) 65%,


  rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 0  ;
    // margin-top: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }
 }

 .row-seller-list-products-page {
    width: 98vw;
    border-radius: .5%;

    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    background-image: linear-gradient(110deg,
    rgba($color-primary-light-3, 1) 0%,
    rgba($color-primary-light-3, .1) 8%,
    rgba($color-white, .5) 15%,
    rgba($color-white, .6) 20%,

    rgba($color-primary-light-3, .5) 40%,
    rgba($color-white, 1) 51%,
    rgba($color-white, 1) 53%,
    rgba($color-white, 1) 57%,
    rgba($color-white, .8) 65%,


    rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 0;
    // margin-top: 5rem;
    padding-left: 0;
    padding-right:  0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
    .col-4-of-5 {
      width: calc(4 * ((100% - 4 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

    .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/6);
    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }
 }


  .row-seller-product-list-outside {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-end;
    justify-items: center;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

     background-image: linear-gradient(125deg,
    rgba(95, 148, 253, .1) 0%,
    rgba(250, 250, 250, .9) 50%,
    rgba(253, 253, 253, .1) 51%,
    rgba(101, 152, 253, .1)  90%);
    margin: 0 auto ;
    // margin-left: .4rem;
    // padding-left: 1rem;
    // padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-0})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

  .row-seller-product-list-inside {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    // height: 95vh;
  //   background-image: linear-gradient(125deg,
  //   rgba(67, 108, 197, .8) 0%,
  //   rgba(67, 108, 197, .8) 30%,
  //  rgb(251, 253, 255) 51%,
  //   rgba(67, 108, 197, .8)  90%);

     background-image: linear-gradient(125deg,
    rgba(95, 148, 253, .1) 0%,
    rgba(250, 250, 250, .9) 50%,
    rgba(253, 253, 253, .1) 51%,
    rgba(101, 152, 253, .1)  90%);
    margin: 5rem auto ;
    margin-left: 0rem;
    padding-left: 4rem;
    // padding-right: 0rem;
    padding-top: 6rem;
    padding-bottom: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    margin-bottom: 5rem;
    // margin-left: .5rem;
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }
  .row-admin-product-list-inside {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    // height: 95vh;
  //   background-image: linear-gradient(125deg,
  //   rgba(67, 108, 197, .8) 0%,
  //   rgba(67, 108, 197, .8) 30%,
  //  rgb(251, 253, 255) 51%,
  //   rgba(67, 108, 197, .8)  90%);

    background-color: rgba($color-white, .01);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

    //  background-image: linear-gradient(125deg,
    // rgba(95, 148, 253, .1) 0%,
    // rgba(250, 250, 250, .9) 50%,
    // rgba(253, 253, 253, .1) 51%,
    // rgba(101, 152, 253, .1)  90%);
    margin: 15rem auto 5rem auto;
    // margin-left: 0rem;
    padding-left: 4rem;
    // padding-right: 0rem;
    padding-top: 6rem;
    padding-bottom: 4rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    margin-bottom: 5rem;
    // margin-left: .5rem;
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-all-videos-editDelete-page {
    width: 100%;
    // border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    height: fit-content;

    background-color: rgba($color-white, .3);

    margin: 10rem 0 !important;
    padding: 10rem 1rem 10rem 0 !important;
    // box-shadow: 0 .5rem 3rem rgba($color-tertiary-dark .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3 !important;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin: 1rem auto;
      background-color: transparent;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      // margin-bottom: 5rem;
      height: fit-content !important;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    margin: 2rem .1rem !important;
    margin-bottom: 4rem;
    height: fit-content;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-2})/5);
    }

    .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

 .row-seller-all-podcasts-edit-delete-page {
    width: 100%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    justify-items: center;
    height: fit-content;

    background-color: rgba($color-white, .2);

    margin: 20rem auto 5rem auto !important;
    // border: .1rem red solid;
    // margin-left: 0rem;
    // padding-left: auto;
    // padding-right: auto;
    // padding-bottom: 4rem;
    padding: 10rem 3rem  !important;
    // padding-top: 15rem;
    box-shadow: 0 .5rem 3rem rgba($color-tertiary-dark, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4 !important;
     }

   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      margin-bottom: 5rem;
      height: 25rem;
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-4})/4);
    // margin: 0 1rem;
    margin-bottom: 2rem;
    height: fit-content;

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-0})/5);
    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }

 }

 .row-page-title {
    width: 65%;
    border-radius: .5%;

    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    background-image: linear-gradient(110deg,
    rgba($color-primary-light-3, 1) 0%,
    rgba($color-primary-light-3, .1) 8%,
    rgba($color-white, .5) 15%,
    rgba($color-white, .6) 20%,

    rgba($color-primary-light-3, .5) 40%,
    rgba($color-white, 1) 51%,
    rgba($color-white, 1) 53%,
    rgba($color-white, 1) 57%,
    rgba($color-white, .8) 65%,


    rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 5rem auto;
    // margin-bottom: 0rem;
    padding-left: 0;
    padding-right:  0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-4;
    //  }
   }
 }

 .row-page-title-2 {
    width: 45%;
    border-radius: .5%;

    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    background-image: linear-gradient(110deg,

    rgba($color-white, .5) 15%,
    rgba($color-white, .6) 20%,


    rgba($color-white, 1) 51%,
    rgba($color-white, 1) 53%,
    rgba($color-white, 1) 57%,
    rgba($color-white, .8) 65%,


    rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 5rem auto 5rem 4rem;
    // margin-bottom: 0rem;
    padding-left: 5rem;
    padding-right:  0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 1rem 1rem .5rem rgba($color-black, .05);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: $gutter-horizontal-4;
    //  }
   }
 }

.row-product-card {
    width: 100%;
    border-radius: .5%;
    color: rgba($color-primary-light-3, 1);
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    // background-image: linear-gradient(110deg,
    // rgba($color-primary-light-3, 1) 0%,
    // rgba($color-primary-light-3, .1) 8%,
    // rgba($color-white, .5) 15%,
    // rgba($color-white, .6) 20%,

    // rgba($color-primary-light-3, .5) 40%,
    // rgba($color-white, 1) 51%,
    // rgba($color-white, 1) 53%,
    // rgba($color-white, 1) 57%,
    // rgba($color-white, .8) 65%,


    // rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 1rem auto;
    // margin-top: 5rem;
    padding-left: 3rem;
    padding-right:  3rem;
    padding-top: 2rem;
    padding-bottom: .5rem;
    box-shadow: 0 .5rem 1rem rgba($color-primary-light, .2);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-4;
     }
   }
 }


.row-profile {
    max-width: 95vw;
    background-color: $color-grey-light-1;
    margin: 0 auto ;
    padding: 3rem 2rem;

    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

     [class^="col-"] {

     float: left;


     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
    }


     .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-2})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-2})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-2})/3) + #{$gutter-horizontal-2});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }
    .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal-2})/5);
    }

      .col-2-of-5 {
      width: calc(2 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 1 * #{$gutter-horizontal-2});

    }
      .col-3-of-5 {
      width: calc(3 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 2 * #{$gutter-horizontal-2});

    }
      .col-4-of-5 {
      width: calc(4 * ((100% - 5 * #{$gutter-horizontal-2})/5) + 3 * #{$gutter-horizontal-2});

    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-2})/4) + #{$gutter-horizontal-2});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }
    .col-1-of-6 {
    width: calc((100% - 6 * #{$gutter-horizontal-1})/6);
    }

    .col-2-of-6 {
    width: calc( 2 * ((100% - 6 * #{$gutter-horizontal-1})/6) + 1 * #{$gutter-horizontal-2});
    border: .1rem solid rgb(216, 216, 243);
    padding: 3rem;
    margin-top: 4rem;

    }
    .col-3-of-6 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/6) + 2 * #{$gutter-horizontal-2});

    }

    .col-5-of-6 {
    width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-2})/6) + 4 * #{$gutter-horizontal-2});
    }
    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    }

    .col-6-of-7 {
    width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-2})/7) + 6 * #{$gutter-horizontal-2});
    }

  }

.row-profile-2 {
    width: 98%;
    border-radius: .5%;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

  background-image: linear-gradient(110deg,
  rgba($color-primary-light-3, 1) 0%,
  rgba($color-primary-light-3, .1) 8%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba($color-primary-light-3, .5) 40%,
  rgba($color-white, 1) 51%,
  rgba($color-white, 1) 53%,
  rgba($color-white, 1) 57%,
 rgba($color-primary-light-3, .5) 65%,


  rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 0 auto 3rem auto;
    // margin-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }


    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-1})/5);
    }

    .col-4-of-5 {
    width: calc( 4 * ((100% - 4 * #{$gutter-horizontal-1})/5) + 1 * #{$gutter-horizontal-1});
    }


    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    }



 }

.row-profile-3 {
    // width: 100%;
    border-radius: .5%;
    // display: flex;
    justify-content: center;
    justify-items: center;
    // height: 95vh;
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

  background-image: linear-gradient(110deg,
  rgba($color-primary-light-3, 1) 0%,
  rgba($color-primary-light-3, .1) 8%,
  rgba($color-white, .5) 15%,
  rgba($color-white, .6) 20%,

  rgba($color-primary-light-3, .5) 40%,
  rgba($color-white, 1) 51%,
  rgba($color-white, 1) 53%,
  rgba($color-white, 1) 57%,
 rgba($color-primary-light-3, .5) 65%,


  rgba($color-white, .1) 90%);

    //  background-image: linear-gradient(125deg,
    // rgb(95, 148, 253) 0%,
    // rgb(250, 250, 250) 50%,
    // rgb(253, 253, 253) 51%,
    // rgb(101, 152, 253)  90%);
    margin: 5rem auto 3rem auto;
    // margin-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 3rem rgba($color-black, .03);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);
     display: flex;
    }

    .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      padding-left: 2rem;
    padding-top: 5rem;
    display: flex;
    }

    .col-2-of-3 {
      width: calc( 2 * ((100% - 2 * #{$gutter-horizontal-1})/3) + 2 * #{$gutter-horizontal-1});
  padding-left: 2rem;
    padding-top: 5rem;
    // margin-left: 5rem;
    // display: flex;
    // justify-content: space-between;
    // border: 1rem red solid;
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }


    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-0})/4) + 1* #{$gutter-horizontal-0});
    padding-left: 2rem;
    padding-top: 5rem;
    // margin-left: rem;
    display: flex;
    justify-content: space-between;
    border: 1rem red solid;
    }

    .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-0})/4) + 3 * #{$gutter-horizontal-1});
      padding-left: 2rem;
      padding-top: 5rem;
      margin-top: 5rem;
      display: flex;
      justify-content: space-between;
      // border: 1rem red solid;
    }

    .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal-1})/5);
    display: flex;
    }

    .col-4-of-5 {
    width: calc( 4 * ((100% - 4 * #{$gutter-horizontal-1})/5) + 3 * #{$gutter-horizontal-1});
    display: flex;
    justify-content: flex-end;
    }


    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    display: flex;
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    display: flex;
    }



 }

 .row-upload-file {
    width: 75%;
    border-radius: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    background-color: rgba($color-tertiary-dark, .2);
    // background-image: linear-gradient(125deg,
    // rgba($color-white, 1) 0%,
    // rgba($color-white, .45) 50%,
    // rgba(163, 187, 236, 0.842) 51%,
    // rgba(67, 108, 197, .8)  90%);

    //  background-image: linear-gradient(125deg,
    // rgba(95, 148, 253, .1) 0%,
    // rgba(250, 250, 250, .9) 50%,
    // rgba(253, 253, 253, .1) 51%,
    // rgba(101, 152, 253, .1)  90%);
    margin: 0 auto;
    // border: .1rem rgba($color-black, .08) solid;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);
    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-0;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2 * ((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-0});

    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-0});

    }

  &--2{
    margin: 3rem 1rem 0 8rem;
    background-color: rgba($color-tertiary-dark, .2);
    // margin-top: -9rem;
  }


 }


.row-create-category-1 {
      width: 65vw;
      height: fit-content;
      // margin-top: -15rem;
      padding: 3rem;
      border-radius: 1%;
      border: .05rem rgba($color-black, .05) solid;
      //background-color: rgba($color-tertiary-light, .2);
      // background-image: linear-gradient(  to  left ,    $color-primary-light,    $color-primary-light-3);
      //padding-top: .1rem;
      // transform: skewY(5deg) ;
//           & > * {
//     transform: skewY(5deg);
// }
      box-shadow: .2rem .3rem .5rem rgba($color-black, .1);
      //background-color: rgba(248, 239, 187, 0.5);
      //background-color: #c3f0e291;
        // rgba($color-primary-light, 1),  //           rgba($color-primary-dark, 1)):;
      //background-blend-mode:screen;
      //background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .8), rgba($color-tertiary-dark,.8),  //   url(../image/hero.jpg));
      //background-image:  // linear-gradient(  //   to right,  //   rgba($color-tertiary-light, .5),  //   rgba($color-tertiary-dark, .5)),  //   url(../image/hero.jpg);
  //   margin: 5rem auto 10rem auto;
  // padding: 1rem ;
  //
      margin:0 auto;


      &:not(:last-child){
      margin-bottom: $gutter-vertical;
      }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }


   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    // margin-left: 1rem;

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-2});
    }


 }

 .row-create-category {
      width: 80%;
      display: block;
      // flex-wrap: wrap;
      justify-content: center;
      justify-items:flex-start;
      background-color: rgba($color-tertiary-dark, .04);
      // height: fit-content;
      // margin-top: -15rem;
      padding-left: 2rem;
      padding-bottom: 3rem;
      padding-top: 2rem;
      border-radius: 1%;
      border: .3rem rgba($color-white, .3) solid;
      //background-color: rgba($color-tertiary-light, .2);
      // background-image: linear-gradient(  to  left ,    $color-primary-light,    $color-primary-light-3);
      //padding-top: .1rem;
      // transform: skewY(5deg) ;
//           & > * {
//     transform: skewY(5deg);
// }
      box-shadow: 0 .5rem .5rem rgba($color-black, .1);
      //background-color: rgba(248, 239, 187, 0.5);
      //background-color: #c3f0e291;
        // rgba($color-primary-light, 1),  //           rgba($color-primary-dark, 1)):;
      //background-blend-mode:screen;
      //background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .8), rgba($color-tertiary-dark,.8),  //   url(../image/hero.jpg));
      //background-image:  // linear-gradient(  //   to right,  //   rgba($color-tertiary-light, .5),  //   rgba($color-tertiary-dark, .5)),  //   url(../image/hero.jpg);
  //   margin: 5rem auto 10rem auto;
  // padding: 1rem ;
  //
      margin:0 auto 5rem auto;


    &:not(:last-child){
    margin-bottom: $gutter-vertical;
    }
   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-2;
     }
   }


   .col-1-of-2 {
     width: calc((100% - 1 *#{ $gutter-horizontal-1})/2);

    }

    .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
    }

    .col-2-of-3 {
    width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
    }



    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

    .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
    }

    .col-1-of-7 {
    width: calc((100% - 6 * #{$gutter-horizontal-2})/7);
    // margin-left: 1rem;

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-2});
    }

    &__categories {
      width: 100%;
      border-radius: 1%;

      // height: 95vh;
      // background-image: linear-gradient(125deg,
      // rgba($color-white, 1) 0%,
      // rgba($color-white, .45) 50%,
      // rgba(163, 187, 236, 0.842) 51%,
      // rgba(67, 108, 197, .8)  90%);

      //  background-image: linear-gradient(125deg,
      // rgba($color-primary-light-3, .5) 0%,
      // rgb(253, 253, 253) 35%,
      // rgb(253, 253, 253) 45%,
      // rgb(253, 253, 253) 65%,
      // rgb(253, 253, 253) 85%,
      //  rgba($color-primary-light-3, .5) 100%);

      // background-color: rgb(222, 228, 241);
      // margin: 2rem auto;
      // margin-top: 6rem;
      // margin-bottom: 5rem;

      //  margin-right: 5rem;
      padding-left: 4rem;
      padding-right: 2rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      box-shadow: 0 .5rem 3rem rgba($color-black, .08);
    //   &:not(:last-child) {
    //     margin-bottom: $gutter-vertical;
    //  }
    //   &:first-child {
    //     margin-top: $gutter-vertical;
    //  }
    @include clearfix;

    // select attribute: [ .... ]
    // className^ means all className starts with / className* means contain ..
    // classname$ means any ends with..
    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-0;
      }
    }


    .col-1-of-2 {
      width: calc((100% - #{ $gutter-horizontal-1})/2);

      }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }



      .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
      }

      .col-2-of-4 {
      width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
      }

      .col-3-of-4 {
      width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-1})/4) + 2 * #{$gutter-horizontal-1});
      }

      .col-3-of-7 {
      width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
      }
      .col-4-of-7 {
      width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
      }

      &__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-items: flex-start;
        margin-top: 2rem;
        padding-left: 2rem;
        padding-right: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
        //border: 1rem red solid;
        background-color: rgba($color-white, .3);
        font-size: 1.6rem;
        font-weight: 300;
        color: rgb(7, 5, 139)  ;
        border-radius: 1%;
        box-shadow: 0 .5rem 3rem rgba($color-black, .1);
        -webkit-border-radius: 1%;
        -moz-border-radius: 1%;
        -ms-border-radius: 1%;
        -o-border-radius: 1%;
      }

      &--outlined {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
  }


 }

 .row-wish-list {
      width: 98%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      // justify-content: space-around;
      justify-items:center;
      background-color: rgba($color-white, .3);
      padding-left: 3rem;
      padding-right:-5rem !important;
      padding-top: 8rem;
      padding-bottom: 8rem;
      // padding: 8rem auto;
      // border-radius: 1%;
      border: .3rem rgba($color-white, .3) solid;
      box-shadow: 0 .5rem .5rem rgba($color-black, .0);
      margin: 22rem auto 0 auto;


    &:not(:last-child){
    margin-bottom: $gutter-vertical;
      }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-2;
      }
   }


    .col-1-of-2 {
     width: calc((100% - 1 *#{ $gutter-horizontal-1})/2);
    //  height:15rem!important;

    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

    .col-1-of-3 {
     width: calc((100% - 2 *#{ $gutter-horizontal-1})/3) ;
     min-height: 23rem;
     max-height: 23rem;
     object-fit: cover;
     overflow: hidden;
       &__info {
      // max-width:fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      justify-items: center;
      // margin: 1rem;
      // padding-left: 1rem;
      // padding-right: 1rem;
      // padding-top:2rem;
      // padding-bottom: 1rem;
      // padding:  1rem auto .5rem auto;
      background-color: rgba($color-secondary-dark, .09);
      font-size: 1.6rem;
      font-weight: 300;
      // color: rgb(7, 5, 139)  ;
      border-radius: 1%;
      box-shadow: 0 .5rem 3rem rgba($color-black, .01);
      -webkit-border-radius: 1%;
      -moz-border-radius: 1%;
      -ms-border-radius: 1%;
      -o-border-radius: 1%;

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
          }
        @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-1;
          }
      }

      .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal-3})/2);
      // min-height: 20rem !important;
      // max-height: 20rem !important;
      // margin-bottom: 1rem;

      }
      .col-1-of-2-title-delete {
      width: calc((100% - 1 *#{ $gutter-horizontal-3})/2);
      // height: 20rem !important;
      margin-left: 1rem;
      margin-right: -2rem;
      // margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      justify-items:center;
      text-align: center;
      font-size: 1.3rem;
      padding-top: .5rem;
      // color: rgba($color-black, .8) !important;
      background-color: rgba($color-white, .19);
      }

    }

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-3});
    }
    .col-5-of-7 {
    width: calc( 5 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 4 * #{$gutter-horizontal-3});
    }


    &__info-add {
      width: 100%;
      min-height: 100rem;
      // height: 100vh;
      display: block;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      justify-items: center;
      padding: 5rem 1rem;
      background-color: rgba($color-tertiary-dark, .09);
      font-size: 1.6rem;
      font-weight: 300;
      color: rgb(7, 5, 139)  ;
      border-radius: 1%;
      box-shadow: 0 .5rem 3rem rgba($color-black, .01);
      -webkit-border-radius: 1%;
      -moz-border-radius: 1%;
      -ms-border-radius: 1%;
      -o-border-radius: 1%;

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
          }
        @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-0;
          }
      }

      .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal})/2);

      }

      .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);
    }

    }
 }

 .row-single-product-page {
      width: 98%;
      display: flex;
      //flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
      justify-items:center;
      background-color: rgba($color-white, .5);
      padding-left: 3rem;
      padding-right:3rem;
      padding-top: 10rem;
      padding-bottom: 1rem;
      border-radius: .5%;
      //border: .3rem rgba($color-white, .3) solid;
      box-shadow: 0 .5rem .5rem rgba($color-black, .0);
      margin: -1rem auto 0 auto;

      &:not(:last-child){
        margin-bottom: $gutter-vertical;
      }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-2;
      }
   }


    .col-1-of-2 {
     width: calc((100% - 1 *#{ $gutter-horizontal-1})/2);

    }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-2})/4);
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    }

    .col-1-of-3 {
     width: calc((100% - 2 *#{ $gutter-horizontal-1})/3) ;

    }

    .col-3-of-7 {
    width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    }
    .col-4-of-7 {
    width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 3 * #{$gutter-horizontal-3});
    }
    .col-5-of-7 {
    width: calc( 5 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 4 * #{$gutter-horizontal-3});
    }

    &__info {
     height:fit-content;
      display: block;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      justify-items: center;
      // margin-top: 12rem;
      // margin-bottom: 12rem;
      // margin-left: 1rem;
      // margin-right: 1rem;
      padding-left: 3rem;
      padding-right: 0;
      padding-top:5rem;
      padding-bottom: 1rem;

      background-color: rgba($color-tertiary-dark, .09);
      font-size: 1.6rem;
      font-weight: 500;
      color: rgb(7, 5, 139)  ;
      border-radius: 1%;
      box-shadow: 0 .5rem 3rem rgba($color-black, .01);
      -webkit-border-radius: 1%;
      -moz-border-radius: 1%;
      -ms-border-radius: 1%;
      -o-border-radius: 1%;

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
          }
        @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-0;
          }
      }

      .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal})/2);

      }

    }
    &__info-add {
      width: 100%;
      // max-height: 111rem;
      // height: fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      justify-items: center;
      padding: 5rem 1rem 0 1rem;
      background-color: rgba($color-tertiary-dark, .03);
      font-size: 1.6rem;
      font-weight: 300;
      color: rgb(7, 5, 139)  ;
      border-radius: 1%;
      box-shadow: 0 .5rem 3rem rgba($color-black, .01);
      -webkit-border-radius: 1%;
      -moz-border-radius: 1%;
      -ms-border-radius: 1%;
      -o-border-radius: 1%;

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
          }
        @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-3;
          }
      }

      .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal})/2);

      }

      .col-1-of-5 {
      width: calc((100% - 4 * #{$gutter-horizontal-2})/5);
      height: 35rem;
    }

    }
 }

 .row-seller-videos-page-header {
      width: 100%;
      height: 50vh;
      // position: fixed;
      // z-index: 100;
      background-color: rgba($color-white, 1);
      border-radius: .5%;
      box-shadow: 0 .5rem .5rem rgba($color-black, .0);
      margin: 0 0 5.4rem 0 !important;

      &:not(:last-child){
        margin-bottom: $gutter-vertical;
      }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-2;
      }
   }
 }

 .row-seller-home-page-header {
      width: 100%;
      // position: fixed;
      // height: 50vh;
      // color: rgba($color-tertiary-light, 1);
      background-color: rgba($color-tertiary-light, .15);
      border-radius: .5%;
      box-shadow: 0 .5rem .5rem rgba($color-black, .09);
      margin: 2rem auto 2rem auto;

      &:not(:last-child){
        margin-bottom: $gutter-vertical;
      }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-2;
      }
   }
 }

 .row-seller-home-create-forms-page-header {
      width: 100%;
      // position: fixed;
      // height: 50vh;
      // color: rgba($color-tertiary-light, 1);
      background-color: rgba($color-tertiary-light, .15);
      border-radius: .5%;
      box-shadow: 0 .5rem .5rem rgba($color-black, .09);
      margin: -2rem auto 2rem auto;

      &:not(:last-child){
        margin-bottom: $gutter-vertical;
      }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-2;
      }
   }
 }

 .row-single-product-02 {
      width: 98%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      justify-items:center;
      background-color: rgba($color-tertiary-dark, .1);
      padding: 3rem;

      border-radius: 1%;
      border: .3rem rgba($color-white, .3) solid;
      box-shadow: 0 .5rem .5rem rgba($color-black, .1);
      margin:-1rem auto 5rem auto;


    &:not(:last-child){
    margin-bottom: $gutter-vertical;
      }
    @include clearfix;

    [class^="col-"] {

      float: left;

      &:not(:last-child){
        margin-right: $gutter-horizontal-1;
      }
   }


    .col-1-of-2 {
     width: calc((100% - 1 *#{ $gutter-horizontal-1})/2);

    }
    .col-1-of-3 {
     width: calc((100% - 2 *#{ $gutter-horizontal-1})/3) ;

    }

    &__info {
      // max-width:fit-content;
      width: 100%;
      display: block;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      justify-items: center;
      margin-top: 2rem;
      // margin-right: 1rem;
      padding: 5rem;


      background-color: rgba($color-white, .4);
      font-size: 1.6rem;
      font-weight: 300;
      color: rgb(7, 5, 139)  ;
      border-radius: 1%;
      box-shadow: 0 .5rem 3rem rgba($color-black, .1);
      -webkit-border-radius: 1%;
      -moz-border-radius: 1%;
      -ms-border-radius: 1%;
      -o-border-radius: 1%;
    }
 }
 .row-single-product-reviews {
       width: 95%;
      //  max-height: fit-content;
       border: .2rem rgb(30, 255, 0) solid;
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       justify-content: flex-start;
       justify-items:center;
       background-color: rgba($color-tertiary-dark, .1);
       //padding: 3rem 2px 3rem 3rem;
       padding-bottom: 5rem;
       padding-top: 3rem;
       padding-right: -5rem;
       padding-left: 5rem;
       border-radius: 1%;
       border: .3rem rgba($color-white, .3) solid;
       box-shadow: 0 .5rem 5rem rgba($color-black, .3);
       margin:-1rem auto 5rem auto;
      //  transform: skewY(.2deg) ;


      // margin-bottom: $gutter-vertical;
        // }
      // @include clearfix;

      // [class^="col-"] {

      //   float: left;

      //   &:not(:last-child){
      //     margin-right: $gutter-horizontal-1;
      //   }
    // }

 }



 .row-review-card {
      //  width: 35%;
       width: fit-content;
       height: fit-content;
       display: flex;
       flex-direction: column;
       //flex-wrap: wrap;
       justify-content: space-evenly;
      //  justify-items: center;
       margin-top: 2rem;
       border-radius: 1%;
       padding: 2rem;
       background-color: rgba($color-white, .3);
       box-shadow: 0 .5rem 3rem rgba($color-black, .1);
      //  transform: skewY(1deg) ;

      &__name{
        width: fit-content;
      }
 }

 .seller-public-vid-post-comment {
      //  width: 35%;
       width: fit-content;
       height: fit-content;
       min-height: 40rem;
       display: flex;
       flex-direction: column;
       //flex-wrap: wrap;
       justify-content: flex-start;
      //  justify-items: center;
       margin-top: 2rem;
       border-radius: 1%;
       padding: 1rem;
       background-color: rgba($color-white, .3);
       box-shadow: 0 .5rem 3rem rgba($color-black, .1);
      //  transform: skewY(1deg) ;

      &__name{
        width: fit-content;
      }
 }

 .row-review-card-remove {
       width: 100%;
       display: flex;
       flex-direction: column;
       //flex-wrap: wrap;
       //justify-content: space-evenly;
       //justify-items: center;
       margin-top: 2rem;
       border-radius: 1%;
       padding: 2rem;
       background-color: rgba($color-white, .3);
       box-shadow: 0 .5rem 3rem rgba($color-black, .05);
      //  transform: skewY(.2deg) ;
       &__name {
        width: fit-content;
      }
 }

 .row-reviews-main-container {
   width: 100%;
     height: fit-content;
     display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // border: red .1rem solid;
      justify-content: space-between;
      justify-items: center;
      // margin-top: 2rem;
       margin-bottom: 2rem;
      // margin-left: 1rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding: 2rem 1rem ;
      box-shadow: 0 1.5rem 3rem rgba($color-black, .05);

      //  &:not(:last-child){
      //   margin-bottom: $gutter-vertical;
      //     }
        @include clearfix;

        [class^="col-"] {

          float: left;

          // &:not(:last-child){
          //   margin-right: $gutter-horizontal-3;
          // }
          // &:not(:first-child){
          //   margin-left: $gutter-horizontal-3;
          // }

       .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal})/2);
      }

       .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      }
       .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-1})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }
  }

 }

 .row-reviews-video-container {
   width: 89%;
   position:absolute;
    //  height: fit-content;
     display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      // border: red .1rem solid;
      justify-content: space-between;
      justify-items: center;
      // margin-top: 2rem;
      // border: .1rem red solid;
       margin-bottom: 2rem;
      margin-left: -.5rem;
      margin-right: 1rem;
      border-radius: 1%;
      padding: 1rem 1rem 3rem 1.5rem ;
      background-color: rgba($color-white, .15);
      box-shadow: 0 1.5rem 3rem rgba($color-tertiary-light, .2);

      //  &:not(:last-child){
      //   margin-bottom: $gutter-vertical;
      //     }
        @include clearfix;

        [class^="col-"] {

          float: left;

          // &:not(:last-child){
          //   margin-right: $gutter-horizontal-2;
          // }
          // &:not(:first-child){
          //   margin-left: $gutter-horizontal-3;
          // }

       .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal-3})/2);
      }

       .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      }
       .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }
  }

 }



 .row-single-product-reviews-container {
   width: 100% !important;
   height: 72%;
   position:absolute;
    //  height: fit-content;
     display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // border: red .1rem solid;
      justify-content: center !important;
      justify-items: center;
       overflow: scroll;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        scrollbar-width:thin !important;
      // margin-top: 2rem;
      // border: .1rem red solid;
      //  margin-bottom: 25rem !important;
      // margin-left: .5rem;
      // margin-right: .5rem;
      // margin: 0 auto 0 auto !important;
      border-radius: 1%;
      // padding: 1rem 1rem 3rem 1.5rem ;
      padding: .1rem 1.5rem !important;
      background-color: rgba($color-tertiary-dark, .01);
      box-shadow: 0 .5rem 3rem rgba($color-black, .1);

      @include respond(phone){
        height: 58vh;
        overflow: scroll;
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        padding: 2rem .5rem !important;

      }

      //  &:not(:last-child){
      //   margin-bottom: $gutter-vertical;
      //     }
        // @include clearfix;

        // [class^="col-"] {

        //   float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-1;
          }
          // &:not(:first-child){
          //   margin-left: $gutter-horizontal-3;
          // }

       .col-1-of-2 {
      width: calc((100% - 1 *#{ $gutter-horizontal-3})/2);
      @include respond(phone){
        width: 95%;
        height: fit-content !important;
        // margin-bottom: 5rem;
        // border: .2rem red solid;
      }
      }

       .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      @include respond(phone){
        width: 95%;
        height: fit-content !important;
        // margin-bottom: 5rem;
        // border: .2rem red solid;
      }
      }
       .col-1-of-4 {
      width: calc((100% - 3 * #{$gutter-horizontal-3})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }
}

//  }


  .row-review-card-name-star {
   width: 100%;
     display: flex;
     height: fit-content;
      flex-direction: column;
      // flex-wrap: wrap;
      justify-content: center;
      justify-items: center;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 400;
      // justify-items: center;
      margin-top: 2rem;
       margin-bottom: 1rem;
      // margin-left: .5rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding: 0 1rem;
      padding-top: 1rem;
      background-color: rgba($color-white, .7);
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .5);

 }

 .row-review-card-name {
   width: 100%;
     display: flex;
     height: fit-content;
      flex-direction: row;
      // flex-wrap: wrap;
      justify-content: space-between;
      justify-items: center;
      font-size: 1.4rem;
      font-weight: 400;

      @include respond(phone){
        font-size: 3rem;
      // justify-content: space-around;
      padding-left: 2rem;
      padding-right: 2rem;
      }
      // justify-items: center;
      // margin-top: 2rem;
      //  margin-bottom: 2rem;
      // margin-left: .5rem;
      // margin-right: 1rem;
      // border-radius: 1%;
      // padding:  1rem 0;
      // background-color: rgba($color-white, .4);
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .5);

 }



 .row-review-card-name-2 {
  // border: .1rem red solid;
   width: 100%;
     display: flex;
     height: fit-content;
      flex-direction: row;
      // flex-wrap: wrap;
      justify-content: flex-start;
      justify-items: center;
      font-size: 1.4rem;
      font-weight: 400;
      justify-items: center;
      text-align: justify;
      // margin-top: 2rem;
       margin-bottom: 2rem;
      // margin-left: .5rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding:  4rem 5rem;
      // background-color: rgba($color-white, .5);
      box-shadow: 0 .1rem .1rem rgba($color-black, .1);

 }

 .row-single-product-descriptions {
  // border: .1rem red solid;
   width: 100%;
     display: flex;
     height: fit-content;
      flex-direction: column;
      // flex-wrap: wrap;
      justify-content: flex-start;
      justify-items: center;
      font-size: 1.4rem;
      font-weight: 400;
      justify-items: center;
      text-align: justify;
      // margin-top: 2rem;
       margin-bottom: 2rem;
      // margin-left: .5rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding:  4rem 5rem;
      background-color: rgba($color-white, .3);
      box-shadow: 0 .1rem .1rem rgba($color-black, .1);


 }
//  img{
//   width: 35%;
//   margin:3rem;
//   padding: 2rem;
//  }

 .row-review-card-name-3 {
  // border: .1rem red solid;
   width: 100%;
     display: flex;
     height: fit-content;
      flex-direction: row;
      // flex-wrap: wrap;
      justify-content: flex-start;
      justify-items: center;
      font-size: 1.4rem;
      font-weight: 400;
      justify-items: center;
      text-align: justify;
      // margin-top: 2rem;
       margin-bottom: 2rem;
      // margin-left: .5rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding:  0 2rem;
      background-color: rgba($color-black, .05);
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .5);

 }

 .row-review-card-name-date-star {
   width: 100%;
     display: flex;
     height: fit-content;
    //  border: .1rem red solid;
      flex-direction: column;
      // flex-wrap: wrap;
      justify-content: center;
      justify-items:center;
      font-size: 1.1rem;
      font-weight: 400;
      // justify-items: center;
      margin-top: 2rem;
       margin-bottom: 1rem;
      // margin-left: 1rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding: -11rem  .5rem .2rem;
      // background-color: rgba($color-white, .5);
      // box-shadow: 0 1.5rem 3rem rgba($color-black, .05);

 }

 .row-review-card-name-modal {
   width: 100%;
     display: flex;
     height: fit-content;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      justify-items: center;
      // text-align: start;
      // margin-top: 2rem;
       margin-bottom: 5rem;
      // margin-left: 1rem;
      // margin-right: 1rem;
      border-radius: 1%;
      padding: 2rem;
      box-shadow: 0 1.5rem 3rem rgba($color-black, .05);

 }
   .star-ratings {
        // border: .1rem red solid;
        margin-left: -5rem;
        // font-size: .5rem;

        display: flex;
        justify-content: flex-start;

      }


 .row-review-card-content {
   width: 45rem;
     display: flex;
     height: 35rem;
      flex-direction: row;
      flex-wrap: wrap;
      color: rgba($color-tertiary-light, 1);
      background-color: rgba($color-grey-light-1, .6);
      justify-content: center;
      justify-items: center;
      overflow: hidden;
      overflow-wrap: break-word;
      // border: .2rem red solid;
      // margin-top: 2rem;
      //  margin-bottom: 2rem;
      // margin: 0;
      // margin-right: 1rem;
      border-radius: 1%;
      padding: 1rem 1rem;
      box-shadow: 0 .5rem 3rem rgba($color-black, .05);

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
          }

      //  &:not(:first-child){
      //   margin-left: $gutter-vertical-0;
      //     }
        @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-1;
          }

       .col-1-of-2 {
        width: calc((100% - 1 *#{ $gutter-horizontal})/2);
      }

       .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }
  }

 }

 .row-single-video-review-card-content {
  position: relative;
   width: 100%;
     display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
    //  height: fit-content;
      color: rgba($color-tertiary-light, 1);
      background-color: rgba($color-white, .3);
      justify-content: center;
      justify-items: center;
      font-weight: 500;
      // overflow: hidden;
      // overflow-wrap: break-word;
      // border: .5rem red solid;
      // margin-top: 2rem;
       margin-bottom: 2rem;
      // margin: 0;
      margin-right: 5rem !important;
      border-radius: 1%;
      padding: 2rem;
      box-shadow: 0 .5rem 3rem rgba($color-black, .12);
        // border: .1rem blue solid;

      @include respond(phone){
     display: flex;
     //width: 100% !important;
     flex-direction: column !important;
     //flex-wrap: wrap !important;
     overflow: scroll;
     -webkit-backface-visibility: visible !important;
     backface-visibility: visible !important;
     height: 65vh;
    //  border: .1rem blue solid;
     //margin-right: 115rem !important;
    //  padding-bottom: 25rem !important;
}

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
          }

       &:not(:first-child){
        margin-right: $gutter-horizontal-1 !important;
          }
        // @include clearfix;

        // [class^="col-"] {

        //   float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-2;
          }

       .col-1-of-2 {
        // width: calc((100% - 1 *#{ $gutter-horizontal-3})/2);
        width:48% !important;
//  border: .1rem red solid;
        @include respond(phone){
        // width: calc((100% - 1 *#{ $gutter-horizontal-2})/2);
          width:95% !important;
          height: 29vh !important;
          margin: 1rem auto;
          // overflow: hidden;
          // border: .2rem blue solid;
        }
      }
       .col-1-of-2-product-review {
        width: calc((100% - 1 * #{ $gutter-horizontal-2})/2);

        @include respond(phone){
        // width: calc((100% - 1 *#{ $gutter-horizontal-2})/2);
          width: 100% !important;
          border: .2rem blue solid;
        }
      }

       .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    }

       .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
      }
  }

//  }

 .row-single-video-review-card-text {
   width: 100%;
     height: 30vh;
     display: flex;
      flex-direction: column !important;
      // flex-wrap: wrap;
      color: rgba($color-grey-dark-3, 1);
      background-color: rgba($color-white, .5);
      justify-content: center;
      justify-items: center;
      font-size: 1.4rem;
      font-weight: 400;
      overflow: hidden;
      overflow-wrap: break-word;
      overflow: scroll;
      scrollbar-width: thin;
      // border: .2rem red solid;
      margin-top: 1rem;
      //  margin-bottom: 2rem;
      // margin: 0;
      // margin-right: 1rem;
      border-radius: 1%;
      padding: 2rem 1rem 1rem 1rem !important;
      box-shadow: 0 .5rem 3rem rgba($color-black, .03);

      @include respond(phone){
        font-size: 3.5rem;
        -webkit-backface-visibility: visible;
      }

 }

 .row-review-card-content-modal {
   width: 100%;
     display: flex;
    //  height: 50%;
      flex-direction: row;
      // flex-wrap: wrap;

      justify-content:space-between;
      // justify-items: center;

      // margin-top: 2rem;
      //  margin-bottom: 2rem;
      margin: 3rem 0;
      margin-right: -2rem;
      border-radius: 1%;
      padding: 0 ;
      box-shadow: 0 .5rem 3rem rgba($color-black, .05);

      // @include respond(phone){
      //   width: 0;
      //   height: 0;
      //   opacity: 0;
      // }

       &:not(:last-child){
        margin-bottom: $gutter-vertical;
      }

        @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-3;
          }
        }

     .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-3})/3) + #{$gutter-horizontal-3});
      }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }

    // .col-2-of-4 {
    // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    // }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }
}



 .row-review-img-slider {
   width: 100%;
     display: flex;
     height: 30rem;
    //  border: .2rem red solid;
      // flex-direction: column;
      // flex-wrap: wrap;
      justify-content: center;
      justify-items: center;
      margin-top: 2rem;
       margin-bottom: 2rem;
      // margin-left: 10rem;
      border-radius: 1%;
      padding: .5rem;
      box-shadow: 0 .5rem 3rem rgba($color-black, .05);

 }


.row-seller-profile-picture-and-bio{
  width: 100%;
  display: flex;
  // justify-content: space-between;
  justify-items: center;
  margin: 2rem auto;
  padding: 2rem ;
  // border: #20a020 .4rem solid;

   @include clearfix;

        [class^="col-"] {

          float: left;

          &:not(:last-child){
            margin-right: $gutter-horizontal-3;
          }
        }

     .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      }

      .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-3})/3) + #{$gutter-horizontal-3});
      }

    .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal-3})/4);
    }

    .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-3})/4) + 2 * #{$gutter-horizontal-3});
    }
}

.row-register {
  width:100% !important;
  height:100% !important;
  position:relative;
  top:0;
  left:0;
}

.ant-pagination-item{
  color: rgba($color-primary-light, 1);
  background-color:rgba($color-white, .5) !important;
  // border: white .1rem solid !important;
}

.ant-pagination-item-active{
  background-color: white !important;
  border: none !important;
}
.anticon-right, .anticon-left{
  display: flex;
  justify-content: center;
  justify-items: center;
  color:rgba($color-white, 1);
  font-size: 2rem;
  // border: .1rem red solid;
  margin-left: 5rem;
  margin-right: 5rem;
  @include respond(tab-land){
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.row-page-fit{
  @include respond(phone){
    height: fit-content !important;
    padding-bottom: 5rem !important;
    // border: 1rem red solid;
    margin-bottom: 50rem;

  }
}
