.feature-box {
  background-color: rgba($color-white,.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
  transition: transform .3s;

  &__icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    background-clip:text;
    -webkit-background-clip: text;
    background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
    color: transparent;
  }

  &:hover {
    transform: translateY(-1.5rem) scale(1.03);
  }


}


.featureTopSeller-box {
  // width: 75%;
  // max-height: fit-content;
  // padding-bottom: 2px;
  //background-color: rgba($color-black,.1);
  font-size: 1.5rem;
  padding: 2.5rem;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 3px;
 // border: 2rem rgb(238, 28, 203) solid;
  padding-bottom: 0 !important;
  box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
  transition: transform .3s;

  // &__icon {
  //   font-size: 6rem;
  //   margin-bottom: .5rem;
  //   background-clip:text;
  //   -webkit-background-clip: text;
  //   background-image: linear-gradient(to right, $color-Greenprimary-light, $color-Greenprimary-dark);
  //   color: transparent;
  // }






}

.feature-topSeller--p {
  margin-bottom: 10px;
  padding-bottom: 18px;
  //border-bottom: 1rem red solid;
}

.feature-topSeller--div {
  //border-bottom: 1rem rgb(11, 134, 7) solid;
  margin-bottom: -5px;
  padding-bottom: -10px;

}


    .col-image {
    max-height:45vh;
    width: 30vw;
  }
  
.carousel .thumbs-wrapper {
    margin: 1px !important;
    overflow: visible;
  }
