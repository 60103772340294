.new-form-control {
  //display: flex;
  //justify-content: center;
  width:95% !important;
  height: 3.8rem !important;
  font-size: 1.8rem;
  color: rgba($color-primary-light-2, 1);
  //line-height: 2rem;
  border: .1rem rgba($color-white, .05) solid;
  background-color:rgba($color-white, .2);
  //background-image:     linear-gradient(to right,  $color-primary-light,  $color-primary-dark);
  // border: .1rem $color-grey-light-1 solid;
  //padding: .5rem 1rem .5rem 1rem;
  margin: .8rem auto ;
  margin-right: .5rem !important;
  padding: 0 2rem !important;
  //margin-right: 5rem;
  border-radius:.5rem ;
  -webkit-border-radius:.5rem ;
  -moz-border-radius:.5rem ;
  -ms-border-radius:.5rem ;
  -o-border-radius:.5rem ;
  //background-image: linear-gradient(180deg,//     rgba(69, 81, 248, 0.6) 5%,//     rgba(69, 81, 248, 0.4) 15%,//     rgba(67, 70, 241, 0.5) 35%,//     rgba(69, 81, 248, 0.4) 40%,//     rgba(69, 81, 248, 0.4) 45%,//     rgba(69, 81, 248, 0.4) 55%,//     rgba(69, 81, 248, 0.6) 65%,//     rgba(56, 116, 236, 0.6) 100%,//     rgba(15, 91, 241, 0.7) 90%);
  //url(../image/hero.jpg):;
  &:active,&:focus {
    outline: none !important;
    background-color:rgba($color-white, .5);
    transform: translateY(-1px);
    box-shadow: 0 .1rem .1rem rgba(14, 14, 15, .3);
    padding-left: 2rem !important;

  }
}
.new-form-control-search {
  //display: flex;
  //justify-content: center;
  width:95% !important;
  height: 3.8rem !important;
  font-size: 1.8rem;
  color: rgba($color-primary-light-2, 1);
  //line-height: 2rem;
  border: .1rem rgba($color-white, .05) solid;
  background-color:rgba($color-white, .2);
  //background-image:     linear-gradient(to right,  $color-primary-light,  $color-primary-dark);
  // border: .1rem $color-grey-light-1 solid;
  //padding: .5rem 1rem .5rem 1rem;
  margin: .8rem auto ;
  margin-right: .5rem !important;
  padding: 0 2rem !important;
  //margin-right: 5rem;
  border-radius:.5rem ;
  -webkit-border-radius:.5rem ;
  -moz-border-radius:.5rem ;
  -ms-border-radius:.5rem ;
  -o-border-radius:.5rem ;

  @include respond(phone){
    margin-right: 2rem !important;
  }
  //background-image: linear-gradient(180deg,//     rgba(69, 81, 248, 0.6) 5%,//     rgba(69, 81, 248, 0.4) 15%,//     rgba(67, 70, 241, 0.5) 35%,//     rgba(69, 81, 248, 0.4) 40%,//     rgba(69, 81, 248, 0.4) 45%,//     rgba(69, 81, 248, 0.4) 55%,//     rgba(69, 81, 248, 0.6) 65%,//     rgba(56, 116, 236, 0.6) 100%,//     rgba(15, 91, 241, 0.7) 90%);
  //url(../image/hero.jpg):;
  &:active,&:focus {
    outline: none !important;
    background-color:rgba($color-white, .5);
    transform: translateY(-1px);
    box-shadow: 0 .1rem .1rem rgba(14, 14, 15, .3);
    padding-left: 2rem !important;

  }
}

.new-form-control-search-hidden{
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 0;
}

// //////////////////////////////////////////////////////////////////////////
//             // :::::::::::::OLD STYLES BELOW:::::::::
// //////////////////////////////////////////////////////////////////////////


.register-form {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    justify-items: center !important;
    margin: 2rem auto!important;
    padding: 2rem;
    // border: .1rem red solid;
    background-color:rgba($color-tertiary-light, .02);

}

.form-delivery-confirmation-input-group{
  width: 85%;
  height: 65%;
  margin: 1rem auto !important;
  padding: 0 2rem !important;
  // border: .1rem red solid;


}

.form {

  &__group-seller-create-home-forms {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: 2rem auto;
    padding: 2rem 1rem 2rem 6rem;
    // border: .1rem red solid;
    background-color:rgba($color-tertiary-light, .02);

    &--subgroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      margin: 2rem 4rem 2rem -2rem;
      padding: 2rem 0 2rem 4rem;
      // border: .1rem rgb(4, 0, 255) solid;
      background-color:rgba($color-white, .2);

    }
  }

  &__quantity-group{
    display: flex;
    justify-items: center;
    // justify-content:space-around !important;

    margin: .1rem 0 !important;
    padding: 0 0 0 2rem;
    // border: .1rem red solid;
    &--color-discount{
      justify-content: flex-start !important;
      justify-items:center !important;
      padding: .5rem 2rem !important;
      background-color: rgba($color-white, .35);
       box-shadow:.1rem .1rem .1rem rgba($color-black, .2);
      color: rgba($color-secondary-dark, .8);

    }
    &--option4-all-sizes-discount{
      display: flex;
      justify-content: center !important;
      justify-items:center !important;
      // margin: 1rem auto;
      padding: .5rem 2rem !important;
      background-color: rgba($color-white, .35);
       box-shadow:.1rem .1rem .1rem rgba($color-black, .2);
      color: rgba($color-secondary-dark, .8);

    }
    &--discount{
      // margin-top: 2rem !important;
      padding-top: .5rem;
      padding-bottom: .5rem;
      background-color: rgba($color-white, .2);
      color: rgba($color-secondary-dark, .8);
      border: rgba($color-white, .5) .1rem solid;
    }
    &--invalidDiscount{
      margin-top: 2rem !important;
      background-color: rgba($color-black, .03);
      color: rgba($color-secondary-dark, .3);
    }
  }

  &__option-2-quantity-group{
    display: flex;
    justify-content:center!important;
    justify-items: center;
      // &:not(:last-child){
       margin-left: 1rem;
      //  margin-right: .5rem;
    //  }
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem auto !important;
    background-color: rgba($color-navy-dark, .03);
    border: .1rem rgba($color-white, 1) solid;
  }

  &__group:not(:last-child) {
    margin: bottom 2rem;
  }

  &__input {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-white, .5);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }
  }

  &__input-register {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin: 1rem auto;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-white, .5);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

    @include respond(phone){
      width: 71.5vw;
      // margin: 2rem auto;
      // padding: 0 auto;
    }

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }
  }

  &__input-forgot-password {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin: 1rem auto;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-white, .5);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

    @include respond(phone){
      width: 75vw;
      font-size: 4rem;
      // margin: 2rem auto;
      // padding: 0 auto;
    }

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }
  }

  &__input-post-text-area {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-black, 1);
    border: none;
    border-bottom: 3px solid transparent !important;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary !important;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark !important;
      }
    }
  }


  &__input-select {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    // margin-bottom: 3rem;/
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .05);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-1 {
    font-size: 1.8rem;
    font-family: inherit;
    color: inherit;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .05);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

    @include respond(phone){
      font-size: 3rem;
      padding: .1rem auto;
    }

  }

  &__input-cart {
    font-size: 1.3rem;
    font-family: inherit;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;
    color: inherit;
    // margin-bottom: 3rem;
    // padding: .5rem 2.5rem .5rem .5rem !important;
    // border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .01);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: fit-content;
    // display: block;
    transition: all .3s;
     &:active, &:focus {
      outline: none !important;
      border: none !important;
      // background-color: rgba(185, 214, 243, 0.1);
      color: rgba($color-tertiary-light, 1);
      // box-shadow: 0 1rem 2rem rgba($color-black, .2);
      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color:rgba($color-secondary-dark, 1);
    }

  }

  &__input-cart-single-page {
    display: flex;
    flex-direction: row;
    // justify-content: space-between !important;
    justify-items: center !important;
    font-size: 1.4rem;
    font-family: inherit;
    font-weight: 600;
    text-align: center !important;
    text-transform: capitalize;
    color: rgba($color-tertiary-light, 1);
    // margin-bottom: 3rem;
    margin-top: .4rem !important;
    padding: .5rem auto 1rem 1rem !important;
    // padding-top: .5rem !important;
    // border-radius: 2px;
    background-color:rgba($color-white, .7);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 100%;
    // display: block;
    // transition: all .3s;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      // background-color: rgba(185, 214, 243, 0.1);
      @include respond(tab-port){
        font-size: 2.5rem !important;
      }

       &:active, &:focus, &:hover {
      outline: none !important;
      border: none !important;
      background-color: rgba(246, 236, 236, 0.5);
      color: rgba($color-tertiary-light, 1);
      // box-shadow: 0 1rem 2rem rgba($color-black, .2);
      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }


      &--color{

        padding: 1rem !important;
        margin-top: -1rem !important;
        margin-bottom: 1rem !important;
        text-align: center;
        // margin-left: 1rem !important;
        // margin-right: 1rem !important;
        // border-radius: 2px;
        // background-color:rgba(yellow, .4);
        border: none!important;
        border-bottom: 3px solid transparent;
        width: 100%;
        // display: block;
        transition: all .3s;
          box-shadow: 0 .3rem .3rem rgba($color-black, .2);
      // background-color: rgba(185, 214, 243, 0.1);
         &:active, &:focus, &:hover {
      outline: none !important;
      border: none !important;
      background-color: rgba(17, 126, 235, 0.5);
      color: rgba($color-tertiary-light, 1);
      // box-shadow: 0 1rem 2rem rgba($color-black, .2);
      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }


      }

    &:hover {
    transform:scale(1) translateY(-.2rem);
    color: rgba($color-tertiary-light, .8);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 .5rem .5rem rgba($color-black, .2);
    -webkit-transform:scale(1) translateY(-.2rem);
    -moz-transform:scale(1) translateY(-.2rem);
    -ms-transform:scale(1) translateY(-.2rem);
    -o-transform:scale(1) translateY(-.2rem);
  }

  }



  &__input-product-items-list-single-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    justify-items: center !important;
    font-size: 1.4rem;
    font-family: inherit;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    color: rgba($color-tertiary-light, 1);
    // margin-bottom: 3rem;
    margin-top: .4rem !important;
    padding: 2rem 1rem .1rem 1rem!important;
    // padding-top: .5rem !important;
    // border-radius: 2px;
    background-color:rgba($color-white, .7);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 100%;
    // display: block;
    // transition: all .3s;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      // background-color: rgba(185, 214, 243, 0.1);
      @include respond(phone){
        font-size: 4rem;
         color: rgba($color-tertiary-light, 1);
         padding: 2.5rem 5rem !important;
      }

       &:active, &:focus, &:hover {
      outline: none !important;
      border: none !important;
      background-color: rgba(246, 236, 236, 0.5);
      color: rgba($color-tertiary-light, 1);
      // box-shadow: 0 1rem 2rem rgba($color-black, .2);
      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }


      &--color{

        padding: 1rem !important;
        margin-top: -1rem !important;
        margin-bottom: 1rem !important;
        text-align: center;
        // margin-left: 1rem !important;
        // margin-right: 1rem !important;
        // border-radius: 2px;
        // background-color:rgba(yellow, .4);
        border: none!important;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        width: 100%;
        // display: block;
        transition: all .3s;
          box-shadow: 0 .3rem .3rem rgba($color-black, .2);
      // background-color: rgba(185, 214, 243, 0.1);
         &:active, &:focus, &:hover {
      outline: none !important;
      border: none !important;
      background-color: rgba($color-white, 0.07);
      color: rgba($color-tertiary-light, 1);
      // box-shadow: 0 1rem 2rem rgba($color-black, .2);
      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }


      }

    &:hover {
    transform:scale(1) translateY(-.2rem);
    color: rgba($color-tertiary-light, .8);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 .5rem .5rem rgba($color-black, .2);
    -webkit-transform:scale(1) translateY(-.2rem);
    -moz-transform:scale(1) translateY(-.2rem);
    -ms-transform:scale(1) translateY(-.2rem);
    -o-transform:scale(1) translateY(-.2rem);
  }

  }




  &__admin-dashboard-delivery-status {
    font-size: 1.6rem;
    font-family: inherit;
    color: rgba($color-secondary-dark, 1);
    // margin: 1rem auto;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .05);
    box-shadow: 0 .5rem .5rem rgba($color-black, .13);
    border: none;
    border-bottom: 3px solid white;
    width: 90%;
    display: block;
    transition: all .3s;

     &:active, &:focus {
      outline: none;
      border: none;
      background-color: rgba(119, 173, 240, .1);
      color: rgba($color-tertiary-light, 1);
      box-shadow: 0 1rem 2rem rgba($color-black, .2);
      // border-bottom: 3px solid $color-secondary-dark;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
        background-color: rgba($color-black, 1);
      }
    }

    &:hover {
      transform: translateY(-3px);
      -webkit-transform: translateY(-3px);
      background-color: rgba(119, 173, 240, .2);
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      -moz-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      -o-transform: translateY(-3px);
    }

  }

  &__seller-detail-order-list-delivery-status {
    font-size: 1.6rem;
    font-family: inherit;
    color: rgba($color-secondary-dark, 1);
    // margin: 1rem auto;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .05);
    box-shadow: 0 .5rem .5rem rgba($color-black, .13);
    border: none;
    border-bottom: 3px solid white;
    width: 90%;
    display: block;
    transition: all .3s;

     &:active, &:focus {
      outline: none;
      border: none;
      background-color: rgba(119, 173, 240, .1);
      color: rgba($color-tertiary-light, 1);
      box-shadow: 0 1rem 2rem rgba($color-black, .2);
      // border-bottom: 3px solid $color-secondary-dark;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
        background-color: rgba($color-black, 1);
      }
    }

    &:hover {
      transform: translateY(-3px);
      -webkit-transform: translateY(-3px);
      background-color: rgba(119, 173, 240, .2);
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      -moz-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      -o-transform: translateY(-3px);
    }

  }




  &__input-shipping-address-info {
    font-size: 1.8rem;
    font-family: inherit;
    color: inherit;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .05);
    border: none!important;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none !important;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);

      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }

      &--city-state-code {
        width:10%;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

    @include respond(phone){
      font-size: 2.5rem;
    }

  }

  &__input-seller-create-home-forms {
    border: .3rem rgb(35, 30, 30) solid;
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin: 1rem 5rem 2rem 0;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .05);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-2 {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    // margin: 1rem auto;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .15);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-general-coupon {
    font-size: 1.9rem;
    font-family: inherit;
    color: inherit;
    // margin: 1rem auto;
    margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-white, .5);
    border: 1rem rgba($color-black, 1);
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all .3s;

    @include respond(phone){
      font-size: 3rem;
    }

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .1rem rgba($color-black, .05);
      border-bottom: 3px solid rgba($color-black, .1);

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-comment-label {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    margin: 1rem auto;
    // margin-bottom: 3rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .15);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-post {
    width: 100%;
    font-size: 1.6rem;
    text-transform: none;
    font-family: inherit;
    color: rgba($color-tertiary-light, 1);
    margin: 1rem auto -3rem -1rem !important;
    padding: .5rem .2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-light, .05);
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-comment-text {
    width: 100%;
    font-size: 1.6rem;
    text-transform: none;
    font-family: inherit;
    color: rgba($color-tertiary-light, 1);
    margin: 1rem auto 3rem auto;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-light, .08);
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-post-title {
    width: 100%;
    font-size: 1.8rem;
    text-transform: none;
    font-family: inherit;
    color: rgba($color-white, 1);
    margin: 1rem auto ;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-light, .2);
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-post-title-seller-pub-vid {
    width: 100%;
    font-size: 1.8rem;
    text-transform: capitalize;
    font-family: inherit;
    color: rgba($color-white, 1);
    margin: 1rem auto .1rem auto !important;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-light, .2);
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }

  &__input-post-text-area-seller-public-vid {
    font-size: 1.2rem;
    font-family: inherit;
    color: rgba($color-tertiary-light, 1);
    margin-top: 1rem;
    // padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-white, .5);
    border: none;
    // border-bottom: 3px solid transparent;
    width: 100%;
    height: 100%;
    min-height: 35rem;
    // display: block;
    transition: all .3s;

    //  &:focus {
    //   // outline: none;
    //   box-shadow: 0 1rem 2rem rgba($color-black, 1);
    //   border-bottom: 3px solid $color-primary;

    //   &:invalid {
    //     border-bottom: 3px solid $color-secondary-dark;
    //   }
    // }

    // &::-webkit-input-placeholder {
    //   color: $color-grey-dark-2;
    // }



  }


  &__input--create-category {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding: .5rem 2rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .12);
    box-shadow: 0 .5rem .5rem rgba($color-black, .05);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

  }


  &__label {
    font-size: 1.9rem;
    font-weight: 700 !important;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: 1rem;
    letter-spacing: .3rem;
    display: flex;
    transition: all .3s;

    @include respond(phone){
      font-size: 3rem;
    }
  }
  &__label-ship-address-region {
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: 1rem;
    letter-spacing: .5rem;
    display: flex;
    transition: all .3s;

    @include respond(phone){
      color: rgba($color-black, 1);
      font-size: 2rem;
      font-weight: 700;
    }
  }

  &__label-contact-seller-customer-admin-topic {
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: -1rem;
    letter-spacing: .3rem;
    display: flex;
    transition: all .3s;
    @include respond(phone){
      font-size: 2rem;
    }
  }

  &__label-shipping {
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    letter-spacing: .5rem;
    display: flex;
    transition: all .3s;
  }


  &__create-color-form{
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    letter-spacing: .5rem;
    display: flex;
    transition: all .3s;
  }

  &__choose-color-option-note{
    width: fit-content;
    color: rgba($color-grey-dark-1, .8) ;
    border-bottom: rgba($color-tertiary-dark, .09) .5rem solid;
    box-shadow: 0 .1rem .1rem rgba($color-black, .02);
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: .7rem auto !important;
    padding-bottom: .7rem;
    letter-spacing: .2rem;
    display: flex;
    transition: all .3s;
  }



  &__shipping-label {
    // border: .1rem red solid;
    font-size: 1.2rem;
    font-weight: 500;
    // margin-left: .2rem;
    // margin-top: .2rem;
    // margin-bottom: .7rem;
    // padding:1rem;
    padding-top:2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    transition: all .3s;
  }


  &__color-name-label {
    // border: .1rem red solid;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1rem 1rem 0 .5rem !important;
    padding:1.5rem 1rem;
    // padding-left: 1rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: flex-end;
    justify-items: center;
    transition: all .3s;
    &--text{
      text-transform: capitalize;
    }
  }

  &__option-5-quantity-label {
    // border: .1rem red solid;
    font-size: 1.3rem;
    font-weight: 700;
    // margin: 1rem 1rem 0 .5rem !important;
    margin-right: 3rem;
    padding:3rem;
    padding-left: 4rem;
    letter-spacing: .5rem;
    // display: flex;
    // justify-content: flex-end;
    // justify-items: center;
    transition: all .3s;
    &--text{
      text-transform: capitalize;
    }

  }

    &--large-background {
      width: 75%;
      margin: 2rem auto !important;
      padding: 1rem auto;
      background-color: rgba($color-tertiary-dark, .05);
     }

  &__size-quatities-label {
    width: 45%;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: rgba($color-tertiary-dark, .05);
    box-shadow: 0 .15rem .15rem rgba($color-tertiary-dark, .3);
    border: .2rem rgba($color-white, .8) solid;
    margin: 1rem 0 .2rem 3rem !important;
    padding: 1.5rem;
    padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    transition: all .3s;
    &--text{
      text-transform: capitalize;
    }
  }

  &__additional-sizes-quatities-label {
    width: 60%;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgba($color-primary-light, .7);
    background-color: rgba($color-secondary-dark, .03);
    box-shadow: 0 .3rem .3rem rgba($color-tertiary-dark, .3);
    border: .2rem rgba($color-white, .8) solid;
    // margin: 1rem 0 .2rem 3rem !important;
    margin: 1rem auto 1.3rem auto;
    padding: 1.5rem;
    padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    transition: all .3s;
    cursor: pointer;
    &--text{
      text-transform: capitalize;
    }

     &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: rgba($color-secondary-dark, .05)!important;
     border: .2rem rgba($color-white, .5) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-secondary-dark, .09)!important;
    border: .2rem rgba($color-white, .4) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
    -moz-transform: translateY(-.1rem);
    -ms-transform: translateY(-.1rem);
    -o-transform: translateY(-.1rem);
}
  }


  &__additional-sizes-quatities-label-option-2 {
    width: 80%;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: rgba($color-secondary-dark, .05);
    box-shadow: 0 .3rem .3rem rgba($color-tertiary-dark, .3);
    border: .2rem rgba($color-white, .8) solid;
    // margin: 1rem 0 .2rem 3rem !important;
    margin: 1rem auto 1.3rem auto;
    padding: 1.5rem;
    padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    transition: all .3s;
    cursor: pointer;
    &--text{
      text-transform: capitalize;
    }

     &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: rgba($color-tertiary-dark, .1)!important;
     border: .2rem rgba($color-white, .5) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-dark, .13)!important;
    border: .2rem rgba($color-white, .4) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
    -moz-transform: translateY(-.1rem);
    -ms-transform: translateY(-.1rem);
    -o-transform: translateY(-.1rem);
}
  }


  &__view-option-1-and-2 {
    width: 70%;

    // border: .1rem red solid;
    font-size: 1.3rem;
    font-weight: 400;
    background-color: rgba($color-tertiary-dark, .05);
    box-shadow: 0 .3rem .3rem rgba($color-tertiary-dark, .3);
    border: .2rem rgba($color-white, .8) solid;
    // margin: 1rem 0 .2rem 3rem !important;
    margin: 1rem auto;
    padding: 1.5rem;
    // padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    transition: all .3s;
    cursor: pointer;
    &--text{
      text-transform: capitalize;
    }

     &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: rgba($color-tertiary-dark, .1)!important;
     border: .2rem rgba($color-white, .5) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-dark, .13)!important;
    border: .2rem rgba($color-white, .4) solid;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
    -moz-transform: translateY(-.1rem);
    -ms-transform: translateY(-.1rem);
    -o-transform: translateY(-.1rem);
}
  }


  &__size-quatities-option-label {
    width: 80%;
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    // color: rgba($color-secondary-dark, 1);
    background-color: rgba($color-white, .3);
    box-shadow: 0 .15rem .15rem rgba($color-tertiary-dark, .3);
    border: .2rem rgba($color-white, .8) solid;
    margin: .8rem auto .2rem auto !important;
    padding: 1.5rem;
    padding-left: 2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    transition: all .3s;
    &--text{
      text-transform: capitalize;
    }
  }

  &__size-qty-label {
    // border: .1rem red solid;
    font-size: 1.4rem;
    font-weight: 500;
    margin: 1.5rem 1rem 1rem -1rem !important;
    // padding:1rem;
    letter-spacing: .2rem;
    display: flex;
   justify-content: flex-start !important;
    justify-items: center;
    transition: all .3s;
    &--text{
      text-transform: capitalize;
    }
  }

  &__sizes-qtys-label {
    // border: .1rem red solid;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 2.3rem 1rem 1rem auto !important;
    // padding:1rem;
    letter-spacing: .2rem;
    display: flex;
    justify-content:center;
    justify-items: center;
    transition: all .3s;
    &--text{
      text-transform: capitalize;
    }
  }

  &__label-upload-pic {
    font-size: 1.6rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    letter-spacing: .5rem;
    display: flex;
    // justify-content: start;
    // justify-items: start;
    transition: all .3s;
  }

  &__label-2 {
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: 1rem;
    letter-spacing: .5rem;
    display: flex;
    transition: all .3s;
  }
  &__label--profile-address-edit-form {
    font-size: 1.7rem !important;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: .5rem;
    letter-spacing: .5rem;
    display: flex;
    transition: all .3s;
    @include respond(phone){
      font-size: 3rem !important;
    }
  }

  &__label-shipping-address-update {
     color: rgba($color-black, 1);
    font-size: 1.6rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: 1rem;
    letter-spacing: .5rem;
    display: flex;
    transition: all .3s;

    @include respond(phone){
      font-size: 3rem;
      font-weight: 500;
      letter-spacing: .2rem;

    }
  }

  &__label-press-release-name {
    width:20% !important;
    display:flex;
    flex-direction:column;
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 2rem;
    margin-top: 7rem;
    margin-bottom: 1rem;
    letter-spacing: .5rem;
    // display: flex;
    transition: all .3s;
  }

  &__label-seller-profile--name {
    width:50%;
    font-size: 1.7rem;
    font-weight: 700;
    color: rgba($color-tertiary-light, .5);
    // border-bottom: rgba($color-black, .1) .2rem solid;
    margin-right: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    padding-bottom: -12rem;
    transition: all .3s;
  }

  &__label-rating {
    font-size: 2.5rem;
    font-weight: 500;
    margin-left: 2rem;
    margin-top: .7rem;
    margin-bottom: 1rem;
    letter-spacing: .3rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    color: rgba($color-tertiary-light, 1);
    transition: all .3s;
  }

  //NOTE Important!!: below &__input and &__label are children of form_group.
  // Also, input and label are sibling and label comes after, not before, input directely. there is no other elements separate them.
  // Therefore we use: " + ". If there were other elements between them, then we use: " ~ " instead of " + "
  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__radio-group {
    width: 49%;
    display: inline-block;
  }

  &__radio-input{
    display: none;
  }

  &__radio-label {
    font-size: $default-font-size;
    cursor: pointer;
    position: relative;
    padding-left: 4.5rem;
  }

  &__radio-button {
    height: 3rem;
    width: 3rem;
    border: 5px solid $color-primary;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -.4rem;
    left: 0;

    &::after {
       content: "";
       display: block;
       height: 1.3rem;
       width: 1.3rem;
       border-radius: 50%;
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%,-50%);
       background-color: $color-primary;
       opacity: 0;
       transition: opacity .2s;
    }
  }

  &__radio-input:checked + &__radio-label &__radio-button::after{
    opacity: 1;
  }

}

.form-1 {
  padding: 2rem;
  background-color: rgba($color-white, .6);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 90%;
   padding-left: 10rem;
   padding-right: 10rem;
   margin: 0 auto;
   border: 1px solid #e3e4f1fd;
 }

}
.form-dashboard-order {
  padding: 2rem 2rem 8rem 2rem;
  background-color: rgba($color-white, .9);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
    width: 100%;

   padding-top: 5rem;
   padding-bottom: 5rem;
   padding-left: 5rem;
   padding-right: 5rem;
  //  margin: 5rem auto 5rem auto;
   border: 1px solid #e3e4f1fd;

   @include respond(phone){
    padding: 1rem auto !important;
   }
 }

 &__width-orders {
   width: 95%;
   padding-left: 5rem;
   padding-right: 5rem;
   margin: -4rem auto auto auto;
   border: 1px solid #e3e4f1fd;
 }
 &__width-list-users {
   width: 95%;
   padding-left: 3rem;
   padding-right: 3rem;
   margin: 38rem auto auto auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-single-product-add {
  height: fit-content;
  border: 2rem rgba(56, 116, 236, 0.6) solid;
  background-color: rgba(252, 244, 237, 0.5);


 &__width {
    width: 100%;
    max-height: fit-content;
   padding-top: 5rem;
   padding-bottom: 2rem;
   padding-left: 3rem;
   padding-right: 3rem;
   margin: 5rem auto 0 auto;
   border: 1px solid #eff0f8fd;

 }

}

.form-cart-order {
  // width: fit-content;
  height: fit-content;
  padding: 2rem;
  background-color: rgba($color-white, .75);
  font-weight: 700;
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);

   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="form-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }

 &__width {
    width: 100%;

   padding-top: 5rem;
   padding-bottom: 5rem;
   padding-left: 2rem;
   padding-right: 2rem;
   margin: 1rem auto 5rem auto;
   border: 1px solid #e3e4f1fd;

 }

}

.form-cart-order-summary {
  // width: fit-content;
  height: fit-content;
  padding: 2rem .1rem !important;
  padding-bottom: 4rem !important;
  background-color: rgba($color-white, .3);
  box-shadow: 0 .1rem .3rem rgba($color-black, .3);

   @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="form-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-1;
     }
   }

 &__width {
    width: 100%;

   padding-top: 5rem;
   padding-bottom: 5rem;
   padding-left: 2rem;
   padding-right: 2rem;
   margin: 1rem auto 5rem auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-checkout-shipping-address-update {
  // width: fit-content;
  // height: fit-content;
  padding: 2rem;
  background-color: rgba($color-white, .3);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
    width: 100%;
// border: .2rem blue solid;
   padding-top: 5rem;
   padding-bottom: 2rem;
   padding-left: 5rem;
   padding-right: 5rem;
   margin: 1rem auto 5rem auto;
   border: 1px solid #e3e4f1fd;

   @include respond(phone){
   padding-left: .5rem;
   padding-right: .5rem;
    margin: 1rem 0 5rem 0;
   }
 }
}

.form-checkout-payment {
 display: flex;
//  align-items: center !important;
justify-content: center;
 justify-items: center !important;
  // width: fit-content;
  // height: fit-content;

  background-color: rgba($color-white, .3);
  box-shadow: .3rem .3rem .5rem rgba($color-black, .1);

 &__width {
    // width: 100%;
 display: flex;
//  align-items: center !important;
 justify-items: center !important;
//  justify-content: center;
  //  padding-top: 5rem;
  //  padding-bottom: 2rem;
padding: 4rem ;
  //  padding-left: 18vw;
  //  padding-right: 5rem;
   margin: 5rem auto 31rem auto;
   border: 1px solid #e2e2eefd;
 }
}

.form-checkout-coupon {
  // width: fit-content;
  // height: fit-content !important;
  padding: .5rem;
  background-color: rgba($color-white, .4);
  // box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
    width: 80%;
height: fit-content !important;
   padding-top: .5rem;
   padding-bottom: 5rem;
   padding-left: 5rem;
   padding-right: 5rem;
   margin: 1rem auto 1rem auto !important;
   border: 1px solid #e3e4f1fd;

   @include respond(phone){
    width: 90%;
   }
 }
}


.form-profile-shipping-address-update {
  // width: fit-content;
  // height: fit-content;
  padding: 2rem;
  // background-color: rgba($color-white, .2);
  // box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
    width: 100%;

   padding-top: 5rem;
   padding-bottom: 5rem;
   padding-left: 5rem;
   padding-right: 5rem;
   margin: -3rem auto 5rem auto;
   border: 1px solid #e3e4f1fd;
 }
}


.form-checkout-summary {
  // border: 1rem red solid;
  // display: flex;
  // flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin-top: 3rem;
  padding: 1rem;
  background-color: rgba($color-white, .3);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);



 &__width {
   width: 100%;
   padding-left: 2rem;
   padding-right: 2rem;
   padding-bottom: 5rem;
   font-weight: 400;
  //  margin: 10rem 0;
  //  border: 1px solid #e3e4f1fd;
 }

//  &__width-orders {
//    width: 95%;
//    padding-left: 5rem;
//    padding-right: 5rem;
//    margin: 0 auto;
//    border: 1px solid #e3e4f1fd;
//  }

}
.form-cart-summary {
  // border: 1rem red solid;
  // margin-top: 5rem;
  padding: 1rem 3rem;
  background-color: rgba($color-white, .5);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 90%;
  //  padding-left: 2rem;
  //  padding-right: 2rem;
   padding-bottom: 5rem;
   font-weight: 400;
   margin: 5rem auto !important;
  //  margin-left: 5rem;
  //  border: 1px solid #e3e4f1fd;
  @include respond(phone){
    margin-top: 0 !important;
  }
 }

 &__width-orders {
   width: 95%;
   padding-left: 5rem;
   padding-right: 5rem;
   margin: 0 auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-cart-table {
  // width: fit-content;
  margin-top: 5rem;

  padding-top: 2rem;
}

.form-dashboard-profile {
  padding: 2rem;
  background-color: rgba($color-white, .15);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 100%;
   padding-left: 15rem;
   padding-right: 5rem;
   margin: 5rem auto;
   border: 1px solid #0b1beefd;
 }
}

.form-profile {
  padding: 2rem;
  background-color: rgba($color-white, .3);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 85%;
   padding-left: 2rem;
   padding-right: 2rem;
   margin: 5rem 0;
   border: 1px solid #e3e4f1fd;
 }
}

.form-create-videos {
  padding: 2rem;
  background-color: rgba($color-white, .2);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 90%;
   padding-left: 10rem;
   padding-right: 10rem;
   margin: 0 auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-create-coupon {
  padding: 2rem;
  background-color: rgba($color-white, .2);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 90%;
   padding-left: 10rem;
   padding-right: 10rem;
   margin: 7rem auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-admin-create-coupon {
  margin-top: 25rem !important;
  padding: 2rem;
  background-color: rgba($color-white, .2);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 90%;
   padding-left: 10rem;
   padding-right: 10rem;
   margin: 7rem auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-seller-create-product {
  padding: 2rem;
  background-color: rgba($color-white, .2);
 box-shadow: 0 .5rem 1rem rgba($color-black, .09);

 &__width {
   width: 90%;
   padding-left: 5rem!important;
   padding-right: 5rem !important;
   margin: 7rem auto;
   border: 1px solid #e3e4f1fd;
 }

}

.form-seller-upload-delivery-pictures {

  background-color: rgba($color-white, .4);
 box-shadow: 0 .5rem 1rem rgba($color-black, .03);



 &__width {
   width: 75%;
   height: fit-content;
   padding: 1rem;
   //padding-left: 5rem!important;
   //padding-right: 5rem !important;
  //  padding: 0 !important;
   padding-top: 7rem;
   margin: 3rem auto;
   margin-top: 2rem;
  //  border: 1px solid #0a19effd;
   border-radius: 1rem;
   -webkit-border-radius: 2rem;
   -moz-border-radius: 1rem;
   -ms-border-radius: 1rem;
   -o-border-radius: 1rem;
}
 &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);

  }
}

.form-contact-seller-customer-admin-topic {

  background-color: rgba($color-tertiary-dark, .04);
 box-shadow: 0 .5rem 1rem rgba($color-black, .03);
 border: .01rem solid rgba($color-black, .05);



 &__width {
   width: 95%;
   height: fit-content;
   font-size: 1.7rem;
   padding: 1rem;
   //padding-left: 5rem!important;
   //padding-right: 5rem !important;
   //padding: 0 !important;
   //padding-top: 7rem;
   margin: 0 2.5%;
  //  margin-top: 1rem;
   //border: 1px solid #0a19effd;
   border-radius: 1rem;
   -webkit-border-radius: 1rem;
   -moz-border-radius: 1rem;
   -ms-border-radius: 1rem;
   -o-border-radius: 1rem;

   @include respond(phone){
    font-size: 3rem;
   }
}

 &:hover {
    transform: translateY(-.2px);
    -webkit-transform: translateY(-.2px);
    box-shadow: 0 1rem 2rem rgba($color-black, .02);
    -moz-transform: translateY(-.2px);
    -ms-transform: translateY(-.2px);
    -o-transform: translateY(-.2px);
}

  &:active,
  &:focus {
    outline: none;
    // transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .05);

  }
}

.form-seller-press-release-pulic {
  // padding: 2rem;
  background-color: rgba($color-white, .2);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);


 &__width {
   width: 100%;
   padding-left: 5rem;
   padding-right: 5rem;
   padding-bottom: 5rem;
   margin: 7rem auto;
   margin-left: 2rem;
   border: 1px solid #e3e4f1fd;
    // border: .1rem red solid;
 }

}

.form-seller-customer-pictures {
  // padding: 2rem;
  background-color: rgba($color-white, .2);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);


 &__width {
   width: 100%;
   padding-left: 5rem;
   padding-right: 5rem;
   padding-bottom: 5rem;
   margin: 7rem auto;
  //  margin-left: 2rem;
   border: 1px solid #e3e4f1fd;
    // border: .1rem red solid;
 }

}

.form-admin-create-product {
  padding: 5rem;
  padding-left: 8rem;
  padding-right: 1rem;
  background-color: rgba($color-white, .25);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);

  color: $color-primary-light-2;


 &__width {
   width: 100%;

   margin: 0 auto 5rem auto;
  //  border: 1px solid #e3e4f1fd;
 }

}

.form-seller-create-product {
  padding: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: rgba($color-white, .25);
  box-shadow: 0 .5rem 1rem rgba($color-black, .09);

  color: $color-primary-light-2;


 &__width {
   width: 100%;

   margin: 0 auto 5rem auto;
  //  border: 1px solid #e3e4f1fd;
 }

}

.form-seller-create-home-form {
  padding: 5rem ;
  // padding-left: 8rem;
  // padding-right: 1rem;
  background-color: rgba($color-white, .25);
  box-shadow: 0 .5rem 1rem rgba($color-black, .08);

  color: $color-primary-light-2;


 &__width {
   width: 100%;

   margin: 0 auto 5rem auto;
  //  border: 1px solid #e3e4f1fd;
 }

}
.form-create-category {
  padding: 2rem;
  background-color: rgba($color-white, .3);
 box-shadow: 0 .5rem 1rem rgba($color-black, .05);

 &__width {
   width: 90%;
   padding-left: 4rem;
   padding-right: 4rem;
   margin: 0 auto;
   border: 1px solid #e3e4f1fd;
 }

}


.form-2 {

  padding: 5rem ;
  background-color: rgba($color-white, .3);
 box-shadow: 0 .5rem 1rem rgba($color-black, .02);
  // margin: 0 3rem;

//  &__width {
//    width: 75%;
//    margin: 5rem auto;
//    border: .1rem solid #6b75fa;
//  }

}

.form-general-coupon {

  padding: 5rem ;
  background-color: rgba($color-tertiary-dark, .04);
//  box-shadow: 0 .5rem 1rem rgba($color-black, .01);
 border: .1rem rgba($color-black, .08) solid;
  // margin: 0 3rem;

//  &__width {
//    width: 75%;
//    margin: 5rem auto;
//    border: .1rem solid #6b75fa;
//  }

}


.form-update-profile {

  padding: 5rem ;
  background-color: rgba($color-white, .9);
 box-shadow: 0 .2rem .2rem rgba($color-black, .2);
  // margin: 0 3rem;

//  &__width {
//    width: 75%;
//    margin: 5rem auto;
//    border: .1rem solid #6b75fa;
//  }

}


.form-2-shipping-address {

  padding: 6rem;
  padding-top: 12rem;
  background-color: rgba($color-white, 1);
 box-shadow: 0 .5rem 1rem rgba($color-black, .02);
  margin-top: 5rem;

 &__width {
   width: 100%;
   margin: 2rem auto;
  //  border: .1rem solid #6b75fa;
 }

}

.form-2-profile {

  padding: 6rem 3rem;
  background-color: rgba($color-white, .9);
 box-shadow: 0 .2rem .2rem rgba($color-black, .05);
  // margin: 0 1rem;

 &__width {
   width: 100%;
  //  margin: 0 ;
  //  border: .1rem solid #6b75fa;
 }

}

.form-3 {
  text-align: start;
  margin: -5rem 0 5rem 0;
  padding: 5rem 0rem 5rem 7rem;
  // background-color: rgba($color-tertiary-dark, .09);
   background-color: rgba($color-white, .4 );
 box-shadow: 0 .5rem 1rem rgba($color-black, .1);

 &__width {
   width: 75%;
   margin: 0 auto;
   border: .1rem solid #6b75fa;
 }

}

.form-inline {
  // border: .5rem rgb(246, 255, 0) solid;
  width: 100%;
  height: 3.95rem;
  display: flex;
  // margin-bottom: .02rem;
  // line-height: 2rem;
  font-size: 2.5rem;

}

.form-inline-search {
  // border: .5rem rgb(246, 255, 0) solid;
  width: 100%;
  height: 3.95rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  // margin-bottom: .02rem;
  // line-height: 2rem;
  font-size: 2.5rem;

}

.form-control {
  //display: flex;
  //justify-content: center;
  width:40vw !important;
  height: fit-content;
  font-size: 2.5rem;
  color: rgba($color-primary-light-2, 1);
  //line-height: 2rem;
  // border: .1rem rgb(21, 255, 0) solid;
  background-color:rgba($color-white, .2);
  //background-image:     linear-gradient(to right,  $color-primary-light,  $color-primary-dark);
  //border: .1rem $color-grey-light-1 solid;
  //padding: .5rem 1rem .5rem 1rem;
  margin:0 1rem 0  0!important;
  //margin-right: 5rem;
  border-radius:.5rem ;
  -webkit-border-radius:.5rem ;
  -moz-border-radius:.5rem ;
  -ms-border-radius:.5rem ;
  -o-border-radius:.5rem ;
  //background-image: linear-gradient(180deg,//     rgba(69, 81, 248, 0.6) 5%,//     rgba(69, 81, 248, 0.4) 15%,//     rgba(67, 70, 241, 0.5) 35%,//     rgba(69, 81, 248, 0.4) 40%,//     rgba(69, 81, 248, 0.4) 45%,//     rgba(69, 81, 248, 0.4) 55%,//     rgba(69, 81, 248, 0.6) 65%,//     rgba(56, 116, 236, 0.6) 100%,//     rgba(15, 91, 241, 0.7) 90%);
  //url(../image/hero.jpg):;

  @include respond(phone){
     width:70vw !important;
    font-size: 3.5rem;
  }

  &:active,&:focus {
    outline: none;
    font-size: 3rem;
    background-color:rgba($color-white, .8);
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba(69, 81, 248, .1);
    padding-left: 2rem;


  }


}

.form-tracking-input {
  width: 75%;
  height: 50vh;
  font-size: 1.5rem;
  color: rgba($color-primary-light-2, 1);
  // line-height: 2rem;
  // border: .5rem rgb(21, 255, 0) solid;
  //  background-image:
  //   linear-gradient(
  //   to right,
  //   $color-primary-light,
  //   $color-primary-dark);
  // border: .1rem $color-grey-light-1 solid;
  // padding:10rem auto 3rem auto !important;
  margin: 3rem auto!important;
  // padding-bottom: 5rem;
  border: none !important;
   background-color:rgba($color-white, .7);
   box-shadow: .5rem .5rem .5rem rgba($color-black, .2);
  border-radius:1rem ;

//  background-image: linear-gradient(180deg,
//     rgba(69, 81, 248, 0.6) 5%,
//     rgba(69, 81, 248, 0.4) 15%,
//     rgba(67, 70, 241, 0.5) 35%,
//     rgba(69, 81, 248, 0.4) 40%,
//     rgba(69, 81, 248, 0.4) 45%,

//     rgba(69, 81, 248, 0.4) 55%,
//     rgba(69, 81, 248, 0.6) 65%,
//     rgba(56, 116, 236, 0.6) 100%,
//     rgba(15, 91, 241, 0.7) 90%);


    // url(../image/hero.jpg);

    &__text-area{
      width: 90%;
      font-size: 1.5rem;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-tertiary-dark, .03);
      padding: 2rem 3rem !important;
      margin: 3rem auto 0 auto !important;
      border: .1rem solid rgba($color-black, .1);
      // margin-right: 11rem;
      border-radius:1rem ;
       &:active, &:focus {
        outline: none;
        border: .1rem solid rgba($color-black, .1);
        background-color:rgba(#979798fd, .03);
        // transform: translateY(-1px);
        // box-shadow: 0 .5rem 1rem rgba(17, 23, 99, 0.1);


      }
    }

    &__text-area-title{
      width: 65% !important;
      font-size: 1.7rem;
      font-weight: 700;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-tertiary-dark, .03);
      box-shadow: 0 .5rem 1rem rgba(69, 81, 248, .05);
      padding: 2rem 3rem !important;
      margin: 3rem auto 0 auto !important;
      // margin-right: 11rem;
      border-radius:1rem ;


    }





}

.form-delivery-confirmation {
  width: 100%;
  //height: 55%;
  //height: 75vh;
  font-size: 1.5rem;
  //margin-top: .5rem;
  color: rgba($color-primary-light-2, 1);
  //padding-top: 1rem;
  //line-height: 2rem;
  //border: .5rem rgb(21, 255, 0) solid;
  //background-image:  //   linear-gradient(  //   to right,  //   $color-primary-light,  //   $color-primary-dark);
  //border: .1rem $color-grey-light-1 solid;
  //padding:10rem auto 3rem auto !important;
  //margin: 0 auto!important;
  //margin-top: -12rem !important;
  padding-bottom: 1rem;
  border: rgba(white, .8) solid .2rem !important;
  background-color:rgba($color-white, .01);
  box-shadow: 5rem 5rem 5rem rgba($color-black, .1);
  border-radius:3rem ;
  //z-index: 110;
  //background-image: linear-gradient(180deg,//     rgba(69, 81, 248, 0.6) 5%,//     rgba(69, 81, 248, 0.4) 15%,//     rgba(67, 70, 241, 0.5) 35%,//     rgba(69, 81, 248, 0.4) 40%,//     rgba(69, 81, 248, 0.4) 45%,//     rgba(69, 81, 248, 0.4) 55%,//     rgba(69, 81, 248, 0.6) 65%,//     rgba(56, 116, 236, 0.6) 100%,//     rgba(15, 91, 241, 0.7) 90%);

  -webkit-border-radius:3rem ;
  -moz-border-radius:3rem ;
  -ms-border-radius:3rem ;
  -o-border-radius:3rem ;
    &__background{
      width: 65%;
      //margin-top: -2rem;
      border-radius: 3rem;
      -webkit-border-radius: 3rem;
      -moz-border-radius: 3rem;
      -ms-border-radius: 3rem;
      -o-border-radius: 3rem;
      margin: 3rem auto!important;
      //padding: .5rem;
      margin-top: -3rem !important;
      background-color: rgba($color-white, .12);
      box-shadow: 5rem 5rem 5rem rgba($color-black, .3);
      //z-index:100;
}


    &__note{
      width: 75% !important;
      display: flex;
      flex-direction: row;
      margin: .5rem auto;
      padding: 0 2rem;
      background-color: rgba($color-Greenprimary-dark, .02);
      color: rgba($color-black, .6);
      font-size: 1.2rem;
      text-align: justify;
      &--title{
        display: flex;
        justify-content: flex-start;
        color:rgba($color-secondary-dark, 1);
      }

      &--text{
        display: flex;

      }
    }
    &__text-area{
      width: 90%;
      font-size: 1.5rem;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-tertiary-dark, .03);
      padding: 2rem 3rem !important;
      margin: 3rem auto 0 auto !important;
      border: .1rem solid rgba($color-black, .1);
      // margin-right: 11rem;
      border-radius:1rem ;
       &:active, &:focus {
        outline: none;
        border: .1rem solid rgba($color-black, .1);
        background-color:rgba(#979798fd, .03);
        // transform: translateY(-1px);
        // box-shadow: 0 .5rem 1rem rgba(17, 23, 99, 0.1);


      }
    }

    &__text-area-title{
      width: 90% !important;
      font-size: 1.5rem;
      font-weight: 600;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-secondary-dark, .03);
      box-shadow: 0 .5rem 1rem rgba(69, 81, 248, .1);
      padding: 2rem 3rem !important;
      margin: 3rem auto 0 auto !important;
      // margin-right: 11rem;
      border-radius:1rem ;


    }

    &__upload-delivery-picture-title{
      width: 65% !important;
      font-size: 1.5rem;
      font-weight: 500;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-tertiary-dark, .03);
      box-shadow: 0 .5rem 1rem rgba(69, 81, 248, .05);
      padding: 2rem 3rem !important;
      margin: 0 auto 0 auto !important;
      // margin-right: 11rem;
      border-radius:1rem ;


    }





}

.form-contact-seller-customer-admin {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  //height: 55%;
  //height: 75vh;
  font-size: 1.7rem;
  //margin-top: .5rem;
  color: rgba($color-primary-light-2, 1);
  //padding-top: 1rem;
  //line-height: 2rem;
  //border: .5rem rgb(21, 255, 0) solid;
  //background-image:  //   linear-gradient(  //   to right,  //   $color-primary-light,  //   $color-primary-dark);
  //border: .1rem $color-grey-light-1 solid;
  //padding:10rem auto 3rem auto !important;
  //margin: 0 auto!important;
  //margin-top: -12rem !important;
  padding-bottom: 1rem;
  // border: rgba(white, .8) solid .2rem !important;
  background-color:rgba($color-white, .03);
  box-shadow: 5rem 5rem 5rem rgba($color-black, .1);
  border-radius:2rem ;
  //z-index: 110;
  //background-image: linear-gradient(180deg,//     rgba(69, 81, 248, 0.6) 5%,//     rgba(69, 81, 248, 0.4) 15%,//     rgba(67, 70, 241, 0.5) 35%,//     rgba(69, 81, 248, 0.4) 40%,//     rgba(69, 81, 248, 0.4) 45%,//     rgba(69, 81, 248, 0.4) 55%,//     rgba(69, 81, 248, 0.6) 65%,//     rgba(56, 116, 236, 0.6) 100%,//     rgba(15, 91, 241, 0.7) 90%);
  -webkit-border-radius:2rem ;
  -moz-border-radius:2rem ;
  -ms-border-radius:2rem ;
  -o-border-radius:2rem ;
  &__background{
      width: 65%;
      //margin-top: -2rem;
      border-radius: 2rem;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      -ms-border-radius: 2rem;
      -o-border-radius: 2rem;
      margin: 3rem auto!important;
      //padding: .5rem;
      margin-top: -3rem !important;
      background-color: rgba($color-white, .12);
      box-shadow: 5rem 5rem 5rem rgba($color-black, .3);
      //z-index:100;
      @include respond(phone){
        width: 80vw;
      }
}


    &__note{
      width: 75% !important;
      display: flex;
      flex-direction: row;
      margin: .5rem auto;
      padding: 0 2rem;
      background-color: rgba($color-Greenprimary-dark, .02);
      color: rgba($color-black, .6);
      font-size: 1.4rem;
      text-align: justify;

      @include respond(phone){
        font-size: 2.5rem !important;
      }
      &--title{
        display: flex;
        font-size: 1.7rem;
        justify-content: flex-start;
        color:rgba($color-secondary-dark, 1);

        @include respond(phone){
          font-size: 2.5rem;
        }
      }

      &--text{
        display: flex;

      }
    }
    &__text-area{
      width: 90%;
      font-size: 1.5rem;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-tertiary-dark, .03);
      padding: 2rem 3rem !important;
      margin: 3rem auto 0 auto !important;
      border: .1rem solid rgba($color-black, .1);
      // margin-right: 11rem;
      border-radius:1rem ;
       &:active, &:focus {
        outline: none;
        border: .1rem solid rgba($color-black, .1);
        background-color:rgba(#979798fd, .03);
        // transform: translateY(-1px);
        // box-shadow: 0 .5rem 1rem rgba(17, 23, 99, 0.1);


      }
    }

    &__text-area-title{
      width: 90% !important;
      font-size: 1.7rem;
      font-weight: 600;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-secondary-dark, .03);
      box-shadow: 0 .5rem 1rem rgba(69, 81, 248, .1);
      padding: 2rem 3rem !important;
      margin: 3rem auto 0 auto !important;
      // margin-right: 11rem;
      border-radius:1rem ;

      @include respond(phone){
        font-size: 3rem;
      }


    }

    &__upload-delivery-picture-title{
      width: 65% !important;
      font-size: 1.5rem;
      font-weight: 500;
      color: rgba($color-primary-light-2, 1);
      background-color: rgba($color-tertiary-dark, .03);
      box-shadow: 0 .5rem 1rem rgba(69, 81, 248, .05);
      padding: 2rem 3rem !important;
      margin: 0 auto 0 auto !important;
      // margin-right: 11rem;
      border-radius:1rem ;


    }





}

.input, #phone {
  border:none !important;
  background-color:rgba($color-white, .5);
  padding: 1rem;
}

.shipping-rate {
  width:15% !important;
    font-size: 1.4rem;
    font-family: inherit;
    color: inherit;
    margin-top:.5rem;
    margin-bottom: .9rem;
    margin-left:3rem;
    padding: .5rem;
    border-radius: 2px;
    background-color:rgba($color-primary-light-01, .02);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}

.shipping-plus-or {
  // width:15% !important;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-family: inherit;
    color: inherit;
    margin-top:.7rem;
    margin-bottom: .9rem;
    margin-right:1rem;
    padding: .5rem 1rem;
    border-radius: 2px;
    background-color:rgba($color-secondary-dark, .02);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}

.shipping-standard {
  width:20% !important;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-family: inherit;
    color: inherit;
    margin-top:.7rem;
    margin-bottom: .9rem;
    margin-right:1rem;
    padding: .5rem .2rem .5rem 1rem;
    border-radius: 2px;
    background-color:rgba($color-secondary-dark, .02);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}


.shipping-standard-1 {
  width:20% !important;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-family: inherit;
    color: inherit;
    margin-top:.7rem;
    margin-bottom: .9rem;
    margin-right:1rem;
    margin-left:1rem;
    padding: .5rem .2rem .5rem .1rem;
    border-radius: 2px;
    // background-color:rgba($color-white, .2);
    // border: none!important;
    // border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}


.shipping-plus {
  width:10% !important;
  display:flex;
  flex-direction:row;
  justify-items:center;
  justify-content:center;
    font-size: 1.4rem;
    font-family: inherit;
    color: red;
    // margin-top:.7rem;
    // margin-bottom: .9rem;
    margin-right:2rem;
    margin-left:1rem;
    padding: 2rem 1rem;
    border-radius: 2px;
    // background-color:rgba($color-secondary-dark, .02);
    // border: none!important;
    // border:rgba($color-black, .07) .1rem solid;
    border-bottom: 3px solid transparent;
    // width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}


.shipping-standard-input {
  width:15% !important;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-family: inherit;
    color: inherit;
    margin-top:.5rem;
    margin-bottom: .5rem;
    margin-right:1rem;
    margin-left:1rem;
    padding: .5rem 1rem;
    border-radius: 2px;
    background-color:rgba($color-tertiary-dark, .02);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}


.shipping-standard-input-1 {
  width:100% !important;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-family: inherit;
    color: inherit;
    margin-top:.5rem;
    margin-bottom: .5rem;
    margin-right:.5rem;
    margin-left:.5rem;
    padding: .5rem .1rem;
    padding-left:.2rem !important;
    border-radius: 2px;
    background-color:rgba($color-secondary-dark, .02);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    // width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}


.color-rgb-input {
  width:100% !important;
  // height: 100%;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: inherit;
    color: rgba($color-tertiary-light, 1) !important;
    margin: 1rem 0 !important;
    margin-right: 1rem;
    // padding: 13rem auto !important;
    padding-right: 0 !important;
    border-radius: 2px;
    background-color:rgba($color-secondary-dark, .035);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    // width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;
      padding-right: 0 !important;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}

.size-qty-input {
  width:100% !important;
  height: 100%;
  display:flex;
  flex-direction:row;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: inherit;
    color: rgba($color-tertiary-light, 1);
    margin: 0 0 1rem auto !important;
    // margin-left: .2rem;
    padding: 1rem .2rem !important;
    padding-left: 1rem !important;
    border-radius: 2px;
    background-color:rgba($color-secondary-dark, .03);
    // border: none!important;
    border:rgba($color-black, .1) .1rem solid;
    border-bottom: 3px solid transparent;
    // width: 90%;
    // display: block;
    transition: all .3s;

     &:focus {
      outline: none;
      box-shadow: 0 .1rem .2rem rgba($color-black, .2);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }

}

 .ql-editor{
  width: 100%;
  height: 100%;
  min-height: 27rem;
  max-height: 35rem;
  // margin-bottom: 1rem;
  background-color:rgba($color-white, .4);
  margin: .5rem;
  margin-bottom:5rem !important;
  @include respond(phone){
    height: 25vh !important;
    max-height: none;
  background-color:rgba($color-white, .6);
    font-size: 3.5rem !important;
  }
  // padding: .2rem;
  // border: .1rem rgba($color-black, .1) solid;
}

.ql-snow{
  font-size: 1.3rem !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 32rem;
  margin-bottom: .2rem;
  // padding: .2rem !important;
   background-color:rgba($color-white, .15);
   border:none;
  // border: .3rem red solid !important;

}

.ql-toolbar{
  padding: 1rem !important;
  // border: .2rem red solid;
  background-color:rgba($color-tertiary-dark, .05);
}


