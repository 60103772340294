.login-btn {
  &:link,
  &:visited {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: $default-font-size;
    padding: 1rem 2rem;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    -webkit-transition: all .2s;
    // box-shadow: 0 1rem 2rem rgba($color-black,.2);

  }
  &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    color: rgba($color-tertiary-light, 1) !important;
    // background-color: transparent !important;
    background-color:rgba($color-white, .45) !important;

    // box-shadow: 0 1rem 2rem rgba($color-tertiary-light,.5);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    color: rgba($color-white, 1) !important;
    background-color: rgba($color-white, .5) !important;
    box-shadow: 0 .1rem .1rem rgba($color-black, .5);
  }


  &--white {
    color: rgba($color-white, 1) !important;
    // background-color: rgba($color-black, .01) !important;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);

    &::after {
    background-color: rgba($color-white, .3);
    }

    // &:hover {
    //   background-color: rgba($color-white, .8);
    // }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

  &--white {
    color: rgba($color-white, 1) !important;
    // background-color: rgba($color-black, .01) !important;
    box-shadow: 0 .1rem .1rem rgba($color-black, .1);

    &::after {
    background-color: rgba($color-white, .3);
    }

    // &:hover {
    //   background-color: rgba($color-white, .8);
    // }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

   &::after {
    content:"";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    transition: all .8s;
    -webkit-transition: all .8s;
  }

  &-animated {
    animation: moveInBottom .5s ease-out .75s;
    -webkit-animation: moveInBottom .5s ease-out .75s;
    animation-fill-mode: backwards;
  }
}

// //////////////////////////////////////////////////////////////////////////
//             // :::::::::::::OLD STYLES BELOW:::::::::
// //////////////////////////////////////////////////////////////////////////



.btn {
  &:link,
  &:visited {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: $default-font-size;
    padding: 1rem 2rem;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    -webkit-transition: all .2s;
    // box-shadow: 0 1rem 2rem rgba($color-black,.2);
    @include respond(phone){
      font-size: 2.5rem;
      text-transform: capitalize;
    }

  }
  &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;

    // box-shadow: 0 1rem 2rem rgba($color-tertiary-light,.5);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    background-color: rgba($color-tertiary-light, .2)!important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;
    @include respond(phone){
      font-size: 3rem !important;
      width: fit-content;
      padding: 2rem 5rem !important;

    }

    &::after {
    background-color: $color-white;
    }

    &:hover {
      background-color: rgba($color-white, .3);
    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

  &--customer-review {
    width: 25%;
    height: fit-content;
    margin: 0 auto !important;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .3rem;
    background-color: rgba($color-tertiary-dark, .25);
    box-shadow: 0 .1rem .1rem rgba($color-black, .5);
    color: $color-white !important;
    border: rgba($color-white, .5) .1rem solid !important;

    border-radius: 1rem;
     -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    //&:;
    // :after{
    // background-color: $color-white;
    // }

    @include respond(phone){
      width: 35vw;
      font-size: 4rem;
    }

    &:hover {
      background-color: rgba($color-white, .8) !important;
      color: $color-primary-light !important;

    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

  &--white-category-sub-product-seller-name {
    width: fit-content;
    margin: .2rem auto !important;
    padding: .4rem 1.3rem;
    background-color: rgba($color-white, .7);
    color: $color-primary-light;
    font-size: 1.2rem;
    letter-spacing: .2rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    &::after{
    background-color: $color-white;
    }

    &:hover {
      background-color: rgba($color-white, .3);
    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

  &--carousel {
    background-color: $color-Greenprimary;
    color: $color-grey-dark;
    width: fit-content;

    &::after {
    background-color: $color-white;
    }

    &:hover {
      background-color: rgba($color-black, .3);
    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }



  &--green {
    background-color: rgba($color-primary, .9);
    color: $color-white;


    &::after {
    background-color: rgba($color-primary, .9);
    }
    &:hover {
      color: $color-white;
      background-color: rgba($color-primary, .7) ;
    }
  }

  &::after {
    content:"";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    transition: all .8s;
    -webkit-transition: all .8s;
  }

  &-animated {
    animation: moveInBottom .5s ease-out .75s;
    -webkit-animation: moveInBottom .5s ease-out .75s;
    animation-fill-mode: backwards;
  }

}

.btn-checkout {
    width:fit-content;
    display: flex;
    background-color: rgba($color-white, .4);
    // color:rgba($color-black, 1)!important;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight:700;
    margin-bottom: -5rem;
    padding: 2rem  !important;
    border-radius: 5%;
    transition: all .2s;
    position: relative;
    -webkit-transition: all .2s;
   box-shadow: 0 .2rem .2rem rgba($color-black, .2);


  &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  // &:active,
  // &:focus {
  //   outline: none;
  //   transform: translateY(-.1rem);
  //   -webkit-transform: translateY(-.1rem);
  //   background-color: rgba($color-tertiary-light, .2)!important;
  //   box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  // }

  // &--white {
  //   background-color: $color-white;
  //   color: $color-grey-dark;

  //   &::after {
  //   background-color: $color-white;
  //   }

  //   &:hover {
  //     background-color: rgba($color-white, .3);
  //   }

  //   // &:active {
  //   //   background-color: rgba($color-white, .95);
  //   // }
  // }

  // &::after {
  //   content:"";
  //   display: inline-block;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 10rem;
  //   -webkit-border-radius: 10rem;\cart
  //   position: absolute;
  //   top:0;
  //   left: 0;
  //   z-index: -1;
  //   transition: all .8s;
  //   -webkit-transition: all .8s;
  // }

  // &-animated {
  //   animation: moveInBottom .5s ease-out .75s;
  //   -webkit-animation: moveInBottom .5s ease-out .75s;
  //   animation-fill-mode: backwards;
  // }


}

.btn-header {
  &:link,
  &:visited {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: $default-font-size;
    padding: 1rem 2rem;
    margin-top: -22rem;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    -webkit-transition: all .2s;
    // box-shadow: 0 1rem 2rem rgba($color-black,.2);

  }
  &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);

    box-shadow: 0 1rem 2rem rgba($color-black,.3);

     &::after {
      transform: scaleX(1.4) scaleY(1.6);
      -webkit-transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;

    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;

    &::after {
    background-color: $color-white;
    }

    &:hover {
      background-color: rgba($color-white, .3);
    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

  &--white-comment-like {
    background-color: $color-white;
    color: $color-grey-dark;

    &::after {
    background-color: $color-white;
    }

    &:hover {
      background-color: rgba($color-white, .3);
    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }

  &--carousel {
    background-color: $color-Greenprimary;
    color: $color-grey-dark;
    width: fit-content;

    &::after {
    background-color: $color-white;
    }

    &:hover {
      background-color: rgba($color-black, .3);
    }

    // &:active {
    //   background-color: rgba($color-white, .95);
    // }
  }



  &--green {
    background-color: rgba($color-primary, .9);
    color: $color-white;


    &::after {
    background-color: rgba($color-primary, .9);
    }
    &:hover {
      color: $color-white;
      background-color: rgba($color-primary, .7) ;
    }
  }

  &::after {
    content:"";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    transition: all .8s;
    -webkit-transition: all .8s;
  }

  &-animated {
    animation: moveInBottom .5s ease-out .75s;
    -webkit-animation: moveInBottom .5s ease-out .75s;
    animation-fill-mode: backwards;
  }

}

.btn-text {
  &:link,
  &:visited {
    font-size: $default-font-size;
    font-weight: 600;
    color: $color-Greenprimary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    border-radius: .5rem;
    padding: .5rem;
    transition: all .2s;
    -webkit-transition: all .2s;
    -webkit-border-radius: .5rem;
  }

  &:hover {
    background-color: $color-Greenprimary;
    color:$color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

.btn-press-release {
  &:link,
  &:visited {
    font-size: 1.5rem !important;
    font-weight: 600;
    margin-left: 2rem;
    color: rgba($color-tertiary-light, 1);
    display: block;
    text-decoration: none;
    border-bottom: 3px solid $color-primary;
    border-radius: .5rem;
    // padding: .5rem;
    padding: 1rem;
    // padding-left: 10rem;
    transition: all .2s;
    -webkit-transition: all .2s;
    -webkit-border-radius: .5rem;
  }

  &:hover {
    background-color: rgba($color-tertiary-dark, .1);
    color:$color-tertiary-light;
    //padding: .5rem;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}

  &:active {
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
}

.button-card {
  &:link,
  &:visited {
    display: block;
   font-size: $default-font-size;
    padding: 3rem 4rem;
    // padding: .5rem 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
    text-decoration: none;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    transition: all .2s;
    -webkit-transition: all .2s;
  }

  &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);

  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;


    &::after {

    background-color: $color-white;
    }
  }

  &--animated {
      animation: moveInBottom .5s ease-out .75s;
      animation-fill-mode: backwards;
  }
}

.btn-create-product {
  &:link,
  &:visited {
    display: block;
   font-size: $default-font-size;
    padding: 3rem 0;
    // padding: 5rem 2rem;
    // margin-left: 3rem;
    // margin-right: 3rem;
    text-decoration: none;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    transition: all .2s;
    -webkit-transition: all .2s;
  }

  &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);

  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;


    &::after {

    background-color: $color-white;
    }
  }

  &--animated {
      animation: moveInBottom .5s ease-out .75s;
      animation-fill-mode: backwards;
  }
}

.btn-create-coupon {
  &:link,
  &:visited {
    display: block;
   font-size: $default-font-size;
    padding:  0;
    // padding: .5rem 2rem;
    // margin-left: 3rem;
    // margin-right: 3rem;
    text-decoration: none;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    transition: all .2s;
    -webkit-transition: all .2s;
  }

  &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);

  }

  &--tertiary {
    // width: 30%;
    background-color: rgba($color-tertiary-dark, .3);
    color: $color-white;
    font-weight: 700;
    // font-size: 1.8rem;


    &::after {

    background-color: $color-white;
    }
  }

  &--animated {
      animation: moveInBottom .5s ease-out .75s;
      animation-fill-mode: backwards;
  }
}

.btn-customer-picture-submit {
  // &:link,
  // &:visited {
    width: 18%;
    height: 4rem !important;
    display: flex;
    justify-content: center !important;
    justify-items: center !important;
    background-color: rgba($color-white, .4);
    color: $color-primary-light;
    //text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 3.5rem;
    padding-top: 1rem !important;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    //display: block;
    //font-size: $default-font-size;
    //padding:  0;
    ////padding: .5rem 2rem;
    ////margin-left: 3rem;
    ////margin-right: 3rem;
    //text-decoration: none;
    //border-radius: 10rem;
    //-webkit-border-radius: 10rem;
    //transition: all .2s;
    //-webkit-transition: all .2s;
    &:hover {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    background-color: rgba($color-white, .6);
    color: rgba($color-primary-light, 1);
    box-shadow: 0 .2rem .2rem rgba($color-black, .2);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);

  }

  // &--tertiary {
  //   width: 30%;
  //   background-color: rgba($color-tertiary-dark, .3);
  //   color: $color-white;
  //   font-weight: 700;
  // }

  &--animated {
      animation: moveInBottom .5s ease-out .75s;
      animation-fill-mode: backwards;
  }
}

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.button-proceed {
  width: 100% ;
  display: flex;
  justify-content: center;
  padding: .3rem .3rem;
  // border: .2rem white solid;
  // background-color: yellow;
}

.btn--register {
  width: 70%;
  font-size: 1.8rem !important;
  font-weight: 500;
   background-color: rgba(50, 94, 168, .5) !important;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, .7) !important;
  margin: 1rem auto -5rem auto !important;
  padding:.5rem;
  border: .01rem rgba(blue, .5) solid;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    font-size: 4rem !important;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, .7);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--register-complete {
  width: 60%;
  height:fit-content;
  font-size: 1.8rem!important;
  font-weight: 500;
  background-color: rgba(50, 94, 168, .5) !important;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, .7) !important;
  margin: 1rem auto 0 auto !important;
  // padding:1rem auto 2rem auto !important;
  border: .01rem rgba(blue, .5) solid;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, .7);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--login {
  width: 63% !important;
  height: fit-content;
  display:block;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(50, 94, 168, .5) !important;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, .7);
  margin: 3rem auto 0 auto !important;
  padding: .7rem !important;
  border: .01rem rgba(blue, .1) solid;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    height: fit-content;
    font-size: 3rem;
    margin-bottom: 5rem;
    padding: 2rem auto;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
     background-color: rgba(9, 95, 245, .4) !important;
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--forgot-password {
  width: 63% !important;
  height: fit-content;
  display:block;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: rgba(50, 94, 168, .5) !important;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, .7);
  margin: 3rem auto 0 auto !important;
  padding: .7rem !important;
  border: .01rem rgba(blue, .1) solid;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    height: fit-content;
    font-size: 4rem;
    margin-bottom: 5rem;
    padding: 2rem auto;
    border: none;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
     background-color: rgba(9, 95, 245, .4) !important;
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}



.btn--login-google-forgot-password {
  width: 100% !important;
  display:flex;
  flex-direction:column;
  justify-content:center;
  font-size: 1.6rem;
  font-weight: 500;
  // background-color:rgba($color-white, .05) ;
  // box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, .7)!important;
  margin: -3rem auto 0 auto !important;
  padding:.5rem;
  // border: .01rem rgba(blue, .5) solid;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    height: fit-content;
    font-size: 3rem;
    padding: 0;
    margin-bottom: 3rem;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    // background-color: rgba(50, 94, 168, .5);
    // box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}


.btn--login-google {
  width: 60% !important;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
   background-color: rgba(50, 94, 168, .5) !important;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, .7);
  margin: 0 auto 0 auto !important;
  padding:.5rem;
  border: .01rem rgba(blue, .5) solid;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    font-size: 3rem;
    padding: 0 !important;
    height: 5rem;
    margin-top: 1.5rem !important;
    margin-bottom: 3rem !important;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(9, 95, 245, .5) !important;
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}


.btn--blue {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color:rgb(60, 112, 202) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  padding:.5rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--blue-contact-seller-customer-admin {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color:rgb(60, 112, 202) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin: 3rem 27.5% !important;
  padding:.5rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    font-size: 3rem;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}



.btn--card-seller-name {
  width: 100% !important;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color:rgba(60, 112, 202, .5) ;
  // box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  // margin-right: 5rem;
  // padding:.5rem auto;
  // padding-right: 15rem !important;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .15);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--card-related-seller-name {
  width: 75% !important;
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center !important;
  background-color:rgba($color-white, .15);
  // box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  // margin-right: 5rem;
  // padding:.5rem auto;
  // padding-right: 15rem !important;
  border: none;
  // border-radius: .5rem;
  // -webkit-border-radius: .5rem;
  // -moz-border-radius: .5rem;
  // -ms-border-radius: .5rem;
  // -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .15);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}


.btn--blue-checkout {
  width: 20vw;
  font-size: 1.8rem;
  font-weight: 700;
  background-color:rgba(60, 112, 202, .5) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  padding:1rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    width: 30vw;
    font-size: 3rem !important;
    margin: 1rem 35vw !important;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    color: rgba($color-white, 1);
    background-color: rgba(60, 112, 202, .6) !important;
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--blue-order-download {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color:rgba(60, 112, 202, .8) ;
  box-shadow: 0 .5rem .5rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin-top: -2rem;
  padding:.5rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(60, 112, 202, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--blue-proceed {
  width: 65%;
  font-size: 1.7rem;
  font-weight: 500;
  background-color:rgb(60, 112, 202) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  margin-bottom: .5rem;
  padding:1rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;


  @include respond(phone){
    font-size: 2.5rem;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--shipping-to-proceed {
  width: 85%;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: .2rem;
  text-transform: capitalize;
  background-color:rgba($color-secondary-dark, .07) ;
  box-shadow: .5rem .5rem .5rem rgba($color-black, .15);
  color: rgba($color-primary-light, 1);
  margin-top: 1rem;
  margin-bottom: .5rem;
  padding:1rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    font-size: 3rem;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
   background-color:rgba($color-secondary-dark, .09) ;
    box-shadow: .7rem .7rem .7rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--shipping-option {
  width: 15%;
  height: 100% !important;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: .2rem;
  background-color:rgba(60, 112, 202, .5) ;
  box-shadow: 0 .5rem .5rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin: auto 0 1rem auto !important;
  // margin-left:2rem;
  padding: 2rem auto !important;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
    // font-weight: 700;

  @include respond(phone){
    width: 20%;
    font-size: 2.5rem;
    // font-weight: 700;
  }


  &:hover {
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    background-color: rgba(50, 94, 168, .6);
    box-shadow: 0 .5rem .5rem rgba($color-black, .1);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
  }
}
}

.btn--blue-disabled {
  width: 45%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color:rgba($color-white, .1) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-grey-dark, .5);
  margin-top: 1rem;
  padding:.5rem;
  border: none;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    // box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--edit {
  width: 20vw;
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  font-weight: 500;

  background-color:rgba(60, 112, 202, .5);
  // background-color:rgba(63, 116, 248, 0.3);
  box-shadow: 0 1rem 1rem rgba($color-black, .1);
  color: rgba($color-white, .8);
  margin: 3rem auto;
  padding: 1.5rem 3rem;
  // padding:3rem auto !important;
  // padding-bottom: 1rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  @include respond(phone){
    font-size: 3rem;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
  }
}

.btn--blue-view-profile-experience-edu {
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // background-color:rgb(60, 112, 202) ;
  background-color:rgba($color-tertiary-dark, .3) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .05);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  padding:.5rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
}

.btn--update-shipping-address {
  width: fit-content;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .3rem;
  // text-transform: capitalize !important;
  // background-color:rgb(60, 112, 202) ;
  background-color:rgba($color-white, .4) ;
  box-shadow: .3rem .3rem .3rem rgba($color-black, .1);
  color: rgba($color-primary-light, 1);
  margin-top: 1rem;
  padding: 1.5rem 3rem !important;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
  &__view-shipping-cost{
    padding:5rem 7rem;
    background-color:rgba($color-secondary-dark, .045) ;
  }

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    // background-color: rgba(50, 94, 168, .1);
    background-color: rgba($color-white, .1) !important;
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
}

.btn--blue-profile-categories-sub {
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  // background-color:rgb(60, 112, 202) ;
  background-color:rgba($color-tertiary-dark, .7) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .05);
  color: rgba($color-white, 1)!important;
  margin-top: 1rem;
  padding:.5rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 1rem 2rem rgba($color-black, .2);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
}

.btn--view-comments {
  width: 55%;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .2rem;
  background-color:rgba(60, 112, 202, .5) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .15);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  padding:.5rem 4rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    color: rgba($color-white, 1);
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    background-color: rgba(97, 144, 227,.5);
    box-shadow: 0 1rem 2rem rgba($color-black, .1);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
}

.btn--view-write-questions-on-video {
  width: 90%;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: .2rem;
  // background-color:rgba(202, 60, 60, 0.5) ;
  background-color:rgba($color-white, .9) ;
  box-shadow: 0 .2rem .2rem rgba($color-tertiary-light, .4);
  color: rgba($color-tertiary-light, .7);
  margin: 5rem 3rem -1rem 3rem !important;
  padding: 2rem 4rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    color: rgba($color-white, 1);
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    // background-color:rgba(202, 60, 60, 0.2) !important;
    background-color:rgba($color-white, .7) !important;
     color: rgba($color-tertiary-light, .7);
    box-shadow: 0 .2rem .2rem rgba($color-black, .15);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    background-color:rgba($color-white, .5) !important;
      color: rgba($color-tertiary-light, 1)!important;
    box-shadow: 0 .5rem .5rem rgba($color-black, .3);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
}

.btn--seller-customer-admin-contact {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: .2rem;
  // background-color:rgba(202, 60, 60, 0.5) ;
  background-color:rgba($color-white, .9) ;
  box-shadow: 0 .2rem .2rem rgba($color-tertiary-light, .4);
  color: rgba($color-tertiary-light, .7);
  margin: 1rem .5rem;
  margin-left: -.1rem;
  padding: 2rem ;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;

  &:hover {
    color: rgba($color-white, 1);
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    // background-color:rgba(202, 60, 60, 0.2) !important;
    background-color:rgba($color-white, .7) !important;
     color: rgba($color-tertiary-light, .7);
    box-shadow: 0 .2rem .2rem rgba($color-black, .15);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-2px);
    background-color:rgba($color-white, .5) !important;
      color: rgba($color-tertiary-light, 1)!important;
    box-shadow: 0 .5rem .5rem rgba($color-black, .3);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
}
}

.btn--blue-like {
  width: fit-content;
  border: none !important;
  font-size: 1.5rem;
  font-weight: 500;
  background-color:rgba($color-white, .4);
  box-shadow: 0 1.5rem 2rem rgba($color-black, .05);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  margin-right: 1rem;
  padding:1rem 1rem .5rem 1rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
  &__like {
    width: 100%;

  }

   &__background {
      width: 90%;
      display: flex;
      justify-content: space-between;
      font-size: 1.6rem;
      font-weight: 500;
      border-radius: 1rem;
      background-color:rgba($color-white, .3);
      box-shadow: 0 1.5rem 2rem rgba($color-black, .05);
      color: rgba($color-white, 1);
      margin: 1rem auto;
      // margin-right: 1rem;
      padding:.5rem 2rem;
      padding-bottom: 1rem;
      border-radius: .5rem;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;

       @include clearfix;

   // select attribute: [ .... ]
   // className^ means all className starts with / className* means contain ..
   // classname$ means any ends with..
   [class^="col-"] {

     float: left;

     &:not(:last-child){
       margin-right: $gutter-horizontal-3;
     }
   }

      .col-1-of-3 {
      width: calc((100% - 2 * #{$gutter-horizontal-3})/3);
      }

       .col-2-of-3 {
      width: calc( 2*((100% - 2 * #{$gutter-horizontal-3})/3) + #{$gutter-horizontal-3});
      }

   .col-1-of-2 {
     width: calc((100% - #{ $gutter-horizontal-3})/2);


    }
}

  &:hover {
    transform: translateY(-.5px);
    -webkit-transform: translateY(-.5px);
    background-color: rgba(50, 94, 168, 1);
    box-shadow: 0 .5rem 1rem rgba($color-black, .05);
    -moz-transform: translateY(-.5px);
    -ms-transform: translateY(-.5px);
    -o-transform: translateY(-.5px);
}

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
     background-color: rgba(50, 94, 168, .5);
    box-shadow: 0 .5rem 1rem rgba($color-black, .1);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
}
}

.btn--blue-like-edit-comment {
  width: 100%;
  border: none !important;
  font-size: 1.6rem;
  font-weight: 500;
  background-color:rgba(239, 242, 247, .7) ;
  box-shadow: 0 1.5rem 2rem rgba($color-black, .1);
  color: rgba($color-white, 1);
  margin-top: 1rem;
  margin-right: 1rem;
  padding:.5rem;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
  &__like {
    width: 100%;

  }

   &__background {
      width:100%;
      font-size: 1.5rem;
      font-weight: 500;
      // border: .1rem rgb(0, 255, 38) solid;
      // border-radius: 0;
      background-color:rgba($color-white, .4);
      box-shadow: 0 1.5rem 2rem rgba($color-black, .05);
      color: rgba($color-white, 1);
      margin-top: 1rem;
      // margin-right: 1rem;
      padding: 1.5rem 1rem 0 1rem;
      // padding-bottom: 1rem;
      // border-radius: .5rem;
      // -webkit-border-radius: 0;
      // -moz-border-radius: 0;
      // -ms-border-radius: 0;
      // -o-border-radius: 0;
    }

  // &:hover {
  //   transform: translateY(-.5px);
  //   -webkit-transform: translateY(-.5px);
  //   background-color: rgba(50, 94, 168, 1);
  //   box-shadow: 0 .5rem 1rem rgba($color-black, .05);
  //   -moz-transform: translateY(-.5px);
  //   -ms-transform: translateY(-.5px);
  //   -o-transform: translateY(-.5px);
  // }

//   &:active,
//   &:focus {
//     outline: none;
//     transform: translateY(-1px);
//      background-color: rgba(50, 94, 168, .5);
//     box-shadow: 0 .5rem 1rem rgba($color-black, .1);
//     -webkit-transform: translateY(-1px);
//     -moz-transform: translateY(-1px);
//     -ms-transform: translateY(-1px);
//     -o-transform: translateY(-1px);
// }
}


.btn-block {
  &:hover {
    outline: none;
    transform: translateY(0);
    background-color: rgba($color-tertiary-dark, .1);
    box-shadow: 0 .5rem 1rem rgba($color-tertiary-dark, 0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

  &:active {
    outline: none;
    transform: translateY(0);
    background-color: rgba($color-tertiary-light, .5);
    box-shadow: 0 .5rem 1rem rgba($color-tertiary-dark, 0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
  &:focus {
    outline: none;
    transform: translateY(0);
    background-color: rgba($color-white, 1);
    box-shadow: 0 .5rem 1rem rgba($color-tertiary-dark, 0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}


}


.btn-shipping-option-details{
  width: 30%;
  margin: 1rem 37.5% 1rem 37.5%;
  padding: 1rem 2rem;
  background-color: rgba($color-white, .7);
  color: rgba($color-black, 1);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: .3rem;
  // color: rgba(#304ffe, 1);
  box-shadow: .2rem .2rem .2rem rgba($color-black, .05);
  border: none;

  @include respond(phone){
     width: 40%;
     margin: 1rem auto 1rem 30%;
    font-size: 2.1rem;
  }

   &:hover {
    outline: none;
    transform: translateY(.5px);
    background-color: rgba($color-white, .3);
    box-shadow: .5rem .5rem 1rem rgba($color-black, .1);
    -webkit-transform: translateY(.5px);
    -moz-transform: translateY(.5px);
    -ms-transform: translateY(.5px);
    -o-transform: translateY(.5px);
}

  &:active {
    outline: none;
    transform: translateY(-.2px);
    background-color: rgba($color-tertiary-light, .3);
    box-shadow: .2 .2rem .2rem rgba($color-black, .05);
    -webkit-transform: translateY(-.2px);
    -moz-transform: translateY(-.2px);
    -ms-transform: translateY(-.2px);
    -o-transform: translateY(-.2px);
}
  &:focus {
    outline: none;
    transform: translateY(-.2px);
    background-color: rgba($color-white, .02);
    box-shadow: .2rem .2rem .2rem rgba($color-black, .05);
    -webkit-transform: translateY(-.2px);
    -moz-transform: translateY(-.2px);
    -ms-transform: translateY(-.2px);
    -o-transform: translateY(-.2px);
}

}

.btn-subs-category-seller {
    width:fit-content;
    display: flex;
    background-color: rgba($color-white, .4);
    color:rgba($color-primary-light, 1)!important;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight:700;
    letter-spacing: .2rem;
    margin-top: -7rem;
    margin-bottom: 5rem;
    margin-right: 3rem;
    padding: 2rem 3rem  !important;
    border: .1rem rgba($color-white, .3) solid;
    border-radius: 5%;
    transition: all .2s;
    position: relative;
    -webkit-transition: all .2s;
   box-shadow: 0 .1rem .1rem rgba($color-black, .2);


  &:hover {
    transform: translateY(-.3rem);
    -webkit-transform: translateY(-.3rem);
    background-color: transparent !important;
    box-shadow: 0 .3rem .3rem rgba($color-black, .2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-.1rem);
    -webkit-transform: translateY(-.1rem);
    color: rgba($color-white, 1) !important;
    background-color: rgba($color-tertiary-dark, .1)!important;
    box-shadow: 0 .4rem .4rem rgba($color-black, .2);
  }

  // &--white {
  //   background-color: $color-white;
  //   color: $color-grey-dark;

  //   &::after {
  //   background-color: $color-white;
  //   }

  //   &:hover {
  //     background-color: rgba($color-white, .3);
  //   }

  //   // &:active {
  //   //   background-color: rgba($color-white, .95);
  //   // }
  // }

  // &::after {
  //   content:"";
  //   display: inline-block;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 10rem;
  //   -webkit-border-radius: 10rem;\cart
  //   position: absolute;
  //   top:0;
  //   left: 0;
  //   z-index: -1;
  //   transition: all .8s;
  //   -webkit-transition: all .8s;
  // }

  // &-animated {
  //   animation: moveInBottom .5s ease-out .75s;
  //   -webkit-animation: moveInBottom .5s ease-out .75s;
  //   animation-fill-mode: backwards;
  // }


}

// .button, .select{
//   text-decoration: none !important;
//   &:hover{
//     // text-decoration:line-through !important;
//     border-bottom: none !important;
//     background-color: rgba(235, 176, 176, .6);
//   }
// }
.Toastify{
  // width: 1500rem !important;
  // height: fit-content !important;
  // display: flex;
  // flex-wrap: wrap !important;
  // padding: -.1rem !important;
  //  background-color: rgba($color-secondary-dark, .5) !important;
  &

  &__toast-body{
  width: 500rem !important;
  background-color: rgba($color-black, .02) !important;
  color: rgba($color-primary-light, 1);
  // display: flex;
  // flex-direction: row;
  // // flex-wrap: wrap !important;
  // margin: 0 1rem !important;
  // padding: .5rem;

  }
  // background-color: red;
}
// .Toastify-toast-width {
//  width: 350rem !important;
// }

.btn-next{
  padding: .5rem 1rem;
}
