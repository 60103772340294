
.column-main-home-main-carousel-categories-subs{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //  background-color: rgba($color-tertiary-dark, .25);

  cursor: pointer;
  font-size:1.45rem;
  font-weight:400;
  margin-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

// main home column: show categories:
.column-main-home-categories-subs-show-categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  cursor: pointer;
  font-size:1.5rem;
  font-weight:600;
  text-decoration:solid;
  color: rgba($color-primary-light, .6);
  text-decoration-line: underline;
  -moz-text-decoration-line: underline;
}

.column-home-categories-select-subs-products {
    width:100% !important;
    height: 65vh;
    display:flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: flex-start;
    background-color: rgba($color-Greenprimary-dark, .05);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .05);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2rem;
    // padding:  0 5rem 0 2rem;
    // border: 1rem blue solid;
    border: none;
    z-index: 1000;

    @include respond(tab-land){
       width:95%;
    height: 63vh;
    margin-top: 1rem;
    }
    @include respond(tab-port){
       width:95%;
    height: 47vh;
    margin-top: .2rem;
    }
    @include respond(phone){
       width:90%;
    height: 20vh;
    // margin-top: -.1rem;
    }
    @include respond(small-phone){
       width:90%;
    height: 20vh !important;
    // margin-top: -.1rem;
    }
}

.column-home-categories-select-subs-products-with-clicked-category-or-subCategory {
    width:100% !important;
    height: 65vh;
    display:flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: flex-start;
    background-color: rgba($color-Greenprimary-dark, .05);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .05);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2rem;
    // padding:  0 5rem 0 2rem;
    border: none;
    z-index: 1000;

    @include respond(tab-land){
       width:95%;
    height: 63vh;
    margin-top: 1rem;
    }
    @include respond(tab-port){
       width:95%;
    height: 47vh;
    margin-top: .2rem;
    }
    @include respond(phone){
       width:90%;
    height: 40vh;
    // margin-top: -.1rem;
    }
    @include respond(small-phone){
       width:90%;
    height: 40vh !important;
    // margin-top: -.1rem;
    }
}

.column-main-home-main-carousel-display-subs{
  width: 90% !important;
  height: 55rem;
  // margin: 5rem .5rem ;
  margin-bottom: 5rem !important;
  padding-top: 3rem;
  // padding: 5rem auto !important;
  text-align: center;
  display: flex;
  justify-content: center;
  // justify-items: center;
  // border: 1rem red solid;
  flex-direction: row;
  flex-wrap: wrap !important;
  color: rgba($color-tertiary-light, 1);
  font-size: 1.5rem;
  font-weight: 500;
  background-color: rgba($color-Greenprimary-dark, .02);
  // box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
  z-index: 10000;

  cursor: pointer;

  &__hide-products {
    width: 15rem !important;
  }

  &__show-subs {
    width: 15%;
    height: fit-content;
    display: flex;
    justify-content: center;
    // justify-items: center;
    flex-direction: row !important;
    margin: 0 2rem 0 1rem !important;
    padding: 1.5rem 1rem;
    align-items: center;
    text-align: center;
     cursor: pointer;
     z-index: 20000;
    background-color: rgba($color-white, 1) !important;
      box-shadow: .2rem .1rem .2rem rgba($color-black, 1);

      &:hover {
      transform: translateY(-3px);
      -webkit-transform: translateY(-3px);
      box-shadow: 0 .1rem .1rem rgba($color-black, .2);
      -moz-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      -o-transform: translateY(-3px);
  }
  }
}

.column-main-home-carousel-category-products-display {
    width:100%;
    height: 65vh;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    background-color: rgba($color-Greenprimary-dark, .02 );
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2rem;
    padding:  0 auto;
    border: none;

    @include respond(tab-port){
    // width:90%;
    height: 45vh;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    }
    @include respond(phone){
      height: 35vh;
    }
    @include respond(small-phone){
      height: 28vh;
    }
}

.column-main-home-carousel-category-products-box-card {
    width: 90%;
    height: 100%;
    // display: flex;
    border-radius: .5%;
  // background-image: linear-gradient(99deg,
  // rgba(88, 91, 245, 0.4) 0%,
  // rgba(178, 179, 243, 0.3) 15%,
  // rgba(67, 70, 241, .3) 50%,
  // rgba(67, 70, 241, .3) 51%,
  // rgba(178, 179, 243, 0.3) 85%,
  // rgba(88, 91, 245, 0.4) 100%,
  // rgba(67, 70, 241, .4) 100%);
  background-color: rgba($color-tertiary-dark, .05);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .2);
    &__height-scroll {
    width: 100% !important;
    height: 65vh;
    padding-bottom: 10rem;
    overflow: scroll;
    scrollbar-width: thin;
    // scrollbar-width: .5rem;
    // scroll-behavior:smooth;
    backface-visibility: hidden ;
    -webkit-backface-visibility: hidden ;
  //  border: 1rem rgba($color-black, 1) solid !important;

    @include respond(big-desktop){
       width: 75vw !important;
      height: 65vh;
      padding: 2rem 2rem 10rem 2rem !important;
      margin-left: -2rem !important;
      margin-top: -1rem !important;
      // border: 2rem red solid;
    }
    @include respond(lab-top){
       width: 75vw !important;
      height: 65vh;
      padding: 2rem 2rem 10rem 2rem !important;
      margin-left: -2rem !important;
      margin-top: -1rem !important;

    }
    @include respond(tab-land){
       width: 75vw !important;
      height: 65vh;
      padding: 2rem 2rem 10rem 2rem !important;
      margin-left: -2rem !important;
      margin-top: -1rem !important;
      // border: .2rem red solid;
    }
    @include respond(tab-port){
       width: 70vw !important;
      height: 45vh;
      padding: 2rem 2rem !important;
      // border: .2rem blue solid;
    }

    @include respond(phone){
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width: 95vw !important;
      height: 58vh !important;
      margin: -2rem 1rem 2rem 0;
      //  margin: 2rem auto 2rem auto !important;
      // padding: 2rem -1rem 2rem 2rem !important;
      padding: 2rem 0 2rem 10rem !important;
      //  border: .2rem red solid;
    }
    @include respond(phone-500){
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width: 95vw !important;
      height: 58vh !important;
       margin: 2rem auto 2rem auto !important;
      // padding: 2rem auto 2rem auto !important;
       padding: 2rem 0 2rem 4rem !important;
      // margin: -2rem 1rem 2rem 0;
      // padding: 2rem 3rem !important;
      //  border: .2rem red solid;
    }
    @include respond(phone-400){
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      width:96vw !important;
      height: 50vh !important;
      margin: 2rem auto 2rem auto !important;
      padding: 2rem auto 2rem auto !important;
      //  border: .2rem red solid;
    }

    @include respond(small-phone){
      width: 100vw !important;
      height: 50vh !important;
      margin: 0 auto !important;
      padding: 0 auto !important;
      // display: flex !important;
      // flex-direction: row;
      // flex-wrap: wrap;
      // border: .2rem blue solid !important;
    }

   }


    &:not(:last-child) {
      margin-bottom: $gutter-vertical;
   }
   @include clearfix;

   [class^="col-"] {

     float: left;

    //  &:not(:last-child){
    //    margin-right: -5rem;
    //  }
   }


   .col-1-of-2 {
    //  width: calc((100% - #{ $gutter-horizontal-1})/2);
    width: calc((100% - 6 * #{$gutter-horizontal-3})/7) !important;
    border: .3rem blue solid;
      // width:50rem !important;
    // display: block;
    background-color: rgba($color-secondary-dark, .15);
     margin: 1rem 3rem;
     height: 18vh;
     overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    @include respond(phone){
      width: 20rem !important;
    }

    }

  //   .col-1-of-3 {
  //   width: calc((100% - 2 * #{$gutter-horizontal-1})/3);
  //   }

  //   .col-2-of-3 {
  //   width: calc( 2*((100% - 2 * #{$gutter-horizontal-1})/3) + #{$gutter-horizontal-1});
  //   }

    // .col-1-of-4 {
    // width: calc((100% - 3 * #{$gutter-horizontal-1})/4);
    // }
    // .col-1-of-4-seller-home {
    // width: calc((100% - 3 * #{$gutter-horizontal-3})/4);

    // }

    // .col-shit {
    // width: calc((100% - 6 * #{$gutter-horizontal-3})/7);

    // }

    // .col-2-of-4 {
    // width: calc( 2 * ((100% - 3 * #{$gutter-horizontal-1})/4) + #{$gutter-horizontal-1});
    // }

    // .col-3-of-4 {
    // width: calc( 3 * ((100% - 3 * #{$gutter-horizontal-2})/4) + 2 * #{$gutter-horizontal-2});
    // }

    //   .col-1-of-6 {
    // width: calc(((100% - 5 * #{$gutter-horizontal-3})/6)  + 4 * #{$gutter-horizontal-1});
    // }

    // .col-5-of-6 {
    // width: calc( 5 * ((100% - 5 * #{$gutter-horizontal-0})/6) + 3 * #{$gutter-horizontal-2});
    // }

    // .col-3-of-7 {
    // width: calc( 3 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 2 * #{$gutter-horizontal-1});
    // }
    // .col-4-of-7 {
    // width: calc( 4 * ((100% - 6 * #{$gutter-horizontal-1})/7) + 1 * #{$gutter-horizontal-1});
    // }

    .col-1-of-7 {
    // width: calc((100% - 6 * #{$gutter-horizontal-3})/7) ;
    width:20vw;
    // display: block;
    background-color: rgba($color-secondary-dark, .5);
     margin: 1rem 3rem;
     height: 14vh;
     overflow: hidden;
    box-shadow: .9rem .9rem .9rem rgba($color-black, .5);
    @include respond(phone){
     width: 15% !important;
    //  width: calc((100% - 7 * #{$gutter-horizontal-1})/7) ;
      height: 15vh !important;
      margin: 1rem 1rem !important;
      background-color: rgba($color-secondary-light, .5);
    }
    @include respond(small-phone){
     width: 135% !important;
    //  width: calc((100% - 7 * #{$gutter-horizontal-1})/7) ;
      height: 15vh !important;
      margin: 1rem 5rem !important;
      background-color: rgba($color-secondary-light, .5);
    }
    }

    // .col-6-of-7 {
    // width: calc( 6 * ((100% - 6 * #{$gutter-horizontal-5})/7) + 5 * #{$gutter-horizontal-5});
    // }

}
