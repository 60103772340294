 .header-image{
  height: 100%;
  border: .3rem rgba($color-white, .2) solid;
  padding-bottom: 0;

  background-image: linear-gradient(180deg,
    // rgba(44, 57, 238, 0.6) 5%,
    // rgba(41, 52, 208, 0.5) 15%,
    // rgba(60, 63, 224, 0.6) 35%,
    // rgba(47, 58, 212, 0.6) 40%,
    // rgba(55, 66, 218, 0.6) 45%,
    // rgba(53, 64, 228, 0.6) 55%,
    // rgba(26, 38, 210, 0.6) 65%,
    // rgba(51, 112, 233, 0.7) 100%,
    // rgba(92, 142, 240, 0.7) 90%),
      // *** 1 ***
    // rgba(69, 81, 248, 0.6) 5%,
    // rgba(69, 81, 248, 0.4) 15%,
    // rgba(67, 70, 241, 0.5) 35%,
    // rgba(69, 81, 248, 0.4) 40%,
    // rgba(69, 81, 248, 0.4) 45%,
    // rgba(69, 81, 248, 0.4) 55%,
    // rgba(69, 81, 248, 0.6) 65%,
    // rgba(15, 91, 241, 0.7) 90%,
    // rgba(56, 116, 236, 0.6) 100%),
    // *** 1 ***

    rgba(23, 41, 239, 0.34) 5%,
    rgba(23, 41, 239, 0.34) 15%,
    rgba(23, 41, 239, 0.34) 35%,
    rgba(23, 41, 239, 0.34) 40%,
    rgba(23, 41, 239, 0.34) 45%,
    rgba(23, 41, 239, 0.34) 55%,
    rgba(23, 41, 239, 0.34) 65%,
    rgba(23, 41, 239, 0.34) 90%,
    rgba(23, 41, 239, 0.34) 100%),

    url(../image/hero.jpg);

  background-size:cover;
  background-position:top;
  position: relative;

    &__logo-box {
    position: absolute;
    top: .1rem;
    left: 1rem;
  }


    &__logo{
    background-size:cover;
    overflow: hidden;
    width: 100%;
    height: fit-content;
    margin: 1rem auto 1rem 2rem;
    padding: 5rem 3rem 3rem 3rem;
    // background-color: rgba($color-white, .017);
    // border-bottom: .2rem rgba($color-white, 1) solid;
    // border-top: .2rem rgba($color-white, 1) solid;
    // background-blend-mode:color-dodge;
    // box-shadow: 0 .2rem .2rem rgba($color-black, .4);
    // border-top-left-radius: 1px;
    // border-top-right-radius: 1px;
  }


  &__text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
  }
 }

 .new-header-menu {
    width: 100%;
    // background-color: black;
    background-image: linear-gradient(180deg,

    rgba(69, 81, 248, 0.6) 5%,
    rgb(55, 63, 177) 15%,
    rgba(127, 134, 243, 0.993) 85%);


  //  margin-bottom: -5rem !important;

    // background-color: red;
    border-bottom: .1rem $color-grey-light-1 solid;
    // background-size: cover;
    // color: rgb(4, 0, 255);
    // margin-top: -3rem;
     @include respond(tab-port){ // width < 600 ?
     width: 100vw;
     height: fit-content;
    //  display: flex;
    //  flex-wrap: wrap;
     font-size: .5rem !important;
     border: .1rem red solid;
    // height: 50vh;
   }

    @include respond(phone){ // width < 600 ?
     width: 0 !important;
     opacity: 0;
    //  height: fit-content;
    //  display: flex;
    //  flex-wrap: wrap;
     font-size: .5rem !important;
     border: .1rem white solid;
    // height: 50vh;
   }

     &__table {
     background-color: rgba($color-black, .02);
    }


    &--subMenu{
      color: rgba($color-secondary-dark, 1) !important;
      background-color: rgba($color-white, 1);
    }

    &-item {
      color: $color-white;
      // background-color: rgba($color-white, 1);



      &--search  {
      // float: right !important;
        width: 40% !important;
        height: 90% !important;
        display: block;
        margin: .03rem auto .02rem auto !important;
        // padding: .1rem auto -1rem auto !important;
        font-size: 2rem;
        // margin-bottom: .3rem;
        border-radius: 1%;
        -webkit-border-radius: 1%;
        -moz-border-radius: 1%;
        -ms-border-radius: 1%;
        -o-border-radius: 1%;
        // font-size: 12rem;
        background-color: rgba($color-white, .15) !important;
        // border: rgba(red, .5) .1rem solid;
        // box-shadow: .05rem .1rem .1rem rgba($color-black, .4);
        @include respond(tab-port){ // width < 600 ?
          max-width:30%;
          height: 90%;
          font-size: 1rem;
        }
        @include respond(phone){ // width < 600 ?
          max-width:30%;
          height: 90%;
          font-size: 1rem;
        }
      }
    }
  }

 .new-header-menu-phone {
    width: 0;
    height: 0;
    opacity: 0;
    // background-color: black;
    background-image: linear-gradient(180deg,

    rgba(69, 81, 248, 0.6) 5%,
    rgb(55, 63, 177) 15%,
    rgba(127, 134, 243, 0.993) 85%);


  //  margin-bottom: -5rem !important;

    // background-color: red;
    border-bottom: .1rem $color-grey-light-1 solid;
    // background-size: cover;
    // color: rgb(4, 0, 255);
    // margin-top: -3rem;
  //    @include respond(tab-port){ // width < 600 ?
  //    width: 100vw;
  //    height: fit-content;
  //   //  display: flex;
  //   //  flex-wrap: wrap;
  //    font-size: .5rem !important;
  //    border: .1rem red solid;
  //   // height: 50vh;
  //  }

    @include respond(phone){ // width < 600 ?
     width: 100vw !important;
     opacity: 1;
     height: fit-content !important;
    //  margin-top: 5rem;
    //  margin-bottom: 5rem !important;
     display: flex;
     flex-direction: column !important;
     position: absolute;
    //  flex-wrap: wrap;
     font-size: .5rem !important;
    //  border: 1rem rgb(160, 8, 8) solid;
     z-index: 100;
    // height: 50vh;

    &__viewed{
        width: 100% !important;
        align-items: center !important;
     opacity: 1;
     height: fit-content !important;
     margin-top: -10rem;
    //  margin-bottom: 18rem;
    margin-left: 7rem !important;
    padding: -5rem !important;
    // background-color: rgb(246, 141, 160);
      // margin-bottom: 1rem !important;
     display: flex;
     flex-direction: column !important;
     justify-content: center !important;
     justify-items: center !important;
    //  background-color: rgba($color-black, .01);
    //  flex-wrap: wrap;
     font-size: .5rem !important;
     z-index: 300;
    //  border: 1rem rgb(51, 160, 8) solid;
    }
    &__viewed-profile{
        width: 100vw !important;
     opacity: 1;
     height: 50% !important;
     margin-top: 1rem;
    //  margin-bottom: 18rem;
    margin-left: 5rem;
    // padding-right: -5rem !important;
    background-color: rgba($color-white, 1);
      // margin-bottom: 1rem !important;
     display: flex;
     flex-direction: column !important;
    //  background-color: rgba($color-black, .01);
     flex-wrap: wrap;
     font-size: 5rem !important;
     z-index: 300;
    //  border: 1rem rgb(51, 160, 8) solid;
    }
   }

     &__table {
     background-color: rgba($color-black, .02);
    }


    &--subMenu{
      color: rgba($color-secondary-dark, 1) !important;
      background-color: rgba($color-white, 1);
    }

    &-item {
      color: $color-white;
      height: fit-content !important;
      // margin-left: 10rem;
      background-color: rgba($color-white, .1);

      @include respond(phone){
        // width: 5rem !important;
        margin: 0 .1rem !important;
        padding: .1rem !important;
        font-size:.5rem !important;
      }



      &--search  {
      // float: right !important;
        width: 70% !important;
        height: fit-content !important;
        display: block;
        margin: -6rem auto .02rem auto !important;
        // padding: .1rem auto -1rem auto !important;
        font-size: 2rem;
        // margin-bottom: .3rem;
        border-radius: 1%;
        -webkit-border-radius: 1%;
        -moz-border-radius: 1%;
        -ms-border-radius: 1%;
        -o-border-radius: 1%;
        // font-size: 12rem;
        background-color: rgba($color-white, .15) !important;
        z-index: 200;
        // border: rgba(red, .5) .1rem solid;
        // box-shadow: .05rem .1rem .1rem rgba($color-black, .4);
        @include respond(tab-port){ // width < 600 ?
          max-width:30%;
          height: 90%;
          font-size: 1rem;
        }
        @include respond(phone){ // width < 600 ?
          max-width:70%;
          height: 90%;
          opacity: 1;
          font-size: 1rem;
        }
      }
      &--search-hidden  {
      // float: right !important;
        width: 0 !important;
        height: 0 !important;
        opacity:0;

        // z-index: 0;
        // border: rgba(red, .5) .1rem solid;
        // box-shadow: .05rem .1rem .1rem rgba($color-black, .4);
        @include respond(tab-port){ // width < 600 ?
          max-width:30%;
          height: 90%;
          font-size: 1rem;
        }
        @include respond(phone){ // width < 600 ?
          max-width:30%;
          height: 90%;
          font-size: 1rem;
        }
      }
    }
  }

  .sub-menu-text{
    color:rgba($color-primary-light, 1);
  }

  .ant-menu{
    // color: rgb(217, 15, 15) !important;
    background-color: rgba($color-white, .9);
   &-submenu{
    // color: white;
    background-color: rgba($color-black, .03) !important;
  }
  }

  //////////////////////////////////////////////////////////////////////////
            // :::::::::::::OLD STYLES BELOW:::::::::
//////////////////////////////////////////////////////////////////////////



.header{

  // margin-bottom: -25rem;
  height: 100%;
  padding-bottom: 0;
  // background-image:
  // linear-gradient(
  //   to right,
  //   $color-primary-light,
  //   $color-primary-dark),
  //   url(../image/hero.jpg);

 background-image: linear-gradient(180deg,
    rgba(69, 81, 248, 0.6) 5%,
    rgba(69, 81, 248, 0.4) 15%,
    rgba(67, 70, 241, 0.5) 35%,
    rgba(69, 81, 248, 0.4) 40%,
    rgba(69, 81, 248, 0.4) 45%,

    rgba(69, 81, 248, 0.4) 55%,
    rgba(69, 81, 248, 0.6) 65%,
    rgba(56, 116, 236, 0.6) 100%,
    rgba(15, 91, 241, 0.7) 90%),


    url(../image/hero.jpg);

  background-size: cover;
  background-position: top;
  position: relative;
  // -webkit-clip.. comes first, then what is below
  // -webkit-clip-path: polygon(0 0, 100% 0, 100% 40vh, 0 100%);
  // clip-path: polygon(0 0, 100% 0, 100% 40vh, 0 100%);

  &__logo-box {
    position: absolute;
    top: .1rem;
    left: 1rem;

  }

  &__logo{
    background-size:cover;
    overflow: hidden;
    width: 100%;
    height: fit-content;
    // text-align: center;

    // border-radius: 50%;
    margin: 1rem auto 1rem 2rem;
    padding: 5rem 3rem 3rem 3rem;
    background-color: rgba($color-white, .017);

    border-bottom: .2rem rgba($color-white, 1) solid;
    border-top: .2rem rgba($color-white, 1) solid;
    background-blend-mode:color-dodge;
    box-shadow: 0 .2rem .2rem rgba($color-black, 1);
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 98% 80%, 85% 75%, 0 100%);
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    // background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .6), rgba($color-secondary-dark, .5)),url(../images/dyagonline-black&white.jpg);

  }


  &__text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
  }

  // &__seller-videos-page {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 85%;

  //   transform: translate(-50%,-50%);
  //   -webkit-transform: translate(-50%,-50%);
  //   -moz-transform: translate(-50%,-50%);
  //   -ms-transform: translate(-50%,-50%);
  //   -o-transform: translate(-50%,-50%);
  //   text-align: center;
  // }


}

.header-seller-videos-page{
  width: 100%;
  // margin-bottom: -25rem;
  height: 100%;
  // padding-bottom: 2rem;
  // background-image:
  // linear-gradient(
  //   to right,
  //   $color-primary-light,
  //   $color-primary-dark),
  //   url(../image/hero.jpg);

 background-image: linear-gradient(180deg,
    rgba(69, 81, 248, 0.6) 5%,
    rgba(69, 81, 248, 0.4) 15%,
    rgba(67, 70, 241, 0.5) 35%,
    rgba(69, 81, 248, 0.4) 40%,
    rgba(69, 81, 248, 0.4) 45%,

    rgba(69, 81, 248, 0.4) 55%,
    rgba(69, 81, 248, 0.6) 65%,
    rgba(56, 116, 236, 0.6) 100%,
    rgba(15, 91, 241, 0.7) 90%),


    url(../image/hero.jpg);

  background-size: cover;
  background-position: top;
  position: relative;

  &__logo-box {
    position: absolute;
    top: .1rem;
    left: 1rem;

  }

  &__logo{
    background-size:cover;
    overflow: hidden;
    width: 100%;
    // height: 10rem;

    // border-radius: 50%;
    margin: 0;
    padding: 6rem 2rem 0 2rem;
    background-color: rgba($color-tertiary-dark, .02);

    // border-bottom: .1rem rgba($color-white, 1) solid;
    // border-right: .1rem rgba($color-white, 1) solid;
    background-blend-mode:color-dodge;
    // box-shadow: 0 .2rem 1rem rgba($color-black, .2);
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 98% 80%, 85% 75%, 0 100%);
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    // background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .6), rgba($color-secondary-dark, .5)),url(../images/dyagonline-black&white.jpg);

  }


  &__text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
  }

  &__seller-videos-page {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;

    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
  }


}

.header-seller{
  // margin-top: -25rem;
  height: 100%;
  // background-image:
  // linear-gradient(
  //   to right,
  //   $color-primary-light,
  //   $color-primary-dark),
  //   url(../image/hero.jpg);



  background-size: cover;
  background-position: top;
  position: relative;
  // -webkit-clip.. comes first, then what is below
  // -webkit-clip-path: polygon(0 0, 100% 0, 100% 40vh, 0 100%);
  // clip-path: polygon(0 0, 100% 0, 100% 40vh, 0 100%);

  &__logo-box {
    position: absolute;
    top: 2rem;
    left: 3rem;

  }

  &__logo{
    background-size:cover;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 6rem 2rem 0 2rem;
    background-color: rgba($color-tertiary-dark, .02);
    background-blend-mode:color-dodge;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;

  }


  &__text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
  }

  &__videos-page {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;


    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
  }


}



.header-menu {
    // width:auto;
    // background-color: black;
    background-image: linear-gradient(180deg,

    rgba(69, 81, 248, 0.6) 5%,
    rgb(55, 63, 177) 15%,
    rgba(127, 134, 243, 0.993) 85%);


  //  margin-bottom: -5rem !important;

    // background-color: red;
    border-bottom: .1rem $color-grey-light-1 solid;
    // background-size: cover;
    // color: rgb(4, 0, 255);
    // margin-top: -3rem;

     &__table {
     background-color: rgba($color-black, .03);
     font-size: 1.3rem;
     color: rgba($color-primary-light, 1);
    }

    &-item {
      color: $color-white;


      &--search  {
        width: 60rem;
        height: fit-content !important;
        display: block;
        margin-top: .8rem;
        // margin-bottom: .3rem;
        border-radius: 1%;
        -webkit-border-radius: 1%;
        -moz-border-radius: 1%;
        -ms-border-radius: 1%;
        -o-border-radius: 1%;
        font-size: 2rem;
        // color: white;
        //text-decoration: none;
        //padding: .5rem 0 .5rem 40rem;
        //border: 1rem red solid;
        background-color: transparent;
        // border: rgba($color-white, .5) .1rem solid;
        // box-shadow: .05rem .1rem .1rem rgba($color-black, .4);
}
    }
  }

.header-menu-seller-home-general-menu {
    // width:auto;
    // background-color: black;
    background-image: linear-gradient(180deg,

    rgba(69, 81, 248, 0.6) 5%,
    rgb(55, 63, 177) 15%,
    rgba(127, 134, 243, 0.993) 85%);




    // background-color: red;
    // border-bottom: .1rem $color-grey-light-1 solid;
    // background-size: cover;
    // color: rgb(4, 0, 255);
    margin-top: -3rem;

    &-item {
      color: $color-white;


      &--search  {
        width: 60rem;
        display: inline-block;


        // text-decoration: none;
        // padding: .5rem 0 .5rem 40rem;
        // border: 1rem red solid;
        // background-color: yellow;


      }
    }
  }

.header-menu-seller-home {
    // width:auto;
    height: 6rem;
    background-color: rgba($color-white, .3);
    padding-top: .8rem;
    padding-bottom: .5rem;
    // background-image: linear-gradient(180deg,

    // rgba(69, 81, 248, 0.6) 5%,
    // rgb(55, 63, 177) 15%,
    // rgba(127, 134, 243, 0.993) );




    // background-color: red;
    // border-bottom: .5rem $color-grey-light-1 solid;
    // background-size: cover;
    // color: rgb(4, 0, 255);
    // margin-top: -10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;

    &-item {
      color: $color-white;


      &--search  {
        width: 60rem;
        display: inline-block;


        // text-decoration: none;
        // padding: .5rem 0 .5rem 40rem;
        // border: 1rem red solid;
        // background-color: yellow;


      }
    }
  }

.header-table {

  &__tr {
    // background-color: rgba($color-primary-light-3, .2  );
    background-color: rgba($color-tertiary-dark, .1  );
    text-align: start;
    font-size: 1.4rem !important;
    width:fit-content !important;



    // background-color: rgba($color-white, .3);

  }

  &__tr-2 {
  background-color: rgba(#304ffe, .1  );
  color: $color-primary-light !important;
  font-size: 1.7rem;
  font-weight: 500;
  }

  &__cart-header-table {
  background-color: rgba(#304ffe, .08  );
  color: $color-primary-light !important;
  padding-left: 1.5rem !important;
  font-size: 1.7rem;
  font-weight: 500;
    @include respond(phone){
      font-size: 2.5rem;
    }

  }


  &__tr-3 {
    background-color: rgba($color-tertiary-dark, .02);
    color: $color-primary-light !important;
    font-size: 1.6rem !important;
    font-weight: 700 !important;

    @include respond(phone){
      // width: 95% !important;
      font-size: 2.5rem !important;
    }

  }

  &__tr-order-admin-dashboard-header {
  background-color: rgba($color-tertiary-dark, .06);
  color:rgba($color-tertiary-light, .8) !important;
  font-size: 1.3rem !important;
  font-weight: 600 !important;

  }

  &__tr-order-customer-history-table-header {
  background-color: rgba($color-tertiary-dark, .05);
  color:rgba($color-tertiary-light, .8) !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  @include respond(phone){
    font-size: 2.4rem !important;
  }
  &--cursor {
    cursor: pointer;
  }

  }

  &__tr-order-admin-dashboard-content {
  background-color: rgba($color-white, .07);
  color:rgba($color-tertiary-light, .9) !important;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  box-shadow: .1rem .1rem .1rem rgba($color-black, .5);

  }

  &__tr-customer-history-content {
  background-color: rgba($color-white, .2);
  color:rgba($color-tertiary-light, .9) !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  // border: .1rem red solid !important;
  @include respond(phone){
    font-size: 2.4rem !important;
  }

  }

  &__td {
    width: fit-content;
    margin: 0 auto;
    // margin-right: 50rem;
    text-align: center;
    font-size: 1.6rem !important;
    font-weight: 600;
    background-color: rgba($color-white, .8);
    color: rgba($color-black, .5);
    // padding-left: .5rem !important;

    @include respond(phone){
      font-size: 3rem !important;
    }

    &--title{
      text-align: left;
      padding-left: 1.5rem !important;
    }
    // border: red .1rem solid;
  }

  &__td-2 {
    background-color: rgba($color-black, .01);
    box-shadow: .1rem .1rem .1rem rgba($color-black, .2);

  }

  &__td-3 {
    background-color: rgba($color-white, .4)!important;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .5);

  }

  &__price {
    // width: 7rem !important;
    display: flex !important;
    flex-wrap: wrap !important;
    // background-color: black;
  }

}

// .header-summary-table{

//   box-shadow: 0 .5rem .5rem rgba($color-black, .09);
//     background-color: rgba($color-tertiary-dark, 0.02);

//   &__inner-table{
//     box-shadow: 0 .5rem .5rem rgba($color-black, .06);
//     background-color: rgba($color-secondary-dark, 0.02);
//     width:95% !important;
//     display:flex;
//     flex-direction:column;
//     margin: 1rem auto;
//     // margin-top:1rem;
//     margin-bottom:2rem !important;
//     padding:2rem;

//      &--standard{
//       background-color: rgba($color-Greenprimary-dark, 0.02);
//      }

//      &--border{
//       border:rgba($color-white, .2) .4rem solid !important;
//        box-shadow: 0 .5rem .5rem rgba($color-black, .06);
//       background-color:rgba($color-tertiary-dark, .03);
//      }

//     &--product-title{
//       display:flex;
//       justify-content:space-between;
//     }
//   }
//   &__tr {
//     background-color: rgba(143, 180, 243, 0.4);
//     color: $color-white;


//     // background-color: rgba($color-white, .3);

//   }

//   &__td {
//      background-color: rgba(162, 193, 247, 0.459);
//     color: $color-white;
//     display: flex;
//     justify-content: space-between;
//   }

//   &__cart {
//     // box-shadow: none;
//     color:rgba($color-tertiary-light, 1);
//     background-color: rgba($color-white, .3);
//     box-shadow: 0 .5rem .5rem rgba($color-black, .02);
//     display: flex;
//     flex-direction:column !important;
//     justify-content: space-between;
//     margin:3rem;
//     padding:2rem;
//   }

//   &__shipping-cost {
//     // box-shadow: none;
//     color:rgba($color-tertiary-light, 1);
//     background-color: rgba($color-white, .03);
//     box-shadow: 0 .5rem .5rem rgba($color-black, .07);
//     display: flex;
//     // flex-direction:column !important;
//     justify-content: space-between;
//     margin-bottom: 1rem;
//     padding:1rem;
//   }

//   &__details {
//     box-shadow: none;
//     background-color: rgba($color-tertiary-dark, .09);


//     &--title{
//        text-align: start;
//        padding-left: 2rem !important;
//     }
//   }

//   &__cart-details {
//     box-shadow: none;
//     background-color: rgba($color-tertiary-dark, .02);


//     &--title{
//        text-align: start;
//        padding-left: 2rem !important;
//     }
//   }

//   &__coupon {
//     box-shadow: none;
//     background-color: rgba($color-white, .2);
//   }

// }

.header-summary-table{
  margin-right: 5rem !important;
  height: fit-content !important;
  // box-shadow: 0 .2rem .2rem rgba($color-black, .04);
    // background-color: rgba($color-white, 1);

  &__inner-table{
    box-shadow: .2rem .2rem .2rem rgba($color-black, .1);
    background-color: rgba($color-white, 0.7);
    color: rgba($color-black, .8);
    font-size: 1.4rem;
    width:100% !important;
    display:flex;
    flex-direction:column;
    margin: 1rem auto;
    // margin-top:1rem;
    margin-bottom:2rem !important;
    padding:2rem 1rem;

    @include respond(phone){
      font-size: 2rem;
    }

     &--standard{
      background-color: rgba($color-Greenprimary-dark, 0.02);
     }

     &--border{
      margin-top: 1rem;
      margin-bottom: 3rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1.4rem;
      border:rgba(black, .03) .3rem solid !important;
      // border-left:rgba($color-black, .2) .1rem solid !important;
      //  box-shadow: .5rem .5rem .5rem rgba($color-black, .03);
      background-color:rgba(black, .01);
     }

    &--product-title{
      display:flex;
      justify-content:space-between;
      // color: rgba($color-primary-light, 1);
    }
  }
  &__tr {
    background-color: rgba(#304ffe, 0.05);
    color: #085eaf !important;
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    padding-left: 1.5rem  ;
    // text-align: center;
    @include respond(phone){
      font-size: 2.5rem !important;
    }


    // background-color: rgba($color-white, .3);

  }

  &__td {
     background-color: rgba(#304ffe, .05);
    color: #424242 !important;
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: .1rem .1rem .1rem rgba($color-black, .2);

    @include respond(phone){
      font-size: 2.5rem;
    }
  }

  &__cart {
    // box-shadow: none;
    width: 95%;
    padding: 1.5rem 2rem;
    color:rgba($color-black, 1);
    font-size: 1.4 !important;
    font-weight: 400;
    background-color: rgba($color-secondary-dark, .014);
    // background-color: none !important;
    box-shadow: 0 .5rem .5rem rgba($color-black, .2);
    // border: .3rem rgba($color-black, .5) !important;

    display: flex;
    flex-direction:column !important;
    justify-content: space-between;
    margin:3rem auto!important;
    // margin-right: 3rem !important;
    // padding:2rem;
  }

  &__shipping-cost {
    // width: fit-content;
    // box-shadow: none;
    color:rgba(#424242, 1) !important;
    font-size: 1.6rem;
    background-color: rgba($color-white, .8);
    box-shadow: 0 .2rem .2rem rgba($color-black, .07);
    display: flex;
    // flex-direction:column !important;
    justify-content: space-between;
    margin-top: -.7rem;
    margin-bottom: 1rem;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    // margin-left: 1rem;
    // margin-right: 1rem;
    // padding:1rem;
    @include respond(phone){
      font-size: 2.5rem;
    }
  }

  &__details {
    box-shadow: none;
    background-color: rgba($color-tertiary-dark, .09);


    &--title{
       text-align: start;
       padding-left: 2rem !important;
    }
  }

  &__cart-details {
    box-shadow: none;
    background-color: rgba($color-white, 1);
    color: rgba($color-black, 1);
    font-size: 1.6rem;
    justify-content: center;
    justify-items: center;

    @include respond(phone){
      font-size: 2.5rem;
    }


    &--title{
       text-align: start;
       padding-left: 2rem !important;
    }
  }

  &__coupon {
    box-shadow: none;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: none;
    background-color: rgba($color-white, .2);
    @include respond(phone){
      font-size: 2.5rem;
    }
  }

}

//  .anticon svg {
//     display: inline-block;
//     width: 1.8rem;
//     height: 1.8rem;
//     // height: 250rem;
//     color: rgba($color-green-yellow, 1);
//   }

// .anticon-search {
//   border:.2rem blue solid;
//   line-height: 10rem;
// }



.social-menu {
	display: flex;
	list-style-type: none;
}
.social-option {
	display:flex;
  flex-direction: column;
  width: 90rem;
  justify-content:center;
  justify-items: center;
  margin-top: -3rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  // margin-right: 5rem;

}

// .social-menu i {
// 	color: #fff;
// 	width: 40px;
// 	height: 40px;
// 	border-radius: 50%;
// 	font-size: 25px;
// 	margin-right: 10px;
// 	transition: all 0.2s ease-in-out;
// 	display: flex;
// 	justify-content: center;
// }

// .social-menu i:before {
// 	display: flex;
// 	flex-flow: row nowrap;
// 	align-items: center;
// }

// .social-menu a {
// 	text-decoration: none;
// }




// .fa-facebook {
// 	background:#3b5998
// }

// .fa-linkedin {
// 	background:#007bb6
// }

// .fa-twitter {
// 	background:#00aced
// }

// .fa-instagram {
// 	background:#3f729b
// }

// .fa-youtube {
// 	background:#c4302b
// }

// .social-menu i:hover {
// 	opacity: .7;
// 	border-radius: 0;
// }


// .form .social-input {
//   display: flex;
// }

// .form .social-input i {
//   padding: 0.5rem;
//   width: 4rem;
// }

// .form .social-input i.fa-twitter {
//   color: #38a1f3;
// }
// .form .social-input i.fa-facebook {
//   color: #3b5998;
// }
// .form .social-input i.fa-instagram {
//   color: #3f729b;
// }
// .form .social-input i.fa-youtube {
//   color: #c4302b;
// }
// .form .social-input i.fa-linkedin {
//   color: #0077b5;
// }

.header-shipping-option{
  display: flex;
  margin: 1rem 2rem .5rem 2rem ;
  padding: 1rem 4rem;
  // color: rgba($color-primary-light, 1);
  color: rgba($color-black, .8);
  background-color: rgba(white, .9);
  box-shadow: .1rem .1rem .1rem rgba($color-black, .05);

  &__title-background{
    padding: 1rem 3rem;
    background-color: rgba($color-secondary-dark, .015);
    border: .1rem rgba($color-black, .03) solid;
    font-size: 1.5rem;
    font-weight: bold;
  box-shadow: .1rem .1rem .1rem rgba($color-black, .05);

  @include respond(phone){
    font-size: 1.8rem;
  }

    &--if-available{
      font-size: 1.4rem;
      font-weight: 500;

      @include respond(phone){
        font-size: 1.6rem;
      }
    }

  }
}


// <div className="col-10 section-seller-home-nav "  >
//           {/* <pre>{JSON.stringify(slug, null, 4)}</pre> */}
//           <SingleProductNav
//             className='section-seller-home-menu section-seller-home-menu__form'
//             sellerId={sellerId && (sellerId)}
//             domain={domain}
//             sellerName={selectedProduct && selectedProduct.seller && selectedProduct.seller.name
//             //   :
//             //             products && products[0] && products[0].seller && products[0].seller.name ?
//             // products[0].seller.name : 'No Name'
//                 }
//             // company={company}
//             slug={slug}
//           />
//         </div>



//  <SellerPublicNav
//             className='section-admin-column section-admin-column__form'
//             sellerId={sellerId && sellerId }
//             sellerName={selectedPodcast && selectedPodcast.seller ? selectedPodcast.seller.name :
//               podcasts && podcasts[0] && podcasts[0].seller && podcasts[0].seller.name ?
//               podcasts[0].seller.name : 'No Name'}
//             company={company}
//             domain={domain}
//             // slug={slug}
//           />
